import * as React from "react";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { setEditableFields, updateDraftWorkField } from "../../../../redux/reducers/MembersPortalReducer";
import { SizedDropdownDataInput } from "../../../../repertoire/components/dropdownDataInput/DropdownDataInput";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { LANGUAGE_LOOKUP, WORK_GENRE_LOOKUP, PORTAL_DEFAULT_VALUES_LOOKUP } from "../../../../lookup/Consts";
import { SizedTextDataInput } from "../../../../repertoire/components/textDataInput/TextDataInput";
import { ADD_NEW_ACTION, DRAFT_AGREEMENTS_AGREEMENT_SIGNATURE_DATE_STATE_KEY, DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY, DRAFT_AGREEMENTS_APPLICABLE_WORKS_TYPE_STATE_KEY, DRAFT_AGREEMENTS_ASSIGNED_SET_TYPES_STATE_KEY, DRAFT_AGREEMENTS_CATEGORY_STATE_KEY, DRAFT_AGREEMENTS_COMMENTS_STATE_KEY, DRAFT_AGREEMENTS_CREATION_DATE_STATE_KEY, DRAFT_AGREEMENTS_EFFECTIVE_FROM_STATE_KEY, DRAFT_AGREEMENTS_EFFECTIVE_TO_STATE_KEY, DRAFT_AGREEMENTS_IP_NAME_NUMBER_STATE_KEY, DRAFT_AGREEMENTS_IP_NAME_STATE_KEY, DRAFT_AGREEMENTS_PUBLISHER_AGREEMENT_NUMBER_STATE_KEY, DRAFT_AGREEMENTS_SOCIETY_AFFILIATION_STATE_KEY, DRAFT_AGREEMENTS_SOCIETY_AGREEMENT_NUMBER_STATE_KEY, DRAFT_AGREEMENTS_STATUS_STATE_KEY, DRAFT_AGREEMENTS_TERRITORY_STATE_KEY, DRAFT_AGREEMENTS_TYPE_STATE_KEY, DRAFT_WORKS_DURATION_STATE_KEY, DRAFT_WORKS_GENRE_STATE_KEY, DRAFT_WORKS_INSTRUMENTATION_STATE_KEY, DRAFT_WORKS_IS_ARRANGEMENT_STATE_KEY, DRAFT_WORKS_IS_COMMISSIONED_COMMERCIAL_STATE_KEY, DRAFT_WORKS_IS_LIBRARY_MUSIC_STATE_KEY, DRAFT_WORKS_LANGUAGE_STATE_KEY, DRAFT_WORKS_ORIGINAL_TITLE_STATE_KEY, DRAFT_WORKS_ORIGINAL_WORK_STATE_KEY, DRAFT_WORKS_TITLES_STATE_KEY, DRAFT_WORKS_WORK_PROPERTIES, REPERTOIRE } from "../../../Consts";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { IMembersPortalField } from "../../../types/IMembersPortalField";
import { SizedCheckboxDataInput } from "../../../../repertoire/components/checkboxDataInput/CheckboxDataInput";
import { IWorkState, IWorkStateKeys } from "../../../../redux/types/IWorkState";
import DataGridTable from "../../../../repertoire/components/dataGridTable/DataGridTable";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import FormattedTimeDataInput from "../../../../repertoire/components/timeFieldDataInput/FormattedTimeDataInput";
import { MEMBERS_PORTAL } from "../../../../accounts/Consts";
import { IContributorItem } from "../../../../repertoire/types/IContributorItem";
import { IAgreementState, IAgreementStateKeys } from "../../../../redux/types/IAgreementState";
import SizedDatePickerDataInput from "../../../../repertoire/components/dateDataInput/DatePickerDataInput";
import { REPERTOIRE_PAGE_FORM_ITEM_SIZE, SEARCH_VIEW, SEARCH_VIEW_AGREEMENT_IP, SEARCH_VIEW_IPS } from "../../../../repertoire/Consts";
import ShowMore from "../../../../repertoire/components/showMore/ShowMore";
import { IWorksSearchQuery } from "../../../../repertoire/types/IWorksSearchQuery";
import { FormatFields } from "../../../../redux/types/FormatFields";

export interface IDraftAgreementsMaintenanceApplicableWorksViewProps {
    dataGridTableData?: IRepertoireComponentDataItem;
    draftAgreementMaintenanceGridsViewData?: IMembersPortalComponentDataItem;
    agreements: IAgreementState;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    updateDraftAgreementField: (value: any, fieldName: IAgreementStateKeys, index?: number) => void;
    resetMessageBanner: () => void;
    changesMade: boolean;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IMembersPortalComponentDataItem;
    dataGridTableEditableFieldsData?: IMembersPortalComponentDataItem;
    setEditableFields?: typeof setEditableFields;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal?: (
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    ) =>void;
    hideModal?: typeof hideModal;
    incrementTempID?: () => void;
    activeHeaderSection?: string;
    isMandatory?: boolean;
    searchWorks?: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    updateDraftAgreementWorkField?:(value: any, fieldName: IAgreementStateKeys, index?: number)=>void;
    getDraftAgreementWorkDetails?: (dataSource: string, workID: number, lookupValues: ILookupDictionary, draftAgreementMaintenanceGridsViewData: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
}

interface IDraftAgreementsMaintenanceApplicableWorksViewState {
    loaded: boolean;
    activeHeaderSection?: string;
}

export default class DraftAgreementsMaintenanceApplicableWorksView extends React.PureComponent<
    IDraftAgreementsMaintenanceApplicableWorksViewProps,
    IDraftAgreementsMaintenanceApplicableWorksViewState> {
    viewData;
    constructor(props: IDraftAgreementsMaintenanceApplicableWorksViewProps) {
        super(props);

        this.state = {
            activeHeaderSection: props.activeHeaderSection ?? "draftAgreementsApplicableWorks",
            loaded: false
        };
        this.viewData = {};
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    renderGridViewHeaderSections = (header: IMembersPortalField) => {

        return (
            <div
                className={["headerSection", "activeSection"].join(" ")}
                key={header.name}
                data-testid={header.name + "-section"}
            >
                {header.data}
            </div>
        );
    };


    showFieldsModal = () => {

    }

    render() {
        const {
            isReadonly,
            lookupValues,
            updateDraftAgreementField,
            incrementTempID,
            dataGridTableData,
            draftAgreementMaintenanceGridsViewData,
            tabs,
            activeTab,
            showModal,
            agreements,
            searchWorks,
            updateDraftAgreementWorkField,
            getDraftAgreementWorkDetails
        } = this.props;

        const changeData = (value: any, fieldName: string) => {
            if(fieldName==='draftAgreementsApplicableWorks'){
                updateDraftAgreementField(value, 'agreementWorks');
            }else{
                updateDraftAgreementField(value, fieldName as IAgreementStateKeys);
            }
            
        }

        const openNewTab = (openItem?:any) =>{
            if (openItem["workID"] != undefined) {
                this.props.getDraftAgreementWorkDetails(REPERTOIRE, openItem["workID"],lookupValues,draftAgreementMaintenanceGridsViewData);
            }
        }

        const showSearchModal = (modalProps: any, searchViewKey: string) => {
            const { showModal } = this.props;
            showModal(SEARCH_VIEW, searchViewKey, modalProps, true, "Search");
        };
        return (
            <>
                <div className="topMargin">
                    <div className="row">
                        <div className="headerContainer">
                         {this.renderGridViewHeaderSections(draftAgreementMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftAgreementsApplicableWorks'))}
                            
                        
                        </div>
                    </div>
                        <div>
                            <h5>The <span className="important-label">*</span>indicates a mandatory field.</h5>
                        </div>
                        {<DataGridTable
                            tableContents={agreements ? agreements.agreementWorks : []}
                            changeData={changeData}
                            dataGridTableData={dataGridTableData}
                            stateKey={DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY}
                            componentInstance={DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY}
                            showRowNumber={false}
                            isReadOnly={isReadonly}
                            tableActions={isReadonly ? [] : [{ name: ADD_NEW_ACTION }]}
                            lookupValues={lookupValues}
                            incrementTempID={incrementTempID}
                            tabs={tabs}
                            activeTab={activeTab}
                            showDropdownsAsCodes={false}  
                            showModal={showModal} 
                            searchWorks={searchWorks}  
                            updateDraftAgreementWorkField={updateDraftAgreementWorkField}    
                            openInNewTab={openNewTab}                        
                                    />}
                </div>
            </>
        );
    }
}