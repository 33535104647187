import * as React from "react";
import { Tab } from "react-bootstrap";
import { ITab } from "../../types/ITab";
import TabComponent from "./TabComponent";
import { displayRepresentationFlag, removeTab } from "../../../redux/reducers/RepertoireReducer";
import CarouselScrollButton from "../carouselScrollButton/CarouselScrollButton";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { IYesNoPromptViewModalProps } from "../modalViews/yesNoPromptView/YesNoPromptView";
import { hideModal, showYesNoPrompt } from "../../../redux/reducers/ModalReducer";
import { WORK_INTRAY_TAB_ICON, WORK_INTRAY_TAB_ICON_TRANSFORM, INTRAY, WORK_REPERTOIRE_TAB_ICON, WORK_REPERTOIRE_TAB_ICON_TRANSFORM, PRODUCT_INTRAY_TAB_ICON, PRODUCT_INTRAY_TAB_ICON_TRANSFORM, PRODUCT_REPERTOIRE_TAB_ICON, PRODUCT_REPERTOIRE_TAB_ICON_TRANSFORM, AGREEMENT_INTRAY_TAB_ICON, AGREEMENT_INTRAY_TAB_ICON_TRANSFORM, AGREEMENT_REPERTOIRE_TAB_ICON, AGREEMENT_REPERTOIRE_TAB_ICON_TRANSFORM } from "../../Consts";
import { setCurrentPage } from "../../../redux/reducers/HomeReducer";
import { MATCHING_ENGINE, MEMBERS_PORTAL } from "../../../accounts/Consts";
import IShortCutKeys from "../../../redux/types/IShortCutKeys";

export interface ITabsViewProps {
    tabs: ITabReduxItem[];
    tabsViewData: IRepertoireComponentDataItem;
    removeTab: typeof removeTab;
    activeTab: number;
    openTab: (index: number) => void;
    closeTabDisabled: boolean;
    hideModal?: typeof hideModal;
    showYesNoPrompt?: typeof showYesNoPrompt;
    displayRepresentation: typeof displayRepresentationFlag;
    setCurrentPage: typeof setCurrentPage;
    portalType: string;
    displayModal: boolean;
    shortCutKeys: IShortCutKeys[];
}

const TabsView: React.FC<ITabsViewProps> = ({
    tabs,
    tabsViewData,
    removeTab,
    activeTab,
    openTab,
    closeTabDisabled,
    hideModal,
    showYesNoPrompt,
    displayRepresentation,
    setCurrentPage,
    portalType,
    displayModal,
    shortCutKeys,
}) => {

    React.useEffect(() => {
        const close = (e) => {
            const escapeKey = shortCutKeys  && shortCutKeys.find(key => key.actionCode === 'CLOSE_ACTIVE_TAB') ? shortCutKeys.find(key => key.actionCode === 'CLOSE_ACTIVE_TAB')?.key : 'Escape';
          if(e.key === escapeKey && !displayModal){
            onClickRemoveTab(activeTab);
          }
        }
        window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
    },[activeTab, displayModal])


    const onClickRemoveTab = (index: number) => {
        if (closeTabDisabled){
            return;
        }
        if (tabs[index].changesMade) {
            const title: string = tabsViewData.fields[0].data;
            const props: IYesNoPromptViewModalProps = {
                yesCallback: () => { 
                    removeTab(index);  
                    hideModal();
                },
                noCallback: () => { hideModal();  }
            }
            showYesNoPrompt(title, props);
        } else {
            removeTab(index);
        }
    }


    const onClick = async(target, index) => {

        let address = portalType === MEMBERS_PORTAL ? '/works/' : '/repertoire/';
        const META_DATA_KEY: any = {
            'find work': { 'link': "works",instance: MATCHING_ENGINE },
            'find agreement': { 'link': "agreements", instance: MATCHING_ENGINE },
            'find ip': { 'link': "ips", instance: MATCHING_ENGINE },
            'find artist': { 'link': "artists", instance: MATCHING_ENGINE },
            'find product': { 'link': "products", instance: MATCHING_ENGINE },
            'find workflow': { 'link': "workflows", instance: MATCHING_ENGINE },
            'find usage information': { 'link': "usage", instance: MATCHING_ENGINE },
            'find usage pools': { 'link': "pools", instance: MATCHING_ENGINE },
            'find distribution': { 'link': "distributions", instance: MATCHING_ENGINE },
            'find payment run': { 'link': "paymentRuns", instance: MATCHING_ENGINE },
            'find license': { 'link': "licenses", instance: MATCHING_ENGINE },
            'find adjustment': { 'link': "adjustments", instance: MATCHING_ENGINE },
        }

        const remove = document.getElementsByClassName("icon ms-Icon ms-Icon--ChromeClose");
        if(remove[index].contains(target)) {
            await onClickRemoveTab(index);
        }
        else  {
            await openTab(index);
        }

        if (tabs[activeTab].title.toLocaleLowerCase().search('find') > 0) {
            setCurrentPage(address + META_DATA_KEY[tabs[activeTab].title.toLowerCase()].link);
        }

        document.title = tabs[activeTab].title;

    }

    const renderTabs = () => {

        return tabs.map((tab: ITab, index: number) => (
            <div onClick={(e) => onClick(e.target, index)} key={index} className={activeTab === index ? "activeTab" : "tab"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 100 100">
                    {tabs[index].workMaintenanceState && tabs[index].workMaintenanceState.work ?
                        (tabs[index].workMaintenanceState.work.dataSource === INTRAY ?
                            <path id="" className="tabIconType" d={WORK_INTRAY_TAB_ICON} transform={WORK_INTRAY_TAB_ICON_TRANSFORM}></path>
                            : <path id="" className="tabIconType" d={WORK_REPERTOIRE_TAB_ICON} transform={WORK_REPERTOIRE_TAB_ICON_TRANSFORM}></path>)
                        : tabs[index].productMaintenanceState && tabs[index].productMaintenanceState.product ?
                            (tabs[index].productMaintenanceState.product.dataSource === INTRAY ?
                                <path id="" className="tabIconType" d={PRODUCT_INTRAY_TAB_ICON} transform={PRODUCT_INTRAY_TAB_ICON_TRANSFORM}></path>
                                : <path id="" className="tabIconType" d={PRODUCT_REPERTOIRE_TAB_ICON} transform={PRODUCT_REPERTOIRE_TAB_ICON_TRANSFORM}></path>)
                            : tabs[index].agreementMaintenanceState && tabs[index].agreementMaintenanceState.agreement ?
                                (tabs[index].agreementMaintenanceState.agreement.dataSource === INTRAY ?
                                    <path id="" className="tabIconType" d={AGREEMENT_INTRAY_TAB_ICON} transform={AGREEMENT_INTRAY_TAB_ICON_TRANSFORM}></path>
                                    : <path id="" className="tabIconType" d={AGREEMENT_REPERTOIRE_TAB_ICON} transform={AGREEMENT_REPERTOIRE_TAB_ICON_TRANSFORM}></path>)
                        :<></>
                    }
                    </svg> 

                <div>
                    {tab.title ? tab.title : "untitled"}
                </div>
                <div
                    className={["removeTabDiv", !!closeTabDisabled && "closeDisabled"].join(" ")}
                >
                    <span className="icon ms-Icon ms-Icon--ChromeClose" aria-hidden="true"></span>
                </div>
            </div>
        ));
    };

    const renderTabContent = () => {
        return tabs.map((tab: ITab, index: number) => (
            <Tab.Pane eventKey={index} key={index} className={tab.isReadonly ? 'versionHistory' : ''} >
                <TabComponent component={tab.component} displayRepresentation={displayRepresentation} />
            </Tab.Pane>
        ));
    };

        return (
            <div className="form-group col-md-12 TabsView">
                <div className="tabsDiv">
                    <CarouselScrollButton
                        content={renderTabs()}
                        distanceToScroll={100}
                    />
                </div>
                <Tab.Container
                    activeKey={activeTab}
                    onSelect={() => null}
                    id="tab-container" className="tab-content formBox tab-pane active fade in"
                >
                    <Tab.Content>{renderTabContent()}</Tab.Content>
                </Tab.Container>
            </div>
        );
}

export default TabsView;