import { Console } from "console";
import { AGENCY_LOOKUP, TERRITORY_LOOKUP } from "../../lookup/Consts";
import { getLookupValues } from "../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { isNullOrUndefined } from "../../util/tools";
import { DATE_INPUT, DROPDOWN_INPUT, READ_ONLY_INPUT, TEXT_INPUT } from "../Consts";
import { IIPRepresentation } from "../types/IIPRepresentation";
import { IInterestedParty } from "../types/IInterestedParty";
import { ISocIP } from "../types/ISocIP";
import { ITerritories } from "../types/ITerritories";

export class InterestedPartyDetailsMapper {
    public static mapIPRepresentationTerritory(territories: ITerritories[], lookups?: any, includeTerritory?: string, excludeTerritory?: string, repID?: number): any {
        let territory;
        let includeTransformed;
        let excludeTransformed;
        let mergedArray;
        if (includeTerritory || excludeTerritory) {
            if (includeTerritory) {
                let includeTerritories = includeTerritory.split(',');
                includeTransformed = includeTerritories.map(value => ({
                    includeExclude: 'I',
                    value: value.toUpperCase()
                }));
            }
            if (excludeTerritory) {
                let excludeTerritories = excludeTerritory.split(',');
                excludeTransformed = excludeTerritories.map(value => ({
                    includeExclude: 'E',
                    value: value.toUpperCase()
                }));
            }

            if (includeTerritory && excludeTerritory) {
                mergedArray = includeTransformed.concat(excludeTransformed);
            }
            else if (includeTerritory.length > 0 && excludeTerritory.length === 0) {
                mergedArray = includeTransformed;
            }
            else if (excludeTerritory.length > 0 && includeTerritory.length === 0) {
                mergedArray = excludeTransformed;
            }
            let representationIDNo = territories ? territories[0].representationID : repID;
            let territoryDetails
            return mergedArray.map(lineItem => {
                if (territories) {
                    territoryDetails = territories.filter(x => (x.code === lineItem.value && x.includeExclude === lineItem.includeExclude))
                }
                const isTerritoryPresent = territoryDetails && territoryDetails.length;
                if (lookups) {
                    territory = lookups.filter(x => x.code === ((lineItem.value) as any))
                    if (territory) {
                        return {
                            code: territory.length > 0 ? territory[0].code : '',
                            description: territory.length > 0 ? territory[0].description : lineItem.value,
                            includeExclude: lineItem.includeExclude,
                            isDeleted: isTerritoryPresent ? territoryDetails[0].isDeleted : false,
                            lastModifiedDate: isTerritoryPresent ? territoryDetails[0].lastModifiedDate : new Date(),
                            representationID: isTerritoryPresent ? territoryDetails[0].representationID : representationIDNo,
                            representationTerritoryID: isTerritoryPresent ? territoryDetails[0].representationTerritoryID : 0,
                            territoryID: territory.length > 0 ? territory[0].typeID : 0,
                            tisnValidFrom: isTerritoryPresent ? territoryDetails[0].tisnValidFrom : new Date()
                        }
                    }

                }

            })


        }
        else if (territories && includeTerritory === ' ' && excludeTerritory === ' ') {
            return territories.map(lineItem => {

                if (lookups) {
                    territory = lookups.filter(x => x.code === ((lineItem.code) as any))

                    if (territory) {
                        return {
                            code: territory[0].code,
                            description: territory[0].description,
                            includeExclude: lineItem.includeExclude,
                            isDeleted: lineItem.isDeleted ? lineItem.isDeleted : false,
                            lastModifiedDate: lineItem.lastModifiedDate ? lineItem.lastModifiedDate : new Date(),
                            representationID: lineItem.representationID,
                            representationTerritoryID: lineItem.representationTerritoryID,
                            territoryID: territory[0].typeID,
                            tisnValidFrom: lineItem.tisnValidFrom
                        }
                    }

                }

            })
        }
    }

    public static mapIncludeTerritory(territories: ITerritories[]): string {

        if (territories) {
            return territories.filter(lineItem => lineItem.includeExclude == 'I').map(x => x.code).join(',');
        }
    }

    public static mapExcludeTerritory(territories: ITerritories[]): string {
        if (territories) {
            return territories.filter(lineItem => lineItem.includeExclude == 'E').map(x => x.code).join(',');
        }
    }


    public static mapSocIP(socIP: ISocIP, lookups: ILookupDictionary, objectKey?: string): any {
        let societyLookUp;
        let territoriesLookup
        if(lookups){
             societyLookUp = getLookupValues(AGENCY_LOOKUP, lookups);
             territoriesLookup = getLookupValues(TERRITORY_LOOKUP, lookups);
        }      
         
        let agency;
        if (socIP && socIP.ipRepresentations && socIP.ipRepresentations.length > 0) {
            return socIP.ipRepresentations.map(lineItem => {
                if (societyLookUp) {
                    if (lineItem.agencyID && Number(lineItem.societyName.value)) {
                        agency = societyLookUp.filter(x => x.code === (lineItem.societyName.value as any))

                    } else if (lineItem.agencyID) {
                        agency = societyLookUp.filter(x => x.code === (lineItem.agencyID as any))
                    } else {
                        agency = societyLookUp.filter(x => x.code === (lineItem.societyName.value as any))
                    }
                }
                const isAgency = agency && agency.length;
                return {
                    agencyID: isAgency && agency[0].code ? agency[0].code : lineItem.agencyID,
                    agencyName: isAgency && agency[0].description ? agency[0].description : lineItem.agencyName,
                    amendedDateTime: lineItem.amendedDateTime ? lineItem.amendedDateTime : (new Date()).toISOString().split('T')[0],
                    applicableTerritories: this.mapIPRepresentationTerritory(lineItem.applicableTerritories, territoriesLookup, lineItem['includeTerritories'].value, lineItem['excludeTerritories'].value, (lineItem.representationID ? lineItem.representationID : 0)),
                    economicRightDescription: lineItem.economicRightDescription ? lineItem.economicRightDescription : null,
                    creationClass: lineItem.creationClass.value,
                    economicRights: lineItem.economicRights.value,
                    externalReferenceID: null,
                    fromDate: (lineItem.fromDate.value) ? (new Date((lineItem.fromDate.value)).toISOString().split('T', 1)[0]) : (new Date().toISOString().split('T', 1)[0]),
                    toDate: lineItem.toDate.value,
                    isAgreementEnded: lineItem.isAgreementEnded ? lineItem.isAgreementEnded : false,
                    societyName: isAgency ? agency[0].description : '',
                    isDeleted: lineItem.isDeleted ? lineItem.isDeleted : false,
                    isWorld: lineItem.isWorld ? lineItem.isWorld : false,
                    lastModifiedDate: lineItem.lastModifiedDate ? lineItem.lastModifiedDate : (new Date()).toISOString().split('T')[0],
                    representationID: lineItem.representationID ? lineItem.representationID : 0,
                    role: lineItem.role.value,
                    sharePercentage: lineItem.sharePercentage ? lineItem.sharePercentage : "100.0",
                    shareSet: lineItem.shareSet.value?lineItem.shareSet.value:'',
                    signedDate: lineItem.signedDate ? lineItem.signedDate : (new Date()).toISOString().split('T')[0],
                    socIPID: isNullOrUndefined(socIP.socIPID) ? 0 : socIP.socIPID,
                    territories: lineItem.territories ? lineItem.territories : null
                }

            })
        }
        else return []
    }

    public static mapInterestedPartStatetoState(socIP: ISocIP, lookups: ILookupDictionary): any {

        if (socIP && socIP.ipRepresentations && socIP.ipRepresentations.length > 0) {

            return socIP.ipRepresentations.map(lineItem => {
                let agency;
                if (lookups){
                    const societyLookUp = getLookupValues(AGENCY_LOOKUP, lookups);
                    if (societyLookUp) {
                        if (lineItem.agencyID) {
                            agency = societyLookUp.find(x => x.code === (lineItem.agencyID ? lineItem.agencyID : lineItem.agencyID.value as any))
                        }
                        else {
                            agency = societyLookUp.find(x => x.code === lineItem.societyName.value)
                        }
                    }
                }
                
                return {
                    agencyID: lineItem.agencyID ? lineItem.agencyID : null,
                    agencyName: lineItem.agencyName ? lineItem.agencyName : null,
                    amendedDateTime: lineItem.amendedDateTime ? lineItem.amendedDateTime : null,
                    applicableTerritories: lineItem.applicableTerritories,
                    economicRightDescription: lineItem.economicRightDescription ? lineItem.economicRightDescription : null,
                    externalReferenceID: lineItem.externalReferenceID ? lineItem.externalReferenceID : null,
                    isAgreementEnded: lineItem.isAgreementEnded ? lineItem.isAgreementEnded : false,
                    isDeleted: lineItem.isDeleted ? lineItem.isDeleted : false,
                    isWorld: lineItem.isWorld ? lineItem.isWorld : false,
                    lastModifiedDate: lineItem.lastModifiedDate ? lineItem.lastModifiedDate : new Date(),
                    representationID: lineItem.representationID ? lineItem.representationID : 0,
                    sharePercentage: lineItem.sharePercentage ? lineItem.sharePercentage : "100.0",
                    signedDate: lineItem.signedDate ? lineItem.signedDate : new Date(),
                    socIPID: isNullOrUndefined(socIP.socIPID) ? 0 : socIP.socIPID,
                    territories: lineItem.territories ? lineItem.territories : null,
                    societyName: { value: agency.description, inputType: DROPDOWN_INPUT },
                    fromDate: { value: lineItem.fromDate, inputType: DATE_INPUT },
                    toDate: { value: lineItem.toDate, inputType: DATE_INPUT },
                    economicRights: { value: lineItem.economicRights, inputType: DROPDOWN_INPUT },
                    shareSet: { value: lineItem.shareSet, inputType: READ_ONLY_INPUT },
                    role: { value: lineItem.role, inputType: DROPDOWN_INPUT },
                    includeTerritories: { value: this.mapIncludeTerritory(lineItem.applicableTerritories), inputType: TEXT_INPUT },
                    excludeTerritories: { value: this.mapExcludeTerritory(lineItem.applicableTerritories), inputType: TEXT_INPUT },
                    creationClass: { value: lineItem.creationClass, inputType: DROPDOWN_INPUT },
                }

            })
        }
        else return []
    }
}