import axios, { AxiosResponse } from "axios";
import { DataSource } from "../../repertoire/types/DataSource";
import { IIPAgreementSearchModel } from "../../repertoire/types/IIPAgreementSearchModel";
import { IWork } from "../../repertoire/types/IWork";
import { IAgreement } from "../../repertoire/types/IAgreement";
import { ISocIP } from "../../repertoire/types/ISocIP";
import { IProductCore } from "../../repertoire/types/IProductCore";
import { SaveOption } from "../../repertoire/types/SaveOption";
import { ManualMergeOptions } from "../../repertoire/types/ManualMergeOptions";
import { IAgreementSearchState } from "../../repertoire/types/IAgreementSearchState";
import { IIPAgreement } from "../../repertoire/types/IIPAgreement";
import { IIPAgreementsInputModel } from "../types/IIPAgreementsInputModel";
import { IAttachedFile } from "../types/IAttachedFile";
import { IAddWorkAttachmentModel } from "../types/IAddAttchmentWorkModel";
import { IAssignWorkflowModel } from "../../repertoire/types/IAssignWorkflowModel";
import { resolve } from "dns";
import { reject } from "lodash";
import { IAddAgreementAttachmentModel } from "../types/IAddAttchmentAgreementModel";
import { IRemoveWorkBlobAttachment } from "../../repertoire/types/IRemoveWorkBlobAttachment";
import { IProductAVRequest } from "../../repertoire/types/IProductAVRequest";
import { IRemoveBlobAttachmentBase } from "../../repertoire/types/IRemoveBlobAttachmentBase";
import { IAddProductAttachmentModel } from "../types/IAddProductAttachmentModel";
import { IArtistCore } from "../../repertoire/types/IArtistCore";
import { IUsagePoolCore } from "../../repertoire/types/usageTypes/IUsagePoolCore";
import { IDistribution } from "../../repertoire/types/usageTypes/IDistribution";
import { IRepresentation } from "../../repertoire/types/usageTypes/IRepresentation";
import { IDeleteRepresentations } from "../../repertoire/types/usageTypes/IDeleteRepresentations";
import { IClaim } from "../../repertoire/types/IClaim";
import { IWorkflow } from "../../repertoire/types/IWorkflow";
import { IAdjustment } from "../../repertoire/types/usageTypes/IAdjustment";
import { IipTransfer } from "../../repertoire/types/usageTypes/IipTransfer";
import { IPaymentRun } from "../../repertoire/types/usageTypes/IPaymentRun";
import { ILicenseRequest } from "../../repertoire/types/ILicenseRequest";
import { IRemoveBlobModel } from "../types/IRemoveBlobModel";
import { IShoppingCart } from "../../repertoire/types/IShoppingCart";
import { ILicenseInput } from "../../repertoire/types/ILicenseInput";
import { ILicenseSaveResult } from "../../repertoire/types/ILicenseSaveResult";
import { IAddUsageAttachmentModel } from "../types/IAddUsageAttachmentModel";
import { IRemoveUsageAttachment } from "../../repertoire/types/IRemoveUsageBlobAttachment";
import { ILicenseRequestWork } from "../../repertoire/types/ILicenseRequestWork";
import { IName } from "../../repertoire/types/IName";
import { IInterestedParty } from "../../repertoire/types/IInterestedParty";
import { IInterestedPartyPostType } from "../../repertoire/types/IInterestedPartyPostType";
import { ILicenseSubmitResult } from "../../repertoire/types/ILicenseSubmitResult";
import { IRepresentationSearchState } from "../../repertoire/types/IRepresentationSearchState";

export class RepertoireService {

    static mergeWork(work: IWork, workId: number, mergeOption: ManualMergeOptions, mergeIntoMatch: boolean): Promise<any> {
        const url: string = "work/merge/" + workId + '/' + mergeOption + '/' + mergeIntoMatch;
        return new Promise<any>((resolve, reject) => {
            axios
                .patch(url, work)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static mergeInterestedParty(mergeIP:IInterestedPartyPostType): Promise<any> {
        const url: string = "interestedParty/merge/";
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, mergeIP)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static mergeProduct(product: IProductCore, matchingProductId: number, mergeOption: ManualMergeOptions, mergeIntoMatch: boolean): Promise<any> {
        const url: string = "product/merge/" + matchingProductId + '/' + mergeOption + '/' + mergeIntoMatch;
        return new Promise<any>((resolve, reject) => {
            axios
                .patch(url, product)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static deleteWork(dataSource: DataSource, workId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .delete("work/delete/" + dataSource.toString() + '/' + workId)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static saveCompleteWorkflow(workflow: IWorkflow): Promise<any> {
        return new Promise<string>((resolve, reject) => {
            axios
                .post("workflow/SaveWorkflow", workflow)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else {
                        reject(res);
                    }
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static updateClaimUsageWorkflow(workflow: IWorkflow): Promise<any> {
        return new Promise<string>((resolve, reject) => {
            axios
                .put("workflow/ProcessClaimWorkflow", workflow)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else {
                        reject(res);
                    }
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static updateWorkflowAndUsage(workflow: IWorkflow): Promise<any> {
        return new Promise<string>((resolve, reject) => {
            axios
                .post("workflow/SaveWorkflow", workflow)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else {
                        reject(res);
                    }
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static linkedAgreements(model: IIPAgreementSearchModel): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post("agreement/linkedAgreements", model)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static createNewWork(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post("work/createNew")
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static saveChanges(updatedWork: IWork, saveOption: SaveOption): Promise<any> {
        const url: string = "work/save/" + saveOption;
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, updatedWork)
                .then((res: any)=> {
                    if(res.status != 200) {
                        let message: string;
                        switch(res.response.status) {
                            case 409:
                                message = "Changes have recently been made on this Work. Please Close and Re-open.";
                            break;
                            default:
                                message = res.message;
                        }
                        reject(message)
                    }
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static saveAdditionalWorkNumbers(work: IWork, pageNumber: number): Promise<any> {
        const url: string = `work/SaveAdditionalWorkNumber/${pageNumber}`;
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, work)
                .then((res: any)=> {
                    if(res.status != 200) {
                        let message: string;
                        switch(res.response.status) {
                            case 409:
                                message = "Changes have recently been made on this Work. Please Close and Re-open.";
                            break;
                            default:
                                message = res.message;
                        }
                        reject(message)
                    }
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static getCurrentPageAdditionalWorkNumberDetails(workId: number, pageNumber: number): Promise<any> {
        const url: string = `work/GetCurrentPageAdditionalWorkNumbers/${workId}/${pageNumber}`;
        return new Promise<any>((resolve, reject) => {
            axios
                .get(url)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        });
    }

    static saveAgreement(updatedAgreement: IAgreement, postAgreement: boolean): Promise<any> {
        if (updatedAgreement.status === null){
            updatedAgreement.status = "draft";
        }
        const url: string = "agreement/save/" + (postAgreement ? "true" : "false");
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, updatedAgreement)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static deleteAgreement(dataSource: DataSource, agreementId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .delete("agreement/delete/" + dataSource.toString() + '/' + agreementId)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static mergeAgreement(mergeAgreement: IAgreement, agreementId: number): Promise<any> {
        const url: string = "agreement/merge/" + agreementId;
        return new Promise<any>((resolve, reject) => {
            axios
                .patch(url, mergeAgreement)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static saveAccountChanges(account: ISocIP): Promise<any> {
        const url: string = "interestedparty/save/";
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, account)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }



    static saveProduct(updatedProduct: IProductCore, saveOption: SaveOption): Promise<any> {
        const url: string = "product/save/" + saveOption;
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, updatedProduct)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static deleteProduct(dataSource: DataSource, productCoreId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .delete("product/delete/" + dataSource.toString() + '/' + productCoreId)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static saveArtist(updatedArtist: IArtistCore): Promise<any> {
        const url: string = "performer/save/";
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, updatedArtist)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static deleteArtist(performerID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .delete("performer/delete/" + performerID)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static saveUsagePool(updatedPool: IUsagePoolCore): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('usagePool/save/', updatedPool)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static getUsagePools(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get('usagePool/pools')
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static deleteUsagePool(poolId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .delete("usagePool/delete/" + poolId)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static saveDistribution(updatedDistribution: IDistribution): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('distribution/save/', updatedDistribution)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static deleteAccount(ipBaseNumber: string, account: string): Promise<any> {
        let url: string = "interestedparty/delete/" + ipBaseNumber;

        if (account !== "") {
            url = "interestedparty/deletebyaccount/" + account;
        }

        return new Promise<any>((resolve, reject) =>
            axios
                .delete(url)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        )
    }

    static filterIpAgreements(searchState: IAgreementSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[]): Promise<any> {
        const url: string = "interestedParty/filterAgreements/";
        const payload: IIPAgreementsInputModel = {
            customDateRange: searchState.customDateRange,
            dateFrom: searchState.dateFrom,
            dateRange: searchState.dateRange,
            dateTo: searchState.dateTo,
            accountNumber: accountNumber,
            ipBaseNumber: ipBaseNumber,
            creationClasses: creationClasses
        }
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, payload)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static filterIpRepresentations(searchState: IRepresentationSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[], role?: string): Promise<any> {
        const url: string = "interestedParty/filterRepresentations/";
        const payload: IIPAgreementsInputModel = {
            customDateRange: searchState.customDateRange,
            dateFrom: searchState.dateFrom,
            dateRange: searchState.dateRange,
            dateTo: searchState.dateTo,
            accountNumber: accountNumber,
            ipBaseNumber: ipBaseNumber,
            creationClasses: creationClasses,
            role: role
        }
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, payload)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static addAttachmentRepertoire(model: IAddWorkAttachmentModel): Promise<IAttachedFile> {
        const url: string = "work/addAttachment"
        return new Promise<IAttachedFile>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        })
    }

    static removeWorkAttachment(model: IAddWorkAttachmentModel): Promise<boolean> {
        const url: string = "work/removeattachment"
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        })
    }

    static addAgreementAttachmentRepertoire(model: IAddAgreementAttachmentModel): Promise<IAttachedFile> {
        const url: string = "agreement/addAttachment"
        return new Promise<IAttachedFile>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        })
    }
    
    static removeAgreementAttachment(model: IAddWorkAttachmentModel): Promise<boolean> {
        const url: string = "agreement/removeattachment"
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        })
    }

    static assignWorkflows(model: IAssignWorkflowModel): Promise<boolean> {
        const url: string = "workflow/Assign";
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data);
                })
                .catch((err => {
                    console.log(err);
                    reject(err);
                }))
        });
    }

    static addProductAttachmentRepertoire(model: IAddProductAttachmentModel): Promise<IAttachedFile> {
        const url: string = "product/addAttachment"
        return new Promise<IAttachedFile>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        })
    }
    static removeProductAttachment(model: IAddProductAttachmentModel): Promise<boolean> {
        const url: string = "product/removeattachment"
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        })
    }

    static addUsageAttachment(model: IAddUsageAttachmentModel): Promise<IAttachedFile> {
        const url: string = "usage/addAttachment"
        return new Promise<IAttachedFile>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static removeUsageAttachment(model: IAddUsageAttachmentModel): Promise<boolean> {
        const url: string = "usage/removeAttachment";
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static removeUsageInputBlob(model: IRemoveBlobModel): Promise<boolean> {
        const url: string = "usage/DeleteBlob"
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        })
    }

    static removeWorkBlobAttachment(model: IRemoveBlobAttachmentBase): Promise<boolean> {
        const url: string = "work/RemoveBlobAttachment";
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data);
                })
                .catch((err => {
                    console.log(err);
                    reject(err);
                }))
        })
    }

    static removeProductBlobAttachment(model: IRemoveBlobAttachmentBase): Promise<boolean> {
        const url: string = "product/RemoveBlobAttachment";
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data);
                })
                .catch((err => {
                    console.log(err);
                    reject(err);
                }))
        })
    }

    static removeUsageBlobAttachment(model: IRemoveUsageAttachment): Promise<boolean> {
        const url = "usage/RemoveBlobAttachment";
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static saveAVRequest(productAVRequest: IProductAVRequest, productCoreID: number): Promise<any> {
        const url: string = "product/SubmissionRequest/" + productCoreID;
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, productAVRequest)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static removeAgreementBlobAttachment(model: IRemoveBlobAttachmentBase): Promise<boolean> {
        const url: string = "agreement/RemoveBlobAttachment";
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data);
                })
                .catch((err => {
                    console.log(err);
                    reject(err);
                }))
        })
    }

    static deleteDistribution(distributionId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .delete("distribution/delete/" + distributionId)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static exportDistributionPools(distribution: IDistribution): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('distribution/export/', distribution)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static addNewRepresentation(representation: IRepresentation): Promise<any> {
        const url: string = "interestedparty/AddInterestedPartyRepresentation"
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, representation)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static getRepresentationGroups(ipBaseNumber: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get('interestedParty/getRepresentationGroupDetails/' + ipBaseNumber)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static importDistributionPools(distribution: IDistribution, fileName: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('distribution/import/', { distribution, fileName })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static deleteInterestedPartyRepresentation(representations: IDeleteRepresentations): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .delete("interestedParty/DeleteInterestedPartyRepresentation/", { data: representations })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static saveClaim(updatedClaim: IClaim): Promise<any> {
        const url: string = "claim/save";
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, updatedClaim)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static saveAdjustment(adjustment: IAdjustment, saveOption?: string): Promise<any> {
        const url: string = "adjustment/save/" + (saveOption === 'SavePost' ? "true" : "false");
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, adjustment)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static transferIp(ipTransfer: IipTransfer): Promise<any> {
        const url: string = "interestedParty/IpTransfer/";

        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, ipTransfer)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static savePaymentRun(updatedPaymentRun: IPaymentRun): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('paymentRun/save/', updatedPaymentRun)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static deletePaymentRun(paymentRunId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .delete("paymentRun/delete/" + paymentRunId)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }


    static getPaymentRunIDs(ipTransfer: IipTransfer): Promise<any> {
        const url: string = "interestedParty/IpTransfer/";

        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, ipTransfer)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static getShoppingCartParameters(): Promise<any> {

        return new Promise<any>((resolve, reject) => {
            axios
                .get('license/ShoppingCartParameters')
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static saveLicenseRequest(updateLicenseRequest: IShoppingCart): Promise<ILicenseSaveResult> {
        const url: string = "license/save";
        return new Promise<ILicenseSaveResult>((resolve, reject) => {
            axios
                .post(url, updateLicenseRequest)
                .then((res: AxiosResponse<ILicenseSaveResult>) => {
                    resolve(res.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static getLicenseRequestWorkParameters
        (commercialUseToken: number, geographicalToken: number,
            sectorToken: number, userTypeToken: number, useTypeToken: string, workID: number): Promise<ILicenseInput[]> {
        const url: string = `license/GetParameters/${commercialUseToken}/${geographicalToken}/${sectorToken}/${userTypeToken}/${useTypeToken}/${workID}`;
        return new Promise<ILicenseInput[]>((resolve, reject) => {
            axios
                .get(url)
                .then((res: AxiosResponse<ILicenseInput[]>) => {
                    resolve(res.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static getLicenseWorkContributors
        (workIds: number[], setType: string, setTypeGroup: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('license/GetWorkContributors/', {
                    workIDs: workIds.map(v => v.toString()),
                    dataSource: "",
                    setType: setType,
                    setTypeGroup: setTypeGroup,
                    contributorTypesToShow: []
                })
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static calculateLicenseWorksPrices
        (shoppingCart: IShoppingCart, selectedLicenseRequestWorks: ILicenseRequestWork[], licenceRequestID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('job/calculateWorksPrices/' + licenceRequestID, { ShoppingCart: shoppingCart, LicenseRequestWorks: selectedLicenseRequestWorks })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static saveIPNames(ipNames: IName[]): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('interestedParty/saveIpNames/', ipNames)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }    

    static submitLicenseToCRM(licenseRequestID :number, clientID: string): Promise<ILicenseSubmitResult> {
        return new Promise<ILicenseSubmitResult>((resolve, reject) => {
            axios.post('license/SubmitLicenseToCRM', {
                licenseRequestID: licenseRequestID,
                clientID: clientID
            }).then((res: AxiosResponse<ILicenseSubmitResult>) => {
                resolve(res.data);
            }).catch((err: AxiosResponse<ILicenseSubmitResult>) => {
                reject(err);
            })
        })
    }

    static addToPackage(userName: string, entityType: string,entityID:number): Promise<any> {
        const url: string = `Package/AddToPackage/${userName}/${entityType}/${entityID}`;
        console.log(url);
        return new Promise<any>((resolve, reject) => {
            axios
                .get(url)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static processClaim(distribution: IDistribution, undoAllocation: boolean): Promise<any> {

        return new Promise<any>((resolve, reject) => {
            axios
                .post('job/allocateDistributionPools/', { distribution, undoAllocation })
                .then(res => {
                    resolve(res);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            })
        })
    }
}
