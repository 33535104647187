import * as React from "react";
import { throttle, last } from "lodash";
import { ALL_ROLES, COMPONENT_INSTANCE_IPS, DELETE_IP_PROMPT_STATE_KEY, EVENT_HANDLER_THROTTLE_TIME, IP_MAINTENANCE_GENERAL_DATA_VIEW, IP_MAINTENANCE_PAGE, IP_MAINTENANCE_TOOLBAR, POST_TO_REPERTOIRE_STATE_KEY, REPERTOIRE_SIDE_MENU_IPS_KEY, REPERTOIRE_SIDE_MENU_PRODUCTS_KEY, REPRESENTATION_PAGE, TOOLBAR_POSITION_BREAKPOINT, UPDATE_IPS_ROLE, WORK_MAINTENANCE_TOOLBAR } from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IInterestedParty, IInterestedPartyStateKeys } from "../../../types/IInterestedParty";
import { ISocIP } from "../../../types/ISocIP";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { showModal, showYesNoPrompt, hideModal, showUpdateFieldsModal, showMessage } from "../../../../redux/reducers/ModalReducer";
import { setChangesMade, addTab, updateInterestedPartyField, sortInterestedPartyFieldData, resetMessageBanner, updateIpAgreementsFilterStateField, setEditableFields, updateEditableFields, clearModalSearchResults, displayRepresentationFlag, getRepresentationGroups, deleteRepresentations, updateRepresenIdsToDelete, setWorkGroupMaintenanceState, ipTransferRequest, updateIpRepresentationFilterStateField } from "../../../../redux/reducers/RepertoireReducer";
import IPMaintenanceToolbar, { IInterestedPartyMaintenanceToolbarProps } from "../ipMaintenanceToolbar/IPMaintenanceToolbar";
import IPMaintenanceGeneralDataView from "../ipMaintenanceGeneralDataView/IPMaintenanceGeneralDataView";
import IPMaintenanceGridsView from "../ipMaintenanceGridsView/IPMaintenanceGridsView";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { IAgreementSearchState } from "../../../types/IAgreementSearchState";
import { IIPAgreement } from "../../../types/IIPAgreement";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { getDataAction } from "../../../components/toolBar/ToolbarHelper";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { If } from "../../../../core/components/If";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { IIPMaintenancePageUIConfiguration } from "../../../../redux/types/IIPMaintenancePageUIConfiguration";
import { IRepertoirePathInfo } from "../../../types/IRepertoirePathInfo";
import SaveResult from "../../../components/saveResult/SaveResult";
import { IDeleteRepresentations } from "../../../types/usageTypes/IDeleteRepresentations";
import { IName } from "../../../types/IName";
import { DataSource } from "../../../types/DataSource";
import { ManualMergeOptions } from "../../../types/ManualMergeOptions";
import { IRepresentationSearchState } from "../../../types/IRepresentationSearchState";

export interface IInterestedPartyMaintenancePageProps {
    ipMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    ipMaintenanceGridsViewData: IRepertoireComponentDataItem;
    ipMaintenanceAccordionViewData?: IRepertoireComponentDataItem;
    ipMaintenanceToolbarData?: IRepertoireComponentDataItem;
    ipMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    dataGridTableData: IRepertoireComponentDataItem;
    interestedParty: IInterestedParty;
    setChangesMade: typeof setChangesMade;
    activeAccordion: string[];
    updateInterestedPartyField: (value: any, fieldName: IInterestedPartyStateKeys) => void;
    sortIPFieldData: typeof sortInterestedPartyFieldData;
    openIPAccordion: (accordionName: string, componentName: string) => void;
    closeIPAccordion: (accordionName: string, componentName: string) => void;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal: typeof showModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    addTab: typeof addTab;
    saveChanges: (account: ISocIP,lookups: ILookupDictionary, ipNames: IName[]) => void;
    deleteAccount: (ipBaseNumber: string, account: string, activeTab: number) => void;
    addNewAccount: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralViewData?: IRepertoireComponentDataItem) => void;
    undoIPChanges: (ipBaseNumber?: string, accountNumber?: string) => void;
    resetMessageBanner: () => void;
    ipMaintenanceTimeRangeSelectorViewData: IComponentDataItem;
    ipAgrementsSearchState: IAgreementSearchState;
    ipRepresentationSearchState:IRepresentationSearchState;
    updateIpAgreementsFilterStateField: typeof updateIpAgreementsFilterStateField;
    updateIpRepresentationFilterStateField: typeof updateIpRepresentationFilterStateField;
    filterIpAgreements: (searchState: IAgreementSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[]) => void;
    filterIpRepresentations: (searchState: IRepresentationSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[], role?: string) => void;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    showUpdateFieldsModal: typeof showUpdateFieldsModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    searchSocIPVersionHistory: (socIPId: number, activeTabItem: ITabReduxItem, formats: FormatFields[]) => void;
    workflowFieldsData: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    editableFields: IRepertoireField[];
    ipMaintenancePageUIConfiguration: IIPMaintenancePageUIConfiguration;
    getIPMaintenaceDisplaySettings: () => void;
    displayRepresentation: typeof displayRepresentationFlag;
    displayRepresentationBoolean: boolean;
    loadRepertoireFromPath: (pathInfo: IRepertoirePathInfo, location: Location) => void;
    createNewRepresentation: (ipBaseNumber: string, lookups: ILookupDictionary, isNew?: boolean, representationMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => void;
    getRepresentationGroups: typeof getRepresentationGroups;
    deleteRepresentations: typeof deleteRepresentations;
    representationIds?: number[];
    updateRepresentationIdsToDelete: typeof updateRepresenIdsToDelete;
    setWorkGroupState: typeof setWorkGroupMaintenanceState;
    ipTransfer: typeof ipTransferRequest;
    roles?: string[];
    incrementTempID?: () => void;
    updateIPNames?: (ipNames: IName[]) => void;
    manualMergeIP: (fromIP: IInterestedParty,toIP:IInterestedParty,ipMaintenanceGeneralDataViewData: IRepertoireComponentDataItem, dataAction: IDataActionToolbar[], lookups: ILookupDictionary) => void;
    showMessage?: typeof showMessage;
    updatedScroll?: number;
    customer?:string;
    
}

interface IInterestedPartyMaintenancePageState {
    toolbarWidth: number;
    ipBaseNumber: string;
    activeHeaderSection?: string;
}
export type IInterestedPartyMaintenanceToolbarKeys = keyof IInterestedPartyMaintenanceToolbarProps;

export class IPMaintenancePage extends React.PureComponent<
    IInterestedPartyMaintenancePageProps,
    IInterestedPartyMaintenancePageState
> {
    toolbarParentRef;
    constructor(props: IInterestedPartyMaintenancePageProps) {
        super(props)
        this.state = {
            toolbarWidth: null,
            ipBaseNumber: ""
        };
        this.toolbarParentRef = React.createRef();
    }

    componentDidMount() {
        const { ipMaintenancePageUIConfiguration, getIPMaintenaceDisplaySettings } = this.props;
        if (!ipMaintenancePageUIConfiguration) {
            getIPMaintenaceDisplaySettings();
        }
    };


    saveAccount() {
        const { saveChanges, interestedParty, tabs, activeTab,lookupValues} = this.props;

        let representationIds = tabs[activeTab].interestedPartyMaintenanceState.representationIds;

        if (representationIds !== undefined && representationIds.length > 0) {
            this.deleteRepresentations(tabs[activeTab].interestedPartyMaintenanceState.interestedParty.ipBaseNumber, representationIds);
        }
        saveChanges(interestedParty.socIP, lookupValues, interestedParty.ipNames);
    }

    deleteRepresentations(ipBaseNumber: string, representationIds: number[]) {
        const { deleteRepresentations, activeTab } = this.props;

        let representations: IDeleteRepresentations = { ipBaseNumber: ipBaseNumber, representationIds: representationIds };

        deleteRepresentations(representations, activeTab);
    }

    deleteAction() {
        const { ipMaintenanceToolbarData, showYesNoPrompt, hideModal } = this.props;

        const title: string = ipMaintenanceToolbarData.fields.find(f => f.name === DELETE_IP_PROMPT_STATE_KEY).data;
        const props: IYesNoPromptViewModalProps = {
            yesCallback: () => { this.deleteAccount() },
            noCallback: () => { hideModal(); }
        }
        showYesNoPrompt(title, props);
    }

    deleteAccount() {
        const { deleteAccount, interestedParty, activeTab } = this.props;
        let baseNumber: string;
        let account: string;

        if (interestedParty.socIP && interestedParty.socIP.ipBaseNumber)
            baseNumber = interestedParty.socIP.ipBaseNumber
        else baseNumber = interestedParty.ipBaseNumber;


        if (interestedParty.socIP && interestedParty.socIP.accountNumber)
            account = interestedParty.socIP.accountNumber
        else account = "";


        deleteAccount(baseNumber, account, activeTab);
    }

    undoChanges = () => {
        const { undoIPChanges, interestedParty } = this.props;

        let ipBaseNumber = (interestedParty.socIP && interestedParty.socIP.ipBaseNumber) ? interestedParty.socIP.ipBaseNumber : interestedParty.ipBaseNumber;

        undoIPChanges(ipBaseNumber);
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    addRepresentation() {
        const { loadRepertoireFromPath } = this.props;
        const pathInfo: IRepertoirePathInfo = { submodule: 'Representation' };

        loadRepertoireFromPath(pathInfo, undefined);
    }

    onUpdateComponentFieldItems(fields: IRepertoireField[], componentName: string) {
        const { updateComponentFieldsItems, activeTab, tabs } = this.props;
        const dataActions = getDataAction("", IP_MAINTENANCE_TOOLBAR);
        updateComponentFieldsItems(fields, componentName, COMPONENT_INSTANCE_IPS, REPERTOIRE_SIDE_MENU_IPS_KEY, tabs, activeTab)
    }
    openAccordion(accordionName: string, componentName: string): void {
        const { openIPAccordion, updateUserPreferences, allResultsPerPage, defaultActiveAccordions } = this.props;
        openIPAccordion(accordionName, componentName);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, accordionName, true, componentName);
    }
    closeAccordion(accordionName: string, componentName: string): void {
        const { closeIPAccordion, updateUserPreferences, allResultsPerPage, defaultActiveAccordions } = this.props;
        closeIPAccordion(accordionName, componentName);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, accordionName, false, componentName);
    }
    openIpExtendedDetailsCRMInNewTab = () => {
        const { interestedParty } = this.props
        window.open(interestedParty.ipExtendedDetails);
    }

    mergeIP(ipIds: IInterestedParty[]) {
        const { manualMergeIP, interestedParty, lookupValues,ipMaintenanceGeneralDataViewData } = this.props;
        const dataActions = getDataAction(DataSource.Repertoire, IP_MAINTENANCE_TOOLBAR);

        manualMergeIP(ipIds[0],interestedParty,ipMaintenanceGeneralDataViewData, dataActions,lookupValues)
    }

    manualMergeIP(matchingWorkid: number, mergeOption: string) {
        const { manualMergeIP, interestedParty, lookupValues,ipMaintenanceGeneralDataViewData } = this.props;

        let mergeAction = ManualMergeOptions[mergeOption];
        const dataActions = getDataAction(DataSource.Repertoire, WORK_MAINTENANCE_TOOLBAR);

        manualMergeIP(interestedParty, interestedParty,ipMaintenanceGeneralDataViewData, dataActions,lookupValues)}
    

    displayContent(display: boolean) {
        if (display) {
            return (<div>There was a problem loading the page.</div>);
        }
        else {
            const {
                ipMaintenanceGeneralDataViewData,
                ipMaintenanceGridsViewData,
                ipMaintenanceAccordionViewData,
                ipMaintenanceToolbarData,
                ipMaintenanceSaveResultData,
                dataGridTableData,
                setChangesMade,
                updateInterestedPartyField,
                activeAccordion,
                addNewAccount,
                addTab,
                interestedParty,
                tabs,
                activeTab,
                sortIPFieldData,
                showModal,
                hideModal,
                ipMaintenanceTimeRangeSelectorViewData,
                ipAgrementsSearchState,
                updateIpAgreementsFilterStateField,
                ipRepresentationSearchState,
                updateIpRepresentationFilterStateField,
                filterIpAgreements,
                filterIpRepresentations,
                setEditableFields,
                updateComponentFieldsItems,
                editableFields,
                editableFieldsDataView,
                showUpdateFieldsModal,
                searchSocIPVersionHistory,
                workflowFieldsData,
                lookupValues,
                ipMaintenancePageUIConfiguration,
                createNewRepresentation,
                getRepresentationGroups,
                resetMessageBanner,
                updateRepresentationIdsToDelete,
                setWorkGroupState,
                ipTransfer,
                roles,
                incrementTempID,
                updateIPNames,
                showMessage,
                showYesNoPrompt,
                customer
            } = this.props;
            const isReadonly: boolean = tabs[activeTab].isReadonly;
            if (this.props.interestedParty) {
                const {
                    ipBaseNumber,
                    birthDate,
                    deathDate,
                    status,
                    type,
                    agencyID,
                    nameSocietyName,
                    socIP,
                    ipNames,
                    ipAgreements,
                    ipLicensingRepresentations,
                    mergeThisIP,
                    replacedAccount
                } = this.props.interestedParty

                const {
                    accountNumber,
                    firstName,
                    lastName,
                    accountType
                } = socIP ? socIP : {
                    accountNumber: '',
                    firstName: '',
                    lastName: '',
                    accountType: ''
                };
                const { updatedScroll } = this.props;

                const {
                    toolbarWidth
                } = this.state;

                const changesMade = tabs[activeTab].changesMade;

                const title = ipBaseNumber;
                
                return (
                    <div>
                        <div><span className="title">{title}</span></div>
                        <If condition={tabs[activeTab].versionNumber != null}>
                            <div><span>Version history number: {tabs[activeTab].versionNumber}</span></div>
                        </If>
                        <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                            <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                        </button>
                        <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                            <IPMaintenanceToolbar
                                scroll={updatedScroll}
                                toolbarWidth={toolbarWidth}
                                changesMade={changesMade}
                                saveResultData={ipMaintenanceSaveResultData}
                                toolbarData={ipMaintenanceToolbarData}
                                saveChanges={this.saveAccount.bind(this)}
                                deleteAccount={this.deleteAction.bind(this)}
                                resetMessageBanner={this.resetMessageBanner.bind(this)}
                                addNewAccount={() => addNewAccount(lookupValues, true, ipMaintenanceGeneralDataViewData)}
                                activeTab={activeTab}
                                tabs={tabs}
                                showYesNoPrompt={showYesNoPrompt}
                                hideModal={hideModal}
                                showModal={showModal}
                                interestedParty={interestedParty}
                                undoIPChanges={this.undoChanges.bind(this)}
                                searchVersionHistory={searchSocIPVersionHistory}
                                isReadonly={isReadonly}
                                clearModalSearchResults={clearModalSearchResults}
                                workflowFieldsData={workflowFieldsData}
                                addRepresentationTab={this.addRepresentation.bind(this)}
                                createNewRepresentation={createNewRepresentation}
                                lookupValues={lookupValues}
                                getRepresentationGroups={getRepresentationGroups}
                                ipTransfer={ipTransfer}
                                roles={roles}
                                openIpExtendedDetailsCRMInNewTab={this.openIpExtendedDetailsCRMInNewTab.bind(this)}
                                mergeIP={this.mergeIP.bind(this)}
                                showMessage={showMessage}
                            />

                            <If condition={tabs[activeTab].showAddRepresentationBanner === undefined ? false : tabs[activeTab].showAddRepresentationBanner}>
                                <div>
                                    <SaveResult
                                        componentInstance={"IPMaintenancePage"}
                                        saveSuccess={tabs[activeTab].addRepresentationSuccessful == null ? false : tabs[activeTab].addRepresentationSuccessful}
                                        saveResultData={ipMaintenanceSaveResultData}
                                        messages={tabs[activeTab].validationMessages}
                                        resetMessageBanner={this.resetMessageBanner.bind(this)}
                                        addRepresentationSuccessful={tabs[activeTab].addRepresentationSuccessful == null ? false : tabs[activeTab].addRepresentationSuccessful}
                                    />
                                </div>
                            </If>
                        </div>

                        <IPMaintenanceGeneralDataView
                            ipMaintenanceGeneralDataViewData={ipMaintenanceGeneralDataViewData}
                            ipBaseNumber={ipBaseNumber}
                            birthDate={birthDate}
                            deathDate={deathDate}
                            status={status}
                            societyName={nameSocietyName}
                            type={type}
                            agencyID={agencyID}
                            accountNumber={accountNumber}
                            lastName={lastName}
                            firstName={firstName}
                            socIP={socIP}
                            updateInterestedPartyField={updateInterestedPartyField}
                            changesMade={changesMade}
                            showUpdateFieldsModal={showUpdateFieldsModal}
                            editableFieldsDataView={editableFieldsDataView}
                            dataGridTableEditableFieldsData={dataGridTableData}
                            setEditableFields={setEditableFields}
                            updateComponentFieldsItems={updateComponentFieldsItems}
                            editableFields={editableFields}
                            activeTab={activeTab}
                            tabs={tabs}
                            hideModal={hideModal}
                            showModal={showModal}
                            isReadonly={isReadonly}
                            lookupValues={lookupValues}
                            roles={roles}
                            mergeThisIP={mergeThisIP}
                        />
                        <IPMaintenanceGridsView
                            ipNames={ipNames}
                            ipAgreements={ipAgreements}
                            ipLicensingRepresentations={ipLicensingRepresentations}
                            ipMergedAccounts={replacedAccount?replacedAccount:[]}
                            showModal={showModal}
                            activeAccordion={activeAccordion}
                            openIPAccordion={this.openAccordion.bind(this)}
                            closeIPAccordion={this.closeAccordion.bind(this)}
                            dataGridTableData={dataGridTableData}
                            ipMaintenanceGridsViewData={ipMaintenanceGridsViewData}
                            ipMaintenanceAccordionViewData={ipMaintenanceAccordionViewData}
                            addTab={addTab}
                            activeTab={activeTab}
                            tabs={tabs}
                            componentInstance={null}
                            updateInterestedPartyField={updateInterestedPartyField}
                            sortInterestedPartyFieldData={sortIPFieldData}
                            ipMaintenanceTimeRangeSelectorViewData={ipMaintenanceTimeRangeSelectorViewData}
                            agreementSearchState={ipAgrementsSearchState}
                            updateIpAgreementsFilterStateField={updateIpAgreementsFilterStateField}
                            representationSearchState={ipRepresentationSearchState}
                            updateIpRepresentationFilterStateField={updateIpRepresentationFilterStateField}
                            filterIpAgreements={filterIpAgreements}
                            filterIpRepresentations= {filterIpRepresentations}
                            isReadonly={isReadonly}
                            ipMaintenancePageUIConfiguration={ipMaintenancePageUIConfiguration}
                            lookupValues={lookupValues}
                            activeHeaderSection={tabs[activeTab].activeHeaderSection}
                            ipBaseNumber={ipBaseNumber}
                            updateRepresentationIdsToDelete={updateRepresentationIdsToDelete}
                            setWorkGroupState={setWorkGroupState}
                            incrementTempID={incrementTempID}
                            updateIPNames={updateIPNames}
                            showUpdateFieldsModal={showUpdateFieldsModal}
                            editableFieldsDataView={editableFieldsDataView}
                            dataGridTableEditableFieldsData={dataGridTableData}
                            setEditableFields={setEditableFields}
                            updateComponentFieldsItems={updateComponentFieldsItems}
                            editableFields={editableFields}
                            roles={roles}
                            manualMergeIP={this.manualMergeIP.bind(this)}
                            ipRepresentations={interestedParty.socIP.ipRepresentations}
                            customer={customer}
                            accountNumberValid={interestedParty.socIP.accountNumber?true:false}
                        />
                    </div>

                );
            }
            return <div>Loading...</div>;
        }
    }

    render() {
        const { displayRepresentationBoolean } = this.props
        return (<div>{this.displayContent(displayRepresentationBoolean)}</div>);
    }
}

