import * as React from 'react';
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "../../types/msalAuthConfig";
import { ILoginViewModel } from '../../types/ILoginViewModel';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { If } from '../../../core/components/If';
import { B2C_LOGIN_TYPE } from '../../Consts';
import { getB2CLoginConfigurationThunk } from '../../../redux/thunks/AccountThunks';


interface IB2CAuthenticatedProps {
    login: (viewModel: ILoginViewModel) => void;
    redirect?: any;
    isSignedIn: boolean;
}

export const B2CAuthenticated = ({ login, redirect, isSignedIn }: IB2CAuthenticatedProps) => {
    const { instance, accounts } = useMsal();
    const account = accounts[0]

    useEffect(() => {

        const username = account.name;


        let vm: ILoginViewModel = {
            email: null,
            password: null,
            rememberMe: true,
            loginType: B2C_LOGIN_TYPE,
            redirect: redirect,
            username: username
        }

        let scope =
        {
            scopes: [],
            extraScopesToConsent: [msalConfig.auth.scope]
        }

        let authority = msalConfig.auth.authority

        const interceptor = async (config) => {
            const url = config.url;
            if (url && (url.includes('http://') || url.includes('https://'))) {
                return config;
            }

            const response = await instance.acquireTokenSilent({
                ...scope,
                account,
                authority
            });

            const bearer = `Bearer ${response.idToken}`;
            config.headers.Authorization = bearer;

            return config;
        }
        axios.interceptors.request.use(interceptor);

        login(vm);
    }, []);

    return (
        <If condition={isSignedIn}>
            <Redirect to={'/'} />
        </If>
    );
}