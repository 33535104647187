import * as React from "react";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { hideModal, showYesNoPrompt } from "../../../redux/reducers/ModalReducer";
import { clearModalSearchResults, copyExistingProduct, nextWorkflowInSession } from "../../../redux/reducers/RepertoireReducer";
import { FormatFields } from "../../../redux/types/FormatFields";
import { IProductState } from "../../../redux/types/IProductState";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { IYesNoPromptViewModalProps } from "../../components/modalViews/yesNoPromptView/YesNoPromptView";
import ToolbarComponent from "../../components/toolBar/ToolBarComponent";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP } from "../../ConfigurationConsts";
import {
    ADD_METHOD_TOOLBAR, ADD_NEW_PRODUCT_STATE_KEY, ADD_TO_PACKAGES_TOOLBAR, ADD_TO_PACKAGE_KEY, ADD_TO_PACKAGE_PRODUCT_STATE_KEY, ALL_ROLES, AUTO_MATCH_PRODUCT_STATE_KEY, COPY_METHOD_TOOLBAR, COPY_PRODUCT_STATE_KEY, DELETE_METHOD_TOOLBAR, DELETE_PRODUCTS_ROLE, DELETE_PRODUCT_PROMPT_STATE_KEY, DELETE_PRODUCT_STATE_KEY, HISTORY_METHOD_TOOLBAR, MATCH_METHOD_TOOLBAR, POST_TO_REPERTOIRE_STATE_KEY, PRODUCT_MAINTENANCE_TOOLBAR, SAVE_CHANGES_STATE_KEY, SAVE_METHOD_TOOLBAR,
    UNDO_CHANGES_PRODUCT_STATE_KEY,
    UNDO_METHOD_TOOLBAR, UPDATE_PRODUCTS_ROLE, VERSION_HISTORY_STATE_KEY
} from "../../Consts";
import { SearchRequests } from "../../services/SearchRequests";
import { DataSource } from "../../types/DataSource";
import { IDataActionToolbar } from "../../types/IDataActionToolbar";
import { IWorkflowParams } from "../../types/IWorkFlowParams";
import { IWorkflowSearchResult } from "../../types/IWorkflowSearchResult";
import { SaveOption } from "../../types/SaveOption";
import { EntityType } from "../../types/EntityType";

export interface IProductMaintenanceToolbarProps {
    changesMade: boolean;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    resetMessageBanner: () => void;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData: IRepertoireComponentDataItem;
    product: IProductState;
    activeTab: number;
    lookupValues: ILookupDictionary;
    tabs: ITabReduxItem[];
    copyExistingProduct: typeof copyExistingProduct;
    addNewProduct: (lookups: ILookupDictionary, isNew?: boolean, productMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[]) => void;
    deleteProduct: () => void;
    saveChanges: (saveOption: SaveOption) => void;
    mergeProduct?: () => void;
    undoChanges: () => void;
    searchProductVersionHistory: (productCoreID: number, formats?: FormatFields[]) => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    dataSource: DataSource;
    isReadonly: boolean;
    productMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    workflowFieldsData: IRepertoireComponentDataItem;
    roles?: string[];
    isWorkflowSessionStarted?: boolean;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    saveWorkflow?: (workflowparams: IWorkflowParams) => void;
    isOpenForWorkflowSession?: boolean;
    skipWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    workflowSessionItems?: IWorkflowSearchResult[];
    currentWorkflowIndex?: number;
    dataActionList?: IDataActionToolbar[];
    refreshProductDetails?: () => void;
    completeWorkflowOnSave: (value: boolean) => void;
    cueSheetsEnabled?:boolean;
    cuesheetDatasource: any;
    addToPackages?:(userName:string,entityType:string,entityID:number)=>void;
    currentUser?: string;

}

export interface IProductMaintenanceToolbarState {
    dataAction: IDataActionToolbar[];
}

export type ProductMaintenanceToolbarKeys = keyof IProductMaintenanceToolbarProps;

class ProductMaintenanceToolbar extends React.Component<
    IProductMaintenanceToolbarProps,
    IProductMaintenanceToolbarState
> {

    constructor(props) {
        super(props);
        this.state = {
            dataAction: this.getDataAction()
        };
    }

    saveChangesAction(saveOption: SaveOption): void {
        const { saveChanges } = this.props;
        saveChanges(saveOption)
    }

    matchAction(product: IProductState): void {
        const { saveChanges } = this.props;
        saveChanges(SaveOption.SaveMatch);
    }

    deleteProductAction() {
        const { deleteProduct, toolbarData, showYesNoPrompt, hideModal } = this.props;
        const title: string = toolbarData.fields.find(f => f.name === DELETE_PRODUCT_PROMPT_STATE_KEY).data;
        const props: IYesNoPromptViewModalProps = {
            yesCallback: () => { deleteProduct(); hideModal(); },
            noCallback: () => { hideModal(); }
        }
        showYesNoPrompt(title, props);
    }

    searchVersionHistory = () => {
        const { searchProductVersionHistory, clearModalSearchResults, product } = this.props;
        clearModalSearchResults();
        const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP)
            .then(formats => {
                searchProductVersionHistory(product.productCoreID, formats);
            });
    };

    AddtoPackage= () => {
        const { addToPackages, product,currentUser} = this.props;
        addToPackages(currentUser,EntityType.Products,product.productCoreID);
    }

    getDeleteRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(DELETE_PRODUCTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getUpdateRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_PRODUCTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }  

    getDataAction() {
        const {
            copyExistingProduct,
            undoChanges,
            addNewProduct,
            toolbarData: { fields },
            productMaintenanceGeneralDataViewData,
            tabs,
            activeTab
        } = this.props;

        const actionList: IDataActionToolbar[] = [];

        const copy: IDataActionToolbar = {
            action: copyExistingProduct,
            component: PRODUCT_MAINTENANCE_TOOLBAR,
            method: COPY_METHOD_TOOLBAR,
            name: fields.find(copy => copy.name === COPY_PRODUCT_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Copy',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(copy);

        const undo: IDataActionToolbar = {
            action: undoChanges,
            component: PRODUCT_MAINTENANCE_TOOLBAR,
            method: UNDO_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === UNDO_CHANGES_PRODUCT_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Undo',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(undo);

        const addNewWorkLookUp = () => { addNewProduct(this.props.lookupValues, true, productMaintenanceGeneralDataViewData, tabs[activeTab].formatFields); }
        const add: IDataActionToolbar = {
            action: addNewWorkLookUp,
            component: PRODUCT_MAINTENANCE_TOOLBAR,
            method: ADD_METHOD_TOOLBAR,
            name: fields.find(add => add.name === ADD_NEW_PRODUCT_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--CircleAddition',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(add);

        const deleteProductAction: IDataActionToolbar = {
            action: this.deleteProductAction.bind(this),
            component: PRODUCT_MAINTENANCE_TOOLBAR,
            method: DELETE_METHOD_TOOLBAR,
            name: fields.find(del => del.name === DELETE_PRODUCT_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Delete',
            isReadOnly: this.getDeleteRoleDisabled() || this.getUpdateRoleDisabled()
        };
        actionList.push(deleteProductAction);

        const save: IDataActionToolbar = {
            action: this.saveChangesAction.bind(this),
            component: PRODUCT_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: '',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(save);

        const history: IDataActionToolbar = {
            action: this.searchVersionHistory,
            component: PRODUCT_MAINTENANCE_TOOLBAR,
            method: HISTORY_METHOD_TOOLBAR,
            name: fields.find(save => save.name === VERSION_HISTORY_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--History'
        };
        actionList.push(history);

        const autoMatchProduct: IDataActionToolbar = {
            action: this.matchAction.bind(this),
            component: PRODUCT_MAINTENANCE_TOOLBAR,
            method: MATCH_METHOD_TOOLBAR,
            name: fields.find(match => match.name === AUTO_MATCH_PRODUCT_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--TriggerAuto',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(autoMatchProduct);
        const addtoPackageProduct: IDataActionToolbar = {
            action: this.AddtoPackage,
            component: PRODUCT_MAINTENANCE_TOOLBAR,
            method: ADD_TO_PACKAGES_TOOLBAR,
            name: fields.find(addtopackage => addtopackage.name === ADD_TO_PACKAGE_PRODUCT_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--AppIconDefaultAdd',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(addtoPackageProduct);
        return actionList;

    }

    render() {
        const {
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            product,
            activeTab,
            tabs,
            toolbarData,
            dataSource,
            isReadonly,
            workflowFieldsData,
            isWorkflowSessionStarted,
            nextWorkflowItem,
            cancelWorkflowSession,
            skipWorkflowItem,
            workflowSessionItems,
            currentWorkflowIndex,
            isOpenForWorkflowSession,
            saveWorkflow,
            refreshProductDetails,
            roles,
            completeWorkflowOnSave,
            cueSheetsEnabled,
            cuesheetDatasource,
            addToPackages
        } = this.props;

        let {
            changesMade,
        } = this.props;

        changesMade = !this.getUpdateRoleDisabled() && changesMade

        let { dataAction } = { ...this.state };

        if (dataSource !== DataSource.Repertoire) {
            dataAction = dataAction.filter(action => action.method !== HISTORY_METHOD_TOOLBAR)
        }
        else {
            dataAction = dataAction.filter(action => action.method !== MATCH_METHOD_TOOLBAR)
        }



        return (
            <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    changesMade={changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    resetMessageBanner={resetMessageBanner}
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    product={product}
                    activeTab={activeTab}
                    tabs={tabs}
                    toolbarData={toolbarData}
                    dataActionToolbar={dataAction}
                    component={PRODUCT_MAINTENANCE_TOOLBAR}
                    isNew={tabs[activeTab].productMaintenanceState.isNew}
                    workflowFieldsData={workflowFieldsData}
                    dataSource={dataSource}
                    isWorkflowSessionStarted={isWorkflowSessionStarted}
                    nextWorkflowItem={nextWorkflowItem}
                    skipWorkflowItem={skipWorkflowItem}
                    cancelWorkflowSession={cancelWorkflowSession}
                    workflowSessionItems={workflowSessionItems}
                    currentWorkflowIndex={currentWorkflowIndex}
                    isOpenForWorkflowSession={isOpenForWorkflowSession}
                    saveWorkflow={saveWorkflow}
                    refreshProductDetails={refreshProductDetails}
                    roles={roles}
                    completeWorkflowOnSave={completeWorkflowOnSave}
                    cueSheetsEnabled={cueSheetsEnabled}
                    cuesheetDatasource = {cuesheetDatasource}
                    addToPackages={addToPackages}
                />
            </div>
        );
    }
}

export default ProductMaintenanceToolbar;
