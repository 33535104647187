import { IAgreementAdminIP } from "../types/IAgreementAdminIP";
import { IAgreementAdminIPDataItems } from "../types/IAgreementAdminIPDataItems";
import {
    READ_ONLY_INPUT,
    TEXT_INPUT,
    DROPDOWN_INPUT,
    DATE_INPUT,
    BOOLEAN_READONLY_INPUT,

    INTEGER_INPUT,
    SOURCE_STATE_KEY,
    TYPE_STATE_KEY,
    SPECIFIC_AGREEMENT_TYPE_STATE_KEY,
    APPLICABLE_WORKS_TYPE_STATE_KEY,
    SET_TYPE_GROUP_STATE_KEY,
    AGREEMENT_CATEGORY_STATE_KEY,
    ASSIGNED_SET_TYPES_STATE_KEY,
    AGREEMENT_SOURCE_TYPE_STATE_KEY
} from "../Consts";
import { IAgreementNumber } from "../types/IAgreementNumber";
import { IAgreementNumberDataItems } from "../types/IAgreementNumberDataItems";
import { IAgreementWork } from "../types/IAgreementWork";
import { IAgreementWorkDataItems } from "../types/IAgreementWorkDataItems";
import { IAgreementProductWork } from "../types/IAgreementProductWork";
import { IAgreementProductWorkDataItems } from "../types/IAgreementProductWorkDataItems";
import { IAgreement } from "../types/IAgreement";
import { IAgreementState } from "../../redux/types/IAgreementState";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { DataSource } from "../types/DataSource";
import { getLookupDefault, getLookupDefaultMultiSelect, getLookupDefaultV2, getLookupValues } from "../../lookup/services/LookupHelpers";
import { IAgreementDuplicate } from "../types/IAgreementDuplicate";
import { IAgreementDuplicateDataItems } from "../types/IAgreementDuplicateDataItems";
import {
    AGREEMENT_CATEGORY_LOOKUP,
    AGREEMENT_SOURCE_LOOKUP,
    AGREEMENT_TYPE_LOOKUP,
    APPLICABLE_WORKS_LOOKUP,
    SET_TYPE_GROUP_LOOKUP,
    SET_TYPE_LOOKUP,
    SPECIFIC_AGREEMENT_TYPE_LOOKUP,
    TERRITORY_LOOKUP,
    WORK_SOURCE_LOOKUP,
    WORK_SOURCE_TYPE_LOOKUP
} from "../../lookup/Consts";
import { IComment } from "../types/IComment";
import { IAgreementNote } from "../types/IAgreementNote";
import { IStoragePathItemData } from "../../dataingestion/types/IStoragePathItemData";
import { IAttachedFile } from "../../redux/types/IAttachedFile";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IWorkflow } from "../types/IWorkflow";
import { IAgreementShareSet } from "../types/IAgreementShareSet";
import dayjs from "dayjs";
import { IAgreementSourceIP } from "../types/IAgreementSourceIP";
import { ISourceIP } from "../types/ISourceIP";
export class AgreementDetailsMapper {
    public static mapAdminIPToState(adminIP: IAgreementAdminIP[]): IAgreementAdminIPDataItems[] {
        if (adminIP) {
            const stateAdminIP: IAgreementAdminIPDataItems[] = [];
            adminIP.map(a => {
                let stateIP = {
                    agreementAdminIPID: a.agreementAdminIPID,
                    administeredByIPBaseNumber: { value: a.administeredByIPBaseNumber, inputType: READ_ONLY_INPUT },
                    administeredName: { value: a.administeredName, inputType: READ_ONLY_INPUT },
                    administeredIP: { value: a.administeredIP, inputType: READ_ONLY_INPUT },
                    accountNumber: { value: a.accountNumber, inputType: READ_ONLY_INPUT },
                    performingShare: { value: a.performingShare, inputType: INTEGER_INPUT },
                    mechanicalShare: { value: a.mechanicalShare, inputType: INTEGER_INPUT },
                    agreementID: a.agreementID,
                    ref1: a.ref1,
                    societyAffiliation: { value: a.societyAffiliation, inputType: READ_ONLY_INPUT },
                    performingShareToDisplay: { value: a.performingShare.toString(), inputType: INTEGER_INPUT },
                    mechanicalShareToDisplay: { value: a.mechanicalShare.toString(), inputType: INTEGER_INPUT },
                }
                if (a.performingShare != null)
                    stateIP.performingShareToDisplay = { value: a.performingShare.toString(), inputType: INTEGER_INPUT }
                if (a.mechanicalShare != null)
                    stateIP.mechanicalShareToDisplay = { value: a.mechanicalShare.toString(), inputType: INTEGER_INPUT }
                stateAdminIP.push(stateIP)
            });
            return stateAdminIP;
        }
        else {
            return [];
        }
    }

    public static mapStateToAdminIP(stateAdminIP: IAgreementAdminIPDataItems[]): IAgreementAdminIP[] {
        if (stateAdminIP) {
            const adminIP: IAgreementAdminIP[] = [];
            stateAdminIP.map(a =>
                adminIP.push({
                    agreementAdminIPID: a.agreementAdminIPID,
                    administeredByIPBaseNumber: a.administeredByIPBaseNumber.value,
                    administeredName: a.administeredName.value,
                    administeredIP: a.administeredIP.value,
                    accountNumber: a.accountNumber.value,
                    performingShare: Number(a.performingShare.value),
                    mechanicalShare: Number(a.mechanicalShare.value),
                    agreementID: a.agreementID,
                    ref1: a.ref1,
                    societyAffiliation: a.societyAffiliation? a.societyAffiliation.value : null
                }));

            return adminIP;
        }
        else {
            return [];
        }
    }

    public static mapNumberToState(number: IAgreementNumber[]): IAgreementNumberDataItems[] {
        if (number) {
            const stateNumber: IAgreementNumberDataItems[] = [];
            number.map(n =>
                stateNumber.push({
                    agreementNumberID: n.agreementNumberID,
                    agreementID: n.agreementID,
                    numberType: { value: n.numberType, inputType: DROPDOWN_INPUT },
                    number: { value: n.number, inputType: TEXT_INPUT },
                }));

            return stateNumber;
        }
        else {
            return [];
        }
    }

    public static mapStateToNumber(stateNumber: IAgreementNumberDataItems[]): IAgreementNumber[] {
        if (stateNumber) {
            const number: IAgreementNumber[] = [];
            stateNumber.map(n =>
                number.push({
                    agreementNumberID: n.agreementNumberID,
                    agreementID: n.agreementID,
                    numberType: n.numberType.value,
                    number: n.number.value,
                }));

            return number;
        }
        else {
            return [];
        }
    }

    public static mapAgreementSourceIPToState(agreementSourceIP: IAgreementSourceIP): ISourceIP {
        if (agreementSourceIP) {
            const agreementSource: ISourceIP = {
                sourceIPID: agreementSourceIP.agreementSourceIPID,
                ipiNameNumber: agreementSourceIP.ipiNameNumber,
                fullName: { value: agreementSourceIP.fullName, inputType: TEXT_INPUT }
            };
            return agreementSource;
        }
        else {
            return null;
        }
    }

    public static mapSourceIPToAgreementSourceIP(sourceIP: ISourceIP): IAgreementSourceIP {
        if (sourceIP) {
            const agreementSource: IAgreementSourceIP = {
                agreementSourceIPID: sourceIP.sourceIPID,
                ipiNameNumber: sourceIP.ipiNameNumber,
                fullName: sourceIP.fullName.value
            };
            return agreementSource;
        }
        else {
            return null;
        }
    }

    public static mapWorkToState(work: IAgreementWork[], applicableWorksType: string): IAgreementWorkDataItems[] {
        if (work) {
            const stateWork: IAgreementWorkDataItems[] = [];
            work.map(w =>
                stateWork.push({
                    agreementWorkID: w.agreementWorkID,
                    type: { value: applicableWorksType, inputType: DROPDOWN_INPUT },
                    workName: { value: w.workName, inputType: READ_ONLY_INPUT },
                    workNumber: { value: w.workNumber, inputType: READ_ONLY_INPUT },
                    workID: w.workID,
                    agreementID: w.agreementID,
                    exclusionUntil: { value: w.exclusionUntil ? w.exclusionUntil.toString() : null, inputType: DROPDOWN_INPUT },
                    excludedUntilDate: { value: w.excludedUntilDate, inputType: DATE_INPUT },
                    recipientWorkNumber: { value: w.recipientWorkNumber ? w.recipientWorkNumber.toString() : null, inputType: TEXT_INPUT },
                    recipientWorkNumberOwner: { value: w.recipientWorkNumberOwner ? w.recipientWorkNumberOwner.toString() : null, inputType: TEXT_INPUT },
                    useDefaultRetention: { value: w.useDefaultRetention ? w.useDefaultRetention.toString() : null, inputType: DROPDOWN_INPUT },
                    worksRetainedUntil: { value: w.worksRetainedUntil ? w.worksRetainedUntil.toString() : null, inputType: DROPDOWN_INPUT },
                    workRetentionEndDate: { value: w.workRetentionEndDate, inputType: DATE_INPUT },
                    useDefaultCollection: { value: w.useDefaultCollection ? w.useDefaultCollection.toString() : null, inputType: DROPDOWN_INPUT },
                    workCollectionUntil: { value: w.workCollectionUntil ? w.workCollectionUntil.toString() : null, inputType: DROPDOWN_INPUT },
                    workCollectionEndDate: { value: w.workCollectionEndDate, inputType: DATE_INPUT }
                }));

            return stateWork;
        }
        else {
            return [];
        }
    }

    public static mapStateToWork(stateWork: IAgreementWorkDataItems[]): IAgreementWork[] {
        if (stateWork) {
            const work: IAgreementWork[] = [];
            stateWork.map(w =>
                work.push({
                    agreementWorkID: w.agreementWorkID,
                    type: w.type.value,
                    workName: w.workName.value,
                    workNumber: w.workNumber.value,
                    workID: w.workID,
                    agreementID: w.agreementID,
                    exclusionUntil: w.exclusionUntil ? w.exclusionUntil.value : null,
                    excludedUntilDate: w.excludedUntilDate ? new Date(w.excludedUntilDate.value) : null,
                    recipientWorkNumber: null,
                    recipientWorkNumberOwner: null,
                    useDefaultRetention: null,
                    worksRetainedUntil: w.worksRetainedUntil ? w.worksRetainedUntil.value : null,
                    workRetentionEndDate: w.workRetentionEndDate ? new Date(w.workRetentionEndDate.value) : null,
                    useDefaultCollection: w.useDefaultCollection ? w.useDefaultCollection.value : null,
                    workCollectionUntil: w.workCollectionUntil ? w.workCollectionUntil.value : null,
                    workCollectionEndDate: w.workCollectionEndDate ? new Date(w.workCollectionEndDate.value) : null
                }));

            return work;
        }
        else {
            return [];
        }
    }

    public static mapCarveOutToState(carveOut: IAgreementProductWork[]): IAgreementProductWorkDataItems[] {
        if (carveOut) {
            const stateCarveOut: IAgreementProductWorkDataItems[] = [];
            carveOut.map(p =>
                stateCarveOut.push({
                    agreementProductWorkID: p.agreementProductWorkID,
                    productName: { value: p.productName, inputType: READ_ONLY_INPUT },
                    productNumber: { value: p.productNumber, inputType: READ_ONLY_INPUT },
                    productId: p.productId,
                    workName: { value: p.workName, inputType: DROPDOWN_INPUT },
                    workNumber: { value: p.workNumber, inputType: READ_ONLY_INPUT },
                    workId: p.workId,
                    agreementID: p.agreementID,
                    productWorks: p.productWorks,
                }));

            return stateCarveOut;
        }
        else {
            return [];
        }
    }

    public static mapStateToCarveOut(stateCarveOut: IAgreementProductWorkDataItems[]): IAgreementProductWork[] {

        if (stateCarveOut) {
            const carveOut: IAgreementProductWork[] = [];
            stateCarveOut.map(p =>
                carveOut.push({
                    agreementProductWorkID: p.agreementProductWorkID,
                    productName: p.productName.value,
                    productNumber: p.productNumber.value,
                    productId: p.productId,
                    workName: p.workName.value,
                    workNumber: p.workNumber.value,
                    workId: p.productWorks ? p.productWorks.find(w => w.title.toUpperCase() === p.workName.value?.toUpperCase())?.workCode : null,
                    agreementID: p.agreementID,
                    productWorks: p.productWorks
                }));

            return carveOut;
        }
        else {
            return [];
        }
    }

    public static mapDuplicateToState(duplicate: IAgreementDuplicate[]): IAgreementDuplicateDataItems[] {
        if (duplicate) {
            const stateDuplicate: IAgreementDuplicateDataItems[] = [];
            duplicate.map(n =>
                stateDuplicate.push({
                    agreementDuplicateID: n.agreementDuplicateID,
                    intrayAgreementID: { value: n.intrayAgreementID.toString(), inputType: READ_ONLY_INPUT },
                    repertoireAgreementID: { value: n.repertoireAgreementID.toString(), inputType: READ_ONLY_INPUT },
                    isDefinite: { value: n.isDefinite, inputType: BOOLEAN_READONLY_INPUT },
                }));

            return stateDuplicate;
        }
        else {
            return [];
        }
    }

    public static mapStateToDuplicate(stateDuplicate: IAgreementDuplicateDataItems[]): IAgreementDuplicate[] {
        if (stateDuplicate) {
            const duplicate: IAgreementDuplicate[] = [];
            stateDuplicate.map(n =>
                duplicate.push({
                    agreementDuplicateID: n.agreementDuplicateID,
                    intrayAgreementID: Number(n.intrayAgreementID.value),
                    repertoireAgreementID: Number(n.repertoireAgreementID.value),
                    isDefinite: n.isDefinite.value
                }));

            return duplicate;
        }
        else {
            return [];
        }
    }

    public static mapCommentsToAgreementNotes(comments: IComment[]): IAgreementNote[] {
        if (comments) {
            const agremmentNotes: IAgreementNote[] = [];
            comments.map(comment => agremmentNotes.push({
                notesID: comment.notesID,
                username: comment.user?.value,
                lastModifiedDate: null,
                subject: comment.subject.value,
                noteText: comment.comment.value,
                isModified: comment.isModified,
                commentCategory: comment.commentCategory.value,
            }));
            return agremmentNotes;
        }
        else {
            return [];
        }
    }

    public static mapAgreementNotesToComments(notes: IAgreementNote[]): IComment[] {
        if (notes) {
            const comments: IComment[] = [];
            notes.map(n => {
                comments.push({
                    notesID: n.notesID,
                    user: { value: n.username, inputType: "" },
                    date: { value: n.lastModifiedDate, inputType: "" },
                    subject: { value: n.subject, inputType: TEXT_INPUT },
                    comment: { value: n.noteText, inputType: TEXT_INPUT },
                    isModified: n.isModified,
                    commentCategory: { value: n.commentCategory, inputType: DROPDOWN_INPUT },
                })
            });
            return comments;
        }
        else {
            return [];
        }
    }

    public static mapAgreementToState(agreement: IAgreement): IAgreementState {
        if (!agreement)
            return null;

        const {
            agreementID,
            dataSource,
            isDeleted,
            lastModifiedDate,
            sourceID,
            ipBaseNumber,
            type,
            specificWorks,
            agreementSourceType,
            specificAgreementType,
            applicableWorksType,
            source,
            maintained,
            effectiveFrom,
            effectiveTo,
            effectiveNotifiedTo,
            effectiveNotifiedFrom,
            creationDate,
            territory,
            agreementCategory,
            setTypeGroup,
            salesOrManufacture,
            agreementSignatureDate,
            bulkTransferWorks,
            priorRoyalties,
            expectedTermination,
            expectedTerminationText,
            postTermRetentionPeriod,
            postTermCollectionPeriod,
            submitterAssignorIPCode,
            libraryMusic,
            recordingPrefix,
            worksRetained,
            defaultRetainedUntil,
            defaultRetentionEndDate,
            defaultCollectionUntil,
            defaultCollectionPeriodEndDate,
            licensedDirect,
            ipName,
            ipNameNumber,
            agreementShareSet,
            agreementRegion,
            agreementUsagePools,
            agreementDuplicate,
            notes,
            agreementTerritory,
            attachedFile,
            agreementBatchID,
            versionNumber,
            showCommentSubjectAsDropdown,
            worldExcluding,
            workflows,
            status,
            societyAffiliation,
            agreementAdminIP
        } = agreement;

        const files: IStoragePathItemData[] = [];
        if (attachedFile) {
            attachedFile.map(file => {
                files.push(
                    {
                        fullName: `repertoireAttachments/agreement/${file.guid}`,
                        inProgress: false,
                        isDirectory: false,
                        isDirty: false,
                        isEditing: false,
                        lastModified: file.lastModifiedDate.toString(),
                        sources: [],
                        metadata: undefined,
                        name: file.fileName,
                        pools: [],
                        formats: [],
                        distributions: [],
                        validationError: undefined,
                        attachmentTypeID: file.attachmentTypeID,
                        attachmentTypeCode: file.attachmentTypeCode,
                    }
                )
            })
        }

        const agreementSourceIP: ISourceIP = AgreementDetailsMapper.mapAgreementSourceIPToState(agreement.agreementSourceIP);

        const agreementState: IAgreementState = {
            agreementID,
            dataSource,
            isDeleted,
            lastModifiedDate,
            sourceID,
            ipBaseNumber,
            type,
            specificWorks,
            agreementSourceType,
            specificAgreementType,
            applicableWorksType,
            source,
            maintained,
            effectiveFrom,
            effectiveTo,
            effectiveNotifiedTo,
            effectiveNotifiedFrom,
            creationDate,
            territory,
            agreementCategory,
            setTypeGroup,
            salesOrManufacture,
            agreementSignatureDate,
            bulkTransferWorks,
            priorRoyalties,
            expectedTermination,
            expectedTerminationText,
            postTermRetentionPeriod,
            postTermCollectionPeriod,
            submitterAssignorIPCode,
            libraryMusic,
            recordingPrefix,
            worksRetained,
            defaultRetainedUntil,
            defaultRetentionEndDate,
            defaultCollectionUntil,
            defaultCollectionPeriodEndDate,
            licensedDirect,
            ipName,
            ipNameNumber,
            agreementShareSet,
            agreementRegion,
            agreementUsagePools,
            agreementAdminIP: AgreementDetailsMapper.mapAdminIPToState(agreement.agreementAdminIP),
            agreementNumbers: AgreementDetailsMapper.mapNumberToState(agreement.agreementNumber),
            agreementWorks: AgreementDetailsMapper.mapWorkToState(agreement.agreementWork, agreement.applicableWorksType),
            agreementProductWorks: AgreementDetailsMapper.mapCarveOutToState(agreement.agreementProductWorks),
            agreementDuplicate: AgreementDetailsMapper.mapDuplicateToState(agreement.agreementDuplicate),
            comments: AgreementDetailsMapper.mapAgreementNotesToComments(agreement.notes),
            agreementTerritory,
            files,
            agreementBatchID,
            versionNumber,
            showCommentSubjectAsDropdown,
            workflows: AgreementDetailsMapper.mapAgreementWorkflowToWorkflow(agreement.workflows),
            agreementSourceIP,
            worldExcluding,
            status,
            societyAffiliation
        }

        return agreementState;
    }

    public static mapStateToAgreement(agreementState: IAgreementState): IAgreement {
        const {
            agreementID,
            dataSource,
            isDeleted,
            lastModifiedDate,
            sourceID,
            ipBaseNumber,
            type,
            specificWorks,
            agreementSourceType,
            specificAgreementType,
            applicableWorksType,
            source,
            maintained,
            effectiveFrom,
            effectiveTo,
            effectiveNotifiedTo,
            effectiveNotifiedFrom,
            creationDate,
            territory,
            agreementCategory,
            setTypeGroup,
            salesOrManufacture,
            agreementSignatureDate,
            bulkTransferWorks,
            priorRoyalties,
            expectedTermination,
            expectedTerminationText,
            postTermRetentionPeriod,
            postTermCollectionPeriod,
            submitterAssignorIPCode,
            libraryMusic,
            recordingPrefix,
            worksRetained,
            defaultRetainedUntil,
            defaultRetentionEndDate,
            defaultCollectionUntil,
            defaultCollectionPeriodEndDate,
            licensedDirect,
            ipName,
            ipNameNumber,
            agreementShareSet,
            agreementRegion,
            agreementUsagePools,
            agreementDuplicate,
            comments,
            files,
            agreementBatchID,
            versionNumber,
            showCommentSubjectAsDropdown,
            status,
            societyAffiliation,
            agreementSourceIP,
            agreementTerritory,
            worldExcluding
        } = agreementState;

        const attachedFiles: IAttachedFile[] = [];

        if (files) {
            files.map(
                file => {
                    attachedFiles.push({
                        fileName: file.name,
                        agreementId: agreementID,
                        lastModifiedDate: new Date(file.lastModified),
                        attachmentTypeID: file.attachmentTypeID,
                        attachmentTypeCode: file.attachmentTypeCode,
                        guid: file.fullName.split('/').pop()
                    })
                }
            )
        }

        const agreement: IAgreement = {
            agreementID,
            dataSource,
            isDeleted,
            lastModifiedDate,
            sourceID,
            ipBaseNumber,
            agreementCategory,
            type,
            specificWorks,
            agreementSourceType,
            specificAgreementType,
            applicableWorksType,
            source,
            maintained,
            effectiveFrom,
            effectiveTo,
            effectiveNotifiedTo,
            effectiveNotifiedFrom,
            creationDate,
            territory,
            setTypeGroup: null,
            salesOrManufacture,
            agreementSignatureDate,
            bulkTransferWorks,
            priorRoyalties,
            expectedTermination,
            expectedTerminationText,
            postTermRetentionPeriod,
            postTermCollectionPeriod,
            submitterAssignorIPCode,
            libraryMusic,
            recordingPrefix,
            worksRetained,
            defaultRetainedUntil,
            defaultRetentionEndDate,
            defaultCollectionUntil,
            defaultCollectionPeriodEndDate,
            licensedDirect,
            ipName,
            ipNameNumber,
            agreementShareSet,
            agreementRegion,
            agreementTerritory,
            agreementUsagePools,
            status,
            societyAffiliation,
            agreementAdminIP: AgreementDetailsMapper.mapStateToAdminIP(agreementState.agreementAdminIP),
            agreementNumber: AgreementDetailsMapper.mapStateToNumber(agreementState.agreementNumbers),
            agreementWork: AgreementDetailsMapper.mapStateToWork(agreementState.agreementWorks),
            agreementProductWorks: AgreementDetailsMapper.mapStateToCarveOut(agreementState.agreementProductWorks),
            agreementDuplicate: AgreementDetailsMapper.mapStateToDuplicate(agreementState.agreementDuplicate),
            notes: AgreementDetailsMapper.mapCommentsToAgreementNotes(agreementState.comments),
            attachedFile: attachedFiles,
            agreementBatchID: agreementBatchID ? Number(agreementBatchID) : null,
            versionNumber,
            showCommentSubjectAsDropdown,
            workflows: AgreementDetailsMapper.mapWorkflowToAgreementWorkflow(agreementState.workflows),
            agreementSourceIP: AgreementDetailsMapper.mapSourceIPToAgreementSourceIP(agreementSourceIP),
            worldExcluding
        }

        return agreement;
    }

    public static createNewAgreement(lookups: ILookupDictionary, agreementMaintenanceGeneralDataViewData: IRepertoireComponentDataItem): IAgreement {
        const agreement: IAgreement = {
            agreementID: 0,
            dataSource: DataSource.Intray,
            isDeleted: false,
            sourceID: 0,
            source: getLookupDefault(AGREEMENT_SOURCE_LOOKUP, lookups),
            ipBaseNumber: null,
            type: getLookupDefaultV2(TYPE_STATE_KEY, AGREEMENT_TYPE_LOOKUP, lookups, agreementMaintenanceGeneralDataViewData),
            specificWorks: null,
            status: null,
            societyAffiliation: null,
            agreementSourceType: getLookupDefaultV2(AGREEMENT_SOURCE_TYPE_STATE_KEY, WORK_SOURCE_TYPE_LOOKUP, lookups, agreementMaintenanceGeneralDataViewData),
            specificAgreementType: getLookupDefaultV2(SPECIFIC_AGREEMENT_TYPE_STATE_KEY, SPECIFIC_AGREEMENT_TYPE_LOOKUP, lookups, agreementMaintenanceGeneralDataViewData),
            applicableWorksType: getLookupDefaultV2(APPLICABLE_WORKS_TYPE_STATE_KEY, APPLICABLE_WORKS_LOOKUP, lookups, agreementMaintenanceGeneralDataViewData),
            maintained: false,
            effectiveFrom: null,
            effectiveTo: null,
            effectiveNotifiedTo: null,
            effectiveNotifiedFrom: null,
            creationDate: null,
            territory: null,
            assignedSetTypes: getLookupDefaultMultiSelect(lookups, SET_TYPE_LOOKUP, ASSIGNED_SET_TYPES_STATE_KEY, agreementMaintenanceGeneralDataViewData),
            agreementCategory: getLookupDefaultV2(AGREEMENT_CATEGORY_STATE_KEY, AGREEMENT_CATEGORY_LOOKUP, lookups, agreementMaintenanceGeneralDataViewData),
            setTypeGroup: getLookupDefaultV2(SET_TYPE_GROUP_STATE_KEY, SET_TYPE_GROUP_LOOKUP, lookups, agreementMaintenanceGeneralDataViewData),
            salesOrManufacture: null,
            agreementSignatureDate: null,
            bulkTransferWorks: false,
            priorRoyalties: false,
            expectedTermination: null,
            expectedTerminationText: null,
            postTermRetentionPeriod: false,
            postTermCollectionPeriod: false,
            submitterAssignorIPCode: null,
            libraryMusic: false,
            recordingPrefix: null,
            worksRetained: null,
            defaultRetainedUntil: null,
            defaultRetentionEndDate: null,
            defaultCollectionUntil: null,
            defaultCollectionPeriodEndDate: null,
            licensedDirect: false,
            ipName: null,
            ipNameNumber: null,
            agreementUsagePools: [],
            agreementShareSet: [],
            agreementRegion: [],
            agreementAdminIP: [],
            agreementNumber: [],
            agreementWork: [],
            agreementProductWorks: [],
            agreementDuplicate: [],
            notes: [],
            attachedFile: [],
            versionNumber: 0,
            showCommentSubjectAsDropdown: false,
            workflows: [],
            worldExcluding: false
        }

        return agreement;
    }

    public static createNewAgreementState(lookups: ILookupDictionary, agreementMaintenanceGeneralDataViewData: IRepertoireComponentDataItem, memberIP?: IAgreementSourceIP): IAgreementState {
        let newAgreement = AgreementDetailsMapper.createNewAgreement(lookups, agreementMaintenanceGeneralDataViewData);
        if (memberIP) {
            newAgreement.agreementSourceIP = memberIP;
            newAgreement.source = getLookupDefaultV2(SOURCE_STATE_KEY, AGREEMENT_SOURCE_LOOKUP, lookups, agreementMaintenanceGeneralDataViewData)
        }
        return AgreementDetailsMapper.mapAgreementToState(newAgreement);
    }

    public static getAgreementTitle(agreement: IAgreement): string {
        if (agreement.agreementID === 0) {
            return 'New Agreement';
        } else {
            return 'Agreement: ' + agreement.agreementID;
        }
    }


    public static mapAgreementWorkflowToWorkflow(workflow: IWorkflow[]): IWorkflow[] {
        if (workflow) {
            const newWorklows: IWorkflow[] = [];
            workflow.map(a =>
                newWorklows.push({
                    id: a.id,
                    entityType: a.entityType,
                    status: a.status,
                    workflowType: a.workflowType,
                    entityID: a.entityID,
                    setType: a.setType,
                    assignedTo: a.assignedTo,
                    dateCreated: a.dateCreated,
                    dateLastUpdated: a.dateLastUpdated,
                    references: a.references,
                    values: a.values,
                    actions: a.actions,
                    errorMessages: a.errorMessages,
                    rejectReason: a.rejectReason
                }));
            return newWorklows;

        }
        else {
            return [];
        }
    }

    public static mapWorkflowToAgreementWorkflow(workflow: IWorkflow[]): IWorkflow[] {
        if (workflow) {
            const newWorklows: IWorkflow[] = [];
            workflow.map(a =>
                newWorklows.push({
                    id: a.id,
                    entityType: a.entityType,
                    status: a.status,
                    workflowType: a.workflowType,
                    entityID: a.entityID,
                    setType: a.setType,
                    assignedTo: a.assignedTo,
                    dateCreated: a.dateCreated,
                    dateLastUpdated: a.dateLastUpdated,
                    references: a.references,
                    values: a.values,
                    actions: a.actions,
                    errorMessages: a.errorMessages,
                    rejectReason: a.rejectReason
                }));
            return newWorklows;

        }
        else {
            return [];
        }
    }
    public static mapMembersStateToAgreement(agreementState: IAgreementState, lookups: ILookupDictionary, defaultTerritories?: any): IAgreement {
        const {
            agreementID,
            dataSource,
            isDeleted,
            lastModifiedDate,
            sourceID,
            ipBaseNumber,
            type,
            specificWorks,
            agreementSourceType,
            specificAgreementType,
            applicableWorksType,
            source,
            maintained,
            effectiveFrom,
            effectiveTo,
            effectiveNotifiedTo,
            effectiveNotifiedFrom,
            creationDate,
            territory,
            agreementCategory,
            setTypeGroup,
            salesOrManufacture,
            agreementSignatureDate,
            bulkTransferWorks,
            priorRoyalties,
            expectedTermination,
            expectedTerminationText,
            postTermRetentionPeriod,
            postTermCollectionPeriod,
            submitterAssignorIPCode,
            libraryMusic,
            recordingPrefix,
            worksRetained,
            defaultRetainedUntil,
            defaultRetentionEndDate,
            defaultCollectionUntil,
            defaultCollectionPeriodEndDate,
            licensedDirect,
            ipName,
            ipNameNumber,
            agreementShareSet,
            agreementRegion,
            agreementUsagePools,
            agreementDuplicate,
            comments,
            files,
            agreementBatchID,
            versionNumber,
            showCommentSubjectAsDropdown,
            agreementAdminIP,
            status,
            assignees,
            agreementSourceIP,
            agreementTerritory,
            worldExcluding,
            societyAffiliation
        } = agreementState;

        const attachedFiles: IAttachedFile[] = [];

        if (files) {
            files.map(
                file => {
                    attachedFiles.push({
                        fileName: file.name,
                        agreementId: agreementID,
                        lastModifiedDate: new Date(file.lastModified),
                        attachmentTypeID: file.attachmentTypeID,
                        attachmentTypeCode: file.attachmentTypeCode,
                        guid: file.fullName.split('/').pop()
                    })
                }
            )
        }
        const getAgreementTerritories = (territories,agreementID) => {
            if (!territories) {
                const lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookups);
                let countriesOptions = [];
                let regionOptions = [];
                let updatedTerritoryCodes = [];
                let updatedAgreementTerritories = [];

                if (lookupValsTerritories) {
                    lookupValsTerritories.filter(item => !item.code.startsWith('2')).map(x => countriesOptions.push({ key: x.typeID, text: x.description, data: x.code }));
                    lookupValsTerritories.filter(item => item.code.startsWith('2')).map(x => regionOptions.push({ key: x.typeID, text: x.description, data: x.code }));
                }
                if (defaultTerritories.includes && defaultTerritories.includes.length) {
                    defaultTerritories.includes.map(item => updatedTerritoryCodes.push(`I: ${lookupValsTerritories.find(x => x.typeID === item).description}`))
                }
                if (defaultTerritories.excludes && defaultTerritories.excludes.length) {
                    defaultTerritories.excludes.map(item => updatedTerritoryCodes.push(`E: ${lookupValsTerritories.find(x => x.typeID === item).description}`))
                }

                updatedTerritoryCodes.map(item => updatedAgreementTerritories.push(
                    {
                        agreementTerritoryID: 0,
                        agreementID: agreementID,
                        territory: lookupValsTerritories.find(x => x.description === item.replace(/^(I: |E: )/, '')).code,
                        isDeleted: false,
                        include: item.startsWith("I: ") ? true : false
                    }
                ))
                if (updatedAgreementTerritories) {
                    return updatedAgreementTerritories.map(territory => {
                        return {
                            agreementTerritoryID: 0,
                            agreementID: territory.agreementID,
                            isDeleted: territory.isDeleted,
                            include: territory.include,
                            territory: territory.territory
                        }
                    })
                }

            } else {
                return territories.map(territory => {
                    return {
                        agreementTerritoryID: 0,
                        agreementID: territory.agreementID,
                        isDeleted: territory.isDeleted,
                        include: territory.include,
                        territory: territory.territory
                    }
                })
            }

        }
        const getAgreementAdminIPs = (assignees) => {
            return assignees.map(assignee => {
                                return {
                                        agreementAdminIPID: 0,
                                        administeredByIPBaseNumber: assignee.administeredByIPBaseNumber?.value,
                                        administeredName: assignee.administeredName?.value,
                                        administeredIP: `${assignee?.assigneeNameNumber?.value}`,
                                        accountNumber: null,
                                        performingShare: assignee?.performingShare?.value ? parseInt(assignee?.performingShare.value) : 0,
                                        mechanicalShare: assignee?.mechanicalShare?.value ? parseInt(assignee?.mechanicalShare.value) : 0,
                                        agreementID: 0,
                                        societyAffiliation: assignee?.societyAffiliation?.value,
                                        ref1: null
                                    }
            })
        }
        const getCommentsFromMembersPortalAgreementComments = (comments) => {
            if (comments) {
                const agremmentNotes: IAgreementNote[] = [];
                comments.map((comment, index) => agremmentNotes.push({
                    notesID: comment?.notesID?.value ? comment?.notesID?.value : index,
                    username: comment?.username?.value ? comment?.username?.value : '',
                    lastModifiedDate: null,
                    subject: comment?.subject?.value ? comment.subject.value : '',
                    noteText: comment?.comment?.value ? comment.comment.value : '',
                    isModified: true,
                    commentCategory: comment?.commentCategory?.value ? comment.commentCategory.value : '',
                }));
                return agremmentNotes;
            }
            else {
                return [];
            }
        }
        const getAgreementShareSet = (agreementShareSet): IAgreementShareSet[] => {
            const shareType = [];
            agreementShareSet.forEach((shareSet, index) => {

                if (shareSet.setType === 'online') {
                    shareType.push({
                        ...agreementShareSet,
                        agreementID: agreementShareSet[index]?.agreementID ? shareSet?.agreementID : 0,
                        agreementShareSetID: agreementShareSet[index]?.agreementShareSetID ? shareSet?.agreementShareSetID : 0,
                        setType: "online"
                    });
                }

                if (shareSet.setType === 'distribution') {
                    shareType.push({
                        ...agreementShareSet,
                        agreementID: agreementShareSet[index]?.agreementID ? shareSet?.agreementID : 0,
                        agreementShareSetID: agreementShareSet[index]?.agreementShareSetID ? shareSet?.agreementShareSetID : 0,
                        setType: "distribution"
                    });
                }
            })
            return shareType
        }

        const agreement: IAgreement = {
            agreementID,
            dataSource,
            isDeleted,
            lastModifiedDate,
            ipName,
            ipNameNumber,
            sourceID: 2,    
            source: getLookupDefault(AGREEMENT_SOURCE_LOOKUP, lookups),
            ipBaseNumber: ipBaseNumber,
            agreementRegion: agreementRegion && agreementRegion.length > 0  ? agreementRegion : [],
            agreementCategory,
            type,
            specificWorks,
            agreementSourceType,
            specificAgreementType,
            applicableWorksType,
            maintained,
            effectiveFrom: effectiveFrom ?  `${effectiveFrom}T00:00:00` as unknown as Date : null,
            effectiveTo: effectiveTo ?  `${effectiveTo}T23:59:59` as unknown as Date  : null,
            effectiveNotifiedTo,
            effectiveNotifiedFrom,
            creationDate: creationDate ? dayjs(creationDate).toDate() : null,
            territory,
            setTypeGroup: null,
            salesOrManufacture,
            agreementSignatureDate,
            bulkTransferWorks,
            priorRoyalties,
            expectedTermination,
            expectedTerminationText,
            postTermRetentionPeriod,
            postTermCollectionPeriod,
            submitterAssignorIPCode,
            libraryMusic,
            recordingPrefix,
            worksRetained,
            defaultRetainedUntil,
            defaultRetentionEndDate,
            defaultCollectionUntil,
            defaultCollectionPeriodEndDate,
            licensedDirect,
            agreementShareSet: getAgreementShareSet(agreementShareSet),
            agreementUsagePools,
            status,
            agreementAdminIP: getAgreementAdminIPs(agreementAdminIP),
            agreementNumber: AgreementDetailsMapper.mapStateToNumber(agreementState.agreementNumbers),
            agreementWork: AgreementDetailsMapper.mapStateToWork(agreementState.agreementWorks),
            agreementProductWorks: AgreementDetailsMapper.mapStateToCarveOut(agreementState.agreementProductWorks),
            agreementDuplicate: AgreementDetailsMapper.mapStateToDuplicate(agreementState.agreementDuplicate),
            notes: getCommentsFromMembersPortalAgreementComments(agreementState.comments),
            agreementTerritory: getAgreementTerritories(agreementTerritory,agreementID),
            attachedFile: attachedFiles,
            agreementBatchID: agreementBatchID ? Number(agreementBatchID) : null,
            versionNumber,
            showCommentSubjectAsDropdown,
            workflows: AgreementDetailsMapper.mapWorkflowToAgreementWorkflow(agreementState.workflows),
            agreementSourceIP: AgreementDetailsMapper.mapSourceIPToAgreementSourceIP(agreementSourceIP),
            worldExcluding,
            societyAffiliation
        }
        return agreement;
    }

}
