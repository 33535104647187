import { connect } from "react-redux";
import { Dispatch } from "redux";
import IAppState from "../../redux/types/IAppState";
import { mp_addTab, resetPagination, sortSearchResults, updatePagination, expandMemberStatementResult, expandAllResults } from "../../redux/reducers/MembersPortalReducer";
import { loadMembersPortalFromPathThunk, searchUnpaidClaimsThunk } from "../../redux/thunks/MembersPortalThunks";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { updateUserPreferencesThunk } from "../../redux/thunks/RepertoireThunks";
import { IMembersPortalPathInfo } from "../types/IMembersPortalPathInfo";
import UnpaidClaimsMembersPage from "./UnpaidClaimsMembersPage";
import { IUnpaidClaimsSearchQuery } from "../types/IUnpaidClaimsSearchQuery";
import { showModal } from "../../redux/reducers/ModalReducer";

export default connect(
    (state: IAppState) => ({
        searchViewData: state.membersPortal.componentData.SearchView,
        unpaidClaimsMembersPageData: state.membersPortal.componentData.UnpaidClaimsMembersPage.fields,
        searchResultsTableData: state.membersPortal.componentData.SearchResultsTable,
        searchSuccessful: state.membersPortal.searchSuccessful,
        unpaidClaimsSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].unpaidClaimsSearchResults,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'unpaidClaims')[0]?.indexOfFirstResult,
        indexOfLastResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'unpaidClaims')[0]?.indexOfLastResult,
        resultsPerPage: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'unpaidClaims')[0]?.resultsPerSection,
        currentPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].currentPage ? state.membersPortal.tabs[state.membersPortal.activeTab].currentPage : undefined,
        memberPaymentStatementGeneralDataViewData: state.membersPortal.componentData.MemberPaymentStatementGeneralDataView,
        defaultActiveAccordions: state.membersPortal.defaultActiveAccordions,
        allResultsPerPage: state.membersPortal.resultsPerPage,
        roles: state.account.roles,
        currentUserNameNumber: sessionStorage.currentUserNameNumber,
        expandedMemberStatementResults: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandedMemberStatementResults,
        expandAll: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandAll,
        continuationToken: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].continuationToken ? state.membersPortal.tabs[state.membersPortal.activeTab].continuationToken : undefined,
        lookupSources: state.membersPortal.matchingSourceTreeData,
        distributions: state.membersPortal.distributions,
        customerDatePreference: state.account.customerDate,
        tabs: state.membersPortal.tabs,
        activeTab: state.membersPortal.activeTab,
        isSearching: state.membersPortal.isSearching,
    }),
    (dispatch: Dispatch) => ({
        searchUnpaidClaims: (query: IUnpaidClaimsSearchQuery, continuationToken: string) =>
            dispatch<any>(searchUnpaidClaimsThunk(query, continuationToken)),
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) =>
            dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) =>
            dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        expandMemberStatementResult: (statementResult: number) => dispatch(expandMemberStatementResult(statementResult)),
        loadMembersPortalFromPath: (pathInfo: IMembersPortalPathInfo, currentUserNameNumber: string, location: Location) => dispatch<any>(loadMembersPortalFromPathThunk(pathInfo, currentUserNameNumber, location)),
        expandAllResults: () => dispatch<any>(expandAllResults()),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
    })
)(UnpaidClaimsMembersPage);
