import IAppState from "../../redux/types/IAppState";
import { connect } from "react-redux";
import AgreementsPage from "./AgreementsPage";
import { Dispatch } from "redux";
import { setAgreementDataSource, addTab, createNewAgreement, resetPagination, updatePagination, setAgreementSearchType, sortSearchResults } from "../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { showModal } from "../../redux/reducers/ModalReducer";
import { IAgreementSearchQuery } from "../types/IAgreementSearchQuery";
import { searchAgreementsThunk, getAgreementDetailsThunk, updateUserPreferencesThunk, getShowCommentSubjectAsDropdownConfigurationParameterThunk, AddtoPackageThunk } from "../../redux/thunks/RepertoireThunks";
import { DataSource } from "../types/DataSource";
import { REPERTOIRE } from "../Consts";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { saveRoles } from "../../redux/reducers/AccountReducer";


    export default connect(
    (state: IAppState) => ({
        agreementsPageData: state.repertoire.componentData.AgreementsPage,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        dataSource: state.repertoire.agreementDataSource,
        lookupValues: state.lookupEntities,
        agreementsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].agreementsSearchResult,
        searchSuccessful: state.repertoire.searchSuccessful,
        agreementMaintenanceGeneralDataViewData: state.repertoire.componentData.AgreementMaintenanceGeneralDataView,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'draftAgreements' || x.repertoireSection === 'registeredAgreements')[0]?.indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'draftAgreements' || x.repertoireSection === 'registeredAgreements')[0]?.indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'draftAgreements' || x.repertoireSection === 'registeredAgreements')[0]?.resultsPerSection,
        currentPage: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].currentPage ? state.repertoire.tabs[state.repertoire.activeTab].currentPage : undefined,
        searchType: state.repertoire.agreementSearchType,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        roles: state.account.roles,
        username:state.account.currentUser
    }),
    (dispatch: Dispatch) => ({
        setAgreementDataSource: (dataSource: string) => dispatch(setAgreementDataSource(dataSource)),
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        searchAgreements: (query: IAgreementSearchQuery, lookupValues: ILookupDictionary) =>
            dispatch<any>(searchAgreementsThunk(query, lookupValues)),
        getAgreementDetails: (dataSource: string, agreementID: number, lookups: ILookupDictionary, actionList: IDataActionToolbar[],agreementMaintenanceGeneralDataView: IRepertoireComponentDataItem) =>
            dispatch<any>(getAgreementDetailsThunk(dataSource, agreementID, lookups,actionList)),
        addNewAgreement: (lookups: ILookupDictionary, isNew?: boolean, actionList?  : IDataActionToolbar[],agreementMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) =>
        dispatch(createNewAgreement(lookups, isNew, actionList, agreementMaintenanceGeneralDataViewData)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        setAgreementSearchType: (searchType: string) => dispatch(setAgreementSearchType(searchType)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        getShowCommentSubjectAsDropdownConfigurationParameter: (group: string) => dispatch<any>(getShowCommentSubjectAsDropdownConfigurationParameterThunk(group)),
        addToPackages:( userName:string,entityType:string,entityID:number)=>dispatch<any>(AddtoPackageThunk(userName,entityType,entityID)),
    })
) (AgreementsPage);