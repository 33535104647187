import axios from "axios";
import { IDraftWorkSearchQuery } from "../types/IDraftWorkSearchQuery";
import { IRegisteredWorkSearchQuery } from "../types/IRegisteredWorkSearchQuery";
import { IDraftWorkSearchResult } from "../types/IDraftWorkSearchResult";
import { IRegisteredWorkSearchResult } from "../types/IRegisteredWorkSearchResult";
import { IArtistSearchQuery } from "../../repertoire/types/IArtistSearchQuery";
import { IArtist } from "../../repertoire/types/IArtist";
import { IAllRegisteredWorkSearchResult } from "../types/IAllRegisteredWorkSearchResult";
import { IAllRegisteredWorkSearchQuery } from "../types/IAllRegisteredWorkSearchQuery";
import { IMemberStatementSearchQuery } from "../types/IMemberStatementSearchQuery";
import { IMemberStatementSearchResult } from "../types/IMemberStatementSearchResult";
import { IDraftSetListSearchResult } from "../types/IDraftSetListSearchResult";
import { IDraftSetListSearchQuery } from "../types/IDraftSetListSearchQuery";
import { IMatchWorksSearchQuery } from "../../repertoire/types/usageTypes/IMatchWorksSearchQuery";
import { IMatchWorksSearchResult } from "../../repertoire/types/usageTypes/IMatchWorksSearchResult";
import { INTRAY } from "../Consts";
import { ILivePerformanceSearchQuery } from "../types/ILivePerformanceSearchQuery";
import { ILivePerformancesSearchResult } from "../types/ILivePerformancesSearchResult";
import {IUnpaidClaimsSearchQuery } from "../types/IUnpaidClaimsSearchQuery";
import { IClaimUnidentifiedClaimModal } from "../types/IClaimUnidentifiedClaimModal";
import { IUnpaidClaimsSearchResult } from "../types/IUnpaidClaimsSearchResult";
import { IClaimsHistorySearchQuery, IClaimsHistorySearchResult } from "../types/IClaimsHistorySearchQuery";
import { IDraftAgreementSearchQuery } from "../types/IDraftAgreementSearchQuery";
import { IDraftAgreementSearchResult } from "../types/IDraftAgreementsSearchResult";
import { IRegisteredAgreementSearchResult } from "../types/IRegisteredAgreementSearchResult";
import { IRegisteredAgreementSearchQuery } from "../types/IRegisteredAgreementSearchQuery";
import { IIPsSearchQuery } from "../../repertoire/types/IIPsSearchQuery";
import { IIPsSearchResult } from "../../repertoire/types/IIPsSearchResult";
import { IWorksSearchQuery } from "../../repertoire/types/IWorksSearchQuery";
import { IWorksSearchResult } from "../../repertoire/types/IWorksSearchResult";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { AGREEMENT_CATEGORY_LOOKUP } from "../../lookup/Consts";

export class SearchRequests {
    public static getConfigurationParameter(key: string, group: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/Component/ConfigurationParameter/" + key + "/" + group)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getLivePerformancesDistribution(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/MembersPortalLivePerformance/GetDistribution/")
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getDraftWorkHistoryVersion(DraftWorkID: number, versionNumber: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/DraftWork/DraftWorkHistory/" + DraftWorkID + '/' + versionNumber)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getMemberStatements(searchQuery: IMemberStatementSearchQuery): Promise<IMemberStatementSearchResult[]> {
        const {
            viewStatementsFrom,
            customRange,
            dateFrom,
            dateTo,
            paymentID,
            distributionCode,
            pool,
            workNumber,
            currentUserNameNumber
        } = searchQuery

        return new Promise<IMemberStatementSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/MemberStatements", {
                    viewStatementsFrom: viewStatementsFrom,
                    customRange: customRange,
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    paymentID: paymentID,
                    distributionCode: distributionCode,
                    pool: pool,
                    workNumber: workNumber,
                    currentUserNameNumber: currentUserNameNumber
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response.message);
                })
        });
    }

    public static claimUnpaidUsage(query: IClaimUnidentifiedClaimModal) {
        const {
            usageGroupId,
            claimedWorkId,
            claimantCurrentUserNameNumber,
        } = query 
        return new Promise<IUnpaidClaimsSearchResult[]>((resolve, reject) => {
        axios
        .post("/MembersPortalClaim/ClaimUP", {
                    UsageGroupId: usageGroupId,
                    ClaimedWorkId: `${claimedWorkId}`,
                    ClaimantCurrentUserNameNumber: claimantCurrentUserNameNumber,
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response.message);
                })
            });
    }

    public static getUnpaidClaims(searchQuery: IUnpaidClaimsSearchQuery, continuationToken: string): Promise<IUnpaidClaimsSearchResult[]> {
        const {
            fullTitle,
            fullContributor,
            fullPerformer,
            sourceMinor,
            sourceMajor,
            workNumber,
            distributionCode,
            isMembersPortal,
            performanceFromDate,
            performanceToDate
        } = searchQuery

        return new Promise<IUnpaidClaimsSearchResult[]>((resolve, reject) => {
            axios
                .post("/MembersPortalSearch/UnpaidUsages", {
                    fullTitle: {
                        value: fullTitle,
                        type: 0
                    },
                    fullContributor: {
                        value: fullContributor,
                        type: 0
                    },
                    performanceFromDate: {
                        value: performanceFromDate,
                        type: 0
                    },
                    performanceToDate: {
                        value: performanceToDate,
                        type: 0
                    },
                    fullPerformer: {
                        value: fullPerformer,
                        type: 0
                    },
                    sourceMinor: {
                        value: sourceMinor,
                        type: 0
                    },
                    sourceMajor: {
                        value: sourceMajor,
                        type: 0
                    },
                    workNumber: {
                        value: workNumber,
                        type: 0
                    },
                    distributionCode: {
                        value: distributionCode,
                        type: 0
                    },
                }
                    )
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response.message);
                })
        });
    }

    public static getClaimsHistory(searchQuery: IClaimsHistorySearchQuery, continuationToken: string): Promise<IClaimsHistorySearchResult[]> {
        const {
            fullTitle,
            claimApprovalStatus,
            isMembersPortal,
            claimantCurrentUserNameNumber,
        } = searchQuery

        return new Promise<IUnpaidClaimsSearchResult[]>((resolve, reject) => {
            axios
                .post("/MembersPortalSearch/MemberClaimHistory", {
                    fullTitle: {
                        value: fullTitle,
                        type: 0
                    },
                    claimStatus: {
                        value: claimApprovalStatus,
                        type: 0
                    },
                    claimantCurrentUserNameNumber: {
                        value: claimantCurrentUserNameNumber,
                        type: 0
                    }
                }
                    )
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response.message);
                })
        });
    }

    public static getSetLists(searchQuery: IDraftSetListSearchQuery): Promise<IDraftSetListSearchResult[]> {
        const {
            setListID,
            title,
            status,
            dataSource,
            currentUserNameNumber
        } = searchQuery;

        return new Promise<IDraftSetListSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/SetLists", {
                    setListID: setListID,
                    title: title,
                    status: status,
                    dataSource: dataSource,
                    currentUserNameNumber: currentUserNameNumber
                })
                .then(response => {
                    const productSearchResultItems: IDraftSetListSearchResult[] = [];
                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];

                        let productSearchResultItem: IDraftSetListSearchResult = {
                            productCoreID: response.data[x].productCoreID,
                            dataSource: dataSource,
                            type: response.data[x].productType,
                            id1: response.data[x].id1,
                            id2: response.data[x].id2 ? response.data[x].id2 : '',
                            setListID: response.data[x].id1,
                            productStatus: response.data[x].status,
                            number: [],
                            title: [],
                            source: response.data[x].source,
                            artist: [],
                            contributor: [],
                            dateCreated: response.data[x].dateCreated
                        }

                        for (let x = 0; x < 3; x++) {
                            if (data.productNames[x]) {
                                productSearchResultItem.title.push(data.productNames[x].name)
                            }

                            if (data.productPerformers[x]) {
                                productSearchResultItem.artist.push(data.productPerformers[x].performerName)
                            }

                            if (data.productNumbers[x]) {
                                productSearchResultItem.number.push(data.productNumbers[x].number)
                            }

                            if (data.productContributors[x]) {
                                productSearchResultItem.contributor.push(data.productContributors[x].name)
                            }
                        }
                        if (!productSearchResultItem.productStatus || productSearchResultItem.productStatus === null) {
                            productSearchResultItem.productStatus = 'DRAFT';
                        }
                        if (dataSource === INTRAY)
                            productSearchResultItem.productStatus = productSearchResultItem.productStatus === 'DRAFT' ? 'Draft' : 'Draft Submitted';
                        else
                            productSearchResultItem.productStatus = 'Registered';

                        productSearchResultItems.push(productSearchResultItem);
                    }
                    resolve(productSearchResultItems);
                })
                .catch(response => {
                    reject(response.message);
                })
        });
    }

    public static getDraftAgreements(
        searchQuery: IDraftAgreementSearchQuery,
        lookupValues: ILookupDictionary
    ): Promise<IDraftAgreementSearchResult[]> {
        const {
            category,
            type,
            ipNumber,
            ipName,
            number,
            searchType,
            currentUserNameNumber,
        } = searchQuery;

        return new Promise<IDraftAgreementSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/DraftAgreements", {
                    agreementCategory: category,
                    type: type,
                    number: number === '' || number === "'" ? null : number,
                    ipNameNumber: ipNumber,
                    ipName: ipName,
                    searchType,
                    currentUserNameNumber: currentUserNameNumber
                })
                .then(response => {
                    let categoryLookup = lookupValues[AGREEMENT_CATEGORY_LOOKUP].lookups;
                    let typeLookup = lookupValues['AgreementType'].lookups;
                    response.data = response.data.filter(item => item.agreementCategory === 'ADM' || item.agreementCategory === 'SPB');
                    for (let x = 0; x < response.data.length; x++) {
                        response.data[x].agreementCategory = categoryLookup.filter(l => l.code === response.data[x].agreementCategory).length > 0 ? categoryLookup.filter(l => l.code === response.data[x].agreementCategory)[0].description : '';
                        response.data[x].agreementType = typeLookup.filter(l => l.code === response.data[x].type).length > 0 ? typeLookup.filter(l => l.code === response.data[x].type)[0].description : '';
                    }
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response.message);
                })
        });
    }

    public static getDraftAgreementsIp(
        searchQuery: IIPsSearchQuery
    ): Promise<IIPsSearchResult[]> {
        const {
            ipNumber,
            fullName,
            accountNumber,
            interestedPartyType,
            count,
            skip
        } = searchQuery;
        return new Promise<IIPsSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/DraftAgreementsIPs", {
                    ipiNumber: {
                        value: ipNumber && !isNaN(Number(ipNumber)) ? ipNumber : null,
                        type: 0
                    },
                    fullName: {
                        value: fullName,
                        type: 0
                    },
                    ipBaseNumber: {
                        value: ipNumber && !isNaN(Number(ipNumber)) ? "" : ipNumber,
                        type: 0
                    },
                    accountNumber: {
                        value: accountNumber,
                        type: 1
                    },
                    replacedAccounts: [{
                        fieldName: "ReplacedAccount",
                        filterFields: [{
                            fieldName: "Number",
                            values: {
                                value: accountNumber,
                                type: 3
                            }
                        }]
                    }],
                    interestedPartyType: interestedPartyType,
                    pagination: {
                      count: count,
                      skip: skip
                    }
                })
                .then(response => {
                    const ipSearchResultItems = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const ipSearchResultItem = {
                            ipNameNumber: response.data[x].ipiNumber,
                            ipBaseNumber: response.data[x].ipBaseNumber,
                            ipName: response.data[x].fullName,
                            ipType: response.data[x].type,
                            accountNumber: response.data[x].accountNumber,
                            societyAffiliation: response.data[x].societyAffiliation,
                            legalEntityType: response.data[x].legalEntityType,
                            accountName:response.data[x].accountName,
                            birthDate: response.data[x].birthDate !== null ? response.data[x].birthDate.substring(0, 10) : null,
                            isLibraryPublisher: response.data[x].isLibraryPublisher !== null ? response.data[x].isLibraryPublisher : null
                        };

                        ipSearchResultItems.push(ipSearchResultItem);
                    }
                    resolve(ipSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        })
    }

    public static getDraftAgreementWorks(
        searchQuery: IWorksSearchQuery
    ): Promise<IWorksSearchResult[]> {
        const {
            title,
            number,
            artist,
            writers,
            societyAccountNumber,
            dataSource,
            workBatchID,
            hasOpenWorkflow,
            workType,
            workGroupID
        } = searchQuery;

        return new Promise<IWorksSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/DraftAgreementsWorks", {
                    title: {
                        value: title,
                        type: 0
                    },
                    number: {
                        value: number,
                        type: 0
                    },
                    artist: {
                        value: artist,
                        type: 0
                    },
                    contributor: {
                        value: writers,
                        type: 0
                    },
                    societyAccountNumber: {
                        value: societyAccountNumber,
                        type: 0
                    },
                    workBatchID: {
                        value: workBatchID,
                        type: 2
                    },
                    dataSource: dataSource,
                    workType: {
                        value: workType,
                        type: 2
                    },
                    hasOpenWorkflow: {
                        value: hasOpenWorkflow,
                        type: 2
                    },
                    workGroupID: {
                        value: workGroupID,
                        type: 0
                    }
                })
                .then(response => {
                    const workSearchResultItems: IWorksSearchResult[] = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];

                        let workSearchResultItem: IWorksSearchResult = {
                            title: [],
                            number: [],
                            writers: [],
                            publishers: [],
                            artists: [],
                            compoundType: data.medleyType ? data.medleyType : '',
                            workID: response.data[x].workID,
                            dataSource: dataSource,
                            duration: data.duration
                        }

                        for (let x = 0; x < 3; x++) {
                            if (data.workNames[x]) {
                                workSearchResultItem.title.push(data.workNames[x].name)
                            }

                            if (data.workNumbers[x]) {
                                workSearchResultItem.number.push(data.workNumbers[x].number)
                            }

                            if (data.performers[x]) {
                                workSearchResultItem.artists.push(data.performers[x].fullName)
                            }
                        }

                        if (data.contributors) {
                            for (let x = 0; x < data.contributors.length; x++) {
                                if (data.contributors[x].contributorType === 'Writer' && workSearchResultItem.writers.length < 3) {
                                    workSearchResultItem.writers.push(data.contributors[x].fullName)
                                }
                                if (data.contributors[x].contributorType === 'Publisher' && workSearchResultItem.publishers.length < 3) {
                                    workSearchResultItem.publishers.push(data.contributors[x].fullName)
                                }
                            }
                        }

                        workSearchResultItems.push(workSearchResultItem);
                    }

                    resolve(workSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    public static getRegisteredAgreements(
        searchQuery: IRegisteredAgreementSearchQuery,
        lookupValues: ILookupDictionary
    ): Promise<IRegisteredAgreementSearchResult[]> {
        const {
            category,
            type,
            ipNumber,
            ipName,
            number,
            searchType,
            currentUserNameNumber,
        } = searchQuery;

        return new Promise<IRegisteredAgreementSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/RegisteredAgreements", {
                    agreementCategory: category,
                    type: type,
                    number: number === '' || number === "'" ? null : number,
                    ipNameNumber: ipNumber,
                    ipName: ipName,
                    searchType,
                    currentUserNameNumber: currentUserNameNumber
                })
                .then(response => {
                    let categoryLookup = lookupValues[AGREEMENT_CATEGORY_LOOKUP].lookups;
                    let typeLookup = lookupValues['AgreementType'].lookups;
                    response.data = response.data.filter(item => item.agreementCategory === 'ADM' || item.agreementCategory === 'SPB');
                    for (let x = 0; x < response.data.length; x++) {
                        response.data[x].agreementCategory = categoryLookup.filter(l => l.code === response.data[x].agreementCategory).length > 0 ? categoryLookup.filter(l => l.code === response.data[x].agreementCategory)[0].description : '';
                        response.data[x].agreementType = typeLookup.filter(l => l.code === response.data[x].type).length > 0 ? typeLookup.filter(l => l.code === response.data[x].type)[0].description : '';
                    }
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response.message);
                })
        });
    }

    public static getDraftWorks(searchQuery: IDraftWorkSearchQuery): Promise<IDraftWorkSearchResult[]> {
        const {
            title,
            status,
            workNumber,
            artist,
            ipNumber,
            ipName,
            dataSource,
            currentUserNameNumber
        } = searchQuery;

        return new Promise<IDraftWorkSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/DraftWorks", {
                    title: title,
                    status: status,
                    workNumber: workNumber,
                    artist: artist,
                    ipNumber: ipNumber,
                    ipName: ipName,
                    dataSource: dataSource,
                    currentUserNameNumber: currentUserNameNumber
                })
                .then(response => {
                    const draftWorkSearchResultItems: IDraftWorkSearchResult[] = [];
                    for (let x = 0; x < response.data.length; x++) {
                        const work = response.data[x];

                        let workSearchResultItem: IDraftWorkSearchResult = {
                            workID: response.data[x].workID,
                            dataSource: response.data[x].dataSource,
                            title: [],
                            status: null,
                            workNumber: [],
                            workContributorIDs: [],
                            contributors: [],
                            ipNumber: [],
                            role: [],
                            perfShare: [],
                            mechShare: [],
                            performers: []
                        }

                        for (let j = 0; j < 3; j++) {
                            if (work.workNames[j]) {
                                workSearchResultItem.title.push(work.workNames[j].name);
                            }

                            if (work.workNumbers[j]) {
                                workSearchResultItem.workNumber.push(work.workNumbers[j].number);
                            }

                            if (work.performers[j]) {
                                workSearchResultItem.performers.push(work.performers[j].fullName);
                            }
                        }

                        if (work.contributors) {
                            for (let j = 0; j < work.contributors.length; j++) {
                                if (work.contributors[j].workContributorID != null) { workSearchResultItem.workContributorIDs.push(work.contributors[j].workContributorID); }
                                else { workSearchResultItem.workContributorIDs.push(0); }

                                if (work.contributors[j].fullName != null && work.contributors[j].fullName != ' ') { workSearchResultItem.contributors.push(work.contributors[j].fullName); }
                                else { workSearchResultItem.contributors.push('unknown'); }

                                if (work.contributors[j].type != null) { workSearchResultItem.role.push(work.contributors[j].type); }
                                else { workSearchResultItem.role.push('X'); }
                            }
                        }

                        if (work.workTerritorySpecificInfo && work.workTerritorySpecificInfo.length > 0 && work.workTerritorySpecificInfo[0].workStatus != null && workSearchResultItem.status == null) {
                            // If anything except 'DRAFT' is present, display 'Draft Submitted', if 'Draft' is present then display 'Draft'
                            workSearchResultItem.status = work.workTerritorySpecificInfo[0].workStatus == "DRAFT" ? 'Draft' : 'Draft Submitted';
                        }

                        // Incase invalid work and no WorkTerritorySpecificInfo is present 
                        if (workSearchResultItem.status == null) {
                            workSearchResultItem.status = 'Draft';
                        }

                        draftWorkSearchResultItems.push(workSearchResultItem);

                    }

                    resolve(draftWorkSearchResultItems);
                })
                .catch(response => {
                    reject(response.message);
                })
        });
    }

    public static getDraftWork(dataSource: string, DraftWorkId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/MembersPortalWork/" + dataSource + '/' + DraftWorkId)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    public static getDraftAgreement(dataSource: string, DraftAgreementId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/MembersPortalAgreement/" + dataSource + '/' + DraftAgreementId)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    public static getDraftSetList(dataSource: string, DraftSetListId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/MembersPortalSetList/" + dataSource + '/' + DraftSetListId)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    public static getLivePerformance(usageId): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/MembersPortalLivePerformance/" + usageId)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    public static getRegisteredWorkHistoryVersion(registeredWorkID: number, versionNumber: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/RegisteredWork/RegisteredWorkHistory/" + registeredWorkID + '/' + versionNumber)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getRegisteredWorks(searchQuery: IRegisteredWorkSearchQuery): Promise<IRegisteredWorkSearchResult[]> {
        const {
            title,
            status,
            workNumber,
            artist,
            ipNumber,
            ipName,
            dataSource,
            currentUserNameNumber,
            contributor
        } = searchQuery;
        return new Promise<IRegisteredWorkSearchResult[]>((resolve, reject) => {
            const contributorOrEmptyString = contributor ? contributor : '';
            axios
                .post("/Search/RegisteredWorks", {
                    title: title,
                    status: status,
                    workNumber: workNumber,
                    artist: artist,
                    ipNumber: ipNumber,
                    ipName: ipName,
                    dataSource: dataSource,
                    currentUserNameNumber: currentUserNameNumber,
                    contributor: contributorOrEmptyString,
                })
                .then(response => {
                    const draftWorkSearchResultItems: IRegisteredWorkSearchResult[] = [];
                    for (let x = 0; x < response.data.length; x++) {
                        const work = response.data[x];

                        let workSearchResultItem: IRegisteredWorkSearchResult = {
                            workID: response.data[x].workID,
                            dataSource: response.data[x].dataSource,
                            title: [],
                            status: 'Registered',
                            workNumber: [],
                            workContributorIDs: [],
                            contributors: [],
                            ipNumber: [],
                            role: [],
                            perfShare: [],
                            mechShare: [],
                            performers: []
                        }

                        for (let j = 0; j < 3; j++) {
                            if (work.workNames[j]) {
                                workSearchResultItem.title.push(work.workNames[j].name);
                            }

                            if (work.workNumbers[j]) {
                                workSearchResultItem.workNumber.push(work.workNumbers[j].number);
                            }

                            if (work.performers[j]) {
                                workSearchResultItem.performers.push(work.performers[j].fullName);
                            }
                        }

                        if (work.contributors) {
                            for (let j = 0; j < work.contributors.length; j++) {
                                if (work.contributors[j].workContributorID != null) { workSearchResultItem.workContributorIDs.push(work.contributors[j].workContributorID); }
                                else { workSearchResultItem.workContributorIDs.push(0); }

                                if (work.contributors[j].fullName != null && work.contributors[j].fullName != ' ') { workSearchResultItem.contributors.push(work.contributors[j].fullName); }
                                else { workSearchResultItem.contributors.push('unknown'); }

                                if (work.contributors[j].type != null) { workSearchResultItem.role.push(work.contributors[j].type); }
                                else { workSearchResultItem.role.push('X'); }
                            }
                        }
                        draftWorkSearchResultItems.push(workSearchResultItem);

                    }

                    resolve(draftWorkSearchResultItems);
                })
                .catch(response => {
                    reject(response.message);
                })
        });
    }


    public static getAllRegisteredWorks(searchQuery: IAllRegisteredWorkSearchQuery): Promise<IAllRegisteredWorkSearchResult[]> {
        const {
            title,
            status,
            workNumber,
            artist,
            ipNumber,
            ipName,
            dataSource,
            currentUserNameNumber
        } = searchQuery;
        return new Promise<IRegisteredWorkSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/AllRegisteredWorks", {
                    title: title,
                    status: status,
                    workNumber: workNumber,
                    artist: artist,
                    ipNumber: ipNumber,
                    ipName: ipName,
                    dataSource: dataSource,
                    currentUserNameNumber: currentUserNameNumber
                })
                .then(response => {
                    const workSearchResultItems: IAllRegisteredWorkSearchResult[] = [];
                    for (let x = 0; x < response.data.length; x++) {
                        const work = response.data[x];
                        let status = 'Registered'

                        if (response.data[x].dataSource === 'Intray') {
                            status = response.data[x].draftSubmitted ? 'Draft Submitted' : 'Draft'
                        }
                        let workSearchResultItem: IAllRegisteredWorkSearchResult = {
                            workID: response.data[x].workID,
                            dataSource: response.data[x].dataSource,
                            title: [],
                            status: status,
                            workNumber: [],
                            workContributorIDs: [],
                            contributors: [],
                            ipNumber: [],
                            role: [],
                            perfShare: [],
                            mechShare: [],
                            performers: []
                        }

                        for (let j = 0; j < 3; j++) {
                            if (work.workNames[j]) {
                                workSearchResultItem.title.push(work.workNames[j].name);
                            }

                            if (work.workNumbers[j]) {
                                workSearchResultItem.workNumber.push(work.workNumbers[j].number);
                            }

                            if (work.performers[j]) {
                                workSearchResultItem.performers.push(work.performers[j].fullName);
                            }
                        }

                        if (work.contributors) {
                            for (let j = 0; j < work.contributors.length; j++) {
                                if (work.contributors[j].workContributorID != null) { workSearchResultItem.workContributorIDs.push(work.contributors[j].workContributorID); }
                                else { workSearchResultItem.workContributorIDs.push(0); }

                                if (work.contributors[j].fullName != null && work.contributors[j].fullName != ' ') { workSearchResultItem.contributors.push(work.contributors[j].fullName); }
                                else { workSearchResultItem.contributors.push('unknown'); }

                                if (work.contributors[j].type != null) { workSearchResultItem.role.push(work.contributors[j].type); }
                                else { workSearchResultItem.role.push('X'); }
                            }
                        }
                        workSearchResultItems.push(workSearchResultItem);
                    }
                    resolve(workSearchResultItems);
                })
                .catch(response => {
                    reject(response.message);
                })
        });
    }

    public static getRegisteredWork(dataSource: string, registeredWorkId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/MembersPortalWork/" + dataSource + '/' + registeredWorkId)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    public static getArtists(
        artist: IArtistSearchQuery,
        dataSource: string
    ): Promise<IArtist[]> {
        return new Promise<IArtist[]>((resolve, reject) => {
            axios
                .post("/search/MPartists", {
                    fullName: { value: artist.fullName, type: 0 },
                    firstName: { value: artist.firstName, type: 0 },
                    lastName: { value: artist.lastName, type: 0 },
                    number: { value: artist.number, type: 0 },
                    type: { value: artist.type, type: 0 },
                    dataSource: dataSource
                })
                .then(response => {
                    const artistSearchResultItems: IArtist[] = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];

                        const artistSearchResultItem: IArtist = {
                            fullName: data.fullName,
                            performerID: data.performerID,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            type: data.type,
                            number: data.number,
                            workPerformerID: data.workPerformerID,
                            performerNumberID: data.performerNumberID ? data.performerNumberID : 0,
                        };
                        artistSearchResultItems.push(artistSearchResultItem);
                    }
                    resolve(artistSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    public static getSetListWorks(
        searchQuery: IMatchWorksSearchQuery
    ): Promise<IMatchWorksSearchResult[]> {
        const {
            workIDs,
            title,
            number,
            artist,
            writers,
            societyAccountNumber,
            dataSource,
            workBatchID,
            hasOpenWorkflow
        } = searchQuery;
        return new Promise<IMatchWorksSearchResult[]>((resolve, reject) => {
            axios
                .post("/search/setListWorks", {
                    workIDs: {
                        values: workIDs.map(id => { return { value: id, type: 2 } })
                    },
                    title: {
                        value: title,
                        type: 0
                    },
                    number: {
                        value: number,
                        type: 0
                    },
                    artist: {
                        value: artist,
                        type: 0
                    },
                    contributor: {
                        value: writers,
                        type: 0
                    },
                    societyAccountNumber: {
                        value: societyAccountNumber,
                        type: 0
                    },
                    workBatchID: {
                        value: workBatchID,
                        type: 2
                    },
                    dataSource: dataSource,
                    hasOpenWorkflow: {
                        value: hasOpenWorkflow,
                        type: 2
                    },
                })
                .then(response => {
                    const workSearchResultItems: IMatchWorksSearchResult[] = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];

                        let workSearchResultItem: IMatchWorksSearchResult = {
                            title: [],
                            number: [],
                            writers: [],
                            publishers: [],
                            artists: [],
                            compoundType: data.medleyType ? data.medleyType : '',
                            workID: response.data[x].workID,
                            dataSource: dataSource,
                            duration: data.duration,
                            status: data.status,
                            isDraftSubmitted: data.isDraftSubmitted

                        }

                        for (let x = 0; x < 3; x++) {
                            if (data.workNames[x]) {
                                workSearchResultItem.title.push(data.workNames[x].name)
                            }

                            if (data.workNumbers[x]) {
                                workSearchResultItem.number.push(data.workNumbers[x].number)
                            }

                            if (data.performers[x]) {
                                workSearchResultItem.artists.push(data.performers[x].fullName)
                            }
                        }

                        if (data.contributors) {
                            for (let x = 0; x < data.contributors.length; x++) {
                                if (data.contributors[x].contributorType === 'Writer' && workSearchResultItem.writers.length < 3) {
                                    workSearchResultItem.writers.push(data.contributors[x].fullName)
                                }
                                if (data.contributors[x].contributorType === 'Publisher' && workSearchResultItem.publishers.length < 3) {
                                    workSearchResultItem.publishers.push(data.contributors[x].fullName)
                                }
                            }
                        }

                        workSearchResultItems.push(workSearchResultItem);
                    }

                    resolve(workSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    public static getLivePerformances(
        searchQuery: ILivePerformanceSearchQuery
    ): Promise<ILivePerformancesSearchResult> {
        return new Promise<ILivePerformancesSearchResult>((resolve, reject) => {
            axios
                .post("/search/LivePerformances", {
                    dateFrom: searchQuery.dateFrom,
                    dateTo: searchQuery.dateTo,
                    currentUserNameNumber: searchQuery.currentUserNameNumber
                })
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }
}
