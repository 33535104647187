import { save, load } from "redux-localstorage-simple"
import { LOCAL_STORAGE, LOCAL_STORAGE_VERSION } from "./StorageEngineKeys";

export const stateFilters = [
    "lookupEntities",
    "home.menuItems",
    "home.currentPage",
    "home.componentData",
    "repertoire.componentData",
    "repertoire.resultsPerPage",
    "repertoire.productMaintenanceSubmissionConfig",
    "repertoire.defaultActiveAccordions",
    "repertoire.matchingSourceTreeData",
    "usage.resultsPerPage",
    "dataIngestion.componentData",
    "dataIngestion.distributions",
    "dataIngestion.formats",
    "dataIngestion.sources",
    "dataIngestion.destinations",
    "membersPortal.componentData",
    "membersPortal.resultsPerPage",
    "membersPortal.defaultActiveAccordions",
];

const localStorageVersion: number = 342;
const existingVersion: number = Number(localStorage[LOCAL_STORAGE_VERSION]);
if (!existingVersion || existingVersion < localStorageVersion && localStorage) {
    localStorage.removeItem(LOCAL_STORAGE);
    localStorage.setItem(LOCAL_STORAGE_VERSION, localStorageVersion.toString());
}

export const saveStorage = save({ states: stateFilters });
export const loadStorage = store => next => action => {
    load({ states: stateFilters, disableWarnings: true })
    return next(action);
}
