import { connect } from "react-redux";
import IAppState from "../../../../redux/types/IAppState";
import UsageWorkSearchModalView from "./UsageProductSearchModalView";
import { resetPagination, updatePagination } from "../../../../redux/reducers/RepertoireReducer";
import { Dispatch } from "redux";
import { addWorkOrProductToUsageSearchCriteria } from "../../../../redux/reducers/RepertoireReducer";
import { IProductSearchResult } from "../../../types/IProductSearchResult";


export default connect(
    (state: IAppState) => ({
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'works')[0]?.indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'works')[0]?.indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'works')[0]?.resultsPerSection,
        matchProductsSearchResult: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].matchProductsSearchResult,
        currentPage: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].currentPage ? state.repertoire.tabs[state.repertoire.activeTab].currentPage : undefined,
        allResultsPerPage: state.repertoire.resultsPerPage,
        continuationToken: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].continuationToken ? state.repertoire.tabs[state.repertoire.activeTab].continuationToken : undefined,
    }),
    (dispatch: Dispatch) => ({
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        addProductToUsageSearchCriteria: (product: IProductSearchResult) => dispatch(addWorkOrProductToUsageSearchCriteria(product)),
    })
)
    (UsageWorkSearchModalView);