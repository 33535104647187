import { connect } from "react-redux";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showScheduledJobModalView, showUpdateFieldsModal, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import { copyExistingDistribution, createNewDistribution, incrementTempID, resetMessageBanner, setEditableFields, sortDistributionPoolFieldData, updateDistributionField, updateEditableFields } from "../../../../redux/reducers/RepertoireReducer";
import { deleteDistributionThunk, saveDistributionThunk, searchUsagePoolsThunk, undoDistributionChangesThunk, searchDistributionVersionHistoryThunk, exportDistributionPoolsThunk, importDistributionPoolsThunk, getLastPercentageValuesThunk, updateUserPreferencesThunk, updateComponentFieldItemsThunk, getUsagePoolDetailsThunk } from "../../../../redux/thunks/RepertoireThunks";
import { allocateDistributionPoolsThunk, runQAOnDistributionPoolsThunk,upsertScheduledJobThunk } from "../../../../redux/thunks/JobThunks";
import IAppState from "../../../../redux/types/IAppState";
import { IDistributionState, IDistributionStateKeys } from "../../../../redux/types/IDistributionState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IUsagePoolSearchQuery } from "../../../types/usageTypes/IUsagePoolSearchQuery";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { ITreeData } from "../../../types/ITreeData";
import { ILastPercentageSearchQuery } from "../../../types/usageTypes/ILastPercentageSearchQuery";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { triggerManualJob } from "../../../../redux/thunks/HomeThunks";
import { ITriggerManualJob } from "../../../../redux/types/ITriggerManualJob";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { getCustomerThunk } from "../../../../redux/thunks/AccountThunks";
import { IScheduledJobModalViewProps } from "../../../components/modalViews/scheduledJobModalView/ScheduledJobModalView";
import { IScheduledJobState } from "../../../../redux/types/IScheduledJobState";
import DistributionMaintenancePageScrollListener from "./DistributionMaintenancePageScrollListener";
import { IDistributionPoolStateKeys } from "../../../../redux/types/IDistributionPoolState";

export default connect(
    (state: IAppState) => ({
        distributionMaintenanceGeneralDataViewData: state.repertoire.componentData.DistributionMaintenanceGeneralDataView,
        scheduledJobModalViewData: state.repertoire.componentData.ScheduledJobModalViewData,
        distributionMaintenanceToolbarData:
            state.repertoire.componentData.DistributionMaintenanceToolbar,
        distributionMaintenanceSaveResultData: state.repertoire.componentData.SaveResult,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        distributionMaintenanceGridsViewData: state.repertoire.componentData.DistributionMaintenanceGridsViewData,
        distribution: state.repertoire.tabs[state.repertoire.activeTab].distributionMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].distributionMaintenanceState.distribution,
        schedule: state.repertoire.tabs[state.repertoire.activeTab].scheduledJobStateState,
        activeTab: state.repertoire.activeTab,
        tabs: state.repertoire.tabs,
        lookupValues: state.lookupEntities,
        distributionTypes: state.repertoire.distributionTypes,
        distributionSubTypes: state.repertoire.distributionSubTypes,
        usagePools: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].usagePoolSearchResult,
        sources: state.repertoire.matchingSourceTreeData,
        allocationJobInProgress: state.repertoire.tabs[state.repertoire.activeTab].allocationJobInProgress,
        roles: state.account.roles,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        editableFields: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].editableFields,
        customer: state.account.customer,
        usagePoolsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].usagePoolSearchResult && state.repertoire.tabs[state.repertoire.activeTab].usagePoolSearchResult,
        jobStatusQA:state.repertoire.jobStatusQA,
        
    }),
    dispatch => ({
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        showScheduledJobModalView: (title: string, props: IScheduledJobModalViewProps, modalComponentInstance: string, message?: string) =>
            dispatch(showScheduledJobModalView(title, props, modalComponentInstance)),
        hideModal: () => dispatch(hideModal()),
        updateDistributionField: (value: any, fieldName: IDistributionStateKeys, lookups?: ILookupDictionary, subFieldName?: string) =>
            dispatch(updateDistributionField(value, fieldName, lookups, subFieldName)),
        saveChanges: (distribution: IDistributionState, distributionMaintenanceGeneralDataViewData: IRepertoireComponentDataItem, lookupValues: ILookupDictionary) =>
            dispatch<any>(saveDistributionThunk(distribution, distributionMaintenanceGeneralDataViewData, lookupValues)),
        addNewDistribution: (lookups: ILookupDictionary, isNew?: boolean, distributionMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) =>
            dispatch(createNewDistribution(lookups, isNew, distributionMaintenanceGeneralDataViewData)),
        undoDistribution: (distributionID: number, lookupValues: ILookupDictionary) =>
            dispatch<any>(undoDistributionChangesThunk(distributionID, lookupValues)),
        deleteDistribution: (distributionId: number, activeTab: number, distributionCode: string, sources: ITreeData[]) =>
            dispatch<any>(deleteDistributionThunk(distributionId, activeTab, distributionCode, sources)),
        triggerManualJob: (manualJob: ITriggerManualJob) => dispatch<any>(triggerManualJob(manualJob)),
        searchUsagePools: (query: IUsagePoolSearchQuery) =>
            dispatch<any>(searchUsagePoolsThunk(query)),
        allocateDistributionPools: (distribution: IDistributionState, undoAllocation: boolean) =>
            dispatch<any>(allocateDistributionPoolsThunk(distribution, undoAllocation)),
        runQAOnDistributionPools: (distribution: IDistributionState, undoQA: boolean) => dispatch<any>(runQAOnDistributionPoolsThunk(distribution, undoQA)),
        upsertScheduledJob: (schedule: IScheduledJobState,componentName?:string) => dispatch<any>(upsertScheduledJobThunk(schedule,componentName)),
        showModal: (modalContent: string, modalComponentInstance: string, modalProps: any, displayModalCloseButton: boolean, title: string, modalMessage?: string, isReadOnly?: boolean) =>
            dispatch<any>(showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title, modalMessage, isReadOnly)),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        searchDistributionVersionHistory: (distributionId: number, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[]) =>
            dispatch<any>(searchDistributionVersionHistoryThunk(distributionId, lookups, actionList, formats)),
        exportDistributionPools: (distribution: IDistributionState, lookupValues: ILookupDictionary) => dispatch<any>(exportDistributionPoolsThunk(distribution, lookupValues)),
        importDistributionPools: (distribution: IDistributionState, fileName: string, lookupValues: ILookupDictionary) => dispatch<any>(importDistributionPoolsThunk(distribution, fileName, lookupValues)),
        copyDistribution: (dataAction: IDataActionToolbar[]) => dispatch<any>(copyExistingDistribution(dataAction)),
        getLastPercentageValues: (lastPercentageSearchQuery: ILastPercentageSearchQuery, lookups: ILookupDictionary) => dispatch<any>(getLastPercentageValuesThunk(lastPercentageSearchQuery, lookups)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        incrementTempID: () => dispatch<any>(incrementTempID()),
        setEditableFields: (componentFieldName: string, componentInstance: string) => dispatch<any>(setEditableFields(componentFieldName, componentInstance)),
        updateEditableField: (value: any, key: string) => dispatch(updateEditableFields(value, key)),
        showUpdateFieldsModal: (tittle: string, props: IShowFieldSettingViewModalProps) => dispatch<any>(showUpdateFieldsModal(tittle, props)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        getCustomer: () => dispatch<any>(getCustomerThunk()),
        getUsagePool: (poolId: number, matchingSources: ITreeData[],isPoolMaintainence?:boolean) => dispatch<any>(getUsagePoolDetailsThunk(poolId, matchingSources,true)),
        sortDistributionPoolFieldData: (value: any, fieldName: IDistributionPoolStateKeys) => 
            dispatch(sortDistributionPoolFieldData(value, fieldName)),
    })
)(DistributionMaintenancePageScrollListener);