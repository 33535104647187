import { Field, Form, Formik } from "formik";
import * as React from "react";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { showModal } from "../../../../redux/reducers/MembersPortalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { setEditableFields, updateUsagePoolField } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ALL_ROLES, COMPONENT_INSTANCE_ARTISTS, COMPONENT_INSTANCE_POOLS, CONFIGURE_PRODUCTS_ROLE, EDITABLE_FIELDS, GENERAL_DATA_EDITABLE_FIELDS, POOL_ALLOCATION_RULE_STATE_KEY, POOL_CAT_1_STATE_KEY, POOL_CAT_2_STATE_KEY, POOL_DESCRIPTION_STATE_KEY, POOL_DISTRIBUTION_CATEGORY_TYPE_STATE_KEY, POOL_INVOICE_STATE_KEY, POOL_LICENSEE_ID_STATE_KEY, POOL_PAYMENT_STATE_KEY, POOL_POOL_CODE_STATE_KEY, REPERTOIRE_PAGE_FORM_ITEM_SIZE, TITLE_EDITABLE_FIELDS_VIEW, USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW } from "../../../Consts";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { ALLOCATION_RULE_LOOKUP, DISTRIBUTION_CATEGORY_TYPE_LOOKUP } from "../../../../lookup/Consts";
import * as Yup from 'yup';
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { makeOptionsList } from "../../../components/dropdownDataInput/DropdownDataInputFormik";
import { CustomCheckBox, CustomInputComponent, newCustomDropDown } from "../../../works/components/workMaintenanceGeneralDataView/WorkMaintenanceGeneralDataViewFormik";

  const usagePoolMaintenanceGeneralSchema = (fieldData) => { 
    const schema = Yup.object().shape(
    {
        poolCode: fieldData?.poolCode?.isMandatory ? Yup.string().test(
            'empty-check',
            'poolCode can not be empty',
            string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
        description:  fieldData?.description?.isMandatory ? Yup.string().test(
            'empty-check',
            'description can not be empty',
            string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
        cat1: fieldData?.cat1?.isMandatory ? Yup.string().test(
            'empty-check',
            'cat1 can not be empty',
            string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
        cat2: fieldData?.cat2?.isMandatory ? Yup.string().test(
                'empty-check',
                'cat2 can not be empty',
                string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
        allocationRule: fieldData?.allocationRule?.isMandatory ? Yup.object().shape({
            data: Yup.string().nullable(),
          }
          ).test(
            'empty-check',
            'allocationRule can not be empty',
            val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
                data: Yup.string().nullable(),
              }).nullable().notRequired(),
        distributionCategoryType: fieldData?.distributionCategoryType?.isMandatory ? Yup.object().shape({
            data: Yup.string().nullable(),
          }
          ).test(
            'empty-check',
            'distributionCategoryType can not be empty',
            val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
                data: Yup.string().nullable(),
              }).nullable().notRequired(),
        licenseeID: fieldData?.licenseeID?.isMandatory ? Yup.string().test(
            'empty-check',
            'licenseeID Number can not be empty',
            string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
    },
)
return schema;
}

const isFieldHaveDefaultValue = (fieldName, viewData) => {
    return !!(viewData && viewData[fieldName] && viewData[fieldName].defaultValue);
}

export interface IUsagePoolMaintenanceGeneralDataViewProps {
    usagePoolMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    poolCode: string;
    description: string;
    cat1: string;
    cat2: string;
    allocationRule: string;
    distributionCategoryType: string;
    invoice: boolean;
    poolEnabled: boolean;
    licenseeID: string;
    updateUsagePoolField: typeof updateUsagePoolField;
    changesMade: boolean;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    dataGridTableEditableFieldsData?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    editableFields: IRepertoireField[];
    payment: boolean;
    roles: string[];
    poolDataFormikRef: any;
    setIsChangeGeneralForm: () => void;
    dataGridTableData: IRepertoireComponentDataItem;
}

const UsagePoolMaintenanceGeneralDataViewFormik = ({
    usagePoolMaintenanceGeneralDataViewData,
    poolCode,
    description,
    cat1,
    cat2,
    allocationRule,
    distributionCategoryType,
    invoice,
    poolEnabled,
    licenseeID,
    updateUsagePoolField,
    changesMade,
    updateComponentFieldsItems,
    showUpdateFieldsModal,
    editableFieldsDataView,
    dataGridTableEditableFieldsData,
    setEditableFields,
    activeTab,
    tabs,
    showModal,
    hideModal,
    isReadonly,
    lookupValues,
    editableFields,
    payment,
    roles,
    poolDataFormikRef,
    setIsChangeGeneralForm,
    dataGridTableData,
}) => {
    const [viewData, setViewData]: any = React.useState({});
    const [loaded, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (usagePoolMaintenanceGeneralDataViewData.fields) {
            const visibleFields = usagePoolMaintenanceGeneralDataViewData.fields.filter(field => !field.hidden);
            const data = {};
            visibleFields.forEach(item => {
                data[item.name] = item;
    
                if (Object.keys(data).length === visibleFields.length) {
                    setViewData(data);
                    setLoading(true);
                }
            });
        }
    }, [usagePoolMaintenanceGeneralDataViewData])

    React.useEffect(() => {
        setEditableFields(USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_POOLS);
    }, []);

    const onSaveEditableFields = () => {
       editableFields && editableFields.default &&  updateComponentFieldsItems(editableFields.default, USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_POOLS, USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW, tabs, activeTab);
    }
    const showFieldsModal = () => {

        setEditableFields(USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_POOLS)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableData,
            onClickSave: onSaveEditableFields,
        }
        showUpdateFieldsModal(title, fieldSetting);
    }

    const handleDataChange = () => {
        setIsChangeGeneralForm(true);
    }

        const getConfigureRoleDisabled = (): boolean => {
            if (roles && (roles.includes(CONFIGURE_PRODUCTS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        const isFieldPresent = (fieldName, viewData) => {
            return viewData && viewData[fieldName] && viewData[fieldName].data;
        }

        const getDefaultValue = (fieldName, viewData) => isFieldHaveDefaultValue(fieldName, viewData) ? viewData[fieldName].defaultValue : '';
        const getInitialValueDropDown = (initialValue, lookupName, fieldName) => {
            const lookUpValuesLocal = getLookupValues(lookupName, lookupValues);
            const initialSelected = initialValue ? initialValue : getDefaultValue(fieldName, viewData);
            const sourceOptions = makeOptionsList(true, lookupName, lookUpValuesLocal, false)
            const foundOption = sourceOptions.find(option => option.data === initialSelected || option.text === initialSelected);
        return foundOption ? foundOption : {}
        }


        if (loaded) {
            return (
                <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                    <div className="row">
                        <div className="col-md-12 editableSectionHeading">
                            <h1 className="borderBootom">General</h1>
                            <button className="editBtn" title="Configure data fields" onClick={showFieldsModal} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                    </div>
                    <div className="row">
                            <div className="col-md-12">
                                <h1>Pool Information</h1>
                            </div>
                    </div>
                    <Formik
                        enableReinitialize
                        validateOnChange
                        initialValues={{
                            poolCode: poolCode ? poolCode : '',
                            description: description ? description : '',
                            invoice: invoice ? invoice : false,
                            cat1: cat1 ? cat1 : '',
                            cat2: cat2 ? cat2 : '',
                            payment: payment ? payment : false,
                            allocationRule: getInitialValueDropDown(allocationRule, ALLOCATION_RULE_LOOKUP, POOL_ALLOCATION_RULE_STATE_KEY),
                            distributionCategoryType: getInitialValueDropDown(distributionCategoryType, DISTRIBUTION_CATEGORY_TYPE_LOOKUP, POOL_DISTRIBUTION_CATEGORY_TYPE_STATE_KEY),
                            licenseeID: licenseeID ? licenseeID : '',
                        }}
                        validate={(values) => {
                            const schema = usagePoolMaintenanceGeneralSchema(viewData)
                            try {
                                schema.validateSync(values, { abortEarly: false });
                                return {}; 
                              } catch (errors: any) {
                                const formikErrors = {};
                                errors && errors.inner && errors.inner.forEach((error) => {
                                  formikErrors[error.path] = error.message;
                                });
                                return formikErrors;
                              }
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                        }}
                > 
                {({ values, errors, submitForm, validateForm }) => {
                    poolDataFormikRef.current = {
                        values: values,
                        submitForm: submitForm,
                        validateForm: validateForm,
                        errors: errors,
                    }
                    return (
        <Form>
            <div className="formItemsContainer">
            <div className="row widthFull flexRow flexAlignItemsEnd">
            {isFieldPresent(POOL_POOL_CODE_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                <div className={POOL_POOL_CODE_STATE_KEY}>
                                        <label>{viewData[POOL_POOL_CODE_STATE_KEY].data}</label>
                                        <Field 
                                            onClick={handleDataChange}
                                            label={viewData[POOL_POOL_CODE_STATE_KEY].data}
                                            name={POOL_POOL_CODE_STATE_KEY}
                                            component={CustomInputComponent}
                                        />
                                    </div>
                                </div>}
                                {isFieldPresent(POOL_DESCRIPTION_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                <div className={POOL_DESCRIPTION_STATE_KEY}>
                                        <label>{viewData[POOL_DESCRIPTION_STATE_KEY].data}</label>
                                        <Field 
                                            onClick={handleDataChange}
                                            label={viewData[POOL_DESCRIPTION_STATE_KEY].data}
                                            name={POOL_DESCRIPTION_STATE_KEY}
                                            component={CustomInputComponent}
                                        />
                                    </div>
                                </div>}
                                
                        {isFieldPresent(POOL_INVOICE_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem flexRow flexAlignItemsCenter`}>
                                    <Field 
                                        label={viewData[POOL_INVOICE_STATE_KEY].data} 
                                        name={POOL_INVOICE_STATE_KEY} 
                                        errorName={POOL_INVOICE_STATE_KEY} 
                                        component={CustomCheckBox}
                                        disabled={isReadonly}
                                        onClick={handleDataChange}
                                    />
                                      <label className='marginHorizontalVerySmall'>{viewData[POOL_INVOICE_STATE_KEY].data}</label>
                                </div>}

                </div>
                <div className="row widthFull flexRow flexAlignItemsEnd">
                {isFieldPresent(POOL_CAT_1_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                <div className={POOL_CAT_1_STATE_KEY}>
                                        <label>{viewData[POOL_CAT_1_STATE_KEY].data}</label>
                                        <Field 
                                            onClick={handleDataChange}
                                            label={viewData[POOL_CAT_1_STATE_KEY].data}
                                            name={POOL_CAT_1_STATE_KEY}
                                            component={CustomInputComponent}
                                        />
                                    </div>
                                </div>}

                {isFieldPresent(POOL_CAT_2_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                <div className={POOL_CAT_2_STATE_KEY}>
                                        <label>{viewData[POOL_CAT_2_STATE_KEY].data}</label>
                                        <Field 
                                            onClick={handleDataChange}
                                            label={viewData[POOL_CAT_2_STATE_KEY].data}
                                            name={POOL_CAT_2_STATE_KEY}
                                            component={CustomInputComponent}
                                        />
                                    </div>
                                </div>}

                            {isFieldPresent(POOL_PAYMENT_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem flexRow flexAlignItemsCenter`}>
                                    <Field 
                                        label={viewData[POOL_PAYMENT_STATE_KEY].data} 
                                        name={POOL_PAYMENT_STATE_KEY} 
                                        errorName={POOL_PAYMENT_STATE_KEY} 
                                        component={CustomCheckBox}
                                        disabled={isReadonly}
                                        onClick={handleDataChange}
                                    />
                                      <label className='marginHorizontalVerySmall'>{viewData[POOL_PAYMENT_STATE_KEY].data}</label>
                                </div>}
            </div>
            <div className="row widthFull">

            {isFieldPresent(POOL_ALLOCATION_RULE_STATE_KEY, viewData) && newCustomDropDown(viewData, POOL_ALLOCATION_RULE_STATE_KEY, lookupValues, ALLOCATION_RULE_LOOKUP, isReadonly, errors, handleDataChange)}
            {isFieldPresent(POOL_DISTRIBUTION_CATEGORY_TYPE_STATE_KEY, viewData) && newCustomDropDown(viewData, POOL_DISTRIBUTION_CATEGORY_TYPE_STATE_KEY, lookupValues,  DISTRIBUTION_CATEGORY_TYPE_LOOKUP, isReadonly, errors, handleDataChange)}
            {isFieldPresent(POOL_LICENSEE_ID_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                <div className={POOL_LICENSEE_ID_STATE_KEY}>
                                        <label>{viewData[POOL_LICENSEE_ID_STATE_KEY].data}</label>
                                        <Field 
                                            onClick={handleDataChange}
                                            label={viewData[POOL_LICENSEE_ID_STATE_KEY].data}
                                            name={POOL_LICENSEE_ID_STATE_KEY}
                                            component={CustomInputComponent}
                                        />
                                    </div>
                                </div>}
            </div>
            </div>
        </Form>)}}


                    </Formik>
                </div>
            );
        }
        return <div />;
}

export default UsagePoolMaintenanceGeneralDataViewFormik;