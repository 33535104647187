import * as React from "react";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import { addTab, clearModalSearchResults, copyAdjustment, resetMessageBanner, setChangesMade, updateAdjustmentField } from "../../../../redux/reducers/RepertoireReducer";
import { IAdjustmentDetail, IAdjustmentDetails } from "../../../../redux/types/IAdjustmentDetails";
import { IAdjustmentState } from "../../../../redux/types/IAdjustmentState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import ActionButton from "../../../components/actionButton/ActionButton";
import Loader from "../../../components/loader/Loader";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { ADJUSTMENT_SEARCH_IP_NUMBERS, ADJUSTMENT_SEARCH_PRODUCT_NUMBERS, ADJUSTMENT_SEARCH_WORK_NUMBERS, ADJUSTMENT_SELECT_ALLOCATIONS, ALL_ROLES, UPDATE_ADJUSTMENTS_ROLE } from "../../../Consts";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IDistribution } from "../../../types/usageTypes/IDistribution";
import { IDistributionPool } from "../../../types/usageTypes/IDistributionPool";
import { AdjustmentMaintenanceGeneralDataView } from "../adjustmentMaintenanceGeneralDataView/AdjustmentMaintenanceGeneralDataView";
import AdjustmentMaintenanceToolbar from "../adjustmentMaintenanceToolbar/AdjustmentMaintenanceToolbar";
import AllocationAdjustmentDetailsView from "../allocationAdjustmentDetailsView/AllocationAdjustmentDetailsView";
import AllocationSelectionView, { AllocationDetailRowType, AllocationHeaderRowType } from "../allocationSelectionView/AllocationSelectionView";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { If } from "../../../../core/components/If";
import { IAllocationSearchModel } from "../../../../redux/types/IAllocationSearchModel";

export interface IAdjustmentMaintenancePageProps {
    adjustmentMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    adjustmentMaintenanceToolbarData: IRepertoireComponentDataItem;
    adjustmentMaintenanceGridsViewData: IRepertoireComponentDataItem;
    adjustmentMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    allocationAdjustmentDetailsViewData: IRepertoireComponentDataItem;
    dataGridTableData: IRepertoireComponentDataItem;
    adjustment: IAdjustmentState;
    setChangesMade: typeof setChangesMade;
    updateAdjustmentField: typeof updateAdjustmentField;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal: typeof showModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    addTab: typeof addTab;
    clearModalSearchResults: typeof clearModalSearchResults;
    saveChanges: (adjustment: IAdjustmentState, saveOption?: string) => void;
    addNewAdjustment: (lookupValues: ILookupDictionary, isNew?: boolean, adjustmentMaintenanceGeneralViewData?: IRepertoireComponentDataItem) => void;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    lookupValues: ILookupDictionary;
    editableFields: any[] | undefined;
    undoAdjustmentChanges: (adjustmentId: string) => void;
    resetMessageBanner: typeof resetMessageBanner;
    distributions: IDistribution[];
    copyAdjustment: () => void;
    searchAllocation: (searchParams: IAllocationSearchModel) => void;
    allocationRecords?: AllocationHeaderRowType[];
    postAdjustments: (batchId: number) => void;
    saveInProgress: boolean;
    adjustmentBatchOperationInProgress: boolean;
    searchAdjustmentVersionHistory: (adjustmentId: string, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[]) => void;
    roles?: string[];
}

interface IAdjustmentMaintenancePageState {
    scroll: number;
    toolbarWidth: number | null;
    sourcePools: { code: string; description: string; }[];
    sourceSegments: { code: string; description: string; }[];
    sourceDistributions: { code: string; description: string }[];
    adjustmentPools: { code: string; description: string; }[];
    adjustmentSegments: { code: string; description: string; }[];
    adjustmentDistributions: { code: string; description: string }[];
    searching: boolean;
    workNumbersToSearch?: string[];
    ipNumbersToSearch?: string[];
    productNumbersToSearch?: string[];
    adjustmentState?: IAdjustmentState;
}

export class AdjustmentMaintenancePage extends React.Component<
    IAdjustmentMaintenancePageProps,
    IAdjustmentMaintenancePageState
> {
    toolbarParentRef;
    viewData;
    constructor(props: IAdjustmentMaintenancePageProps) {
        super(props)
        this.state = {
            scroll: 0,
            toolbarWidth: null,
            sourceDistributions: [],
            sourcePools: [],
            sourceSegments: [],
            adjustmentDistributions: [],
            adjustmentPools: [],
            adjustmentSegments: [],
            searching: false,
            workNumbersToSearch: [],
            ipNumbersToSearch: [],
            productNumbersToSearch: []
        };
        this.toolbarParentRef = React.createRef();
        this.viewData = {}
    }

    componentDidMount() {
        const { distributions, adjustment } = this.props;

        const {
            adjustmentMaintenanceGridsViewData: { fields }
        } = this.props
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;
            });
            this.setState(() => ({ adjustmentState: adjustment }))
        }

        if (distributions && distributions.length > 0) {
            let distributionSourceList = [];
            let distributionAdjustmentsList = [];
            distributions.forEach(distribution => {
                if (distribution.distributionStatus === "Closed") {
                    distributionSourceList.push({ code: distribution.distributionCode, description: distribution.distributionCode });
                } else {
                    distributionAdjustmentsList.push({ code: distribution.distributionCode, description: distribution.distributionCode });
                }
            });
            this.setState(() => ({ sourceDistributions: distributionSourceList, adjustmentDistributions: distributionAdjustmentsList }));
        }
        if (adjustment && adjustment.sourceDistribution) {
            this.getPoolsAndSegments(adjustment.sourceDistribution, 'sourceDistribution');
            if (adjustment.sourcePool && adjustment.sourcePool.length == 1)
                this.getPoolsAndSegments(adjustment.sourcePool[0], 'sourcePool');
        }
        if (adjustment && adjustment.adjustmentDistribution) {
            this.getPoolsAndSegments(adjustment.adjustmentDistribution, 'adjustmentDistribution');
            if (adjustment.adjustmentPool)
                this.getPoolsAndSegments(adjustment.adjustmentPool, 'adjustmentPool')
        }
    }

    componentDidUpdate(prevProps: IAdjustmentMaintenancePageProps, prevState: IAdjustmentMaintenancePageState): void {
        const { allocationRecords } = this.props;
        const { searching } = this.state;
        if (searching && allocationRecords) {
            this.setState(() => ({ searching: false }));
        }
    }

    saveAdjustment(saveOption?: string) {
        const { saveChanges, adjustment } = this.props;
        saveChanges(adjustment, saveOption);
    }

    undoChanges = () => {
        const { undoAdjustmentChanges, adjustment } = this.props;
        undoAdjustmentChanges(adjustment.id);
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    selectAllocationHeader = (selectedAllocationDetails: AllocationHeaderRowType) => {
        const { allocationRecords } = this.props;

        const headerID = selectedAllocationDetails.AllocationUsageHeaderId;
       
        allocationRecords.forEach((value, index) => {
            if (value.AllocationUsageHeaderId === headerID)
                value.IsSelected = !value.IsSelected;
        })
    }

    addAdjustmentDetails = (selectedAllocationDetails: AllocationDetailRowType) => {
        const { adjustment, updateAdjustmentField } = this.props;
        let adjustmentDetail: IAdjustmentDetails;

        const adjustmentDetailFrom: IAdjustmentDetail = {
            adjustmentDetailID: 0,
            adjustmentId: adjustment.id,
            allocationHeaderID: selectedAllocationDetails.AllocationUsageHeaderID,
            distribution: adjustment.sourceDistribution,
            pool: selectedAllocationDetails.PoolCode,
            segment: adjustment.sourceSegment,
            workNumber: selectedAllocationDetails.WorkNumber,
            workName: selectedAllocationDetails.WorkName,
            accountNumber: selectedAllocationDetails.PayeeAccountNumber,
            name: selectedAllocationDetails.PayeeName,
            ipNumber: selectedAllocationDetails.PayeeIpNameNumber,
            sharePercentAdjusted: selectedAllocationDetails.SharePct,
            pointsAdjusted: selectedAllocationDetails.PointsAllocated,
            amountAdjusted: selectedAllocationDetails.Amount
        };

        const adjustmentDetailTo: IAdjustmentDetail = {
            adjustmentDetailID: 0,
            adjustmentId: adjustment.id,
            allocationHeaderID: selectedAllocationDetails.AllocationUsageHeaderID,
            distribution: adjustment.adjustmentDistribution,
            pool: (adjustment.adjustmentPool !== '' && adjustment.adjustmentPool != undefined) ? adjustment.adjustmentPool : selectedAllocationDetails.PoolCode,
            segment: adjustment.adjustmentSegment,
            workNumber: selectedAllocationDetails.WorkNumber,
            workName: selectedAllocationDetails.WorkName,
            accountNumber: '',
            name: '',
            ipNumber: undefined,
            sharePercentAdjusted: '',
            pointsAdjusted: '',
            amountAdjusted: ''
        }

        if (adjustment.adjustmentDetails && adjustment.adjustmentDetails.length > 0) {

            adjustmentDetail = adjustment.adjustmentDetails.find(x => x.id === selectedAllocationDetails.AllocationUsageHeaderID);
            if (adjustmentDetail) {
                adjustment.adjustmentDetails = adjustment.adjustmentDetails.filter(x => x.id !== selectedAllocationDetails.AllocationUsageHeaderID);
                adjustmentDetailFrom.adjustmentDetailID = adjustmentDetail.adjustmentDetailFrom.length;
                adjustmentDetail.adjustmentDetailFrom.push(adjustmentDetailFrom);
                adjustment.adjustmentDetails.push(adjustmentDetail);
            }
            else {
                adjustmentDetail = {
                    id: selectedAllocationDetails.AllocationUsageHeaderID,
                    adjustmentDetailFrom: [adjustmentDetailFrom],
                    adjustmentDetailTo: [adjustmentDetailTo]
                }
                adjustment.adjustmentDetails.push(adjustmentDetail);
            }
        }
        else {
            adjustmentDetail = {
                id: selectedAllocationDetails.AllocationUsageHeaderID,
                adjustmentDetailFrom: [adjustmentDetailFrom],
                adjustmentDetailTo: [adjustmentDetailTo]
            }
            adjustment.adjustmentDetails = [adjustmentDetail];
        }

        updateAdjustmentField(adjustment.adjustmentDetails, 'adjustmentDetails');
    }

    getPoolsAndSegments = (value: string, fieldName: keyof IAdjustmentState) => {
        const { distributions, adjustment } = this.props;
        let sourcePoolsList: IDistributionPool[] = [];
        let sourcePools: { code: string, description: string }[] = []
        let adjustmentPoolsList: IDistributionPool[]
        let adjustmentPools: { code: string, description: string }[] = []
        switch (fieldName) {
            case 'sourceDistribution':
                let sourceDistribution = distributions.find(d => d.distributionCode === value);
                sourcePoolsList = sourceDistribution ? sourceDistribution.distributionPools : [];
                sourcePools = [...new Set(sourcePoolsList.map(p => p.poolCode))].sort().map(poolCode => ({ code: poolCode, description: poolCode }));
                this.setState(() => ({ sourcePools }));
                break;
            case 'adjustmentDistribution':
                let adjustmentDistribution = distributions.find(d => d.distributionCode === value);
                adjustmentPoolsList = adjustmentDistribution ? adjustmentDistribution.distributionPools : [];
                adjustmentPools = [...new Set(adjustmentPoolsList.map(p => p.poolCode))].sort().map(poolCode => ({ code: poolCode, description: poolCode }));
                this.setState(() => ({ adjustmentPools }));
                break;
            case 'sourcePool':
                let newSourcePools = adjustment.sourcePool;
                if (newSourcePools.includes(value)) {
                    newSourcePools = newSourcePools.filter(x => x !== value)
                }
                else {
                    newSourcePools.push(value)
                }
                if (newSourcePools.length === 1) {
                    let selectedSourceDistribution = distributions.find(d => d.distributionCode === adjustment.sourceDistribution);
                    sourcePoolsList = selectedSourceDistribution.distributionPools;
                    if (sourcePoolsList.find(p => p.poolCode === newSourcePools[0])) {
                        let sourceSegments = sourcePoolsList.filter(p => p.poolCode === newSourcePools[0]).map(x => x.segment).sort().map(seg => ({ code: seg, description: seg }));
                        this.setState(() => ({ sourceSegments }));
                    }
                }
                break;
            case 'adjustmentPool':
                let selectedAdjustmentDistribution = distributions.find(d => d.distributionCode === adjustment.adjustmentDistribution);
                adjustmentPoolsList = selectedAdjustmentDistribution.distributionPools;
                if (adjustmentPoolsList.find(p => p.poolCode === value)) {
                    let adjustmentSegments = adjustmentPoolsList.filter(p => p.poolCode === value).map(x => x.segment).sort().map(seg => ({ code: seg, description: seg }));
                    this.setState(() => ({ adjustmentSegments }));
                }
                break;
        }
    }

    updateAdjustmentFieldAndChangeState = (value: any, fieldName: keyof IAdjustmentState) => {
        const { updateAdjustmentField } = this.props;
        updateAdjustmentField(value, fieldName);
        this.getPoolsAndSegments(value, fieldName);
    }


    onClickSearch = () => {
        const { searchAllocation, adjustment } = this.props;
        const { workNumbersToSearch, ipNumbersToSearch, productNumbersToSearch } = this.state;
        this.setState(() => ({ searching: true }))
        const model: IAllocationSearchModel =
        {
            ipBaseNumbers: ipNumbersToSearch.filter(x => isNaN(Number(x)) && x !== ''),
            ipNameNumbers: ipNumbersToSearch.filter(x => !isNaN(Number(x)) && x !== '').map(x => Number(x)),
            distribution: adjustment.sourceDistribution,
            pools: adjustment.sourcePool,
            segment: adjustment.sourceSegment,
            workNumbers: workNumbersToSearch.filter(x => x !== ''),
            productNumbers: productNumbersToSearch.filter(x => x !== '')
        }

        searchAllocation(model)
    }

    updateWorkNumbersToSearch = (value) => {
        this.setState(() => ({ workNumbersToSearch: value.split(",") }));
    }

    updateIPNumbersToSearch = (value) => {
        this.setState(() => ({ ipNumbersToSearch: value.split(",") }));
    }

    updateProductNumbersToSearch = (value) => {
        this.setState(() => ({ productNumbersToSearch: value.split(",") }));
    }

    renderAllocationSearchFields() {
        const { adjustment } = this.props;
        const { workNumbersToSearch, ipNumbersToSearch, productNumbersToSearch } = this.state
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">{this.viewData[ADJUSTMENT_SELECT_ALLOCATIONS]}</span>
                    </div>
                </div>
                <div className="searchView">
                    <div key='searchFields' className="row">
                        <SizedTextDataInput
                            fieldType={"text"}
                            label={this.viewData[ADJUSTMENT_SEARCH_WORK_NUMBERS]}
                            fieldName={ADJUSTMENT_SEARCH_WORK_NUMBERS}
                            changeData={this.updateWorkNumbersToSearch}
                            value={workNumbersToSearch.toString()}
                            readOnly={false}
                            isHidden={!this.viewData[ADJUSTMENT_SEARCH_WORK_NUMBERS]}
                        />

                        <SizedTextDataInput
                            fieldType={"text"}
                            label={this.viewData[ADJUSTMENT_SEARCH_IP_NUMBERS]}
                            fieldName={ADJUSTMENT_SEARCH_IP_NUMBERS}
                            changeData={this.updateIPNumbersToSearch}
                            value={ipNumbersToSearch.toString()}
                            readOnly={false}
                            isHidden={!this.viewData[ADJUSTMENT_SEARCH_IP_NUMBERS]}
                        />

                        <SizedTextDataInput
                            fieldType={"text"}
                            label={this.viewData[ADJUSTMENT_SEARCH_PRODUCT_NUMBERS]}
                            fieldName={ADJUSTMENT_SEARCH_PRODUCT_NUMBERS}
                            changeData={this.updateProductNumbersToSearch}
                            value={productNumbersToSearch.toString()}
                            readOnly={false}
                            isHidden={!this.viewData[ADJUSTMENT_SEARCH_PRODUCT_NUMBERS]}
                        />
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                            <ActionButton buttonText="Search" disabled={adjustment.sourceDistribution === '' || adjustment.adjustmentDistribution === '' || adjustment.dataSource === 'Posted'} buttonAction={this.onClickSearch} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderAllocationSearchResultsGrid() {
        const { allocationRecords } = this.props;
        const { searching } = this.state;
        if (allocationRecords) {
            return (
                <AllocationSelectionView
                    searchResults={allocationRecords}
                    viewData={this.viewData}
                    addAdjustmentDetails={this.addAdjustmentDetails}
                    selectAllocationHeader={this.selectAllocationHeader}
                />
            )
        }

        else if (searching) {
            return (
                <Loader />
            )
        }
    }

    render() {
        const {
            adjustmentMaintenanceGeneralDataViewData,
            adjustmentMaintenanceToolbarData,
            adjustmentMaintenanceSaveResultData,
            allocationAdjustmentDetailsViewData,
            tabs,
            activeTab,
            showModal,
            hideModal,
            lookupValues,
            adjustment,
            addNewAdjustment,
            updateAdjustmentField,
            saveInProgress,
            adjustmentBatchOperationInProgress,
            postAdjustments,
            allocationRecords,
            searchAdjustmentVersionHistory,
            copyAdjustment,
            clearModalSearchResults,
            roles
        } = this.props;
        const { scroll, toolbarWidth, sourceDistributions, sourcePools, sourceSegments, adjustmentDistributions, adjustmentPools, adjustmentSegments } = this.state

        const title = adjustment ? adjustment.adjustmentDistribution : null;

        const changesMade = tabs[activeTab].isReadonly ? false : tabs[activeTab].changesMade;
        let isReadonly: boolean = adjustment && adjustment.dataSource === 'Posted' || tabs[activeTab].isReadonly;



        if (this.props.adjustment) {
            return (
                <div>
                    <div><span className="title">{title} ({adjustment.dataSource})</span></div>
                    <If condition={tabs[activeTab].versionNumber != null}>
                        <div><span>Version history number: {tabs[activeTab].versionNumber}</span></div>
                    </If>
                    <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                        <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                    </button>
                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <AdjustmentMaintenanceToolbar
                            changesMade={changesMade}
                            toolbarWidth={toolbarWidth}
                            showYesNoPrompt={showYesNoPrompt}
                            showModal={showModal}
                            hideModal={hideModal}
                            scroll={scroll}
                            saveResultData={adjustmentMaintenanceSaveResultData}
                            toolbarData={adjustmentMaintenanceToolbarData}
                            saveChanges={this.saveAdjustment.bind(this)}
                            clearModalSearchResults={clearModalSearchResults}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            addNewAdjustment={() => addNewAdjustment(lookupValues, true, adjustmentMaintenanceGeneralDataViewData)}
                            activeTab={activeTab}
                            tabs={tabs}
                            adjustment={adjustment}
                            undoAdjustmentChanges={this.undoChanges.bind(this)}
                            isReadonly={isReadonly}
                            copyAdjustment={copyAdjustment}
                            lookupValues={lookupValues}
                            searchVersionHistory={searchAdjustmentVersionHistory}
                            roles={roles}
                        />
                    </div>

                    <AdjustmentMaintenanceGeneralDataView
                        adjustmentMaintenanceGeneralDataViewData={adjustmentMaintenanceGeneralDataViewData}
                        updateAdjustmentField={this.updateAdjustmentFieldAndChangeState}
                        activeTab={activeTab}
                        tabs={tabs}
                        adjustment={adjustment}
                        isReadonly={isReadonly}
                        lookupValues={lookupValues}
                        sourceDistributions={sourceDistributions}
                        sourcePools={sourcePools}
                        sourceSegments={sourceSegments}
                        adjustmentDistributions={adjustmentDistributions}
                        adjustmentPools={adjustmentPools}
                        adjustmentSegments={adjustmentSegments}
                        changesMade={changesMade}
                        saveInProgress={saveInProgress}
                        adjustmentBatchOperationInProgress={adjustmentBatchOperationInProgress}
                        postAdjustmentBatch={postAdjustments}            
                    />
                    {this.renderAllocationSearchFields()}
                    {this.renderAllocationSearchResultsGrid()}
                    <br></br>
                    <AllocationAdjustmentDetailsView
                        allocationAdjustmentDetailsViewData={allocationAdjustmentDetailsViewData}
                        updateAdjustmentField={updateAdjustmentField}
                        showModal={showModal}
                        adjustment={adjustment}
                        allocationRecords={allocationRecords}
                    />
                </div>

            );
        }
        return <div>Loading...</div>;
    }
}

