import { IShoppingCart } from "../types/IShoppingCart";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { ILicenseRequestWorkItem } from "../../redux/types/ILicenseRequestWorkItem";
import { ILicenseRequestWork } from "../types/ILicenseRequestWork";
import { ILicenseRequest } from "../types/ILicenseRequest";
import { ANO_PUBLICACION_FIELD_KEY, AUTOR_FIELD_KEY, A_FIELD_KEY, CHECKBOX_INPUT, DE_PAGINA_FIELD_KEY, DROPDOWN_INPUT, EMPTY_STRING_VALUE, ENTREGA_CONTENIDO_FIELD_KEY, FECHA_POST_FIELD_KEY, FIN_FIELD_KEY, IDIOMA_FIELD_KEY, INICIO_FIELD_KEY, LICENSE_OPEN_STATUS_STATE_KEY, LICENSE_SUBMITTED_STATUS_STATE_KEY, NOMBRE_CURSO_FIELD_KEY, NUMERO_ACCESOS_FIELD_KEY, NUMERO_ARTICULOS_FIELD_KEY, NUMERO_ASISTENTE_FIELD_KEY, NUMERO_CAPITULOS_FIELD_KEY, NUMERO_DESTINATARIO_FIELD_KEY, NUMERO_DE_PAGINAS_FIELD_KEY, NUMERO_DE_RECORTES_FIELD_KEY, NUMERO_GRAFICOS_FIELD_KEY, NUMERO_REPRODUCCIONES_FIELD_KEY, NUMERO_USUARIOS_FIELD_KEY, NUMERO_VOLUMEN_FIELD_KEY, OBRA_COMPLETA_FIELD_KEY, OBSERVACIONES_FIELD_KEY, PORCENTAJE_FIELD_KEY, PRECIO_EJEMPLEAR_FIELD_KEY, SOPORTE_ESTAMPADO_FIELD_KEY, TEXT_INPUT, TEXT_READONLY_IN_REPERTOIRE_INPUT, TITULO_FIELD_KEY, TITULO_NUEVA_OBRA_FIELD_KEY, TITULO_POST_FIELD_KEY, TOTAL_DE_PAGINAS_FIELD_KEY, TOTAL_PAGINA_2_PAGINA_FIELD_KEY, UNIVERSIDAD_INSTITUCION_FIELD_KEY, URL_FIELD_KEY } from "../Consts";
import { IShoppingCartState } from "../../redux/types/IShoppingCartState";
import { ILicenseRequestItem } from "../../redux/types/ILicenseRequestItem";
import { ILicenseInput } from "../types/ILicenseInput";
import { ILicenseInputItem } from "../../redux/types/ILicenseInputItem";
import { ILicenseRequestWorkParameter } from "../types/ILicenseRequestWorkParameter";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { BOOLEAN_YES_NO_LOOKUP } from "../../lookup/Consts";

export class LicenseDetailsMapper {


    private static mapRequestWorkParameterToLicenseRequestWorkItem(licenseWorkRequestItem: ILicenseRequestWorkItem, licenseRequestWorkParameterFields: ILicenseInputItem[], parameters: ILicenseRequestWorkParameter[]): ILicenseRequestWorkItem{
        const result = licenseWorkRequestItem;

        if (parameters.length > 0) {
            parameters.map(p => {
                if (result.licenseRequestWorkID === p.licenseRequestWorkID) {
                    result[this.getFieldNameBaseOnLabel(p.fieldName)] = p.value;
                }
            })
        }

        if (licenseRequestWorkParameterFields === undefined || licenseRequestWorkParameterFields.length <= 0) 
            return result;
        
        licenseRequestWorkParameterFields.map(field => {
            const parameter = parameters.find(p => p.parameterID == field.licenseInputID);
            licenseWorkRequestItem[field.data] = parameter.value;
        })

        return result;
    }

    ///MAP BDO TO STATE
    public static mapLicenseRequestWorkToLicenseRequestWorkItem(licenseWorks: ILicenseRequestWork[],licenseRequestWorkParameterFields: ILicenseInputItem[]): ILicenseRequestWorkItem[]{
        const licenseWorkRequestItem: ILicenseRequestWorkItem[] = [];

        if (licenseWorks && licenseWorks.length > 0) {
            
            licenseWorks.map((l: ILicenseRequestWork) => {
                
                let newItem: ILicenseRequestWorkItem = {
                    licenseRequestID: l.licenseRequestID,
                    licenseRequestWorkID: l.licenseRequestWorkID,
                    price: l.price,
                    workID: l.workID,
                    discountPercentage: l.discountPercentage,
                    netPrice: l.priceNet,
                    percentageVA: l.percentageVA,
                    percentageVAA: l.percentageVAA,
                    percentageVAT: l.percentageVAT,
                    percentageVE: l.percentageVE,
                    priceVAT: l.priceVat,
                    title: l.workTitles,
                    workNumber: l.workNumbers,
                    source: l.source,
                    state: l.state,
                    expirationDate: l.expirationDate,
                    deletedDate: l.deletedDate,
                    description: l.description,
                    administrationPrice: l.priceAdministration,
                    currencyCalculation: l.currencyCalculation,
                    currencyActual: l.currencyActual,
                    currencyExchangeRate: l.currencyExchangeRate,
                    notes: l.notes,
                    creationDate: l.creationDate,
                    lastModifiedDate: l.lastModifiedDate,
                    tokenTypeOfContent: l.tokenTypeOfContent,
                    tokenWork: l.tokenWork,
                    number: 0,
                    order: l.licenseRequestID,
                }
                newItem = LicenseDetailsMapper.mapRequestWorkParameterToLicenseRequestWorkItem(newItem, licenseRequestWorkParameterFields,l.requestWorkParameter);
                licenseWorkRequestItem.push(newItem);
            });
        }
        return licenseWorkRequestItem;

    }


    public static mapLicenseRequestToState(licenseRequest: ILicenseRequest[],licenseRequestWorkParameterFields: ILicenseInputItem[]): ILicenseRequestItem[]{
        let licenseRequestItem: ILicenseRequestItem[] = [];
        
        if (licenseRequest && licenseRequest.length > 0) {
            licenseRequest.map(li => {
                const newItem: ILicenseRequestItem = {
                    administrationPrice: li.administrationPrice,
                    licenseRequestID: li.licenseRequestID,
                    shoppingCartID: li.shoppingCartID,
                    amountTotal: li.amountTotal,
                    amountVat: li.amountVat,
                    billingAddress: { value: li.billingAddress, inputType: DROPDOWN_INPUT },
                    billingName: { value: li.billingName, inputType: DROPDOWN_INPUT },
                    createdDate: li.createdDate,
                    description: li.description,
                    finalized: li.finalized,
                    lastModifiedDate: li.lastModifiedDate,
                    licenseCode: li.licenseCode,
                    licenseeAddress: { value: li.licenseeAddress, inputType: DROPDOWN_INPUT },
                    licenseeName: { value: li.licenseeName, inputType: DROPDOWN_INPUT },
                    licenseRequestWorks: [],
                    paid: li.paid,
                    price: li.price,
                    reuseDetailsForBilling: { value: false, inputType: CHECKBOX_INPUT },
                    vat: li.Vat,
                    status: { value: ComponentsHelper.isEmpty(li.licenseCode) ? LICENSE_OPEN_STATUS_STATE_KEY : LICENSE_SUBMITTED_STATUS_STATE_KEY, inputType: TEXT_READONLY_IN_REPERTOIRE_INPUT },
                    client: li.client,
                    clientValue: { value: li.clientValue, inputType: DROPDOWN_INPUT },
                    licenseeNameValue: {value: li.licenseeNameValue, inputType: DROPDOWN_INPUT},
                    licenseeAddressValue: {value: li.licenseeAddressValue, inputType: DROPDOWN_INPUT},
                    billingNameValue: {value: li.billingNameValue, inputType: DROPDOWN_INPUT},
                    billingAddressValue: { value: li.billingAddressValue, inputType: DROPDOWN_INPUT },
                    signerNameValue: { value: li.signerNameValue, inputType: DROPDOWN_INPUT },
                    signerName: { value: li.signerName, inputType: DROPDOWN_INPUT },
                    
                }
                newItem.licenseRequestWorks = LicenseDetailsMapper.mapLicenseRequestWorkToLicenseRequestWorkItem(li.licenseRequestWorks, licenseRequestWorkParameterFields);
                licenseRequestItem.push(newItem)
            });
        } else {
            licenseRequestItem.push({
                licenseRequestID: 0,
                shoppingCartID: 0,
                billingAddress: { inputType: DROPDOWN_INPUT, value: EMPTY_STRING_VALUE },
                billingNameValue: { inputType: DROPDOWN_INPUT, value: EMPTY_STRING_VALUE },
                billingName: { inputType: DROPDOWN_INPUT, value: EMPTY_STRING_VALUE },
                billingAddressValue: { inputType: DROPDOWN_INPUT, value: EMPTY_STRING_VALUE },
                licenseeAddress: { inputType: DROPDOWN_INPUT, value: EMPTY_STRING_VALUE },
                licenseeAddressValue: { inputType: DROPDOWN_INPUT, value: EMPTY_STRING_VALUE },
                licenseeName: {inputType: DROPDOWN_INPUT, value: EMPTY_STRING_VALUE},
                licenseeNameValue: { inputType: DROPDOWN_INPUT, value: EMPTY_STRING_VALUE },
                client:  EMPTY_STRING_VALUE,
                clientValue: { value: EMPTY_STRING_VALUE, inputType: DROPDOWN_INPUT },
                signerName: {inputType: DROPDOWN_INPUT, value: EMPTY_STRING_VALUE},
                signerNameValue: { inputType: DROPDOWN_INPUT, value: EMPTY_STRING_VALUE },
                status: { inputType: TEXT_INPUT, value: "Open" },
                reuseDetailsForBilling: {inputType: BOOLEAN_YES_NO_LOOKUP, value: false}
            });
        }
        return licenseRequestItem;
    }

    public static mapLicenseToState(cart: IShoppingCart, lookupValues: ILookupDictionary,licenseRequestWorkParameterFields: ILicenseInputItem[]): IShoppingCartState {
        if (!cart) return null;
        
        const {
            shoppingCartID,
            licenseRequests,
            commercialUseToken,
            createdDate,
            geographicalToken,
            lastModifiedDate,
            licensingUsesID,
            shoppingCartName,
            sectorToken,
            userID,
            userName,
            userTypeToken
        } = cart;

        let shoppingCartState: IShoppingCartState = {
            commercialUse: { value: commercialUseToken, inputType: DROPDOWN_INPUT },
            createdDate: createdDate === null ? new Date().toISOString() : createdDate.toString(),
            geographicalArea: { value: geographicalToken, inputType: DROPDOWN_INPUT },
            lastModifiedDate: lastModifiedDate === null ? new Date().toISOString() : lastModifiedDate.toString(),
            licenseRequests: [],
            uses: {value:licensingUsesID, inputType: DROPDOWN_INPUT},
            shoppingCartName: {value: shoppingCartName, inputType: TEXT_INPUT},
            sector: {value: sectorToken, inputType: DROPDOWN_INPUT},
            shoppingCartID: shoppingCartID,
            userID: userID,
            isUserExternal: {value:false, inputType: CHECKBOX_INPUT},
            userName: {value:userName, inputType: TEXT_INPUT},
            userTypes: { inputType: DROPDOWN_INPUT, value: userTypeToken },
            userType: { inputType: DROPDOWN_INPUT, value: EMPTY_STRING_VALUE },
            licenseWorkContributors: undefined
        };

        shoppingCartState.licenseRequests = LicenseDetailsMapper.mapLicenseRequestToState(licenseRequests,licenseRequestWorkParameterFields)

        return shoppingCartState;
    }

    public static mapLicenseInputToLicenseInputItem(licenseInputs: ILicenseInput[]): ILicenseInputItem[]{

        let licenseInputItems: ILicenseInputItem[] = [];

        if (licenseInputs && licenseInputs.length > 0) {
            licenseInputs.map(i => licenseInputItems.push({
                fieldType: TEXT_INPUT,
                label: i.label,
                licenseInputID: i.licenseInputID,
                licensingRequestTypeID: i.licensingRequestTypeID,
                value: EMPTY_STRING_VALUE,
                data: LicenseDetailsMapper.getFieldNameBaseOnLabel(i.label),
                origin: i.origin
            }))
        }
        return licenseInputItems;
    }

    ///MAP STATE TO BDO

    public static mapLicenseRequestWorkItemToLicenseRequestWork(licenseRequestWorks: ILicenseRequestWorkItem[],licenseRequestWorkParameterFields: ILicenseInputItem[]) : ILicenseRequestWork[] {
        const licensesWorks: ILicenseRequestWork[] = [];

        if (licenseRequestWorks && licenseRequestWorks.length > 0) {
            licenseRequestWorks.map(l => 
                licensesWorks.push({
                    licenseRequestID: l.licenseRequestID,
                    licenseRequestWorkID: l.licenseRequestWorkID,
                    price: l.price,
                    workID: l.workID,
                    discountPercentage: l.discountPercentage,
                    priceNet: l.netPrice,
                    percentageVA: l.percentageVA,
                    percentageVAA: l.percentageVAA,
                    percentageVAT: l.percentageVAT,
                    percentageVE: l.percentageVE,                    
                    priceVat: l.priceVAT,
                    workTitles: l.title,
                    workNumbers: l.workNumber,
                    source: l.source,
                    state: l.state,
                    expirationDate: l.expirationDate,
                    deletedDate: l.deletedDate,
                    description: l.description,
                    priceAdministration: l.administrationPrice,
                    currencyCalculation: l.currencyCalculation,
                    currencyActual: l.currencyActual,
                    currencyExchangeRate: l.currencyExchangeRate,
                    notes: l.notes,
                    creationDate: l.creationDate,
                    lastModifiedDate: l.lastModifiedDate,
                    tokenTypeOfContent: l.tokenTypeOfContent,
                    tokenWork: l.tokenWork,
                    requestWorkParameter: LicenseDetailsMapper.mapRequestWorkParameterItemToRequestWorkParameter(l, licenseRequestWorkParameterFields)
                    })
                )
        }

        return licensesWorks;
    }

    private static mapRequestWorkParameterItemToRequestWorkParameter(licenseRequestWorkItem: ILicenseRequestWorkItem, licenseRequestWorkParameterFields: ILicenseInputItem[]): ILicenseRequestWorkParameter[]{
        
        const workParameter: ILicenseRequestWorkParameter[] = [];
        if (licenseRequestWorkItem.licenseRequestWorkParameter !== undefined && licenseRequestWorkItem.licenseRequestWorkParameter.length > 0) {
            licenseRequestWorkItem.licenseRequestWorkParameter.map(field => workParameter.push({
                parameterID: field.licenseInputID,
                licensingRequestTypeID: field.licensingRequestTypeID,
                licenseRequestWorkID: licenseRequestWorkItem.licenseRequestWorkID,
                value: licenseRequestWorkItem[field.data] ?? null,
                fieldName: licenseRequestWorkItem[field.label]
            }))
            return workParameter;
        }
        if (licenseRequestWorkParameterFields === undefined || licenseRequestWorkParameterFields.length <= 0)
            return workParameter;

        licenseRequestWorkParameterFields.map(field => workParameter.push({
            parameterID: field.licenseInputID,
            licensingRequestTypeID: field.licensingRequestTypeID,
            licenseRequestWorkID: licenseRequestWorkItem.licenseRequestWorkID,
            value: licenseRequestWorkItem[field.data] ?? null,
            fieldName: licenseRequestWorkItem[field.label]
        }))
        return workParameter;
    }

    public static mapLicenseRequestStateToLicense(licenseRequestState: ILicenseRequestItem[],licenseRequestWorkParameterFields: ILicenseInputItem[]) :ILicenseRequest[] {
        let licenseRequest: ILicenseRequest[] = [];
        
        if (!licenseRequestState) return licenseRequest;

        licenseRequestState.map(li => licenseRequest.push({
            licenseRequestID: li.licenseRequestID,
            shoppingCartID: li.shoppingCartID,
            administrationPrice: li.administrationPrice,
            amountTotal: li.amountTotal,
            amountVat: li.amountVat,
            billingAddress: li.billingAddress.value,
            billingName: li.billingName.value,
            client: li.client,
            clientID: li.clientID,
            createdDate: li.createdDate,
            description: li.description,
            finalized: li.finalized,
            lastModifiedDate: li.lastModifiedDate,
            licenseCode: li.licenseCode,
            licenseeAddress: li.licenseeAddress.value,
            licenseeName: li.licenseeName.value,
            paid: li.paid,
            price: li.price,
            signerName: li.signerName.value,
            Vat: li.vat,
            clientValue: li.clientValue.value,
            licenseeAddressValue: li.licenseeAddressValue.value,
            licenseeNameValue: li.licenseeNameValue.value,
            billingAddressValue: li.billingAddressValue.value,
            billingNameValue: li.billingNameValue.value,
            signerNameValue: li.signerNameValue.value,
            licenseRequestWorks: LicenseDetailsMapper.mapLicenseRequestWorkItemToLicenseRequestWork(li.licenseRequestWorks,licenseRequestWorkParameterFields)
            
        }));

        return licenseRequest;
        
    }

    public static mapShoppingCartStateToShoppingCart(shoppingCartState: IShoppingCartState,licenseRequestWorkParameterFields: ILicenseInputItem[]) : IShoppingCart {
        if (!shoppingCartState) return null;
        const {
            commercialUse,
            createdDate,
            geographicalArea,
            lastModifiedDate,
            licenseRequests,
            uses,
            shoppingCartName,
            sector,
            shoppingCartID,
            userID,
            userTypes,
            userName
         } = shoppingCartState;

        let shoppingCart: IShoppingCart = {
            commercialUseToken: commercialUse.value,
            createdDate: (createdDate === null ? new Date() : new Date(createdDate)),
            geographicalToken: geographicalArea.value,
            lastModifiedDate: new Date(),
            licenseRequests: LicenseDetailsMapper.mapLicenseRequestStateToLicense(licenseRequests,licenseRequestWorkParameterFields),
            licensingUsesID: uses.value,
            shoppingCartName: shoppingCartName.value,
            sectorToken: sector.value,
            shoppingCartID: shoppingCartID,
            userID: Number(userID),
            userTypeToken: userTypes.value,
            userName: userName.value
        }
        return shoppingCart;
    }

    public static createNewLicense(lookups: ILookupDictionary): IShoppingCart {
        const license: IShoppingCart = {
            shoppingCartID: 0,
            userTypeToken: undefined,
            commercialUseToken: undefined,
            licenseRequests: [{
                licenseRequestID: 0,
                shoppingCartID: 0,
                licenseRequestWorks: []
            }],
            shoppingCartName: EMPTY_STRING_VALUE,
            userID: 0,
            licensingUsesID: undefined,
            sectorToken: undefined,
            geographicalToken: undefined,
            createdDate: new Date(),
            lastModifiedDate: new Date()
        }

        return license;
    }

    public static createNewLicenseState(lookups: ILookupDictionary,licenseRequestWorkParameterFields: ILicenseInputItem[]): IShoppingCartState {
        return LicenseDetailsMapper.mapLicenseToState(LicenseDetailsMapper.createNewLicense(lookups), lookups,licenseRequestWorkParameterFields);
    }

    private static getFieldNameBaseOnLabel(label: string): string {
        
        switch (label) {
            case DE_PAGINA_FIELD_KEY: {
                return 'dePagina'
            }
            case A_FIELD_KEY: {
                return 'a'
            }
            case NUMERO_DE_PAGINAS_FIELD_KEY: {
                return 'numeroDePagina'
            }
            case TOTAL_DE_PAGINAS_FIELD_KEY: {
                return 'totalPaginas'
            }
            case PORCENTAJE_FIELD_KEY: {
                return 'porcentaje'
            }
            case OBRA_COMPLETA_FIELD_KEY: {
                return 'obraCompleta'
            }
            case NUMERO_CAPITULOS_FIELD_KEY: {
                return 'numeroCapitulos'
            }
            case NUMERO_ARTICULOS_FIELD_KEY: {
                return 'numeroArticulos'
            }
            case NUMERO_GRAFICOS_FIELD_KEY: {
                return 'numeroGraficos'
            }
            case IDIOMA_FIELD_KEY: {
                return 'idioma'
            }
            case OBSERVACIONES_FIELD_KEY: {
                return 'observaciones'
            }
            case NOMBRE_CURSO_FIELD_KEY: {
                return 'nombreDelCurso'
            }
            case UNIVERSIDAD_INSTITUCION_FIELD_KEY: {
                return 'universidadInstitucion'
            }
            case PRECIO_EJEMPLEAR_FIELD_KEY: {
                return 'precioEjemplar'
            }
            case TITULO_NUEVA_OBRA_FIELD_KEY: {
                return 'tituloNuevaObra'
            }
            case SOPORTE_ESTAMPADO_FIELD_KEY: {
                return 'soporteDelEstampado'
            }
            case ENTREGA_CONTENIDO_FIELD_KEY: {
                return 'entregaDelContenido'
            }
            case NUMERO_REPRODUCCIONES_FIELD_KEY: {
                return 'numeroDeReproducciones'
            }
            case NUMERO_ACCESOS_FIELD_KEY: {
                return 'numeroDeAccesos'
            }
            case NUMERO_USUARIOS_FIELD_KEY: {
                return 'numeroDeUsuarios'
            }
            case NUMERO_DESTINATARIO_FIELD_KEY: {
                return 'numeroDeDestinatarios'
            }
            case NUMERO_ASISTENTE_FIELD_KEY: {
                return 'numeroDeAsistente'
            }
            case NUMERO_DE_RECORTES_FIELD_KEY: {
                return 'numeroDeRecortes'
            }
            case INICIO_FIELD_KEY: {
                return 'inicio'
            }
            case FIN_FIELD_KEY: {
                return 'fin'
            }
            case URL_FIELD_KEY: {
                return 'url'
            }
            case TITULO_FIELD_KEY: {
                return 'titulo'
            }
            case TOTAL_PAGINA_2_PAGINA_FIELD_KEY: {
                return 'totalPaginas2'
            }
            case ANO_PUBLICACION_FIELD_KEY: {
                return 'anoPublicacion'
            }
            case NUMERO_VOLUMEN_FIELD_KEY: {
                return 'numeroVolumen'
            }
            case AUTOR_FIELD_KEY: {
                return 'autor'
            }
            case FECHA_POST_FIELD_KEY: {
                return 'fechaDelPost'
            }
            case TITULO_POST_FIELD_KEY: {
                return 'tituloDelPost'
            }
        }   
    }
}