import * as React from "react";
import SelectorView from "../components/selectorView/SelectorView";
import { INTRAY, REPERTOIRE, SEARCH_VIEW_AGREEMENTS, PUBLISHER, ADMINISTERED, AGREEMENT_MAINTENANCE_TOOLBAR, ASSIGNOR, ASSIGNEE, ALL_ROLES, UPDATE_AGREEMENTS_ROLE, VIEW_AGREEMENTS_ROLE, AGREEMENTS_PAGE_VIEW } from "../Consts";
import { getAgreementDetailsFailure, setAgreementDataSource, setAgreementSearchType } from "../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import SearchView from "../components/searchView/SearchView";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IAgreementSearchResult } from "../types/IAgreementSearchResult";
import { IAgreementSearchQuery } from "../types/IAgreementSearchQuery";
import { DataSource } from "../types/DataSource";
import { getDataAction } from "../components/toolBar/ToolbarHelper";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import { AGREEMENT_MAINTENANCE_GROUP } from "../ConfigurationConsts";

export interface IAgreementsPageProps {
    agreementsPageData: IRepertoireComponentDataItem;
    dataSource: string;
    setAgreementDataSource: typeof setAgreementDataSource;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    searchAgreements: (query: IAgreementSearchQuery, lookupValues: ILookupDictionary) => void;
    agreementsSearchResults: IAgreementSearchResult[];
    getAgreementDetails: (dataSource: string, agreementID: number, lookups: ILookupDictionary, dataAction: IDataActionToolbar[],agreementMaintenanceGeneralDataView: IRepertoireComponentDataItem) => void;
    addNewAgreement: (lookups: ILookupDictionary, isNew?: boolean, dataAction?: IDataActionToolbar[], agreementMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) => void;
    searchSuccessful: boolean;
    agreementMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    searchType: string;
    setAgreementSearchType: typeof setAgreementSearchType;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    sortSearchResults: (name: string, results: any) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    getShowCommentSubjectAsDropdownConfigurationParameter?: (group: string) => void;
    currentUser?:string;
    addToPackages?:(userName:string,entityType:string,entityID:number)=>void;
}
interface IState {
    searchType?: string;
    loaded: boolean;
}

const AgreementsPage = (props: IAgreementsPageProps) => {
    const [loaded, setLoaded] = React.useState(false)
    const { instance, accounts } = useMsal()
    
    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getRoles();
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])

    const checkIfLoaded = () => {
        const { agreementsPageData } = props;

        if (agreementsPageData.fields && agreementsPageData.fields.length > 1 && !loaded) {
            setLoaded(true)
            const header = agreementsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    const addNewAgreement = (): void => {
        const { addNewAgreement, lookupValues, agreementMaintenanceGeneralDataViewData } = props;
        const actionList = getDataAction(DataSource.Intray, AGREEMENT_MAINTENANCE_TOOLBAR);
        addNewAgreement(lookupValues, true, actionList, agreementMaintenanceGeneralDataViewData);
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const getUpdateAgreementRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(UPDATE_AGREEMENTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    const getViewRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(VIEW_AGREEMENTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    if (loaded) {
        const {
            dataSource,
            searchType,
            setAgreementDataSource,
            setAgreementSearchType,
            agreementsPageData: {
                fields: {
                    0: { data: dataSourceTitle },
                    1: { data: searchTypeTitle }
                }
            },
            searchViewData,
            searchResultsTableData,
            searchAgreements,
            agreementsSearchResults,
            lookupValues,
            searchSuccessful,
            agreementMaintenanceGeneralDataViewData,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            resetPagination,
            updatePagination,
            sortSearchResults,
            roles,
            getShowCommentSubjectAsDropdownConfigurationParameter,
            currentUser,
            addToPackages
        } = props;

        const getAgreementDetails = (dataSource: string, agreementID: number) => {
            const actionList = getDataAction(dataSource,AGREEMENT_MAINTENANCE_TOOLBAR);
            props.getAgreementDetails(dataSource, agreementID, lookupValues,actionList,agreementMaintenanceGeneralDataViewData);
        }
        const username = sessionStorage.getItem('username');

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">Find Agreement</span>
                        <div className="newWorkButton" title="Create New Agreement" hidden={getUpdateAgreementRoleDisabled()}>
                            <div className="IconTextButton" onClick={addNewAgreement}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                    New Agreement
                                </div>
                        </div>
                    </div>
                </div>
                    <div className="row">
                        <SelectorView
                            selection={dataSource}
                            setSelection={setAgreementDataSource}
                            option1={REPERTOIRE}
                            option2={INTRAY}
                            selectorTitle={dataSourceTitle}
                        />

                        <SelectorView
                            selection={searchType}
                            setSelection={setAgreementSearchType}
                            option1={ASSIGNOR}
                            option2={ASSIGNEE}
                            selectorTitle={searchTypeTitle}
                        />
                    </div>
                <SearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_AGREEMENTS}
                    modalProps={undefined}
                    dataSource={dataSource}
                    hideModal={undefined}
                    searchAgreements={searchAgreements}
                    agreementsSearchResults={agreementsSearchResults}
                    getAgreementDetails={getAgreementDetails}
                    lookupValues={lookupValues}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    updatePagination={updateUserPagination}
                    searchType={searchType}
                    sortSearchResults={sortSearchResults}
                    searchDisabled={getViewRoleDisabled()}
                    currentUser={username}
                    addToPackages={addToPackages}
                    getShowCommentSubjectAsDropdownConfigurationParameter={getShowCommentSubjectAsDropdownConfigurationParameter}
                    groupName={AGREEMENT_MAINTENANCE_GROUP}
                />
            </div>
        );
    }
    return <div />;
}

export default AgreementsPage;