import * as React from "react";
import {
    USAGE_MAINTENANCE_TOOLBAR,
    SAVE_METHOD_TOOLBAR,
    SAVE_CHANGES_STATE_KEY,
    ALL_ROLES,
    UPDATE_USAGES_ROLE
} from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { clearModalSearchResults, nextWorkflowInSession, startWorkflowSession } from "../../../../redux/reducers/RepertoireReducer";
import { showYesNoPrompt, hideModal, showModal } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IDataActionToolbar } from "../../../../repertoire/types/IDataActionToolbar";
import ToolbarComponent from "../../../components/usageComponents/toolBar/ToolBarComponent";
import { IUsageGroupState } from "../../../../redux/types/IUsageGroupState";
import { IWorkflowSearchResult } from "../../../types/IWorkflowSearchResult";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { ITreeData } from "../../../types/ITreeData";
import { IWorkflow } from "../../../types/IWorkflow";
import { IWorkflowParams } from "../../../types/IWorkFlowParams";

export interface IUsageMaintenanceToolbarProps {
    changesMade: boolean;
    hideModal: typeof hideModal;
    showModal: typeof showModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    clearModalSearchResults?: typeof clearModalSearchResults;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData: IRepertoireComponentDataItem;
    activeTab: number;
    tabs: ITabReduxItem[];
    saveChanges: () => void;
    resetMessageBanner: () => void;
    isNew?: boolean;
    currentUser?: string;
    usageMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    usageGroup: IUsageGroupState;
    workflowFieldsData?: IRepertoireComponentDataItem;
    isWorkflowSessionStarted?: boolean;
    startWorkflowSession?: typeof startWorkflowSession;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    workflowSessionItems?: IWorkflowSearchResult[];
    currentWorkflowIndex?: number;
    isOpenForWorkflowSession?: boolean;
    skipWorkWorkflowItem: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    formatFields: FormatFields[];
    lookupValues: ILookupDictionary;
    refreshWorkDetails?: () => void;
    saveWorkflow?: (workflowparams: IWorkflowParams) => void;
    completeWorkflowOnSave: (value: boolean) => void;
    roles?: string[];
    updateWorkflowAndEntityStatus?: (workflowparams: IWorkflowParams, approvalStatus: boolean) => void;
    updateClaimWorkflowAndEntityStatus?: (workflowparams: IWorkflowParams, callFrom: string, refreshDetails: () => void) => void;
}

export interface IUsageMaintenanceToolbarState {
    dataAction: IDataActionToolbar[];
}

export type UsageMaintenanceToolbarKeys = keyof IUsageMaintenanceToolbarProps;

class UsageMaintenanceToolbar extends React.Component<
    IUsageMaintenanceToolbarProps,
    IUsageMaintenanceToolbarState
> {
    actionData;

    constructor(props) {
        super(props);
        this.state = {
            dataAction: this.getDataAction()
        };
    }

    getUpdateRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_USAGES_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getDataAction() {
        const {
            toolbarData: { fields },
            tabs,
            activeTab
        } = this.props;

        const actionList: IDataActionToolbar[] = [];

        const save: IDataActionToolbar = {
            action: this.saveChangesAction.bind(this),
            component: USAGE_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: '',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(save);

        return actionList;
    }

    saveChangesAction = (usageGroup: IUsageGroupState) => {
        const { saveChanges } = this.props;
        saveChanges()
    }

    render() {
        const {
            showYesNoPrompt,
            hideModal,
            showModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            activeTab,
            tabs,
            toolbarData,
            usageGroup,
            clearModalSearchResults,
            workflowFieldsData,
            isWorkflowSessionStarted,
            startWorkflowSession,
            cancelWorkflowSession,
            nextWorkflowItem,
            skipWorkflowItem,
            workflowSessionItems,
            currentWorkflowIndex,
            isOpenForWorkflowSession,
            skipWorkWorkflowItem,
            formatFields,
            lookupValues,
            refreshWorkDetails,
            saveWorkflow,
            roles,
            updateWorkflowAndEntityStatus,
            updateClaimWorkflowAndEntityStatus,
            completeWorkflowOnSave
        } = this.props;

        let {
            changesMade,
        } = this.props;

        changesMade = !this.getUpdateRoleDisabled() && changesMade

        const isReadonly: boolean = tabs[activeTab].isReadonly;
        let { dataAction } = { ...this.state };

        return (
            <>
                <ToolbarComponent
                    scroll={scroll}
                    usageGroup={usageGroup}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    resetMessageBanner={resetMessageBanner}
                    changesMade={changesMade}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={dataAction}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    showModal={showModal}
                    clearModalSearchResults={clearModalSearchResults}
                    isReadonly={isReadonly}
                    toolbarData={toolbarData}
                    component={USAGE_MAINTENANCE_TOOLBAR}
                    isNew={tabs[activeTab].usageMaintenanceState.isNew}
                    workflowFieldsData={workflowFieldsData}
                    isWorkflowSessionStarted={isWorkflowSessionStarted}
                    startWorkflowSession={startWorkflowSession}
                    cancelWorkflowSession={cancelWorkflowSession}
                    nextWorkflowItem={nextWorkflowItem}
                    skipWorkflowItem={skipWorkflowItem}
                    workflowSessionItems={workflowSessionItems}
                    currentWorkflowIndex={currentWorkflowIndex}
                    isOpenForWorkflowSession={isOpenForWorkflowSession}
                    skipWorkWorkflowItem={skipWorkWorkflowItem}
                    lookupValues={lookupValues}
                    formatFields={formatFields}
                    refreshWorkDetails={refreshWorkDetails}
                    saveWorkflow={saveWorkflow}
                    roles={roles}
                    updateWorkflowAndEntityStatus={updateWorkflowAndEntityStatus}
                    updateClaimWorkflowAndEntityStatus={updateClaimWorkflowAndEntityStatus}
                    completeWorkflowOnSave={completeWorkflowOnSave}
                />
            </>
        );
    }
}

export default UsageMaintenanceToolbar;
