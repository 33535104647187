import { connect } from "react-redux";
import IAppState from "../../../../redux/types/IAppState";
import { IInterestedParty, IInterestedPartyStateKeys } from "../../../types/IInterestedParty";
import { showModal, showYesNoPrompt, hideModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { addTab, sortInterestedPartyFieldData, setChangesMade, updateInterestedPartyField, createNewAccount, resetMessageBanner, updateIpAgreementsFilterStateField, updateEditableFields, setEditableFields, openAccordion, closeAccordion, displayRepresentationFlag, updateRepresentationField, updateRepresenIdsToDelete, setWorkGroupMaintenanceState, incrementTempID, updateIPNames, updateIpRepresentationFilterStateField } from "../../../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { ISocIP } from "../../../types/ISocIP";
import { deleteAccountThunk, filterIpAgreementsThunk, saveAccountChangesThunk, undoIPChangesThunk, updateComponentFieldItemsThunk, searchSocIPVersionHistoryThunk, updateUserPreferencesThunk, getIPMaintenaceDisplaySettingsThunk, loadRepertoireFromPathThunk, getRepresentationGroupsThunk, deleteRepresentationsThunk, ipTransferThunk, mergeIPThunk, filterIpRepresentationThunk } from "../../../../redux/thunks/RepertoireThunks";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IAgreementSearchState, IAgreementSearchStateKeys } from "../../../types/IAgreementSearchState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { IRepertoirePathInfo } from "../../../types/IRepertoirePathInfo";
import { IRepresentationStateKeys } from "../../../types/usageTypes/IRepresentation";
import { newRepresentationThunk } from "../../../../redux/thunks/RepresentationThunks";
import { IDeleteRepresentations } from "../../../types/usageTypes/IDeleteRepresentations";
import { IIpLicensingRepresentation } from "../../../types/IIpLicensingRepresentation";
import { IName } from "../../../types/IName";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import IPMaintenancePageScrollListener from "./IPMaintenancePageScrollListener";
import { IRepresentationSearchState, IRepresentationSearchStateKeys } from "../../../types/IRepresentationSearchState";


export default connect(
    (state: IAppState) => ({
        ipMaintenanceGeneralDataViewData:
            state.repertoire.componentData.IPMaintenanceGeneralDataView,
        ipMaintenanceGridsViewData:
            state.repertoire.componentData.IPMaintenanceGridsView,
        ipMaintenanceAccordionViewData:
            state.repertoire.componentData.IPMaintenanceAccordionView,
        ipMaintenanceToolbarData:
            state.repertoire.componentData.IPMaintenanceToolbar,
        ipMaintenanceSaveResultData:
            state.repertoire.componentData.SaveResult,
        dataGridTableData:
            state.repertoire.componentData.DataGridTable,
        activeTab: state.repertoire.activeTab,
        tabs: state.repertoire.tabs,
        activeAccordian: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].activeAccordions,
        loaded: state.repertoire.tabs[state.repertoire.activeTab].interestedPartyMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].interestedPartyMaintenanceState.loaded,
        ipMaintenancePageUIConfiguration: state.repertoire.ipMaintenancePageUIConfiguration,
        interestedParty: state.repertoire.tabs[state.repertoire.activeTab].interestedPartyMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].interestedPartyMaintenanceState.interestedParty,
        ipMaintenanceTimeRangeSelectorViewData: state.repertoire.componentData.IpMaintenanceTimeRangeSelector,
        ipAgrementsSearchState: state.repertoire.ipAgrementsSearchState,
        ipRepresentationSearchState: state.repertoire.ipRepresentationSearchState,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        workflowFieldsData: state.repertoire.componentData.WorkflowFieldsData,
        lookupValues: state.lookupEntities,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        editableFields: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].editableFields,
        displayRepresentationBoolean: state.repertoire.displayRepresentation,
        representationIds: state.repertoire.tabs[state.repertoire.activeTab].interestedPartyMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].interestedPartyMaintenanceState.representationIds,
        activeAccordion: state.repertoire.tabs[state.repertoire.activeTab].activeAccordions,
        roles: state.account.roles,
        customer:state.account.customer
    }),
    dispatch => ({
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string,
            modalMessage?: string,
            isReadOnly?: boolean,
            key?: string,
            index?: number

        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title,modalMessage,isReadOnly,key,index)
            ),
        hideModal: () => dispatch(hideModal()),
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        addTab: (tab: ITabReduxItem) =>
            dispatch(addTab(tab)),
        setChangesMade: (tabIndex: number, changesMade: boolean) =>
            dispatch(setChangesMade(tabIndex, changesMade)),
        updateInterestedPartyField: (value: any, fieldName: IInterestedPartyStateKeys) =>
            dispatch(updateInterestedPartyField(value, fieldName)),
        updateRepresentationField: (value: any, fieldName: IRepresentationStateKeys) =>
            dispatch(updateRepresentationField(value, fieldName)),
        saveChanges: (account: ISocIP,lookups?: ILookupDictionary, ipNames?: IName[]) =>
            dispatch<any>(saveAccountChangesThunk(account,lookups ,ipNames)),
        addNewAccount: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralDataView?: IRepertoireComponentDataItem) =>
            dispatch<any>(createNewAccount(lookupValues, isNew, ipMaintenanceGeneralDataView)),
        sortIPFieldData: (value: any, fieldName: IInterestedPartyStateKeys) =>
            dispatch(sortInterestedPartyFieldData(value, fieldName)),
        openIPAccordion: (accordionName: string, componentName: string) =>
            dispatch(openAccordion(accordionName, componentName)),
        closeIPAccordion: (accordionName: string, componentName: string) =>
            dispatch(closeAccordion(accordionName, componentName)),
        undoIPChanges: (ipBaseNumber?: string, accountNumber?: string) =>
            dispatch<any>(undoIPChangesThunk(ipBaseNumber, accountNumber)),
        deleteAccount: (ipBaseNumber: string, account: string, activeTab?: number) =>
            dispatch<any>(deleteAccountThunk(ipBaseNumber, account, activeTab)),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps,message?:string) =>
            dispatch(showYesNoPrompt(title, props,message)),
        updateIpAgreementsFilterStateField: (value: any, field: IAgreementSearchStateKeys) =>
            dispatch<any>(updateIpAgreementsFilterStateField(value, field)),
        filterIpAgreements: (searchState: IAgreementSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[]) =>
            dispatch<any>(filterIpAgreementsThunk(searchState, ipBaseNumber, accountNumber, creationClasses)),
        filterIpRepresentations: (searchState: IRepresentationSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[], role?: string) =>
            dispatch<any>(filterIpRepresentationThunk(searchState, ipBaseNumber, accountNumber, creationClasses, role)),
        updateIpRepresentationFilterStateField: (value: any, field: IRepresentationSearchStateKeys) =>
            dispatch<any>(updateIpRepresentationFilterStateField(value, field)),
        setEditableFields: (componentFieldName: string, componentInstance: string) =>
            dispatch<any>(setEditableFields(componentFieldName, componentInstance)),
        updateEditableField: (value: any, key: string) =>
            dispatch(updateEditableFields(value, key)),
        showUpdateFieldsModal: (tittle: string, props: IShowFieldSettingViewModalProps) =>
            dispatch<any>(showUpdateFieldsModal(tittle, props)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        searchSocIPVersionHistory: (socIPID: number, activeTabItem: ITabReduxItem, formats: FormatFields[]) =>
            dispatch<any>(searchSocIPVersionHistoryThunk(socIPID, activeTabItem, formats)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        getIPMaintenaceDisplaySettings: () =>
            dispatch<any>(getIPMaintenaceDisplaySettingsThunk()),
        displayRepresentation: (display: boolean) => dispatch<any>(displayRepresentationFlag(display)),
        loadRepertoireFromPath: (pathInfo: IRepertoirePathInfo, location: Location) => dispatch<any>(loadRepertoireFromPathThunk(pathInfo, location)),
        createNewRepresentation: (ipBaseNumber: string, lookups: ILookupDictionary, isNew?: boolean, representationMaintenanceGeneralDataView?: IRepertoireComponentDataItem) =>
            dispatch<any>(newRepresentationThunk(ipBaseNumber, lookups, isNew, representationMaintenanceGeneralDataView)),
        getRepresentationGroups: (ipBaseNumber: string, activeTab?: number) =>
            dispatch<any>(getRepresentationGroupsThunk(ipBaseNumber, activeTab)),
        deleteRepresentations: (representations: IDeleteRepresentations, activeTab?: number) =>
            dispatch<any>(deleteRepresentationsThunk(representations, activeTab)),
        updateRepresentationIdsToDelete: (representationIds: number[], updatedRepresentationTable: IIpLicensingRepresentation[]) => dispatch(updateRepresenIdsToDelete(representationIds, updatedRepresentationTable)),
        setWorkGroupState: (ipLicensingRepresentation: IIpLicensingRepresentation) => dispatch(setWorkGroupMaintenanceState(ipLicensingRepresentation)),
        ipTransfer: () =>
            dispatch<any>(ipTransferThunk()),
        incrementTempID: () => dispatch<any>(incrementTempID()),
        updateIPNames: (ipNames: IName[]) => dispatch<any>(updateIPNames(ipNames)),
        manualMergeIP: (fromIP: IInterestedParty, toIP: IInterestedParty,ipMaintenanceGeneralDataViewData: IRepertoireComponentDataItem, dataActions: IDataActionToolbar[],lookups: ILookupDictionary) =>
            dispatch<any>(mergeIPThunk(fromIP, toIP,ipMaintenanceGeneralDataViewData, lookups,dataActions)),
    })
)(IPMaintenancePageScrollListener);