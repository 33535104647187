import { connect } from "react-redux";
import IAppState from "../../../../redux/types/IAppState";
import { showModal, showYesNoPrompt, hideModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { addTab,  setChangesMade, resetMessageBanner, updateEditableFields, setEditableFields, openAccordion, closeAccordion, updateArtistField, createNewArtist, sortArtistFieldData, incrementTempID } from "../../../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { ISocIP } from "../../../types/ISocIP";
import {  deleteArtistThunk, saveArtistThunk, undoArtistChangesThunk, updateComponentFieldItemsThunk, updateUserPreferencesThunk } from "../../../../redux/thunks/RepertoireThunks";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { IArtistState, IArtistStateKeys } from "../../../../redux/types/IArtistState";
import { ArtistMaintenancePage } from "./ArtistMaintenancePage";
import { IArtistCore } from "../../../types/IArtistCore";
import ArtistMaintenancePageScrollListener from "./ArtistMaintenancePageScrollListener";


export default connect(
    (state: IAppState) => ({
        artistMaintenanceGeneralDataViewData:
            state.repertoire.componentData.ArtistMaintenanceGeneralDataView,
        artistMaintenanceGridsViewData:
            state.repertoire.componentData.ArtistMaintenanceGridsView,
        artistMaintenanceAccordionViewData:
            state.repertoire.componentData.ArtistMaintenanceAccordionView,
        artistMaintenanceToolbarData:
            state.repertoire.componentData.ArtistMaintenanceToolbar,
        artistMaintenanceSaveResultData:
            state.repertoire.componentData.SaveResult,
        dataGridTableData:
            state.repertoire.componentData.DataGridTable,
        activeTab: state.repertoire.activeTab,
        tabs: state.repertoire.tabs,
        activeAccordion: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].activeAccordions,
        loaded: state.repertoire.tabs[state.repertoire.activeTab].artistMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].artistMaintenanceState.loaded,
        artist: state.repertoire.tabs[state.repertoire.activeTab].artistMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].artistMaintenanceState.artist,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        workflowFieldsData: state.repertoire.componentData.WorkflowFieldsData,
        lookupValues: state.lookupEntities,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        editableFields: state.repertoire.tabs[state.repertoire.activeTab].editableFields && state.repertoire.tabs[state.repertoire.activeTab].editableFields,
        roles: state.account.roles,
    }),
    dispatch => ({
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        hideModal: () => dispatch(hideModal()),
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        addTab: (tab: ITabReduxItem) =>
            dispatch(addTab(tab)),
        setChangesMade: (tabIndex: number, changesMade: boolean) =>
            dispatch(setChangesMade(tabIndex, changesMade)),
        updateArtistField: (value: any, fieldName: IArtistStateKeys) =>
            dispatch(updateArtistField(value, fieldName)),
        saveChanges: (artist: IArtistState) =>
            dispatch<any>(saveArtistThunk(artist)),
        addNewArtist: (lookupValues: ILookupDictionary, isNew?: boolean, artistMaintenanceGeneralDataView?: IRepertoireComponentDataItem) =>
            dispatch<any>(createNewArtist(lookupValues, isNew, artistMaintenanceGeneralDataView)),
        sortArtistFieldData: (value: any, fieldName: IArtistStateKeys) =>
            dispatch(sortArtistFieldData(value, fieldName)),
        openArtistAccordion: (accordionName: string, componentName: string) =>
            dispatch(openAccordion(accordionName, componentName)),
        closeArtistAccordion: (accordionName: string, componentName: string) =>
            dispatch(closeAccordion(accordionName, componentName)),
        undoArtistChanges: (performerID: number) =>
            dispatch<any>(undoArtistChangesThunk(performerID)),
        deleteArtist: (performerID: number, activeTab?: number) =>
            dispatch<any>(deleteArtistThunk(performerID, activeTab)),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        setEditableFields: (componentFieldName: string, componentInstance: string) =>
            dispatch<any>(setEditableFields(componentFieldName, componentInstance)),
        updateEditableField: (value: any, key: string) =>
            dispatch(updateEditableFields(value, key)),
        showUpdateFieldsModal: (tittle: string, props: IShowFieldSettingViewModalProps) =>
            dispatch<any>(showUpdateFieldsModal(tittle, props)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        incrementTempID: () => dispatch<any>(incrementTempID())

    })
)(ArtistMaintenancePageScrollListener);