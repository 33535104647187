import { connect } from "react-redux";
import {openAccordion, closeAccordion, incrementTempID, nextWorkflowInSession, } from "../../../../redux/reducers/RepertoireReducer";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showUpdateFieldsModal, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import { addDroppedFile, addRepertoireAttachmentSuccess, addTab, cleanDroppedFilesRepertoireState, copyExistingAgreement, createNewAgreement, resetMessageBanner, setChangesMade, setEditableFields, setProgressUploadFileRepertoireProccess, sortAgreementFieldData, updateAgreementField, updateAgreementIP, updateEditableFields } from "../../../../redux/reducers/RepertoireReducer";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import { postAgreementBatchThunk } from "../../../../redux/thunks/JobThunks";
import { deleteAgreementThunk, getAgreementDetailsThunk, getCommentCategoryFlagThunk, searchUsagePoolsThunk, getIPDetailsThunk, getWorkDetailsThunk, getProductDetailsThunk, refreshAgreementDetailsThunk, saveAgreementThunk, searchAgreementVersionHistoryThunk, undoAgreementChangesThunk, updateComponentFieldItemsThunk, updateUserPreferencesThunk, cancelWorkflowSessionThunk, skipWorkflowInSessionThunk, skipUsageWorkflowInSessionThunk, saveWorkflowThunk, mergeAgreementThunk,AddtoPackageThunk } from "../../../../redux/thunks/RepertoireThunks";
import { IAgreementState, IAgreementStateKeys } from "../../../../redux/types/IAgreementState";
import IAppState from "../../../../redux/types/IAppState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { DataSource } from "../../../types/DataSource";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IP } from "../../../types/IP";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import { IUsagePoolSearchQuery } from "../../../types/usageTypes/IUsagePoolSearchQuery";
import { getDataIngestionComponentDataThunk, getFilesystemsThunk, refreshDirectoryThunk, selectedNodeChangedThunk } from "../../../../redux/thunks/DataIngestionThunks";
import { addFileToUpload, CleanUploadFilesState, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import IFile from "../../../../redux/types/IFiles";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { ITreeNodeData } from "../../../../treeview/types/TreeNodeData";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { ITreeData } from "../../../types/ITreeData";
import AgreementMaintenancePageScrollListener from "./AgreementMaintenancePageScrollListener";
import { IWorkflowSearchResult } from "../../../types/IWorkflowSearchResult";
import { IWorkflowParams } from "../../../types/IWorkFlowParams";
import { AGREEMENT_WORKFLOW } from "../../../Consts";
import { removeAgreementAttachmentIfExistsThunk } from "../../../../redux/thunks/MembersPortalThunks";

export default connect(
    (state: IAppState) => ({
        agreementMaintenanceGeneralViewData:
            state.repertoire.componentData.AgreementMaintenanceGeneralDataView,
        agreementMaintenanceGridsViewData:
            state.repertoire.componentData.AgreementMaintenanceGridsView,
        agreementMaintenanceAccordionViewData:
            state.repertoire.componentData.AgreementMaintenanceAccordionView,
        agreementMaintenanceToolbarData:
            state.repertoire.componentData.AgreementMaintenanceToolbar,
        agreementMaintenanceSaveResultData:
            state.repertoire.componentData.SaveResult,
        dataGridTableData:
            state.repertoire.componentData.DataGridTable,
        dataSource: state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState.agreement && state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState.agreement.dataSource,
        lookupValues: state.lookupEntities,
        tabs: state.repertoire.tabs,
        activeTab: state.repertoire.activeTab,
        loaded: state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState.loaded,
        agreement: state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState.agreement,
        workMaintenanceGeneralDataViewData: state.repertoire.componentData.WorkMaintenanceGeneralDataView,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        ipMaintenanceGeneralViewData: state.repertoire.componentData.IPMaintenanceGeneralDataView,
        workflowFieldsData: state.repertoire.componentData.WorkflowFieldsData,
        comments: state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState.agreement && state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState.agreement.comments,
        currentUser: state.account.currentUser,
        containerDetailsWindowComponentData: state.dataIngestion.componentData.ContainerDetailsWindow,
        storagePathItemData: state.dataIngestion.componentData.StoragePathItem,
        distributions: state.dataIngestion.distributions,
        formats: state.dataIngestion.formats,
        storagePaths: state.dataIngestion.storagePaths,
        selectedFileSystem: state.dataIngestion.selectedFileSystem,
        destinations: state.dataIngestion.destinations && state.dataIngestion.destinations.lookups,
        droppedFiles: state.dataIngestion.droppedFiles,
        isDropZone: state.dataIngestion.droppedFiles.inDropZone,
        filesystems: state.dataIngestion.filesystems,
        treeNodes: state.dataIngestion.treeNodes,
        selectedNode: state.dataIngestion.selectedNode,
        attachedFiles: state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState.agreement && state.repertoire.tabs[state.repertoire.activeTab].agreementMaintenanceState.agreement.files,
        agreementAttachmentsDropped: state.repertoire.tabs && state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].droppedFiles,
        shareDecimalsToDisplay: state.repertoire.shareDecimalsToDisplay,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        editableFields: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].editableFields,
        sources: state.repertoire.matchingSourceTreeData,
        paginationDetails: state.dataIngestion.paginationDetails,
        saveInProgress: state.repertoire.saveInProgress,
        roles: state.account.roles,
        commentCategoryFlag: state.repertoire.commentCategoryFlag,
        agreementAttachmentTypeRequired: state.repertoire.agreementAttachmentTypeRequired,
        usagePoolsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].usagePoolSearchResult && state.repertoire.tabs[state.repertoire.activeTab].usagePoolSearchResult,
        isWorkflowSessionStarted: state.repertoire.isWorkflowSessionStarted,
        workflowSessionItems: state.repertoire.workflowSessionItems,
        currentWorkflowIndex: state.repertoire.currentWorkflowIndex,
        isOpenForWorkflowSession: state.repertoire.tabs[state.repertoire.activeTab].isEntityForWorkflowSession,
    }),
    dispatch => ({
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        hideModal: () => dispatch(hideModal()),
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) => 
            dispatch(showYesNoPrompt(title, props)),
        clearModalSearchResults: () => 
            dispatch<any>(clearModalSearchResultsThunk()),
        setChangesMade: (tabIndex: number, changesMade: boolean) => 
            dispatch(setChangesMade(tabIndex, changesMade)),
        updateAgreementField: (value: any, fieldName: IAgreementStateKeys) => 
            dispatch(updateAgreementField(value, fieldName)),
        sortAgreementFieldData: (value: any, fieldName: IAgreementStateKeys) => 
            dispatch(sortAgreementFieldData(value, fieldName)),
        copyExistingAgreement: (dataAction: IDataActionToolbar[], userName?: string) => dispatch(copyExistingAgreement(dataAction,userName)),
        deleteAgreement: (dataSource: DataSource, agreementId: number, activeTab: number) => 
            dispatch<any>(deleteAgreementThunk(dataSource, agreementId, activeTab)),
        saveChanges: (updatedAgreement: IAgreementState,
            postAgreement: boolean,
            dataActions?: IDataActionToolbar[],
            workflowsSessionItems?: IWorkflowSearchResult[],
            currentWorkflowIndex?: number,
            isOpenForWorkflowSession?: boolean,
            activeTab?: number) => 
            dispatch<any>(saveAgreementThunk(updatedAgreement, postAgreement, dataActions, workflowsSessionItems, currentWorkflowIndex, isOpenForWorkflowSession, activeTab)),
        addNewAgreement: (lookups: ILookupDictionary, isNew?: boolean,dataAction?: IDataActionToolbar[],agreementMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => 
            dispatch(createNewAgreement(lookups, isNew,dataAction,agreementMaintenanceGeneralDataView)),
        searchUsagePools: (query: IUsagePoolSearchQuery) =>
            dispatch<any>(searchUsagePoolsThunk(query)),
        openWorkAccordian: (accordionName: string, componentName: string) =>
            dispatch(openAccordion(accordionName, componentName)),
        closeWorkAccordian: (accordionName: string, componentName: string) =>
            dispatch(closeAccordion(accordionName, componentName)),
        updateAgreementIP: (agreementAdminIPID: number, ip: IP) => 
            dispatch(updateAgreementIP(agreementAdminIPID, ip)),
        addTab: (tab: ITabReduxItem) => 
            dispatch(addTab(tab)),
        undoAgreement: (dataSource: string, agreementID: number) => 
            dispatch<any>(undoAgreementChangesThunk(dataSource, agreementID)),
        getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[],dataActions?: IDataActionToolbar[],workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem,formats?: FormatFields[]) =>
            dispatch<any>(getWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats,readonlyIndicatorsWorkFlagTypes)),
        getProductDetails: (dataSource: string, productID: number, productMaintenanceGeneralDataView: IRepertoireComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(getProductDetailsThunk(dataSource, productID, productMaintenanceGeneralDataView, formats, false, false)),
        getAgreementDetails: (dataSource: string, agreementID: number, lookups: ILookupDictionary, dataActions?: IDataActionToolbar[], agreementMaintenanceGeneralDataView?: IRepertoireComponentDataItem, isOpenForWorkflowSession?: boolean) =>
            dispatch<any>(getAgreementDetailsThunk(dataSource, agreementID, lookups, dataActions, isOpenForWorkflowSession)),
        getIPDetails: (ipBaseNumber: string, accountNumber: string, ipMaintenanceGeneralViewData: IRepertoireComponentDataItem) =>
            dispatch<any>(getIPDetailsThunk(ipBaseNumber, accountNumber,ipMaintenanceGeneralViewData)),
        setEditableFields: (componentFieldName: string, componentInstance: string) =>
            dispatch<any>(setEditableFields(componentFieldName, componentInstance)),
        updateEditableField: (value: any, key: string) => 
            dispatch(updateEditableFields(value, key)),
        showUpdateFieldsModal:(tittle: string, props: IShowFieldSettingViewModalProps) => 
            dispatch<any>(showUpdateFieldsModal(tittle,props)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => dispatch<any>(addRepertoireAttachmentSuccess(attachedFile)),
        removeAgreementAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => dispatch<any>(removeAgreementAttachmentIfExistsThunk(attachedFile,source)),
        getDataIngestionComponentData: () => dispatch<any>(getDataIngestionComponentDataThunk()),        
        setInDropZone: (inDropZone: boolean) => dispatch(setInDropZone(inDropZone)),
        refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => dispatch<any>(refreshDirectoryThunk(filesystem, sources)),
        addFileToUpload: (file: IFile, isComplete: boolean) => dispatch<any>(addFileToUpload(file,isComplete)),
        setProgressUploadFileProccess: (message: string,file: IFile, isUploadComplete: boolean) => dispatch<any>(setProgressUploadFileProccess(message,file,isUploadComplete)),
        sortColumn:(section: string, ascending: boolean) => dispatch<any>(sortColumn(section,ascending)),
        cleanUploadFilesState:() => dispatch<any>(CleanUploadFilesState()), 
        getFilesystems: (isRepertoireModule?: boolean) => dispatch<any>(getFilesystemsThunk(isRepertoireModule)),
        selectedNodeChanged: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => dispatch<any>(selectedNodeChangedThunk(node, sources)),
        addDroppedFileRepertoire: (file:IFile, inProgress:boolean) => dispatch(addDroppedFile(file,inProgress)),
        setProgressUploadFileRepertoire: (message: string,file: IFile, isUploadComplete:boolean) => dispatch(setProgressUploadFileRepertoireProccess(message,file,isUploadComplete)),
        cleanDroppedFilesRepertoire: () => dispatch(cleanDroppedFilesRepertoireState()),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        refreshAgreementDetails: (activeTabItem: ITabReduxItem,dataSource: string, agreementID: number,editableFields: IRepertoireField[]) =>
            dispatch<any>(refreshAgreementDetailsThunk(dataSource, agreementID,activeTabItem,editableFields)),
        updatePagination: (resultsPerPage: number, currentPage: number, section: string) =>
            dispatch<any>(updatePagination(resultsPerPage, currentPage, section)),
        searchVersionHistory: (agreementID: number, activeTabItem: ITabReduxItem, actionList: IDataActionToolbar[]) => dispatch<any>(searchAgreementVersionHistoryThunk(agreementID, activeTabItem, actionList)),
        postAgreementBatch: (agreementBatchID: number) => dispatch<any>(postAgreementBatchThunk(agreementBatchID)),
        incrementTempID: () => dispatch<any>(incrementTempID()),
        nextWorkflowItem: () => dispatch(nextWorkflowInSession()),
        cancelWorkflowSession: (activeTab: number) => dispatch<any>(cancelWorkflowSessionThunk(activeTab)),
        skipWorkflowItem: (activeTab: number
            , currentWorkflowIndex: number
            , workflows: IWorkflowSearchResult[]
            , lookups: ILookupDictionary
            , otherIndicatorsWorkFlagTypes: string[]
            , dataActions: IDataActionToolbar[] = []
            , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
            , formats: FormatFields[],
            readonlyIndicatorsWorkFlagTypes: string[]) => dispatch<any>(skipWorkflowInSessionThunk(activeTab, currentWorkflowIndex, workflows, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes)),
        skipUsageWorkflowItem: (activeTab: number
            , currentWorkflowIndex: number
            , workflows: IWorkflowSearchResult[]) => dispatch<any>(skipUsageWorkflowInSessionThunk(activeTab, currentWorkflowIndex, workflows)),
        saveWorkflow: (workflowparams: IWorkflowParams) =>
            dispatch<any>(saveWorkflowThunk(workflowparams, AGREEMENT_WORKFLOW)),
        addToPackages:( userName:string,entityType:string,entityID:number)=>dispatch<any>(AddtoPackageThunk(userName,entityType,entityID)),      
        mergeAgreement: (agreement: IAgreementState, agreementId: number, lookups: ILookupDictionary, dataActions: IDataActionToolbar[]) =>
            dispatch<any>(mergeAgreementThunk(agreement, agreementId, dataActions, lookups))
    })
)(AgreementMaintenancePageScrollListener);