import FormattedTimeDataInput from "./FormattedTimeDataInput"
import IAppState from "../../../redux/types/IAppState";
import { ISearchViewKeyEventListenerComponentProps } from "../searchView/SearchViewKeyEventListener";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { connect } from "react-redux";


export interface ITimeDateInputWrapperProps {
    value: any;
    changeData: (value: any, name: IRepertoireStateKeys, isOnBlur?: boolean, index?: number) => void;
    fieldName: string;
    label: string;
    readOnly?: boolean;
    isHidden?: boolean;
    isDataGridSize?: boolean;
    isMandatory?: boolean;
    errors?: any;
    isFormik?: boolean;
}
export interface IStateProps {
    activeTab: number;
    tabs: ITabReduxItem[];}

type ITimeDateInputWrapperConnectProps = ITimeDateInputWrapperProps & IStateProps;

const TimeDateInputWrapper: React.FC<ITimeDateInputWrapperConnectProps> = (props) => {
    return <FormattedTimeDataInput {...props} />
}

export default connect((state: IAppState, props: ITimeDateInputWrapperProps) => ({
    tabs: state.repertoire.tabs,
    activeTab: state.repertoire.activeTab,
}), null)(TimeDateInputWrapper);