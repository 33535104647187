import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import IAppState from "../../redux/types/IAppState";
import { mp_addTab, createNewDraftWork, resetPagination, sortSearchResults, updatePagination, setDataSource, expandWorkResult, expandAllResults } from "../../redux/reducers/MembersPortalReducer";
import {
    searchRegisteredAgreementsThunk,
    getRegisteredAgreementDetailsThunk,
    getDraftAgreementDetailsThunk,
} from "../../redux/thunks/MembersPortalThunks";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IRegisteredAgreementSearchQuery } from "../../membersportal/types/IRegisteredAgreementSearchQuery";
import RegisteredAgreementsPage from "./RegisteredAgreementsPage";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { FormatFields } from "../../redux/types/FormatFields";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { updateUserPreferencesThunk } from "../../redux/thunks/RepertoireThunks";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { MemberPortalSearchType } from "../../redux/types/MemberPortalSearchType";

const RegisteredAgreementsPageContainer = connect(
    (state: IAppState) => ({
        dataSource: state.membersPortal.dataSource,
        registeredAgreementsPageData: state.membersPortal.componentData.RegisteredAgreementsPage,
        searchViewData: state.membersPortal.componentData.SearchView,
        searchResultsTableData: state.membersPortal.componentData.SearchResultsTable,
        registeredAgreementSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].registeredAgreementSearchResult,
        searchSuccessful: state.membersPortal.searchSuccessful,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'registeredAgreements')[0]?.indexOfFirstResult,
        indexOfLastResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'registeredAgreements')[0]?.indexOfLastResult,
        resultsPerPage: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'registeredAgreements')[0]?.resultsPerSection,
        currentPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].currentPage ? state.membersPortal.tabs[state.membersPortal.activeTab].currentPage : undefined,
        registeredAgreementsMaintenanceGeneralDataViewData: state.membersPortal.componentData.RegisteredAgreementMaintenanceGeneralDataView,
        defaultActiveAccordions: state.membersPortal.defaultActiveAccordions,
        allResultsPerPage: state.membersPortal.resultsPerPage,
        roles: state.account.roles,
        memberPortalSearchType: MemberPortalSearchType.MyRegisteredSearch,
        currentUserNameNumber: state.account.currentUserNameNumber,
        expandedWorkResults: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandedWorkResults,
        expandAll: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandAll,
    }),
    (dispatch: Dispatch) => ({
        setDataSource: (dataSource: string) => dispatch(setDataSource(dataSource)),
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
        searchRegisteredAgreements: (query: IRegisteredAgreementSearchQuery,  lookupValues: ILookupDictionary) =>
            dispatch<any>(searchRegisteredAgreementsThunk(query, lookupValues)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) =>
            dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        getRegisteredAgreementDetails: (dataSource: string, registeredAgreementsID: number, lookupValues: ILookupDictionary, registeredAgreementMaintenanceDataViewData: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(getRegisteredAgreementDetailsThunk(dataSource, registeredAgreementsID, lookupValues, registeredAgreementMaintenanceDataViewData, formats)),
        addNewRegisteredAgreements: (lookups: ILookupDictionary, currentUserNameNumber? : string, isNew?: boolean, registeredAgreementsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch(createNewDraftWork(lookups, currentUserNameNumber, isNew, registeredAgreementsMaintenanceGeneralDataViewData, formats)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) =>
            dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        expandWorkResult: (workResult: number) => dispatch<any>(expandWorkResult(workResult)),
        expandAllResults: () => dispatch<any>(expandAllResults()),
        getDraftAgreementDetails: (dataSource: string, draftAgreementsID: number, lookupValues: ILookupDictionary, registeredAgreementsMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(getDraftAgreementDetailsThunk(dataSource, draftAgreementsID, lookupValues, registeredAgreementsMaintenanceGeneralDataViewData, formats)),
    })
)(RegisteredAgreementsPage);
export default RegisteredAgreementsPageContainer;