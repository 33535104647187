import * as React from "react";
import { getLookupValues } from "../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { JOB_STATUS_LOOKUP, JOB_TYPE_LOOKUP } from "../../../lookup/Consts";
import { updateJobSearchStateField } from "../../../redux/reducers/HomeReducer";
import {
    IJobSearchState,
    JOB_STATUS,
    JOB_TYPE,
    JOB_USER,
} from "../../types/IJobSearchState";
import { IComponentDataItem } from "../../../core/types/IComponentDataItem";
import {
    ALL_FIELD,
    FILTER_TITLE_FIELD,
    STATUS_FIELD,
    TYPE_FIELD,
    USER_FIELD,
} from "./JobSearchViewFields";
import DropdownDataInput from "../../../repertoire/components/dropdownDataInput/DropdownDataInput";

interface IJobSearchViewProps {
    lookups: ILookupDictionary;
    users: string[];
    updateJobSearchStateField: typeof updateJobSearchStateField;
    jobSearchState: IJobSearchState;
    jobSearchViewData: IComponentDataItem;
}

interface IJobSearchViewState {
    loaded: boolean;
}

export class JobSearchView extends React.Component<
    IJobSearchViewProps,
    IJobSearchViewState
> {
    viewData;
    constructor(props: IJobSearchViewProps) {
        super(props);
        this.state = { loaded: false };
        this.viewData = {};
    }

    componentDidUpdate() {
        this.load();
    }

    componentDidMount() {
        this.load();
    }

    load() {
        const { loaded } = this.state;
        if (!loaded) {
            if (!this.props.jobSearchViewData) {
                return;
            }

            const {
                jobSearchViewData: { fields },
            } = this.props;

            if (fields)
                fields.forEach((item) => {
                    this.viewData[item.name] = item.data;

                    if (Object.keys(this.viewData).length === fields.length) {
                        this.setState({ loaded: true });
                    }
                });
        }
    }

    render() {
        const { lookups, jobSearchState, updateJobSearchStateField } = this.props;
        const { loaded } = this.state;
        if (!loaded) return <div className="loading" />;

        const renderJobTypes = () => {
            const jobTypes = getLookupValues(JOB_TYPE_LOOKUP, lookups);
            const options = [];
            options.push({ code: 'All', description: 'All' });
            if (jobTypes) {
                jobTypes.map((j) =>
                    options.push({ code: j.code, description: j.description })
                );
            }
            options.sort((x, y) => {
                if (x !== options[0] && y !== options[0]) {
                    if (x.description < y.description) {
                        return -1;
                    }
                    if (x.description > y.description) {
                        return 1;
                    }
                    return 0;
                }
            })
            return options;
        };
        const renderJobStatuses = () => {
            const jobStatuses = getLookupValues(JOB_STATUS_LOOKUP, lookups);
            const options = [];
            options.push({ code: 'All', description: 'All' });
            if (jobStatuses) {
                jobStatuses.map((js) => options.push({ code: js.code, description: js.description })
                );
            }
            return options;
        };
        const renderUsers = () => {
            const { users } = this.props;
            const options = [];

            options.push({ code: 'All', description: 'All' });
            if (users) {
                users.map((u) =>
                    options.push({ code: u, description: u.toLowerCase() })
                );
                options.sort((x, y) => {
                    if (x !== options[0] && y !== options[0]) {
                        if (x.description < y.description) {
                            return -1;
                        }
                        if (x.description > y.description) {
                            return 1;
                        }
                        return 0;
                    }})
            }
            options.sort((x, y) => {
                if (x !== options[0] && y !== options[0]) {
                    if (x.description < y.description) {
                        return -1;
                    }
                    if (x.description > y.description) {
                        return 1;
                    }
                    return 0;
                }
            })
            return options;
        };

        const jobType: string = jobSearchState.jobType
            ? jobSearchState.jobType
            : "All";
        const user: string = jobSearchState.user ? jobSearchState.user : "All";
        const status: string = jobSearchState.jobStatus
            ? jobSearchState.jobStatus
            : "All";

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <h3>{this.viewData[FILTER_TITLE_FIELD]}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-4">
                        <DropdownDataInput
                            options={renderJobTypes()}
                            label={this.viewData[TYPE_FIELD]}
                            fieldName={JOB_TYPE}
                            value={jobType}
                            changeData={updateJobSearchStateField}
                            allowNull={false}
                            isHidden={false}
                        />
                    </div>

                    <div className="form-group col-md-4">
                        <DropdownDataInput
                            options={renderJobStatuses()}
                            label={this.viewData[STATUS_FIELD]}
                            fieldName={JOB_STATUS}
                            value={status}
                            changeData={updateJobSearchStateField}
                            allowNull={false}
                            isHidden={false}
                        />
                    </div>

                    <div className="form-group col-md-4">
                        <DropdownDataInput
                            options={renderUsers()}
                            label={this.viewData[USER_FIELD]}
                            fieldName={JOB_USER}
                            value={user}
                            changeData={updateJobSearchStateField}
                            allowNull={false}
                            isHidden={false}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
