import { IRepertoireStateKeys } from "../../repertoire/types/IRepertoireStateKeys";
import {
    LANGUAGE_LOOKUP,
    WORK_NAME_LOOKUP,
    WORK_PERFORMER_LOOKUP,
    WORK_NUMBER_LOOKUP,
    WORK_TYPE_LOOKUP,
    TERRITORY_LOOKUP,
    WORK_CONTRIBUTOR_TYPE_LOOKUP,
    WORK_CONTRIBUTOR_SUB_TYPE_LOOKUP,
    SET_TYPE_GROUP_LOOKUP,
    AGREEMENT_TYPE_LOOKUP,
    WORK_CONTRIBUTOR_SOURCE_LOOKUP,
    APPLICABLE_WORKS_LOOKUP,
    AGREEMENT_NUMBER_TYPE_LOOKUP,
    PRODUCT_TYPE_LOOKUP,
    PRODUCT_SOURCE_LOOKUP,
    PRODUCT_NUMBER_TYPE_LOOKUP,
    PRODUCT_PERFORMER_TYPE_LOOKUP,
    PRODUCT_NAME_TYPE_LOOKUP,
    AV_CATEGORY_LOOKUP,
    AV_VERSION_CATEGORY_LOOKUP,
    CUE_TYPE_LOOKUP,
    CUE_SHEET_TYPE_LOOKUP,
    AV_WORK_DESTINATION_LOOKUP,
    CODE_OF_REVISION_LOOKUP,
    AV_RIP_ROLE_LOOKUP,
    BOOLEAN_YES_NO_LOOKUP,
    INSTRUMENTATION_LOOKUP,
    INSTRUMENT_LOOKUP,
    DISTRIBUTIONTYPE_LOOKUP,
    ALLOCATIONSTATUS_LOOKUP,
    USAGEMATCHSTATUS_LOOKUP,
    WORK_PERFORMER_NUMBER_LOOKUP,
    ALLOCATION_RULE_LOOKUP,
    USAGE_TYPE_LOOKUP,
    SET_TYPE_RIGHTS_LOOKUP,
    SET_TYPE_LOOKUP,
    DISTRIBUTION_STATUS_LOOKUP,
    DISTRIBUTION_TYPE_LOOKUP,
    MUSIC_USAGE_LOOKUP,
    TRANSLATION_TYPE_LOOKUP,
    SHARE_RIGHT_TYPE_LOOKUP,
    ADJUSTMENT_CATEGORY_LOOKUP,
    ADJUSTMENT_STATUS_LOOKUP,
    AGREEMENT_CATEGORY_LOOKUP,
    CURRENCY_LOOKUP,
    PAYMENTRUN_STATUS_LOOKUP,
    PAYMENTRUN_TYPE_LOOKUP,
    PRODUCTION_TYPE_LOOKUP,
    WORK_STATUS_LOOKUP,
    USAGES_FEES_ERROR_LOOKUP,
    WORK_GENRE_LOOKUP,
    LICENSING_USES_LOOKUP,
    MP_STATEMENT_DATE_FROM_LOOKUP,
    USAGE_GROUP_APPROVAL_STATUS_LOOKUP,
    CLAIM_STATUS_LOOKUP,
    CLAIM_ORIGIN_LOOKUP,
    CLAIM_DEPARTMENT_LOOKUP,
    COMMENT_CATEGORY_LOOKUP,
    CLAIM_TYPE_LOOKUP,
    DISTRIBUTION_SUB_TYPE_LOOKUP,
    DISTRIBUTION_CATEGORY_TYPE_LOOKUP,
    MUSIC_ARRANGEMENT_LOOKUP,
    LYRIC_ADAPTATION_LOOKUP,
    QA_STATUS_LOOKUP,
    JOB_TYPE_LOOKUP,
    SOCIETY_OF_LICENSE_LOOKUP,
    AGREEMENT_RETENTION_LOOKUP,
    AGREEMENT_RETAINED_UNTIL_LOOKUP,
    USAGEGROUP_INCLUDEFEESINERROR_LOOKUP,
    JOB_TIME_RANGE_LOOKUP,
    USAGE_GROUP_QA_STATUS_LOOKUP,
    PORTAL_DEFAULT_VALUES_LOOKUP,
    ADDITIONAL_ATTRIBUTES_LOOKUP
} from "../../lookup/Consts";
import {
    TITLES_KEY,
    LANGUAGE_OBJECT_KEY,
    ARTISTS_KEY,
    NUMBERS_KEY,
    CONTRIBUTORS_KEY,
    SET_TYPE_GROUP_KEY,
    AGREEMENT_TYPE_KEY,
    APPLICABLE_WORKS_KEY,
    AGREEMENT_NUMBERS_KEY,
    PRODUCT_SEARCH_TYPE_STATE_KEY,
    PRODUCT_SEARCH_TYPE_SOURCE_KEY,
    PRODUCT_NUMBERS_KEY,
    PRODUCT_TYPE_STATE_KEY,
    PRODUCT_PERFORMERS_KEY,
    PRODUCT_NAMES_KEY,
    PRODUCT_AV_WORKS_KEY,
    PRODUCT_CONTRIBUTORS_KEY,
    WORK_INSTRUMENTATION_STATE_KEY,
    USAGETYPE_SEARCH_USAGES_STATE_KEY,
    ALLOCATIONSTATUS_SEARCH_USAGES_STATE_KEY,
    MATCHSTATUS_SEARCH_USAGES_STATE_KEY,
    ARTIST_NUMBERS_KEY,
    POOL_RIGHT_TYPES_KEY,
    WORK_TYPE,
    PRODUCT_WORKS_KEY,
    TRANSLATION_TYPE,
    DISTRIBUTION_SETTINGS_KEY,
    AGREEMENT_CATEGORY_STATE_KEY,
    CURRENCY_STATE_KEY,
    PAYMENTRUN_STATE_KEY,
    REPERTOIRE,
    EMPTY_STRING_VALUE,
    USAGE_DETAILS_ROWS_SECTION_STATE_KEY,
    USAGE_GROUP_APPROVAL_STATUS_STATE_KEY,
    ADDITIONAL_NUMBERS_KEY,
    MUSIC_ARRANGEMENT_STATE_KEY,
    LYRIC_ADAPTATION_STATE_KEY,
    USAGE_GROUP_INCLUDE_FEES_IN_ERROR_STATE_KEY
} from "../../repertoire/Consts";
import { ILookupDictionary } from "../types/ILookupDictionary";
import { ILookup, ILookupInstance } from "../types/ILookup";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { MEMBERS_PORTAL } from "../../accounts/Consts";
import { IIPStatus } from "../../repertoire/types/IIPStatus";

export const mapLookupKey = (stateKey: IRepertoireStateKeys, objectKey: string): string => {
    let lookup = '';
    if (stateKey === TITLES_KEY) {
        if (objectKey === LANGUAGE_OBJECT_KEY) lookup = LANGUAGE_LOOKUP;
        else lookup = WORK_NAME_LOOKUP;
    } else if (stateKey === WORK_TYPE) {
        lookup = WORK_TYPE_LOOKUP;
    } else if (objectKey === TRANSLATION_TYPE) {
        lookup = TRANSLATION_TYPE_LOOKUP
    } else if (stateKey === ARTISTS_KEY) {
        lookup = WORK_PERFORMER_LOOKUP;
    } else if (stateKey === ARTIST_NUMBERS_KEY) {
        lookup = WORK_PERFORMER_NUMBER_LOOKUP;
    } else if (stateKey === NUMBERS_KEY) {
        lookup = WORK_NUMBER_LOOKUP;
    } else if (stateKey === ADDITIONAL_NUMBERS_KEY) {
        lookup = WORK_NUMBER_LOOKUP;
    }
    else if (stateKey === SET_TYPE_GROUP_KEY) {
        lookup = SET_TYPE_GROUP_LOOKUP;
    } else if (stateKey === AGREEMENT_TYPE_KEY) {
        lookup = AGREEMENT_TYPE_LOOKUP;
    } else if (stateKey === APPLICABLE_WORKS_KEY) {
        lookup = APPLICABLE_WORKS_LOOKUP;
        if (objectKey === "exclusionUntil")
            lookup = AGREEMENT_RETAINED_UNTIL_LOOKUP;
        if (objectKey === "worksRetainedUntil")
            lookup = AGREEMENT_RETAINED_UNTIL_LOOKUP;
        if (objectKey === "useDefaultCollection")
            lookup = AGREEMENT_RETENTION_LOOKUP;
        if (objectKey === "workCollectionUntil")
            lookup = AGREEMENT_RETAINED_UNTIL_LOOKUP;
    } else if (stateKey === AGREEMENT_NUMBERS_KEY) {
        lookup = AGREEMENT_NUMBER_TYPE_LOOKUP;
    } else if (stateKey === PRODUCT_SEARCH_TYPE_STATE_KEY) {
        lookup = PRODUCT_TYPE_LOOKUP;
    } else if (stateKey === PRODUCT_SEARCH_TYPE_SOURCE_KEY) {
        lookup = PRODUCT_SOURCE_LOOKUP;
    } else if (stateKey === PRODUCT_TYPE_STATE_KEY) {
        lookup = PRODUCT_TYPE_LOOKUP;
    } else if (stateKey === PRODUCT_NUMBERS_KEY) {
        lookup = PRODUCT_NUMBER_TYPE_LOOKUP
    } else if (stateKey === PRODUCT_PERFORMERS_KEY) {
        lookup = WORK_PERFORMER_LOOKUP
    } else if (stateKey === PRODUCT_CONTRIBUTORS_KEY) {
        lookup = AV_RIP_ROLE_LOOKUP
    } else if (stateKey === AGREEMENT_CATEGORY_STATE_KEY) {
        lookup = AGREEMENT_CATEGORY_LOOKUP
    }
    else if (stateKey === CURRENCY_STATE_KEY) {
        lookup = CURRENCY_LOOKUP
    }
    else if (stateKey === MUSIC_ARRANGEMENT_STATE_KEY) {
        lookup = MUSIC_ARRANGEMENT_LOOKUP
    }
    else if (stateKey === LYRIC_ADAPTATION_STATE_KEY) {
        lookup = LYRIC_ADAPTATION_LOOKUP
    }
    else if (stateKey === PRODUCT_NAMES_KEY) {
        if (objectKey === "productNameType") {
            lookup = PRODUCT_NAME_TYPE_LOOKUP
        }
        else {
            lookup = LANGUAGE_LOOKUP
        }
    }
    else if (
        stateKey === CONTRIBUTORS_KEY) {
        if (objectKey === "territory")
            lookup = TERRITORY_LOOKUP;
        if (objectKey === "source")
            lookup = WORK_CONTRIBUTOR_SOURCE_LOOKUP
        if (objectKey === "role")
            lookup = WORK_CONTRIBUTOR_TYPE_LOOKUP;
        if (objectKey === "subType")
            lookup = WORK_CONTRIBUTOR_SUB_TYPE_LOOKUP;
        if (objectKey === "senderRep")
            lookup = BOOLEAN_YES_NO_LOOKUP;
        if (objectKey === "societyOfLicense")
            lookup = SOCIETY_OF_LICENSE_LOOKUP;
    }
    else if (stateKey === PRODUCT_AV_WORKS_KEY) {
        if (objectKey === "territory")
            lookup = TERRITORY_LOOKUP;
        if (objectKey === "category")
            lookup = AV_CATEGORY_LOOKUP;
        if (objectKey === "versionCategory")
            lookup = AV_VERSION_CATEGORY_LOOKUP;
        if (objectKey === "type")
            lookup = CUE_TYPE_LOOKUP;
        if (objectKey === "cueSheetType")
            lookup = CUE_SHEET_TYPE_LOOKUP;
        if (objectKey === "destination")
            lookup = AV_WORK_DESTINATION_LOOKUP;
        if (objectKey === "countryOfOrigin")
            lookup = TERRITORY_LOOKUP;
        if (objectKey === "versionTerritory")
            lookup = TERRITORY_LOOKUP;
        if (objectKey === "codeOfRevision")
            lookup = CODE_OF_REVISION_LOOKUP;
        if (objectKey === "production")
            lookup = PRODUCTION_TYPE_LOOKUP;
    }
    else if (stateKey === WORK_INSTRUMENTATION_STATE_KEY) {
        if (objectKey === "type") {
            lookup = INSTRUMENTATION_LOOKUP;
        }
        else {
            lookup = INSTRUMENT_LOOKUP;
        }
    }
    else if (stateKey === 'allocationRule')
        lookup = ALLOCATION_RULE_LOOKUP;
    else if (stateKey === 'distributionCategoryType')
        lookup = DISTRIBUTION_CATEGORY_TYPE_LOOKUP;
    else if (stateKey === 'qaStatus')
        lookup = QA_STATUS_LOOKUP;
    else if (objectKey === 'usageType')
        lookup = USAGE_TYPE_LOOKUP
    else if (stateKey === POOL_RIGHT_TYPES_KEY) {
        if (objectKey === "economicRights")
            lookup = SET_TYPE_RIGHTS_LOOKUP;
        if (objectKey === "shareSet")
            lookup = SET_TYPE_LOOKUP
    }
    else if (stateKey === 'distributionStatus')
        lookup = DISTRIBUTION_STATUS_LOOKUP;
    else if (stateKey === 'distributionType')
        lookup = DISTRIBUTION_TYPE_LOOKUP;
    else if (stateKey === 'distributionSubType')
        lookup = DISTRIBUTION_SUB_TYPE_LOOKUP;
    else if (stateKey === PRODUCT_WORKS_KEY) {
        if (objectKey === 'musicUsage')
            lookup = MUSIC_USAGE_LOOKUP;
    }
    else if (stateKey === DISTRIBUTION_SETTINGS_KEY) {
        if (objectKey === 'setTypeGroup')
            lookup = SET_TYPE_GROUP_LOOKUP
        else if (objectKey === 'setType')
            lookup = SET_TYPE_LOOKUP
        else if (objectKey === 'shareRightType')
            lookup = SHARE_RIGHT_TYPE_LOOKUP
    }
    else if (stateKey === 'adjustmentCategory')
        lookup = ADJUSTMENT_CATEGORY_LOOKUP
    else if (stateKey === 'adjustmentStatus')
        lookup = ADJUSTMENT_STATUS_LOOKUP
    else if (stateKey === 'paymentRunStatus')
        lookup = PAYMENTRUN_STATUS_LOOKUP
    else if (stateKey === 'paymentRunType')
        lookup = PAYMENTRUN_TYPE_LOOKUP
    else if (stateKey === 'draftWorkStatus')
        lookup = WORK_STATUS_LOOKUP
    else if (stateKey === 'memberStatementViewStatementsFrom')
        lookup = MP_STATEMENT_DATE_FROM_LOOKUP
    else if (stateKey === 'claimStatus')
        lookup = CLAIM_STATUS_LOOKUP
    else if (stateKey === 'claimType')
        lookup = CLAIM_TYPE_LOOKUP
    else if (stateKey === 'origin')
        lookup = CLAIM_ORIGIN_LOOKUP
    else if (stateKey === 'department')
        lookup = CLAIM_DEPARTMENT_LOOKUP
    else if (stateKey === 'comments' && objectKey === 'commentCategory')
        lookup = COMMENT_CATEGORY_LOOKUP
    else if(stateKey === 'draftAgreementsComments' && objectKey === 'commentCategory')
        lookup = COMMENT_CATEGORY_LOOKUP

    return lookup;
}

export const mapUsageLookupKey = (stateKey: IRepertoireStateKeys, objectKey: string): string => {
    let lookup = "";

    if (stateKey === USAGETYPE_SEARCH_USAGES_STATE_KEY) {
        lookup = DISTRIBUTIONTYPE_LOOKUP;
    }
    else if (stateKey === ALLOCATIONSTATUS_SEARCH_USAGES_STATE_KEY) {
        lookup = ALLOCATIONSTATUS_LOOKUP;
    }
    else if (stateKey === MATCHSTATUS_SEARCH_USAGES_STATE_KEY) {
        lookup = USAGEMATCHSTATUS_LOOKUP;
    }
    else if (stateKey === PRODUCT_SEARCH_TYPE_STATE_KEY) {
        lookup = PRODUCT_TYPE_LOOKUP;
    }
    else if (stateKey === PRODUCT_TYPE_STATE_KEY) {
        lookup = PRODUCT_TYPE_LOOKUP;
    }
    else if (stateKey === PAYMENTRUN_STATE_KEY) {
        lookup = PAYMENTRUN_TYPE_LOOKUP;
    }
    else if (stateKey === USAGE_DETAILS_ROWS_SECTION_STATE_KEY)
        lookup = USAGES_FEES_ERROR_LOOKUP;
    else if (stateKey === USAGE_GROUP_APPROVAL_STATUS_STATE_KEY)
        lookup = USAGE_GROUP_APPROVAL_STATUS_LOOKUP;
    else if (stateKey === USAGE_GROUP_INCLUDE_FEES_IN_ERROR_STATE_KEY)
        lookup = USAGEGROUP_INCLUDEFEESINERROR_LOOKUP;
    return lookup;
}
const extractNumber = (str: string) => {
    const match = str.match(/\d+/);
    return match && match.length > 0 ? parseInt(match[0]) : 0;
};

export const getStatusCodeValue = (lookup: string, lookupValues: ILookupDictionary, value: IIPStatus[]): string => {
    if (value != null && value.length > 0) {
        let result = lookupValues[lookup] ? lookupValues[lookup].lookups : undefined;
        result = result.sort((a, b) => extractNumber(a.description) - extractNumber(b.description));
        return result.find(x => x.typeID === value[0].statusCode).description;
    }
}

export const getReferencedIP = (status: IIPStatus[], statusCode: string): string => {
    if (statusCode === "Buy Out" || statusCode === "Deletion Cross Reference") {
        return status[0].forwardingBaseNumber;
    }
    return "";
}

export const getLookupValues = (lookup: string, lookupValues: ILookupDictionary, objectKey?: string, portalType?: string): ILookupInstance[] => {
    let result = lookupValues[lookup] ? lookupValues[lookup].lookups : undefined;
    if (result && result.length > 0) {
        if (lookup === TERRITORY_LOOKUP || lookup === JOB_TYPE_LOOKUP || lookup === USAGES_FEES_ERROR_LOOKUP) {
            result = result.sort((a, b) => extractNumber(a.description) - extractNumber(b.description));
        }
        else {
            result = result.sort((a, b) => extractNumber(a.description) - extractNumber(b.description));
        }
    }

    if (lookup === INSTRUMENTATION_LOOKUP) {
        let customInstrumentation = result.find(x => x.typeID === -1);
        result = result.filter(x => x.typeID !== -1);
        result = result.sort((a, b) => a.description.localeCompare(b.description));
        if (portalType !== MEMBERS_PORTAL)
            result.unshift(customInstrumentation);
    }

    if (lookup === SET_TYPE_LOOKUP && (objectKey === 'shareSet')) {
        result = result.filter(x => x.typeID < 0);
    }

    if (lookup === PORTAL_DEFAULT_VALUES_LOOKUP) {

        var portalTypeId = portalType == 'MembersPortal' ? 2 : 1;
        if (result != undefined && result.length > 0)
            result = result.filter(x => x.code == objectKey && x.extraFields["PortalType"] == portalTypeId);
    }

    if (lookup === USAGEMATCHSTATUS_LOOKUP) {
        let matchStatus = [];
        if (result) result.forEach(x => matchStatus.push(Object.assign({}, x)));
        result = matchStatus;
    }

    if (lookup === ADDITIONAL_ATTRIBUTES_LOOKUP) {
        let additionalAttributes = [];
        if (result) {
            result.forEach(x => {
                x.additionalData = x.extraFields["Group"];
                additionalAttributes.push(Object.assign({}, x))
            });
        }
        result = additionalAttributes;
    }

    if (lookup === USAGE_GROUP_APPROVAL_STATUS_LOOKUP) {
        let approvalStatus = [];
        if (result)
            result.forEach(x => approvalStatus.push(Object.assign({}, x)));
        result = approvalStatus;
    }

    if (lookup === USAGEGROUP_INCLUDEFEESINERROR_LOOKUP) {
        let includeFeesInError = [];
        if (result)
            result.forEach(x => includeFeesInError.push(Object.assign({}, x)));
        result = includeFeesInError;
    }

    if (lookup === WORK_GENRE_LOOKUP || lookup === ALLOCATION_RULE_LOOKUP || lookup === DISTRIBUTION_CATEGORY_TYPE_LOOKUP) {
        result = result.sort((a, b) => a.description.localeCompare(b.description));
    }

    if (lookup === LICENSING_USES_LOOKUP) {
        if (result) result.forEach(x => x.code = x.typeID.toString())

    }

    if (result && result.length > 0 && lookup === CURRENCY_LOOKUP) {
        result = result.sort((a, b) => a.description.localeCompare(b.description));
    }

    if (lookup === MUSIC_ARRANGEMENT_LOOKUP || lookup === LYRIC_ADAPTATION_LOOKUP || lookup === JOB_TIME_RANGE_LOOKUP) {
        result = result.sort((a, b) => a.typeID - b.typeID);
    }

    if (lookup === USAGE_GROUP_QA_STATUS_LOOKUP) {
        let qaStatus = [];
        if (result)
            result.forEach(x => qaStatus.push(Object.assign({}, x)));
        result = qaStatus;
    }

    return result;
};


export const getLookupDefaultMultiSelect = (lookupValues: ILookupDictionary, lookup: string, key: string, viewData: IRepertoireComponentDataItem): any[] => {
    let dropdownOptions = [];
    const lookupVals = getLookupValues(lookup, lookupValues);
    if (lookupVals) {
        lookupVals.filter(x => x.typeID < 0 && x.code.toUpperCase() !== REPERTOIRE.toUpperCase())
        .forEach(x => dropdownOptions.push({ key: x.typeID, text: x.description, data: x.code }));
    }

    if (viewData && viewData.fields.length > 0 && viewData.fields.filter(x => x.name === key).length > 0) {
        const field = viewData.fields.filter(x => x.name === key)[0];
        if (field && field.defaultValue) {

            const fieldDefaultValue = field.defaultValue.split(",");
            let results = [];

            dropdownOptions.forEach((x) => {
                if (fieldDefaultValue.includes(x.text)) {
                    results.push(x.key);
                }
            });
            return results;
        }
        return [];
    }
    return [];
};

export const getLookupDefaultV2 = (key: string, lookup: string, lookupValues: ILookupDictionary, viewData: IRepertoireComponentDataItem): string => {
    if (viewData && viewData.fields.length > 0 && viewData.fields.filter(x => x.name === key).length > 0) {
        const fieldDefaultValue = viewData.fields.filter(x => x.name === key)[0].defaultValue;

        if (lookupValues[lookup]) {
            const filteredValues = lookupValues[lookup].lookups.filter(x => x.description === fieldDefaultValue)[0];
            const result = filteredValues ? filteredValues.code : undefined
            return result;
        }
        return "";
    }
    return "";
};

export const getLookupDefault = (lookup: string, lookupValues: ILookupDictionary): string => {
    const result = lookupValues[lookup] ? lookupValues[lookup].defaultValue : undefined;
    return result;
};

export const getLookupDefaultDescription = (lookup: string, lookupValues: ILookupDictionary): string => {
    const result = lookupValues[lookup] ? lookupValues[lookup].defaultValue : undefined;
    if (result) {
        return lookupValues[lookup].lookups.find(x => x.code === result).description;
    }
    return "";
}

export const searchLookupValueByCode = (lookup: string, lookupValues: ILookupDictionary, code: string): string => {
    const result = lookupValues[lookup] ? lookupValues[lookup].lookups : undefined;
    if (result) {
        const lookupValue = result.find(x => x.code === code);
        if (lookupValue) {
            return lookupValue.description;
        }
    }
    return "";
}
