import * as React from "react";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { ContainerDetailsWindow } from "../../../../dataingestion/components/containerDetailsWindow/ContainerDetailsWindow";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { IStoragePathItemData } from "../../../../dataingestion/types/IStoragePathItemData";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { addFileToUpload, CleanUploadFilesState, fileMetadataChanged, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import { hideModal, showModal } from "../../../../redux/reducers/ModalReducer";
import { addDroppedFile, addTab, cleanDroppedFilesRepertoireState, clearModalSearchResults, setProgressUploadFileRepertoireProccess, sortAgreementFieldData, updateAgreementField, updateAgreementWork} from "../../../../redux/reducers/RepertoireReducer";
import { RepertoireService } from "../../../../redux/services/RepertoireService";
import { IAddAgreementAttachmentModel } from "../../../../redux/types/IAddAttchmentAgreementModel";
import { IAgreementState, IAgreementStateKeys } from "../../../../redux/types/IAgreementState";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import IDroppedFiles from "../../../../redux/types/IDroppedFiles";
import IFile from "../../../../redux/types/IFiles";
import { IPaginationDetail } from "../../../../redux/types/IPaginationDetail";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { ADD_NEW_ACTION, ADMINISTERED_BY_KEY, AGREEMENTS_KEY, AGREEMENT_ATTACHEMENT_STATE_KEY, AGREEMENT_DUPLICATE_KEY, AGREEMENT_NUMBERS_KEY, APPLICABLE_WORKS_KEY, AGREEMENT_CARVEOUTS_KEY, COMMENTS_KEY, COMMENT_CATEGORY_KEY, REMOVE_ACTION, REPERTOIRE, WORK_SEARCH_VIEW } from "../../../Consts";
import { DataSource } from "../../../types/DataSource";
import { IAgreementAdminIPDataItems } from "../../../types/IAgreementAdminIPDataItems";
import { IAgreementDuplicateDataItems } from "../../../types/IAgreementDuplicateDataItems";
import { IAgreementNumberDataItems } from "../../../types/IAgreementNumberDataItems";
import { IAgreementWorkDataItems } from "../../../types/IAgreementWorkDataItems";
import { IAgreementProductWorkDataItems } from "../../../types/IAgreementProductWorkDataItems";
import { IComment } from "../../../types/IComment";
import { IRemoveAgreementAttachment } from "../../../types/IRemoveAgreementBlobAttachment";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IDistribution } from "../../../types/usageTypes/IDistribution";
import AgreementMaintenanceWorkTableContainer from "./AgreementMaintenanceWorkTableContainer";

export interface IAgreementMaintenanceGridsViewProps {
    agreementMaintenanceGridsViewData: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    agreementAdminIP?: IAgreementAdminIPDataItems[];
    applicableWorks?: IAgreementWorkDataItems[];
    agreementProductWorks?: IAgreementProductWorkDataItems[];
    agreementNumbers?: IAgreementNumberDataItems[];
    agreementDuplicate?: IAgreementDuplicateDataItems[];
    comments?: IComment[];
    tabs: ITabReduxItem[];
    activeTab: number;
    dataSource: string;
    componentInstance: string;
    updateAgreementField: typeof updateAgreementField;
    sortAgreementFieldData: typeof sortAgreementFieldData;
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    clearModalSearchResults: typeof clearModalSearchResults;
    addTab?: typeof addTab
    getProductDetails: (dataSource: string, workID: number) => void;
    getWorkDetails: (dataSource: string, productID: number) => void;
    getIPDetails: (ipBaseNumber: string, accountNumber: string) => void;
    getAgreementDetails: (dataSource: string, agreementID: number) => void;
    currentUser?: string;
    containerDetailsWindowComponentData: IComponentDataItem;
    distributions: IDistribution[];
    destinations: ILookupInstance[];
    selectedFileSystem: IFileSystem;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    sortColumn: typeof sortColumn;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    storagePaths: IStoragePathItemData[];
    storagePathItemData: IComponentDataItem;
    refreshDirectory: (filesystem: IFileSystem) => void;
    addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => void;
    attachedFiles: IStoragePathItemData[];
    removeAgreementAttachmentIfExists:(attachedFile: IAttachedFile, source: DataSource) => void;
    agreement: IAgreementState;
    agreementAttachmentsDropped?: IDroppedFiles;
    cleanDroppedFilesRepertoire?: typeof cleanDroppedFilesRepertoireState;
    addDroppedFileRepertoire?: typeof addDroppedFile;
    setProgressUploadFileRepertoire?: typeof setProgressUploadFileRepertoireProccess;
    shareDecimalsToDisplay?: number;
    refreshAgreementDetail?: () => void;
    paginationDetails: IPaginationDetail[];
    updatePagination: typeof updatePagination;
    isReadonly: boolean;
    incrementTempID?: () => void;
    commentCategoryFlag?: boolean;
    agreementAttachmentTypeRequired?: string;
    mergeAgreement: (agreementId: number) => void;
}

interface IAgreementMaintenanceGridsViewState {
    activeHeaderSection: string;
}

export default class AgreementMaintenanceGridsView extends React.PureComponent<
    IAgreementMaintenanceGridsViewProps,
    IAgreementMaintenanceGridsViewState
    > {
    constructor(props: IAgreementMaintenanceGridsViewProps) {
        super(props);

        this.state = {
            activeHeaderSection: "administeredBy"
        };
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    changeData = (value: any, name: IRepertoireStateKeys) => {
            this.props.updateAgreementField(value, name as IAgreementStateKeys);
    }

    sortData(value: any, name: IRepertoireStateKeys): void {
        this.props.sortAgreementFieldData(value, name as IAgreementStateKeys);
    }

    mergeActionClick = (tableContentItem: any) => {
        this.props.mergeAgreement(+tableContentItem["repertoireAgreementID"].value);
    }

    openInNewTab(openItem: any): void {

        if(openItem["productID"] != undefined){
            this.props.getProductDetails(REPERTOIRE, openItem["productID"]);
        }
        if (openItem["workID"] != undefined) {
            this.props.getWorkDetails(REPERTOIRE, openItem["workID"]);
        }
        else if (openItem["administeredByIPBaseNumber"] != undefined) {
            this.props.getIPDetails(openItem["administeredByIPBaseNumber"].value, null);
        }
        else if (openItem["repertoireAgreementID"] != undefined) {
            this.props.getAgreementDetails(REPERTOIRE, openItem["repertoireAgreementID"].value)
        }
    }

    uploadAgreementAttachment(fileName: string, id: number, dataSource: DataSource) { 
        const model: IAddAgreementAttachmentModel ={
            attachedFile: {
                fileName: fileName,
                agreementId: id
            },
            dataSource: dataSource
        }
        return Promise.resolve(
            RepertoireService.addAgreementAttachmentRepertoire(model)
               .then((res :IAttachedFile) => {
                    return res;
               })
               .catch((err: any) => {
                   const newFile: IFile ={
                    isInProgress: true,
                    message: err,
                    name: fileName,
                    size:0
                   }
                setProgressUploadFileProccess(err, newFile, true);
                return false;
               })
        )
    }


    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { agreementMaintenanceGridsViewData, componentInstance, dataSource } = this.props;

        if (agreementMaintenanceGridsViewData && agreementMaintenanceGridsViewData.fields) {
            if (dataSource === DataSource.Repertoire) {
                const modifiedagreementMaintenanceGridsViewData = agreementMaintenanceGridsViewData.fields.filter(a => a.name !== AGREEMENT_DUPLICATE_KEY);

                return modifiedagreementMaintenanceGridsViewData.filter(f => f.componentInstance === null || f.componentInstance === componentInstance).map((section, index) => (
                    <div
                        className={[
                            "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                        ].join(" ")}
                        key={index}
                        onClick={() => this.onClickHeaderSection(section.name)}
                    >
                        {section.data}
                    </div>
                ));
            } else {
                return agreementMaintenanceGridsViewData.fields.filter(f => f.componentInstance === null || f.componentInstance === componentInstance).map((section, index) => (
                    <div
                        className={[
                            "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                        ].join(" ")}
                        key={index}
                        onClick={() => this.onClickHeaderSection(section.name)}
                    >
                        {section.data}
                    </div>
                ));
            }
        }
    };

    removeAttachmentFromMaintenanceScreen(fullName:string, agreementID: number, dataSource: DataSource, guid: string,index: number){
        const {refreshAgreementDetail} = this.props;
       const attachedFile: IAttachedFile = {
           fileName: fullName,
            agreementId: agreementID
       }
       const model:IRemoveAgreementAttachment ={
           Guid: guid,
           Source: dataSource,
           agreementID: agreementID
       }

       RepertoireService.removeAgreementBlobAttachment(model)
           .then(res => {
               if(res){
                   RepertoireService.removeAgreementAttachment({attachedFile: attachedFile, dataSource: dataSource})
                           .then((res: boolean) => {
                               if(res){
                                refreshAgreementDetail();
                                }
                            })
           }})
   }

    render() {
        const {
            dataSource,
            agreementAdminIP,
            applicableWorks,
            agreementProductWorks,
            agreementNumbers,
            agreementDuplicate,
            agreementMaintenanceGridsViewData,
            showModal,
            lookupValues,
            clearModalSearchResults,
            addTab,
            comments,
            currentUser,
            distributions,
            selectedFileSystem,
            droppedFiles,
            setInDropZone,
            sortColumn,
            addFileToUpload,
            isDropZone,
            setProgressUploadFileProccess,
            cleanUploadFilesState,
            storagePaths,
            storagePathItemData,
            containerDetailsWindowComponentData,
            destinations,
            refreshDirectory,
            attachedFiles,
            addRepertoireAttachmentToState,
            removeAgreementAttachmentIfExists,
            agreement,
            agreementAttachmentsDropped,
            cleanDroppedFilesRepertoire,
            addDroppedFileRepertoire,
            setProgressUploadFileRepertoire,
            shareDecimalsToDisplay,
            paginationDetails,
            updatePagination,
            isReadonly,
            tabs,
            activeTab,
            incrementTempID,
            commentCategoryFlag,
            agreementAttachmentTypeRequired,
            mergeAgreement
        } = this.props;

        const {
            activeHeaderSection
        } = this.state;

        const filteredFields = agreementMaintenanceGridsViewData.fields.filter(
            (field: IRepertoireField) =>
                field.name === activeHeaderSection
        );

        let { dataGridTableData } = this.props

        if (!commentCategoryFlag && filteredFields[0].name === COMMENTS_KEY) {
            dataGridTableData.fields = dataGridTableData.fields.filter(x => x.name != COMMENT_CATEGORY_KEY);
        }


        return (
            <div className="GridsView">
                <div className="headerContainer">
                    {this.renderGridViewHeaderSections()}
                </div>
                {activeHeaderSection === ADMINISTERED_BY_KEY ?
                    <DataGridTable
                        tableContents={agreementAdminIP}
                        changeData={this.changeData.bind(this)}
                        sortData={this.sortData.bind(this)}
                        dataGridTableData={dataGridTableData}
                        stateKey={filteredFields[0].name}
                        componentInstance={ADMINISTERED_BY_KEY}
                        tableActions={[{ name: ADD_NEW_ACTION }]}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        showRowNumber={false}
                        lookupValues={lookupValues}
                        addTab={addTab}
                        tabs={tabs}
                        activeTab={activeTab}
                        openInNewTab={this.openInNewTab.bind(this)}
                        shareDecimalsToDisplay={shareDecimalsToDisplay}
                        isReadOnly={isReadonly}
                        incrementTempID={incrementTempID}
                        entity={agreement}
                    /> : <div> </div>}

                {activeHeaderSection === APPLICABLE_WORKS_KEY ?
                    <AgreementMaintenanceWorkTableContainer
                        tableContents={applicableWorks}
                        dataGridTableData={dataGridTableData}
                        componentInstance={APPLICABLE_WORKS_KEY}
                        changeData={this.changeData.bind(this)}
                        openWork={this.openInNewTab.bind(this)}
                        stateKey={filteredFields[0].name}
                        lookupValues={lookupValues}
                        showModal={showModal}
                   />
                   : <div> </div>}
                
                {activeHeaderSection === AGREEMENT_CARVEOUTS_KEY ?
                    <DataGridTable
                        tableContents={agreementProductWorks}
                        changeData={this.changeData.bind(this)}
                        sortData={this.sortData.bind(this)}
                        dataGridTableData={dataGridTableData}
                        stateKey={filteredFields[0].name}
                        componentInstance={AGREEMENT_CARVEOUTS_KEY}
                        tableActions={[{ name: ADD_NEW_ACTION }] }
                        showRowNumber={false}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        lookupValues={lookupValues}
                        addTab={addTab}
                        tabs={tabs}
                        activeTab={activeTab}
                        openInNewTab={this.openInNewTab.bind(this)}
                        isReadOnly={isReadonly}
                        incrementTempID={incrementTempID}
                        entity={agreement}

                    /> : <div> </div>}

                {activeHeaderSection === AGREEMENT_NUMBERS_KEY ?
                    <DataGridTable
                        tableContents={agreementNumbers}
                        changeData={this.changeData.bind(this)}
                        sortData={this.sortData.bind(this)}
                        dataGridTableData={dataGridTableData}
                        stateKey={filteredFields[0].name}
                        componentInstance={AGREEMENT_NUMBERS_KEY}
                        tableActions={[{ name: ADD_NEW_ACTION }] }
                        showRowNumber={false}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        lookupValues={lookupValues}
                        isReadOnly={isReadonly}
                        tabs={tabs}
                        activeTab={activeTab}
                        incrementTempID={incrementTempID}
                        entity={agreement}
                    /> : <div> </div>}

                {(activeHeaderSection === AGREEMENT_DUPLICATE_KEY && dataSource === DataSource.Intray) ?
                    <DataGridTable
                        tableContents={agreementDuplicate}
                        changeData={this.changeData.bind(this)}
                        sortData={this.sortData.bind(this)}
                        dataGridTableData={dataGridTableData}
                        stateKey={filteredFields[0].name}
                        componentInstance={AGREEMENT_DUPLICATE_KEY}
                        tableActions={[]}
                        showRowNumber={false}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        lookupValues={lookupValues}
                        openInNewTab={this.openInNewTab.bind(this)}
                        isReadOnly={isReadonly}
                        tabs={tabs}
                        activeTab={activeTab}
                        incrementTempID={incrementTempID}
                        entity={agreement}
                        mergeActionClick={this.mergeActionClick.bind(this)}
                    /> : <div> </div>}

                    {(activeHeaderSection === COMMENTS_KEY ) ?
                    <DataGridTable
                        tableContents={comments}
                        changeData={this.changeData.bind(this)}
                        sortData={this.sortData.bind(this)}
                        dataGridTableData={dataGridTableData}
                        stateKey={filteredFields[0].name}
                        componentInstance={COMMENTS_KEY}
                        tableActions={[{ name: ADD_NEW_ACTION },{name: REMOVE_ACTION }]}
                        showRowNumber={false}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        lookupValues={lookupValues}
                        currentUser={currentUser}
                        isReadOnly={isReadonly}
                        tabs={tabs}
                        activeTab={activeTab}
                        incrementTempID={incrementTempID}
                        entity={agreement}
                    /> : <div> </div>}
                {(activeHeaderSection === AGREEMENT_ATTACHEMENT_STATE_KEY) ? 
                    <div key={activeHeaderSection} className={'topMarginRepertoireAttachment'}>
                    <ContainerDetailsWindow 
                                addFileToUpload={addFileToUpload}
                                cancelChanges={undefined}
                                cleanUploadFilesState={cleanUploadFilesState}
                                containerDetailsWindowComponentData={containerDetailsWindowComponentData}
                                currentDirectory={"agreement"}
                                destinations={destinations}
                                editingChanged={undefined}
                                fileMetadataChanged={fileMetadataChanged}
                                jobs={[]}
                                matchNow={undefined}
                                matchNowSubmit={undefined}
                                openDirectory={undefined}
                                pageContentIsActive={true}
                                distributions={distributions}
                                droppedFiles={droppedFiles}
                                isDropZone={isDropZone}
                                selectedFileSystem={selectedFileSystem}
                                setInDropZone={setInDropZone}
                                setProgressUploadFileProccess={setProgressUploadFileProccess}
                                sortColumn={sortColumn}
                                storagePathItemData={storagePathItemData}
                                storagePaths={attachedFiles}
                                refreshDirectory={refreshDirectory}
                                saveChanges={undefined}
                                saveState={undefined}
                                isRepertoireModule={true} 
                                toggleSidebarCollapsed={undefined} 
                                validationMessageChanged={undefined}
                                addRepertoireAttachmentToState={addRepertoireAttachmentToState}
                                entityID={agreement.agreementID}
                                entitySource={agreement.dataSource}
                                removeAttachmentIfExists={removeAgreementAttachmentIfExists}
                                uploadAttachment={this.uploadAgreementAttachment.bind(this)}
                                agreementAttachmentsDropped={agreementAttachmentsDropped}
                                component={AGREEMENTS_KEY}
                                cleanDroppedFilesRepertoire={cleanDroppedFilesRepertoire}
                                addDroppedFileRepertoire={addDroppedFileRepertoire}
                                setProgressUploadFileRepertoire={setProgressUploadFileRepertoire}
                                displayRemove={true}
                                removeAttachmentMaintenanceScreen={this.removeAttachmentFromMaintenanceScreen.bind(this)}
                                paginationDetails={paginationDetails}
                                updatePagination={updatePagination}
                                lookupValues={lookupValues}
                                isWorkAttachmentTypeNotRequired={false}
                                isWorkAttachmentTypeOptional={false}
                                isProductAttachmentTypeNotRequired={false}
                                isProductAttachmentTypeOptional={false}
                                tabs={tabs}
                                activeTab={activeTab}
                                agreementAttachmentTypeRequired={agreementAttachmentTypeRequired}
                                />
                </div> : <div></div>
                }
            </div>
        );
    }
}