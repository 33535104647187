import type { TableColumnsType } from 'antd';
import { Space, Table } from 'antd';
import React from 'react';
import { SizedCheckboxDataInput } from '../../../components/checkboxDataInput/CheckboxDataInput';

export interface AllocationHeaderRowType {
    key: React.Key;
    AllocationUsageHeaderId: number;
    PoolCode: string;
    WorkNumber: string;
    WorkName: string;
    ProductNumber?: string;
    AllocationDate: string;
    AllocationWeight: string;
    TotalAmount: string;
    UsageDetails?: AllocationDetailRowType[];
    IsSelected: boolean;
}

export interface AllocationDetailRowType {
    key: React.Key;
    AllocationUsageHeaderID: number;
    IPNameNumber: number;
    AccountNumber: string;
    Amount: string;
    SharePct: string;
    Name: string;
    PayeeAccountNumber: string;
    PayeeIPBaseNumber: string;
    PayeeIpNameNumber: number;
    PayeeName: string;
    PoolCode: string;
    PointsAllocated?: string;
    WorkNumber?: string;
    WorkName?: string;
}
interface IAdjustmentMaintenancePageProps {
    searchResults: AllocationHeaderRowType[];
    viewData: any;
    addAdjustmentDetails?: (row: AllocationDetailRowType) => void;
    selectAllocationHeader: (AllocationDetailRowType) => void;
}

const AllocationSelectionView: React.FC<IAdjustmentMaintenancePageProps> = (props: IAdjustmentMaintenancePageProps) => {
    const { viewData } = props;

    const onClick = (value) => {
        const { addAdjustmentDetails } = props;
        addAdjustmentDetails(value);
    }

    const onClickSelectAllocationHeader = (value) => {
        const { selectAllocationHeader } = props;
        selectAllocationHeader(value);
    }

    const expandedRowRender = (record: AllocationHeaderRowType, index) => {

        const details = record.UsageDetails;
        const data: AllocationDetailRowType[] = details.map((item, index) => {
            return {
                key: index.toString(),
                AllocationUsageHeaderID: item.AllocationUsageHeaderID,
                IPNameNumber: item.IPNameNumber,
                AccountNumber: item.AccountNumber,
                Name: item.Name,
                Amount: item.Amount.replace('0E-18', '0.000000000000000000'),
                PointsAllocated: item.PointsAllocated.replace('0E-18', '0.000000000000000000'),
                SharePct: item.SharePct.replace('0E-18', '0.000000000000000000'),
                PayeeIPBaseNumber: item.PayeeIPBaseNumber,
                PayeeIpNameNumber: item.PayeeIpNameNumber,
                PayeeAccountNumber: item.PayeeAccountNumber,
                PayeeName: item.PayeeName,
                PoolCode: item['PoolCode'],
                WorkNumber: record.WorkNumber,
                WorkName: record.WorkName
            }
        });
        const columns: TableColumnsType<AllocationDetailRowType> = [
            {
                title: "Actions",
                dataIndex: 'operation',
                key: 'operation',
                render: (_, value) => {
                    return <><Space onClick={() => onClick(value)} size="middle"><a>Add Adjustment Details</a></Space></>
                },
            },
            { title: viewData["accountNumber"], dataIndex: 'AccountNumber', key: 'AccountNumber' },
            { title: viewData["ipName"], dataIndex: 'Name', key: 'Name' },
            { title: viewData["ipiNumber"], dataIndex: 'IPNameNumber', key: 'IPNameNumber' },
            { title: viewData["payeeAccountNumber"], dataIndex: 'PayeeAccountNumber', key: 'PayeeAccountNumber' },
            { title: viewData["payeeNameNumber"], dataIndex: 'PayeeIpNameNumber', key: 'PayeeIpNameNumber' },
            { title: viewData["payeeBaseNumber"], dataIndex: 'PayeeIPBaseNumber', key: 'PayeeIPBaseNumber' },
            { title: viewData["payeeName"], dataIndex: 'PayeeName', key: 'PayeeName' },
            { title: viewData["sharePct"], dataIndex: 'SharePct', key: 'SharePct' },
            { title: viewData["pointsAllocated"], dataIndex: 'PointsAllocated', key: 'PointsAllocated' },
            { title: viewData["amountAllocated"], dataIndex: 'Amount', key: 'Amount' },
        ];

        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    const columns: TableColumnsType<AllocationHeaderRowType> = [
        {
            title: '', dataIndex: 'checkbox', key: 'checkbox', render: (_, value) => {
                return <SizedCheckboxDataInput label=''
                    value={props.searchResults.filter(x => x.AllocationUsageHeaderId === value.AllocationUsageHeaderId)[0].IsSelected }
                    changeData={() => onClickSelectAllocationHeader(value)} fieldName='SelectAllocationHeader' />
            }
        },
        { title: viewData["allocationHeaderID"], dataIndex: 'AllocationUsageHeaderId', key: 'AllocationUsageHeaderId' },
        { title: viewData["poolCode"], dataIndex: 'PoolCode', key: 'PoolCode' },
        { title: viewData["workNumber"], dataIndex: 'WorkNumber', key: 'WorkNumber' },
        { title: viewData["workName"], dataIndex: 'WorkName', key: 'WorkName' },
        { title: viewData["productNumber"], dataIndex: 'ProductNumber', key: 'ProductNumber' },
        { title: viewData["allocationDate"], dataIndex: 'AllocationDate', key: 'AllocationDate' },
        { title: viewData["allocationWeight"], dataIndex: 'AllocationWeight', key: 'AllocationWeight' },
        { title: viewData["totalAmountAllocated"], dataIndex: 'TotalAmount', key: 'TotalAmount' }
    ];

    const data: AllocationHeaderRowType[] = props.searchResults;

    return (
        <>
            <Table
                columns={columns}
                expandable={{ expandedRowRender: (record, index) => expandedRowRender(record, index) }}
                dataSource={data}
                size="small"
            />
        </>
    );
};

export default AllocationSelectionView;