import * as React from "react";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { setDataSource } from "../../redux/reducers/RepertoireReducer";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import SearchView from "../../repertoire/components/searchView/SearchView";
import {
    SEARCH_VIEW_DRAFT_WORKS,
} from "../Consts";
import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { DataSource } from "../../repertoire/types/DataSource";
import { MemberPortalSearchTypeItem } from "../../redux/types/MemberPortalSearchTypeItem";
import { MemberPortalSearchType } from "../../redux/types/MemberPortalSearchType";
import { SearchRequests } from "../../repertoire/services/SearchRequests";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP } from "../ConfigurationConsts";
import { IRegisteredWorkSearchQuery } from "../types/IRegisteredWorkSearchQuery";
import { IRegisteredWorkSearchResult } from "../types/IRegisteredWorkSearchResult";
import { FormatFields } from "../../redux/types/FormatFields";

export interface IRegisteredWorksPageProps {
    registeredWorksPageData: IMembersPortalComponentDataItem;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    searchRegisteredWorks?: (query: IRegisteredWorkSearchQuery) => void;
    registeredWorkSearchResults: IRegisteredWorkSearchResult[];
    searchSuccessful: boolean;
    registeredWorksMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    getRegisteredWorkDetails: (dataSource: string, workID: number, lookupValues: ILookupDictionary, memberWorkMaintenanceDataViewData: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    addNewDraftWorks: (lookups: ILookupDictionary, currentUserNameNumber: string, isNew?: boolean, draftWorksMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    disabled?: boolean;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    setDataSource: typeof setDataSource;
    dataSource: string;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    memberPortalSearchType: MemberPortalSearchTypeItem;
    currentUserNameNumber?: string;
    expandWorkResult?: (workResult: number) => void;
    expandedWorkResults?: number[];
    expandAllResults: () => void;
    expandAll: boolean;
}

const RegisteredWorksPage = (props: IRegisteredWorksPageProps) => {
    const [loaded, setLoaded] = React.useState(false);
    const { instance, accounts } = useMsal();

    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])


    const checkIfLoaded = () => {
        const { registeredWorksPageData } = props;

        if (registeredWorksPageData.fields && registeredWorksPageData.fields.length >= 1 && !loaded) {
            setLoaded(true);
            const header = registeredWorksPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    const addNewDraftWorks = (): void => {
        const { addNewDraftWorks, lookupValues, registeredWorksMaintenanceGeneralDataViewData, currentUserNameNumber } = props;
        const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                addNewDraftWorks(lookupValues, currentUserNameNumber, true, registeredWorksMaintenanceGeneralDataViewData, formats);
            });
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }
    let currentDataSource = "";
    switch (props.memberPortalSearchType.code) {
        case MemberPortalSearchType.MyDraftSearch.code:
            setDataSource(DataSource.Intray);
            currentDataSource = DataSource.Intray;
            break;
        case MemberPortalSearchType.MyRegisteredSearch.code:
        case MemberPortalSearchType.AllRegisteredSearch.code:
            setDataSource(DataSource.Repertoire);
            currentDataSource = DataSource.Repertoire;
            break;
    }

    if (loaded) {
        const {
            registeredWorksPageData,
            registeredWorksMaintenanceGeneralDataViewData,
            searchViewData,
            searchResultsTableData,
            searchRegisteredWorks,
            searchSuccessful,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            resetPagination,
            sortSearchResults,
            lookupValues,
            expandWorkResult,
            expandedWorkResults,
            expandAllResults,
            expandAll,
            currentUserNameNumber,
            memberPortalSearchType
        } = props;

        const getRegisteredWorkDetails = (dataSource: string, workID: number) => {
            const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                .then(formats => {
                    props.getRegisteredWorkDetails(dataSource, workID, lookupValues, registeredWorksMaintenanceGeneralDataViewData, formats);
                });
        }

        let searchPageLabelTitle = null;
        if (registeredWorksPageData && registeredWorksPageData.fields.length > 0) {
            searchPageLabelTitle = registeredWorksPageData.fields.find((v, i, a) => v.name == "searchPageLabelTitle").data;
        }

        let searchResults = null

        switch (memberPortalSearchType.code) {
            case MemberPortalSearchType.MyDraftSearch.code:
                searchResults = props.registeredWorksPageData;
                break;

            case MemberPortalSearchType.MyRegisteredSearch.code:
            case MemberPortalSearchType.AllRegisteredSearch.code:
                searchResults = props.registeredWorkSearchResults;
                break;
        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">{searchPageLabelTitle}</span>
                        <div className="newWorkButton" title="Register New Work">
                            <div className="IconTextButton" onClick={addNewDraftWorks}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                Register New Work
                            </div>
                        </div>
                    </div>
                </div>
                <SearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_DRAFT_WORKS}
                    modalProps={undefined}
                    dataSource={currentDataSource}
                    hideModal={undefined}
                    lookupValues={lookupValues}
                    searchDraftWorks={searchRegisteredWorks}
                    draftWorkSearchResults={searchResults}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    updatePagination={updateUserPagination}
                    getDraftWorkDetails={getRegisteredWorkDetails}
                    sortSearchResults={sortSearchResults}
                    searchDisabled={false}
                    expandWorkResult={expandWorkResult}
                    expandedWorkResults={expandedWorkResults}
                    expandAllResults={expandAllResults}
                    expandAll={expandAll}
                    currentUserNameNumber={currentUserNameNumber}
                    memberPortalSearchType={memberPortalSearchType}
                />
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default RegisteredWorksPage;
