import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import {
    hideModal,
    showModal,
    showUpdateFieldsModal
} from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { setEditableFields, updateEditableFields, updateInterestedPartyField } from "../../../../redux/reducers/RepertoireReducer";
import { IInterestedPartyStateKeys, ISocIPKeys } from "../../../types/IInterestedParty";
import {
    IP_IPBASENUMBER_STATE_KEY,
    IP_BIRTHDATE_STATE_KEY,
    IP_DEATHDATE_STATE_KEY,
    IP_SOCIETY_NAME_STATE_KEY,
    IP_TYPE_STATE_KEY,
    IP_ACCOUNT_NUMBER_STATE_KEY,
    IP_LAST_NAME_STATE_KEY,
    IP_SECOND_LAST_NAME_STATE_KEY,
    IP_FIRST_NAME_STATE_KEY,
    IP_MAINTENANCE_GENERAL_DATA_VIEW,
    TITLE_EDITABLE_FIELDS_VIEW,
    IP_SUB_ACCOUNT_STATE_KEY,
    EDITABLE_FIELDS,
    IP_ACCOUNT_TYPE_STATE_KEY,
    GENERAL_DATA_EDITABLE_FIELDS,
    COMPONENT_INSTANCE_IPS,
    CONFIGURE_IPS_ROLE,
    ALL_ROLES,
    DATA_GRID_TABLE,
    IP_NAMES_KEY,
    IP_MERGE_STATE_KEY,
    IP_STATUS_STATE_KEY,
    IP_REFERENCED_IP_STATE_KEY,
    IP_DEFAULT_SOCIETY_OF_LICENSE_STATE_KEY
} from "../../../Consts";
import SizedDateDataInput from "../../../components/dateDataInput/DateDataInput";
import { ISocIP } from "../../../types/ISocIP";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IFieldSettingViewModalProps } from "../../../components/modalViews/fieldSettingView/FieldSettingView";
import { ACCOUNT_TYPE_LOOKUP, IPI_STATUS_LOOKUP, SOCIETY_OF_LICENSE_LOOKUP } from "../../../../lookup/Consts";
import { getLookupDefault, getLookupValues, getReferencedIP, getStatusCodeValue } from "../../../../lookup/services/LookupHelpers";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";
import { IIPStatus } from "../../../types/IIPStatus";

export interface IIPMaintenanceGeneralDataViewProps {
    ipMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    ipBaseNumber: any;
    birthDate?: Date;
    deathDate?: Date;
    status: IIPStatus[];
    societyName: string;
    type: string;
    agencyID: string;
    accountNumber: string;
    lastName: string;
    firstName: string;
    socIP: ISocIP;
    updateInterestedPartyField: (value: any, fieldName: IInterestedPartyStateKeys) => void;
    changesMade: boolean;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    dataGridTableEditableFieldsData?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    editableFields: IRepertoireField[];
    roles: string[];
    mergeThisIP?: boolean;
}

interface IIPMaintenanceGeneralDataViewState {
    loaded: boolean;
}

export default class IPMaintenanceGeneralDataView extends React.PureComponent<
    IIPMaintenanceGeneralDataViewProps,
    IIPMaintenanceGeneralDataViewState
> {
    viewData;
    constructor(props: IIPMaintenanceGeneralDataViewProps) {
        super(props);
        this.state = {
            loaded: false
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            ipMaintenanceGeneralDataViewData: { fields }
        } = this.props
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab } = this.props;
        updateComponentFieldsItems(editableFields["default"], IP_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_IPS, IP_MAINTENANCE_GENERAL_DATA_VIEW, tabs, activeTab);
    }

    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableEditableFieldsData,
            setEditableFields,
        } = this.props;

        setEditableFields(IP_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_IPS)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableEditableFieldsData,
            onClickSave: this.onSaveEditableFields.bind(this)
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    render() {
        const {
            ipMaintenanceGeneralDataViewData,
            ipBaseNumber,
            birthDate,
            deathDate,
            societyName,
            type,
            agencyID,
            socIP,
            changesMade,
            updateInterestedPartyField,
            isReadonly,
            lookupValues,
            mergeThisIP,
            tabs,
            activeTab,
            status
        } = this.props;
        const { loaded } = this.state;

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            if (name === 'ipBaseNumber') {
                updateInterestedPartyField(value, name);
            }
            else if (name === 'mergeThisIP') {
                updateInterestedPartyField(value, name);
            }
            else if (name as ISocIPKeys) {
                const socIPData: ISocIP = (socIP && socIP.accountNumber !== undefined) ? JSON.parse(JSON.stringify(socIP)) : {
                    accountNumber: '',
                    lastName: '',
                    secondLastName: '',
                    firstName: '',
                    ipBaseNumber: ipBaseNumber,
                    socIPID: 0,
                    defaultSocietyOfLicense: '',
                    accountType: getLookupDefault(ACCOUNT_TYPE_LOOKUP, lookupValues)
                };
                socIPData[name] = value;
                updateInterestedPartyField(socIPData, "socIP");
            }
        }

        const getConfigureRoleDisabled = (): boolean => {
            const { roles } = this.props;
            if (roles && (roles.includes(CONFIGURE_IPS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        if (loaded) {
            return (
                <div className="">
                    <div className="row">
                        <div className="col-md-12 editableSectionHeading">
                            <h1 className="borderBootom">General</h1>
                            <button className="editBtn" title="Configure data fields" onClick={this.showFieldsModal.bind(this)} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                        <div>
                            <SizedCheckboxDataInput
                                label={this.viewData[IP_MERGE_STATE_KEY]}
                                fieldName={IP_MERGE_STATE_KEY}
                                value={mergeThisIP}
                                changeData={changeData}
                                readonly={isReadonly}
                            />
                        </div>
                    </div>
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Account Information</h1>
                            </div>
                        </div>
                        <div className="row">
                            <SizedTextDataInput
                                label={this.viewData[IP_ACCOUNT_NUMBER_STATE_KEY]}
                                fieldName={IP_ACCOUNT_NUMBER_STATE_KEY}
                                changeData={changeData}
                                value={socIP ? socIP.accountNumber : ""}
                                isHidden={!this.viewData[IP_ACCOUNT_NUMBER_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedTextDataInput
                                label={this.viewData[IP_LAST_NAME_STATE_KEY]}
                                fieldName={IP_LAST_NAME_STATE_KEY}
                                changeData={changeData}
                                value={socIP ? socIP.lastName : ""}
                                isHidden={!this.viewData[IP_LAST_NAME_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedTextDataInput
                                label={this.viewData[IP_SECOND_LAST_NAME_STATE_KEY]}
                                fieldName={IP_SECOND_LAST_NAME_STATE_KEY}
                                changeData={changeData}
                                value={socIP ? socIP.secondLastName : ""}
                                isHidden={!this.viewData[IP_SECOND_LAST_NAME_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedTextDataInput
                                label={this.viewData[IP_FIRST_NAME_STATE_KEY]}
                                fieldName={IP_FIRST_NAME_STATE_KEY}
                                changeData={changeData}
                                value={socIP ? socIP.firstName : ""}
                                isHidden={!this.viewData[IP_FIRST_NAME_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                        </div>
                        <div className="row">
                            <SizedDropdownDataInput
                                label={this.viewData[IP_ACCOUNT_TYPE_STATE_KEY]}
                                fieldName={IP_ACCOUNT_TYPE_STATE_KEY}
                                value={socIP ? socIP.accountType : ""}
                                changeData={changeData}
                                options={getLookupValues(ACCOUNT_TYPE_LOOKUP, lookupValues)}
                                allowNull={false}
                                readOnly={isReadonly}
                                isHidden={!this.viewData[IP_ACCOUNT_TYPE_STATE_KEY]} />
                            <SizedTextDataInput
                                label={this.viewData[IP_SUB_ACCOUNT_STATE_KEY]}
                                fieldName={IP_SUB_ACCOUNT_STATE_KEY}
                                changeData={changeData}
                                value={socIP ? socIP.subAccount : ""}
                                isHidden={!this.viewData[IP_SUB_ACCOUNT_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedDropdownDataInput
                                label={this.viewData[IP_DEFAULT_SOCIETY_OF_LICENSE_STATE_KEY]}
                                fieldName={IP_DEFAULT_SOCIETY_OF_LICENSE_STATE_KEY}
                                value={socIP ? socIP.defaultSocietyOfLicense : ""}
                                changeData={changeData}
                                options={getLookupValues(SOCIETY_OF_LICENSE_LOOKUP, lookupValues)}
                                allowNull={true}
                                readOnly={isReadonly}
                                isHidden={!this.viewData[IP_DEFAULT_SOCIETY_OF_LICENSE_STATE_KEY]} />
                        </div>
                    </div>
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>IPI Information</h1>
                            </div>
                        </div>
                        <div className="row">
                            {(tabs[activeTab].title === 'New Account') ?
                                <SizedTextDataInput
                                    label={this.viewData[IP_IPBASENUMBER_STATE_KEY]}
                                    fieldName={IP_IPBASENUMBER_STATE_KEY}
                                    changeData={changeData}
                                    value={ipBaseNumber}
                                    readOnly={false || isReadonly}
                                    isHidden={!this.viewData[IP_IPBASENUMBER_STATE_KEY]}
                                />
                                :
                                <SizedTextDataInput
                                    label={this.viewData[IP_IPBASENUMBER_STATE_KEY]}
                                    fieldName={IP_IPBASENUMBER_STATE_KEY}
                                    changeData={changeData}
                                    value={ipBaseNumber}
                                    readOnly={true}
                                    isHidden={!this.viewData[IP_IPBASENUMBER_STATE_KEY]}
                                />
                            }

                            <SizedDateDataInput
                                label={this.viewData[IP_BIRTHDATE_STATE_KEY]}
                                fieldName={IP_BIRTHDATE_STATE_KEY}
                                changeData={changeData}
                                value={birthDate ? birthDate.toString() : null}
                                readOnly={true}
                                isHidden={!this.viewData[IP_BIRTHDATE_STATE_KEY]}
                            />
                            <SizedDateDataInput
                                label={this.viewData[IP_DEATHDATE_STATE_KEY]}
                                fieldName={IP_DEATHDATE_STATE_KEY}
                                changeData={changeData}
                                value={deathDate ? deathDate.toString() : null}
                                readOnly={true}
                                isHidden={!this.viewData[IP_DEATHDATE_STATE_KEY]}
                            />
                            <SizedTextDataInput
                                label={this.viewData[IP_STATUS_STATE_KEY]}
                                fieldName={IP_STATUS_STATE_KEY}
                                changeData={changeData}
                                value={getStatusCodeValue(IPI_STATUS_LOOKUP, lookupValues, status)}
                                readOnly={true}
                                isHidden={!this.viewData[IP_STATUS_STATE_KEY]}
                            />
                            <SizedTextDataInput
                                label={this.viewData[IP_REFERENCED_IP_STATE_KEY]}
                                fieldName={IP_REFERENCED_IP_STATE_KEY}
                                changeData={changeData}
                                value={getReferencedIP(status, getStatusCodeValue(IPI_STATUS_LOOKUP, lookupValues, status))}
                                readOnly={true}
                                isHidden={!this.viewData[IP_REFERENCED_IP_STATE_KEY]}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        return <div />;
    }
}
