import React from "react";
import { createCopyOfTableContents } from "../../../../membersportal/services/TableFunctions";
import DisplayTable from "../../../../membersportal/sharedComponents/displayTable/DisplayTable";
import { SizedActionButton } from "../../../components/actionButton/ActionButton";
import { APPLICABLE_WORKS_KEY, SEARCH_VIEW } from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IAgreementWork } from "../../../types/IAgreementWork";
import { IAgreementWorkDataItems } from "../../../types/IAgreementWorkDataItems";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { getLookupValues, mapLookupKey, getLookupDefault } from "../../../../lookup/services/LookupHelpers";
import { APPLICABLE_WORKS_LOOKUP } from "../../../../../src/lookup/Consts";

export interface IAgreementMaintenanceWorkTableContainerProps {
    tableContents: IAgreementWorkDataItems[];
    dataGridTableData: IRepertoireComponentDataItem;
    componentInstance: string;
    openWork: (openItem: IAgreementWorkDataItems) => void;
    changeData: (value: any, name: IRepertoireStateKeys, objectKey?: string, ipRepresentationsIdsToRemove?: number[]) => void;
    stateKey: string;
    lookupValues: any;
    showModal: any;
}

const AgreementMaintenanceWorkTableContainer: React.FC<IAgreementMaintenanceWorkTableContainerProps> = ({
    tableContents,
    dataGridTableData,
    componentInstance,
    stateKey,
    changeData,
    openWork,
    lookupValues,
    showModal,
}) => {
    const EXCLUSION_UNTIL = 'exclusionUntil';
    const WORKS_RETAINED_UNTIL = 'worksRetainedUntil';
    const WORKS_COLLECTION_UNTIL = 'workCollectionUntil';
    const USE_DEFAULT_COLLECTION = 'useDefaultCollection';

    let filteredTableData = dataGridTableData.fields.filter(
        (field: IRepertoireField) => {
             return field.componentInstance === componentInstance && field.data !== 'Work'
        }
     )

    const removeRowAction = (rowItem: any) => {
        const tableContentsCopy = createCopyOfTableContents(tableContents);
        if (tableContentsCopy.indexOf(rowItem) > -1) {
            tableContentsCopy.splice(tableContentsCopy.indexOf(rowItem), 1);
        }
        changeData(tableContentsCopy, stateKey, null, null);
    }

    const rowAction = (openItem: any) => {
        openWork(openItem)
    }

    const rowActions = {
        iconButton: {
            icon: "icon ms-Icon ms-Icon--OpenInNewTab",
            onClick: rowAction,
        }            
    }

    const onChange = (
        value: any,
        fieldName: string,
        rowIndex: number,
    ) => {
        const tableContentsCopy = createCopyOfTableContents(tableContents);
        tableContentsCopy[rowIndex][fieldName].value = value
        changeData(tableContentsCopy, stateKey);
    };

    const lookupOptionsAndOnChangeFunctions = {
        type: {
            onChangeValue: onChange,
        },
        exclusionUntil: {
            options: getLookupValues(mapLookupKey(stateKey, EXCLUSION_UNTIL), lookupValues),
            onChangeValue: onChange,
        },
        worksRetainedUntil: {
            options: getLookupValues(mapLookupKey(stateKey, WORKS_RETAINED_UNTIL), lookupValues),
            onChangeValue: onChange,
        },
        workCollectionUntil: {
            options: getLookupValues(mapLookupKey(stateKey, WORKS_COLLECTION_UNTIL), lookupValues),
            onChangeValue: onChange,
        },
        useDefaultCollection: {
            options: getLookupValues(mapLookupKey(stateKey, USE_DEFAULT_COLLECTION), lookupValues),
            onChangeValue: onChange,
        },
        excludedUntilDate: {
            onChangeValue: onChange,
        },
        WorkCollectionEndDate: {
            onChangeValue: onChange,
        },
    };

    return  <div>
            <DisplayTable
                tableContents={tableContents}
                dataGridTableData={filteredTableData}
                showRowNumber={false}
                rowActions={rowActions}
                removeRowAction={removeRowAction}
                isRenderLinkTitle={true}
                lookupOptionsAndOnChangeFunctions={lookupOptionsAndOnChangeFunctions}
             />
            <div className="tableContainer">
                <SizedActionButton
                    buttonText={"Search"}
                    buttonAction={() => showModal(SEARCH_VIEW, APPLICABLE_WORKS_KEY, null, true, 'Search', '', false, 'modalSearch', null)}
                />
            </div>
        </div>
}

export default AgreementMaintenanceWorkTableContainer;