import * as React from "react";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { IStoragePathItemData } from "../../../../dataingestion/types/IStoragePathItemData";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { addFileToUpload, CleanUploadFilesState, setInDropZone, setProgressUploadFileProccess, sortColumn } from "../../../../redux/reducers/DataIngestionReducer";
import { hideModal, showModal, showUpdateFieldsModal, showWorkSubmission } from "../../../../redux/reducers/ModalReducer";
import { addTab, clearModalSearchResults, enableCustomField, setEditableFields, sortWorkFieldData, updateContributorSetType, updateWorkField } from "../../../../redux/reducers/RepertoireReducer";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import IDroppedFiles from "../../../../redux/types/IDroppedFiles";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IWorkState, IWorkStateKeys } from "../../../../redux/types/IWorkState";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { COMPONENTS_KEY, CONTRIBUTORS_KEY, INTRAY, MEDLEY_TYPE_HAS_DERIVED_VERSIONS, ARTICLE_METADATA_KEY, REPERTOIRE, TITLES_KEY, WORK_CISNET_SUBMISSIONS, WORK_ISWC_SUBMISSIONS, WORK_ICE_SUBMISSIONS, WORK_WID_SUBMISSIONS, SHEET_MUSIC_METADATA_KEY, BOOK_METADATA_KEY, CHAPTER_METADATA_KEY, MAGAZINE_METADATA_KEY, OTHER_INDICATORS_KEY, READONLY_INDICATORS_KEY, WORK_MAINTENANCE_ACCORDION_VIEW, COMMENTS_KEY, COMMENT_CATEGORY_KEY, ADDITIONAL_NUMBERS_KEY } from "../../../Consts";
import { DataSource } from "../../../types/DataSource";
import { IAccordionData } from "../../../types/IAccordionData";
import { IAgreementShareDisplayConfiguration } from "../../../types/IAgreementShareDisplayConfiguration";
import { IContributorSiteConfiguration } from "../../../types/IContributorSiteConfiguration";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IWorkContributorSetType } from "../../../types/IWorkContributorSetType";
import { ManualMergeOptions } from "../../../types/ManualMergeOptions";
import { IDistribution } from "../../../types/usageTypes/IDistribution";
import { IWorkProductDataItems } from "../../../types/IWorkProductDataItems";
import WorkSubmissionDetails from "../workSubmissionDetails/WorkSubmissionDetails";
import WorkMetadataAccordion from "../workMetadataAccordion/WorkMetadataAccordion";
import { IReadonlyFlagsField } from "../../../types/IReadonlyFlagsField";
import { IFlag } from "../../../types/IFlag";
import { DataTypeHelper } from "../../../../core/services/DataTypeHelper";
import { If } from "../../../../core/components/If";
import SaveResult from "../../../components/saveResult/SaveResult";
import { IValidationMessage } from "../../../types/IValidationMessage";
import WorkNumberAdditionalTable from "./workNumberAdditionalTable";
import { INumberAdditional } from "../../../types/INumberAdditional";
import ContributorsDataViewResizeListener from "../contributorsDataView/ContributorDataViewResizeListener";

export interface IWorkMaintenanceAccordionProps {
    updateWorkField: typeof updateWorkField;
    sortWorkFieldData: typeof sortWorkFieldData;
    updateContributorSetType: typeof updateContributorSetType;
    addTab: typeof addTab;
    showModal: typeof showModal;
    openAccordion: (accordionName: string, componentName: string) => void;
    closeAccordion: (accordionName: string, componentName: string) => void;
    field: IRepertoireField;
    data: IAccordionData;
    dataGridTableData?: IRepertoireComponentDataItem;
    contributorsDataViewData?: IRepertoireComponentDataItem;
    workMetadataAccordionViewData?: IRepertoireComponentDataItem;
    clearModalSearchResults: typeof clearModalSearchResults;
    lookupValues: ILookupDictionary;
    activeAccordian: string[];
    tabs: ITabReduxItem[];
    activeTab: number;
    dataSource: DataSource;
    getAgreementDetails?: (dataSource: string, agreementID: number, lookups: ILookupDictionary, dataAction?: IDataActionToolbar[], agreementGeneralDataView?: IRepertoireComponentDataItem) => void;
    workContributorSetTypes?: IWorkContributorSetType[];
    isReadonly: boolean;
    getWorkDetails: (dataSource: string, workID: number) => void;
    getProductDetails: (dataSource: string, productID: number) => void;
    getIPDetails: (ipBaseNumber: string, accountNumber: string) => void;
    showWorkSubmissionDetail?: typeof showWorkSubmission;
    hideModal?: typeof hideModal;
    viewData: IRepertoireComponentDataItem;
    workSubmissionDetails?: IRepertoireComponentDataItem;
    work: IWorkState;
    currentUser?: string;
    manualMergeWork: (matchingWorkId: number, manualMergeOption: ManualMergeOptions) => void;
    contributorDisplaySettings: IContributorSiteConfiguration[];
    agreementMaintenanceGeneralDataView: IRepertoireComponentDataItem;
    customFields?: number[];
    enableCustomField?: typeof enableCustomField;
    containerDetailsWindowComponentData: IComponentDataItem;
    distributions: IDistribution[];
    destinations: ILookupInstance[];
    selectedFileSystem: IFileSystem;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    sortColumn: typeof sortColumn;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    storagePaths: IStoragePathItemData[];
    storagePathItemData: IComponentDataItem;
    refreshDirectory: (filesystem: IFileSystem) => void;
    attachedFiles?: IAttachedFile[];
    updateSetTypeOnBreak: (setType: string) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableContributorFieldGridDataView: IRepertoireComponentDataItem;
    contributorGridEditableFields: IRepertoireField[];
    setEditableFields: typeof setEditableFields;
    updateContributorFields?: (fields: IRepertoireField[], componentName: string, componentInstance?: string) => void;
    agreementShareDisplayOptions: IAgreementShareDisplayConfiguration;
    shareDecimalsToDisplay?: number;
    resetMessageBanner?: () => void;
    editableFields: IRepertoireField[];
    readonlyIndicators: IFlag[];
    readonlyFlagsField : IReadonlyFlagsField[];
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    addNewAccount: () => void;
    expandedWorkResults?: number[];
    expandMatchingWork?: (index: number) => void;
    saveResultData: IRepertoireComponentDataItem;
    incrementTempID?: () => void;
    commentCategoryFlag?: boolean;
    saveAdditionalWorkNumbers?: (pageNumber: number, additionalNumbers: INumberAdditional[]) => void;
    getCurrentPageDetails?: (pageNumber: number) => void;
}

interface IWorkMaintenanceAccordionState {
    isAccordionActive: boolean;
}

export default class WorkMaintenanceAccordion extends React.PureComponent<
    IWorkMaintenanceAccordionProps,
    IWorkMaintenanceAccordionState
> {
    constructor(props: IWorkMaintenanceAccordionProps) {
        super(props);

        this.state = {
            isAccordionActive: false
        };
    }


    componentDidMount() {
        const {
            field: { name, componentName },
            tabs,
            activeTab,
            openAccordion,
            closeAccordion,
            work
        } = this.props;

        if (tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.find(x => x === name)) {
            if (name === COMPONENTS_KEY && !work.compoundType) {
                closeAccordion(name, componentName);
                this.setState({ isAccordionActive: false });
            }
            else {
                openAccordion(name, componentName);
                this.setState({ isAccordionActive: true });
            } 
        }
    }

    toggleAccordion = () => {
        const { isAccordionActive } = this.state;
        const {
            field: { name, componentName },
            tabs,
            activeTab,
            work,
            openAccordion,
            closeAccordion
        } = this.props;

        if (name !== COMPONENTS_KEY || work.compoundType != null || work.isArrangement) {
            if (tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(name)) {
                closeAccordion(name, componentName);
            } else {
                openAccordion(name, componentName);
            }
        }
        this.setState({
            isAccordionActive: !isAccordionActive
        });
    };

    openProduct(openItem: IWorkProductDataItems): void {

        if (openItem.intrayIndicator.value) {
            this.props.getProductDetails(INTRAY, openItem.productCoreID)
        } else {
            this.props.getProductDetails(REPERTOIRE, openItem.productCoreID)
        }
    }

    openInNewTab(openItem: any): void {

        if (openItem.intrayIndicator) {
            if (openItem.intrayIndicator.value) {
                this.props.getWorkDetails(INTRAY, openItem.workCode)
            } else {
                this.props.getWorkDetails(REPERTOIRE, openItem.workCode)
            }
        }else if (openItem["repertoireWorkID"] != undefined) {
            this.props.getWorkDetails(REPERTOIRE, openItem["repertoireWorkID"]);
        }
        else if (openItem["administeredByIPBaseNumber"] != undefined) {
            this.props.getIPDetails(openItem["administeredByIPBaseNumber"].value, null);
        }
        else if (openItem["ipBaseNumber"] != undefined) {
            this.props.getIPDetails(openItem["ipBaseNumber"], null);
        }
        else if (openItem["accountNumber"] != undefined) {
            this.props.getIPDetails(null, openItem["accountNumber"].value);
        }
        else if(openItem["workNumberID"] != undefined){
            this.props.getWorkDetails(openItem["dataSource"], openItem["workNumberID"]);
        }

    }

    changeData = (value: any, name: IRepertoireStateKeys) => {
        this.props.updateWorkField(value, name as IWorkStateKeys);
    }

    groupActionClick = (action: any, tableContentItem: any) => {
        this.props.manualMergeWork(tableContentItem.repertoireWorkID, action);
    }
    
    readonlyIndicatorsCheck = (fieldName: string) : boolean => {
        const{readonlyFlagsField, readonlyIndicators} = this.props

        let value = (readonlyIndicators && readonlyFlagsField) ? ((readonlyIndicators?.filter
            (x => x.flagTypeCode == (readonlyFlagsField?.filter
                (x => x.FieldNames?.includes(fieldName))[0]?.Code))[0]?.value)) : "0";

        let isReadOnly = DataTypeHelper.getBooleanFormString(value);
                
        return isReadOnly;
    }

    sortData(value: any, name: IRepertoireStateKeys): void {
        this.props.sortWorkFieldData(value, name as IWorkStateKeys);
    }

    renderAccordionData = () => {
        const {
            field,
            contributorsDataViewData,
            workMetadataAccordionViewData,
            showModal,
            clearModalSearchResults,
            lookupValues,
            data,
            addTab,
            updateWorkField,
            tabs,
            activeTab,
            dataSource,
            getAgreementDetails,
            workContributorSetTypes,
            updateContributorSetType,
            isReadonly,
            showWorkSubmissionDetail,
            hideModal,
            viewData,
            workSubmissionDetails,
            work,
            currentUser,
            manualMergeWork,
            contributorDisplaySettings,
            agreementMaintenanceGeneralDataView,
            customFields,
            enableCustomField,
            updateSetTypeOnBreak,
            showUpdateFieldsModal,
            editableFields,
            editableContributorFieldGridDataView,
            contributorGridEditableFields,
            setEditableFields,
            readonlyFlagsField,
            readonlyIndicators,
            updateContributorFields,
            agreementShareDisplayOptions,
            shareDecimalsToDisplay,
            resetMessageBanner,
            updateComponentFieldsItems,
            addNewAccount,
            expandedWorkResults,
            expandMatchingWork,
            incrementTempID,
            commentCategoryFlag,
            saveAdditionalWorkNumbers,
            getCurrentPageDetails
        } = this.props;

        let { dataGridTableData } = this.props

        const isReadOnlyIndicators = field.name == READONLY_INDICATORS_KEY;

        if (!commentCategoryFlag && field.name === COMMENTS_KEY) {
            dataGridTableData.fields = dataGridTableData.fields.filter(x => x.name != COMMENT_CATEGORY_KEY);
        }

        if (tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(field.name) && data && data.data) {
            if (field.name === CONTRIBUTORS_KEY) {
                return (
                    <ContributorsDataViewResizeListener
                        tableContents={data.data}
                        contributorsDataViewData={contributorsDataViewData}
                        dataGridTableData={dataGridTableData}
                        updateWorkField={updateWorkField}
                        sortData={this.sortData.bind(this)}
                        resetMessageBanner={resetMessageBanner}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        lookupValues={lookupValues}
                        tableActions={data.actions}
                        addTab={addTab}
                        dataSource={dataSource}
                        setTypeGroup={tabs[activeTab].workMaintenanceState.work.territorySpecificInfo.setTypeGroup}
                        getAgreementDetails={getAgreementDetails}
                        workContributorSetTypes={workContributorSetTypes}
                        updateContributorSetType={updateContributorSetType}
                        contributorInheritanceChanged={tabs[activeTab].workMaintenanceState.contributorInheritanceChanged}
                        isReadonly={isReadonly ? isReadonly : this.readonlyIndicatorsCheck(field.name)}
                        openInNewTab={this.openInNewTab.bind(this)}
                        tabs={tabs}
                        activeTab={activeTab}
                        currentUser={currentUser}
                        contributorConfiguration={JSON.stringify(contributorDisplaySettings)}
                        agreementMaintenanceGeneralDataView={agreementMaintenanceGeneralDataView}
                        updateSetTypeOnBreak={updateSetTypeOnBreak}
                        editableContributorFieldGridDataView={editableContributorFieldGridDataView}
                        editableFields={editableFields}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        contributorGridEditableFields={contributorGridEditableFields}
                        setEditableFields={setEditableFields}
                        updateContributorFields={updateContributorFields}
                        hideModal={hideModal}
                        agreementShareDisplayOptions={agreementShareDisplayOptions}
                        shareDecimalsToDisplay={shareDecimalsToDisplay}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        addNewAccount={addNewAccount}
                        incrementTempID={incrementTempID}
                    />
                )
            }
            if (field.name === ADDITIONAL_NUMBERS_KEY) {
                return (
                    <>
                        <WorkNumberAdditionalTable
                            dataGridTableData={dataGridTableData}
                            lookupValues={lookupValues}
                            data={data.data}
                            changeData={this.changeData.bind(this)}
                            saveAdditionalWorkNumbers={saveAdditionalWorkNumbers}
                            getCurrentPageDetails={getCurrentPageDetails}
                            workId={tabs[activeTab].workMaintenanceState.work.workID}
                        />
                    </>
                )
            }
            else if (field.name == ARTICLE_METADATA_KEY ||
                field.name == SHEET_MUSIC_METADATA_KEY ||
                field.name == BOOK_METADATA_KEY ||
                field.name == CHAPTER_METADATA_KEY ||
                field.name == MAGAZINE_METADATA_KEY ||
                field.name == OTHER_INDICATORS_KEY ||
                field.name == READONLY_INDICATORS_KEY){
                return(
                    <div className={ `${isReadonly || isReadOnlyIndicators ? 'versionHistoryPointer' : ''} ${["contentAccordion", field.name].join(" ") }`}
                    
                    >
                        <WorkMetadataAccordion 
                        tabContents ={data.data}
                        lookupValues={lookupValues}
                        isReadOnly={isReadonly || isReadOnlyIndicators ? true : this.readonlyIndicatorsCheck(field.name)}
                        changeData={this.changeData.bind(this)}
                        componentInstance={field.name}
                        readonlyFlagsField={readonlyFlagsField}
                        readonlyIndicators={readonlyIndicators}
                        workMetadataAccordionViewData={workMetadataAccordionViewData}

                        />
                    </div>
                    
                )
            }
            else if (field.name == WORK_WID_SUBMISSIONS ||
                field.name == WORK_ISWC_SUBMISSIONS ||
                field.name == WORK_ICE_SUBMISSIONS ||
                field.name == WORK_CISNET_SUBMISSIONS) {
                return (
                    <WorkSubmissionDetails
                        workSubmissionDetails={workSubmissionDetails}
                        updateWorkField={updateWorkField}
                        viewData={viewData}
                        tableContents={data.data}
                        tableActions={data.actions}
                        stateKey={field.name}
                        sortData={this.sortData.bind(this)}
                        componentInstance={field.name}
                        dataGridTableData={dataGridTableData}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        lookupValues={lookupValues}
                        addTab={addTab}
                        showRowNumber={false}
                        showDropdownsAsCodes={field.name == TITLES_KEY}
                        dataSource={dataSource}
                        isReadOnly={isReadonly}
                        openInNewTab={this.openInNewTab.bind(this)}
                        showWorkSubmissionDetail={showWorkSubmissionDetail}
                        hideModal={hideModal}
                        componentName={field.name}
                        work={work}
                        currentUser={currentUser}
                        tabs={tabs}
                        activeTab={activeTab}
                        incrementTempID={incrementTempID}
                    />
                )
            }
            else if (field.name === COMPONENTS_KEY) {

                let filteredGridTableData: IRepertoireComponentDataItem = { fields: dataGridTableData.fields };

                if (work.isArrangement  || work.compoundType === MEDLEY_TYPE_HAS_DERIVED_VERSIONS) {
                    let filteredFields = filteredGridTableData.fields.filter(
                        (field: IRepertoireField) => field.name !== "duration")
                    filteredGridTableData.fields = filteredFields;
                }
                return (
                    <DataGridTable
                            tableContents={data.data}
                            tableActions={data.actions}
                            stateKey={field.name}
                            changeData={this.changeData.bind(this)}
                            sortData={this.sortData.bind(this)}
                            componentInstance={field.name}
                            dataGridTableData={filteredGridTableData}
                            showModal={showModal}
                            clearModalSearchResults={clearModalSearchResults}
                            lookupValues={lookupValues}
                            addTab={addTab}
                            showRowNumber={false}
                            showDropdownsAsCodes={false}
                            dataSource={dataSource}
                            isReadOnly={isReadonly}
                            openInNewTab={this.openInNewTab.bind(this)}
                            showWorkSubmissionDetail={showWorkSubmissionDetail}
                            hideModal={hideModal}
                            currentUser={currentUser}
                            groupActionClick={this.groupActionClick.bind(this)}
                            dependentRowAction={this.openInNewTab.bind(this)}
                            updateWorkField={updateWorkField}
                            customFields={customFields}
                            enableCustomField={enableCustomField}
                            tabs={tabs}
                            activeTab={activeTab}
                            incrementTempID={incrementTempID}
                            entity={work}
                        />
                    )
            }
            else {
                return (
                    <DataGridTable
                        tableContents={data.data}
                        tableActions={data.actions}
                        stateKey={field.name}
                        changeData={this.changeData.bind(this)}
                        sortData={this.sortData.bind(this)}
                        componentInstance={field.name}
                        dataGridTableData={dataGridTableData}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        lookupValues={lookupValues}
                        addTab={addTab}
                        showRowNumber={false}
                        showDropdownsAsCodes={field.name == TITLES_KEY}
                        dataSource={dataSource}
                        isReadOnly={isReadonly ? isReadonly : this.readonlyIndicatorsCheck(field.name)}
                        dependentRowAction={this.openProduct.bind(this)}
                        openInNewTab={this.openInNewTab.bind(this)}
                        showWorkSubmissionDetail={showWorkSubmissionDetail}
                        hideModal={hideModal}
                        currentUser={currentUser}
                        groupActionClick={this.groupActionClick.bind(this)}
                        updateWorkField={updateWorkField}
                        customFields={customFields}
                        enableCustomField={enableCustomField}
                        tabs={tabs}
                        activeTab={activeTab}
                        expandedWorkResults={expandedWorkResults}
                        expandMatchingWork={expandMatchingWork}
                        incrementTempID={incrementTempID}
                        entity={work}
                    />
                )
            }
        }
    };

    

    render() {
        const { field, tabs, activeTab, saveResultData, resetMessageBanner } = this.props;
        let sections = [];
        let messages: IValidationMessage[];
        let msg = {messageKey:''};
        if(tabs[activeTab].validationMessages != null) {
            messages = [];
            tabs[activeTab].validationMessages.forEach(e => {
                if(saveResultData.fields.find(x => x.name.toString() === e.messageKey)?.section != null) {
                    sections.push(saveResultData.fields.find(x => x.name.toString() === e.messageKey)?.section);
                    let validationMessage: IValidationMessage = { messageKey: e.messageKey, additionalInfo: e.additionalInfo }
                    messages.push(validationMessage);
                }
            })
        }
        return (
            <div className="AccordionContainer">
                <div
                    className={["itemAccordion", tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(field.name) ? "activeItem" : null].join(" ")}
                    onClick={() => this.toggleAccordion()}
                >
                    <i
                        title="Expand section"
                        className={["icon ms-Icon ms-Icon--ChevronRight",
                            tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(field.name) ? "expanded" : null].join(" ")}
                    />
                    {field.data}
                    <If condition={tabs[activeTab].validationMessages != null && 
                    tabs[activeTab].validationMessages.length > 0 && 
                    (sections.includes(field.name))}>
                    <SaveResult
                        componentInstance={WORK_MAINTENANCE_ACCORDION_VIEW}
                        saveSuccess={tabs[activeTab].saveSuccessful}
                        postSuccess={tabs[activeTab].postSuccessful}
                        matchingSuccess={tabs[activeTab].matchingSuccessful}
                        mergeSuccess={tabs[activeTab].mergeSuccessful}
                        exportSuccess={tabs[activeTab].exportSuccessful}
                        saveResultData={saveResultData}
                        messages={messages}
                        resetMessageBanner={resetMessageBanner}
                        deleteAccount={tabs[activeTab].deleteSuccessful}
                        newAllocateDistributionPoolsJobSuccess={tabs[activeTab].newAllocateDistributionPoolsJobSuccess}
                        allocationJobInProgress={false}                        
                    />
                    </If>
                </div>
                <div>{this.renderAccordionData()}</div>
            </div>
        );
    }
}
