import * as React from "react";
import { ITriggerManualJob } from "../../../redux/types/ITriggerManualJob";
import { IAckHWMState } from "../../types/IAckHWMState";
import TextDataInput from "../../../repertoire/components/textDataInput/TextDataInput";
import {
    CWR_ACK2_JOB_TYPE,
    HIGH_WATER_MARK_KEY,
    HIGH_WATER_MARK_TITLE,
} from "../../Consts";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react/lib/Dropdown";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { getLookupValues } from '../../../lookup/services/LookupHelpers';
import { SUBMITTER_LOOKUP } from "../../../lookup/Consts";
import DatePickerDataInput from "../../../repertoire/components/dateDataInput/DatePickerDataInput";

export interface ICWRACK2JobParametersProps {
    setManualJobParameters: (parameters: ITriggerManualJob) => void;
    cwrAckHWM: IAckHWMState[];
    lookups: ILookupDictionary;
}

interface ICWRACK2JobParametersState {
    hwm: any;
    selectedOpenSubmitters: any[];
    submitters: any[];
}

export class CWRACK2JobParameters extends React.Component<
    ICWRACK2JobParametersProps,
    ICWRACK2JobParametersState
> {
    constructor(props: ICWRACK2JobParametersProps) {
        super(props);

        this.state = { 
            hwm: null,
            selectedOpenSubmitters: [],
            submitters: getLookupValues(SUBMITTER_LOOKUP, this.props.lookups),
        };

    }

    renderIPIOptions = () => {
        const { submitters } = this.state;
        const options = [];
        options.push({ key: "All", text: "All", data: "*" });
        if (submitters) {
            submitters.map((s) =>
                options.push({
                    data: s.extraFields.IPNameNumber,
                    text: s.description,
                    key: s.extraFields.IPNameNumber.toString(),
                })
            );
        } 
        return options;
    };

    changeHWM = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        const { setManualJobParameters } = this.props;
        const { selectedOpenSubmitters, submitters } = this.state;

        let selectSubmitterState = selectedOpenSubmitters;
        let submitterOptions = this.renderIPIOptions();
        if (option.text === "All" && option.selected) {
            submitterOptions.forEach((option) => (
                selectSubmitterState.push(option.key.toString())
            ));
        }
        else if (option.text === "All" && !option.selected) {
            selectSubmitterState = [];
        }
        else if (option.text !== "All" && !selectSubmitterState.includes(option.key.toString())) {
            selectSubmitterState.push(option.key.toString());
        }
        else {
            const index = selectSubmitterState.indexOf(option.key.toString());

            if (index > -1) {
                selectSubmitterState.splice(index, 1);
            }

            if (selectSubmitterState.includes(0)) {
                selectSubmitterState.splice(0, 1);
            }
        }

        const manualJobParameters: ITriggerManualJob = {
            jobType: CWR_ACK2_JOB_TYPE,
            jobParameters: JSON.stringify(selectSubmitterState.length > 0 && selectSubmitterState[0] === "All" ? { "IPINameNumbers": ["All"] } : { "IPINameNumbers": selectSubmitterState, "HighWaterMark": null }),
        };
        setManualJobParameters(manualJobParameters);
        if (selectSubmitterState.length === 1 && selectSubmitterState[0] != "All") {
            const ackHWM = submitters.find(
                (x) => x.extraFields.IPNameNumber.toString() === selectSubmitterState[0]
            );
            const hwmDate = ackHWM ? ackHWM.extraFields.ACKHighWaterMark : null;
            this.setState({ hwm: hwmDate });
        }
        this.setState(() => ({ selectedOpenSubmitters: selectSubmitterState }));
    };

    updateHWM = (value: string | boolean | Date, fieldName?: string, index?: number) => {

        const { setManualJobParameters } = this.props;
        const { hwm, selectedOpenSubmitters } = this.state;
        const manualJobParameters: ITriggerManualJob = {
            jobType: CWR_ACK2_JOB_TYPE,
            jobParameters: JSON.stringify(selectedOpenSubmitters.length > 0 && selectedOpenSubmitters[0] === "All" ? { "IPINameNumbers": ["All"] } : { "IPINameNumbers": selectedOpenSubmitters, "HighWaterMark": value }),
        };
        setManualJobParameters(manualJobParameters);
        this.setState({ hwm: value });
    }

    render() {
        const { hwm } = this.state;
        
        const onRenderPeriodDropdownTitle = (
            options: IDropdownOption[] | undefined
        ): JSX.Element => {
            const displayValue = options.filter(o => o.text !== 'All').map(o => o.text).join(", ");
            return (
                <div>
                    <span>{displayValue}</span>
                </div>
            );
        };

        return (
            <>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <label>Submitter</label>
                    <Dropdown
                        selectedKeys={this.state.selectedOpenSubmitters}
                        options={this.renderIPIOptions()}
                        multiSelect={true}
                        onChange={this.changeHWM}
                        placeholder={""}
                        disabled={false}
                        required={true}
                        onRenderTitle={onRenderPeriodDropdownTitle}
                    />
                </div>
                
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <DatePickerDataInput
                        label={HIGH_WATER_MARK_TITLE}
                        fieldName={HIGH_WATER_MARK_KEY}
                        changeData={this.updateHWM}
                        value={hwm}
                        readOnly={false}
                        isHidden={false}
                    />
                </div>
            </>
        );
    }
}
