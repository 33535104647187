import * as React from "react";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { IDraftWorkSearchQuery } from "../types/IDraftWorkSearchQuery";
import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { IDraftWorkSearchResult } from "../types/IDraftWorkSearchResult";
import { setDataSource } from "../../redux/reducers/MembersPortalReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { DataSource } from "../../repertoire/types/DataSource";
import { IMemberWorkSearchResult } from "../types/IMemberWorkSearchResult";
import { MemberPortalSearchTypeItem } from "../../redux/types/MemberPortalSearchTypeItem";
import { MemberPortalSearchType } from "../../redux/types/MemberPortalSearchType";
import { SearchRequests } from "../../repertoire/services/SearchRequests";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP } from "../ConfigurationConsts";
import { FormatFields } from "../../redux/types/FormatFields";
import DraftWorksSearchView from "./DraftWorksSearchView";

export interface IDraftWorksPageProps {
    draftWorksPageData: IMembersPortalComponentDataItem;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    searchDraftWorks?: (query: IDraftWorkSearchQuery) => void;
    draftWorkSearchResults: IDraftWorkSearchResult[];
    memberWorkSearchResults?: IMemberWorkSearchResult[];
    searchSuccessful: boolean;
    draftWorksMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    getDraftWorkDetails: (dataSource: string, workID: number, lookupValues: ILookupDictionary, draftWorkMaintenanceDataViewData: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    addNewDraftWorks: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, draftWorksMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    disabled?: boolean;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    setDataSource: typeof setDataSource;
    dataSource: string;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    memberPortalSearchType: MemberPortalSearchTypeItem;
    currentUserNameNumber?: string;
    expandWorkResult?: (workResult: number) => void;
    expandedWorkResults?: number[];
    expandAllResults: () => void;
    expandAll: boolean;
}

const DraftWorksPage = (props: IDraftWorksPageProps) => {
    const [loaded, setLoaded] = React.useState(false);
    const { instance, accounts } = useMsal();

    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])


    const checkIfLoaded = () => {
        const { draftWorksPageData } = props;

        if (draftWorksPageData.fields && draftWorksPageData.fields.length >= 1 && !loaded) {
            setLoaded(true);
            const header = draftWorksPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }
    const addNewDraftWorks = (): void => {
        const { addNewDraftWorks, lookupValues, draftWorksMaintenanceGeneralDataViewData, currentUserNameNumber } = props;
        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                addNewDraftWorks(lookupValues, currentUserNameNumber, true, draftWorksMaintenanceGeneralDataViewData, formats);
            });
    }

    let currentDataSource = "";
    switch (props.memberPortalSearchType.code) {
        case MemberPortalSearchType.MyDraftSearch.code:
            setDataSource(DataSource.Intray);
            currentDataSource = DataSource.Intray;
            break;
        case MemberPortalSearchType.MyRegisteredSearch.code:
        case MemberPortalSearchType.AllRegisteredSearch.code:
            setDataSource(DataSource.Repertoire);
            currentDataSource = DataSource.Repertoire;
            break;
    }

    if (loaded) {
        const {
            draftWorksPageData,
            draftWorksMaintenanceGeneralDataViewData,
            searchViewData,
            searchResultsTableData,
            searchDraftWorks,
            searchSuccessful,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            resetPagination,
            sortSearchResults,
            lookupValues,
            expandAllResults,
            expandAll,
            currentUserNameNumber,
            memberPortalSearchType
        } = props;

        const getDraftWorkDetails = (dataSource: string, workID: number) => {
            SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                .then(formats => {
                    props.getDraftWorkDetails(dataSource, workID, lookupValues, draftWorksMaintenanceGeneralDataViewData, formats);
                });
        }

        let searchPageLabelTitle = null;
        if (draftWorksPageData && draftWorksPageData.fields.length > 0) {
            searchPageLabelTitle = draftWorksPageData.fields.find((v, i, a) => v.name == "searchPageLabelTitle").data;
        }

        let searchResults = null

        switch (memberPortalSearchType.code) {
            case MemberPortalSearchType.MyDraftSearch.code:
                searchResults = props.draftWorkSearchResults;
                break;

            case MemberPortalSearchType.MyRegisteredSearch.code:
            case MemberPortalSearchType.AllRegisteredSearch.code:
                searchResults = props.memberWorkSearchResults;
                break;
        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">{searchPageLabelTitle}</span>
                        <div className="newWorkButton" title="Register New Work">
                            <div className="IconTextButton" onClick={addNewDraftWorks}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                Register New Work
                            </div>
                        </div>
                    </div>
                </div>
                <DraftWorksSearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    lookupValues={lookupValues}
                    searchDraftWorks={searchDraftWorks}
                    draftWorkSearchResults={searchResults}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    resetPagination={resetPagination}
                    sortSearchResults={sortSearchResults}
                    expandAllResults={expandAllResults}
                    expandAll={expandAll}
                    currentUserNameNumber={currentUserNameNumber}
                    getDraftWorkDetails={getDraftWorkDetails}
                    memberPortalSearchType={memberPortalSearchType}
                    dataSource={currentDataSource}
                />
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default DraftWorksPage;
