import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import IAppState from "../../redux/types/IAppState";
import { mp_addTab, createNewDraftWork, resetPagination, sortSearchResults, updatePagination, setDataSource, expandWorkResult, expandAllResults } from "../../redux/reducers/MembersPortalReducer";
import {
    searchDraftWorksThunk,
    getDraftWorkDetailsThunk,
    // searchDraftWorksVersionHistoryThunk
} from "../../redux/thunks/MembersPortalThunks";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IDraftWorkSearchQuery } from "../types/IDraftWorkSearchQuery";
import DraftWorksPage from "./DraftWorksPage";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import { FormatFields } from "../../redux/types/FormatFields";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { updateUserPreferencesThunk } from "../../redux/thunks/RepertoireThunks";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { MemberPortalSearchTypeItem } from "../../redux/types/MemberPortalSearchTypeItem";
import { MemberPortalSearchType } from "../../redux/types/MemberPortalSearchType";

export default connect(
    (state: IAppState) => ({
        dataSource: state.membersPortal.dataSource,
        draftWorksPageData: state.membersPortal.componentData.DraftWorksPage,
        searchViewData: state.membersPortal.componentData.SearchView,
        searchResultsTableData: state.membersPortal.componentData.SearchResultsTable,
        draftWorkSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkSearchResult,
        searchSuccessful: state.membersPortal.searchSuccessful,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'draftWorks')[0]?.indexOfFirstResult,
        indexOfLastResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'draftWorks')[0]?.indexOfLastResult,
        resultsPerPage: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'draftWorks')[0]?.resultsPerSection,
        currentPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].currentPage ? state.membersPortal.tabs[state.membersPortal.activeTab].currentPage : undefined,
        draftWorksMaintenanceGeneralDataViewData: state.membersPortal.componentData.DraftWorkMaintenanceGeneralDataView,
        defaultActiveAccordions: state.membersPortal.defaultActiveAccordions,
        allResultsPerPage: state.membersPortal.resultsPerPage,
        roles: state.account.roles,
        memberPortalSearchType: MemberPortalSearchType.MyDraftSearch,
        currentUserNameNumber: state.account.currentUserNameNumber,
        expandedWorkResults: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandedWorkResults,
        expandAll: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandAll,
    }),
    (dispatch: Dispatch) => ({
        setDataSource: (dataSource: string) => dispatch(setDataSource(dataSource)),
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
        searchDraftWorks: (query: IDraftWorkSearchQuery) =>
            dispatch<any>(searchDraftWorksThunk(query)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) =>
            dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        getDraftWorkDetails: (dataSource: string, draftWorksID: number, lookupValues: ILookupDictionary, draftWorkMaintenanceDataViewData: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(getDraftWorkDetailsThunk(dataSource, draftWorksID, lookupValues, draftWorkMaintenanceDataViewData, formats)),
        addNewDraftWorks: (lookups: ILookupDictionary, currentUserNameNumber? : string, isNew?: boolean, draftWorksMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch(createNewDraftWork(lookups, currentUserNameNumber, isNew, draftWorksMaintenanceGeneralDataViewData, formats)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) =>
            dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        expandWorkResult: (workResult: number) => dispatch<any>(expandWorkResult(workResult)),
        expandAllResults: () => dispatch<any>(expandAllResults()),
    })
)(DraftWorksPage);
