import React, { PureComponent, FunctionComponent } from "react";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { EMPTY_STRING_VALUE, REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../Consts";
import { connect } from "react-redux";
import IAppState from "../../../redux/types/IAppState";
import { DatePicker } from "antd";
import dayjs from 'dayjs';

export interface IDateDataInputProps {
    label: string;
    value: string;
    changeData: (
        value: string | boolean,
        fieldName: IRepertoireStateKeys,
        index?: number
    ) => void;
    fieldName: IRepertoireStateKeys;
    readOnly?: boolean;
    isHidden: boolean;
    isMandatory?: boolean;
    customerDatePreference?: string;
}

const SizedDateDataInput: FunctionComponent<IDateDataInputProps> = (props: IDateDataInputProps) => {

    if (props.isHidden)
        return null;
    return <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} datePickerSize`} >
        <DateDataInput {...props} />
    </div>
}

class DateDataInput extends PureComponent<
    IDateDataInputProps
> {
    constructor(props: IDateDataInputProps) {
        super(props);
    }

    render() {
        const { label, changeData, fieldName, readOnly, isMandatory } = this.props;
        const val = this.props.value;
        const date = val && val.length >= 10 ? val.slice(0, 10) : '';

        const standardDateForProcessing = "YYYY-MM-DD";
        const EUROPEAN_DATE_FORMAT = "DD/MM/YYYY";
        const preferenceDateFormat = this.props.customerDatePreference ? this.props.customerDatePreference : EUROPEAN_DATE_FORMAT;

        const handleDateChange = (date) => {
            if(date) {
            changeData(date.format(standardDateForProcessing), fieldName)
            return;
            }
            changeData(undefined, fieldName)
        }

        return (
            <div className={'flexColumn'} >
                {label ? <label className={EMPTY_STRING_VALUE.concat(isMandatory ? " isMandatory " : "", readOnly ? " disabledItem " : "")}>{label}</label> : null}
                {readOnly ?
                  <DatePicker
                  value={this.props.value ? dayjs(this.props.value) : undefined}
                  onChange={handleDateChange}
                  disabled={true}
                  size="large"
                  format={preferenceDateFormat}
                  zIndexPopup={1056}
                   />

                 :
                    <DatePicker
                        value={this.props.value  ? dayjs(this.props.value ) : undefined}
                        onChange={handleDateChange}
                        size="large"
                        format={preferenceDateFormat}
                        zIndexPopup={1056}
                         />
                }
            </div>
        );
    }
}

export default connect((state: IAppState) => {
    return ({
    customerDatePreference: state.account.customerDate,
})
})(SizedDateDataInput);