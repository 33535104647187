import React from "react";
import { useField } from "formik";
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import { connect } from "react-redux";
import IAppState from "../../redux/types/IAppState";


const DatePickerFormikField =  ({ ...props }: { [x: string]: any; name: string }) => {
  const EUROPEAN_DATE_FORMAT = "DD/MM/YYYY";
  const preferenceDateFormat = props.customerDatePreference ? props.customerDatePreference : EUROPEAN_DATE_FORMAT;
  const [field, meta, { setValue }] = useField(props);
  return (
    <div data-testid={props.name} className={ meta.error || props.error ? "validationForkmikField" : "emptyValidationBorder" } >
    <DatePicker
    size={props.size ? props.size : 'medium'}
    style={{width: '100%', borderRadius: '3px'}}
      format={preferenceDateFormat}
      {...field}
      {...props}
      value={field.value ? dayjs(field.value) : undefined}
      onChange={(val) => {
        props.onChange && props.onChange();
        setValue(val);
      }}
    />
    </div>
  );
};

export default connect((state: IAppState) => {
  return ({
  customerDatePreference: state.account.customerDate,
})
})(DatePickerFormikField);