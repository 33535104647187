import * as React from 'react';
import { ILoginViewModel } from '../../types/ILoginViewModel';
import { Redirect } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { If } from '../../../core/components/If';
import { IComponentDataItem } from '../../../core/types/IComponentDataItem';
import {
    EMAIL_LABEL_FIELD,
    FORGOT_PASSWORD_LINK_TEXT_FIELD,
    LOGIN_FAILED_FIELD,
    LOGIN_HEADER_FIELD,
    LOGIN_LABEL_FIELD,
    MUSIC_INDUSTRY_HEADER_FIELD,
    PASSWORD_LABEL_FIELD
} from './LoginPageFields';
import { MSALLoginButton } from "../MSAL/MSALLoginButton";
import { LoginType } from '../../types/LoginType';
import { clearRepertoireCache } from '../../../redux/reducers/RepertoireReducer';

interface ILoginPageProps {
    location: any;
    loginFailed: boolean;
    isSignedIn: boolean;
    login: (viewModel: ILoginViewModel) => void;
    loginPageDataItem: IComponentDataItem;
    getAccountFields: () => void;
    getAppVersionNumber: () => void;
    appVersionNumber: string;
    redirect?: any;
    getLoginType: () => void;
    loginType: LoginType
}

interface ILoginPageState {
    email: string,
    password: string,
    rememberMe: boolean,
    loaded: boolean;
}

export class LoginPage extends React.Component<ILoginPageProps, ILoginPageState>{
    viewData;
    appVersionNumber;
    constructor(props: ILoginPageProps) {
        super(props);

        this.state = {
            email: '',
            password: '',
            rememberMe: false,
            loaded: false,
        };
        this.viewData = {};
    }

    componentDidMount() {
        const { loginPageDataItem, getAccountFields, getAppVersionNumber, getLoginType } = this.props;
        clearRepertoireCache();
        if (loginPageDataItem.fields.length === 0) {
            getAccountFields();
            getAppVersionNumber();
        }
        this.loadFields();
        getLoginType();
    }

    componentDidUpdate() {
        this.loadFields();
    }

    loadFields() {
        const { loaded } = this.state;
        if (!loaded) {
            if (!this.props.loginPageDataItem || this.props.loginPageDataItem.fields.length == 0 || this.props.appVersionNumber == null) {
                return;
            }

            const { loginPageDataItem: { fields } } = this.props;

            if (fields)
                fields.forEach(item => {
                    this.viewData[item.name] = item.data;

                    if (Object.keys(this.viewData).length === fields.length) {
                        this.setState({ loaded: true });
                    }
                });
        }
    }

    emailChanged(e: React.SyntheticEvent<HTMLInputElement>) {
        this.setState({ email: e.currentTarget.value });
    }

    passwordChanged(e: React.SyntheticEvent<HTMLInputElement>) {
        this.setState({ password: e.currentTarget.value });
    }

    rememberMeChanged(e: React.SyntheticEvent<HTMLInputElement>) {
        this.setState({ rememberMe: e.currentTarget.checked });
    }

    login(event: (React.FormEvent<Form> | React.MouseEvent<HTMLButtonElement, MouseEvent>)) {
        const { redirect } = this.props;

        if (event != null) { event.preventDefault(); }

        let vm: ILoginViewModel = {
            email: this.state.email,
            password: this.state.password,
            rememberMe: this.state.rememberMe,
            redirect: redirect,
            username: this.state.email
        }

        this.props.login(vm);
    }

    onKeyPress(e: (KeyboardEvent | React.KeyboardEvent<HTMLDivElement>)) {
        if (e.key === 'Enter') {
            this.login(null);
        }
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/' } }
        const { loginFailed, isSignedIn, redirect, appVersionNumber, loginType } = this.props;
        const { loaded } = this.state;

        if (isSignedIn && !redirect.search) {
            return <Redirect to={from} />
        } else if (isSignedIn && redirect.search) {
            return <div className="loading" />
        }

        if (!loaded) {
            return <div className="loading" />
        }

        if (loginType === undefined) {
            return <div className="loading" />
        }
        
        return (
            <div className="wrapperLogin">
                <div className="bgLogin"></div>

                <div className="container-fluid pageContent">
                    <div className="row" onKeyPress={this.onKeyPress.bind(this)}>
                        <div className="formBox  col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4 col-xs-10 col-xs-offset-1 shadow">
                            {/* Logo */}
                            <div className="row">
                                <div className="logo col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1 col-xs-10 col-xs-offset-1" title="Spanish Point Technologies">
                                    <img className="img-responsive" src="assets/logo-matching-engine.png" />
                                </div>
                            </div>
                            <div>
                            {(() => {
                                if (loginType === LoginType.Both || loginType === LoginType.Username) {
                                    return (
                                        <>
                                            <div className="row">
                                                <div className="col-sm-10 col-md-offset-1 col-sm-10 col-sm-offset-1">
                                                    <h2>{this.viewData[MUSIC_INDUSTRY_HEADER_FIELD]}</h2>
                                                    <h2>{this.viewData[LOGIN_HEADER_FIELD]}</h2>
                                                </div>
                                            </div>

                                            <div className="row">
                                                {/* Email */}

                                                <div className="form-group col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1">
                                                    <label>{this.viewData[EMAIL_LABEL_FIELD]}</label>
                                                    <input type="text" name="email"
                                                        value={this.state.email}
                                                        onChange={this.emailChanged.bind(this)} />
                                                </div>
                                                {/* Password */}
                                                <div className="form-group col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1">
                                                    <label>{this.viewData[PASSWORD_LABEL_FIELD]}</label>
                                                    <input type="password"
                                                        value={this.state.password}
                                                        onChange={this.passwordChanged.bind(this)}
                                                        name="password" />
                                                </div>
                                                <div className="col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1">
                                                    <p>
                                                        <a href="">{this.viewData[FORGOT_PASSWORD_LINK_TEXT_FIELD]}</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                else {
                                    return (
                                        <div className="row">
                                            <div className="col-sm-10 col-md-offset-1 col-sm-10 col-sm-offset-1">
                                                <h2>{this.viewData[MUSIC_INDUSTRY_HEADER_FIELD]}</h2>
                                            </div>
                                        </div>
                                    )
                                }
                            })()}
                            </div>

                            <div className="row">
                                {(() => {
                                    if (loginType === LoginType.Both) {
                                        return (
                                            <>
                                                <MSALLoginButton containerClass="col-lg-5 col-md-5 col-sm-10 col-sm-push-1 col-xs-push-0 form-group" />
                                                <div className="col-lg-5 col-md-5 col-sm-10 col-sm-push-1 col-xs-push-0 form-group">
                                                    <button className="button btn-defaultPrimary"
                                                        onClick={this.login.bind(this)}>{this.viewData[LOGIN_LABEL_FIELD]}
                                                    </button>
                                                </div>
                                            </>
                                        )
                                    } else if (loginType === LoginType.AAD) {
                                        return (
                                            <MSALLoginButton containerClass="col-lg-6 col-lg-push-3 col-md-6 col-md-push-5 col-sm-10 col-sm-push-1 col-xs-12 col-xs-push-0 form-group" />
                                        )
                                    } else {
                                        return (
                                            <div className="col-lg-6 col-lg-push-5 col-md-6 col-md-push-5 col-sm-10 col-sm-push-1 col-xs-12 col-xs-push-0 form-group">
                                                <button className="button btn-defaultPrimary"
                                                    onClick={this.login.bind(this)}>{this.viewData[LOGIN_LABEL_FIELD]}
                                                </button>
                                            </div>
                                        )
                                    }
                                })()}
                                
                            </div>
                            {/* Login failed message */}
                            <If condition={loginFailed}>
                                <div className="row">
                                    <div className="col-md-12 alert alert-danger alert-dismissible fade in">
                                        <label data-dismiss="alert" >{this.viewData[LOGIN_FAILED_FIELD]}</label>
                                    </div>
                                </div>
                            </If>
                        </div>
                    </div>
                    <div className="versionBox col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4 col-xs-10 col-xs-offset-1 shadow">
                        <span>Version {appVersionNumber}</span>
                    </div>
                </div>
            </div>
        );
    }
}