import * as React from "react";
import { NavItem, NavLink } from 'reactstrap';
import TableHeaderSection from "../tableHeaderSection/TableHeaderSection";
import TextDataInput from "../textDataInput/TextDataInput";
import SizedDateDataInput from "../dateDataInput/DateDataInput";
import BooleanReadonlyDisplay from '../checkboxDataInput/BooleanReadonlyDisplay';
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import {
    TEXT_INPUT,
    DROPDOWN_INPUT,
    DATE_INPUT,
    COMMENTS_VIEW,
    OPEN_ACTION,
    ADD_NEW_ACTION,
    SEARCH_ACTION,
    REMOVE_ACTION,
    SEARCH_VIEW,
    DEPENDENT_ROW_ACTION,
    DATA_GRID_TABLE_FIELDS_EXPANDER,
    SEARCH_VIEW_CONTRIBUTORS,
    IPI_NUMBER_STATE_KEY,
    READ_ONLY_INPUT,
    CONTRIBUTORS_KEY,
    BOOLEAN_READONLY_INPUT,
    TEXT_READONLY_IN_REPERTOIRE_INPUT,
    ADMINISTERED_BY_KEY,
    ADMINISTERED_BY_IP_BASE_NUMBER_STATE_KEY,
    APPLICABLE_WORKS_KEY,
    APPLICABLE_WORK_NAME_STATE_KEY,
    AGREEMENT_CARVEOUTS_KEY,
    AGREEMENT_CARVEOUTS_PRODUCT_NAME_STATE_KEY,
    AGREEMENTS_IP_KEY,
    TABLE_ACTIONS_INSTANCE_KEY,
    MATCHING_REPERTOIRE_WORKS_KEY,
    MATCHING_REPERTOIRE_WORK_NAME_STATE_KEY,
    CHECKBOX_INPUT,
    CODE_ACTION,
    WORK_SUBMISSION,
    WORK_RESPONSE,
    INTEGER_INPUT,
    PRODUCT_WORKS_KEY,
    PRODUCT_WORKS_REPERTOIRE_STATE_KEY,
    PRODUCT_CONTRIBUTORS_KEY,
    PRODUCT_CONTRIBUTOR_NAME_STATE_KEY,
    GROUPED_ACTION,
    EMPTY_STRING_VALUE,
    COMPONENTS_KEY,
    COMPONENTS_WORKS_REPERTOIRE_STATE_KEY,
    TOGGLE_BUTTON,
    EDITABLE_FIELDS,
    IS_HIDDEN_FIELD,
    WORK_CONTRIBUTOR_RELATIONSHIPS_STATE_KEY,
    CUSTOM_INPUT,
    IP_NAME_NUMBER_STATE_KEY,
    EDITABLE_FIELDS_GRID,
    SEQUENCE_FIELD,
    ORDER_GRID_FIELD_METADATA,
    MATCHING_REPERTOIRE_PRODUCT_NAME_STATE_KEY,
    MATCHING_REPERTOIRE_PRODUCTS_KEY,
    AGREEMENT_DUPLICATE_KEY,
    OPEN_PARENT,
    PARENT_WORK_KEY,
    TIMESPAN_INPUT,
    READ_ONLY_TIMESPAN_INPUT, USAGE_ROWS_SECTION_KEY, USAGE_MATCHING_WORKS_SECTION_KEY, USAGESEARCH_VIEW, MATCHINGWORKS_KEY, MATCHINGPRODUCTS_KEY,
    USAGE_MATCHING_PRODUCTS_SECTION_KEY, ARTISTS_KEY, WORK_ARTIST_NUMBER_KEY, ARTIST_TYPE_STATE_KEY, PRODUCT_PERFORMERS_KEY, PRODUCT_PERFORMER_NAME_STATE_KEY, ECONOMIC_RIGHT_DESCRIPTION, ECONOMIC_RIGHT, DROPDOWN_TREE_INPUT, DISTRIBUTION_POOLS_KEY, DISTRIBUTION_POOL_CODE_STATE_KEY, DISTRIBUTION_POOL_SELECTION_CRITERIA_STATE_KEY, SELECTION_CRITERIA_VIEW, FOREIGN_SOCIETY_INFO_VIEW, MATCHING_WORK_MORE_INFO_VIEW, IP_LICENSING_REPRESENTATION_KEY, WORK_NOT_FOUND_VIEW, PAYMENTRUN_DISTRIBUTION_CODE_STATE_KEY, USAGE_DETAILS_ROWS_SECTION_KEY, USAGE_DETAILS_ROWS_INBOUNDFEESINERROR, USAGE_DETAILS_ROWS_OUTBOUNDFEESINERROR, USAGE_DETAILS_ROWS_SECTION_STATE_KEY, USAGE_DETAILS_ROWS_ACTIONS, LINK_INPUT, USAGE_PRODUCT_DETAILS_ROWS_SECTION_KEY, TIME_INPUT, IP_NAMES_SECTION_STATE_KEY, CONTRIBUTOR_COMMENTS_VIEW, IP_NAMES_KEY, CLAIMED_WORKS, COPY_ACTION, WORK_SUBJECT_KEY, WORK_SUBJECT_STATE_KEY, SEARCH_VIEW_SUBJECTS, WORKNUMBER_CORE_OR_EXTERNAL_BOTH, WORKNUMBER_CORE_OR_EXTERNAL_CORE, USAGE_MAINTENANCE_GRIDS_VIEW, POOL_WEIGHT_SETTINGS_KEY,
    IP_REPRESENTATION_KEY,
    TERRITORY_SELECTION_VIEW,
    WORK_EDITION,
    WORK_NAME_KEY,
    SEARCH_VIEW_IPS,
    DRAFT_AGREEMENTS_PAGE_VIEW,
    SEARCH_VIEW_DRAFT_AGREEMENTS,
    MERGE_ACTION
} from "../../Consts";
import DropdownDataInput from "../dropdownDataInput/DropdownDataInput";
import { createCopyOfTableContents } from "../../services/TableFunctions";
import { IRepertoireField } from "../../types/IRepertoireField";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import DataGridTableSection from "../dataGridTableSection/DataGridTableSection";
import { hideModal, showModal, showWorkSubmission } from "../../../redux/reducers/ModalReducer";
import ShowMore from "../showMore/ShowMore";
import IconTextButton from "../iconTextButton/IconTextButton";
import { SizedActionButton } from "../actionButton/ActionButton";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { mapLookupKey, getLookupDefault, getLookupValues, mapUsageLookupKey } from "../../../lookup/services/LookupHelpers";
import { ITableAction, ITableActionName, isTableAction } from "../../types/ITableAction";
import { addTab, clearModalSearchResults, updateWorkField, enableCustomField, addUsageDetailsShareIPRow, expandUsageDetails } from "../../../redux/reducers/RepertoireReducer";
import { DataSource } from "../../types/DataSource";
import {
    AGREEMENT_NUMBER_TYPE_LOOKUP,
    APPLICABLE_WORKS_LOOKUP,
    LANGUAGE_LOOKUP,
    SET_TYPE_GROUP_LOOKUP,
    SET_TYPE_LOOKUP,
    TERRITORY_LOOKUP,
    WORK_CONTRIBUTOR_TYPE_LOOKUP,
    WORK_CONTRIBUTOR_SUB_TYPE_LOOKUP,
    WORK_NAME_LOOKUP,
    WORK_NUMBER_LOOKUP,
    WORK_PERFORMER_LOOKUP,
    WORK_SOURCE_LOOKUP,
    PRODUCT_NAME_TYPE_LOOKUP,
    PRODUCT_NUMBER_TYPE_LOOKUP,
    PRODUCT_STATUS_LOOKUP,
    AV_RIP_ROLE_LOOKUP,
    INSTRUMENTATION_LOOKUP,
    WORK_PERFORMER_NUMBER_LOOKUP,
    USAGE_TYPE_LOOKUP,
    COMMENT_CATEGORY_LOOKUP,
    SOCIETY_OF_LICENSE_LOOKUP,
    AGREEMENT_RETENTION_LOOKUP,
    AGREEMENT_RETAINED_UNTIL_LOOKUP,
    SET_TYPE_RIGHTS_LOOKUP,
    AGENCY_LOOKUP,
    IP_AGREEMENT_ROLE_LOOKUP
} from "../../../lookup/Consts";
import CheckboxDataInput from "../checkboxDataInput/CheckboxDataInput";
import { ISubmissionPromptViewModalProps } from "../modalViews/workSubmission/WorkSubmission";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { ManualMergeOptions } from "../../types/ManualMergeOptions";
import { IContributorItem } from "../../types/IContributorItem";
import { Dictionary } from "../../../core/types/Dictionary";
import { ToggleButtonDataInput } from "../toggleInput/ToggleInput";
import { If } from "../../../core/components/If";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react";
import { RepertoireHelper } from "../../services/RepertoireHelper";
import { ComponentsHelper } from "../../../core/services/ComponentHelper";
import { IAgreementShareDisplayConfiguration } from "../../types/IAgreementShareDisplayConfiguration";
import FormattedTimeDataInput from "../timeFieldDataInput/FormattedTimeDataInput";
import { DataGridTableRow } from "../usageComponents/dataGridTableRow/DataGridTableRow";
import { UsageRowView } from "../../usage/components/usageRowView/UsageRowView";
import { ITreeData } from "../../types/ITreeData";
import { Space } from "antd";
import DropdownTreeDataInput from "../dropdownTreeDataInput/DropdownTreeDataInput";
import { FieldOptions } from "../fieldOptions/FieldOptions";
import { IDistribution } from "../../types/usageTypes/IDistribution";
import { IIpLicensingRepresentation } from "../../types/IIpLicensingRepresentation";
import { IDistributionPoolState } from "../../../redux/types/IDistributionPoolState";
import { IPaymentRunDistributionState } from "../../../redux/types/IPaymentRunDistributionState";
import { MEMBERS_PORTAL } from "../../../accounts/Consts";
import { ADD_COVER_VERSION_ACTION, DRAFT_SET_LISTS_WORK_STATE_KEY, ADD_NEW_DATE_ACTION, SET_LISTS_WORK_SEARCHVIEW_STATE_KEY, DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY, DRAFT_AGREEMENT_ASSIGNEE_VIEW, DRAFT_AGREEMENTS_COMMENTS_STATE_KEY } from "../../../membersportal/Consts";
import SimpleTimeDataInput from "../timeFieldDataInput/SimpleTimeDataInput";
import { IUsagePoolDatesAndDimensions } from "../../types/usageTypes/IUsagePoolDatesAndDimensions";
import { IUsagePool } from "../../types/usageTypes/IUsagePool";
import { IUsagePoolState } from "../../../redux/types/IUsagePoolState";
import ListenToShortCutKeysDataGridTable, { DO_NOT_SHOW_MODAL } from "./ListenToShortCutKeysDataGridTable";
import IPRoleSelector from "./IPRoleSelector";
import DateDisplay from "../dateDisplay/DateDisplay";
import { date } from "yup";
import { IWorksSearchQuery } from "../../types/IWorksSearchQuery";
import { IAgreementStateKeys } from "../../../redux/types/IAgreementState";
import { SELECT_IP } from "../../../membersportal/draftAgreements/components/draftAgreementsAssignee/DraftAgreementsMaintenanceAssigneeView";

export interface IDataGridTableProps {
    tableContents: any;
    changeData?: (value: any, name: IRepertoireStateKeys, objectKey?: string, ipRepresentationsIdsToRemove?: number[]) => void;
    sortData?: (value: any, name: IRepertoireStateKeys) => void;
    dependentRowAction?: (value: any) => void;
    componentInstance: string;
    dataGridTableData: IRepertoireComponentDataItem;
    tableActions?: ITableAction[];
    stateKey: IRepertoireStateKeys;
    showModal?: (
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    )=>void;
    clearModalSearchResults?: typeof clearModalSearchResults | (() => void);
    lookupValues?: ILookupDictionary;
    addTab?: typeof addTab;
    showDropdownsAsCodes?: boolean
    showRowNumber: boolean;
    setTypeGroup?: string;
    activeSetType?: string;
    dataSource?: DataSource;
    isReadOnly?: boolean;
    openInNewTab?: (openItem: any) => void;
    showWorkSubmissionDetail?: typeof showWorkSubmission;
    hideModal?: typeof hideModal;
    tabs?: ITabReduxItem[];
    activeTab?: number;
    currentUser?: string;
    groupActionClick?: (action: any, tableContentItem: any) => void;
    contributorConfiguration?: string;
    updateContributorRelationships?: (value: string, contributors: IContributorItem[], rowIndex: number) => void;
    updateWorkField?: typeof updateWorkField;
    customFields?: number[];
    enableCustomField?: typeof enableCustomField;
    showFieldsModal?: () => void;
    agreementShareDisplayOptions?: IAgreementShareDisplayConfiguration
    shareDecimalsToDisplay?: number;
    isExpandable?: boolean;
    usageType?: string;
    lookupSources?: ITreeData[];
    addNewAccount?: () => void;
    poolCodes?: string[];
    distributionCodes?: string[];
    distributions?: IDistribution[];
    clearAllRowsButThis?: (value: any) => void;
    expandedWorkResults?: number[];
    expandedProductResults?: number[];
    expandMatchingWork?: (index: number) => void;
    expandMatchingProduct?: (index: number) => void;
    expandUsageDetailRow?: (index: number) => void;
    allowMultiSelectChbk?: boolean;
    isBulkCheckboxActive?: boolean;
    changeBulkCheckbox?: (value: boolean) => void;
    incrementTempID?: () => void;
    entity?: any;
    onClickAddUsageDetails?: (index: number) => void;
    addUsageDetailsShareIPRow?: typeof addUsageDetailsShareIPRow;
    portalType?: string;
    resetMessageBanner?: () => void;
    openWorkFlow?: boolean;
    datesAndDimensions?: IUsagePoolDatesAndDimensions[];
    usagePoolsSearchResults?: IUsagePool[];
    poolCode?: string;
    sources?: ITreeData[];
    getUsagePool?: (poolId: number, matchingSources: ITreeData[]) => void;
    pool?: IUsagePoolState;
    isExpandedUsageMatchingSection?: boolean;
    expandedUsageDetailsRow?: number[];
    creationClassOptions?: any[];
    customer?: string;
    searchWorks?: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    updateDraftAgreementWorkField?: (value: any, fieldName: IAgreementStateKeys, index?: number) => void;
    mergeActionClick?: (tableContentItem: any) => void;

}

interface IDataGridTableState {
    activeSortHeaderSection: string;
    focusedRow: number;
    searchModalName: string;
    isRemoveAction: boolean;
}

export default class DataGridTable extends React.PureComponent<
    IDataGridTableProps,
    IDataGridTableState
> {
    componentRef;
    constructor(props: IDataGridTableProps) {
        super(props);

        this.componentRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            activeSortHeaderSection: undefined,
            focusedRow: null,
            searchModalName: DO_NOT_SHOW_MODAL,
            isRemoveAction: false,
        };
        const { usagePoolsSearchResults, poolCode, sources, getUsagePool } = this.props;
        if (usagePoolsSearchResults) {
            const findX = usagePoolsSearchResults.find(x => x.poolCode === poolCode)
            if (findX)
                getUsagePool(findX.poolId, sources);
        }
    }

    setTheSearchModalTypeShortCutKeyListening = (stateKey) => {
        const { dataSource } = this.props;
        switch (stateKey) {
            case CONTRIBUTORS_KEY:
                this.setState({ searchModalName: CONTRIBUTORS_KEY });
                return;
            case PRODUCT_CONTRIBUTORS_KEY:
                this.setState({ searchModalName: PRODUCT_CONTRIBUTORS_KEY });
                return;
            case APPLICABLE_WORKS_KEY:
                this.setState({ searchModalName: APPLICABLE_WORKS_KEY })
            case MATCHING_REPERTOIRE_WORKS_KEY:
                this.setState({ searchModalName: MATCHING_REPERTOIRE_WORKS_KEY })
                return;
            case MATCHING_REPERTOIRE_PRODUCTS_KEY:
                this.setState({ searchModalName: MATCHING_REPERTOIRE_PRODUCTS_KEY })
                return;
            case ARTISTS_KEY:
                if (dataSource === DataSource.Intray) {
                    this.setState({ searchModalName: ARTISTS_KEY })
                    return;
                }
                this.setState({ searchModalName: DO_NOT_SHOW_MODAL })
                return;
            case PRODUCT_PERFORMERS_KEY:
                if (dataSource === DataSource.Intray) {
                    this.setState({ searchModalName: PRODUCT_PERFORMERS_KEY })
                    return;
                }
                this.setState({ searchModalName: DO_NOT_SHOW_MODAL })
                return;
            case AGREEMENTS_IP_KEY:
            case ADMINISTERED_BY_KEY:
                this.setState({ searchModalName: AGREEMENTS_IP_KEY })
                return;
            default:
                this.setState({ searchModalName: DO_NOT_SHOW_MODAL })
        }
    }


    handleClickOutside(event: any) {
        if (this.componentRef.current && !this.componentRef.current.contains(event.target)) {
            this.setState({ searchModalName: DO_NOT_SHOW_MODAL })
            this.setState({ focusedRow: null })
            return;
        }
    }

    componentDidMount() {
        const { componentInstance, dataGridTableData } = this.props;
        this.setTheSearchModalTypeShortCutKeyListening(componentInstance)
        const filteredFields = dataGridTableData && dataGridTableData?.fields.filter(field => field.componentInstance === componentInstance);
        if (filteredFields?.filter(field => field.fieldType === REMOVE_ACTION).length > 0) {
            this.setState({ isRemoveAction: true });
        }
    }

    onRowClick = (rowNumber) => {

        const { componentInstance, expandUsageDetailRow } = this.props;
        this.setState({ focusedRow: rowNumber });
        this.setTheSearchModalTypeShortCutKeyListening(componentInstance);
        if (expandUsageDetailRow) {
            expandUsageDetailRow(rowNumber);
        }
    }

    addRowShortCutKeys = () => {
        const { focusedRow } = this.state;

        const { tableActions } = this.props;
        if ((focusedRow === 0 || focusedRow) && tableActions && tableActions.filter(action => action.name === ADD_NEW_ACTION)?.length > 0) {
            this.onClickAdd();
        }
    }

    deleteRowShortCutKeys = () => {
        const { focusedRow, isRemoveAction } = this.state;
        const { tableContents } = this.props;
        if ((focusedRow === 0 || focusedRow) && isRemoveAction) {
            this.onClickRemove(tableContents[focusedRow]);
        }
    }

    showSearchModalShortCutKey = () => {
        const { focusedRow, searchModalName } = this.state;
        const { tableContents } = this.props;

        if (searchModalName !== DO_NOT_SHOW_MODAL) {
            this.showSearchModal(tableContents[focusedRow], searchModalName)
        }

    }

    public ascending: boolean = false;
    public ipRepresentationsIdsToRemove: number[] = [];
    public updatedIpLicensingRepresentations: IIpLicensingRepresentation[] = [];

    sortTableByColumn = (section: string) => {
        const { activeSortHeaderSection } = this.state;
        const { tableContents, sortData, stateKey, componentInstance, tabs, activeTab, activeSetType } = this.props;
        let fullTableContentsCopy: Dictionary<IContributorItem[]>;
        let tableContentsCopy: any;

        if (stateKey === SEARCH_VIEW_CONTRIBUTORS) {
            fullTableContentsCopy = tabs[activeTab].workMaintenanceState.work.contributors;
            tableContentsCopy = fullTableContentsCopy[activeSetType];
        }
        else {
            tableContentsCopy = JSON.parse(JSON.stringify(tableContents));
        }

        if (activeSortHeaderSection !== section) {
            this.ascending = false;
        }
        if (tableContentsCopy) {
            tableContentsCopy.sort((a: any, b: any) => {
                // set to emtpy string if null
                const newA = a[section] ? (a[section].value ? a[section].value : a[section]) || "" : "";
                const newB = b[section] ? (b[section].value ? b[section].value : b[section]) || "" : "";

                if (!this.ascending) {
                    return newA.toString().localeCompare(newB.toString(), undefined, { 'numeric': true });
                }
                return newB.toString().localeCompare(newA.toString(), undefined, { 'numeric': true });
            });
        }

        this.setState({ activeSortHeaderSection: section });
        if (componentInstance === SEARCH_VIEW_CONTRIBUTORS) {
            fullTableContentsCopy[activeSetType] = tableContentsCopy;
            sortData(fullTableContentsCopy, stateKey);
        } else {
            sortData(tableContentsCopy, stateKey);
        }
        this.ascending = !this.ascending;
    };

    changeTableData = (
        value: any,
        stateKey: IRepertoireStateKeys,
        objectKey: string,
        rowIndex: number,
        componentInstance?: string,
        newOrderValue?: string
    ) => {
        const { changeData, tableContents, updateContributorRelationships, datesAndDimensions } = this.props;
        const tableContentsCopy = createCopyOfTableContents(tableContents, (componentInstance === undefined || componentInstance.length > 0) ? true : false);
        if (stateKey === MATCHING_REPERTOIRE_WORKS_KEY && objectKey == "isDefinite") {
            if (tableContentsCopy.length > 1) {
                tableContentsCopy[rowIndex][objectKey].value = value;
                for (let i = 0; i < tableContentsCopy.length; i++) {
                    if (i != rowIndex) {
                        tableContentsCopy[i][objectKey].value = false
                    }
                }
            }
            else {
                tableContentsCopy[rowIndex][objectKey].value = value;
            }
        }
        if (stateKey === CONTRIBUTORS_KEY && objectKey === WORK_CONTRIBUTOR_RELATIONSHIPS_STATE_KEY) {
            updateContributorRelationships(value, tableContentsCopy, rowIndex);
            return;
        }
        else if (objectKey === "workName") {
            tableContentsCopy[rowIndex][objectKey].value = value;
            tableContentsCopy[rowIndex]["workNumber"].value = tableContentsCopy[rowIndex].productWorks[0].number
            tableContentsCopy[rowIndex]["workId"] = tableContentsCopy[rowIndex].productWorks[0].workCode
        }
        else if (stateKey === DRAFT_AGREEMENT_ASSIGNEE_VIEW && (objectKey == "performingShare" || objectKey == "mechanicalShare")) {
            tableContentsCopy[rowIndex][objectKey].value = isNaN(Number(value)) || Number(value) < 0
                ? tableContentsCopy[rowIndex][objectKey].value
                : (Math.round((Number(value) + Number.EPSILON) * 100000) / 100000);
        }
        else if (stateKey === CONTRIBUTORS_KEY && (objectKey == "perfPercentage" || objectKey == "mechPercentage" || objectKey == "copyLevyPercentage" || objectKey == "copyLicencePercentage")) {
            tableContentsCopy[rowIndex][objectKey].value = isNaN(Number(value)) || Number(value) < 0
                ? tableContentsCopy[rowIndex][objectKey].value
                : (Math.round((Number(value) + Number.EPSILON) * 100000) / 100000);
        }
        else if (stateKey === CONTRIBUTORS_KEY && objectKey == "relationships") {
            tableContentsCopy[rowIndex][objectKey].value = value
        }
        else if (stateKey === CONTRIBUTORS_KEY && objectKey == "societyOfLicense") {
            tableContentsCopy[rowIndex][objectKey].value = value
        }
        else if (componentInstance === EDITABLE_FIELDS) {
            stateKey = componentInstance;
            if (objectKey === IS_HIDDEN_FIELD) {
                tableContentsCopy[rowIndex].hidden = !tableContentsCopy[rowIndex].hidden;
            }
            else if (objectKey === "defaultValue") {
                tableContentsCopy[rowIndex].defaultValue = value;
            }
            else if (objectKey === 'isMandatory') {
                tableContentsCopy[rowIndex].isMandatory = value;
            }
            else {
                tableContentsCopy[rowIndex].data = value;
            }
            tableContentsCopy[rowIndex].modifiedByUser = true;
        }
        else if (componentInstance === EDITABLE_FIELDS_GRID) {
            stateKey = componentInstance;
            if (objectKey === IS_HIDDEN_FIELD) {
                tableContentsCopy[rowIndex].hidden = !tableContentsCopy[rowIndex].hidden;
                tableContentsCopy[rowIndex].modifiedByUser = true;
            }
            else if (objectKey === SEQUENCE_FIELD) {
                const orders = tableContents.map(x => x[ORDER_GRID_FIELD_METADATA]);
                const newOrder = parseInt(newOrderValue);
                if (newOrder > rowIndex) {

                    tableContentsCopy[rowIndex][ORDER_GRID_FIELD_METADATA] = orders[newOrder];

                    for (let i = rowIndex; i < newOrder; i++) {
                        tableContentsCopy[i + 1][ORDER_GRID_FIELD_METADATA] = orders[i];
                    }
                }
                else if (newOrder < rowIndex) {

                    tableContentsCopy[rowIndex][ORDER_GRID_FIELD_METADATA] = orders[newOrder];

                    for (let i = newOrder; i < rowIndex; i++) {
                        tableContentsCopy[i][ORDER_GRID_FIELD_METADATA] = orders[i + 1];
                    }
                }
                tableContentsCopy[rowIndex].modifiedByUser = true;
            }
            else {
                tableContentsCopy[rowIndex].data = value;
            }
        }
        else if (componentInstance === COMPONENTS_KEY) {
            stateKey = componentInstance;
            tableContentsCopy[rowIndex][objectKey].value = value;
        }
        else if (componentInstance === PRODUCT_WORKS_KEY) {
            stateKey = componentInstance;
            tableContentsCopy[rowIndex][objectKey].value = value;
        }
        else if (stateKey === USAGE_ROWS_SECTION_KEY) {
            tableContentsCopy[rowIndex][objectKey] = value;
        }
        else if (stateKey === USAGE_DETAILS_ROWS_SECTION_STATE_KEY || stateKey === IP_NAMES_SECTION_STATE_KEY) {
            tableContentsCopy[rowIndex][objectKey] = value;
        }
        else if (componentInstance === "comments") {
            tableContentsCopy[rowIndex][objectKey].value = value;
            tableContentsCopy[rowIndex]['isModified'] = true;
        }
        else {
            tableContentsCopy[rowIndex][objectKey].value = value;
        }

        if (objectKey === 'effectiveDateRange') {
            tableContentsCopy[rowIndex][objectKey].value = value;
            const result = datesAndDimensions.find((word) => word.effectiveDateRange === value);
            tableContentsCopy[rowIndex]['dimensionsAndDatesID'] = result.poolDimensionsAndDatesID;
            tableContentsCopy[rowIndex]['use'].inputType = result.use ? TEXT_INPUT : READ_ONLY_INPUT;
            tableContentsCopy[rowIndex]['usageDimensionOne'].inputType = result.dimension1 ? TEXT_INPUT : READ_ONLY_INPUT;
            tableContentsCopy[rowIndex]['usageDimensionTwo'].inputType = result.dimension2 ? TEXT_INPUT : READ_ONLY_INPUT;
            tableContentsCopy[rowIndex]['usageDimensionThree'].inputType = result.dimension3 ? TEXT_INPUT : READ_ONLY_INPUT;
            tableContentsCopy[rowIndex]['usageDimensionFour'].inputType = result.dimension4 ? TEXT_INPUT : READ_ONLY_INPUT;
            tableContentsCopy[rowIndex]['usageDimensionFive'].inputType = result.dimension5 ? TEXT_INPUT : READ_ONLY_INPUT;
            tableContentsCopy[rowIndex]['fromTime'].inputType = result.time ? TIME_INPUT : READ_ONLY_INPUT;
            tableContentsCopy[rowIndex]['toTime'].inputType = result.time ? TIME_INPUT : READ_ONLY_INPUT;
            tableContentsCopy[rowIndex]['musicSource'].inputType = result.musicSource ? TEXT_INPUT : READ_ONLY_INPUT;

            tableContentsCopy[rowIndex]['use'].value = result.use ? tableContentsCopy[rowIndex]['use'].value : '';
            tableContentsCopy[rowIndex]['usageDimensionOne'].value = result.dimension1 ? tableContentsCopy[rowIndex]['usageDimensionOne'].value : '';
            tableContentsCopy[rowIndex]['usageDimensionTwo'].value = result.dimension2 ? tableContentsCopy[rowIndex]['usageDimensionTwo'].value : '';
            tableContentsCopy[rowIndex]['usageDimensionThree'].value = result.dimension3 ? tableContentsCopy[rowIndex]['usageDimensionThree'].value : '';
            tableContentsCopy[rowIndex]['usageDimensionFour'].value = result.dimension4 ? tableContentsCopy[rowIndex]['usageDimensionFour'].value : '';
            tableContentsCopy[rowIndex]['usageDimensionFive'].value = result.dimension5 ? tableContentsCopy[rowIndex]['usageDimensionFive'].value : '';
            tableContentsCopy[rowIndex]['fromTime'].value = result.time ? tableContentsCopy[rowIndex]['fromTime'].value : '';
            tableContentsCopy[rowIndex]['toTime'].value = result.time ? tableContentsCopy[rowIndex]['toTime'].value : '';
            tableContentsCopy[rowIndex]['musicSource'].value = result.musicSource ? tableContentsCopy[rowIndex]['musicSource'].value : '';
        }

        
        if (componentInstance === "poolWeightSettings") {
            if (objectKey === 'effectiveDateRange') {
                tableContentsCopy[rowIndex][objectKey].value = value;
                const result = datesAndDimensions.find((word) => word.effectiveDateRange === value);
                tableContentsCopy[rowIndex]['dimensionsAndDatesID'] = result.poolDimensionsAndDatesID;
                tableContentsCopy[rowIndex]['use'].inputType = result.use ? TEXT_INPUT : READ_ONLY_INPUT;
                tableContentsCopy[rowIndex]['usageDimensionOne'].inputType = result.dimension1 ? TEXT_INPUT : READ_ONLY_INPUT;
                tableContentsCopy[rowIndex]['usageDimensionTwo'].inputType = result.dimension2 ? TEXT_INPUT : READ_ONLY_INPUT;
                tableContentsCopy[rowIndex]['usageDimensionThree'].inputType = result.dimension3 ? TEXT_INPUT : READ_ONLY_INPUT;
                tableContentsCopy[rowIndex]['usageDimensionFour'].inputType = result.dimension4 ? TEXT_INPUT : READ_ONLY_INPUT;
                tableContentsCopy[rowIndex]['usageDimensionFive'].inputType = result.dimension5 ? TEXT_INPUT : READ_ONLY_INPUT;
                tableContentsCopy[rowIndex]['fromTime'].inputType = result.time ? TIME_INPUT : READ_ONLY_INPUT;
                tableContentsCopy[rowIndex]['toTime'].inputType = result.time ? TIME_INPUT : READ_ONLY_INPUT;
                tableContentsCopy[rowIndex]['musicSource'].inputType = result.musicSource ? TEXT_INPUT : READ_ONLY_INPUT;

                tableContentsCopy[rowIndex]['use'].value = result.use ? tableContentsCopy[rowIndex]['use'].value : '';
                tableContentsCopy[rowIndex]['usageDimensionOne'].value = result.dimension1 ? tableContentsCopy[rowIndex]['usageDimensionOne'].value : '';
                tableContentsCopy[rowIndex]['usageDimensionTwo'].value = result.dimension2 ? tableContentsCopy[rowIndex]['usageDimensionTwo'].value : '';
                tableContentsCopy[rowIndex]['usageDimensionThree'].value = result.dimension3 ? tableContentsCopy[rowIndex]['usageDimensionThree'].value : '';
                tableContentsCopy[rowIndex]['usageDimensionFour'].value = result.dimension4 ? tableContentsCopy[rowIndex]['usageDimensionFour'].value : '';
                tableContentsCopy[rowIndex]['usageDimensionFive'].value = result.dimension5 ? tableContentsCopy[rowIndex]['usageDimensionFive'].value : '';
                tableContentsCopy[rowIndex]['fromTime'].value = result.time ? tableContentsCopy[rowIndex]['fromTime'].value : '';
                tableContentsCopy[rowIndex]['toTime'].value = result.time ? tableContentsCopy[rowIndex]['toTime'].value : '';
                tableContentsCopy[rowIndex]['musicSource'].value = result.musicSource ? tableContentsCopy[rowIndex]['musicSource'].value : '';
            } else {
                tableContentsCopy[rowIndex][objectKey].value = value;
            }

        }

        changeData(tableContentsCopy, stateKey, objectKey);
    };
    changeTableDataForMultiSelect = (value: any, fieldName: string) => {
        const { tableContents, changeBulkCheckbox } = this.props;
        changeBulkCheckbox(value);
        for (let i = 0; i < tableContents.length; i++) {
            tableContents[i][fieldName].value = value;
        }
    };

    changeDataForLivePerformance = (data: string) => {
        if (data === "Program Start Date" || data === "Program End Date")
            return data.replace("Date", "Time");
        return data
    }

    onClickOpen = (componentInstance: string, tableContentItem: any, fieldName: string) => {
        const { showModal, isReadOnly, entity } = this.props;
        switch (componentInstance) {
            case "comments":
                showModal(COMMENTS_VIEW, null, tableContentItem, true, "Comment Details");
                break;
            case "distributionPools":
                if (fieldName === "Selection Criteria")
                    showModal(SELECTION_CRITERIA_VIEW, null, tableContentItem, true, "Selection Criteria", undefined, isReadOnly);
                else if (fieldName === "Currency and Deductions")
                    showModal(FOREIGN_SOCIETY_INFO_VIEW, null, tableContentItem, true, "Currency and Deductions", undefined, (tableContentItem.status.value === "Allocated"));
                break;
            case "matchingRepertoireWorks":
                if (tableContentItem['repertoireWorkID'] <= 0) {
                    showModal(WORK_NOT_FOUND_VIEW, null, null, true, "Work Not Selected");
                } else {
                    showModal(MATCHING_WORK_MORE_INFO_VIEW, null, tableContentItem['repertoireWorkID'], true, "Work Information");
                }
                break;
            case "contributors":
                if (fieldName === "Comment") {
                    showModal(CONTRIBUTOR_COMMENTS_VIEW, null, tableContentItem, true, "Comment Details");
                }
                break;
            default:
                break;
        }
    };

    onClickRemove = (tableContentItem: any) => {
        const { changeData, stateKey, tableContents, tabs, activeTab } = this.props;
        const tableContentsCopy = createCopyOfTableContents(tableContents);
        if (activeTab) {
            this.ipRepresentationsIdsToRemove = tabs[activeTab].interestedPartyMaintenanceState?.representationIds ?? []
        }
        else {
            this.ipRepresentationsIdsToRemove = []
        }
        if (tableContentsCopy.indexOf(tableContentItem) > -1) {
            tableContentsCopy.splice(tableContentsCopy.indexOf(tableContentItem), 1);
        }
        changeData(tableContentsCopy, stateKey, null, this.ipRepresentationsIdsToRemove);
    };

    updateIpRepresentationsIdsToRemove = (tableContentItem: any): number[] => {
        const { tabs, activeTab } = this.props;

        if (activeTab) {
            this.ipRepresentationsIdsToRemove = tabs[activeTab].interestedPartyMaintenanceState?.representationIds ?? [];
        }
        else {
            this.ipRepresentationsIdsToRemove = [];
        }

        let ipLicensingRepresentation: any = tableContentItem ? tableContentItem as IIpLicensingRepresentation : undefined;

        if (ipLicensingRepresentation !== undefined || ipLicensingRepresentation.id !== undefined) {
            this.ipRepresentationsIdsToRemove.push(ipLicensingRepresentation.id);
        }

        return this.ipRepresentationsIdsToRemove;
    }

    handleCopy = () => {
        const { tableContents, componentInstance, changeData, incrementTempID, stateKey, datesAndDimensions } = this.props;
        const allRowsToBeCopied = tableContents.filter(row => {
            return row && row?.copySection?.value
        })

        let tableContentsCopy: any = tableContents ? createCopyOfTableContents(tableContents) : [];
        let newItems = [];
        allRowsToBeCopied.forEach(row => {
            switch (componentInstance) {
                case "poolWeightSettings": {
                    const result = row?.effectiveDateRange?.value && datesAndDimensions.filter((word) => word.effectiveDateRange === row.effectiveDateRange.value);
                    newItems.push({
                        weightingCode: { value: row.weightingCode.value, inputType: TEXT_INPUT },
                        musicSource: { value: row.musicSource.value, inputType: result && result[0] && result[0]?.musicSource ? TEXT_INPUT : READ_ONLY_INPUT },
                        usageDimensionOne: { value: row.usageDimensionOne.value, inputType: result && result[0] && result[0]?.dimension1 ? TEXT_INPUT : READ_ONLY_INPUT },
                        usageDimensionTwo: { value: row.usageDimensionTwo.value, inputType: result && result[0] && result[0]?.dimension2 ? TEXT_INPUT : READ_ONLY_INPUT },
                        usageDimensionThree: { value: row.usageDimensionThree.value, inputType: result && result[0] && result[0]?.dimension3 ? TEXT_INPUT : READ_ONLY_INPUT },
                        usageDimensionFour: { value: row.usageDimensionFour.value, inputType: result && result[0] && result[0]?.dimension4 ? TEXT_INPUT : READ_ONLY_INPUT },
                        usageDimensionFive: { value: row.usageDimensionFive.value, inputType: result && result[0] && result[0]?.dimension5 ? TEXT_INPUT : READ_ONLY_INPUT },
                        use: { value: row.use.value, inputType: TEXT_INPUT },
                        effectiveDateRange: { value: row.effectiveDateRange.value, inputType: DROPDOWN_INPUT },
                        fromTime: { value: row.fromTime.value, inputType: TIME_INPUT },
                        toTime: { value: row.toTime.value, inputType: TIME_INPUT },
                        weightMultiplier: { value: row.weightMultiplier.value, inputType: TEXT_INPUT },
                        copySection: { value: false, inputType: CHECKBOX_INPUT },
                        dimensionsAndDatesID: row.dimensionsAndDatesID,
                        poolID: row.poolID,
                    })
                    break;
                }
            }

        })

        if (newItems.length > 0) {
            newItems.forEach(item => {
                tableContentsCopy.push(item);
            })
        }
        changeData(tableContentsCopy, stateKey);
        incrementTempID()

    }

    onClickAdd = () => {
        const { changeData, stateKey, tableContents, customer, setTypeGroup, activeSetType, componentInstance, datesAndDimensions, lookupValues, currentUser, incrementTempID, tabs, activeTab, entity, portalType } = this.props;
        let nextTempID: number = tabs[activeTab].nextTempID;
        if (nextTempID === undefined) {
            nextTempID = 0;
            tabs[activeTab].nextTempID = nextTempID;
        }
        let tableContentsCopy: any = tableContents ? createCopyOfTableContents(tableContents) : [];
        let newItem: any;
        switch (componentInstance) {
            case "titles":
                {
                    newItem =
                    {
                        type:
                        {
                            value: getLookupDefault(WORK_NAME_LOOKUP, lookupValues),
                            inputType: DROPDOWN_INPUT
                        },
                        language:
                        {
                            value: getLookupDefault(LANGUAGE_LOOKUP, lookupValues),
                            inputType: DROPDOWN_INPUT
                        },
                        title:
                        {
                            value: "",
                            inputType: TEXT_INPUT
                        },
                        workNameID: nextTempID
                    };
                    break;
                };
            case "subjects":
                {
                    newItem =
                    {
                        startDate:
                        {
                            value: "",
                            inputType: DATE_INPUT,
                        },
                        endDate:
                        {
                            value: "",
                            inputType: DATE_INPUT,
                        },
                        subject:
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT,
                        },
                        percentage:
                        {
                            value: 0,
                            inputType: INTEGER_INPUT,
                        },
                        SubjectID: nextTempID
                    };
                    break;
                };
            case "workPrice":
                {
                    newItem = {
                        startDate:
                        {
                            value: null,
                            inputType: DATE_INPUT
                        },
                        endDate:
                        {
                            value: null,
                            inputType: DATE_INPUT
                        },
                        price:
                        {
                            value: 0,
                            inputType: INTEGER_INPUT,
                        },
                        publisherPrice: {
                            value: 0,
                            inputType: INTEGER_INPUT,
                        },
                        workPriceID: nextTempID
                    }
                    break;
                }
            case "numbers":
                {
                    newItem =
                    {
                        type:
                        {
                            value: getLookupDefault(WORK_NUMBER_LOOKUP, lookupValues),
                            inputType: DROPDOWN_INPUT
                        },
                        number:
                        {
                            value: "",
                            inputType: TEXT_INPUT
                        },
                        workNumberID: nextTempID
                    };
                    break;
                };
            case "additionalNumbers":
                {
                    newItem =
                    {
                        type:
                        {
                            value: getLookupDefault(WORK_NUMBER_LOOKUP, lookupValues),
                            inputType: DROPDOWN_INPUT
                        },
                        numberAdditional:
                        {
                            value: "",
                            inputType: TEXT_INPUT
                        },
                        workNumberAdditionalID: nextTempID,
                        isDeleted: false
                    };
                    break;
                };
            case "comments": {
                newItem = {
                    user: {
                        value: currentUser
                    },
                    date: {
                        value: ""
                    },
                    subject: {
                        value: "",
                        inputType: TEXT_INPUT
                    },
                    comment: {
                        value: "",
                        inputType: TEXT_INPUT
                    },
                    commentCategory: {
                        value: getLookupDefault(COMMENT_CATEGORY_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    isModified: true,
                    notesID: nextTempID,
                };

                break;
            };
            case "contributors": {
                newItem = {
                    workContributorID: nextTempID,
                    accountNumber: { value: "", inputType: READ_ONLY_INPUT },
                    ipiNumber:
                    {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    mechPercentage:
                    {
                        value: 0,
                        inputType: INTEGER_INPUT
                    },
                    name:
                    {
                        value: "",
                        inputType: portalType === MEMBERS_PORTAL ? TEXT_INPUT : READ_ONLY_INPUT
                    },
                    percentageOfPub:
                    {
                        value: "",
                        inputType: TEXT_INPUT
                    },
                    perfPercentage:
                    {
                        value: 0,
                        inputType: INTEGER_INPUT
                    },
                    regDate:
                    {
                        value: null,
                        inputType: DATE_INPUT
                    },
                    societyOfLicense: {
                        value: getLookupDefault(SOCIETY_OF_LICENSE_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    relation:
                    {
                        value: "",
                        inputType: TEXT_INPUT
                    },
                    reference:
                    {
                        value: "",
                        inputType: TEXT_INPUT
                    },
                    setType: activeSetType ? activeSetType : getLookupDefault(SET_TYPE_LOOKUP, lookupValues),
                    setTypeGroup: setTypeGroup ? setTypeGroup : getLookupDefault(SET_TYPE_GROUP_LOOKUP, lookupValues),
                    source:
                    {
                        value: getLookupDefault(WORK_SOURCE_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    territory:
                    {
                        value: getLookupDefault(TERRITORY_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    role:
                    {
                        value: getLookupDefault(WORK_CONTRIBUTOR_TYPE_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    subType:
                    {
                        value: getLookupDefault(WORK_CONTRIBUTOR_SUB_TYPE_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    senderRep:
                    {
                        value: null,
                        inputType: DROPDOWN_INPUT
                    },
                    agreementChain: [],
                    sourceIP: null,
                    sourceType: "",
                    sourceSociety: "",
                    mechSocietyAffiliation: EMPTY_STRING_VALUE,
                    perfSocietyAffiliation: EMPTY_STRING_VALUE,
                    performingShareToDisplay: {
                        value: '0',
                        inputType: INTEGER_INPUT
                    },
                    mechanicalShareToDisplay: {
                        value: '0',
                        inputType: INTEGER_INPUT
                    },
                    copyLevyPercentage: {
                        value: '0',
                        inputType: INTEGER_INPUT
                    },
                    copyLicencePercentage: {
                        value: '0',
                        inputType: INTEGER_INPUT
                    }
                };
                break;
            };
            case "artists": {
                newItem = {
                    workPerformerID: nextTempID,
                    type:
                    {
                        value: getLookupDefault(WORK_PERFORMER_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    firstName:
                    {
                        value: "",
                        inputType: TEXT_READONLY_IN_REPERTOIRE_INPUT
                    },
                    lastName:
                    {
                        value: "",
                        inputType: TEXT_READONLY_IN_REPERTOIRE_INPUT
                    },
                    fullName:
                    {
                        value: "",
                        inputType: TEXT_READONLY_IN_REPERTOIRE_INPUT,
                    },
                    number:
                    {
                        value: "",
                        inputType: TEXT_READONLY_IN_REPERTOIRE_INPUT,
                    }
                };
                break;
            };
            case "workEdition": {
                newItem = {
                    workEditionID: nextTempID,
                    yearOfEdition:
                    {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    completationEditionDate:
                    {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    editionNumber:
                    {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    dateOfPrinting:
                    {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    numberOfPrinting:
                    {
                        value: "0.00",
                        inputType: INTEGER_INPUT,
                    },
                    numberOfCopiesInCirculation:
                    {
                        value: "0.00",
                        inputType: INTEGER_INPUT,
                    },
                    editionOrigin:
                    {
                        value: "",
                        inputType: READ_ONLY_INPUT,
                    }

                };
                break;
            };
            case "administeredBy": {
                newItem = {
                    agreementAdminIPID: nextTempID,
                    administeredByIPBaseNumber: {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    accountNumber: {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    administeredName: {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    administeredIP: {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    performingShare: {
                        value: 100,
                        inputType: INTEGER_INPUT
                    },
                    mechanicalShare: {
                        value: 100,
                        inputType: INTEGER_INPUT
                    },
                    performingShareToDisplay: {
                        value: '100',
                        inputType: INTEGER_INPUT
                    },
                    mechanicalShareToDisplay: {
                        value: '100',
                        inputType: INTEGER_INPUT
                    }
                };
                break;
            };
            case "applicableWorks": {
                newItem = {
                    agreementWorkID: nextTempID,
                    workName: {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    workNumber: {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    type: {
                        value: getLookupDefault(APPLICABLE_WORKS_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    exclusionUntil: {
                        value: getLookupDefault(AGREEMENT_RETAINED_UNTIL_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    excludedUntilDate: {
                        value: null,
                        inputType: DATE_INPUT
                    },
                    worksRetainedUntil: {
                        value: getLookupDefault(AGREEMENT_RETAINED_UNTIL_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    workRetentionEndDate: {
                        value: null,
                        inputType: DATE_INPUT
                    },
                    useDefaultCollection: {
                        value: getLookupDefault(AGREEMENT_RETENTION_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    workCollectionUntil: {
                        value: getLookupDefault(AGREEMENT_RETAINED_UNTIL_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    workCollectionEndDate: {
                        value: null,
                        inputType: DATE_INPUT
                    }
                };
                break;
            };
            case "agreementCarveOuts": {
                newItem = {
                    agreementProductWorkID: nextTempID,
                    productName: {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    productNumber: {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    workName: {
                        value: "",
                        inputType: DROPDOWN_INPUT
                    },
                    workNumber: {
                        value: "",
                        inputType: READ_ONLY_INPUT
                    },
                    workId: null
                };
                break;
            };
            case "agreementNumbers": {
                newItem = {
                    agreementNumberID: nextTempID,
                    number: {
                        value: "",
                        inputType: TEXT_INPUT
                    },
                    numberType: {
                        value: getLookupDefault(AGREEMENT_NUMBER_TYPE_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    }
                };
                break;
            };
            case "productName": {
                newItem = {
                    productNameID: nextTempID,
                    name: {
                        value: "",
                        inputType: TEXT_INPUT
                    },
                    productNameType: {
                        value: getLookupDefault(PRODUCT_NAME_TYPE_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    language: {
                        value: getLookupDefault(LANGUAGE_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    }
                };
                break;
            };
            case "productNumber": {
                newItem = {
                    productNumberID: nextTempID,
                    number: {
                        value: "",
                        inputType: TEXT_INPUT
                    },
                    productNumberType: {
                        value: getLookupDefault(PRODUCT_NUMBER_TYPE_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    status: {
                        value: getLookupDefault(PRODUCT_STATUS_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    }
                };
                break;
            };
            case "productPerformer": {
                newItem = {
                    productPerformerID: nextTempID,
                    performerName: {
                        value: "",
                        inputType: TEXT_INPUT
                    },
                    type: {
                        value: getLookupDefault(WORK_PERFORMER_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    }
                };
                break;
            };
            case "licensesAndProducts": {
                newItem = {
                    workProductID: nextTempID,
                    title: { value: "", inputType: READ_ONLY_INPUT },
                    number: { value: "", inputType: READ_ONLY_INPUT },
                    contributors: { value: "", inputType: READ_ONLY_INPUT },
                    artists: { value: "", inputType: READ_ONLY_INPUT },
                    intrayIndicator: { value: "", inputType: BOOLEAN_READONLY_INPUT },
                };
                break;
            };
            case "workPaymentRuns": {
                newItem = {
                    paymentRun: { value: "", inputType: READ_ONLY_INPUT },
                    distibution: { value: "", inputType: READ_ONLY_INPUT },
                    pool: { value: "", inputType: READ_ONLY_INPUT },
                    amount: { value: "", inputType: READ_ONLY_INPUT },
                    segment: { value: "", inputType: READ_ONLY_INPUT },
                    date: { value: "", inputType: READ_ONLY_INPUT }
                };
                break;
            };
            case "matchingRepertoireWorks": {
                newItem = {
                    workID: nextTempID,
                    repertoireWorkID: -1,
                    name: [
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        }
                    ],
                    number: [
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        }
                    ],
                    publisher: [
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        }
                    ],
                    writer: [
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        }
                    ],
                    artist: [
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        }
                    ],
                    isDefinite: {
                        value: false,
                        inputType: CHECKBOX_INPUT
                    }
                };
                break;
            };
            case "matchingRepertoireProducts": {
                newItem = {
                    productID: nextTempID,
                    repertoireProductID: -1,
                    name: [
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        }
                    ],
                    number: [
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        },
                        {
                            value: "",
                            inputType: READ_ONLY_INPUT
                        }
                    ],
                    isDefinite: {
                        value: false,
                        inputType: CHECKBOX_INPUT
                    }
                }
                break;
            };
            case "productAVWork": {
                newItem = {
                    productAVWorkID: nextTempID,
                    territory: { value: "", inputType: DROPDOWN_INPUT },
                    category: { value: "", inputType: DROPDOWN_INPUT },
                    seriesIdentifier: { value: "", inputType: TEXT_INPUT },
                    productionNumber: { value: "", inputType: TEXT_INPUT },
                    episodeNumber: { value: "", inputType: TEXT_INPUT },
                    yearOfProduction: { value: "", inputType: INTEGER_INPUT },
                    totalDuration: { value: "", inputType: TIMESPAN_INPUT },
                    musicDuration: { value: "", inputType: TIMESPAN_INPUT },
                    airDate: { value: null, inputType: DATE_INPUT },
                    airedUntil: { value: null, inputType: DATE_INPUT },
                    type: { value: "", inputType: DROPDOWN_INPUT },
                    cueSheetType: { value: "", inputType: DROPDOWN_INPUT },
                    destination: { value: "", inputType: DROPDOWN_INPUT },
                    countryOfOrigin: { value: "", inputType: DROPDOWN_INPUT },
                    codeOfRevision: { value: "", inputType: DROPDOWN_INPUT },
                    dateOfRevision: { value: null, inputType: DATE_INPUT },
                    vIsan: { value: "", inputType: TEXT_INPUT },
                };
                break;
            };
            case "productContributor": {
                newItem = {
                    productContributorID: nextTempID,
                    name: { value: "", inputType: TEXT_INPUT },
                    avRipRoleCode: { value: "", inputType: DROPDOWN_INPUT }
                };
                break;
            }
            case "instrumentation": {
                newItem = {
                    instrumentationID: nextTempID,
                    type: { value: getLookupDefault(INSTRUMENTATION_LOOKUP, lookupValues), inputType: DROPDOWN_INPUT },
                    description: { value: "", inputType: CUSTOM_INPUT },
                    workInstruments: []
                };
                break;
            }
            case "artistNumbers": {
                newItem = {
                    performerNumberID: nextTempID,
                    number: { value: "", inputType: TEXT_INPUT },
                    numberType: { value: getLookupDefault(WORK_PERFORMER_NUMBER_LOOKUP, lookupValues), inputType: DROPDOWN_INPUT }
                };
                break;
            }
            case "poolSources": {
                newItem = {
                    poolSourceID: nextTempID,
                    usageType: { value: getLookupDefault(USAGE_TYPE_LOOKUP, lookupValues), inputType: DROPDOWN_INPUT },
                    source: { value: null, inputType: DROPDOWN_TREE_INPUT },
                    hierarchy: { value: "", inputType: READ_ONLY_INPUT }
                }
                break;
            }
            case "poolRightTypes": {
                newItem = {
                    poolRightID: nextTempID,
                    shareSet: { value: '', inputType: DROPDOWN_INPUT },
                    economicRights: { value: '', inputType: DROPDOWN_INPUT },
                    economicRightDescription: { value: '', inputType: READ_ONLY_INPUT }
                }
                break;
            }
            case "distributionPools": {
                let distPoolState: IDistributionPoolState = {
                    distributionPoolID: nextTempID,
                    poolCode: { value: null, inputType: DROPDOWN_INPUT },
                    description: { value: "", inputType: READ_ONLY_INPUT },
                    status: { value: "Not Allocated", inputType: READ_ONLY_INPUT },
                    segment: { value: "", inputType: TEXT_INPUT },
                    amountToAllocate: { value: 0, inputType: INTEGER_INPUT },
                    amountAllocated: { value: 0, inputType: READ_ONLY_INPUT },
                    actualValuePerPoint: { value: 0, inputType: READ_ONLY_INPUT },
                    nominalValuePerPoint: { value: null, inputType: INTEGER_INPUT },
                    allocationRule: { value: "", inputType: READ_ONLY_INPUT },
                    selectionCriteria: { type: "All" },
                    selected: { value: false, inputType: CHECKBOX_INPUT },
                    exchangeRate: { value: 1, inputType: INTEGER_INPUT },
                    witholdingTaxAgreement: { value: 0, inputType: INTEGER_INPUT },
                    witholdingTaxNoAgreement: { value: 0, inputType: INTEGER_INPUT },
                    socialAndCulturalDeduction: { value: 0, inputType: INTEGER_INPUT },
                    bankCharges: { value: 0, inputType: INTEGER_INPUT },
                    adminFees: { value: 0, inputType: INTEGER_INPUT },
                    currency: { value: "", inputType: TEXT_INPUT },
                    otherAdditions: { value: 0, inputType: INTEGER_INPUT },
                    otherDeductions: { value: 0, inputType: INTEGER_INPUT },
                    narrative: { value: "", inputType: TEXT_INPUT },
                    tdpsvAllocated: { value: null, inputType: READ_ONLY_INPUT },
                    tdpsvError: { value: null, inputType: READ_ONLY_INPUT },
                    tdpsvUa: { value: null, inputType: READ_ONLY_INPUT },
                    tdpsvUu: { value: null, inputType: READ_ONLY_INPUT },
                    poolID: null,
                    continuallyDistribute: { value: false, inputType: CHECKBOX_INPUT },
                    adjustments: { value: false, inputType: CHECKBOX_INPUT },
                    qaStatus: { value: "Not Ran", inputType: READ_ONLY_INPUT },
                    cdStatus: { value: false, inputType: READ_ONLY_INPUT }
                }
                newItem = distPoolState;
                break;
            }
            case "paymentRunDistributions": {
                let paymentRunDistributionState: IPaymentRunDistributionState = {
                    paymentRunDistributionID: nextTempID,
                    distributionCode: { value: null, inputType: DROPDOWN_INPUT },
                    distributionID: null,
                    paymentRunID: 0,
                    status: { value: "", inputType: READ_ONLY_INPUT },
                    cat1: { value: "", inputType: READ_ONLY_INPUT },
                    cat2: { value: "", inputType: READ_ONLY_INPUT },
                    description: { value: "", inputType: READ_ONLY_INPUT },
                    year: { value: "", inputType: READ_ONLY_INPUT },
                }
                newItem = paymentRunDistributionState;
                break;
            }
            case "SelectionCriteria": {
                newItem = {
                    distPoolSegmentID: nextTempID,
                    distribution: { value: null, inputType: DROPDOWN_INPUT },
                    pool: { value: null, inputType: DROPDOWN_INPUT },
                    segment: { value: null, inputType: DROPDOWN_INPUT },
                }
                break;
            }
            case "DateRangeSelectionCriteria": {
                newItem = {
                    dateRangeSegmentID: nextTempID,
                    fromDate: { value: null, inputType: DATE_INPUT },
                    toDate: { value: null, inputType: DATE_INPUT }
                }
                break;
            }
            case "productWorkSearch": {
                newItem = {
                    title: {
                        value: '',
                        inputType: TEXT_INPUT
                    },
                    number: {
                        value: '',
                        inputType: READ_ONLY_INPUT
                    },
                    writer: {
                        value: '',
                        inputType: TEXT_INPUT
                    },
                    artist: {
                        value: '',
                        inputType: TEXT_INPUT
                    },
                    status: {
                        value: 'Draft',
                        inputType: READ_ONLY_INPUT
                    },
                    duration: {
                        value: 0,
                        inputType: INTEGER_INPUT
                    },
                    intrayIndicator: {
                        value: portalType === MEMBERS_PORTAL ? true : undefined
                    },
                    remove: {
                        value: '',
                        inputType: TEXT_INPUT
                    },
                }
                break;
            }
            case "moreDates": {
                newItem = {
                    usageDate: {
                        value: '',
                        inputType: DATE_INPUT
                    },
                    programStartDate: {
                        value: '',
                        inputType: TIME_INPUT
                    },
                    programEndDate: {
                        value: '',
                        inputType: TIME_INPUT
                    }
                }
                break;
            }
            case "poolWeightSettings": {
                newItem = {
                    weightingCode: { value: "", inputType: TEXT_INPUT },
                    musicSource: { value: "", inputType: TEXT_INPUT },
                    use: { value: "", inputType: TEXT_INPUT },
                    effectiveDateRange: { value: "", inputType: DROPDOWN_INPUT },
                    fromTime: { value: "", inputType: TIME_INPUT },
                    toTime: { value: "", inputType: TIME_INPUT },
                    usageDimensionOne: { value: "", inputType: TEXT_INPUT },
                    usageDimensionTwo: { value: "", inputType: TEXT_INPUT },
                    usageDimensionThree: { value: "", inputType: TEXT_INPUT },
                    usageDimensionFour: { value: "", inputType: TEXT_INPUT },
                    usageDimensionFive: { value: "", inputType: TEXT_INPUT },
                    weightMultiplier: { value: "", inputType: TEXT_INPUT },
                    copySection: { value: false, inputType: CHECKBOX_INPUT },
                }
                break;
            }
            case "poolEstimatedValuePerPointSettings": {
                newItem = {
                    effectiveFrom: { value: "", inputType: DATE_INPUT },
                    effectiveTo: { value: "", inputType: DATE_INPUT },
                    evpp: { value: "", inputType: TEXT_INPUT },
                }
                break;
            }
            case "ipRepresentation": {
                newItem = {
                    societyName: { value: customer, inputType: DROPDOWN_INPUT },
                    fromDate: { value: new Date().toISOString().split('T', 1)[0], inputType: DATE_INPUT },
                    toDate: { value: new Date('9999-12-31').toISOString().split('T', 1)[0], inputType: DATE_INPUT },
                    economicRights: { value: "", inputType: DROPDOWN_INPUT },
                    shareSet: { value: "", inputType: READ_ONLY_INPUT },
                    role: { value: "", inputType: DROPDOWN_INPUT },
                    includeTerritories: { value: '2WL', inputType: TEXT_INPUT },
                    excludeTerritories: { value: "", inputType: TEXT_INPUT },
                    creationClass: { value: "", inputType: DROPDOWN_INPUT },
                }
                break;
            }
            case DRAFT_AGREEMENTS_COMMENTS_STATE_KEY: {
                let formattedToday = new Date().toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(',', '');
                newItem = {
                    date: { value: formattedToday, inputType: READ_ONLY_INPUT },
                    subject: { value: "", inputType: TEXT_INPUT },
                    commentCategory: {
                        value: getLookupDefault(COMMENT_CATEGORY_LOOKUP, lookupValues),
                        inputType: DROPDOWN_INPUT
                    },
                    comment: { value: "", inputType: TEXT_INPUT },
                }
                break;
            }
            case DRAFT_AGREEMENT_ASSIGNEE_VIEW: {
                newItem = {
                    assigneeNameNumber: { value: "", inputType: READ_ONLY_INPUT },
                    assigneeName: { value: "", inputType: READ_ONLY_INPUT },
                    societyAffiliation: { value: "", inputType: READ_ONLY_INPUT },
                    performingShare: { value: 0, inputType: INTEGER_INPUT },
                    mechanicalShare: { value: 0, inputType: INTEGER_INPUT },
                }
                break;
            }
            case "draftAgreementsApplicableWorks": {
                newItem = {
                    workName: { value: "", inputType: TEXT_INPUT },
                    workNumber: { value: "", inputType: TEXT_INPUT },
                }
                break;
            }
            default:
                {
                    newItem = null;
                    break;
                }
        }
        if (newItem != null) {
            tableContentsCopy.push(newItem);
        }
        changeData(tableContentsCopy, stateKey);
        incrementTempID()
    };

    onOpenCodeModel = (submission: string, type: string) => {
        const { showWorkSubmissionDetail, hideModal } = this.props;
        const field = JSON.stringify(JSON.parse(submission), null, 2)
        const props: ISubmissionPromptViewModalProps = {
            hideCallback: () => { hideModal() },
            field: field === 'null' ? '' : field,
            title: type
        }
        showWorkSubmissionDetail(submission, props);
    }

    getMaxLength = (fieldName: string) => {
        switch (fieldName) {
            case "seriesIdentifier": {
                return 4;
            }
            case "productionNumber": {
                return 12;
            }
            case "episodeNumber": {
                return 20;
            }
            case "vIsan": {
                return 26;
            }
            default: {
                return undefined;
            }
        };
    }

    renderLineBreak = (array: string[]) => {
        return (
            <div>
                {array.map((item, index) => (
                    <div key={index}>
                        {item}
                        {index + 1 !== array.length ? <span>,</span> : null}
                    </div>
                ))}
            </div>
        );
    }

    getDataInputType = (
        tableContentItem: any,
        objectKey: string,
        rowIndex: number,
        fieldType?: string,
        isHidden?: boolean,
        divTitle?: string,
        minValueForNumericField?: number,
        disabled?: boolean
    ) => {
        const { stateKey, lookupValues, showDropdownsAsCodes, dataSource, isReadOnly, datesAndDimensions, componentInstance, customFields, tabs, activeTab, lookupSources, tableContents, poolCodes, distributionCodes, distributions, expandedWorkResults, expandedProductResults, onClickAddUsageDetails, portalType } = this.props;
        if (tableContentItem === undefined) {
            tableContentItem = { value: '', inputType: '' };
        }
        let { value, inputType } = (Array.isArray(tableContentItem)) ? { value: '', inputType: '' } : tableContentItem;
        if (!value && !inputType) {
            value = tableContentItem;
            inputType = fieldType;
        }
        if (Array.isArray(tableContentItem) && (stateKey === MATCHING_REPERTOIRE_WORKS_KEY || stateKey === MATCHING_REPERTOIRE_PRODUCTS_KEY)) {
            inputType = tableContentItem.length >= 1 ? tableContentItem[0].inputType : 'readonly';
        }
        let type: string = inputType;
        if (inputType === TEXT_READONLY_IN_REPERTOIRE_INPUT) {
            type = dataSource === DataSource.Intray ? TEXT_INPUT : READ_ONLY_INPUT;
        }

        const multiSelectChangeData = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
            this.props.updateWorkField(option.data, "workInstruments", rowIndex);
        }

        const tableCell = stateKey !== EDITABLE_FIELDS ? "tableCell" : "";
        switch (type) {

            case TEXT_INPUT:
                if (componentInstance === EDITABLE_FIELDS && objectKey === "defaultValue") {

                    value = tableContentItem["defaultValue"];
                    value = value ? value : '';
                    return (
                        disabled ? <></> :
                            <div className={tableCell}>
                                <TextDataInput
                                    value={value}
                                    fieldName={stateKey}
                                    changeData={(value, stateKey) =>
                                        this.changeTableData(value, stateKey, objectKey, rowIndex, componentInstance)
                                    }
                                    readOnly={isReadOnly}
                                    maxLength={this.getMaxLength(objectKey)}
                                    isHidden={isHidden}
                                />
                            </div>
                    );
                }
                else {
                    return (
                        <div className={tableCell}>
                            <TextDataInput
                                value={componentInstance === ARTISTS_KEY && objectKey === WORK_ARTIST_NUMBER_KEY ? (value && value.number ? value.number : value) : value}
                                fieldName={stateKey}
                                changeData={(value, stateKey) =>
                                    this.changeTableData(value, stateKey, objectKey, rowIndex, componentInstance)
                                }
                                readOnly={isReadOnly}
                                maxLength={this.getMaxLength(objectKey)}
                                isHidden={isHidden}
                                objectKey={objectKey}
                            />
                        </div>
                    );
                }
            case INTEGER_INPUT:
                return (
                    <div className="tableCell">
                        <TextDataInput
                            value={value === 0 ? "0" : value}
                            fieldName={stateKey}
                            changeData={(value, stateKey) =>
                                this.changeTableData(value, stateKey, objectKey, rowIndex, componentInstance)
                            }
                            readOnly={isReadOnly}
                            maxLength={this.getMaxLength(stateKey)}
                            isHidden={isHidden}
                            minValueForNumericField={minValueForNumericField}
                        />
                    </div>
                );
            case READ_ONLY_INPUT:
                var frontendValue = value;
                if (stateKey == 'productWork' && objectKey == 'musicUsage') {
                    let lookup = mapLookupKey(stateKey, objectKey);
                    frontendValue = (getLookupValues(lookup, lookupValues).filter(x => x.code == value)[0] || { description: null }).description;
                }

                if (stateKey == 'matchingRepertoireWorks') {
                    if (expandedWorkResults && expandedWorkResults.includes(rowIndex)) {
                        return <div className="tableCell">
                            <TextDataInput
                                value={frontendValue.length >= 1 ? frontendValue[0].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={isHidden}
                            />
                            <TextDataInput
                                value={frontendValue.length >= 2 ? frontendValue[1].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={isHidden}
                            />
                            <TextDataInput
                                value={frontendValue.length >= 3 ? frontendValue[2].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={isHidden}
                            />
                        </div>
                    } else {
                        return <div className="tableCell">
                            <TextDataInput
                                value={frontendValue.length >= 1 ? frontendValue[0].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={isHidden}
                            />
                        </div>
                    }
                }

                if (stateKey == 'matchingRepertoireProducts') {
                    if (expandedProductResults && expandedProductResults.includes(rowIndex)) {
                        return <div className="tableCell">
                            <TextDataInput
                                value={frontendValue.length >= 1 ? frontendValue[0].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={isHidden}
                            />
                            <TextDataInput
                                value={frontendValue.length >= 2 ? frontendValue[1].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={isHidden}
                            />
                            <TextDataInput
                                value={frontendValue.length >= 3 ? frontendValue[2].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={isHidden}
                            />
                        </div>
                    } else {
                        return <div className="tableCell">
                            <TextDataInput
                                value={frontendValue.length >= 1 ? frontendValue[0].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={isHidden}
                            />
                        </div>
                    }
                }

                if (stateKey == 'matchingRepertoireProducts') {
                    if (expandedProductResults && expandedProductResults.includes(rowIndex)) {
                        return <div className="tableCell">
                            <TextDataInput
                                value={frontendValue.length >= 1 ? frontendValue[0].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={isHidden}
                            />
                            <TextDataInput
                                value={frontendValue.length >= 2 ? frontendValue[1].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={isHidden}
                            />
                            <TextDataInput
                                value={frontendValue.length >= 3 ? frontendValue[2].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={isHidden}
                            />
                        </div>
                    } else {
                        return <div className="tableCell">
                            <TextDataInput
                                value={frontendValue.length >= 1 ? frontendValue[0].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={isHidden}
                            />
                        </div>
                    }
                }

                return (
                    <div className="tableCell">
                        <TextDataInput
                            value={frontendValue}
                            fieldName={stateKey}
                            changeData={null}
                            readOnly={true}
                            isHidden={isHidden}
                        />
                    </div>
                );
            case DROPDOWN_INPUT:
                let options;
                let lookup;
                const { datesAndDimensions } = this.props;
                if (stateKey === USAGE_DETAILS_ROWS_SECTION_KEY) {
                    lookup = mapUsageLookupKey(stateKey, objectKey);
                } else {
                    lookup = mapLookupKey(stateKey, objectKey);
                }
                if (objectKey === DISTRIBUTION_POOL_CODE_STATE_KEY) {
                    options = !!poolCodes ? [...new Set(poolCodes)].map(pc => { return { code: pc, description: pc } }).sort((a, b) => a.code.localeCompare(b.code, undefined, { sensitivity: 'base' })) : null;
                }
                else if (objectKey === 'workName') {
                    if (tableContents[rowIndex]['productName'].value !== '') {
                        options = tableContents[rowIndex].productWorks ? tableContents[rowIndex].productWorks.map(each => ({ code: each.title, description: each.title })) : []
                    }
                    else {
                        options = []
                    }
                }
                else if (objectKey === 'distribution') {
                    options = !!distributions ? distributions.map(d => ({ code: d.distributionCode, description: d.distributionCode })) : null;
                }
                else if (objectKey === 'pool') {
                    if (tableContents[rowIndex]['distribution'].value !== '') {
                        let distribution = !!distributions ? distributions.find(x => x.distributionCode === tableContents[rowIndex]['distribution'].value) : null;
                        if (distribution && distribution.distributionPools)
                            options = [...new Set(distribution.distributionPools.map(dp => dp.poolCode))].map(poolCode => ({ code: poolCode, description: poolCode }));
                    }
                    else {
                        options = [];
                        if (tableContents[rowIndex]['pool'].value !== '')
                            this.changeTableData('', stateKey, 'pool', rowIndex)
                    }
                }
                else if (objectKey === 'segment') {
                    if (tableContents[rowIndex]['distribution'].value !== '' && tableContents[rowIndex]['pool'].value !== '') {
                        let pools = distributions.find(x => x.distributionCode === tableContents[rowIndex]['distribution'].value).distributionPools.filter(x => x.poolCode === tableContents[rowIndex]['pool'].value);
                        options = pools.map(p => ({ code: p.segment, description: p.segment }));
                    }
                    else {
                        options = [];
                        if (tableContents[rowIndex]['segment'].value !== '')
                            this.changeTableData('', stateKey, 'segment', rowIndex)
                    }

                }
                else if (objectKey === 'economicRights' && componentInstance !== IP_REPRESENTATION_KEY) {
                    if (rowIndex > 0) {
                        options = getLookupValues(lookup, lookupValues).filter(x => x.typeID > 0);
                    } else {
                        options = getLookupValues(lookup, lookupValues);
                    }
                }
                else if (objectKey === 'economicRights' && componentInstance === IP_REPRESENTATION_KEY) {
                    options = getLookupValues(SET_TYPE_RIGHTS_LOOKUP, lookupValues);

                }
                else if (objectKey === 'shareSet' && componentInstance === IP_REPRESENTATION_KEY) {
                    options = getLookupValues(SET_TYPE_LOOKUP, lookupValues).filter(x => x.typeID < 0);

                }
                else if (objectKey === 'societyName' && componentInstance === IP_REPRESENTATION_KEY) {
                    options = getLookupValues(AGENCY_LOOKUP, lookupValues);

                }
                else if (objectKey === 'role' && componentInstance === IP_REPRESENTATION_KEY) {
                    const creationClass = tabs[activeTab].interestedPartyMaintenanceState?.interestedParty?.socIP?.ipRepresentations[rowIndex].creationClass?.value ? tabs[activeTab].interestedPartyMaintenanceState?.interestedParty?.socIP?.ipRepresentations[rowIndex].creationClass?.value : '';
                    return <div>
                        <IPRoleSelector
                            fieldValue={value}
                            lookupValues={lookupValues}
                            changeData={(value, stateKey) => {
                                this.changeTableData(value, stateKey, objectKey, rowIndex)
                            }}
                            creationClassValue={creationClass}
                        />
                    </div>
                }
                else if (objectKey === 'includeTerritories' && componentInstance === IP_REPRESENTATION_KEY) {
                    options = getLookupValues(TERRITORY_LOOKUP, lookupValues);
                }
                else if (objectKey === 'excludeTerritories' && componentInstance === IP_REPRESENTATION_KEY) {
                    options = getLookupValues(TERRITORY_LOOKUP, lookupValues);
                }


                else if (objectKey === PAYMENTRUN_DISTRIBUTION_CODE_STATE_KEY) {
                    options = !!distributionCodes ? [...new Set(distributionCodes)].map(pc => { return { code: pc, description: pc } }).sort((a, b) => a.code.localeCompare(b.code, undefined, { sensitivity: 'base' })) : null;
                }
                else if (objectKey === USAGE_DETAILS_ROWS_INBOUNDFEESINERROR || objectKey === USAGE_DETAILS_ROWS_OUTBOUNDFEESINERROR) {
                    options = getLookupValues(lookup, lookupValues)
                }
                else if (objectKey === 'effectiveDateRange') {

                    const dateRange = datesAndDimensions.map(element => { return { code: element.effectiveDateRange, description: element.effectiveDateRange } });
                    options = dateRange;
                }
                else if (objectKey === 'creationClass') {

                    const { creationClassOptions } = this.props;
                    options = creationClassOptions.map((element) => ({ code: element.text, description: element.text }));
                    options = options.filter(option => option.code !== 'All');
                }

                else {

                    options = objectKey === "countryOfOrigin" ? getLookupValues(lookup, lookupValues).filter(x => x.code.length <= 2) :
                        objectKey === 'shareSet' ? getLookupValues(lookup, lookupValues, objectKey) :
                            getLookupValues(lookup, lookupValues, null, portalType)
                }

                let isShowAsCode = objectKey === "countryOfOrigin" || objectKey === "economicRights" ? true : showDropdownsAsCodes

                let readOnly: boolean = isReadOnly

                if (objectKey === "workName" && tableContents[rowIndex]['productName'].value === '') {
                    readOnly = true
                }
                if (objectKey === "exclusionUntil") {
                    readOnly = false;
                }
                if (objectKey === "senderRep") {
                    isShowAsCode = false;
                }
                if (objectKey === "language") {
                    isShowAsCode = false;
                    options = options.sort((a, b) => a.description.localeCompare(b.description));
                }
                let className = objectKey === 'avRipRoleCode' ? EMPTY_STRING_VALUE : 'tableCell';

                if (lookup === 'WorkNumber' && objectKey === 'type') {
                    options = options.filter(x => { return x.extraFields.CoreOrExternal.toUpperCase() === WORKNUMBER_CORE_OR_EXTERNAL_BOTH || x.extraFields.CoreOrExternal.toUpperCase() === WORKNUMBER_CORE_OR_EXTERNAL_CORE })
                }
                if (objectKey === "societyOfLicense") {
                    isShowAsCode = false;
                    //tableContents[rowIndex]['societyOfLicense'].value = value;
                    //var x = value;
                    options = options.sort((a, b) => a.description.localeCompare(b.description));
                }
                if (objectKey === 'type' && stateKey === 'applicableWorks') {
                    readOnly = true;
                }
                else if (objectKey === 'economicRights' && componentInstance === IP_REPRESENTATION_KEY) {
                    readOnly = false;
                }
                if (objectKey === 'avRipRoleCode' && stateKey === 'productContributor') {
                    let result = [{ code: "", description: "" }];
                    options.map(x => {
                        let value = x.description.split('-')
                        result.push({ code: x.code, description: value[0] })
                    });
                    options = result;
                }
                return (
                    <div className={className}>
                        {objectKey !== DISTRIBUTION_POOL_CODE_STATE_KEY ? <DropdownDataInput
                            value={value}
                            options={options}
                            changeData={(value, stateKey) =>
                                this.changeTableData(value, stateKey, objectKey, rowIndex)
                            }
                            fieldName={stateKey}
                            allowNull={true}
                            showDropdownsAsCodes={isShowAsCode}
                            readOnly={readOnly}
                            isHidden={isHidden}
                        /> : <>
                            <div className="gridContainer">
                                <DropdownDataInput
                                    value={value}
                                    options={options}
                                    changeData={(value, stateKey) =>
                                        this.changeTableData(value, stateKey, objectKey, rowIndex)
                                    }
                                    fieldName={stateKey}
                                    allowNull={true}
                                    showDropdownsAsCodes={isShowAsCode}
                                    readOnly={readOnly}
                                    isHidden={isHidden}
                                />
                                <IconTextButton
                                    icon={"icon ms-Icon ms-Icon--OpenInNewTab"}
                                    text={""}
                                    onClick={() => this.props.openInNewTab(tableContentItem)}
                                    disabled={isReadOnly}
                                />
                            </div>
                        </>}
                    </div>
                );
            case DATE_INPUT:
                return (
                    <div className="tableCell">
                        <SizedDateDataInput
                            value={value}
                            fieldName={stateKey}
                            label={undefined}
                            changeData={(value, stateKey) =>
                                this.changeTableData(value, stateKey, objectKey, rowIndex)
                            }
                            readOnly={isReadOnly}
                            isHidden={isHidden}
                        />
                    </div>
                );
            case TIME_INPUT:
                return (
                    <>
                        {<SimpleTimeDataInput
                            value={value}
                            changeData={(value, stateKey) => this.changeTableData(value, stateKey, objectKey, rowIndex)}
                            fieldName={stateKey}
                            label={''}
                            readonly={isReadOnly}
                        />}
                    </>
                );
            case TIMESPAN_INPUT:
                return (<div className="tableCell">
                    <FormattedTimeDataInput
                        fieldName={objectKey}
                        label=""
                        value={value}
                        changeData={(value, stateKey) =>
                            this.changeTableData(value, stateKey, objectKey, rowIndex, componentInstance)
                        }
                        readOnly={isReadOnly}
                        tabs={tabs}
                        activeTab={activeTab}
                        isHidden={isHidden}
                        isDataGridSize={true}
                    />
                </div>
                );
            case READ_ONLY_TIMESPAN_INPUT:
                return (<div className="tableCell">
                    <FormattedTimeDataInput
                        fieldName={objectKey}
                        label=""
                        value={value}
                        changeData={(value, stateKey) =>
                            this.changeTableData(value, stateKey, objectKey, rowIndex, componentInstance)
                        }
                        readOnly={true}
                        tabs={tabs}
                        activeTab={activeTab}
                        isHidden={isHidden}
                        isDataGridSize={true}

                    />
                </div>
                );
            case BOOLEAN_READONLY_INPUT:
                return <BooleanReadonlyDisplay value={value} />
            case CHECKBOX_INPUT:
                return (componentInstance === EDITABLE_FIELDS && objectKey === "isMandatory") ? (
                    <div className="tableCell">
                        <CheckboxDataInput
                            label="Is Mandatory"
                            fieldName={stateKey}
                            value={tableContentItem["isMandatory"]}
                            changeData={(value, stateKey) =>
                                this.changeTableData(value, stateKey, objectKey, rowIndex, componentInstance)}
                            readonly={false}
                            isHidden={isHidden}
                        />
                    </div>) : (
                    <div className="tableCell">
                        <CheckboxDataInput
                            label=""
                            fieldName={stateKey}
                            value={value}
                            changeData={(value, stateKey) =>
                                this.changeTableData(value, stateKey, objectKey, rowIndex)}
                            readonly={isReadOnly}
                            isHidden={isHidden}
                        />
                    </div>);
            case TOGGLE_BUTTON:
                return (componentInstance === EDITABLE_FIELDS && objectKey === "isHiddenField") ? (
                    <ToggleButtonDataInput
                        changeData={(value, stateKey) =>
                            this.changeTableData(value, stateKey, objectKey, rowIndex, componentInstance)}
                        fieldName={tableContentItem.name}
                        value={tableContentItem.hidden}
                        label={undefined}
                        readonly={isReadOnly}
                        disabled={!tableContentItem["isMandatoryModifiable"] || tableContentItem["isMandatory"]}
                    />
                ) : (
                    <ToggleButtonDataInput
                        changeData={(value, stateKey) =>
                            this.changeTableData(value, stateKey, objectKey, rowIndex, componentInstance)}
                        fieldName={tableContentItem.name}
                        value={tableContentItem.hidden}
                        label={undefined}
                        readonly={isReadOnly}

                    />
                );
            case CUSTOM_INPUT:
                if (customFields && customFields.includes(rowIndex)) {
                    if (objectKey === "workInstruments") {
                        let lookup = mapLookupKey(stateKey, objectKey);
                        let instrumentsOptions = [];
                        const instrumentLookups = getLookupValues(lookup, lookupValues);
                        instrumentLookups.forEach(x => instrumentsOptions.push({ key: x.typeID, text: x.description, data: x.code }));

                        let mappedValues: number[] = [];
                        instrumentLookups.forEach(function (x) {
                            if (value && value.filter(y => y.instrument.value === x.code || y.instrument === x.code).length > 0) {
                                mappedValues.push(x.typeID);
                            }
                        });

                        return (
                            <div className="tableCell">
                                <Dropdown
                                    selectedKeys={mappedValues}
                                    options={instrumentsOptions}
                                    multiSelect={true}
                                    onChange={multiSelectChangeData}
                                    placeholder={"No Instruments selected."}
                                    disabled={false}
                                />
                            </div >
                        )
                    } else {
                        return (
                            <div className={tableCell}>
                                <TextDataInput
                                    value={value}
                                    fieldName={stateKey}
                                    changeData={(value, stateKey) =>
                                        this.changeTableData(value, stateKey, objectKey, rowIndex, componentInstance)
                                    }
                                    readOnly={false}
                                    maxLength={this.getMaxLength(objectKey)}
                                    isHidden={isHidden}
                                />
                            </div>
                        )
                    }
                }
                else {
                    if (objectKey === "workInstruments") {
                        let lookup = mapLookupKey(stateKey, objectKey);
                        let instrumentsOptions = [];
                        const instrumentLookups = getLookupValues(lookup, lookupValues);
                        instrumentLookups.forEach(x => instrumentsOptions.push({ key: x.typeID, text: x.description, data: x.code }));

                        return (
                            <div className="tableCell">
                                <Dropdown
                                    selectedKeys={value}
                                    options={instrumentsOptions}
                                    multiSelect={true}
                                    onChange={multiSelectChangeData}
                                    placeholder={"N/A"}
                                    disabled={true}
                                />
                            </div >
                        )
                    } else {
                        return (
                            <div className={tableCell}>
                                <TextDataInput
                                    value={value}
                                    fieldName={stateKey}
                                    changeData={(value, stateKey) =>
                                        this.changeTableData(value, stateKey, objectKey, rowIndex, componentInstance)
                                    }
                                    readOnly={true}
                                    maxLength={this.getMaxLength(objectKey)}
                                    isHidden={isHidden}
                                />
                            </div>
                        )
                    }
                }
            case DROPDOWN_TREE_INPUT: {
                let readOnly: boolean = isReadOnly;
                let currentMatchType = tableContentItem.value ? lookupSources.find(x => x.id === tableContentItem.value).matchType : null;
                let currentValue = value;
                let options: ITreeData[] = lookupSources;
                if (objectKey === 'source') {
                    if (!tableContents[rowIndex]['usageType'].value) {
                        options = [];
                        readOnly = true;
                    }
                    else {
                        options = lookupSources.filter(x => x.matchType === tableContents[rowIndex]['usageType'].value)
                        if (currentMatchType != tableContents[rowIndex]['usageType'].value) {
                            currentValue = null;
                            tableContents[rowIndex].hierarchy.value = '';
                        }
                    }
                }

                return (
                    <div className="tableCell">
                        <DropdownTreeDataInput
                            options={options}
                            readOnly={false}
                            fieldName={stateKey}
                            changeData={(value, stateKey) =>
                                this.changeTableData(value, stateKey, objectKey, rowIndex, componentInstance)}
                            value={currentValue}
                            isHidden={isHidden}
                            allowNull={false}
                        />
                    </div>
                )
            }
            case LINK_INPUT:
                return (
                    <><Space onClick={() => onClickAddUsageDetails(rowIndex)} size="middle"><a>Add Adjustment Details</a></Space></>
                )

            default:
                if (objectKey === 'date') {
                    return (<div className="tableCell">
                        <DateDisplay value={value} showTime={true} />
                    </div>)
                }
                if (stateKey === USAGE_DETAILS_ROWS_SECTION_KEY && objectKey === "amount" && tableContentItem === "0E-18") {
                    tableContentItem = 0;
                }

                if (objectKey !== "selectionCriteria") {
                    return (
                        <div className="tableCell">
                            <DataGridTableSection
                                data={objectKey === IP_NAME_NUMBER_STATE_KEY ? RepertoireHelper.zeroPaddedIPINameNumber(tableContentItem) :
                                    (typeof (tableContentItem) === 'string' || typeof (tableContentItem) === 'number' ? tableContentItem :
                                        tableContentItem.value)}
                                title={objectKey === ECONOMIC_RIGHT ? divTitle : undefined} />
                        </div>
                    );
                }
        }
    };

    getTableSections = (tableContentItem: any, index: number) => {
        const { dataGridTableData, componentInstance, showRowNumber, stateKey, agreementShareDisplayOptions, isReadOnly, shareDecimalsToDisplay, dataSource, isExpandable } = this.props;
        let currentComponentInstance = componentInstance;

        if (componentInstance === SET_LISTS_WORK_SEARCHVIEW_STATE_KEY) {
            currentComponentInstance = DRAFT_SET_LISTS_WORK_STATE_KEY;
        }
        if (dataGridTableData && dataGridTableData.fields) {
            let filteredFields: IRepertoireField[] = dataGridTableData.fields.filter(
                (field: IRepertoireField) => field.componentInstance && field.componentInstance === currentComponentInstance);
            const expanderFields = dataGridTableData.fields.filter(
                (field: IRepertoireField) => field.componentInstance && field.componentInstance === DATA_GRID_TABLE_FIELDS_EXPANDER);
            const dependentFields = dataGridTableData.fields.filter(
                (field: IRepertoireField) => field.componentInstance && field.componentInstance.startsWith(currentComponentInstance + '.'));
            const dependentFieldKey: string = dependentFields && dependentFields.length > 0 && dependentFields[0].componentInstance.split('.')[1];

            if (currentComponentInstance === CONTRIBUTORS_KEY || currentComponentInstance === IP_NAMES_KEY) {
                filteredFields = filteredFields.filter(x => !x.hidden);
            }

            if (currentComponentInstance === CONTRIBUTORS_KEY) {
                filteredFields = ComponentsHelper.orderFieldsBySpecificField(filteredFields as IRepertoireField[], ORDER_GRID_FIELD_METADATA);
            }

            const sections: JSX.Element[] = [];
            const dependentSections: JSX.Element[] = [];

            if (showRowNumber && currentComponentInstance !== IP_LICENSING_REPRESENTATION_KEY) {
                sections.splice(0, 0, <td key='rowNumber' className="rowNumber">
                    <div className="tableCell">
                        {index + 1}
                    </div>
                </td>);
            }
            filteredFields.map((field) => {
                const key = field.name;
                if ((stateKey === CONTRIBUTORS_KEY && key === IPI_NUMBER_STATE_KEY)
                    || (stateKey === ADMINISTERED_BY_KEY && key === ADMINISTERED_BY_IP_BASE_NUMBER_STATE_KEY)
                    || (stateKey === APPLICABLE_WORKS_KEY && key === APPLICABLE_WORK_NAME_STATE_KEY)
                    || (stateKey === AGREEMENT_CARVEOUTS_KEY && key === AGREEMENT_CARVEOUTS_PRODUCT_NAME_STATE_KEY)
                    || (stateKey === PRODUCT_CONTRIBUTORS_KEY && key === PRODUCT_CONTRIBUTOR_NAME_STATE_KEY)
                    || (dataSource === DataSource.Intray && stateKey === ARTISTS_KEY && key === ARTIST_TYPE_STATE_KEY)
                    || (dataSource === DataSource.Intray && stateKey === PRODUCT_PERFORMERS_KEY && key === PRODUCT_PERFORMER_NAME_STATE_KEY)
                    || (stateKey === DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY && key === "workName")) {
                    sections.push(this.renderFieldOptions(field, tableContentItem, index));
                }
                else if ((stateKey === MATCHING_REPERTOIRE_WORKS_KEY) && !isTableAction(field.fieldType)) {
                    if (key === MATCHING_REPERTOIRE_WORK_NAME_STATE_KEY) {
                        sections.push(this.renderFieldOptions(field, tableContentItem, index));
                    } else {
                        sections.push(
                            <td key={key} className="td" title={field.data}>
                                {this.getDataInputType(tableContentItem[key], key, index, field.fieldType, false)}
                            </td>
                        );

                    }
                }
                else if (stateKey === WORK_SUBJECT_KEY && key === WORK_SUBJECT_STATE_KEY) {
                    sections.push(
                        <td key={field.name} title={field.data}>
                            <div className="tableCell hasIcon" style={{ display: 'flex', alignItems: 'center', marginTop: '4px', paddingLeft: '4px' }}>
                                <TextDataInput
                                    value={tableContentItem[field.name].value}
                                    fieldName={field.name}
                                    changeData={(value, stateKey) => this.changeTableData(tableContentItem[field.name].value, field.name, field.data, index)}
                                    readOnly={true}
                                    isHidden={true}
                                />
                                <ShowMore
                                    options={!isReadOnly && [
                                        {
                                            text: expanderFields.find(e => e.name === "selectSubject").data,
                                            onClick: () => { this.showSearchModal(tableContentItem, SEARCH_VIEW_SUBJECTS) },
                                            icon: "assets/external.svg"
                                        }
                                    ]}
                                />
                            </div>
                        </td>
                    );
                }
                else if ((stateKey === MATCHING_REPERTOIRE_PRODUCTS_KEY) && !isTableAction(field.fieldType)) {
                    if (key === MATCHING_REPERTOIRE_PRODUCT_NAME_STATE_KEY) {
                        sections.push(this.renderFieldOptions(field, tableContentItem, index));
                    } else {
                        sections.push(
                            <td key={key} className="td" title={field.data}>
                                {this.getDataInputType(tableContentItem[key], key, index, field.fieldType, false)}
                            </td>
                        );

                    }
                }
                else if ((stateKey === CONTRIBUTORS_KEY && key === 'perfPercentage') || (stateKey === ADMINISTERED_BY_KEY && key === 'performingShare')) {
                    tableContentItem['performingShareToDisplay'].value = RepertoireHelper.trailingZeroesForShares(tableContentItem['performingShareToDisplay'].value, shareDecimalsToDisplay)
                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(tableContentItem['performingShareToDisplay'], key, index, field.fieldType, false)}
                        </td>
                    );
                }
                else if ((stateKey === CONTRIBUTORS_KEY && key === 'mechPercentage') || (stateKey === ADMINISTERED_BY_KEY && key === 'mechanicalShare')) {
                    tableContentItem['mechanicalShareToDisplay'].value = RepertoireHelper.trailingZeroesForShares(tableContentItem['mechanicalShareToDisplay'].value, shareDecimalsToDisplay)
                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(tableContentItem['mechanicalShareToDisplay'], key, index, field.fieldType, false)}
                        </td>
                    );
                }
                else if ((stateKey === DRAFT_AGREEMENT_ASSIGNEE_VIEW && key === 'performingShare')) {
                    tableContentItem['performingShare'].value = RepertoireHelper.trailingZeroesForShares(tableContentItem['performingShare'].value, 2)
                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(tableContentItem['performingShare'], key, index, field.fieldType, false)}
                        </td>
                    );
                }
                else if ((stateKey === DRAFT_AGREEMENT_ASSIGNEE_VIEW && key === 'mechanicalShare')) {
                    tableContentItem['mechanicalShare'].value = RepertoireHelper.trailingZeroesForShares(tableContentItem['mechanicalShare'].value, 2)
                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(tableContentItem['mechanicalShare'], key, index, field.fieldType, false)}
                        </td>
                    );
                }
                else if ((stateKey === CONTRIBUTORS_KEY && key === 'contributorComments')) {
                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            <div className="contributor-comment">
                                <IconTextButton
                                    icon={"ms-Icon ms-Icon--Chat"}
                                    text={""}
                                    onClick={() => this.onClickOpen(field.componentInstance, tableContentItem, field.data)}
                                />
                            </div>
                        </td>
                    )
                }
                else if ((stateKey === CONTRIBUTORS_KEY && key === 'relation')) {
                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(tableContentItem['relation'], key, index, field.fieldType, false)}
                        </td>
                    );
                }
                else if ((stateKey === USAGE_DETAILS_ROWS_SECTION_KEY && key === USAGE_DETAILS_ROWS_INBOUNDFEESINERROR)) {
                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(tableContentItem['inboundFeesInError'], key, index, field.fieldType, false)}
                        </td>
                    );
                }
                else if ((stateKey === USAGE_DETAILS_ROWS_SECTION_KEY && key === USAGE_DETAILS_ROWS_OUTBOUNDFEESINERROR)) {
                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(tableContentItem['outboundFeesInError'], key, index, field.fieldType, false)}
                        </td>
                    );
                }
                else if ((stateKey === USAGE_DETAILS_ROWS_SECTION_KEY && key === USAGE_DETAILS_ROWS_ACTIONS)) {
                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(USAGE_DETAILS_ROWS_ACTIONS, key, index, field.fieldType, false)}
                        </td>
                    );
                }
                else if ((stateKey === DISTRIBUTION_POOLS_KEY && key === 'amountToAllocate')) {

                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(tableContentItem['amountToAllocate'], key, index, field.fieldType, false, null, 0)}
                        </td>
                    );
                }
                else if ((stateKey === DISTRIBUTION_POOLS_KEY && key === 'nominalValuePerPoint')) {

                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(tableContentItem['nominalValuePerPoint'], key, index, field.fieldType, false, null, 0)}
                        </td>
                    );
                }
                else if ((stateKey === CONTRIBUTORS_KEY && key === 'copyLevyPercentage')) {
                    tableContentItem['copyLevyPercentage'].value = RepertoireHelper.trailingZeroesForShares(tableContentItem['copyLevyPercentage'].value, shareDecimalsToDisplay)
                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(tableContentItem['copyLevyPercentage'], key, index, field.fieldType, false)}
                        </td>
                    );
                }
                else if ((stateKey === CONTRIBUTORS_KEY && key === 'copyLicencePercentage')) {
                    tableContentItem['copyLicencePercentage'].value = RepertoireHelper.trailingZeroesForShares(tableContentItem['copyLicencePercentage'].value, shareDecimalsToDisplay)
                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(tableContentItem['copyLicencePercentage'], key, index, field.fieldType, false)}
                        </td>
                    );
                }
                else if (stateKey === AGREEMENT_DUPLICATE_KEY && key === "repertoireAgreementID") {
                    sections.push(
                        <td key={field.name} title={field.data}>
                            <div className="tableCell hasIcon">
                                <TextDataInput
                                    value={tableContentItem[field.name].value}
                                    fieldName={field.name}
                                    changeData={(value, stateKey) => this.changeTableData(tableContentItem[field.name].value, field.name, field.data, index)}
                                    readOnly={true}
                                    isHidden={true}
                                />
                                <ShowMore
                                    options={!isReadOnly && [
                                        {
                                            text: expanderFields.find(e => e.name === "openAgreement").data,
                                            onClick: () => { this.props.openInNewTab(tableContentItem) },
                                            icon: "assets/external.svg"
                                        }
                                    ]}
                                />
                            </div>
                        </td>
                    )
                }
                else if (stateKey === DRAFT_AGREEMENTS_COMMENTS_STATE_KEY && key === "comment") {
                    sections.push(
                        <td key={key} className="td">
                            <div style={{ position: 'relative', zIndex: 1000 }}>
                                <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', right: -15, top: '50%', transform: 'translateY(-50%)' }}>
                                    <ShowMore
                                    //tablecontentitem stops all table data being passed
                                        options={[
                                            {
                                                text: "Expand Comment",
                                                onClick: () => { this.showSearchModal(tableContentItem, SEARCH_VIEW_DRAFT_AGREEMENTS, index) },
                                                icon: "assets/external.svg"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="input-container">
                                    <TextDataInput
                                        value={tableContentItem[key].value}
                                        fieldName={key}
                                        changeData={(value, stateKey) => this.changeTableData(value, stateKey, key, index)}
                                        readOnly={isReadOnly}
                                        isHidden={false}
                                    />
                                </div>
                            </div>
                        </td>
                    );
                }
                else if (stateKey === DRAFT_AGREEMENT_ASSIGNEE_VIEW && key === "assigneeNameNumber") {
                    sections.push(
                        <td key={key} className="td">
                            <div style={{ position: 'relative', zIndex: 1000 }}>
                                <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', right: -15, top: '50%', transform: 'translateY(-50%)' }}>
                                    <ShowMore
                                        options={[
                                            {
                                                text: SELECT_IP,
                                                onClick: () => { this.showSearchModal(SEARCH_VIEW, SEARCH_VIEW_IPS, index) },
                                                icon: "assets/external.svg"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="input-container">
                                    <TextDataInput
                                        value={tableContentItem[key].value}
                                        fieldName={key}
                                        changeData={(value, stateKey) => this.changeTableData(value, stateKey, key, index)}
                                        readOnly={true}
                                        isHidden={false}
                                    />
                                </div>
                            </div>
                        </td>
                    );
                }
                else if (stateKey === IP_REPRESENTATION_KEY && key === "includeTerritories") {
                    sections.push(this.renderFieldOptions(field, tableContentItem, index));
                }
                else if (stateKey === IP_REPRESENTATION_KEY && key === "excludeTerritories") {

                    sections.push(this.renderFieldOptions(field, tableContentItem, index));
                }
                else if (isTableAction(field.fieldType)) {
                    sections.push(this.renderRowAction(tableContentItem, field))
                }
                else if (stateKey === EDITABLE_FIELDS) {
                    const nameField = tableContentItem["name"];
                    const item = {
                        value: tableContentItem["data"], inputType: field.fieldType, hidden: tableContentItem["hidden"] as boolean,
                        name: nameField, isReadOnly: false, defaultValue: tableContentItem["defaultValue"],
                        isMandatory: tableContentItem["isMandatory"], isMandatoryModifiable: tableContentItem["isMandatoryModifiable"]
                    }
                    const disabled = field.name === "defaultValue" && (tableContentItem["fieldType"] === "dropdown" || tableContentItem["fieldType"] === "multiselect");
                    sections.push(
                        <td key={`${key}_${item["name"]}`} className="td col-lg-3 col-md-3 col-sm-3 col-xs-3" title={field.data}>
                            {this.getDataInputType(item, field.name, index,
                                field.fieldType, tableContentItem["hidden"] as boolean,
                                undefined, undefined, !disabled)}
                        </td>
                    );
                }
                else if (stateKey === CLAIMED_WORKS && key === 'workNumbers') {
                    if (tableContentItem[key] instanceof Array) {
                        const elements = tableContentItem[key].map((claimWorkNumber, index) => {
                            return (
                                <div className="m-1" key={`${index}_list`}><Space>{claimWorkNumber}</Space></div>
                            )
                        })
                        sections.push(...elements);
                    }
                }
                else if (tableContentItem[key] || tableContentItem[key] == '') {
                    if (currentComponentInstance === COMPONENTS_KEY && key === 'duration') {
                        sections.push(
                            <td key={key} className="td unableMinWidth" title={field.data}>
                                {this.getDataInputType(tableContentItem[key], key, index, field.fieldType, false)}
                            </td>
                        );
                    } else if (currentComponentInstance === COMPONENTS_KEY && key === 'writer') {
                        sections.push(
                            <td key={key} className="td" title={field.data}>
                                {this.getDataInputType(tableContentItem[key], key, index, field.fieldType, false)}
                            </td>
                        );
                    }
                    else {

                        let className = 'tableCell';
                        if (currentComponentInstance === 'productContributor' && (field.name === 'avRipRoleCode' || field.name === 'name')) {
                            className = `${className} minWidth`;
                        }
                        sections.push(
                            <td key={key} className={`td ${stateKey === CONTRIBUTORS_KEY && key === 'name' && 'extraWide'}`} title={field.data}>
                                <div className={className}>
                                    {this.getDataInputType(tableContentItem[key], key, index, field.fieldType, false,
                                        key === ECONOMIC_RIGHT ? tableContentItem[ECONOMIC_RIGHT_DESCRIPTION] : undefined)}
                                </div>
                            </td>
                        );
                    }

                }
                else if (stateKey === COMPONENTS_KEY) {
                    if (key === "Action") {
                        sections.push(
                            <td key={key} className="td" title={field.data}>
                                <div className="tableCell">
                                    <div key={key} className="arrowIconDiv">
                                        <div className="tableCell">
                                            <IconTextButton
                                                icon={"icon ms-Icon ms-Icon--OpenInNewTab"}
                                                text={tableContentItem["data"]}
                                                onClick={() => this.props.openInNewTab(tableContentItem)}
                                                disabled={isReadOnly}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </td>
                        )
                    }
                    else {
                        sections.push(
                            <td key={key} className="td" title={field.data}>
                                {this.getDataInputType(tableContentItem, key, index, field.fieldType, false)}
                            </td>
                        )
                    }
                }
                else if (stateKey === EDITABLE_FIELDS_GRID) {
                    const nameField = tableContentItem["name"];
                    const item = { value: tableContentItem["data"], inputType: field.fieldType, hidden: tableContentItem["hidden"] as boolean, name: nameField, isReadOnly: false }
                    sections.push(
                        <div key={`${key}_${item["name"]}`} className="td col-lg-6 col-md-6 col-sm-6 col-xs-6" title={field.data}>
                            {this.getDataInputType(item, field.name, index, field.fieldType, tableContentItem["hidden"] as boolean)}
                        </div>
                    );
                }
                else if (stateKey === PARENT_WORK_KEY) {
                    if (key === OPEN_PARENT) {
                        sections.push(
                            <td key={key} className="td" title={field.data}>
                                <div className="tableCell">
                                    <div key={key} className="arrowIconDiv">
                                        <div className="tableCell">
                                            <IconTextButton
                                                icon={"icon ms-Icon ms-Icon--OpenInNewTab"}
                                                text={"Work"}
                                                onClick={() => this.props.openInNewTab(tableContentItem)}
                                                disabled={isReadOnly}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </td>
                        )
                    }
                    else {
                        const nameField = tableContentItem["name"];
                        const item = { value: tableContentItem[key].value, inputType: field.fieldType, hidden: tableContentItem["hidden"] as boolean, name: nameField, isReadOnly: false }
                        sections.push(
                            <td key={key} className="td" title={field.data}>
                                {this.getDataInputType(item, key, index, field.fieldType, false)}
                            </td>
                        )
                    }
                }
                else if (stateKey === CONTRIBUTORS_KEY && key === WORK_CONTRIBUTOR_RELATIONSHIPS_STATE_KEY) {
                    const nameField = tableContentItem["name"];
                    const item = { value: tableContentItem["relation"].value, inputType: field.fieldType, hidden: tableContentItem["hidden"] as boolean, name: nameField, isReadOnly: false }
                    sections.push(
                        <td key={key} className="td" title={field.data}>
                            {this.getDataInputType(item, WORK_CONTRIBUTOR_RELATIONSHIPS_STATE_KEY, index, field.fieldType, false)}
                        </td>)
                }
                else {

                    sections.push(<td key={key} className="td" />)
                }

            });


            if (dependentFields && dependentFieldKey) {
                const items: any[] = tableContentItem[dependentFieldKey];
                if (items) {
                    let i: number = 0;
                    items.map(item => {
                        i++;
                        const rowCells: JSX.Element[] = [];
                        rowCells.push(<td key={i} className="td" />)
                        filteredFields.map(field => {
                            const dependentField = dependentFields.find(f => f.name === field.name);

                            const key: string = dependentFields[0].componentInstance + '.' + field.name;
                            let alignRight = false
                            if (dependentField) {
                                if (isTableAction(dependentField.fieldType)) {
                                    rowCells.push(this.renderRowAction(item, dependentField))
                                }
                                else {
                                    let cellData = item[field.name];
                                    if (field.name === 'ipiNumber')
                                        cellData = RepertoireHelper.zeroPaddedIPINameNumber(isNaN(Number(item['ipiNumber'])) ? "" : item[field.name]);

                                    if (field.name === 'perfPercentage' && agreementShareDisplayOptions != null && agreementShareDisplayOptions.ShowAgreementShares) {
                                        cellData = RepertoireHelper.trailingZeroesForShares(item['performingShareToDisplay'], shareDecimalsToDisplay);
                                        alignRight = true;
                                    }

                                    else if (field.name === 'mechPercentage' && agreementShareDisplayOptions != null && agreementShareDisplayOptions.ShowAgreementShares) {
                                        cellData = RepertoireHelper.trailingZeroesForShares(item['mechanicalShareToDisplay'], shareDecimalsToDisplay);
                                        alignRight = true
                                    }

                                    rowCells.push(
                                        <td key={key} className={`td ${alignRight ? 'alignRight' : ''}`} title={field.data}>
                                            {cellData}
                                        </td>
                                    );
                                }
                            }
                            else {
                                if (field.name === 'accountNumber') {
                                    if (isNaN(Number(item['ipiNumber']))) {
                                        rowCells.push(
                                            <td key={key} className={`td ${alignRight ? 'alignRight' : ''}`} title={field.data}>
                                                {item['ipiNumber']}
                                            </td>
                                        );
                                    }
                                    else
                                        rowCells.push(<td key={key} className="td" />)
                                }
                                else
                                    rowCells.push(<td key={key} className="td" />)
                            }
                        });

                        dependentSections.push(
                            <tr key={index + dependentFieldKey + i} className="tr dependent">
                                {rowCells}
                            </tr>
                        )
                    });
                }
            }

            return { sections, dependentSections };
        }
    };

    renderFieldOptions(field: IRepertoireField, tableContentItem: any, index: number): JSX.Element {
        const { dataGridTableData, stateKey, isReadOnly, dataSource, lookupValues, showModal, addNewAccount, openInNewTab, expandedWorkResults, expandMatchingWork, expandedProductResults, expandMatchingProduct } = this.props;
        return (stateKey === 'matchingRepertoireWorks' && (!expandedWorkResults || !expandedWorkResults.includes(index)) ?
            <td className="td" title={field.data}>
                <img
                    src="assets/right-arrow.svg"
                    title="Expand title"
                    alt="Right Arrow icon"
                    className={expandedWorkResults && expandedWorkResults.includes(index) ? "arrowIconExpanded" : "arrowIcon"}
                    onClick={() => expandMatchingWork(index)}
                />
                <FieldOptions
                    dataGridTableData={dataGridTableData}
                    stateKey={stateKey}
                    isReadOnly={isReadOnly}
                    dataSource={dataSource}
                    lookupValues={lookupValues}
                    field={field}
                    tableContentItem={tableContentItem}
                    index={index}
                    showModal={showModal}
                    showSearchModal={this.showSearchModal}
                    addNewAccount={addNewAccount}
                    changeTableData={this.changeTableData}
                    openInNewTab={openInNewTab}
                /></td> :
            stateKey === 'matchingRepertoireWorks' && (expandedWorkResults && expandedWorkResults.includes(index)) ?
                <td className="td" title={field.data}>
                    <img
                        src="assets/right-arrow.svg"
                        title="Expand title"
                        alt="Right Arrow icon"
                        className={expandedWorkResults && expandedWorkResults.includes(index) ? "arrowIconExpanded" : "arrowIcon"}
                        onClick={() => expandMatchingWork(index)}
                    />
                    <FieldOptions
                        dataGridTableData={dataGridTableData}
                        stateKey={stateKey}
                        isReadOnly={isReadOnly}
                        dataSource={dataSource}
                        lookupValues={lookupValues}
                        field={field}
                        tableContentItem={tableContentItem}
                        index={index}
                        showModal={showModal}
                        showSearchModal={this.showSearchModal}
                        addNewAccount={addNewAccount}
                        changeTableData={this.changeTableData}
                        openInNewTab={openInNewTab}
                    />
                    <TextDataInput
                        value={tableContentItem.name.length >= 2 ? tableContentItem.name[1].value : ''}
                        fieldName={stateKey}
                        changeData={null}
                        readOnly={true}
                        isHidden={false}
                    />
                    <TextDataInput
                        value={tableContentItem.name.length >= 3 ? tableContentItem.name[2].value : ''}
                        fieldName={stateKey}
                        changeData={null}
                        readOnly={true}
                        isHidden={false}
                    />
                </td> :
                stateKey === 'matchingRepertoireProducts' && (!expandedProductResults || !expandedProductResults.includes(index)) ?
                    <td className="td" title={field.data}>
                        <img
                            src="assets/right-arrow.svg"
                            title="Expand title"
                            alt="Right Arrow icon"
                            className={expandedProductResults && expandedProductResults.includes(index) ? "arrowIconExpanded" : "arrowIcon"}
                            onClick={() => expandMatchingProduct(index)}
                        />
                        <FieldOptions
                            dataGridTableData={dataGridTableData}
                            stateKey={stateKey}
                            isReadOnly={isReadOnly}
                            dataSource={dataSource}
                            lookupValues={lookupValues}
                            field={field}
                            tableContentItem={tableContentItem}
                            index={index}
                            showModal={showModal}
                            showSearchModal={this.showSearchModal}
                            addNewAccount={addNewAccount}
                            changeTableData={this.changeTableData}
                            openInNewTab={openInNewTab}
                        />
                    </td> :
                    stateKey === 'matchingRepertoireProducts' && (expandedProductResults && expandedProductResults.includes(index)) ?
                        <td className="td" title={field.data}>
                            <img
                                src="assets/right-arrow.svg"
                                title="Expand title"
                                alt="Right Arrow icon"
                                className={expandedProductResults && expandedProductResults.includes(index) ? "arrowIconExpanded" : "arrowIcon"}
                                onClick={() => expandMatchingProduct(index)}
                            />
                            <FieldOptions
                                dataGridTableData={dataGridTableData}
                                stateKey={stateKey}
                                isReadOnly={isReadOnly}
                                dataSource={dataSource}
                                lookupValues={lookupValues}
                                field={field}
                                tableContentItem={tableContentItem}
                                index={index}
                                showModal={showModal}
                                showSearchModal={this.showSearchModal}
                                addNewAccount={addNewAccount}
                                changeTableData={this.changeTableData}
                                openInNewTab={openInNewTab}
                            />
                            <TextDataInput
                                value={tableContentItem.name.length >= 2 ? tableContentItem.name[1].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={false}
                            />
                            <TextDataInput
                                value={tableContentItem.name.length >= 3 ? tableContentItem.name[2].value : ''}
                                fieldName={stateKey}
                                changeData={null}
                                readOnly={true}
                                isHidden={false}
                            />
                        </td> :
                        <td className="td" title={tableContentItem[field.name].value ? tableContentItem[field.name].value : field.data} >
                            <FieldOptions
                                dataGridTableData={dataGridTableData}
                                stateKey={stateKey}
                                isReadOnly={isReadOnly}
                                dataSource={dataSource}
                                lookupValues={lookupValues}
                                field={field}
                                tableContentItem={tableContentItem}
                                index={index}
                                showModal={showModal}
                                showSearchModal={this.showSearchModal}
                                addNewAccount={addNewAccount}
                                changeTableData={this.changeTableData}
                                openInNewTab={openInNewTab}
                            />
                        </td>
        );
    }

    getHeaderSections = () => {
        const { dataGridTableData, componentInstance, showRowNumber, allowMultiSelectChbk, isBulkCheckboxActive, usageType } = this.props;
        const { activeSortHeaderSection } = this.state;
        let currentComponentInstance = componentInstance;

        if (componentInstance === SET_LISTS_WORK_SEARCHVIEW_STATE_KEY) {
            currentComponentInstance = DRAFT_SET_LISTS_WORK_STATE_KEY;
        }
        const editableGridFields = currentComponentInstance === EDITABLE_FIELDS_GRID ? 'td col-lg-6 col-md-6 col-xs-6' : 'td'
        if (dataGridTableData && dataGridTableData.fields) {
            let filteredFields: IRepertoireField[] = dataGridTableData.fields.filter(
                (field: IRepertoireField) => field.componentInstance === currentComponentInstance);

            if (currentComponentInstance === CONTRIBUTORS_KEY || currentComponentInstance === IP_NAMES_KEY || currentComponentInstance === USAGE_ROWS_SECTION_KEY) {
                filteredFields = filteredFields.filter(x => !x.hidden);
            }
            let fieldsToRender = filteredFields;
            if ((currentComponentInstance === CONTRIBUTORS_KEY || currentComponentInstance === USAGE_ROWS_SECTION_KEY) && filteredFields.length > 0)
                fieldsToRender = ComponentsHelper.orderFieldsBySpecificField(filteredFields, 'order');

            const headerSections = fieldsToRender.map((section: IRepertoireField, index: number) => (

                <td key={section.name} className={`${editableGridFields} ${section.componentInstance === COMPONENTS_KEY && (section.name === 'title')
                    ? "col-lg-6 col-md-6 col-xs-6" : section.componentInstance === COMPONENTS_KEY && (section.name === 'number' || section.name === 'writer') ?
                        "col-lg-3 col-md-3 col-xs-3" : section.componentInstance === 'productContributor' && (section.name === 'avRipRoleCode' || section.name === 'name') ? 'col-lg-4 col-md-4 col-sm-4'
                            : EMPTY_STRING_VALUE}`}>
                    {(currentComponentInstance === DISTRIBUTION_POOLS_KEY && section.name === 'selected' && allowMultiSelectChbk === true) || (currentComponentInstance === POOL_WEIGHT_SETTINGS_KEY && section.name === 'copySection' && allowMultiSelectChbk === true) ?
                        <div className="headerCell ">
                            <CheckboxDataInput
                                label=""
                                fieldName=""
                                value={isBulkCheckboxActive}
                                changeData={(value, stateKey) =>
                                    this.changeTableDataForMultiSelect(value, section.name)
                                }
                                readonly={false}
                                isHidden={!allowMultiSelectChbk}
                            />
                        </div>
                        :
                        <TableHeaderSection
                            section={usageType === "SET LIST" ? this.changeDataForLivePerformance(section.data) : section.data}
                            fieldName={section.name}
                            isSortingActive={section.name === activeSortHeaderSection}
                            ascending={this.ascending}
                            onClickHeaderSection={this.sortTableByColumn}
                            sortable={(currentComponentInstance === EDITABLE_FIELDS_GRID) ? false : !isTableAction(section.fieldType)}
                        />
                    }
                </td>
            ));

            if (showRowNumber && currentComponentInstance !== IP_LICENSING_REPRESENTATION_KEY) {
                headerSections.splice(0, 0, <td key="rowNumber" title="Row Number"></td>);
            }
            return headerSections;
        }
    };

    showSearchModal = (modalProps: any, searchViewKey: string, index?: number) => {
        const { showModal, componentInstance, dataSource, stateKey } = this.props;
        if(stateKey!==IP_REPRESENTATION_KEY && stateKey!=='draftAgreementsApplicableWorks'){
            clearModalSearchResults();
        }

        if (componentInstance == PRODUCT_WORKS_KEY && dataSource == DataSource.Repertoire) {
            searchViewKey = PRODUCT_WORKS_REPERTOIRE_STATE_KEY;
        }
        else if (componentInstance == COMPONENTS_KEY && dataSource == DataSource.Repertoire) {
            searchViewKey = COMPONENTS_WORKS_REPERTOIRE_STATE_KEY;
        }
        else if (componentInstance == USAGE_MATCHING_WORKS_SECTION_KEY && stateKey == MATCHINGWORKS_KEY) {
            searchViewKey = USAGE_MATCHING_WORKS_SECTION_KEY;
        }
        else if (componentInstance == USAGE_MATCHING_WORKS_SECTION_KEY && stateKey == MATCHINGPRODUCTS_KEY) {
            searchViewKey = USAGE_MATCHING_PRODUCTS_SECTION_KEY;
        }
        if (searchViewKey == USAGE_MATCHING_WORKS_SECTION_KEY) {
            showModal(USAGESEARCH_VIEW, USAGE_MATCHING_WORKS_SECTION_KEY, modalProps, true, "Search Work To Match");
        }
        else if (searchViewKey == USAGE_MATCHING_PRODUCTS_SECTION_KEY) {
            showModal(USAGESEARCH_VIEW, USAGE_MATCHING_PRODUCTS_SECTION_KEY, modalProps, true, "Search Product To Match");
        }
        else if (stateKey === IP_REPRESENTATION_KEY && searchViewKey === 'Territory Include') {
            showModal(TERRITORY_SELECTION_VIEW, null, modalProps, true, "Territory Include", '', false, '', index)
        }
        else if (stateKey === IP_REPRESENTATION_KEY && searchViewKey === 'Territory Exclude') {
            showModal(TERRITORY_SELECTION_VIEW, null, modalProps, true, "Territory Exclude", null, null, null, index)
        }
        //else if (searchViewKey === SET_LISTS_WORK_SEARCHVIEW_STATE_KEY) {
        //         searchViewKey = DRAFT_WORKS_STATE_KEY;
        //     showModal(SEARCH_VIEW, searchViewKey, modalProps, true, "Search");
        // }
        else if (stateKey === "draftAgreementsComments"){
            showModal(DRAFT_AGREEMENTS_PAGE_VIEW, DRAFT_AGREEMENTS_PAGE_VIEW, modalProps, true, "Comments", '', false, 'modalSearch', index);
        }
        else if (stateKey === "DraftAgreementsMaintenanceAssigneeView"){
            showModal(SEARCH_VIEW, 'ips', modalProps, true, "Select IP", '', false, 'agreementsAssignee', index);
        }
        else {
            showModal(SEARCH_VIEW, searchViewKey, modalProps, true, "Search", null, null, null, index);
        }
    };

    dependentRowAction(tableContentItem: any) {
        const { dependentRowAction } = this.props;
        dependentRowAction(tableContentItem);
    }

    renderRowAction = (tableContentItem: any, field: IRepertoireField): JSX.Element => {
        const { stateKey, dependentRowAction, componentInstance, isReadOnly, tabs, activeTab, tableActions } = this.props;
        const tableActionName: ITableActionName = field.fieldType as ITableActionName;
        const tableAction = (tableActions || []).filter(e => e.name === tableActionName);
        let isDisabledSpecified = tableAction[0] ? tableAction[0].disabled !== undefined : false;
        switch (tableActionName) {
            case OPEN_ACTION: {
                return (
                    <td className="td" key={OPEN_ACTION}>
                        <div className="tableCell">
                            <IconTextButton
                                icon={"icon ms-Icon ms-Icon--OpenInNewTab"}
                                text={""}
                                onClick={() => this.onClickOpen(field.componentInstance, tableContentItem, field.data)}
                                disabled={isDisabledSpecified ? tableAction[0].disabled : isReadOnly}
                            />
                        </div>
                    </td>
                );
            }
            case ADD_NEW_ACTION: {
                return (
                    <td className="td" key={ADD_NEW_ACTION}>
                        <div className="tableCell">
                            <IconTextButton
                                icon={"icon ms-Icon ms-Icon--Delete"}
                                text={field.data}
                                onClick={() => this.onClickAdd()}
                                disabled={isReadOnly}
                            />
                        </div>
                    </td>
                );
            }
            case ADD_COVER_VERSION_ACTION: {
                return (
                    <td className="td" key={ADD_COVER_VERSION_ACTION}>
                        <div className="tableCell">
                            <IconTextButton
                                icon={"icon ms-Icon ms-Icon--Delete"}
                                text={field.data}
                                onClick={() => this.onClickAdd()}
                                disabled={isReadOnly}
                            />
                        </div>
                    </td>
                );
            }
            case ADD_NEW_DATE_ACTION: {
                return (
                    <td className="td" key={ADD_NEW_DATE_ACTION}>
                        <div className="tableCell">
                            <IconTextButton
                                icon={"icon ms-Icon ms-Icon--Delete"}
                                text={field.data}
                                onClick={() => this.onClickAdd()}
                                disabled={isReadOnly}
                            />
                        </div>
                    </td>
                );
            }

            case REMOVE_ACTION: {
                return (
                    <td className="td" key={REMOVE_ACTION}>
                        <div className="tableCell">
                            <IconTextButton
                                icon={"icon ms-Icon ms-Icon--Delete"}
                                text={"Remove"}
                                onClick={() => this.onClickRemove(tableContentItem)}
                                disabled={isReadOnly || (stateKey == DISTRIBUTION_POOLS_KEY && tableContentItem.status.value === 'Allocated')}
                            />
                        </div>
                    </td>
                );
            }
            case SEARCH_ACTION: {
                return (
                    <td className="td" key={SEARCH_ACTION}>
                        <div className="tableCell">
                            <IconTextButton
                                icon={"icon ms-Icon ms-Icon--Delete"}
                                text={field.data}
                                onClick={() => this.showSearchModal(tableContentItem, componentInstance)}
                                disabled={isReadOnly}
                            />
                        </div>
                    </td>
                );
            }
            case DEPENDENT_ROW_ACTION: {
                let disable = isReadOnly;
                if (componentInstance === CONTRIBUTORS_KEY) {
                    disable = tabs[activeTab].isReadonly;
                }
                return (
                    <td className="td" key={DEPENDENT_ROW_ACTION}>
                        <div className="tableCell">
                            <IconTextButton
                                icon={"icon ms-Icon ms-Icon--OpenInNewTab"}
                                text={field.data}
                                onClick={() => this.dependentRowAction.bind(this)(tableContentItem)}
                                disabled={disable}
                            />
                        </div>
                    </td>
                );
            }
            case CODE_ACTION: {
                const key = CODE_ACTION.concat('_').concat(tableContentItem.workSubmissionID).concat(field.name === WORK_SUBMISSION ? WORK_SUBMISSION : WORK_RESPONSE);
                const code = field.name === WORK_SUBMISSION ? tableContentItem.submission : tableContentItem.response;
                return (

                    <td className="td" key={key}>
                        <div className="tableCell hasCode">
                            <code>{code}</code>
                            <IconTextButton
                                icon={"icon ms-Icon ms-Icon--FullScreen"}
                                text=''
                                onClick={() => this.onOpenCodeModel(field.name === WORK_SUBMISSION ? tableContentItem.submission : tableContentItem.response, field.data)}
                                disabled={isReadOnly}
                            />
                        </div>
                    </td>
                );
            }
            case GROUPED_ACTION: {

                return (
                    <td className="td" key={field.name}>
                        <div className="tableCell">
                            {this.renderGroupedActions(tableContentItem, field)}
                        </div>
                    </td>
                );
            }
            case MERGE_ACTION:
                {
                    let disabled = false;
                    const { repertoireAgreementID } = tableContentItem;
                    disabled =!repertoireAgreementID.value;
                    return (
                        <td className="td" key={field.name}>
                            <div className="tableCell">
                        <button disabled={disabled} type="button" key={field.name} title="Merge" onClick={() => this.props.mergeActionClick(tableContentItem)} className="button btn-defaultSecondary mergeButton" >
                          Merge
                                </button>
                            </div>
                        </td>
                    );                    
                }
            default: {
                return null;
            }
        }
    };

    renderGroupedActions = (tableContentItem: any, field: IRepertoireField): JSX.Element => {
        const actions = JSON.parse(field.additionalData);
        const buttons: JSX.Element[] = [];
        const dropdowns: JSX.Element[] = [];
        let i = 0;

        let disabled = false;
        let disableAll = false;
        if (tableContentItem.isDefinite.value == true) {
            if (actions.hasOwnProperty(ManualMergeOptions.PostNew)) {
                delete actions[ManualMergeOptions.PostNew];
            }
        }
        else if (tableContentItem.isDefinite.value == false) {
            disabled = true;
        }

        if (tableContentItem.name.value == "" && tableContentItem.number.value == "") {
            disableAll = true;
        }

        if (actions) {
            for (const x in actions) {

                if (i === 0) {
                    buttons.push(<button disabled={disableAll} type="button" key={i} title={actions[x]} onClick={() => this.props.groupActionClick(x, tableContentItem)} className="button btn-defaultSecondary" ><div className='overflowEllipsis widthJoinedButton flexRow'>{actions[x]}</div></button>);
                    buttons.push(<button disabled={disableAll} title="More options" key={-1} type="button" className="button btn-defaultSecondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="caret"></span>
                        <span className="sr-only">Toggle Dropdown</span>
                    </button>);
                }
                else {
                    dropdowns.push(
                        <NavItem key={i}>
                            <NavLink disabled={disableAll} onClick={() => this.props.groupActionClick(x, tableContentItem)}>{actions[x]}</NavLink>
                        </NavItem>
                    )
                }
                i = i + 1;
            }
        }

        buttons.push(
            <ul className="dropdown-menu" key={-2}>
                {dropdowns}
            </ul>
        )
        return (
            <div className="btn-group splitButton">
                {buttons}
            </div>
        );
    }

    renderTableActions = () => {
        const { tableActions } = this.props;
        return (<div>
            {tableActions && tableActions.map((ta, index) => this.renderTableAction(ta, index))}
        </div>);
    }

    renderTableAction = (tableAction: ITableAction, index: number) => {
        const { componentInstance, dataGridTableData, isReadOnly, openWorkFlow, tableContents } = this.props;
        switch (tableAction.name) {
            case ADD_NEW_ACTION: {
                return (
                    <SizedActionButton key={index}
                        buttonText={dataGridTableData.fields.find(f => f.componentInstance === TABLE_ACTIONS_INSTANCE_KEY && f.name === ADD_NEW_ACTION).data}
                        buttonAction={this.onClickAdd}
                        disabled={tableAction.disabled || isReadOnly}
                    />
                );
            }
            case COPY_ACTION: {
                const isThereAtLeastOneCopyCheckBoxSelected = tableContents && tableContents.length > 0 && tableContents?.find(line => {
                    return line && line?.copySection?.value
                });
                return (
                    <SizedActionButton key={index}
                        buttonText={dataGridTableData.fields.find(f => f.componentInstance === TABLE_ACTIONS_INSTANCE_KEY && f.name === COPY_ACTION).data}
                        buttonAction={this.handleCopy}
                        disabled={tableAction.disabled || isReadOnly || !isThereAtLeastOneCopyCheckBoxSelected}
                    />
                );
            }
            case ADD_COVER_VERSION_ACTION: {
                return (
                    <SizedActionButton key={index}
                        buttonText={dataGridTableData.fields.find(f => f.componentInstance === TABLE_ACTIONS_INSTANCE_KEY && f.name === ADD_COVER_VERSION_ACTION).data}
                        buttonAction={this.onClickAdd}
                        disabled={tableAction.disabled || isReadOnly}
                    />
                );
            }
            case ADD_NEW_DATE_ACTION: {
                return (
                    <SizedActionButton key={index}
                        buttonText={dataGridTableData.fields.find(f => f.componentInstance === TABLE_ACTIONS_INSTANCE_KEY && f.name === ADD_NEW_DATE_ACTION).data}
                        buttonAction={this.onClickAdd}
                        disabled={tableAction.disabled || isReadOnly}
                    />
                );
            }

            case SEARCH_ACTION: {
                return (
                    <SizedActionButton key={index}
                        buttonText={dataGridTableData.fields.find(f => f.componentInstance === TABLE_ACTIONS_INSTANCE_KEY && f.name === SEARCH_ACTION).data}
                        buttonAction={() => this.showSearchModal(null, componentInstance)}
                        disabled={tableAction.disabled || isReadOnly || openWorkFlow}
                    />
                );
            }
            default: {
                return null;
            }
        }
    };

    renderHeader = () => {
        const {
            isExpandable
        } = this.props
        let sections = this.getHeaderSections();
        return <tr key="headerRow" className="tr">{isExpandable && <td />}{sections}</tr>;
    };

    handleDrop = (newOrder, e, droppedOnItem, tableContents) => {
        newOrder = newOrder - 1;

        e.target.classList.remove('js-active');
        const droppeddItem: IRepertoireField = JSON.parse(e.dataTransfer.getData('text/plain'));

        const dataTableFields: IRepertoireField[] = tableContents.filter(x => x.componentInstance === CONTRIBUTORS_KEY);

        let originalFieldPosition: number = 0;
        for (var i = 0; i < dataTableFields.length; i++) {
            if (dataTableFields[i].name === droppeddItem.name) {
                originalFieldPosition = i;
                break;
            }
        }

        this.changeTableData(droppedOnItem, EDITABLE_FIELDS_GRID, SEQUENCE_FIELD, originalFieldPosition, EDITABLE_FIELDS_GRID, newOrder);
    }

    handleDragLeave = (index, e, tableContentItem, tableContents) => {
        e.target.classList.remove('js-active');
    }

    handleDragEnd = (index, e, tableContentItem, tableContents) => {
        e.preventDefault();
        e.target.classList.add('js-active');
    }

    handleDragEnter = (index, e, tableContentItem, tableContents) => {
        e.preventDefault();
        e.target.classList.add('js-active');
    }

    handleDragOver = (index, e, tableContentItem, tableContents) => {
        e.preventDefault();
        e.target.classList.add('js-active');
    }

    handleDragStart = (index, e, draggedItem, tableContents) => {
        e.dataTransfer.setData('text/plain', JSON.stringify(draggedItem));
    }

    renderTableContents = () => {
        const { tableContents, isExpandedUsageMatchingSection, componentInstance, customFields, poolCode, sources, getUsagePool, pool, dataGridTableData, usagePoolsSearchResults, isExpandable, datesAndDimensions, stateKey, changeData, expandedUsageDetailsRow, addUsageDetailsShareIPRow, lookupValues, showModal, portalType, resetMessageBanner, openWorkFlow } = this.props;
        const { focusedRow } = this.state;
        const rows: JSX.Element[] = [];
        if (tableContents) {
            let fieldsToRender: any = tableContents;
            if (componentInstance === 'poolWeightSettings') {
                tableContents.map(element => {
                    if (datesAndDimensions.filter((word) => word.effectiveDateRange === element.effectiveDateRange.value)) {
                        const result = datesAndDimensions.filter((word) => word.effectiveDateRange === element.effectiveDateRange.value);
                        if (result.length > 0) {
                            element.use.inputType = result[0].use ? TEXT_INPUT : READ_ONLY_INPUT;
                            element.usageDimensionOne.inputType = result[0].dimension1 ? TEXT_INPUT : READ_ONLY_INPUT;
                            element.usageDimensionTwo.inputType = result[0].dimension2 ? TEXT_INPUT : READ_ONLY_INPUT;
                            element.usageDimensionThree.inputType = result[0].dimension3 ? TEXT_INPUT : READ_ONLY_INPUT;
                            element.usageDimensionFour.inputType = result[0].dimension4 ? TEXT_INPUT : READ_ONLY_INPUT;
                            element.usageDimensionFive.inputType = result[0].dimension5 ? TEXT_INPUT : READ_ONLY_INPUT;
                            element.fromTime.inputType = result[0].time ? TIME_INPUT : READ_ONLY_INPUT;
                            element.toTime.inputType = result[0].time ? TIME_INPUT : READ_ONLY_INPUT;
                            element.musicSource.inputType = result[0].musicSource ? TEXT_INPUT : READ_ONLY_INPUT;
                        }
                    }
                })
            }
            if (componentInstance === CONTRIBUTORS_KEY || componentInstance === EDITABLE_FIELDS_GRID || componentInstance === DRAFT_AGREEMENTS_COMMENTS_STATE_KEY) {
                let fields: any[] = [];
                if (fieldsToRender.length === undefined) {
                    Object.keys(fieldsToRender).map(function (key) {
                        fields.push(fieldsToRender[key])
                    })
                    fieldsToRender = ComponentsHelper.orderFieldsBySpecificField(fields as IRepertoireField[], 'order');
                }
                else {
                    fieldsToRender = ComponentsHelper.orderFieldsBySpecificField(fieldsToRender as IRepertoireField[], 'order');
                }
            }
            fieldsToRender && fieldsToRender.map((tableContentItem: any, index: any) => {
                const items = this.getTableSections(tableContentItem, index);
                const sections: JSX.Element[] = items.sections;
                const dependentSections: JSX.Element[] = items.dependentSections;
                if (componentInstance != "contributors" && isTableAction(tableContentItem.value)) {
                    sections.push(this.renderRowAction(tableContentItem, index))
                }
                else if (componentInstance === "instrumentation" && tableContentItem.type.value === 'CUSTOM' && portalType !== MEMBERS_PORTAL) {
                    if (!customFields || !customFields.includes(index)) {
                        this.props.enableCustomField(index);
                    }
                } else if (componentInstance === "instrumentation" && tableContentItem.type.value !== 'CUSTOM' && portalType !== MEMBERS_PORTAL) {
                    if (customFields && customFields.includes(index)) {
                        this.props.enableCustomField(index);
                    }
                }
                else {
                    sections[sections.findIndex(e => e.key === "remove")] = this.renderRowAction(tableContentItem, index);
                }


                if (componentInstance === EDITABLE_FIELDS_GRID) {
                    rows.push(
                        <div key={index++} draggable={true} className="row daggableItem"
                            onDrop={e => this.handleDrop(index, e, tableContentItem, tableContents)}
                            onDragLeave={e => this.handleDragLeave(index, e, tableContentItem, tableContents)}
                            onDragEnd={e => this.handleDragEnd(index, e, tableContentItem, tableContents)}
                            onDragOver={e => this.handleDragOver(index, e, tableContentItem, tableContents)}
                            onDragEnter={e => this.handleDragEnter(index, e, tableContentItem, tableContents)}
                            onDragStart={e => this.handleDragStart(index, e, tableContentItem, tableContents)}>
                            {sections}
                        </div>

                    )

                } else if (componentInstance === USAGE_ROWS_SECTION_KEY) {
                    let usageGridData = dataGridTableData;
                    if (pool && pool.datesAndDimensions.length > 0) {
                        pool.datesAndDimensions.map(element => {
                            var startDate = new Date(element.effectiveFrom);
                            var endDate = element.effectiveTo != null ? new Date(element.effectiveTo) : new Date();
                            var dateToCheck = new Date(tableContentItem.usageDate.value);
                            if (dateToCheck >= startDate && dateToCheck <= endDate) {
                                tableContentItem.use.inputType = element.use ? 'text' : 'row';
                                tableContentItem.dimension1.inputType = element.dimension1 ? 'text' : 'row';
                                tableContentItem.dimension2.inputType = element.dimension2 ? 'text' : 'row';
                                tableContentItem.dimension3.inputType = element.dimension3 ? 'text' : 'row';
                                tableContentItem.dimension4.inputType = element.dimension4 ? 'text' : 'row';
                                tableContentItem.dimension5.inputType = element.dimension5 ? 'text' : 'row';
                            }
                            else {
                                tableContentItem.use.inputType = 'row';
                                tableContentItem.dimension1.inputType = 'row';
                                tableContentItem.dimension2.inputType = 'row';
                                tableContentItem.dimension3.inputType = 'row';
                                tableContentItem.dimension4.inputType = 'row';
                                tableContentItem.dimension5.inputType = 'row';
                            }
                        })
                    } else {
                        tableContentItem.use.inputType = 'row';
                        tableContentItem.dimension1.inputType = 'row';
                        tableContentItem.dimension2.inputType = 'row';
                        tableContentItem.dimension3.inputType = 'row';
                        tableContentItem.dimension4.inputType = 'row';
                        tableContentItem.dimension5.inputType = 'row';
                    }
                    rows.push(
                        <UsageRowView
                            onRowClick={() => this.onRowClick(index)}
                            key={index}
                            usage={tableContentItem}
                            index={index}
                            dataGridTableData={usageGridData}
                            lookupValues={lookupValues}
                            changeData={changeData}
                            showModal={showModal}
                            addUsageDetailsShareIPRow={addUsageDetailsShareIPRow}
                            tableContents={tableContents}
                            resetMessageBanner={resetMessageBanner}
                            focusedRowNumber={focusedRow}
                            expandedUsageDetailsRow={expandedUsageDetailsRow}
                        />
                    );
                }
                else if (componentInstance === USAGE_MATCHING_WORKS_SECTION_KEY || componentInstance === USAGE_MATCHING_PRODUCTS_SECTION_KEY) {
                    rows.push(
                        <DataGridTableRow
                            onRowClick={() => this.onRowClick(index)}
                            dataGridTableData={dataGridTableData}
                            stateKey={stateKey}
                            componentInstance={componentInstance}
                            tableContentsItem={tableContentItem}
                            key={index}
                            index={index}
                            changeData={changeData}
                            tableContents={tableContents}
                            isExpandable={isExpandable}
                            dependentRowAction={this.dependentRowAction.bind(this)}
                            clearAllRowsButThis={this.props.clearAllRowsButThis.bind(this)}
                            openWorkFlow={openWorkFlow}
                            focusedRowNumber={focusedRow}
                            isExpandedUsageMatchingSection={isExpandedUsageMatchingSection}
                        />
                    );
                }
                else if (componentInstance === IP_LICENSING_REPRESENTATION_KEY) {
                    rows.push(
                        <DataGridTableRow
                            onRowClick={() => this.onRowClick(index)}
                            dataGridTableData={dataGridTableData}
                            stateKey={stateKey}
                            componentInstance={componentInstance}
                            tableContentsItem={tableContentItem}
                            key={index}
                            index={index}
                            changeData={changeData}
                            tableContents={tableContents}
                            isExpandable={isExpandable}
                            dependentRowAction={this.dependentRowAction.bind(this)}
                            updateIpRepresentationsIdsToRemove={this.updateIpRepresentationsIdsToRemove.bind(this)}
                            focusedRowNumber={focusedRow}
                        />
                    );
                }
                else if (componentInstance === USAGE_PRODUCT_DETAILS_ROWS_SECTION_KEY) {
                    rows.push(
                        <DataGridTableRow
                            onRowClick={() => this.onRowClick(index)}
                            dataGridTableData={dataGridTableData}
                            stateKey={stateKey}
                            componentInstance={componentInstance}
                            tableContentsItem={tableContentItem}
                            key={index}
                            index={index}
                            tableContents={tableContents}
                            isExpandable={isExpandable}
                            focusedRowNumber={focusedRow}
                        />
                    );
                }
                else {
                    rows.push(<tr key={index} onClick={() => this.onRowClick(index)} className={`tr ${focusedRow === index && 'focusedTableRow'}`}>{sections}</tr>)
                }
                rows.push(...dependentSections);
            });
        }

        return rows;
    };

    renderTableContentByComponent() {
        const { componentInstance, stateKey } = this.props;

        if (componentInstance === EDITABLE_FIELDS_GRID) {
            return (
                <div className="dragContainer">
                    {this.renderTableContents()}
                </div>
            )
        }
        else {
            return (
                <tbody key="body" className="tbody ">
                    {this.renderTableContents()}
                </tbody>
            )
        }
    }

    renderTableByComponent() {
        const { componentInstance, stateKey } = this.props;
        if (componentInstance === EDITABLE_FIELDS_GRID) {
            return (
                <>
                    <table className={["table", stateKey].join(" ")}>
                        <thead key="thead" className={"thead"}>
                            {this.renderHeader()}
                        </thead>
                    </table>
                    {this.renderTableContentByComponent()}
                </>
            )
        }
        else {
            return (
                <table className={["table", stateKey].join(" ")}>
                    <thead key="thead" className={"thead"}>
                        {this.renderHeader()}
                    </thead>
                    {this.renderTableContentByComponent()}
                </table>
            )
        }
    }

    render() {
        const { componentInstance } = this.props;
        const { focusedRow } = this.state;
        let className: string = 'tableContainerModal';

        if (componentInstance === 'contributors') {
            className = 'tableContainer';
        }
        else if (componentInstance !== EDITABLE_FIELDS && componentInstance !== EDITABLE_FIELDS_GRID) {
            className = 'row tableContainer';
        }
        const isEditableTable = (componentInstance === EDITABLE_FIELDS || componentInstance === EDITABLE_FIELDS_GRID) ? true : false;
        return (
            <>
                <div key="table" className={className} ref={this.componentRef}>
                    <ListenToShortCutKeysDataGridTable
                        showSearchModal={this.showSearchModalShortCutKey.bind(this)}
                        addRow={this.addRowShortCutKeys.bind(this)}
                        deleteRow={this.deleteRowShortCutKeys.bind(this)}
                        handleClickOutside={this.handleClickOutside.bind(this)} />
                    <div className="col-md-12">
                        {this.renderTableByComponent()}
                    </div>
                </div>
                <If condition={!isEditableTable}>
                    <div key="actions container" className="row tableContainer">
                        {this.renderTableActions()}
                    </div>
                </If>
            </>
        );
    }
}



