import * as React from "react";
import { If } from "../../../../core/components/If";
import { CONTRIBUTOR_MEMBER_TYPE_LOOKUP, LICENSING_USE_TYPE_LOOKUP, SET_TYPE_GROUP_LOOKUP, SET_TYPE_LOOKUP, SHARE_RIGHT_TYPE_LOOKUP, WORK_SUBJECT_LOOKUP, WORK_TYPE_LOOKUP } from "../../../../lookup/Consts";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { showModal } from "../../../../redux/reducers/ModalReducer";
import { sortDistributionPoolFieldData, updateDistributionField } from "../../../../redux/reducers/RepertoireReducer";
import { IDistributionPoolState, IDistributionPoolStateKeys } from "../../../../redux/types/IDistributionPoolState";
import { IDistributionStateKeys } from "../../../../redux/types/IDistributionState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { MessageBanner, MessageType } from "../../../components/messageBanner/MessageBanner";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { ADD_NEW_ACTION, DISTRIBUTION_POOLS_KEY, DISTRIBUTION_SETTINGS_KEY, OPEN_ACTION, DISTRIBUTION_SET_TYPE_GROUP_STATE_KEY, DISTRIBUTION_SHARE_RIGHT_TYPE_STATE_KEY, DISTRIBUTION_SUBJECTS_KEY, DISTRIBUTION_SUBJECT_WORK_TYPE_STATE_KEY, DISTRIBUTION_SUBJECT_RIGHT_TYPE_STATE_KEY, DISTRIBUTION_SUBJECT_LICENSING_TYPE_STATE_KEY, DISTRIBUTION_SUBJECT_MEMBER_TYPE_STATE_KEY, IS_DISPLAY, IS_ACTIVE, IS_MUSIC, SHEET_MUSIC_WORK_TYPE_CODE, SUBJECT_TYPE, DISTRIBUTION_NO_DISTRIBUTION_TYPE_AVAILABLE_STATE_KEY, MAGAZINE_WORK_TYPE_CODE, IS_MAGAZINE_SUBJECT } from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IDistributionSettings } from "../../../types/usageTypes/IDistributionSettings";
import { IDistributionSubjectTypes } from "../../../types/usageTypes/IDistributionSubjectTypes";
import { ILastPercentageSearchQuery } from "../../../types/usageTypes/ILastPercentageSearchQuery";
import { ISubjects } from "../../../types/usageTypes/ISubjects";
import { IUsagePool } from "../../../types/usageTypes/IUsagePool";
import { IUsagePoolSearchQuery } from "../../../types/usageTypes/IUsagePoolSearchQuery";
import { ITreeData } from "../../../types/ITreeData";
import { IUsagePoolWeightSettingsStateKeys } from "../../../types/usageTypes/IUsagePoolWeightSettings";

export interface IDistributionMaintenanceGridsViewProps {
    distributionMaintenanceGridsViewData: IRepertoireComponentDataItem,
    dataGridTableData?: IRepertoireComponentDataItem;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    updateDistributionField: typeof updateDistributionField;
    distributionPools: IDistributionPoolState[];
    searchUsagePools: (query: IUsagePoolSearchQuery) => void;
    usagePools: IUsagePool[];
    showModal: typeof showModal;
    distributionSettings: IDistributionSettings;
    distributionSubjectTypes: IDistributionSubjectTypes;
    subjects: ISubjects[]
    isNewDistribution?: boolean;
    getLastPercentageValues: (lastPercentageSearchQuery: ILastPercentageSearchQuery, lookups: ILookupDictionary) => void;
    isBulkCheckboxActive?:boolean;
    changeBulkCheckbox?: (value: boolean) => void;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    resetMessageBanner?: () => void;
    incrementTempID?: () => void;
    usagePoolsSearchResults?: IUsagePool[];
    sources?: ITreeData[];
    getUsagePool: (poolId: number, matchingSources: ITreeData[]) => void;
    customer?: string;
    sortDistributionPoolFieldData?: typeof sortDistributionPoolFieldData;
}

interface IDistributionMaintenanceGridsViewState {
    activeHeaderSection: string;
    loaded: boolean;
}

export class DistributionMaintenanceGridsView extends React.Component<
    IDistributionMaintenanceGridsViewProps,
    IDistributionMaintenanceGridsViewState
> {
    invalidCD = false;
    viewData;
    constructor(props: IDistributionMaintenanceGridsViewProps) {
        super(props);
        this.state = {
            activeHeaderSection: DISTRIBUTION_POOLS_KEY,
            loaded: false
        };
        this.viewData = {};
    }

    componentDidUpdate() {
        this.loadData();
        this.loadSubjects();
    }

    componentDidMount() {
        this.loadData();
        this.loadSubjects();

        const {
            searchUsagePools,
            dataGridTableData: { fields },
            distributionMaintenanceGridsViewData
        } = this.props;
        const query: IUsagePoolSearchQuery = {
            poolCode: "",
            cat1: "",
            cat2: "",
            allocationRule: ""
        }
        searchUsagePools(query);

        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
            const noSubject = distributionMaintenanceGridsViewData.fields.find(x => x.name == DISTRIBUTION_NO_DISTRIBUTION_TYPE_AVAILABLE_STATE_KEY);
            if(noSubject)
                this.viewData[noSubject.name] = noSubject.data;
        }
    }

    loadData() {
        const { loaded } = this.state;
        if (!loaded) {
            if (!this.props.distributionMaintenanceGridsViewData) {
                return;
            }
            else {
                this.setState({ loaded: true });
            }
        }
    }

    loadSubjects(){
        const {distributionMaintenanceGridsViewData, subjects} = this.props

        if(distributionMaintenanceGridsViewData.fields.some(f => f.name == DISTRIBUTION_SUBJECTS_KEY)){
            if(subjects == null){
                const subject: ISubjects[] = [];
                this.changeData(subject, "subjects")
            }
        }
    }

    changeData = (value: any, name: IRepertoireStateKeys, objectKey?: string) => {
        const {lookupValues,  updateDistributionField, usagePools, distributionSettings } = this.props;

        if (name !== DISTRIBUTION_SETTINGS_KEY && name !== DISTRIBUTION_SUBJECTS_KEY && name != "subjects") {
            this.invalidCD = false;
            value = value.map(v => {
                if(v.continuallyDistribute.value && !v.nominalValuePerPoint.value){
                    this.invalidCD = true;
                    v.continuallyDistribute.value = false;
                }
                v.description.value = !!v.poolCode.value
                    ? usagePools
                        .filter(up => up.poolCode === v.poolCode.value)
                        .map(up => up.description)[0]
                    : "";
                return v;
            });
        }
        updateDistributionField(value, name as IDistributionStateKeys, lookupValues, objectKey);
    }

    changeDistributionSettingsData = (value: any, name: IRepertoireStateKeys) => {
        const { lookupValues, updateDistributionField, usagePools, distributionSettings } = this.props;
        let updatedDistributionSettings = distributionSettings;
        updatedDistributionSettings[name].value = value;

        this.changeData(updatedDistributionSettings, DISTRIBUTION_SETTINGS_KEY)
    }

    changeDistributionSubjectsData = (value: any, name: IRepertoireStateKeys) => {
        const { lookupValues, distributionSubjectTypes} = this.props;
        let updatedDistributionSubjects = distributionSubjectTypes;
        updatedDistributionSubjects[name].value = value;

        if(name === DISTRIBUTION_SUBJECT_WORK_TYPE_STATE_KEY && value )
            {
                const workSubjectTypes = getLookupValues(WORK_SUBJECT_LOOKUP, lookupValues);
                let subjectValues;
                if (value === SHEET_MUSIC_WORK_TYPE_CODE && workSubjectTypes) {
                    subjectValues = workSubjectTypes.filter(type => type.extraFields[IS_ACTIVE] === true && type.extraFields[IS_MUSIC] === true);
                } else if (value === MAGAZINE_WORK_TYPE_CODE && workSubjectTypes) {
                    subjectValues = workSubjectTypes.filter(type => type.extraFields[IS_MAGAZINE_SUBJECT] === true && type.extraFields[IS_ACTIVE] === true && type.extraFields[IS_MUSIC] === false)
                } else {
                    subjectValues = workSubjectTypes.filter(type => type.extraFields[IS_ACTIVE] === true && type.extraFields[IS_MUSIC] === false && type.extraFields[IS_MAGAZINE_SUBJECT] !== true );
                }

                const subject: ISubjects[] = [];
                subjectValues.map((s, i) => subject.push({
                    subjectId: 0,
                    code: s.code,
                    value: null,
                    name: s.description
                }));

                this.changeData(subject, 'subjects')
            }

        this.changeData(updatedDistributionSubjects, DISTRIBUTION_SUBJECTS_KEY)
    }
    
    changeSubjectsFields = ( value: any, fieldName: string, index: number) => {
        
        const { subjects} = this.props;

        let updatedDistributionSubjects = subjects;
        updatedDistributionSubjects[index].value = value;
        
        this.changeData(updatedDistributionSubjects, "subjects");
        
    };

    filterSetTypeLookupValues = () => {
        const { lookupValues} = this.props;
        let allSetTypeLookupValues = getLookupValues(SET_TYPE_LOOKUP, lookupValues);
        return allSetTypeLookupValues.filter(x => x.typeID < 0)
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };
    
    workTypeDropDownLookup = () : ILookupInstance[]=> {
        const { lookupValues} = this.props;
        const workTypes = getLookupValues(WORK_TYPE_LOOKUP, lookupValues);
        let options =  workTypes ? workTypes.filter(type => type.extraFields[IS_DISPLAY] === true ) : null
        return options;
    }
    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { distributionMaintenanceGridsViewData } = this.props;

        if (distributionMaintenanceGridsViewData && distributionMaintenanceGridsViewData.fields) {
            const fields = distributionMaintenanceGridsViewData.fields.filter(f => f.fieldType === null && f.name != DISTRIBUTION_NO_DISTRIBUTION_TYPE_AVAILABLE_STATE_KEY);
            return fields.map((section, index) => (
                <div
                    className={[
                        "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                    ].join(" ")}
                    key={index}
                    onClick={() => this.onClickHeaderSection(section.name)}
                >
                    {section.data}
                </div>
            ));
        }
    };

    searchLastPercentageValues =() => {
        const { distributionSubjectTypes, getLastPercentageValues, lookupValues, distributionSettings } = this.props;

        const searchQuery: ILastPercentageSearchQuery = {
            workType: distributionSubjectTypes.distributionSubjectWorkType.value,
            licensingUseType: distributionSubjectTypes.distributionSubjectLicensingUseType.value,
            memberType: distributionSubjectTypes.distributionSubjectMemberType.value,
            rightType: distributionSettings.distributionSettingsShareRightType.value
        }

        getLastPercentageValues(searchQuery, lookupValues)
    }

    renderLastPercentageButton = () => {
        const { subjects, distributionSubjectTypes, distributionSettings } = this.props;

        if(subjects){
            const isDisabled : boolean = (distributionSubjectTypes.distributionSubjectWorkType.value && distributionSubjectTypes.distributionSubjectLicensingUseType.value
                                        && distributionSubjectTypes.distributionSubjectMemberType.value && distributionSettings.distributionSettingsShareRightType.value) ? false : true

            return  <div className="form-group col-md-3 col-sm-6 col-xs-6" style={{ padding: "inherit" }}>
                        <div className="form-group col-md-4 col-md-push-2 col-sm-6 col-xs-12 distributionSubjectInput">
                            <button onClick={isDisabled ? null  : this.searchLastPercentageValues}
                                className={isDisabled ? 'disabledButton': 'enabledButton'}>
                                Get last percentage value
                            </button>
                        </div>
                    </div>
            
        }
    }
    renderSubjects = () => {
        const { subjects } = this.props;

        if (subjects) {

            return subjects.map((subject, index) => (
                <SizedTextDataInput
                                fieldType={"integer"}
                                label={subject.name + " - " + subject.code}
                                fieldName={subject.code}
                                changeData={(value) =>
                                    this.changeSubjectsFields(value, subject.name, index)} 
                                value ={subjects[index].value}
                                readOnly={false}
                                isHidden={false}
                            />
            ));
        }
    };

    openInNewTab(openItem: any): void {
        const {usagePoolsSearchResults,getUsagePool,sources} = this.props;
        if(usagePoolsSearchResults){
            const findX=usagePoolsSearchResults.find(x => x.poolCode===openItem.value)
            getUsagePool(findX.poolId, sources);
        }
    }
    sortData(value: any, name: IRepertoireStateKeys): void {
        this.props.sortDistributionPoolFieldData(value, name as IDistributionPoolStateKeys);
    }


    render() {
        const {
            isReadonly,
            lookupValues,
            dataGridTableData,
            distributionPools,
            usagePools,
            showModal,
            distributionSettings,
            distributionSubjectTypes,
            isNewDistribution,
            isBulkCheckboxActive,
            changeBulkCheckbox,
            tabs,
            activeTab,
            resetMessageBanner,
            incrementTempID,
            customer
        } = this.props;

        const {
            activeHeaderSection,
            loaded
        } = this.state;

        if (!loaded)
            return <div className="loading" />

        return (
            <div className="GridsView">
                <div className="headerContainer">
                    {this.renderGridViewHeaderSections()}
                </div>
                
                {this.invalidCD ?
                    <div>
                    <MessageBanner messageType={MessageType.Danger}
                        messages={["To use Continuous Distribution, there must be a value in Value Per Point To Allocate."]}
                        resetMessageBanner={resetMessageBanner}
                    />
                </div>
                :null}


                <If condition={tabs[activeTab].showSearchLastPercentageWarningBanner === undefined ? false : tabs[activeTab].showSearchLastPercentageWarningBanner}>
                    <div>     
                        <MessageBanner messageType={MessageType.Warning}
                            messages={[this.viewData[DISTRIBUTION_NO_DISTRIBUTION_TYPE_AVAILABLE_STATE_KEY]]}
                            resetMessageBanner={resetMessageBanner}
                        />
                    </div>
                </If>

                {activeHeaderSection === DISTRIBUTION_POOLS_KEY ?
                    <DataGridTable
                        tableContents={distributionPools}
                        changeData={this.changeData.bind(this)}
                        dataGridTableData={dataGridTableData}
                        stateKey={DISTRIBUTION_POOLS_KEY}
                        componentInstance={DISTRIBUTION_POOLS_KEY}
                        showRowNumber={false}
                        isReadOnly={isReadonly}
                        tableActions={[{ name: ADD_NEW_ACTION }, { name: OPEN_ACTION, disabled: false }]}
                        lookupValues={lookupValues}
                        poolCodes={!!usagePools ? usagePools.map(up => up.poolCode) : null}
                        showModal={showModal}
                        allowMultiSelectChbk={true}
                        isBulkCheckboxActive={isBulkCheckboxActive}
                        changeBulkCheckbox={changeBulkCheckbox}
                        tabs={tabs}
                        activeTab={activeTab}
                        incrementTempID={incrementTempID}
                        openInNewTab={this.openInNewTab.bind(this)}
                        customer={customer}
                        sortData={this.sortData.bind(this)}
                    /> : <div> </div>}
                {activeHeaderSection === DISTRIBUTION_SETTINGS_KEY ?
                    <div className="row" style={{ paddingTop: "20px" }}>
                        <SizedDropdownDataInput
                            label={this.viewData[DISTRIBUTION_SET_TYPE_GROUP_STATE_KEY]}
                            fieldName={DISTRIBUTION_SET_TYPE_GROUP_STATE_KEY}
                            value={distributionSettings.distributionSettingsSetTypeGroup.value}
                            changeData={this.changeDistributionSettingsData.bind(this)}
                            options={getLookupValues(SET_TYPE_GROUP_LOOKUP, lookupValues)}
                            allowNull={true}
                            readOnly={!isNewDistribution}
                            isHidden={false}
                        />
                        <SizedDropdownDataInput
                            label={this.viewData[DISTRIBUTION_SHARE_RIGHT_TYPE_STATE_KEY]}
                            fieldName={DISTRIBUTION_SHARE_RIGHT_TYPE_STATE_KEY}
                            value={distributionSettings.distributionSettingsShareRightType.value}
                            changeData={this.changeDistributionSettingsData.bind(this)}
                            options={getLookupValues(SHARE_RIGHT_TYPE_LOOKUP, lookupValues)}
                            allowNull={true}
                            readOnly={!isNewDistribution}
                            isHidden={false}
                        />
                     </div> : <div> </div>}
                    {activeHeaderSection === DISTRIBUTION_SUBJECTS_KEY ?
                    <div>
                    <div className="row" style={{ paddingTop: "20px" }}>
                        <SizedDropdownDataInput
                            label={this.viewData[DISTRIBUTION_SUBJECT_WORK_TYPE_STATE_KEY]}
                            fieldName={DISTRIBUTION_SUBJECT_WORK_TYPE_STATE_KEY}
                            value={distributionSubjectTypes.distributionSubjectWorkType.value}
                            changeData={this.changeDistributionSubjectsData.bind(this)}
                            options={this.workTypeDropDownLookup()}
                            allowNull={true}
                            readOnly={!isNewDistribution}
                            isHidden={false}
                            componentInstance={DISTRIBUTION_SUBJECTS_KEY}
                        />
                        <SizedDropdownDataInput
                            label={this.viewData[DISTRIBUTION_SUBJECT_LICENSING_TYPE_STATE_KEY]}
                            fieldName={DISTRIBUTION_SUBJECT_LICENSING_TYPE_STATE_KEY}
                            value={distributionSubjectTypes.distributionSubjectLicensingUseType.value}
                            changeData={this.changeDistributionSubjectsData.bind(this)}
                            options={getLookupValues(LICENSING_USE_TYPE_LOOKUP, lookupValues)}
                            allowNull={true}
                            readOnly={!isNewDistribution}
                            isHidden={false}
                            componentInstance={DISTRIBUTION_SUBJECTS_KEY}
                        />
                        <SizedDropdownDataInput
                            label={this.viewData[DISTRIBUTION_SUBJECT_MEMBER_TYPE_STATE_KEY]}
                            fieldName={DISTRIBUTION_SUBJECT_MEMBER_TYPE_STATE_KEY}
                            value={distributionSubjectTypes.distributionSubjectMemberType.value}
                            changeData={this.changeDistributionSubjectsData.bind(this)}
                            options={getLookupValues(CONTRIBUTOR_MEMBER_TYPE_LOOKUP, lookupValues)}
                            allowNull={true}
                            readOnly={!isNewDistribution}
                            isHidden={false}
                            componentInstance={DISTRIBUTION_SUBJECTS_KEY}
                        />
                        {this.renderLastPercentageButton()}
                    </div>
                    <hr></hr>
                    <div className="row"> 
                        {this.renderSubjects()}
                    </div>
                    </div> : <div> </div>}
            </div>
        );

    }
}