import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IAppState from "../../../redux/types/IAppState";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export interface IDateDisplayProps {
    value: any;
    customerDatePreference?: string;
    currentFormat?: string;
    showTime?: boolean;
}

export const YEAR_DAY_MONTH_DATE_FORMAT = "YYYY/DD/MM";

const DateDisplay:  React.FC<IDateDisplayProps> = ({ value, customerDatePreference, currentFormat, showTime }) => {
        const [dateToDisplay, setDateToDisplay] = useState('');

        useEffect(() => {
            let newValue = value;
            if(value && value?.value) {
                newValue = value?.value;
            }
            formatDate(newValue)
        }, [value]);

        const EUROPEAN_DATE_FORMAT = "DD/MM/YYYY";
        let preferenceDateFormat = customerDatePreference ? customerDatePreference : EUROPEAN_DATE_FORMAT;
        preferenceDateFormat = `${preferenceDateFormat}${showTime ? " HH:mm" : ''}`
        const formatDate = (value) => {
            let date = dayjs(value, currentFormat)
            let dateString = '';
            if (value) {
                dateString = date.isValid() ? date.utc().format(preferenceDateFormat) : '';
            }
            setDateToDisplay(dateString)
        }


        return (
            <div className={'flexColumn'} >
                {dateToDisplay}
            </div>
        );
}

export default connect((state: IAppState) => {
    return ({
    customerDatePreference: state.account.customerDate,
})
})(DateDisplay);
