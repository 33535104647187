import * as React from "react";
import { IClaimState, IClaimStateKeys } from "../../../../redux/types/IClaimState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { MEMBER_NUMBER_STATE_KEY, RIGHTHOLDER_ID_STATE_KEY, RIGHTHOLDER_NAME_STATE_KEY } from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { getLookupValues, mapLookupKey } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { updateClaimField } from "../../../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IDistributionState } from "../../../../redux/types/IDistributionState";
import SizedDateDataInput from "../../../components/dateDataInput/DateDataInput";

export interface IClaimMaintenanceGeneralDataViewProps {
    claimMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    claim: IClaimState,
    updateClaimField: typeof updateClaimField;
    activeTab: number;
    tabs: ITabReduxItem[];
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    componentInstance: string;
    openDistributions: IDistributionState[];
    getOpenDistributions: () => void;
}

interface IClaimMaintenanceGeneralDataViewState {
    loaded: boolean;
}

export class ClaimMaintenanceGeneralDataView extends React.PureComponent<
    IClaimMaintenanceGeneralDataViewProps,
    IClaimMaintenanceGeneralDataViewState
> {
    viewData;
    constructor(props: IClaimMaintenanceGeneralDataViewProps) {
        super(props);
        this.state = {
            loaded: false
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            claimMaintenanceGeneralDataViewData: { fields },
            openDistributions,
            getOpenDistributions
        } = this.props
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
        if (!openDistributions || openDistributions.length === 0) getOpenDistributions();
    }

    changeData = (value: any, name: IRepertoireStateKeys) => {
        const { updateClaimField } = this.props;
        updateClaimField(value, name as IClaimStateKeys);
    }
    renderClaimGeneralTitleField = () => {
        const { claimMaintenanceGeneralDataViewData } = this.props;

        if (claimMaintenanceGeneralDataViewData && claimMaintenanceGeneralDataViewData.fields) {
            return claimMaintenanceGeneralDataViewData.fields.filter(f => f.fieldType === null).map((section, index) => (                
                    section.data
            ));
        }
    }

    renderClaimGeneralFields = () => {
        const { claimMaintenanceGeneralDataViewData, lookupValues, claim, openDistributions } = this.props;

        let componentInstance = this.props.componentInstance;

        if (claimMaintenanceGeneralDataViewData && claimMaintenanceGeneralDataViewData.fields && componentInstance === 'claimsGeneral') {

            let filteredFields = claimMaintenanceGeneralDataViewData.fields.filter(
                (claimField: IRepertoireField) =>
                    claimField.componentInstance === componentInstance
            );

            let filteredSearchFieldsMapper = (claimField: IRepertoireField, index: number): React.ReactElement => {
                let reactElement: React.ReactElement = <></>;

                if ((claimField.fieldType === 'text' || claimField.fieldType === 'integer' || claimField.fieldType === 'decimal')) {
                    reactElement =
                        <div key={index}>
                            <SizedTextDataInput
                                fieldType={claimField.fieldType}
                                label={claimField.data}
                                fieldName={claimField.name}
                                changeData={this.changeData}
                                value={claim[claimField.name]}
                                readOnly={false}
                                isHidden={claimField.hidden}
                            />
                        </div>
                }
                else if (claimField.fieldType === 'date' && claimField.name === 'endYearOfDistributions' || claimField.name === 'startYearOfDistributions') {

                    reactElement =
                        <div key={index}>
                            <SizedDateDataInput
                                label={claimField.data}
                                fieldName={claimField.name}
                                changeData={this.changeData}
                                value={claim[claimField.name] + "-01-01"}
                                readOnly={false}
                                isHidden={claimField.hidden}
                            />
                        </div>
                }
                else if (claimField.fieldType === 'date') {
                    reactElement =
                        <div key={index}>
                            <SizedDateDataInput
                                label={claimField.data}
                                fieldName={claimField.name}
                                changeData={this.changeData}
                                value={claim[claimField.name]}
                                readOnly={false}
                                isHidden={claimField.hidden}
                            />
                        </div>
                }
                else {
                    let options: { code: string, description: string }[] = [];

                    if (lookupValues && getLookupValues(mapLookupKey(claimField.name, ''), lookupValues))
                        options = getLookupValues(mapLookupKey(claimField.name, ''), lookupValues);
                    else if (claimField.name === 'targetDistribution')
                        options = openDistributions.map(od => ({
                            code: od.distributionCode,
                            description: od.distributionCode
                        }));
                    else
                        options = undefined;

                    reactElement =
                        <div key={index}>
                            <SizedDropdownDataInput
                                label={claimField.data}
                                fieldName={claimField.name}
                                changeData={this.changeData}
                                value={claim[claimField.name]}
                                options={options}
                                allowNull={true}
                                isHidden={claimField.hidden}
                                componentInstance={componentInstance}
                            />
                        </div>
                }

                return reactElement;
            };

            return filteredFields.map(filteredSearchFieldsMapper);
        }
    }

    render() {
        const {
            updateClaimField,
        } = this.props;

        const {
            memberNumber,
            rightHolderID,
            name,
        } = this.props.claim;

        const { loaded } = this.state;

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            updateClaimField(value, name as IClaimStateKeys);
        }

        if (loaded) {
            return (
                <div>
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Claim Information</h1>
                            </div>
                        </div>
                        <div className="row">
                            <SizedTextDataInput
                                label={this.viewData[MEMBER_NUMBER_STATE_KEY]}
                                fieldName={MEMBER_NUMBER_STATE_KEY}
                                changeData={changeData}
                                value={memberNumber}
                                isHidden={!this.viewData[MEMBER_NUMBER_STATE_KEY]}
                                readOnly={false}
                            />
                            <SizedTextDataInput
                                label={this.viewData[RIGHTHOLDER_ID_STATE_KEY]}
                                fieldName={RIGHTHOLDER_ID_STATE_KEY}
                                changeData={changeData}
                                value={rightHolderID}
                                isHidden={!this.viewData[RIGHTHOLDER_ID_STATE_KEY]}
                                readOnly={false}
                            />
                            <SizedTextDataInput
                                label={this.viewData[RIGHTHOLDER_NAME_STATE_KEY]}
                                fieldName={RIGHTHOLDER_NAME_STATE_KEY}
                                changeData={changeData}
                                value={name}
                                isHidden={!this.viewData[RIGHTHOLDER_NAME_STATE_KEY]}
                                readOnly={false}
                            />                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h1>{this.renderClaimGeneralTitleField()}</h1>
                        </div>
                    </div>
                    <div className="row">
                        {this.renderClaimGeneralFields()}
                    </div><br/>
                </div>
            );
        }
        return <div />;
    }
}