import { stat } from "fs";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { addTab, copyExistingDistribution, createNewDistribution, resetPagination, sortSearchResults, updatePagination } from "../../redux/reducers/RepertoireReducer";
import { getDistributionDetailsThunk, getDistributionSubTypesThunk, getDistributionTypesThunk, searchDistributionsThunk, updateUserPreferencesThunk } from "../../redux/thunks/RepertoireThunks";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IAppState from "../../redux/types/IAppState";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import { IDistributionSearchQuery } from "../types/usageTypes/IDistributionSearchQuery";
import DistributionsPage from "./DistributionsPage";

export default connect(
    (state: IAppState) => ({
        distributionsPageData: state.repertoire.componentData.DistributionsPage,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        distributionsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].distributionSearchResult,
        searchSuccessful: state.repertoire.searchSuccessful,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'distributions')[0].indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'distributions')[0].indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'distributions')[0].resultsPerSection,
        currentPage: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].currentPage ? state.repertoire.tabs[state.repertoire.activeTab].currentPage : undefined,
        distributionTypes: state.repertoire.distributionTypes,
        distributionSubTypes: state.repertoire.distributionSubTypes,
        distributionMaintenanceGeneralDataViewData: state.repertoire.componentData.DistributionMaintenanceGeneralDataView,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        roles: state.account.roles,
    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        searchDistributions: (query: IDistributionSearchQuery) =>
            dispatch<any>(searchDistributionsThunk(query)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        getDistributionTypes: () => dispatch<any>(getDistributionTypesThunk()),
        getDistributionSubTypes: () => dispatch<any>(getDistributionSubTypesThunk()),
        getDistributionDetails: (distributionID: number, lookupValues: ILookupDictionary) => dispatch<any>(getDistributionDetailsThunk(distributionID, lookupValues)),
        addNewDistribution: (lookups: ILookupDictionary, isNew?: boolean, distributionMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) =>
            dispatch(createNewDistribution(lookups, isNew, distributionMaintenanceGeneralDataViewData)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
    })
)(DistributionsPage);