import { connect } from "react-redux";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { sortColumn } from "../../../../redux/reducers/DataIngestionReducer";
import { hideModal, showMessage, showModal, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import {
    addTab,
    copyExistingPaymentRun,
    createNewPaymentRun,
    incrementTempID,
    resetMessageBanner,
    setChangesMade,
    setValidationWarningMessage,
    updatePaymentRunField
} from "../../../../redux/reducers/RepertoireReducer";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import {
    updateUserPreferencesThunk, savePaymentRunThunk, deletePaymentRunThunk, getLastPercentageValuesThunk, undoPaymentRunChangesThunk, searchDistributionsThunk, searchPaymentRunVersionHistoryThunk
} from "../../../../redux/thunks/RepertoireThunks";
import IAppState from "../../../../redux/types/IAppState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IPopUpInfoProps } from "../../../components/modalViews/popUpInfo/PopUpInfo";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { ITreeData } from "../../../types/ITreeData";
import { PaymentRunMaintenancePage } from "./PaymentRunMaintenancePage";
import { IPaymentRunState, IPaymentRunStateKeys } from "../../../../redux/types/IPaymentRunState";
import { ILastPercentageSearchQuery } from "../../../types/usageTypes/ILastPercentageSearchQuery";
import { IDistributionSearchQuery } from "../../../types/usageTypes/IDistributionSearchQuery";
import { FormatFields } from "../../../../redux/types/FormatFields";
import PaymentRunMaintenancePageScrollListener from "./PaymentRunMaintenancePageScrollListener";

export default connect(
    (state: IAppState) => ({
        paymentRunMaintenanceGeneralDataViewData: state.repertoire.componentData.PaymentRunMaintenanceGeneralDataView,
        paymentRunMaintenanceToolbarData:
            state.repertoire.componentData.PaymentRunMaintenanceToolbar,
        paymentRunMaintenanceSaveResultData: state.repertoire.componentData.SaveResult,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        paymentRunMaintenanceGridsViewData: state.repertoire.componentData.PaymentRunMaintenanceGridsViewData,
        paymentRun: state.repertoire.tabs[state.repertoire.activeTab].paymentRunMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].paymentRunMaintenanceState.paymentRun,
        activeTab: state.repertoire.activeTab,
        tabs: state.repertoire.tabs,
        lookupValues: state.lookupEntities,
        sources: state.repertoire.matchingSourceTreeData,
        distributions: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].distributionSearchResult,
        roles: state.account.roles,
    }),
    dispatch => ({
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        hideModal: () => dispatch(hideModal()),
        updatePaymentRunField: (value: any, fieldName: IPaymentRunStateKeys, lookups?: ILookupDictionary, subFieldName?: string) =>
            dispatch(updatePaymentRunField(value, fieldName, lookups, subFieldName)),
        saveChanges: (paymentRun: IPaymentRunState, paymentRunMaintenanceGeneralDataViewData: IRepertoireComponentDataItem, lookupValues: ILookupDictionary) =>
            dispatch<any>(savePaymentRunThunk(paymentRun, paymentRunMaintenanceGeneralDataViewData, lookupValues)),
        addNewPaymentRun: (lookups: ILookupDictionary, isNew?: boolean, paymentRunMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) =>
            dispatch(createNewPaymentRun(lookups, isNew, paymentRunMaintenanceGeneralDataViewData)),
        deletePaymentRun: (paymentRunId: number, activeTab: number, sources: ITreeData[]) =>
            dispatch<any>(deletePaymentRunThunk(paymentRunId, activeTab, sources)),
        searchDistributions: (query: IDistributionSearchQuery) =>
            dispatch<any>(searchDistributionsThunk(query)),
        showModal: (modalContent: string, modalComponentInstance: string, modalProps: any, displayModalCloseButton: boolean, title: string, modalMessage?: string, isReadOnly?: boolean) =>
            dispatch<any>(showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title, modalMessage, isReadOnly)),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        copyPaymentRun: (dataAction: IDataActionToolbar[]) => dispatch<any>(copyExistingPaymentRun(dataAction)),
        getLastPercentageValues: (lastPercentageSearchQuery: ILastPercentageSearchQuery, lookups: ILookupDictionary) => dispatch<any>(getLastPercentageValuesThunk(lastPercentageSearchQuery, lookups)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        showMessage: (props: IPopUpInfoProps) => dispatch(showMessage(props)),
        undoPaymentRunChanges: (paymentRunId: number, lookups: ILookupDictionary) => dispatch<any>(undoPaymentRunChangesThunk(paymentRunId, lookups)),
        searchPaymentRunVersionHistory: (paymentRunId: number, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[]) =>
            dispatch<any>(searchPaymentRunVersionHistoryThunk(paymentRunId, lookups, actionList, formats)),
        setChangesMade: (tabIndex: number, changesMade: boolean) => dispatch(setChangesMade(tabIndex, changesMade)),
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        sortColumn: (section: string, ascending: boolean) => dispatch<any>(sortColumn(section, ascending)),
        setValidationWarningMessage: (displayWarning: boolean) => dispatch(setValidationWarningMessage(displayWarning)),
        undoPaymentRun: (paymentRunID: number, lookupValues: ILookupDictionary) => 
            dispatch<any>(undoPaymentRunChangesThunk(paymentRunID, lookupValues)),
        incrementTempID: () => dispatch<any>(incrementTempID())
    })
)(PaymentRunMaintenancePageScrollListener);