import { IMenuItem } from "../../core/types/IMenuItem";
import { ICustomerLogo } from "../../home/types/ICustomerLogo";
import IHomeComponentData from "../../home/types/IHomeComponentData";
import { IJobSearchResult } from "../../home/types/IJobSearchResult";
import { IJobSearchStateKeys } from "../../home/types/IJobSearchState";
import { IDataSourceBase } from "../../home/types/JobParameterControls/IDataSource";
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";
import { IP } from "../../repertoire/types/IP";
import { IDistributionState } from "../types/IDistributionState";
import { IEnableModulesConfigurationParametersItem } from "../types/IEnableModulesConfigurationParameterItem";
import { IUsagePoolState } from "../types/IUsagePoolState";

// Action names

type FETCH_HOME_COMPONENT_DATA_REQUEST_TYPE = "FETCH_HOME_COMPONENT_DATA_REQUEST";
type FETCH_HOME_COMPONENT_DATA_SUCCESS_TYPE = "FETCH_HOME_COMPONENT_DATA_SUCCESS";
type FETCH_HOME_COMPONENT_DATA_FAILURE_TYPE = "FETCH_HOME_COMPONENT_DATA_FAILURE";
type FETCH_MENU_ITEMS_REQUEST_TYPE = "FETCH_MENU_ITEMS_REQUEST";
type FETCH_MENU_ITEMS_SUCCESS_TYPE = "FETCH_MENU_ITEMS_SUCCESS";
type FETCH_MENU_ITEMS_FAILURE_TYPE = "FETCH_MENU_ITEMS_FAILURE";
type UPDATE_JOB_SEARCH_STATE_FIELD_TYPE = "UPDATE_JOB_SEARCH_STATE_FIELD";
type SEARCH_JOBS_REQUEST_TYPE = "SEARCH_JOBS_REQUEST";
type SEARCH_JOBS_SUCCESS_TYPE = "SEARCH_JOBS_SUCCESS";
type SEARCH_JOBS_FAILURE_TYPE = "SEARCH_JOBS_FAILURE";
type SET_CURRENT_PAGE_TYPE = "SET_CURRENT_PAGE";
type TOGGLE_TOP_MENU_IS_ACTIVE_TYPE = "TOGGLE_TOP_MENU_IS_ACTIVE";
type GET_ENABLED_MODULES_REQUEST_TYPE = 'GET_ENABLED_MODULES_REQUEST_TYPE';
type GET_ENABLED_MODULES_SUCCESS_TYPE = 'GET_ENABLED_MODULES_SUCCESS_TYPE';
type GET_ENABLED_MODULES_FAILURE_TYPE = 'GET_ENABLED_MODULES_FAILURE_TYPE';
type MANUAL_JOB_VIEW_REQUEST_TYPE = "MANUAL_JOB_VIEW_REQUEST";
type MANUAL_JOB_VIEW_SUCCESS_TYPE = "MANUAL_JOB_VIEW_SUCCESS";
type MANUAL_JOB_VIEW_FAILURE_TYPE = "MANUAL_JOB_VIEW_FAILURE";
type GET_MANUAL_JOB_PARAMETERS_REQUEST_TYPE = "GET_MANUAL_JOB_PARAMETERS_REQUEST_TYPE";
type GET_MANUAL_JOB_PARAMETERS_SUCCESS_TYPE = "GET_MANUAL_JOB_PARAMETERS_SUCCESS_TYPE";
type GET_MANUAL_JOB_PARAMETERS_FAILURE_TYPE = "GET_MANUAL_JOB_PARAMETERS_FAILURE_TYPE";
type TRIGGER_MANUAL_JOB_REQUEST_TYPE = "TRIGGER_MANUAL_JOB_REQUEST_TYPE";
type GET_USAGE_FILENAMES_REQUEST_TYPE = "GET_USAGE_FILENAMES_REQUEST_TYPE";
type GET_SCHEDULED_JOB_REQUEST_TYPE = "GET_SCHEDULED_JOB_REQUEST_TYPE";
type GET_SCHEDULED_JOB_SUCCESS_TYPE = "GET_SCHEDULED_JOB_SUCCESS_TYPE";
type GET_SCHEDULED_JOB_FAILURE_TYPE = "GET_SCHEDULED_JOB_FAILURE_TYPE";
type UPSERT_SCHEDULED_JOB_REQUEST_TYPE = "UPSERT_SCHEDULED_JOB_REQUEST_TYPE";
type UPSERT_SCHEDULED_JOB_SUCCESS_TYPE = "UPSERT_SCHEDULED_JOB_SUCCESS_TYPE";
type UPSERT_SCHEDULED_JOB_FAILURE_TYPE = "UPSERT_SCHEDULED_JOB_FAILURE_TYPE";
type GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_REQUEST_TYPE = "GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_REQUEST_TYPE"
type GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_SUCCESS_TYPE = "GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_SUCCESS_TYPE"
type GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_FAILURE_TYPE = "GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_FAILURE_TYPE"
type TRIGGER_MANUAL_JOB_SUCCESS_TYPE = "TRIGGER_MANUAL_JOB_SUCCESS_TYPE";
type GET_USAGE_FILENAMES_SUCCESS_TYPE = "GET_USAGE_FILENAMES_SUCCESS_TYPE";
type TRIGGER_MANUAL_JOB_FAILURE_TYPE = "TRIGGER_MANUAL_JOB_FAILURE_TYPE";
type GET_USAGE_FILENAMES_FAILURE_TYPE ="GET_USAGE_FILENAMES_FAILURE_TYPE";
type CLEAR_HOME_CACHE_TYPE = "CLEAR_HOME_CACHE";
type GET_CWR_ACK_HWM_SUCCESS_TYPE = "GET_CWR_ACK_HWM_SUCCESS_TYPE";
type GET_CWR_ACK_HWM_FAILURE_TYPE = "GET_CWR_ACK_HWM_FAILURE_TYPE";
type GET_ICE_ATF_SUCCESS_TYPE = "GET_ICE_ATF_SUCCESS_TYPE";
type GET_ICE_ATF_FAILURE_TYPE = "GET_ICE_ATF_FAILURE_TYPE";
type GET_AAG_HWM_SUCCESS_TYPE = "GET_AAG_HWM_SUCCESS_TYPE";
type GET_AAG_HWM_FAILURE_TYPE = "GET_AAG_HWM_FAILURE_TYPE";
type GET_EMUW_HWM_REQUEST_TYPE = "GET_EMUW_HWM_REQUEST_TYPE";
type GET_EMUW_HWM_SUCCESS_TYPE = "GET_EMUW_HWM_SUCCESS_TYPE";
type GET_EMUW_HWM_FAILURE_TYPE = "GET_EMUW_HWM_FAILURE_TYPE";
type GET_OPEN_DISTRIBUTIONS_REQUEST_TYPE = "GET_OPEN_DISTRIBUTIONS_REQUEST_TYPE";
type GET_OPEN_DISTRIBUTIONS_SUCCESS_TYPE = "GET_OPEN_DISTRIBUTIONS_SUCCESS_TYPE";
type GET_OPEN_DISTRIBUTIONS_FAILURE_TYPE = "GET_OPEN_DISTRIBUTIONS_FAILURE_TYPE";
type GET_ALL_DISTRIBUTIONS_REQUEST_TYPE = "GET_ALL_DISTRIBUTIONS_REQUEST_TYPE";
type GET_ALL_DISTRIBUTIONS_SUCCESS_TYPE = "GET_ALL_DISTRIBUTIONS_SUCCESS_TYPE";
type GET_ALL_DISTRIBUTIONS_FAILURE_TYPE = "GET_ALL_DISTRIBUTIONS_FAILURE_TYPE";
type GET_POOLS_REQUEST_TYPE = "GET_POOLS_REQUEST_TYPE";
type GET_POOLS_SUCCESS_TYPE = "GET_POOLS_SUCCESS_TYPE";
type GET_POOLS_FAILURE_TYPE = "GET_POOLS_FAILURE_TYPE";
type DISPLAY_CUSTOMER_LOGO_REQUEST_TYPE = "DISPLAY_CUSTOMER_LOGO_REQUEST_TYPE";
type DISPLAY_CUSTOMER_LOGO_SUCCESS_TYPE = "DISPLAY_CUSTOMER_LOGO_SUCCESS_TYPE";
type DISPLAY_CUSTOMER_LOGO_FAILURE_TYPE = "DISPLAY_CUSTOMER_LOGO_FAILURE_TYPE";
type SEPARATE_PUBLISHED_CHECKBOX_REQUEST_TYPE = "SEPARATE_PUBLISHED_CHECKBOX_REQUEST_TYPE";
type SEPARATE_PUBLISHED_CHECKBOX_SUCCESS_TYPE = "SEPARATE_PUBLISHED_CHECKBOX_SUCCESS_TYPE";
type SEPARATE_PUBLISHED_CHECKBOX_FAILURE_TYPE = "SEPARATE_PUBLISHED_CHECKBOX_FAILURE_TYPE";
type GET_CUSTOMER_NAME_SUCCESS_TYPE = "GET_CUSTOMER_NAME_SUCCESS_TYPE";
type GET_CUSTOMER_LOGO_ADDRESSES_SUCCESS_TYPE = "GET_CUSTOMER_LOGO_ADDRESSES_SUCCESS_TYPE";
type FETCH_MANUAL_JOB_DATA_SOURCES_REQUEST_TYPE = "FETCH_MANUAL_JOB_DATA_SOURCES_REQUEST_TYPE";
type FETCH_MANUAL_JOB_DATA_SOURCES_SUCCESS_TYPE = "FETCH_MANUAL_JOB_DATA_SOURCES_SUCCESS_TYPE";
type FETCH_MANUAL_JOB_DATA_SOURCES_FAILURE_TYPE = "FETCH_MANUAL_JOB_DATA_SOURCES_FAILURE_TYPE";
type SEARCH_CONTRIBUTORS_REQUEST_TYPE_HOME_MODAL = "SEARCH_CONTRIBUTORS_REQUEST_HOME_MODAL";
type SEARCH_CONTRIBUTORS_SUCCESS_TYPE_HOME_MODAL = "SEARCH_CONTRIBUTORS_SUCCESS_HOME_MODAL";
type SEARCH_CONTRIBUTORS_FAILURE_TYPE_HOME_MODAL = "SEARCH_CONTRIBUTORS_FAILURE_HOME_MODAL";
type GET_CWRG_HWM_REQUEST_TYPE = "GET_CWRG_HWM_REQUEST_TYPE";
type GET_CWRG_HWM_SUCCESS_TYPE = "GET_CWRG_HWM_SUCCESS_TYPE";
type GET_CWRG_HWM_FAILURE_TYPE = "GET_CWRG_HWM_FAILURE_TYPE";
type SET_CWRG_HWM_TYPE = "SET_CWRG_HWM_TYPE";

type UPDATE_PAGINATION_TYPE_HOME_MODAL = 'UPDATE_PAGINATION_HOME_MODAL';
type RESET_PAGINATION_TYPE_HOME_MODAL = 'RESET_PAGINATION_HOME_MODAL';

type SORT_SEARCH_RESULTS_TYPE_HOME_MODAL = 'SORT_SEARCH_RESULTS_HOME_MODAL';
type UPDATE_MANUAL_JOB_PARAMS = 'UPDATE_MANUAL_JOB_PARAMS';



// Action types
export interface HomeComponentDataRequest {
    type: FETCH_HOME_COMPONENT_DATA_REQUEST_TYPE;
}

export interface HomeComponentDataSuccess {
    type: FETCH_HOME_COMPONENT_DATA_SUCCESS_TYPE;
    payload: {
        componentData: IHomeComponentData;
    };
}

export interface HomeComponentDataFailure {
    type: FETCH_HOME_COMPONENT_DATA_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface MenuItemsRequest {
    type: FETCH_MENU_ITEMS_REQUEST_TYPE;
}

export interface MenuItemsSuccess {
    type: FETCH_MENU_ITEMS_SUCCESS_TYPE;
    payload: {
        menuItems: IMenuItem[];
    };
}

export interface MenuItemsFailure {
    type: FETCH_MENU_ITEMS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface UpdateJobSearchStateField {
    type: UPDATE_JOB_SEARCH_STATE_FIELD_TYPE;
    payload: {
        field: string;
        value: any;
    };
}

export interface SearchJobsRequest {
    type: SEARCH_JOBS_REQUEST_TYPE;
}

export interface SearchJobsSuccess {
    type: SEARCH_JOBS_SUCCESS_TYPE;
    payload: {
        jobs: IJobSearchResult[]
    };
}

export interface SearchJobsFailure {
    type: SEARCH_JOBS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SetCurrentPage {
    type: SET_CURRENT_PAGE_TYPE;
    payload: {
        link: string;
    };
}

export interface ToggleTopMenuIsActive {
    type: TOGGLE_TOP_MENU_IS_ACTIVE_TYPE;
}

export interface GetEnabledModuleRequest {
    type: GET_ENABLED_MODULES_REQUEST_TYPE
}
export interface GetEnabledModuleSuccess {
    type: GET_ENABLED_MODULES_SUCCESS_TYPE,
    payload: {
        param: IEnableModulesConfigurationParametersItem[];
    }
}
export interface GetEnabledModuleFailure {
    type: GET_ENABLED_MODULES_FAILURE_TYPE,
    payload: {
        error: string;
    }
}

export interface ManualJobViewRequest {
    type: MANUAL_JOB_VIEW_REQUEST_TYPE;
}

export interface ManualJobViewSuccess {
    type: MANUAL_JOB_VIEW_SUCCESS_TYPE;

}

export interface ManualJobViewFailure {
    type: MANUAL_JOB_VIEW_FAILURE_TYPE;
}

export interface GetManualJobParametersRequest {
    type: GET_MANUAL_JOB_PARAMETERS_REQUEST_TYPE,
    payload: {
        value: any;
    }
}

export interface GetManualJobParametersSuccess {
    type: GET_MANUAL_JOB_PARAMETERS_SUCCESS_TYPE,
    payload: {
        jobParameter: any,
        jobName: any;
    }
}

export interface GetManualJobParametersFailure {
    type: GET_MANUAL_JOB_PARAMETERS_FAILURE_TYPE,
}

export interface TriggerManualJobRequest {
    type: TRIGGER_MANUAL_JOB_REQUEST_TYPE,
}

export interface GetUsageFileNamesRequest {
    type: GET_USAGE_FILENAMES_REQUEST_TYPE,
}

export interface GetScheduledJobRequest{
    type: GET_SCHEDULED_JOB_REQUEST_TYPE,
}

export interface GetScheduledJobSuccess{
    type: GET_SCHEDULED_JOB_SUCCESS_TYPE,
    payload: {
        value: any;
    }
}

export interface GetScheduledJobFailure{
    type: GET_SCHEDULED_JOB_FAILURE_TYPE,
    payload: {
        value: any;
    }
}

export interface UpsertScheduledJobAsyncRequest{
    type: UPSERT_SCHEDULED_JOB_REQUEST_TYPE,
}

export interface UpsertScheduledJobAsyncSuccess{
    type: UPSERT_SCHEDULED_JOB_SUCCESS_TYPE,
    payload: {
        value: any;
    }
}

export interface UpsertScheduledJobAsyncFailure{
    type: UPSERT_SCHEDULED_JOB_FAILURE_TYPE,
}

export interface GetAllUsageFilenamesFromDistributionRequest {
    type: GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_REQUEST_TYPE,
}

export interface GetAllUsageFilenamesFromDistributionSuccess {
    type: GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_SUCCESS_TYPE,
    payload: {
        value: any;
    }
}

export interface GetAllUsageFilenamesFromDistributionFailure {
    type: GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_FAILURE_TYPE,
}

export interface TriggerManualJobSuccess {
    type: TRIGGER_MANUAL_JOB_SUCCESS_TYPE,
}
export interface GetUsageFileNamesFailure {
    type: GET_USAGE_FILENAMES_FAILURE_TYPE,
}

export interface GetUsageFileNamesSuccess {
    type: GET_USAGE_FILENAMES_SUCCESS_TYPE,
    payload: {
        value: any;
    }
}

export interface TriggerManualJobFailure {
    type: TRIGGER_MANUAL_JOB_FAILURE_TYPE,
}
export interface ClearHomeCache {
    type: CLEAR_HOME_CACHE_TYPE,
}

export interface GetCWRAckHWMSuccess {
    type: GET_CWR_ACK_HWM_SUCCESS_TYPE,
    payload: {
        value: any;
    }
}

export interface getIceATFFailure {
    type: GET_ICE_ATF_FAILURE_TYPE,
    payload: {
        value: any;
    }
}
export interface getIceATFSuccess {
    type: GET_ICE_ATF_SUCCESS_TYPE,
    payload: {
        value: any;
    }
}
export interface GetCWRAckHWMFailure {
    type: GET_CWR_ACK_HWM_FAILURE_TYPE,
    payload: {
        value: any;
    }
}
export interface GetAutomaticAgreementGHWMSuccess {
    type: GET_AAG_HWM_SUCCESS_TYPE,
    payload: {
        value: any;
    }
}

export interface GetAutomaticAgreementGHWMFailure {
    type: GET_AAG_HWM_FAILURE_TYPE,
    payload: {
        value: any;
    }
}

export interface GetEmuwHwmRequest {
    type: GET_EMUW_HWM_REQUEST_TYPE,
    payload: {
        distributionId: any;
    }
}

export interface GetEmuwHwmSuccess {
    type: GET_EMUW_HWM_SUCCESS_TYPE,
    payload: {
        value: any;
    }
}

export interface GetEmuwHwmFailure {
    type: GET_EMUW_HWM_FAILURE_TYPE,
    payload: {
        value: any;
    }
}

export interface GetCwrgHwmRequest {
    type: GET_CWRG_HWM_REQUEST_TYPE
}

export interface GetCwrgHwmSuccess {
    type: GET_CWRG_HWM_SUCCESS_TYPE,
    payload: {
        hwm: any;
    }
}

export interface GetCwrgHwmFailure {
    type: GET_CWRG_HWM_FAILURE_TYPE,
    payload: {
        error: any;
    }
}

export interface SetCwrgHwm {
    type: SET_CWRG_HWM_TYPE,
    payload: {
        hwm: any;
    }
}

export interface GetOpenDistributionsRequest {
    type: GET_OPEN_DISTRIBUTIONS_REQUEST_TYPE
}

export interface GetOpenDistributionsSuccess {
    type: GET_OPEN_DISTRIBUTIONS_SUCCESS_TYPE,
    payload: {
        openDistributions: IDistributionState[];
    }
}

export interface GetOpenDistributionsFailure {
    type: GET_OPEN_DISTRIBUTIONS_FAILURE_TYPE,
    payload: {
        value: any;
    }
}

export interface GetAllDistributionsRequest {
    type: GET_ALL_DISTRIBUTIONS_REQUEST_TYPE
}

export interface GetAllDistributionsSuccess {
    type: GET_ALL_DISTRIBUTIONS_SUCCESS_TYPE,
    payload: {
        allDistributions: IDistributionState[];
    }
}

export interface GetAllDistributionsFailure {
    type: GET_ALL_DISTRIBUTIONS_FAILURE_TYPE,
    payload: {
        value: any;
    }
}

export interface GetPoolsRequest {
    type: GET_POOLS_REQUEST_TYPE
}

export interface GetPoolsSuccess {
    type: GET_POOLS_SUCCESS_TYPE,
    payload: {
        pools: IUsagePoolState[];
    }
}

export interface GetPoolsFailure {
    type: GET_POOLS_FAILURE_TYPE,
    payload: {
        value: any;
    }
}

export interface GetDisplayCustomerLogoRequest {
    type: DISPLAY_CUSTOMER_LOGO_REQUEST_TYPE
    payload: {
        requested: boolean;
    }
}
export interface GetDisplayCustomerLogoSuccess {
    type: DISPLAY_CUSTOMER_LOGO_SUCCESS_TYPE,
    payload: {
        value: number
    }
}
export interface GetDisplayCustomerLogoFailure {
    type: DISPLAY_CUSTOMER_LOGO_FAILURE_TYPE
}
export interface GetSeparatePublishedCheckboxRequest {
    type: SEPARATE_PUBLISHED_CHECKBOX_REQUEST_TYPE
}
export interface GetSeparatePublishedCheckboxSuccess {
    type: SEPARATE_PUBLISHED_CHECKBOX_SUCCESS_TYPE,
    payload: {
        value: number
    }
}
export interface GetSeparatePublishedCheckboxFailure {
    type: SEPARATE_PUBLISHED_CHECKBOX_FAILURE_TYPE
}
export interface GetCustomerNameSuccess {
    type: GET_CUSTOMER_NAME_SUCCESS_TYPE,
    payload: {
        name: string
    }
}

export interface GetLogoAddresses {
    type: GET_CUSTOMER_LOGO_ADDRESSES_SUCCESS_TYPE,
    payload: {
        customerLogos: ICustomerLogo[],
        customerName: string
    }
}

export interface FetchManualJobDataSourcesRequest {
    type: FETCH_MANUAL_JOB_DATA_SOURCES_REQUEST_TYPE
}

export interface FetchManualJobDataSourcesSuccess {
    type: FETCH_MANUAL_JOB_DATA_SOURCES_SUCCESS_TYPE,
    payload: {
        DataSources: { [key: string]: IDataSourceBase }
    }
}

export interface FetchManualJobDataSourcesFailure {
    type: FETCH_MANUAL_JOB_DATA_SOURCES_FAILURE_TYPE,
    payload: {
        error: string
    }
}

export interface SearchContributorRequest {
    type: SEARCH_CONTRIBUTORS_REQUEST_TYPE_HOME_MODAL;
}

export interface SearchContributorsSuccess {
    type: SEARCH_CONTRIBUTORS_SUCCESS_TYPE_HOME_MODAL;
    payload: {
        contributorsResult: IContributorSearchResult[];
    };
}

export interface SearchContributorsFailure {
    type: SEARCH_CONTRIBUTORS_FAILURE_TYPE_HOME_MODAL;
    payload: {
        error: string;
    };
}


export interface UpdatePagination {
    type: UPDATE_PAGINATION_TYPE_HOME_MODAL;
    payload: {
        indexOfFirstResult: number,
        indexOfLastResult: number,
        resultsPerPage: number,
        currentPage: number,
        repertoireSection: string
    }
}

export interface SortSearchResults {
    type: SORT_SEARCH_RESULTS_TYPE_HOME_MODAL;
    payload: {
        name: string,
        results: any
    }
}

export interface ResetPagination {
    type: RESET_PAGINATION_TYPE_HOME_MODAL;
    payload: {
        repertoireSection: string
    }
}

export interface UpdateManualJobParams {
    type: UPDATE_MANUAL_JOB_PARAMS;
    payload: {
        params: any,
    }
}

export type ActionTypes =
    HomeComponentDataRequest
    | HomeComponentDataSuccess
    | HomeComponentDataFailure
    | MenuItemsRequest
    | MenuItemsSuccess
    | MenuItemsFailure
    | UpdateJobSearchStateField
    | SearchJobsRequest
    | SearchJobsFailure
    | SearchJobsSuccess
    | SetCurrentPage
    | ToggleTopMenuIsActive
    | GetEnabledModuleRequest
    | GetEnabledModuleSuccess
    | GetEnabledModuleFailure
    | ManualJobViewRequest
    | ManualJobViewSuccess
    | ManualJobViewFailure
    | GetManualJobParametersRequest
    | GetManualJobParametersSuccess
    | GetManualJobParametersFailure
    | TriggerManualJobRequest
    | GetUsageFileNamesRequest
    | TriggerManualJobSuccess
    | GetUsageFileNamesSuccess
    | TriggerManualJobFailure
    | GetUsageFileNamesFailure
    | GetAllUsageFilenamesFromDistributionRequest
    | GetAllUsageFilenamesFromDistributionSuccess
    | GetAllUsageFilenamesFromDistributionFailure
    | GetScheduledJobRequest
    | GetScheduledJobSuccess
    | GetScheduledJobFailure
    | UpsertScheduledJobAsyncRequest
    | UpsertScheduledJobAsyncSuccess
    | UpsertScheduledJobAsyncFailure
    | ClearHomeCache
    | GetCWRAckHWMSuccess
    | GetCWRAckHWMFailure
    | getIceATFSuccess
    | getIceATFFailure
    | GetAutomaticAgreementGHWMSuccess
    | GetAutomaticAgreementGHWMFailure
    | GetEmuwHwmSuccess
    | GetEmuwHwmFailure
    | GetDisplayCustomerLogoRequest
    | GetDisplayCustomerLogoSuccess
    | GetDisplayCustomerLogoFailure
    | GetSeparatePublishedCheckboxRequest
    | GetSeparatePublishedCheckboxSuccess
    | GetSeparatePublishedCheckboxFailure
    | GetLogoAddresses
    | GetCustomerNameSuccess
    | FetchManualJobDataSourcesRequest
    | FetchManualJobDataSourcesSuccess
    | FetchManualJobDataSourcesFailure
    | SearchContributorRequest
    | SearchContributorsSuccess
    | SearchContributorsFailure
    | ResetPagination
    | SortSearchResults
    | UpdatePagination
    | UpdateManualJobParams
    | GetOpenDistributionsSuccess
    | GetAllDistributionsSuccess
    | GetAllDistributionsRequest
    | GetAllDistributionsFailure
    | GetPoolsSuccess
    | GetCwrgHwmSuccess
    | GetCwrgHwmFailure
    | SetCwrgHwm
    ;

export const FETCH_HOME_COMPONENT_DATA_REQUEST: FETCH_HOME_COMPONENT_DATA_REQUEST_TYPE = "FETCH_HOME_COMPONENT_DATA_REQUEST";
export const FETCH_HOME_COMPONENT_DATA_SUCCESS: FETCH_HOME_COMPONENT_DATA_SUCCESS_TYPE = "FETCH_HOME_COMPONENT_DATA_SUCCESS";
export const FETCH_HOME_COMPONENT_DATA_FAILURE: FETCH_HOME_COMPONENT_DATA_FAILURE_TYPE = "FETCH_HOME_COMPONENT_DATA_FAILURE";
export const FETCH_MENU_ITEMS_REQUEST: FETCH_MENU_ITEMS_REQUEST_TYPE = "FETCH_MENU_ITEMS_REQUEST";
export const FETCH_MENU_ITEMS_SUCCESS: FETCH_MENU_ITEMS_SUCCESS_TYPE = "FETCH_MENU_ITEMS_SUCCESS";
export const FETCH_MENU_ITEMS_FAILURE: FETCH_MENU_ITEMS_FAILURE_TYPE = "FETCH_MENU_ITEMS_FAILURE";
export const UPDATE_JOB_SEARCH_STATE_FIELD: UPDATE_JOB_SEARCH_STATE_FIELD_TYPE = "UPDATE_JOB_SEARCH_STATE_FIELD";
export const SEARCH_JOBS_REQUEST: SEARCH_JOBS_REQUEST_TYPE = "SEARCH_JOBS_REQUEST";
export const SEARCH_JOBS_SUCCESS: SEARCH_JOBS_SUCCESS_TYPE = "SEARCH_JOBS_SUCCESS";
export const SEARCH_JOBS_FAILURE: SEARCH_JOBS_FAILURE_TYPE = "SEARCH_JOBS_FAILURE";
export const SET_CURRENT_PAGE: SET_CURRENT_PAGE_TYPE = "SET_CURRENT_PAGE";
export const TOGGLE_TOP_MENU_IS_ACTIVE: TOGGLE_TOP_MENU_IS_ACTIVE_TYPE = "TOGGLE_TOP_MENU_IS_ACTIVE";
export const GET_ENABLED_MODULES_REQUEST: GET_ENABLED_MODULES_REQUEST_TYPE = 'GET_ENABLED_MODULES_REQUEST_TYPE';
export const GET_ENABLED_MODULES_SUCCESS: GET_ENABLED_MODULES_SUCCESS_TYPE = 'GET_ENABLED_MODULES_SUCCESS_TYPE';
export const GET_ENABLED_MODULES_FAILURE: GET_ENABLED_MODULES_FAILURE_TYPE = 'GET_ENABLED_MODULES_FAILURE_TYPE';
export const MANUAL_JOB_VIEW_REQUEST: MANUAL_JOB_VIEW_REQUEST_TYPE = "MANUAL_JOB_VIEW_REQUEST";
export const MANUAL_JOB_VIEW_SUCCESS: MANUAL_JOB_VIEW_SUCCESS_TYPE = "MANUAL_JOB_VIEW_SUCCESS";
export const MANUAL_JOB_VIEW_FAILURE: MANUAL_JOB_VIEW_FAILURE_TYPE = "MANUAL_JOB_VIEW_FAILURE";
export const GET_MANUAL_JOB_PARAMETERS_REQUEST: GET_MANUAL_JOB_PARAMETERS_REQUEST_TYPE = "GET_MANUAL_JOB_PARAMETERS_REQUEST_TYPE";
export const GET_MANUAL_JOB_PARAMETERS_SUCCESS: GET_MANUAL_JOB_PARAMETERS_SUCCESS_TYPE = "GET_MANUAL_JOB_PARAMETERS_SUCCESS_TYPE";
export const GET_MANUAL_JOB_PARAMETERS_FAILURE: GET_MANUAL_JOB_PARAMETERS_FAILURE_TYPE = "GET_MANUAL_JOB_PARAMETERS_FAILURE_TYPE";
export const TRIGGER_MANUAL_JOB_REQUEST: TRIGGER_MANUAL_JOB_REQUEST_TYPE = "TRIGGER_MANUAL_JOB_REQUEST_TYPE";
export const GET_USAGE_FILENAMES_REQUEST: GET_USAGE_FILENAMES_REQUEST_TYPE = "GET_USAGE_FILENAMES_REQUEST_TYPE";
export const GET_SCHEDULED_JOB_REQUEST: GET_SCHEDULED_JOB_REQUEST_TYPE = "GET_SCHEDULED_JOB_REQUEST_TYPE";
export const GET_SCHEDULED_JOB_SUCCESS: GET_SCHEDULED_JOB_SUCCESS_TYPE = "GET_SCHEDULED_JOB_SUCCESS_TYPE";
export const GET_SCHEDULED_JOB_FAILURE: GET_SCHEDULED_JOB_FAILURE_TYPE = "GET_SCHEDULED_JOB_FAILURE_TYPE";
export const UPSERT_SCHEDULED_JOB_REQUEST: UPSERT_SCHEDULED_JOB_REQUEST_TYPE = "UPSERT_SCHEDULED_JOB_REQUEST_TYPE";
export const UPSERT_SCHEDULED_JOB_SUCCESS: UPSERT_SCHEDULED_JOB_SUCCESS_TYPE = "UPSERT_SCHEDULED_JOB_SUCCESS_TYPE";
export const UPSERT_SCHEDULED_JOB_FAILURE: UPSERT_SCHEDULED_JOB_FAILURE_TYPE = "UPSERT_SCHEDULED_JOB_FAILURE_TYPE";
export const TRIGGER_MANUAL_JOB_SUCCESS: TRIGGER_MANUAL_JOB_SUCCESS_TYPE = "TRIGGER_MANUAL_JOB_SUCCESS_TYPE";
export const GET_USAGE_FILENAMES_SUCCESS: GET_USAGE_FILENAMES_SUCCESS_TYPE = "GET_USAGE_FILENAMES_SUCCESS_TYPE";
export const TRIGGER_MANUAL_JOB_FAILURE: TRIGGER_MANUAL_JOB_FAILURE_TYPE = "TRIGGER_MANUAL_JOB_FAILURE_TYPE";
export const GET_USAGE_FILENAMES_FAILURE: GET_USAGE_FILENAMES_FAILURE_TYPE = "GET_USAGE_FILENAMES_FAILURE_TYPE";
export const GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_REQUEST: GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_REQUEST_TYPE = "GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_REQUEST_TYPE";
export const GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_SUCCESS: GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_SUCCESS_TYPE = "GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_SUCCESS_TYPE";
export const GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_FAILURE: GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_FAILURE_TYPE = "GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_FAILURE_TYPE";
export const CLEAR_HOME_CACHE: CLEAR_HOME_CACHE_TYPE = "CLEAR_HOME_CACHE";
export const GET_CWR_ACK_HWM_SUCCESS: GET_CWR_ACK_HWM_SUCCESS_TYPE = "GET_CWR_ACK_HWM_SUCCESS_TYPE";
export const GET_CWR_ACK_HWM_FAILURE: GET_CWR_ACK_HWM_FAILURE_TYPE = "GET_CWR_ACK_HWM_FAILURE_TYPE";
export const GET_ICE_ATF_SUCCESS: GET_ICE_ATF_SUCCESS_TYPE = "GET_ICE_ATF_SUCCESS_TYPE";
export const GET_ICE_ATF_FAILURE: GET_ICE_ATF_FAILURE_TYPE = "GET_ICE_ATF_FAILURE_TYPE";
export const GET_AAG_HWM_SUCCESS: GET_AAG_HWM_SUCCESS_TYPE = "GET_AAG_HWM_SUCCESS_TYPE";
export const GET_AAG_HWM_FAILURE: GET_AAG_HWM_FAILURE_TYPE = "GET_AAG_HWM_FAILURE_TYPE";
export const GET_EMUW_HWM_REQUEST: GET_EMUW_HWM_REQUEST_TYPE = "GET_EMUW_HWM_REQUEST_TYPE";
export const GET_EMUW_HWM_SUCCESS: GET_EMUW_HWM_SUCCESS_TYPE = "GET_EMUW_HWM_SUCCESS_TYPE";
export const GET_EMUW_HWM_FAILURE: GET_EMUW_HWM_FAILURE_TYPE = "GET_EMUW_HWM_FAILURE_TYPE";
export const GET_CWRG_HWM_REQUEST: GET_CWRG_HWM_REQUEST_TYPE = "GET_CWRG_HWM_REQUEST_TYPE";
export const GET_CWRG_HWM_SUCCESS: GET_CWRG_HWM_SUCCESS_TYPE = "GET_CWRG_HWM_SUCCESS_TYPE";
export const GET_CWRG_HWM_FAILURE: GET_CWRG_HWM_FAILURE_TYPE = "GET_CWRG_HWM_FAILURE_TYPE";
export const SET_CWRG_HWM: SET_CWRG_HWM_TYPE = "SET_CWRG_HWM_TYPE";
export const GET_OPEN_DISTRIBUTIONS_REQUEST: GET_OPEN_DISTRIBUTIONS_REQUEST_TYPE = "GET_OPEN_DISTRIBUTIONS_REQUEST_TYPE";
export const GET_OPEN_DISTRIBUTIONS_SUCCESS: GET_OPEN_DISTRIBUTIONS_SUCCESS_TYPE = "GET_OPEN_DISTRIBUTIONS_SUCCESS_TYPE";
export const GET_OPEN_DISTRIBUTIONS_FAILURE: GET_OPEN_DISTRIBUTIONS_FAILURE_TYPE = "GET_OPEN_DISTRIBUTIONS_FAILURE_TYPE";
//Get Distribution
export const GET_All_DISTRIBUTIONS_REQUEST: GET_ALL_DISTRIBUTIONS_REQUEST_TYPE = "GET_ALL_DISTRIBUTIONS_REQUEST_TYPE";
export const GET_ALL_DISTRIBUTIONS_SUCCESS: GET_ALL_DISTRIBUTIONS_SUCCESS_TYPE = "GET_ALL_DISTRIBUTIONS_SUCCESS_TYPE";
export const GET_ALL_DISTRIBUTIONS_FAILURE: GET_ALL_DISTRIBUTIONS_FAILURE_TYPE = "GET_ALL_DISTRIBUTIONS_FAILURE_TYPE";
//Get Distribution
export const GET_POOLS_REQUEST: GET_POOLS_REQUEST_TYPE = "GET_POOLS_REQUEST_TYPE";
export const GET_POOLS_SUCCESS: GET_POOLS_SUCCESS_TYPE = "GET_POOLS_SUCCESS_TYPE";
export const GET_POOLS_FAILURE: GET_POOLS_FAILURE_TYPE = "GET_POOLS_FAILURE_TYPE";
export const DISPLAY_CUSTOMER_LOGO_REQUEST: DISPLAY_CUSTOMER_LOGO_REQUEST_TYPE = "DISPLAY_CUSTOMER_LOGO_REQUEST_TYPE";
export const DISPLAY_CUSTOMER_LOGO_SUCCESS: DISPLAY_CUSTOMER_LOGO_SUCCESS_TYPE = "DISPLAY_CUSTOMER_LOGO_SUCCESS_TYPE";
export const DISPLAY_CUSTOMER_LOGO_FAILURE: DISPLAY_CUSTOMER_LOGO_FAILURE_TYPE = "DISPLAY_CUSTOMER_LOGO_FAILURE_TYPE";
export const SEPARATE_PUBLISHED_CHECKBOX_REQUEST: SEPARATE_PUBLISHED_CHECKBOX_REQUEST_TYPE = "SEPARATE_PUBLISHED_CHECKBOX_REQUEST_TYPE";
export const SEPARATE_PUBLISHED_CHECKBOX_SUCCESS: SEPARATE_PUBLISHED_CHECKBOX_SUCCESS_TYPE = "SEPARATE_PUBLISHED_CHECKBOX_SUCCESS_TYPE";
export const SEPARATE_PUBLISHED_CHECKBOX_FAILURE: SEPARATE_PUBLISHED_CHECKBOX_FAILURE_TYPE = "SEPARATE_PUBLISHED_CHECKBOX_FAILURE_TYPE";
export const GET_CUSTOMER_LOGO_ADDRESSES_SUCCESS: GET_CUSTOMER_LOGO_ADDRESSES_SUCCESS_TYPE = "GET_CUSTOMER_LOGO_ADDRESSES_SUCCESS_TYPE"
export const GET_CUSTOMER_NAME_SUCCESS: GET_CUSTOMER_NAME_SUCCESS_TYPE = "GET_CUSTOMER_NAME_SUCCESS_TYPE"
export const FETCH_MANUAL_JOB_DATA_SOURCES_REQUEST: FETCH_MANUAL_JOB_DATA_SOURCES_REQUEST_TYPE = "FETCH_MANUAL_JOB_DATA_SOURCES_REQUEST_TYPE"
export const FETCH_MANUAL_JOB_DATA_SOURCES_SUCCESS: FETCH_MANUAL_JOB_DATA_SOURCES_SUCCESS_TYPE = "FETCH_MANUAL_JOB_DATA_SOURCES_SUCCESS_TYPE"
export const FETCH_MANUAL_JOB_DATA_SOURCES_FAILURE: FETCH_MANUAL_JOB_DATA_SOURCES_FAILURE_TYPE = "FETCH_MANUAL_JOB_DATA_SOURCES_FAILURE_TYPE"

export const SEARCH_CONTRIBUTORS_REQUEST_HOME_MODAL: SEARCH_CONTRIBUTORS_REQUEST_TYPE_HOME_MODAL =
    "SEARCH_CONTRIBUTORS_REQUEST_HOME_MODAL";
export const SEARCH_CONTRIBUTORS_SUCCESS_HOME_MODAL: SEARCH_CONTRIBUTORS_SUCCESS_TYPE_HOME_MODAL =
    "SEARCH_CONTRIBUTORS_SUCCESS_HOME_MODAL";
export const SEARCH_CONTRIBUTORS_FAILURE_HOME_MODAL: SEARCH_CONTRIBUTORS_FAILURE_TYPE_HOME_MODAL =
    "SEARCH_CONTRIBUTORS_FAILURE_HOME_MODAL";
export const UPDATE_PAGINATION_HOME_MODAL: UPDATE_PAGINATION_TYPE_HOME_MODAL = 'UPDATE_PAGINATION_HOME_MODAL';
export const RESET_PAGINATION_HOME_MODAL: RESET_PAGINATION_TYPE_HOME_MODAL = 'RESET_PAGINATION_HOME_MODAL';
export const SORT_SEARCH_RESULTS: SORT_SEARCH_RESULTS_TYPE_HOME_MODAL = 'SORT_SEARCH_RESULTS_HOME_MODAL';
export const UPDATE_MANUAL_JOB_PARAMS: UPDATE_MANUAL_JOB_PARAMS = 'UPDATE_MANUAL_JOB_PARAMS';

