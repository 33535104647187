import * as React from "react";
import { If } from "../../core/components/If";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { hideModal, showUpdateFieldsModal } from "../../redux/reducers/ModalReducer";
import { addArtistToWork, setDataSource, setEditableFields, updateEditableFields } from "../../redux/reducers/RepertoireReducer";
import { FormatFields } from "../../redux/types/FormatFields";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IFieldSettingViewModalProps } from "../components/modalViews/fieldSettingView/FieldSettingView";
import { IShowFieldSettingViewModalProps } from "../components/repertoireModal/RepertoireModal";
import SearchView from "../components/searchView/SearchView";
import SelectorView from "../components/selectorView/SelectorView";
import { getDataAction } from "../components/toolBar/ToolbarHelper";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, CONFIGURATION_PARAMETER_SHOW_COMMENT_SUBJECT_AS_DROPDOWN_KEY, WORK_MAINTENANCE_GROUP } from "../ConfigurationConsts";
import {
    ADVANCED, ALL_ROLES, CONFIGURE_WORKS_ROLE, EDITABLE_FIELDS, INTRAY, REPERTOIRE, SEARCH_VIEW, SEARCH_VIEW_WORKS, SIMPLE, TITLE_EDITABLE_FIELDS_VIEW, UPDATE_WORKS_ROLE, VIEW_WORKS_ROLE, WORKS_PAGE_VIEW, WORK_MAINTENANCE_TOOLBAR, WORK_SEARCH_EDITABLE_FIELDS
} from "../Consts";
import { SearchRequests } from "../services/SearchRequests";
import { DataSource } from "../types/DataSource";
import { IArtist } from "../types/IArtist";
import { IArtistSearchQuery } from "../types/IArtistSearchQuery";
import { IContributorSearchQuery } from "../types/IContributorSearchQuery";
import { IContributorSearchResult } from "../types/IContributorSearchResult";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import { IRepertoireField } from "../types/IRepertoireField";
import { IWorksSearchQuery } from "../types/IWorksSearchQuery";
import { IWorksSearchResult } from "../types/IWorksSearchResult";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import { IWorkflowSearchResult } from "../types/IWorkflowSearchResult";
import { isNullOrUndefined } from "../../util/tools";

export interface IWorksPageProps {
    worksPageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    dataSource: string;
    setDataSource: typeof setDataSource;
    addArtistToWork: typeof addArtistToWork;
    getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], actionList: IDataActionToolbar[], readonlyIndicatorsWorkFlagTypes: string[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], openEntityForWorflowSession?: boolean, workGroup?: string) => void;
    getWorkDetailsWorkflow: (
        dataSource: string,
        workID: number,
        lookups: ILookupDictionary,
        otherIndicatorsWorkFlagTypes: string[],
        dataActions: IDataActionToolbar[],
        workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem,
        formats: FormatFields[],
        readonlyIndicatorsWorkFlagTypes: string[],
        openEntityForWorflowSession?: boolean,
        activeTab?: number, currentWorkflowIndex?: number,
        workflows?: IWorkflowSearchResult[],
        workGroup?: string) => void;
    searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    worksSearchResults: IWorksSearchResult[];
    searchArtists: (query: IArtistSearchQuery, dataSource: string) => void;
    artistsSearchResults: IArtist[];
    searchContributors: (
        query: IContributorSearchQuery,
        dataSource: string
    ) => void;
    contributorsSearchResults: IContributorSearchResult[];
    addNewWork: (lookups: ILookupDictionary, actionList: IDataActionToolbar[], isNew?: boolean, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[], workGroupId?: string, workGroup?: string) => void;
    lookupValues: ILookupDictionary;
    searchSuccessful: boolean;
    workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    expandedWorkResults: number[];
    expandAll: boolean;
    expandWorkResult: (workResult: number) => void;
    expandAllResults: () => void;
    otherIndicatorsWorkFlagTypes: string[];
    readonlyIndicatorsWorkFlagTypes: string[];
    getOtherIndicatorsWorkFlagTypes: () => void,
    getReadonlyIndicatorsWorkFlagTypes: () => void,
    editableFieldsDataView: IRepertoireComponentDataItem;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    currentWorkflowIndex?: number;
    workflowSessionItems?: IWorkflowSearchResult[];
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateEditableField: typeof updateEditableFields;
    setEditableFields: typeof setEditableFields;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    dataGridTableData?: IRepertoireComponentDataItem;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    editableFields: IRepertoireField[];
    hideModal?: typeof hideModal;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    sortSearchResults: (name: string, results: any) => void;
    workGroupId?: string;
    workGroup?: string;
    disabled?: boolean;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    getShowCommentSubjectAsDropdownConfigurationParameter?: (group: string) => void;
    currentUser?:string;
    addToPackages?:(userName:string,entityType:string,entityID:number)=>void;
}

const WorksPage = (props:IWorksPageProps) => {
    const [searchType, setSearchType] = React.useState(SIMPLE)
    const [loaded, setLoaded] = React.useState(false)
    const { instance, accounts } = useMsal()

    React.useEffect(() => {
        checkIfLoaded();
        checkIfIndicatorTypesLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getRoles();
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])


    const checkIfLoaded = () => {
        const { worksPageData, searchViewData, editableFields, setEditableFields } = props;

        let fieldsToState: IRepertoireField[] = [];
        if (worksPageData) {
            const searchData = { ...searchViewData }
            if (worksPageData.fields && worksPageData.fields.length > 0 && !loaded) {
                setLoaded(true);
                const header = worksPageData.fields.find(f => f.name === "PageTitle");
                if (header) {
                    document.title = header.data;
                }
            }
        }
        if (!loaded && searchViewData && searchViewData.fields && searchViewData.fields.length > 0 && (!editableFields || editableFields.length === 0)){
            setEditableFields(SEARCH_VIEW, SEARCH_VIEW_WORKS)
        }
    }

    const checkIfIndicatorTypesLoaded = () => {
        const {
            otherIndicatorsWorkFlagTypes,
            getOtherIndicatorsWorkFlagTypes,
            readonlyIndicatorsWorkFlagTypes,
            getReadonlyIndicatorsWorkFlagTypes
        } = props;

        if (otherIndicatorsWorkFlagTypes == undefined) {
            getOtherIndicatorsWorkFlagTypes();
        }

        if (readonlyIndicatorsWorkFlagTypes == undefined) {
            getReadonlyIndicatorsWorkFlagTypes();
        }
    }

    const addNewWork = () : void => {
        const { addNewWork, lookupValues, workMaintenanceGeneralDataViewData, workGroupId, workGroup } = props;
        const actionList = getDataAction(DataSource.Intray, WORK_MAINTENANCE_TOOLBAR);
        const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                addNewWork(lookupValues, actionList, true, workMaintenanceGeneralDataViewData, formats, workGroupId, workGroup);
            });
    }

    const onSaveEditableFields = () => {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab } = props;
        updateComponentFieldsItems(editableFields["default"], SEARCH_VIEW, SEARCH_VIEW_WORKS, SEARCH_VIEW, tabs, activeTab);
    }

    const showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableData,
            setEditableFields
        } = props;

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === WORK_SEARCH_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableData,
            onClickSave: onSaveEditableFields
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    if (loaded) {
        const {
            dataSource,
            searchViewData,
            setDataSource,
            addArtistToWork,
            searchResultsTableData,
            searchWorks,
            tabs,
            activeTab,
            worksSearchResults,
            lookupValues,
            searchSuccessful,
            workMaintenanceGeneralDataViewData,
            expandedWorkResults,
            expandAll,
            expandWorkResult,
            expandAllResults,
            editableFields,
            worksPageData,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            resetPagination,
            sortSearchResults,
            workGroupId,
            workGroup,
            disabled,
            roles,
            currentWorkflowIndex,
            workflowSessionItems,
            getShowCommentSubjectAsDropdownConfigurationParameter,
            addToPackages,
            currentUser
        } = props;

        let isDataSourceHidden: boolean = false;
        let isSearchTypeHidden: boolean = false;
        let dataSourceField = null;
        let searchTypeField = null;
        let filteredSearchViewData = { fields: [] };
        let disabledNormalisied: boolean = (disabled === undefined || disabled === null) ? false : disabled;

        if (searchViewData) {
            const filtered = searchViewData.fields.filter(f => f.componentInstance === SEARCH_VIEW_WORKS);
            dataSourceField = filtered && filtered.find(x => x.name === 'dataSource');
            searchTypeField = filtered && filtered.find(x => x.name === 'searchType');
            isDataSourceHidden = dataSourceField && dataSourceField.hidden;
            isSearchTypeHidden = searchTypeField && searchTypeField.hidden
            filteredSearchViewData = { fields: searchViewData.fields.filter(f => !['dataSource', 'searchType'].includes(f.name)) };
        }

        const getWorkDetails = (dataSource: string, workID: number) => {
            const actionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
            const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                .then(formats => {
                    props.getWorkDetails(dataSource, workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, actionList, tabs[activeTab].readonlyIndicatorWorkFlagTypes, workMaintenanceGeneralDataViewData, formats, false, workGroup);
                });

        }

        const getWorkDetailsWorkflow = (dataSource: string, workID: number, selectedWorkflows: IWorkflowSearchResult[], openEntityForWorkflowSession?: boolean)  => {
            const actionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
            const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                .then(formats => {
                    props.getWorkDetailsWorkflow(dataSource, workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, actionList, workMaintenanceGeneralDataViewData, formats, tabs[activeTab].readonlyIndicatorWorkFlagTypes, openEntityForWorkflowSession, activeTab, currentWorkflowIndex, selectedWorkflows, workGroup);
                });
        }

        const getUpdateRoleDisabled = (): boolean => {
            const { roles } = props;
            if (roles && (roles.includes(UPDATE_WORKS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        const getViewRoleDisabled = (): boolean => {
            const { roles } = props;
            if (roles && (roles.includes(VIEW_WORKS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        const getConfigureRoleDisabled = (): boolean => {
            const { roles } = props;
            if (roles && (roles.includes(CONFIGURE_WORKS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }
        const username = sessionStorage.getItem('username');
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <If condition={!isNullOrUndefined(workGroup) && workGroup.length > 0}>
                            <span className="title">Find Works of Work Group</span>
                        </If>
                        <If condition={isNullOrUndefined(workGroup)}>
                            <span className="title">Find Works</span>
                        </If>

                        <div>
                            <button className="editBtn" title="Configure data fields" style={{ paddingBottom: "4px" }} onClick={showFieldsModal} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                            <div className="newWorkButton" title="Create New Work" style={{ paddingRight: "24px" }} hidden={getUpdateRoleDisabled()}>
                                <div className="IconTextButton" onClick={addNewWork} >
                                    <i className="icon ms-Icon ms-Icon--CircleAddition"
                                        aria-hidden="true">
                                    </i>
                                    New Work
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <SelectorView
                        selection={dataSource}
                        setSelection={setDataSource}
                        option1={REPERTOIRE}
                        option2={INTRAY}
                        selectorTitle={dataSourceField && dataSourceField.data}
                        disabled={disabledNormalisied}
                    />
                    <SelectorView
                        selection={searchType}
                        setSelection={setSearchType}
                        option1={SIMPLE}
                        option2={ADVANCED}
                        selectorTitle={searchTypeField && searchTypeField.data}
                    />
                </div>
                <SearchView
                    searchViewData={filteredSearchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_WORKS}
                    modalProps={undefined}
                    dataSource={dataSource}
                    addArtistToWork={addArtistToWork}
                    getWorkDetails={getWorkDetails}
                    getWorkDetailsWorkflow={getWorkDetailsWorkflow}
                    searchWorks={searchWorks}
                    worksSearchResults={worksSearchResults}
                    hideModal={undefined}
                    searchSuccessful={searchSuccessful}
                    expandedWorkResults={expandedWorkResults}
                    expandAll={expandAll}
                    expandWorkResult={expandWorkResult}
                    expandAllResults={expandAllResults}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    updatePagination={updateUserPagination}
                    lookupValues={lookupValues}
                    sortSearchResults={sortSearchResults}
                    workGroupId={workGroupId}
                    searchDisabled={getViewRoleDisabled()}
                    getShowCommentSubjectAsDropdownConfigurationParameter={getShowCommentSubjectAsDropdownConfigurationParameter}
                    groupName={WORK_MAINTENANCE_GROUP}
                    currentUser={username}
                    addToPackages={addToPackages}
                />
            </div>
        );
    }
    return <div />;
}

export default WorksPage;
