import * as React from "react";
import { If } from "../../../../core/components/If";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import {
    hideModal,
    showModal,
    showYesNoPrompt
} from "../../../../redux/reducers/ModalReducer";
import {
    setEditableFields
} from "../../../../redux/reducers/MembersPortalReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import {
    DRAFT_SET_LISTS_STATUS_DRAFT_SUBMITTED_TYPE,
    DRAFT_SET_LISTS_STATUS_DRAFT_TYPE,
} from "../../../Consts";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { DataSource } from "../../../../repertoire/types/DataSource";
import MultiStepForm, { Step } from "../../../components/multiStepForm/MultiStepForm";
import { sortColumn } from "../../../../redux/reducers/DataIngestionReducer";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import DraftSetListsMaintenanceGeneralDataView from "../draftSetListsMaintenanceGeneralDataView/DraftSetListsMaintenanceGeneralDataView";
import DraftSetListsMaintenanceToolbar from "../draftSetlistsMaintenanceToolbar/DraftSetlistsMaintenanceToolbar";
import { ISetListsStateKeys } from "../../../types/ISetListsState";
import { IProductState } from "../../../../redux/types/IProductState";
import { ProductDetailsMapper } from "../../../../repertoire/services/ProductDetailsMapper";

export interface IDraftSetListsMaintenancePageProps {
    draftSetListsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem;
    draftSetListsMaintenanceToolbarData?: IMembersPortalComponentDataItem;
    draftWorkMaintenanceSaveResultData?: IMembersPortalComponentDataItem;
    draftSetListsMaintenanceGridsViewData?: IMembersPortalComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    activeTab: number;
    tabs: ITabReduxItem[];
    dataSource: DataSource;
    lookupValues?: ILookupDictionary;
    setList: IProductState;
    hideModal: typeof hideModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    resetMessageBanner: () => void;
    addNewDraftSetList: (lookupValues: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, draftWorksMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem) => void;
    updateDraftSetListsField?: (value: any, fieldName: ISetListsStateKeys, index?: number) => void;
    saveChanges: (work: IProductState, draftWorksMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem, lookupValues: ILookupDictionary) => void;
    undoDraftSetList?: (dataSource: string, draftSetListID: number, lookupValues: ILookupDictionary) => void;
    deleteDraftSetList: (dataSource: string, draftWorksID: number, activeTab: number) => void;
    showModal: typeof showModal;
    clearModalSearchResults: () => void;
    copyDraftSetList: (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary) => void;
    incrementTempID?: () => void;
    sortColumn: typeof sortColumn;
    stepsCurrent?: number;
    stepsSetCurrent?: (current: number) => void;
    currentUser: string;
    currentUserNameNumber: string;
    portalType: string;
    updatedScroll?: number;
}


interface IDraftSetListsMaintenancePageState {
    toolbarWidth: number;
    isBulkCheckboxActive?: boolean;
}

export class DraftSetListsMaintenancePage extends React.PureComponent<
    IDraftSetListsMaintenancePageProps,
    IDraftSetListsMaintenancePageState
> {
    toolbarParentRef;
    constructor(props: IDraftSetListsMaintenancePageProps) {
        super(props);
        this.state = {
            toolbarWidth: null,
            isBulkCheckboxActive: false
        };

        this.toolbarParentRef = React.createRef();
    }

    addNewDraftSetLists() {
        const { addNewDraftSetList, lookupValues, draftSetListsMaintenanceGeneralDataViewData, currentUserNameNumber} = this.props;
        addNewDraftSetList(lookupValues, currentUserNameNumber, true, draftSetListsMaintenanceGeneralDataViewData);
    }

    confirmAndSubmit = () => {
        const { saveChanges, setList, draftSetListsMaintenanceGeneralDataViewData, lookupValues } = this.props;
        let draftSetList: IProductState = JSON.parse(JSON.stringify(setList));
        draftSetList.status = DRAFT_SET_LISTS_STATUS_DRAFT_SUBMITTED_TYPE;
        saveChanges(draftSetList, draftSetListsMaintenanceGeneralDataViewData, lookupValues);
        this.setState({ isBulkCheckboxActive: false });
    }

    saveAsDraftSetList = () => {
        const { saveChanges, setList, draftSetListsMaintenanceGeneralDataViewData, lookupValues } = this.props;
        let draftSetList: IProductState = JSON.parse(JSON.stringify(setList));
        draftSetList.status = DRAFT_SET_LISTS_STATUS_DRAFT_TYPE;
        saveChanges(draftSetList, draftSetListsMaintenanceGeneralDataViewData, lookupValues);
        this.setState({ isBulkCheckboxActive: false });
    }

    deleteDraftSetList() {
        const { dataSource, activeTab, setList } = this.props;
        this.props.deleteDraftSetList(dataSource, setList.productCoreID, activeTab);
    }

    undoChanges = () => {
        const { dataSource, setList, undoDraftSetList, lookupValues } = this.props;
        undoDraftSetList(dataSource, setList.productCoreID, lookupValues);
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    changeBulkCheckbox = (value: boolean) => {
        this.setState({ isBulkCheckboxActive: value });
    }


    render() {

        const {
            tabs,
            activeTab,
            draftSetListsMaintenanceToolbarData,
            draftWorkMaintenanceSaveResultData,
            draftSetListsMaintenanceGridsViewData,
            draftSetListsMaintenanceGeneralDataViewData,
            hideModal,
            showYesNoPrompt,
            addNewDraftSetList,
            updateDraftSetListsField,
            dataGridTableData,
            showModal,
            clearModalSearchResults,
            copyDraftSetList,
            incrementTempID,
            dataSource,
            sortColumn,
            stepsCurrent,
            stepsSetCurrent,
            currentUser,
            portalType,
            currentUserNameNumber
        } = this.props;
        
        let setList = this.props.setList;

        if (!setList) {
            if (currentUserNameNumber) {
                const userNameNumber = currentUserNameNumber;
                const userName = sessionStorage.getItem('username');
                setList = ProductDetailsMapper.createNewProductState(this.props.lookupValues, null, { ipiNameNumber: parseInt(userNameNumber), productSourceIPID: 0, fullName: userName });
            }
        }

        let isNewDraftSetList: boolean = this.props.setList?.productCoreID < 1 ? true : false;
        let { lookupValues } = this.props;

        const workIdString = "(" + this.props.setList?.productCoreID.toString() + ") "
        const title = '';
        //const title = WorkDetailsMapper.getOriginalTitleFromTitles(setList.setListID, setList.title)

        const changesMade = tabs[activeTab].changesMade || false;
        const isReadonly: boolean = tabs[activeTab].isReadonly;

        const changeData = (value: any, fieldName: string) => {
            //const { status } = this.props.draftWork;
            updateDraftSetListsField(value, fieldName as ISetListsStateKeys);
        }

        let readOnlyDataGridTableData = JSON.parse(JSON.stringify(dataGridTableData));
        //TODO extract steps into separate files to clean up this component
        let steps: Step[] =
            [
                {
                    title: 'Set List Details',
                    content: <DraftSetListsMaintenanceGeneralDataView
                        draftSetListsMaintenanceGridsViewData={draftSetListsMaintenanceGridsViewData}
                        setList={setList}
                        isReadonly={isReadonly}
                        lookupValues={lookupValues}
                        updateDraftSetListsField={updateDraftSetListsField}
                        showModal={showModal}
                        hideModal={hideModal}
                        isNewDraftSetList={isNewDraftSetList}
                        activeTab={activeTab}
                        tabs={tabs}
                        resetMessageBanner={this.resetMessageBanner.bind(this)}
                        incrementTempID={incrementTempID}
                        editableFields={[]}
                        changesMade={changesMade}
                        setEditableFields={setEditableFields}
                        activeHeaderSection={tabs[activeTab].activeHeaderSection}
                        dataGridTableData={dataGridTableData}
                        draftSetListsMaintenanceGeneralDataViewData={draftSetListsMaintenanceGeneralDataViewData}
                        clearModalSearchResults={clearModalSearchResults}
                    />
                },
                {
                    title: 'Submission',
                    content: <div>
                        <DraftSetListsMaintenanceGeneralDataView
                            draftSetListsMaintenanceGridsViewData={draftSetListsMaintenanceGridsViewData}
                            setList={setList}
                            isReadonly={true}
                            lookupValues={lookupValues}
                            updateDraftSetListsField={updateDraftSetListsField}
                            showModal={showModal}
                            hideModal={hideModal}
                            isNewDraftSetList={isNewDraftSetList}
                            activeTab={activeTab}
                            tabs={tabs}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            incrementTempID={incrementTempID}
                            editableFields={[]}
                            changesMade={changesMade}
                            setEditableFields={setEditableFields}
                            activeHeaderSection={tabs[activeTab].activeHeaderSection}
                            dataGridTableData={readOnlyDataGridTableData}
                            draftSetListsMaintenanceGeneralDataViewData={draftSetListsMaintenanceGeneralDataViewData}
                            clearModalSearchResults={clearModalSearchResults}
                        />
                    </div>
                }
            ];

        if (setList.dataSource !== DataSource.Intray) {
            steps = steps.filter(s => s.title === 'Submission')
        }

        let zeroedStepsCurrent = stepsCurrent ?? 0;

        const { updatedScroll } = this.props;
        const {
            toolbarWidth
        } = this.state;

        return (
            <div className="">
                <div><span className="title">{title}</span></div>
                <If condition={tabs[activeTab].versionNumber != null}>
                    <div><span>Version history number: {tabs[activeTab].versionNumber}</span></div>
                </If>

                <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                    <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                </button>

                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <DraftSetListsMaintenanceToolbar
                            changesMade={changesMade}
                            showYesNoPrompt={showYesNoPrompt}
                            showModal={showModal}
                            hideModal={hideModal}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            toolbarData={draftSetListsMaintenanceToolbarData}
                            saveResultData={draftWorkMaintenanceSaveResultData}
                            setList={setList}
                            activeTab={activeTab}
                            tabs={tabs}
                            dataSource={dataSource}
                            saveChanges={this.saveAsDraftSetList.bind(this)}
                            isReadonly={isReadonly}
                            undoDraftSetListChanges={this.undoChanges.bind(this)}
                            addNewDraftSetList={() => addNewDraftSetList(lookupValues, currentUserNameNumber, true, draftSetListsMaintenanceGridsViewData)}
                            isNew={tabs[activeTab].draftWorkMaintenanceState?.isNew}
                            lookupValues={lookupValues}
                            copyDraftSetList={copyDraftSetList}
                            currentUser={currentUser}
                        />
                    </div>

                <MultiStepForm
                    steps={steps}
                    readonly={false}
                    saveChanges={this.saveAsDraftSetList}
                    confirmAndSubmit={this.confirmAndSubmit}
                    dataSource={dataSource}
                    current={zeroedStepsCurrent}
                    setCurrent={stepsSetCurrent}
                />
            </div>
        );
    }
}
