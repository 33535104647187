import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MEMBERS_PORTAL } from "../../../accounts/Consts";
import { clearDataIngestionCache } from "../../../redux/reducers/DataIngestionReducer";
import { clearHomeCache, setCurrentPage, toggleTopMenuIsActive } from "../../../redux/reducers/HomeReducer";
import { clearLookupCache } from "../../../redux/reducers/LookupReducer";
import { createNewDraftSetLists, createNewDraftWork,createNewDraftAgreement, mp_addTab } from "../../../redux/reducers/MembersPortalReducer";
import { addTab, clearRepertoireCache } from "../../../redux/reducers/RepertoireReducer";
import { getCustomerThunk, getUserCRMValuesThunk } from "../../../redux/thunks/AccountThunks";
import { displayCustomerLogoThunk, getMenuItemsThunk } from "../../../redux/thunks/HomeThunks";
import IAppState from "../../../redux/types/IAppState";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { Header } from "./Header";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../../redux/types/FormatFields";
import { showModal } from "../../../redux/reducers/ModalReducer";
import IMembersPortalComponentDataItem from "../../../redux/types/IMembersPortalComponentDataItem";

export default connect(
    (state: IAppState) => ({
        menuItems: state.home.menuItems,
        currentPage: state.home.currentPage,
        topMenuIsActive: state.home.topMenuIsActive,
        username: state.account.currentUser,
        isSignedIn: state.account.isSignedIn,
        enabledModules: state.home.enableModules,
        displayCustomerLogo: state.home.displayCustomerLogo,
        logoAddresses: state.home.logoAddresses,
        isLogoRequested: state.home.isLogoRequested,
        portalType: state.account.portalType,
        customer: state.account.customer,
        currentUserNameNumber: state.account.currentUserNameNumber,
        lookupValues: state.lookupEntities,
        jobs: state.job && state.job.jobs,
    }),
    (dispatch: Dispatch, ownProps) => ({
        setCurrentPage: (link: string) => dispatch(setCurrentPage(link)),
        toggleTopMenuIsActive: () => dispatch(toggleTopMenuIsActive()),
        getMenuItems: (portalType: string) => dispatch<any>(getMenuItemsThunk(portalType)),
        clearLookupCache: () => dispatch(clearLookupCache()),
        clearHomeCache: () => dispatch(clearHomeCache()),
        clearRepertoireCache: () => dispatch(clearRepertoireCache()),
        clearDataIngestionCache: () => dispatch(clearDataIngestionCache()),
        getDisplayCustomerLogo: () => dispatch<any>(displayCustomerLogoThunk()),
        getCustomer: () => dispatch<any>(getCustomerThunk()),
        addTab: (tab: ITabReduxItem, portalType: string) => { 
            portalType == MEMBERS_PORTAL ? 
                dispatch(mp_addTab(tab)) : dispatch(addTab(tab)); 
        },
        addWork: (lookups: ILookupDictionary, currentUserNumber: string, formats?: FormatFields[]) => dispatch(createNewDraftWork(lookups, currentUserNumber, true, null, formats)),
        addSetLists: (lookups: ILookupDictionary, currentUserNumber: string) => dispatch(createNewDraftSetLists(lookups, currentUserNumber, true, null)),
        addAgreements: (lookups: ILookupDictionary, currentUserNumber: string,isNew?:boolean, draftAgreementsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem) => dispatch(createNewDraftAgreement(lookups, currentUserNumber, isNew, draftAgreementsMaintenanceGeneralDataViewData)),
        getUserCRMValues: () => dispatch<any>(getUserCRMValuesThunk()),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),       
    })
)(Header);