import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { ITabReduxItem } from "../types/ITabReduxItem";
import { IDataActionToolbar } from "../../repertoire/types/IDataActionToolbar";
import { FormatFields } from "../types/FormatFields";
import { IResultsPerPage } from "../types/IResultsPerPage";
import { IActiveAccordion } from "../types/IActiveAccordion";
import { IDraftWorkSearchQuery } from "../../membersportal/types/IDraftWorkSearchQuery";
import { IDraftWorkSearchResult } from "../../membersportal/types/IDraftWorkSearchResult";
import { IDraftWorkVersionHistorySearchResult } from "../../membersportal/types/IDraftWorkVersionHistorySearchResult";
import { IValidationMessage } from "../../repertoire/types/IValidationMessage";
import IMembersPortalComponentData from "../types/IMembersPortalComponentData";
import { IRegisteredWork } from "../../membersportal/types/IRegisteredWork";
import { IRegisteredWorkVersionHistorySearchResult } from "../../membersportal/types/IRegisteredWorkVersionHistorySearchResult";
import { IRegisteredWorkSearchQuery } from "../../membersportal/types/IRegisteredWorkSearchQuery";
import { IWork } from "../../repertoire/types/IWork";
import { IWorkStateKeys } from "../types/IWorkState";
import IMembersPortalComponentDataItem from "../types/IMembersPortalComponentDataItem";
import { IMembersPortalField } from "../../membersportal/types/IMembersPortalField";
import { IWorkSaveResult } from "../../repertoire/types/IWorkSaveResult";
import { IRegisteredWorkSearchResult } from "../../membersportal/types/IRegisteredWorkSearchResult";
import { IAgreementLink } from "../../repertoire/types/IAgreementLink";
import { IP } from "../../repertoire/types/IP";
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";
import { IArtist } from "../../repertoire/types/IArtist";
import { IAttachedFile } from "../types/IAttachedFile";
import IFile from "../types/IFiles";
import { IAllRegisteredWorkSearchResult } from "../../membersportal/types/IAllRegisteredWorkSearchResult";
import { IViewAsAccessRequest } from "../../membersportal/types/IViewAsAccessRequest";
import { IMemberStatementSearchResult } from "../../membersportal/types/IMemberStatementSearchResult";
import { IDraftSetListSearchResult } from "../../membersportal/types/IDraftSetListSearchResult";
import { IRegisteredSetListsSearchResults } from "../../membersportal/types/IRegisteredSetListsSearchResults";
import { ILivePerformancesSearchResult } from "../../membersportal/types/ILivePerformancesSearchResult";
import { ISetListsStateKeys } from "../../membersportal/types/ISetListsState";
import { IProductSaveResult } from "../../repertoire/types/IProductSaveResult";
import { IProductCore } from "../../repertoire/types/IProductCore";
import { IUsageGroupStateKeys } from "../types/IUsageGroupState";
import { IUsageGroupSetListAdditionalStateKeys } from "../../repertoire/types/usageTypes/IUsageGroupSetListAdditional";
import { IUsageGroupVenueDetailsStateKeys } from "../../repertoire/types/usageTypes/IUsageGroupVenueDetails";
import { IUsageSaveResult } from "../../repertoire/types/usageTypes/IUsageSaveResult";
import { IUsageGroup } from "../../repertoire/types/usageTypes/IUsageGroup";
import { GetMatchingSourcesFailure, GetMatchingSourcesRequest, GetMatchingSourcesSuccess, GetUsageDistributionsFailure, GetUsageDistributionsRequest, GetUsageDistributionsSuccess } from "./RepertoireActions";
import { IUnpaidClaimsSearchResult } from "../../membersportal/types/IUnpaidClaimsSearchResult";
import { IClaimsHistorySearchResult } from "../../membersportal/types/IClaimsHistorySearchQuery";
import { IDraftAgreementSearchResult } from "../../membersportal/types/IDraftAgreementsSearchResult";
import { IAgreement } from "../../repertoire/types/IAgreement";
import { IRegisteredAgreementSearchResult } from "../../membersportal/types/IRegisteredAgreementSearchResult";
import { IIPsSearchResult } from "../../repertoire/types/IIPsSearchResult";
import { IAgreementStateKeys } from "../types/IAgreementState";
import { IWorksSearchResult } from "../../repertoire/types/IWorksSearchResult";
import { IAgreementSaveResult } from "../../repertoire/types/IAgreementSaveResult";
import { ICase } from "../../membersportal/types/ICase";
import { IApplication } from "../../membersportal/types/IApplication";



// Action name types
type MP_GET_DRAFT_WORK_REQUEST_TYPE = "MP_GET_DRAFT_WORK_REQUEST";
type MP_GET_DRAFT_WORK_SUCCESS_TYPE = "MP_GET_DRAFT_WORK_SUCCESS";
type MP_GET_DRAFT_WORK_FAILURE_TYPE = "MP_GET_DRAFT_WORK_FAILURE";

type MP_GET_DRAFT_WORK_ID_REQUEST_TYPE = "MP_GET_DRAFT_WORK_ID_REQUEST";
type MP_GET_DRAFT_WORK_ID_SUCCESS_TYPE = "MP_GET_DRAFT_WORK_ID_SUCCESS";
type MP_GET_DRAFT_WORK_ID_FAILURE_TYPE = "MP_GET_DRAFT_WORK_ID_FAILURE";

type MP_SAVE_DRAFT_WORK_REQUEST_TYPE = "MP_SAVE_DRAFT_WORK_REQUEST";
type MP_SAVE_DRAFT_WORK_SUCCESS_TYPE = "MP_SAVE_DRAFT_WORK_SUCCESS";
type MP_SAVE_DRAFT_WORK_FAILURE_TYPE = "MP_SAVE_DRAFT_WORK_FAILURE";

type MP_SAVE_DRAFT_SET_LISTS_REQUEST_TYPE = "MP_SAVE_DRAFT_SET_LISTS_REQUEST";
type MP_SAVE_DRAFT_SET_LISTS_SUCCESS_TYPE = "MP_SAVE_DRAFT_SET_LISTS_SUCCESS";
type MP_SAVE_DRAFT_SET_LISTS_FAILURE_TYPE = "MP_SAVE_DRAFT_SET_LISTS_FAILURE";

type MP_SAVE_DRAFT_AGREEMENT_REQUEST_TYPE = "MP_SAVE_DRAFT_AGREEMENT_REQUEST";
type MP_SAVE_DRAFT_AGREEMENT_SUCCESS_TYPE = "MP_SAVE_DRAFT_AGREEMENT_SUCCESS";
type MP_SAVE_DRAFT_AGREEMENT_FAILURE_TYPE = "MP_SAVE_DRAFT_AGREEMENT_FAILURE";


type MP_DELETE_DRAFT_WORK_REQUEST_TYPE = "MP_DELETE_DRAFT_WORK_REQUEST";
type MP_DELETE_DRAFT_WORK_SUCCESS_TYPE = "MP_DELETE_DRAFT_WORK_SUCCESS";
type MP_DELETE_DRAFT_WORK_FAILURE_TYPE = "MP_DELETE_DRAFT_WORK_FAILURE";
type MP_DELETE_DRAFT_SET_LIST_FAILURE_TYPE = "MP_DELETE_DRAFT_SET_LIST_FAILURE";
type MP_DELETE_DRAFT_SET_LIST_REQUEST_TYPE = "MP_DELETE_DRAFT_SET_LIST_REQUEST";
type MP_DELETE_DRAFT_SET_LIST_SUCCESS_TYPE = "MP_DELETE_DRAFT_SET_LIST_SUCCESS";

type MP_UPDATE_DRAFT_WORK_FIELD_TYPE = "MP_UPDATE_DRAFT_WORK_FIELD"
type MP_UPDATE_DRAFT_AGREEMENT_FIELD_TYPE = "MP_UPDATE_DRAFT_AGREEMENT_FIELD"
type MP_UPDATE_DRAFT_SET_LISTS_FIELD_TYPE = "MP_UPDATE_DRAFT_SET_LISTS_FIELD"
type MP_UPDATE_LIVE_PERFORMANCE_FIELD_TYPE = "MP_UPDATE_LIVE_PERFORMANCE_FIELD"
type MP_UPDATE_LIVE_PERFORMANCE_SET_LIST_ADDITIONAL_FIELD_TYPE = "MP_UPDATE_LIVE_PERFORMANCE_SET_LIST_ADDITIONAL_FIELD"
type MP_UPDATE_LIVE_PERFORMANCE_VENUE_DETAILS_FIELD_TYPE = "MP_UPDATE_LIVE_PERFORMANCE_VENUE_DETAILS_FIELD"
type MP_SAVE_DRAFT_LIVE_PERFORMANCE_FAILURE_TYPE = "MP_SAVE_DRAFT_LIVE_PERFORMANCE_FAILURE";
type MP_SAVE_DRAFT_LIVE_PERFORMANCE_SUCCESS_TYPE = "MP_SAVE_DRAFT_LIVE_PERFORMANCE_SUCCESS";

type MP_SAVE_WORK_CONTRIBUTOR_COMMENT_TYPE = "MP_SAVE_WORK_CONTRIBUTOR_COMMENT";


type MP_CREATE_NEW_DRAFT_WORK_TYPE = "MP_CREATE_NEW_DRAFT_WORK";
type MP_CREATE_NEW_DRAFT_AGREEMENT_TYPE = "MP_CREATE_NEW_DRAFT_AGREEMENT";
type MP_CREATE_NEW_DRAFT_SET_LISTS_TYPE = "MP_CREATE_NEW_DRAFT_SET_LISTS";
type MP_COPY_DRAFT_WORK_TYPE = "MP_COPY_DRAFT_WORK";
type MP_CREATE_NEW_DRAFT_LIVE_PERFORMANCE_TYPE = "MP_CREATE_NEW_DRAFT_LIVE_PERFORMANCE";

type MP_CREATE_NEW_LIVE_PERFORMANCE_TYPE = "MP_CREATE_NEW_LIVE_PERFORMANCE";

type MP_UNDO_DRAFT_WORK_CHANGES_REQUEST_TYPE = "MP_UNDO_DRAFT_WORK_CHANGES_REQUEST";
type MP_UNDO_DRAFT_WORK_CHANGES_SUCCESS_TYPE = "MP_UNDO_DRAFT_WORK_CHANGES_SUCCESS";
type MP_UNDO_DRAFT_WORK_CHANGES_FAILURE_TYPE = "MP_UNDO_DRAFT_WORK_CHANGES_FAILURE";

type MP_UNDO_DRAFT_SET_LIST_CHANGES_REQUEST_TYPE = "MP_UNDO_DRAFT_SET_LIST_CHANGES_REQUEST";
type MP_UNDO_DRAFT_SET_LIST_CHANGES_SUCCESS_TYPE = "MP_UNDO_DRAFT_SET_LIST_CHANGES_SUCCESS";
type MP_UNDO_DRAFT_SET_LIST_CHANGES_FAILURE_TYPE = "MP_UNDO_DRAFT_SET_LIST_CHANGES_FAILURE";

type MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_REQUEST_TYPE = "MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_REQUEST";
type MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_SUCCESS_TYPE = "MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_SUCCESS";
type MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_FAILURE_TYPE = "MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_FAILURE";

type MP_SEARCH_DRAFT_WORKS_REQUEST_TYPE = "MP_SEARCH_DRAFT_WORKS_REQUEST";
type MP_SEARCH_DRAFT_WORKS_SUCCESS_TYPE = "MP_SEARCH_DRAFT_WORKS_SUCCESS";
type MP_SEARCH_DRAFT_WORKS_FAILURE_TYPE = "MP_SEARCH_DRAFT_WORKS_FAILURE";

type MP_SEARCH_DRAFT_AGREEMENTS_REQUEST_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_REQUEST";
type MP_SEARCH_DRAFT_AGREEMENTS_SUCCESS_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_SUCCESS";
type MP_SEARCH_DRAFT_AGREEMENTS_FAILURE_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_FAILURE";

type MP_SEARCH_DRAFT_AGREEMENTS_IP_REQUEST_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_IP_REQUEST";
type MP_SEARCH_DRAFT_AGREEMENTS_IP_SUCCESS_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_IP_SUCCESS";
type MP_SEARCH_DRAFT_AGREEMENTS_IP_FAILURE_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_IP_FAILURE";

type MP_SEARCH_DRAFT_AGREEMENTS_WORKS_REQUEST_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_WORKS_REQUEST";
type MP_SEARCH_DRAFT_AGREEMENTS_WORKS_SUCCESS_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_WORKS_SUCCESS";
type MP_SEARCH_DRAFT_AGREEMENTS_WORKS_FAILURE_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_WORKS_FAILURE";

type MP_SEARCH_REGISTERED_AGREEMENTS_REQUEST_TYPE = "MP_SEARCH_REGISTERED_AGREEMENTS_REQUEST";
type MP_SEARCH_REGISTERED_AGREEMENTS_SUCCESS_TYPE = "MP_SEARCH_REGISTERED_AGREEMENTS_SUCCESS";
type MP_SEARCH_REGISTERED_AGREEMENTS_FAILURE_TYPE = "MP_SEARCH_REGISTERED_AGREEMENTS_FAILURE";


type MP_UPDATE_DRAFT_AGREEMENT_WORK_FIELD_TYPE = "MP_UPDATE_DRAFT_AGREEMENT_WORK_FIELD";

type MP_SEARCH_DRAFT_SET_LISTS_REQUEST_TYPE = "MP_SEARCH_DRAFT_SET_LISTS_REQUEST";
type MP_SEARCH_DRAFT_SET_LISTS_SUCCESS_TYPE = "MP_SEARCH_DRAFT_SET_LISTS_SUCCESS";
type MP_SEARCH_DRAFT_SET_LISTS_FAILURE_TYPE = "MP_SEARCH_DRAFT_SET_LISTS_FAILURE";
type MP_COPY_DRAFT_SET_LIST_TYPE = "MP_COPY_DRAFT_SET_LIST";
type MP_COPY_DRAFT_LIVE_PERFORMANCE_TYPE = "MP_COPY_DRAFT_LIVE_PERFORMANCE";

type MP_GET_REGISTERED_WORK_REQUEST_TYPE = "MP_GET_REGISTERED_WORK_REQUEST";
type MP_GET_REGISTERED_WORK_SUCCESS_TYPE = "MP_GET_REGISTERED_WORK_SUCCESS";
type MP_GET_REGISTERED_WORK_FAILURE_TYPE = "MP_GET_REGISTERED_WORK_FAILURE";

type MP_GET_REGISTERED_WORK_ID_REQUEST_TYPE = "MP_GET_REGISTERED_WORK_ID_REQUEST";
type MP_GET_REGISTERED_WORK_ID_SUCCESS_TYPE = "MP_GET_REGISTERED_WORK_ID_SUCCESS";
type MP_GET_REGISTERED_WORK_ID_FAILURE_TYPE = "MP_GET_REGISTERED_WORK_ID_FAILURE";

type MP_SAVE_REGISTERED_WORK_REQUEST_TYPE = "MP_SAVE_REGISTERED_WORK_REQUEST";
type MP_SAVE_REGISTERED_WORK_SUCCESS_TYPE = "MP_SAVE_REGISTERED_WORK_SUCCESS";
type MP_SAVE_REGISTERED_WORK_FAILURE_TYPE = "MP_SAVE_REGISTERED_WORK_FAILURE";

type MP_DELETE_REGISTERED_WORK_REQUEST_TYPE = "MP_DELETE_REGISTERED_WORK_REQUEST";
type MP_DELETE_REGISTERED_WORK_SUCCESS_TYPE = "MP_DELETE_REGISTERED_WORK_SUCCESS";
type MP_DELETE_REGISTERED_WORK_FAILURE_TYPE = "MP_DELETE_REGISTERED_WORK_FAILURE";

type MP_UPDATE_REGISTERED_WORK_FIELD_TYPE = "MP_UPDATE_REGISTERED_WORK_FIELD"

type MP_CREATE_NEW_REGISTERED_WORK_TYPE = "MP_CREATE_NEW_REGISTERED_WORK";
type MP_COPY_REGISTERED_WORK_TYPE = "MP_COPY_REGISTERED_WORK";

type MP_UNDO_REGISTERED_WORK_CHANGES_REQUEST_TYPE = "MP_UNDO_REGISTERED_WORK_CHANGES_REQUEST";
type MP_UNDO_REGISTERED_WORK_CHANGES_SUCCESS_TYPE = "MP_UNDO_REGISTERED_WORK_CHANGES_SUCCESS";
type MP_UNDO_REGISTERED_WORK_CHANGES_FAILURE_TYPE = "MP_UNDO_REGISTERED_WORK_CHANGES_FAILURE";

type MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_REQUEST_TYPE = "MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_REQUEST";
type MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_SUCCESS_TYPE = "MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_SUCCESS";
type MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_FAILURE_TYPE = "MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_FAILURE";

type MP_SEARCH_REGISTERED_WORKS_REQUEST_TYPE = "MP_SEARCH_REGISTERED_WORKS_REQUEST";
type MP_SEARCH_REGISTERED_WORKS_SUCCESS_TYPE = "MP_SEARCH_REGISTERED_WORKS_SUCCESS";
type MP_SEARCH_REGISTERED_WORKS_FAILURE_TYPE = "MP_SEARCH_REGISTERED_WORKS_FAILURE";

type MP_SEARCH_REGISTERED_SET_LISTS_REQUEST_TYPE = "MP_SEARCH_REGISTERED_SET_LISTS_REQUEST";
type MP_SEARCH_REGISTERED_SET_LISTS_SUCCESS_TYPE = "MP_SEARCH_REGISTERED_SET_LISTS_SUCCESS";
type MP_SEARCH_REGISTERED_SET_LISTS_FAILURE_TYPE = "MP_SEARCH_REGISTERED_SET_LISTS_FAILURE";

type MP_SEARCH_ALL_REGISTERED_WORKS_REQUEST_TYPE = "MP_SEARCH_ALL_REGISTERED_WORKS_REQUEST";
type MP_SEARCH_ALL_REGISTERED_WORKS_SUCCESS_TYPE = "MP_SEARCH_ALL_REGISTERED_WORKS_SUCCESS";
type MP_SEARCH_ALL_REGISTERED_WORKS_FAILURE_TYPE = "MP_SEARCH_ALL_REGISTERED_WORKS_FAILURE";


type MP_GET_DRAFT_WORK_DETAILS_REQUEST_TYPE = "MP_GET_DRAFT_WORK_DETAILS_REQUEST";
type MP_GET_DRAFT_WORK_DETAILS_SUCCESS_TYPE = "MP_GET_DRAFT_WORK_DETAILS_SUCCESS";
type MP_GET_DRAFT_WORK_DETAILS_FAILURE_TYPE = "MP_GET_DRAFT_WORK_DETAILS_FAILURE";

type MP_GET_DRAFT_AGREEMENT_DETAILS_REQUEST_TYPE = "MP_GET_DRAFT_AGREEMENT_DETAILS_REQUEST";
type MP_GET_DRAFT_AGREEMENT_DETAILS_SUCCESS_TYPE = "MP_GET_DRAFT_AGREEMENT_DETAILS_SUCCESS";
type MP_GET_DRAFT_AGREEMENT_DETAILS_FAILURE_TYPE = "MP_GET_DRAFT_AGREEMENT_DETAILS_FAILURE";

type MP_GET_REGISTERED_WORK_DETAILS_REQUEST_TYPE = "MP_GET_REGISTERED_WORK_DETAILS_REQUEST";
type MP_GET_REGISTERED_WORK_DETAILS_SUCCESS_TYPE = "MP_GET_REGISTERED_WORK_DETAILS_SUCCESS";
type MP_GET_REGISTERED_WORK_DETAILS_FAILURE_TYPE = "MP_GET_REGISTERED_WORK_DETAILS_FAILURE";

type MP_GET_DRAFT_SET_LIST_DETAILS_REQUEST_TYPE = "MP_GET_DRAFT_SET_LIST_DETAILS_REQUEST";
type MP_GET_DRAFT_SET_LIST_DETAILS_SUCCESS_TYPE = "MP_GET_DRAFT_SET_LIST_DETAILS_SUCCESS";
type MP_GET_DRAFT_SET_LIST_DETAILS_FAILURE_TYPE = "MP_GET_DRAFT_SET_LIST_DETAILS_FAILURE";

type MP_GET_REGISTERED_SET_LIST_DETAILS_REQUEST_TYPE = "MP_GET_REGISTERED_SET_LIST_DETAILS_REQUEST";
type MP_GET_REGISTERED_SET_LIST_DETAILS_SUCCESS_TYPE = "MP_GET_REGISTERED_SET_LIST_DETAILS_SUCCESS";
type MP_GET_REGISTERED_SET_LIST_DETAILS_FAILURE_TYPE = "MP_GET_REGISTERED_SET_LIST_DETAILS_FAILURE";

type MP_GET_ALL_REGISTERED_WORK_DETAILS_REQUEST_TYPE = "MP_GET_ALL_REGISTERED_WORK_DETAILS_REQUEST";
type MP_GET_ALL_REGISTERED_WORK_DETAILS_SUCCESS_TYPE = "MP_GET_ALL_REGISTERED_WORK_DETAILS_SUCCESS";
type MP_GET_ALL_REGISTERED_WORK_DETAILS_FAILURE_TYPE = "MP_GET_ALL_REGISTERED_WORK_DETAILS_FAILURE";


type MP_UPDATE_WORK_CONTRIBUTOR_IP_REQUEST_TYPE = "MP_UPDATE_WORK_CONTRIBUTOR_IP_REQUEST";
type MP_UPDATE_WORK_CONTRIBUTOR_IP_SUCCESS_TYPE = "MP_UPDATE_WORK_CONTRIBUTOR_IP_SUCCESS";
type MP_UPDATE_WORK_CONTRIBUTOR_IP_FAILURE_TYPE = "MP_UPDATE_WORK_CONTRIBUTOR_IP_FAILURE";

type MP_SEARCH_CONTRIBUTORS_REQUEST_TYPE = "MP_SEARCH_CONTRIBUTORS_REQUEST";
type MP_SEARCH_CONTRIBUTORS_SUCCESS_TYPE = "MP_SEARCH_CONTRIBUTORS_SUCCESS";
type MP_SEARCH_CONTRIBUTORS_FAILURE_TYPE = "MP_SEARCH_CONTRIBUTORS_FAILURE";

type MP_SEARCH_ARTISTS_REQUEST_TYPE = "MP_SEARCH_ARTISTS_REQUEST";
type MP_SEARCH_ARTISTS_SUCCESS_TYPE = "MP_SEARCH_ARTISTS_SUCCESS";
type MP_SEARCH_ARTISTS_FAILURE_TYPE = "MP_SEARCH_ARTISTS_FAILURE";

type MP_ADD_ARTIST_TO_WORK_TYPE = "MP_ADD_ARTIST_TO_WORK";

type MP_ADD_REPERTOIRE_ATTACHMENT_REQUEST_TYPE = 'MP_ADD_REPERTOIRE_ATTACHMENT_REQUEST';
type MP_ADD_REPERTOIRE_ATTACHMENT_SUCCESS_TYPE = 'MP_ADD_REPERTOIRE_ATTACHMENT_SUCCESS';
type MP_ADD_REPERTOIRE_ATTACHMENT_FAILURE_TYPE = 'MP_ADD_REPERTOIRE_ATTACHMENT_FAILURE';

type MP_REMOVE_WORK_ATTACHMENT_REQUEST_TYPE = 'MP_REMOVE_WORK_ATTACHMENT_REQUEST';
type MP_REMOVE_WORK_ATTACHMENT_SUCCESS_TYPE = 'MP_REMOVE_WORK_ATTACHMENT_SUCCESS';
type MP_REMOVE_WORK_ATTACHMENT_FAILURE_TYPE = 'MP_REMOVE_WORK_ATTACHMENT_FAILURE';

type MP_ADD_DROPPED_FILE_TYPE = 'MP_ADD_DROPPED_FILE';
type MP_SET_NEW_PROGRESS_UPLOAD_FILE_REPERTOIRE_PROCCESS_TYPE = "MP_SET_NEW_PROGRESS_UPLOAD_FILE_REPERTOIRE_PROCCESS";
type MP_CLEAN_DROPPED_FILES_STATE_TYPE = 'MP_CLEAN_DROPPED_FILES_STATE'

type MP_SEARCH_REQUEST_ACCESS_IP_TYPE = 'MP_SEARCH_REQUEST_ACCESS_IP';
type MP_SEARCH_REQUEST_ACCESS_IP_SUCCESS_TYPE = 'MP_SEARCH_REQUEST_ACCESS_IP_SUCCESS';
type MP_SEARCH_REQUEST_ACCESS_IP_FAILURE_TYPE = 'MP_SEARCH_REQUEST_ACCESS_IP_FAILURE';

type MP_REQUEST_VIEW_AS_ACCESS_TYPE = 'MP_REQUEST_VIEW_AS_ACCESS';
type MP_REQUEST_VIEW_AS_ACCESS_SUCCESS_TYPE = 'MP_REQUEST_VIEW_AS_ACCESS_SUCCESS';
type MP_REQUEST_VIEW_AS_ACCESS_FAILURE_TYPE = 'MP_REQUEST_VIEW_AS_ACCESS_FAILURE';

type MP_GET_VIEW_AS_ACCESS_REQUESTS_TYPE = 'MP_GET_VIEW_AS_ACCESS_REQUESTS';
type MP_GET_VIEW_AS_ACCESS_REQUESTS_SUCCESS_TYPE = 'MP_GET_VIEW_AS_ACCESS_REQUESTS_SUCCESS';
type MP_GET_VIEW_AS_ACCESS_REQUESTS_FAILURE_TYPE = 'MP_GET_VIEW_AS_ACCESS_REQUESTS_FAILURE';

type MP_GET_MY_APPLICATIONS_TYPE = 'MP_GET_MY_APPLICATIONS';
type MP_GET_MY_APPLICATIONS_SUCCESS_TYPE = 'MP_GET_MY_APPLICATIONS_SUCCESS';
type MP_GET_MY_APPLICATIONS_FAILURE_TYPE = 'MP_GET_MY_APPLICATIONS_FAILURE';

type MP_GET_MY_CASES_TYPE = 'MP_GET_MY_CASES';
type MP_GET_MY_CASES_SUCCESS_TYPE = 'MP_GET_MY_CASES_SUCCESS';
type MP_GET_MY_CASES_FAILURE_TYPE = 'MP_GET_MY_CASES_FAILURE';

type MP_SAVE_VIEW_AS_ACCESS_TYPE = 'MP_SAVE_VIEW_AS_ACCESS';
type MP_SAVE_VIEW_AS_ACCESS_SUCCESS_TYPE = 'MP_SAVE_VIEW_AS_ACCESS_SUCCESS';
type MP_SAVE_VIEW_AS_ACCESS_FAILURE_TYPE = 'MP_SAVE_VIEW_AS_ACCESS_FAILURE';
type MP_RESET_VIEW_AS_SAVE_STATUS_TYPE = 'MP_RESET_VIEW_AS_SAVE_STATUS';

type MP_SEARCH_STATEMENTS_REQUEST_TYPE = "MP_SEARCH_STATEMENTS_REQUEST";
type MP_SEARCH_STATEMENTS_SUCCESS_TYPE = "MP_SEARCH_STATEMENTS_SUCCESS";
type MP_SEARCH_STATEMENTS_FAILURE_TYPE = "MP_SEARCH_STATEMENTS_FAILURE";

type MP_SEARCH_UNPAID_CLAIMS_REQUEST_TYPE = "MP_SEARCH_UNPAID_CLAIMS_REQUEST";
type MP_SEARCH_UNPAID_CLAIMS_SUCCESS_TYPE = "MP_SEARCH_UNPAID_CLAIMS_SUCCESS";
type MP_SEARCH_UNPAID_CLAIMS_FAILURE_TYPE = "MP_SEARCH_UNPAID_CLAIMS_FAILURE";

type MP_SEARCH_CLAIMS_HISTORY_REQUEST_TYPE = "MP_SEARCH_CLAIMS_HISTORY_REQUEST";
type MP_SEARCH_CLAIMS_HISTORY_SUCCESS_TYPE = "MP_SEARCH_CLAIMS_HISTORY_SUCCESS";
type MP_SEARCH_CLAIMS_HISTORY_FAILURE_TYPE = "MP_SEARCH_CLAIMS_HISTORY_FAILURE";
type MP_CLAIM_UNPAID_USAGE_REQUEST_TYPE = "MP_CLAIM_UNPAID_USAGE_REQUEST";
type MP_CLAIM_UNPAID_USAGE_SUCCESS_TYPE = "MP_CLAIM_UNPAID_USAGE_SUCCESS";
type MP_CLAIM_UNPAID_USAGE_FAILURE_TYPE = "MP_CLAIM_UNPAID_USAGE_FAILURE";

type MP_GET_STATEMENT_DETAILS_REQUEST_TYPE = "MP_GET_STATEMENT_DETAILS_REQUEST";
type MP_GET_STATEMENT_DETAILS_SUCCESS_TYPE = "MP_GET_STATEMENT_DETAILS_SUCCESS";
type MP_GET_STATEMENT_DETAILS_FAILURE_TYPE = "MP_GET_STATEMENT_DETAILS_FAILURE";

type MP_CANCEL_ACCESS_REQUEST_TYPE = 'MP_CANCEL_ACCESS_REQUEST';
type MP_CANCEL_ACCESS_REQUEST_SUCCESS_TYPE = 'MP_CANCEL_ACCESS_REQUEST_SUCCESS';
type MP_CANCEL_ACCESS_REQUEST_FAILURE_TYPE = 'MP_CANCEL_ACCESS_REQUEST_FAILURE';

type MP_SEARCH_LIVE_PERFORMANCES_REQUEST_TYPE = "MP_SEARCH_LIVE_PERFORMANCES_REQUEST";
type MP_SEARCH_LIVE_PERFORMANCES_SUCCESS_TYPE = "MP_SEARCH_LIVE_PERFORMANCES_SUCCESS";
type MP_SEARCH_LIVE_PERFORMANCES_FAILURE_TYPE = "MP_SEARCH_LIVE_PERFORMANCES_FAILURE";

type MP_GET_LIVE_PERFORMANCE_DETAILS_REQUEST_TYPE = "MP_GET_LIVE_PERFORMANCE_DETAILS_REQUEST";
type MP_GET_LIVE_PERFORMANCE_DETAILS_SUCCESS_TYPE = "MP_GET_LIVE_PERFORMANCE_DETAILS_SUCCESS";
type MP_GET_LIVE_PERFORMANCE_DETAILS_FAILURE_TYPE = "MP_GET_LIVE_PERFORMANCE_DETAILS_FAILURE";

type MP_GET_SHARE_DECIMALS_TO_DISPLAY_REQUEST_TYPE = 'MP_GET_SHARE_DECIMALS_TO_DISPLAY_REQUEST';
type MP_GET_SHARE_DECIMALS_TO_DISPLAY_SUCCESS_TYPE = 'MP_GET_SHARE_DECIMALS_TO_DISPLAY_SUCCESS';
type MP_GET_SHARE_DECIMALS_TO_DISPLAY_FAILURE_TYPE = 'MP_GET_SHARE_DECIMALS_TO_DISPLAY_FAILURE';

type MP_REFRESH_WORK_DETAILS_REQUEST_TYPE = "MP_REFRESH_WORK_DETAILS_REQUEST";
type MP_REFRESH_WORK_DETAILS_SUCCESS_TYPE = "MP_REFRESH_WORK_DETAILS_SUCCESS";
type MP_REFRESH_WORK_DETAILS_FAILURE_TYPE = "MP_REFRESH_WORK_DETAILS_FAILURE";

type MP_REFRESH_AGREEMENT_DETAILS_REQUEST_TYPE = "MP_REFRESH_AGREEMENT_DETAILS_REQUEST";
type MP_REFRESH_AGREEMENT_DETAILS_SUCCESS_TYPE = "MP_REFRESH_AGREEMENT_DETAILS_SUCCESS";
type MP_REFRESH_AGREEMENT_DETAILS_FAILURE_TYPE = "MP_REFRESH_AGREEMENT_DETAILS_FAILURE";


type MP_REDUX_STORAGE_LOAD_TYPE = "MP_REDUX_STORAGE_LOAD";
type MP_ADD_TAB_TYPE = "MP_ADD_TAB";
type MP_REPLACE_TAB_TYPE = "MP_REPLACE_TAB";
type MP_REMOVE_TAB_TYPE = "MP_REMOVE_TAB";
type MP_OPEN_TAB_TYPE = "MP_OPEN_TAB";


type MP_SET_WARNING_MESSAGE_TYPE = 'MP_SET_WARNING_MESSAGE';

type MP_INCREMENT_TEMPID_TYPE = "MP_INCREMENT_TEMPID";

type MP_REGISTER_STEPS_SET_CURRENT_TYPE = "MP_REGISTER_STEPS_SET_CURRENT";

type MP_RESET_MESSAGE_BANNER_TYPE = "MP_RESET_MESSAGE_BANNER";

type ADD_WORK_TO_SET_LIST_TYPE = "MP_ADD_WORK_TO_SET_LIST";

type ADD_SET_LIST_TO_LIVE_PERFORMANCE_TYPE = "MP_ADD_SET_LIST_TO_LIVE_PERFORMANCE_TYPE";

type MP_GET_CRM_INTEGRATION_ENABLED_REQUEST_TYPE = "MP_GET_CRM_INTEGRATION_ENABLED_REQUEST_TYPE";
type MP_GET_CRM_INTEGRATION_ENABLED_SUCCESS_TYPE = "MP_GET_CRM_INTEGRATION_ENABLED_SUCCESS_TYPE";
type MP_GET_CRM_INTEGRATION_ENABLED_FAILURE_TYPE = "MP_GET_CRM_INTEGRATION_ENABLED_FAILURE_TYPE";

type MP_GET_CRM_POWER_PORTAL_BASE_URL_REQUEST_TYPE = "MP_GET_CRM_POWER_PORTAL_BASE_URL_REQUEST";
type MP_GET_CRM_POWER_PORTAL_BASE_URL_SUCCESS_TYPE = "MP_GET_CRM_POWER_PORTAL_BASE_URL_SUCCESS";
type MP_GET_CRM_POWER_PORTAL_BASE_URL_FAILURE_TYPE = "MP_GET_CRM_POWER_PORTAL_BASE_URL_FAILURE";


export const MP_REDUX_STORAGE_LOAD: MP_REDUX_STORAGE_LOAD_TYPE = "MP_REDUX_STORAGE_LOAD";
export const MP_ADD_TAB: MP_ADD_TAB_TYPE = "MP_ADD_TAB";
export const MP_REPLACE_TAB: MP_REPLACE_TAB_TYPE = "MP_REPLACE_TAB";
export const MP_REMOVE_TAB: MP_REMOVE_TAB_TYPE = "MP_REMOVE_TAB";
export const MP_OPEN_TAB: MP_OPEN_TAB_TYPE = "MP_OPEN_TAB";

export interface CreateNewDraftWork {
    type: MP_CREATE_NEW_DRAFT_WORK_TYPE;
    payload: {
        lookups: ILookupDictionary,
        isNew?: boolean,
        draftWorkMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem,
        formats?: FormatFields[];
        currentUserNameNumber?: string;
    }
}
export interface CreateNewDraftAgreement {
    type: MP_CREATE_NEW_DRAFT_AGREEMENT_TYPE;
    payload: {
        lookups: ILookupDictionary,
        isNew?: boolean,
        draftAgreementMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem,
        formats?: FormatFields[];
        currentUserNameNumber?: string;
    }
}
export interface CreateNewDraftSetLists {
    type: MP_CREATE_NEW_DRAFT_SET_LISTS_TYPE;
    payload: {
        lookups: ILookupDictionary,
        isNew?: boolean,
        draftSetListsMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem,
        formats?: FormatFields[];
        currentUserNameNumber?: string;
    }
}

export interface CreateNewDraftLivePerformance {
    type: MP_CREATE_NEW_LIVE_PERFORMANCE_TYPE;
    payload: {
        lookups: ILookupDictionary,
        isNew?: boolean,
        livePerformanceMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem,
        formats?: FormatFields[],
        distributionCode?: string;
        currentUserNameNumber?: string;
    }
}

export interface CopyExistingDraftWork {
    type: MP_COPY_DRAFT_WORK_TYPE;
    payload: {
        actionList: IDataActionToolbar[],
        userName: string,
        lookups: ILookupDictionary

    }
}

export interface CopyExistingDraftLivePerformance {
    type: MP_COPY_DRAFT_LIVE_PERFORMANCE_TYPE;
    payload: {
        actionList: IDataActionToolbar[],
        userName: string,
        lookups: ILookupDictionary

    }
}

export interface CopyExistingDraftSetList {
    type: MP_COPY_DRAFT_SET_LIST_TYPE;
    payload: {
        actionList: IDataActionToolbar[],
        userName: string,
        lookups: ILookupDictionary

    }
}

export interface UndoDraftWorkChangesRequest {
    type: MP_UNDO_DRAFT_WORK_CHANGES_REQUEST_TYPE;
}

export interface UndoDraftWorkChangesSuccess {
    type: MP_UNDO_DRAFT_WORK_CHANGES_SUCCESS_TYPE;
    payload: {
        work: IWork,
        lookupValues: ILookupDictionary
    }
}

export interface UndoDraftWorkChangesFailure {
    type: MP_UNDO_DRAFT_WORK_CHANGES_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface UndoDraftSetListChangesRequest {
    type: MP_UNDO_DRAFT_SET_LIST_CHANGES_REQUEST_TYPE;
}

export interface UndoDraftSetListChangesSuccess {
    type: MP_UNDO_DRAFT_SET_LIST_CHANGES_SUCCESS_TYPE;
    payload: {
        product: IProductCore,
        lookupValues: ILookupDictionary
    }
}

export interface UndoDraftSetListChangesFailure {
    type: MP_UNDO_DRAFT_SET_LIST_CHANGES_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface SearchDraftWorkVersionHistoryRequest {
    type: MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_REQUEST_TYPE;
}

export interface SearchDraftWorkVersionHistorySuccess {
    type: MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_SUCCESS_TYPE;
    payload: {
        results: IDraftWorkVersionHistorySearchResult[]
    }
}

export interface SearchDraftWorkVersionHistoryFailure {
    type: MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_FAILURE_TYPE;
}




export interface SaveDraftWorkRequest {
    type: MP_SAVE_DRAFT_WORK_REQUEST_TYPE
}

export interface SaveDraftWorkSuccess {
    type: MP_SAVE_DRAFT_WORK_SUCCESS_TYPE;
    payload: {
        workSaveResult: IWorkSaveResult;
        lookupValues: ILookupDictionary
    }
}

export interface SaveDraftWorkFailure {
    type: MP_SAVE_DRAFT_WORK_FAILURE_TYPE;
    payload: {
        workSaveResult: IWorkSaveResult;
        lookupValues: ILookupDictionary
    }
}

export interface SaveDraftSetListsRequest {
    type: MP_SAVE_DRAFT_SET_LISTS_REQUEST_TYPE
}

export interface SaveDraftSetListsSuccess {
    type: MP_SAVE_DRAFT_SET_LISTS_SUCCESS_TYPE;
    payload: {
        setListsSaveResult: IProductSaveResult;
    }
}

export interface SaveDraftAgreementRequest {
    type: MP_SAVE_DRAFT_AGREEMENT_REQUEST_TYPE
}

export interface SaveDraftAgreementSuccess {
    type: MP_SAVE_DRAFT_AGREEMENT_SUCCESS_TYPE;
    payload: {
        agreementSaveResult: IAgreementSaveResult;
    }
}
export interface SaveDraftAgreementFailure {
    type: MP_SAVE_DRAFT_AGREEMENT_FAILURE_TYPE;
    payload: {
        agreementSaveResult: IAgreementSaveResult;
    }
}

export interface SaveDraftLivePerformanceSuccess {
    type: MP_SAVE_DRAFT_LIVE_PERFORMANCE_SUCCESS_TYPE;
    payload: {
        usageSaveResult: IUsageSaveResult;
    }
}

export interface SaveDraftSetListsFailure {
    type: MP_SAVE_DRAFT_SET_LISTS_FAILURE_TYPE;
    payload: {
        setListsSaveResult: IProductSaveResult;
    }
}

export interface SaveDraftLivePerformanceFailure {
    type: MP_SAVE_DRAFT_LIVE_PERFORMANCE_FAILURE_TYPE;
    payload: {
        usageSaveResult: IUsageSaveResult;
    }
}


export interface DeleteDraftWorkRequest {
    type: MP_DELETE_DRAFT_WORK_REQUEST_TYPE;
}

export interface DeleteDraftWorkSuccess {
    type: MP_DELETE_DRAFT_WORK_SUCCESS_TYPE;
    payload: {
        workId: number;
    }
}

export interface DeleteDraftWorkFailure {
    type: MP_DELETE_DRAFT_WORK_FAILURE_TYPE;
    payload: {
        error?: IValidationMessage;
    }
}


export interface DeleteDraftSetListRequest {
    type: MP_DELETE_DRAFT_SET_LIST_REQUEST_TYPE;
}

export interface DeleteDraftSetListSuccess {
    type: MP_DELETE_DRAFT_SET_LIST_SUCCESS_TYPE;
    payload: {
        workId: number;
    }
}

export interface DeleteDraftSetListFailure {
    type: MP_DELETE_DRAFT_SET_LIST_FAILURE_TYPE;
    payload: {
        error?: IValidationMessage;
    }
}


export interface DeleteDraftSetListRequest {
    type: MP_DELETE_DRAFT_SET_LIST_REQUEST_TYPE;
}

export interface DeleteDraftSetListSuccess {
    type: MP_DELETE_DRAFT_SET_LIST_SUCCESS_TYPE;
    payload: {
        workId: number;
    }
}

export interface DeleteDraftSetListFailure {
    type: MP_DELETE_DRAFT_SET_LIST_FAILURE_TYPE;
    payload: {
        error?: IValidationMessage;
    }
}

export interface SearchDraftSetListsRequest {
    type: MP_SEARCH_DRAFT_SET_LISTS_REQUEST_TYPE;
}

export interface SearchDraftSetListsSuccess {
    type: MP_SEARCH_DRAFT_SET_LISTS_SUCCESS_TYPE;
    payload: {
        setListsResult: IDraftSetListSearchResult[];
    };
}

export interface SearchDraftSetListsFailure {
    type: MP_SEARCH_DRAFT_SET_LISTS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchDraftWorksRequest {
    type: MP_SEARCH_DRAFT_WORKS_REQUEST_TYPE;
    payload: {
        isModalSearch?: boolean;
    }
}

export interface SearchDraftWorksSuccess {
    type: MP_SEARCH_DRAFT_WORKS_SUCCESS_TYPE;
    payload: {
        draftWorksResult: IDraftWorkSearchResult[];
    };
}

export interface SearchDraftWorksFailure {
    type: MP_SEARCH_DRAFT_WORKS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchDraftAgreementsRequest {
    type: MP_SEARCH_DRAFT_AGREEMENTS_REQUEST_TYPE;
    payload: {
        isModalSearch?: boolean;
    }
}
export interface SearchDraftAgreementsIPRequest {
    type: MP_SEARCH_DRAFT_AGREEMENTS_IP_REQUEST_TYPE;
    payload: {
        isModalSearch?: boolean;
    }
}


export interface SearchDraftAgreementsWorksRequest {
    type: MP_SEARCH_DRAFT_AGREEMENTS_WORKS_REQUEST_TYPE;
    payload: {
        isModalSearch?: boolean;
    }
}


export interface SearchDraftAgreementsSuccess {
    type: MP_SEARCH_DRAFT_AGREEMENTS_SUCCESS_TYPE;
    payload: {
        draftAgreementsResult: IDraftAgreementSearchResult[];
    };
}
export interface SearchDraftAgreementsIPSuccess {
    type: MP_SEARCH_DRAFT_AGREEMENTS_IP_SUCCESS_TYPE;
    payload: {
        draftAgreementsIPResult: IIPsSearchResult[];
    };
}

export interface SearchDraftAgreementsWorksSuccess {
    type: MP_SEARCH_DRAFT_AGREEMENTS_WORKS_SUCCESS_TYPE;
    payload: {
        draftAgreementsWorksResult: IWorksSearchResult[];
    };
}



export interface SearchDraftAgreementsFailure {
    type: MP_SEARCH_DRAFT_AGREEMENTS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}
export interface SearchDraftAgreementsIPFailure {
    type: MP_SEARCH_DRAFT_AGREEMENTS_IP_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchDraftAgreementsWorksFailure {
    type: MP_SEARCH_DRAFT_AGREEMENTS_WORKS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}


export interface SearchRegisteredAgreementsRequest {
    type: MP_SEARCH_REGISTERED_AGREEMENTS_REQUEST_TYPE;
    payload: {
        isModalSearch?: boolean;
    }
}

export interface SearchRegisteredAgreementsSuccess {
    type: MP_SEARCH_REGISTERED_AGREEMENTS_SUCCESS_TYPE;
    payload: {
        registeredAgreementsResult: IRegisteredAgreementSearchResult[];
    };
}

export interface SearchRegisteredAgreementsFailure {
    type: MP_SEARCH_REGISTERED_AGREEMENTS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetDraftWorkIDsRequest {
    type: MP_GET_DRAFT_WORK_ID_REQUEST_TYPE;
}

export interface GetDraftWorkIDsSuccess {
    type: MP_GET_DRAFT_WORK_ID_SUCCESS_TYPE;
    payload: {
        workIDs: string[];
        searchBody?: IDraftWorkSearchQuery;
    };
}

export interface GetDraftWorkIDsFailure {
    type: MP_GET_DRAFT_WORK_ID_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetDraftWorkDetailsRequest {
    type: MP_GET_DRAFT_WORK_DETAILS_REQUEST_TYPE;
}

export interface GetDraftWorkDetailsSuccess {
    type: MP_GET_DRAFT_WORK_DETAILS_SUCCESS_TYPE;
    payload: {
        workDetails: IWork;
    };
}

export interface GetDraftWorkDetailsFailure {
    type: MP_GET_DRAFT_WORK_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        workId: number;
    };
}


export interface GetDraftAgreementDetailsRequest {
    type: MP_GET_DRAFT_AGREEMENT_DETAILS_REQUEST_TYPE;
}

export interface GetDraftAgreementDetailsSuccess {
    type: MP_GET_DRAFT_AGREEMENT_DETAILS_SUCCESS_TYPE;
    payload: {
        agreementDetails: IAgreement;
    };
}

export interface GetDraftAgreementDetailsFailure {
    type: MP_GET_DRAFT_AGREEMENT_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        agreementId: number;
    };
}


export interface GetRegisteredWorkDetailsRequest {
    type: MP_GET_REGISTERED_WORK_DETAILS_REQUEST_TYPE;
}

export interface GetRegisteredWorkDetailsSuccess {
    type: MP_GET_REGISTERED_WORK_DETAILS_SUCCESS_TYPE;
    payload: {
        workDetails: IWork;
    };
}

export interface GetRegisteredWorkDetailsFailure {
    type: MP_GET_REGISTERED_WORK_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        workId: number;
    };
}

export interface GetDraftSetListDetailsRequest {
    type: MP_GET_DRAFT_SET_LIST_DETAILS_REQUEST_TYPE;
}

export interface GetDraftSetListDetailsSuccess {
    type: MP_GET_DRAFT_SET_LIST_DETAILS_SUCCESS_TYPE;
    payload: {
        setListDetails: IProductCore;
    };
}

export interface GetDraftSetListDetailsFailure {
    type: MP_GET_DRAFT_SET_LIST_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        productCoreId: number;
    };
}

export interface GetRegisteredSetListDetailsRequest {
    type: MP_GET_REGISTERED_SET_LIST_DETAILS_REQUEST_TYPE;
}

export interface GetRegisteredSetListDetailsSuccess {
    type: MP_GET_REGISTERED_SET_LIST_DETAILS_SUCCESS_TYPE;
    payload: {
        setListDetails: IProductCore;
    };
}

export interface GetRegisteredSetListDetailsFailure {
    type: MP_GET_REGISTERED_SET_LIST_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        productCoreId: number;
    };
}

export interface GetAllRegisteredWorkDetailsRequest {
    type: MP_GET_ALL_REGISTERED_WORK_DETAILS_REQUEST_TYPE;
}

export interface GetAllRegisteredWorkDetailsSuccess {
    type: MP_GET_ALL_REGISTERED_WORK_DETAILS_SUCCESS_TYPE;
    payload: {
        workDetails: IWork;
    };
}

export interface GetAllRegisteredWorkDetailsFailure {
    type: MP_GET_ALL_REGISTERED_WORK_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        workId: number;
    };
}

export interface UpdateDraftWorkField {
    type: MP_UPDATE_DRAFT_WORK_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IWorkStateKeys;
        index?: number;
    }
}
export interface UpdateDraftAgreementField {
    type: MP_UPDATE_DRAFT_AGREEMENT_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IAgreementStateKeys;
        index?: number;
    }
}

export interface UpdateDraftAgreementWorkField {
    type: MP_UPDATE_DRAFT_AGREEMENT_WORK_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IAgreementStateKeys;
        index?: number;
    }
}

export interface UpdateLivePerformanceField {
    type: MP_UPDATE_LIVE_PERFORMANCE_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IUsageGroupStateKeys;
        index?: number;
    }
}

export interface UpdateLivePerformanceSetListAdditionalField {
    type: MP_UPDATE_LIVE_PERFORMANCE_SET_LIST_ADDITIONAL_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IUsageGroupSetListAdditionalStateKeys;
        index?: number;
    }
}
export interface UpdateLivePerformanceVenueDetailsField {
    type: MP_UPDATE_LIVE_PERFORMANCE_VENUE_DETAILS_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IUsageGroupVenueDetailsStateKeys;
        index?: number;
    }
}

export interface UpdateLivePerformanceVenueDetailsField {
    type: MP_UPDATE_LIVE_PERFORMANCE_VENUE_DETAILS_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IUsageGroupVenueDetailsStateKeys;
        index?: number;
    }
}


export interface UpdateDraftSetListsField {
    type: MP_UPDATE_DRAFT_SET_LISTS_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: ISetListsStateKeys;
        index?: number;
    }
}

export interface CreateNewRegisteredWork {
    type: MP_CREATE_NEW_REGISTERED_WORK_TYPE;
    payload: {
        lookups: ILookupDictionary,
        isNew?: boolean,
        registeredWorkMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem
    }
}

export interface CopyExistingRegisteredWork {
    type: MP_COPY_REGISTERED_WORK_TYPE;
    actionList: IDataActionToolbar[];
}

export interface UndoRegisteredWorkChangesRequest {
    type: MP_UNDO_REGISTERED_WORK_CHANGES_REQUEST_TYPE;
}

export interface UndoRegisteredWorkChangesSuccess {
    type: MP_UNDO_REGISTERED_WORK_CHANGES_SUCCESS_TYPE;
    payload: {
        registeredWork: IWork,
        lookupValues: ILookupDictionary
    }
}

export interface UndoRegisteredWorkChangesFailure {
    type: MP_UNDO_REGISTERED_WORK_CHANGES_FAILURE_TYPE;
    payload: {
        error: any
    }
}


export interface SearchRegisteredWorkVersionHistoryRequest {
    type: MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_REQUEST_TYPE;
}

export interface SearchRegisteredWorkVersionHistorySuccess {
    type: MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_SUCCESS_TYPE;
    payload: {
        results: IRegisteredWorkVersionHistorySearchResult[]
    }
}

export interface SearchRegisteredWorkVersionHistoryFailure {
    type: MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_FAILURE_TYPE;
}

export interface SaveRegisteredWorkRequest {
    type: MP_SAVE_REGISTERED_WORK_REQUEST_TYPE
}

export interface SaveRegisteredWorkSuccess {
    type: MP_SAVE_REGISTERED_WORK_SUCCESS_TYPE;
    payload: {
        registeredWorkSaveResult: IWorkSaveResult;
        lookupValues: ILookupDictionary
    }
}

export interface SaveRegisteredWorkFailure {
    type: MP_SAVE_REGISTERED_WORK_FAILURE_TYPE;
    payload: {
        registeredWorkSaveResult: IWorkSaveResult;
        lookupValues: ILookupDictionary
    }
}

export interface DeleteRegisteredWorkRequest {
    type: MP_DELETE_REGISTERED_WORK_REQUEST_TYPE;
}

export interface DeleteRegisteredWorkSuccess {
    type: MP_DELETE_REGISTERED_WORK_SUCCESS_TYPE;
    payload: {
        workId: number;
    }
}

export interface DeleteRegisteredWorkFailure {
    type: MP_DELETE_REGISTERED_WORK_FAILURE_TYPE;
    payload: {
        error?: IValidationMessage;
    }
}

export interface SearchRegisteredWorksRequest {
    type: MP_SEARCH_REGISTERED_WORKS_REQUEST_TYPE;
}

export interface SearchRegisteredWorksSuccess {
    type: MP_SEARCH_REGISTERED_WORKS_SUCCESS_TYPE;
    payload: {
        registeredWorksResult: IRegisteredWorkSearchResult[];
    };
}

export interface SearchRegisteredWorksFailure {
    type: MP_SEARCH_REGISTERED_WORKS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchRegisteredSetListsRequest {
    type: MP_SEARCH_REGISTERED_SET_LISTS_REQUEST_TYPE;
}

export interface SearchRegisteredSetListsSuccess {
    type: MP_SEARCH_REGISTERED_SET_LISTS_SUCCESS_TYPE;
    payload: {
        registeredSetListsResult: IRegisteredSetListsSearchResults[];
    };
}

export interface SearchRegisteredSetListsFailure {
    type: MP_SEARCH_REGISTERED_SET_LISTS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchAllRegisteredWorksRequest {
    type: MP_SEARCH_ALL_REGISTERED_WORKS_REQUEST_TYPE;
    payload: {
        isModalSearch?: boolean;
    }
}

export interface SearchAllRegisteredWorksSuccess {
    type: MP_SEARCH_ALL_REGISTERED_WORKS_SUCCESS_TYPE;
    payload: {
        allRegisteredWorksResult: IAllRegisteredWorkSearchResult[];
    };
}

export interface SearchAllRegisteredWorksFailure {
    type: MP_SEARCH_ALL_REGISTERED_WORKS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetRegisteredWorkRequest {
    type: MP_GET_REGISTERED_WORK_REQUEST_TYPE
}

export interface GetRegisteredWorkSuccess {
    type: MP_GET_REGISTERED_WORK_SUCCESS_TYPE;
    payload: {
        registeredWork: IRegisteredWork
    }
}

export interface GetRegisteredWorkFailure {
    type: MP_GET_REGISTERED_WORK_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface GetRegisteredWorkIDsRequest {
    type: MP_GET_REGISTERED_WORK_ID_REQUEST_TYPE;
}

export interface GetRegisteredWorkIDsSuccess {
    type: MP_GET_REGISTERED_WORK_ID_SUCCESS_TYPE;
    payload: {
        workIDs: string[];
        searchBody?: IRegisteredWorkSearchQuery;
    };
}

export interface GetRegisteredWorkIDsFailure {
    type: MP_GET_REGISTERED_WORK_ID_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface UpdateRegisteredWorkField {
    type: MP_UPDATE_REGISTERED_WORK_FIELD_TYPE;
    payload: {
        value: any;
        fieldName: IWorkStateKeys;
        lookups?: ILookupDictionary;
        subFieldName?: string;
    }
}
export interface IncrementTempID {
    type: MP_INCREMENT_TEMPID_TYPE;
}

export interface SetWarningMessage {
    type: MP_SET_WARNING_MESSAGE_TYPE;
    payload: {
        displayWarning: boolean;
    }
}

export interface UpdateWorkContributorIPRequest {
    type: MP_UPDATE_WORK_CONTRIBUTOR_IP_REQUEST_TYPE;
}

export interface UpdateWorkContributorIPSuccess {
    type: MP_UPDATE_WORK_CONTRIBUTOR_IP_SUCCESS_TYPE;
    payload: {
        workContributorId: number,
        ip: IP,
        agreementChain: IAgreementLink[]
    }
}

export interface UpdateWorkContributorIPFailure {
    type: MP_UPDATE_WORK_CONTRIBUTOR_IP_FAILURE_TYPE;
}

export interface SearchContributorRequest {
    type: MP_SEARCH_CONTRIBUTORS_REQUEST_TYPE;
}

export interface SearchContributorsSuccess {
    type: MP_SEARCH_CONTRIBUTORS_SUCCESS_TYPE;
    payload: {
        contributorsResult: IContributorSearchResult[];
    };
}

export interface SearchContributorsFailure {
    type: MP_SEARCH_CONTRIBUTORS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchArtistsRequest {
    type: MP_SEARCH_ARTISTS_REQUEST_TYPE;
}

export interface SearchArtistsSuccess {
    type: MP_SEARCH_ARTISTS_SUCCESS_TYPE;
    payload: {
        artistsResult: IArtist[];
    };
}

export interface SearchArtistsFailure {
    type: MP_SEARCH_ARTISTS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface AddArtistToWork {
    type: MP_ADD_ARTIST_TO_WORK_TYPE;
    payload: {
        artist: IArtist;
        id: number;
    }
}

export interface AddRepertoireAttachmentRequest {
    type: MP_ADD_REPERTOIRE_ATTACHMENT_REQUEST_TYPE,
}
export interface AddRepertoireAttachmentSuccess {
    type: MP_ADD_REPERTOIRE_ATTACHMENT_SUCCESS_TYPE,
    payload: {
        attachment: IAttachedFile
    }
}
export interface AddRepertoireAttachmentFailure {
    type: MP_ADD_REPERTOIRE_ATTACHMENT_FAILURE_TYPE,
    payload: {
        error: any
    }
}
export interface RemoveWorkAttachmentRequest {
    type: MP_REMOVE_WORK_ATTACHMENT_REQUEST_TYPE,
}
export interface RemoveWorkAttachmentSuccess {
    type: MP_REMOVE_WORK_ATTACHMENT_SUCCESS_TYPE,
    payload: {
        attachment: IAttachedFile
    }
}
export interface RemoveWorkAttachmentFailure {
    type: MP_REMOVE_WORK_ATTACHMENT_FAILURE_TYPE,
    payload: {
        error: any
    }
}

export interface AddDroppedFile {
    type: MP_ADD_DROPPED_FILE_TYPE,
    payload: {
        file: IFile,
        inProgress: boolean
    }
}

export interface SearchRequestAccessIP {
    type: MP_SEARCH_REQUEST_ACCESS_IP_TYPE;
}

export interface SearchRequestAccessIPSuccess {
    type: MP_SEARCH_REQUEST_ACCESS_IP_SUCCESS_TYPE;
    payload: {
        contributorsResult: IContributorSearchResult[];
    };
}

export interface SearchRequestAccessIPFailure {
    type: MP_SEARCH_REQUEST_ACCESS_IP_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface RequestViewAsAccess {
    type: MP_REQUEST_VIEW_AS_ACCESS_TYPE;
}

export interface RequestViewAsAccessSuccess {
    type: MP_REQUEST_VIEW_AS_ACCESS_SUCCESS_TYPE;
    payload: {
        success: boolean;
    };
}

export interface RequestViewAsAccessFailure {
    type: MP_REQUEST_VIEW_AS_ACCESS_FAILURE_TYPE;
    payload: {
        error: boolean;
    };
}

export interface GetViewAsAccessRequests {
    type: MP_GET_VIEW_AS_ACCESS_REQUESTS_TYPE;
}

export interface GetViewAsAccessRequestsSuccess {
    type: MP_GET_VIEW_AS_ACCESS_REQUESTS_SUCCESS_TYPE;
    payload: {
        viewAsAccessRequests: IViewAsAccessRequest[];
    };
}

export interface GetViewAsAccessRequestsFailure {
    type: MP_GET_VIEW_AS_ACCESS_REQUESTS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetMyApplications {
    type: MP_GET_MY_APPLICATIONS_TYPE;
}

export interface GetMyApplicationsSuccess {
    type: MP_GET_MY_APPLICATIONS_SUCCESS_TYPE;
    payload: {
        applications: IApplication[];
    };
}

export interface GetMyApplicationsFailure {
    type: MP_GET_MY_APPLICATIONS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetMyCases {
    type: MP_GET_MY_CASES_TYPE;
}

export interface GetMyCasesSuccess {
    type: MP_GET_MY_CASES_SUCCESS_TYPE;
    payload: {
        cases: ICase[];
    };
}

export interface GetMyCasesFailure {
    type: MP_GET_MY_CASES_FAILURE_TYPE;
    payload: {
        error: string;
    };
}


export interface SaveViewAsAccess {
    type: MP_SAVE_VIEW_AS_ACCESS_TYPE;
}

export interface SaveViewAsAccessSuccess {
    type: MP_SAVE_VIEW_AS_ACCESS_SUCCESS_TYPE;
    payload: {
        viewAsAccessRequests: IViewAsAccessRequest[];
        viewAsAccessSaveSuccess: boolean;
    };
}

export interface SaveViewAsAccessFailure {
    type: MP_SAVE_VIEW_AS_ACCESS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface ResetViewAsSaveStatus {
    type: MP_RESET_VIEW_AS_SAVE_STATUS_TYPE
}

export interface CancelAccessRequest {
    type: MP_CANCEL_ACCESS_REQUEST_TYPE;
}

export interface CancelAccessRequestSuccess {
    type: MP_CANCEL_ACCESS_REQUEST_SUCCESS_TYPE;
    payload: {
        cancelAccessRequestSuccess: boolean;
        accountNumber: string;
    };
}

export interface CancelAccessRequestFailure {
    type: MP_CANCEL_ACCESS_REQUEST_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SetProgressUploadFileRepertoireProccess {
    type: MP_SET_NEW_PROGRESS_UPLOAD_FILE_REPERTOIRE_PROCCESS_TYPE;
    payload: {
        file: IFile,
        message: string,
        isUploadComplete: boolean
    }
}

export interface CleanDroppedFilesState {
    type: MP_CLEAN_DROPPED_FILES_STATE_TYPE;
}

export interface ReisterStepsSetCurrent {
    type: MP_REGISTER_STEPS_SET_CURRENT_TYPE;
    payload: {
        current: number,
    }
}


export interface SearchMemberStatementsRequest {
    type: MP_SEARCH_STATEMENTS_REQUEST_TYPE;
}

export interface SearchMemberStatementsSuccess {
    type: MP_SEARCH_STATEMENTS_SUCCESS_TYPE;
    payload: {
        statementsResult: IMemberStatementSearchResult[];
    };
}

export interface SearchMemberStatementsFailure {
    type: MP_SEARCH_STATEMENTS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchUnpaidClaimsRequest {
    type: MP_SEARCH_UNPAID_CLAIMS_REQUEST_TYPE;
}

export interface SearchClaimsHistoryRequest {
    type: MP_SEARCH_CLAIMS_HISTORY_REQUEST_TYPE;
}

export interface SearchUnpaidClaimsSuccess {
    type: MP_SEARCH_UNPAID_CLAIMS_SUCCESS_TYPE;
    payload: {
        unpaidClaimsResult: IUnpaidClaimsSearchResult[];
    };
}

export interface ClaimUnpaidUsageRequest {
    type: MP_CLAIM_UNPAID_USAGE_REQUEST_TYPE;
}

export interface ClaimUnpaidUsageFailure {
    type: MP_CLAIM_UNPAID_USAGE_FAILURE_TYPE;
}

export interface ClaimUnpaidUsageSuccess {
    type: MP_CLAIM_UNPAID_USAGE_SUCCESS_TYPE;
}


export interface SearchClaimsHistorySuccess {
    type: MP_SEARCH_CLAIMS_HISTORY_SUCCESS_TYPE;
    payload: {
        claimsHistoryResult: IClaimsHistorySearchResult[];
    };
}

export interface SearchUnpaidClaimsFailure {
    type: MP_SEARCH_UNPAID_CLAIMS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchClaimsHistoryFailure {
    type: MP_SEARCH_CLAIMS_HISTORY_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetMemberStatementDetailsRequest {
    type: MP_GET_STATEMENT_DETAILS_REQUEST_TYPE;
}

export interface GetMemberStatementDetailsSuccess {
    type: MP_GET_STATEMENT_DETAILS_SUCCESS_TYPE;
    payload: {
        statementsResult: IMemberStatementSearchResult;
    };
}

export interface GetMemberStatementDetailsFailure {
    type: MP_GET_STATEMENT_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface ResetMessageBanner {
    type: MP_RESET_MESSAGE_BANNER_TYPE;
}

export const MP_UPDATE_USER_PREFERENCE: MP_UPDATE_USER_PREFERENCE_TYPE = 'MP_UPDATE_USER_PREFERENCE';
export const MP_UPDATE_PAGINATION: MP_UPDATE_PAGINATION_TYPE = 'MP_UPDATE_PAGINATION';
export const MP_RESET_PAGINATION: MP_RESET_PAGINATION_TYPE = 'MP_RESET_PAGINATION';

export const MP_GET_USER_PREFERENCES_REQUEST: MP_GET_USER_PREFERENCES_REQUEST_TYPE = 'MP_GET_USER_PREFERENCES_REQUEST';
export const MP_GET_USER_PREFERENCES_SUCCESS: MP_GET_USER_PREFERENCES_SUCCESS_TYPE = 'MP_GET_USER_PREFERENCES_SUCCESS';
export const MP_GET_USER_PREFERENCES_FAILURE: MP_GET_USER_PREFERENCES_FAILURE_TYPE = 'MP_GET_USER_PREFERENCES_FAILURE';

export const MP_SORT_SEARCH_RESULTS: MP_SORT_SEARCH_RESULTS_TYPE = 'MP_SORT_SEARCH_RESULTS';

type MP_GET_USER_PREFERENCES_REQUEST_TYPE = 'MP_GET_USER_PREFERENCES_REQUEST';
type MP_GET_USER_PREFERENCES_SUCCESS_TYPE = 'MP_GET_USER_PREFERENCES_SUCCESS';
type MP_GET_USER_PREFERENCES_FAILURE_TYPE = 'MP_GET_USER_PREFERENCES_FAILURE';


type MP_UPDATE_PAGINATION_TYPE = 'MP_UPDATE_PAGINATION';
type MP_RESET_PAGINATION_TYPE = 'MP_RESET_PAGINATION';

type MP_UPDATE_USER_PREFERENCE_TYPE = 'MP_UPDATE_USER_PREFERENCE';
type MP_SORT_SEARCH_RESULTS_TYPE = 'MP_SORT_SEARCH_RESULTS';




export interface GetUserPreferenceRequest {
    type: MP_GET_USER_PREFERENCES_REQUEST_TYPE;
}
export interface GetUserPreferenceSuccess {
    type: MP_GET_USER_PREFERENCES_SUCCESS_TYPE;
    payload: {
        resultsPerPage: Array<IResultsPerPage>;
        activeAccordions: Array<IActiveAccordion>;
    }
}
export interface GetUserPreferenceFailure {
    type: MP_GET_USER_PREFERENCES_FAILURE_TYPE;
}

export interface UpdateUserPreference {
    type: MP_UPDATE_USER_PREFERENCE_TYPE;
    payload: {
        resultsPerPage: Array<IResultsPerPage>;
        activeAccordions: Array<IActiveAccordion>;
    }
}

export interface UpdatePagination {
    type: MP_UPDATE_PAGINATION_TYPE;
    payload: {
        indexOfFirstResult: number,
        indexOfLastResult: number,
        resultsPerPage: number,
        currentPage: number,
        repertoireSection: string
    }
}

export interface SortSearchResults {
    type: MP_SORT_SEARCH_RESULTS_TYPE;
    payload: {
        name: string,
        results: any
    }
}

export interface ResetPagination {
    type: MP_RESET_PAGINATION_TYPE;
    payload: {
        repertoireSection: string
    }
}


export interface ResetMessageBanner {
    type: MP_RESET_MESSAGE_BANNER_TYPE;
}

export const MP_EXPAND_ALL_RESULTS: MP_EXPAND_ALL_RESULTS_TYPE = 'MP_EXPAND_ALL_RESULTS';
type MP_EXPAND_ALL_RESULTS_TYPE = 'MP_EXPAND_ALL_RESULTS';

export interface ExpandAllResults {
    type: MP_EXPAND_ALL_RESULTS_TYPE
}

type MP_EXPAND_WORK_RESULT_TYPE = 'MP_EXPAND_WORK_RESULT';
export const MP_EXPAND_WORK_RESULT: MP_EXPAND_WORK_RESULT_TYPE = 'MP_EXPAND_WORK_RESULT';

export interface ExpandWorkResult {
    type: MP_EXPAND_WORK_RESULT_TYPE,
    payload: {
        workResult: number
    }
}

type MP_EXPAND_STATEMENT_RESULT_TYPE = 'MP_EXPAND_STATEMENT_RESULT';
export const MP_EXPAND_STATEMENT_RESULT: MP_EXPAND_STATEMENT_RESULT_TYPE = 'MP_EXPAND_STATEMENT_RESULT';

export interface ExpandMemberStatementResult {
    type: MP_EXPAND_STATEMENT_RESULT_TYPE,
    payload: {
        statementResult: number
    }
}

export const MP_ENABLE_CUSTOM_FIELD: MP_ENABLE_CUSTOM_FIELD_TYPE = 'MP_ENABLE_CUSTOM_FIELD';
type MP_ENABLE_CUSTOM_FIELD_TYPE = 'MP_ENABLE_CUSTOM_FIELD';
export interface EnableCustomField {
    type: MP_ENABLE_CUSTOM_FIELD_TYPE
    payload: {
        index: number
    }
}

export const MP_GET_FORMAT_FIELDS_REQUEST: MP_GET_FORMAT_FIELD_REQUEST_TYPE = 'MP_GET_FIELD_FORMAT_REQUEST';
export const MP_GET_FORMAT_FIELDS_SUCCESS: MP_GET_FORMAT_FIELD_SUCCESS_TYPE = 'MP_GET_FIELD_SUCCESS_REQUEST';
export const MP_GET_FORMAT_FIELDS_FAILURE: MP_GET_FORMAT_FIELD_FAILURE_TYPE = 'MP_GET_FIELD_FAILURE_REQUEST';
type MP_GET_FORMAT_FIELD_REQUEST_TYPE = 'MP_GET_FIELD_FORMAT_REQUEST';
type MP_GET_FORMAT_FIELD_SUCCESS_TYPE = 'MP_GET_FIELD_SUCCESS_REQUEST';
type MP_GET_FORMAT_FIELD_FAILURE_TYPE = 'MP_GET_FIELD_FAILURE_REQUEST';

export interface GetFormatFieldsRequest {
    type: MP_GET_FORMAT_FIELD_REQUEST_TYPE
}
export interface GetFormatFieldsSuccess {
    type: MP_GET_FORMAT_FIELD_SUCCESS_TYPE,
    payload: {
        formatFields: FormatFields[]
    }
}
export interface GetFormatFieldsFailure {
    type: MP_GET_FORMAT_FIELD_FAILURE_TYPE,
    payload: {
        error: any
    }
}


export const MP_SET_EDITABLE_FIELDS_TAB: MP_SET_EDITABLE_FIELDS_TAB_TYPE = "MP_SET_EDITABLE_FIELDS_TAB";
export const MP_UPDATE_EDITABLE_FIELD: MP_UPDATE_EDITABLE_FIELD_TYPE = "MP_UPDATE_EDITABLE_FIELD";
type MP_SET_EDITABLE_FIELDS_TAB_TYPE = 'MP_SET_EDITABLE_FIELDS_TAB';
type MP_UPDATE_EDITABLE_FIELD_TYPE = 'MP_UPDATE_EDITABLE_FIELD';
export interface SetEditableFields {
    type: MP_SET_EDITABLE_FIELDS_TAB_TYPE;
    payload: {
        componentFieldName: string,
        componentInstance: string;
    }
}
export interface UpdateEditableField {
    type: MP_UPDATE_EDITABLE_FIELD_TYPE;
    payload: {
        value: any[];
    }
}

type MP_UPDATE_COMPONENT_FIELD_REQUEST_TYPE = 'MP_UPDATE_COMPONENT_FIELD_REQUEST';
type MP_UPDATE_COMPONENT_FIELD_SUCCESS_TYPE = 'MP_UPDATE_COMPONENT_FIELD_SUCCESS';
type MP_UPDATE_COMPONENT_FIELD_FAILURE_TYPE = 'MP_UPDATE_COMPONENT_FIELD_FAILURE';
export const MP_UPDATE_COMPONENT_FIELD_REQUEST: MP_UPDATE_COMPONENT_FIELD_REQUEST_TYPE = "MP_UPDATE_COMPONENT_FIELD_REQUEST";
export const MP_UPDATE_COMPONENT_FIELD_SUCCESS: MP_UPDATE_COMPONENT_FIELD_SUCCESS_TYPE = "MP_UPDATE_COMPONENT_FIELD_SUCCESS";
export const MP_UPDATE_COMPONENT_FIELD_FAILURE: MP_UPDATE_COMPONENT_FIELD_FAILURE_TYPE = "MP_UPDATE_COMPONENT_FIELD_FAILURE";
export interface UpdateComponentFieldRequest {
    type: MP_UPDATE_COMPONENT_FIELD_REQUEST_TYPE;
}

export interface UpdateComponentFieldFailure {
    type: MP_UPDATE_COMPONENT_FIELD_FAILURE_TYPE;
    payload: {
        error: string
    }
}

export interface UpdateComponentFieldSuccess {
    type: MP_UPDATE_COMPONENT_FIELD_SUCCESS_TYPE;
    payload: {
        componentFields: IMembersPortalField[];
        component: string;
        componentInstance: string;
    };
}

type MP_OPEN_ACCORDION_TYPE = "MP_OPEN_ACCORDION";
type MP_CLOSE_ACCORDION_TYPE = "MP_CLOSE_ACCORDION";
export const MP_OPEN_ACCORDION: MP_OPEN_ACCORDION_TYPE = "MP_OPEN_ACCORDION";
export const MP_CLOSE_ACCORDION: MP_CLOSE_ACCORDION_TYPE = "MP_CLOSE_ACCORDION";
export interface OpenAccordion {
    type: MP_OPEN_ACCORDION_TYPE;
    payload: {
        accordionName: string;
        componentName: string;
    };
}
export interface CloseAccordion {
    type: MP_CLOSE_ACCORDION_TYPE;
    payload: {
        accordionName: string;
        componentName: string;
    };
}

export interface CloseWorkMaintenanceAccordian {
    type: MP_CLOSE_ACCORDION_TYPE;
    payload: {
        accordionName: string;
        componentName: string;
    };
}



export interface AddTabAction {
    type: MP_ADD_TAB_TYPE;
    payload: {
        tab: ITabReduxItem;
        keepTitle?: boolean;
    };
}

export interface ReplaceTabAction {
    type: MP_REPLACE_TAB_TYPE;
    payload: {
        tab: ITabReduxItem,
        targetTabTitle: string
    };
}

export interface RemoveTabAction {
    type: MP_REMOVE_TAB_TYPE;
    payload: {
        index: number;
    };
}

export interface OpenTabAction {
    type: MP_OPEN_TAB_TYPE;
    payload: {
        index: number;
    };
}

type MP_SET_CHANGES_MADE_TYPE = "MP_SET_CHANGES_MADE";
export const MP_SET_CHANGES_MADE: MP_SET_CHANGES_MADE_TYPE = "MP_SET_CHANGES_MADE";
export interface SetChangesMade {
    type: MP_SET_CHANGES_MADE_TYPE;
    payload: {
        changesMade: boolean;
        tabIndex: number;
    };
}

type MP_CLEAR_MODAL_SEARCH_RESULTS_TYPE = "MP_CLEAR_MODAL_SEARCH_RESULTS"
export const MP_CLEAR_MODAL_SEARCH_RESULTS: MP_CLEAR_MODAL_SEARCH_RESULTS_TYPE = "MP_CLEAR_MODAL_SEARCH_RESULTS"
export interface ClearModalSearchResults {
    type: MP_CLEAR_MODAL_SEARCH_RESULTS_TYPE;
}


type MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_REQUEST_TYPE = "MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_REQUEST";
type MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_SUCCESS_TYPE = "MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_SUCCESS";
type MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_FAILURE_TYPE = "MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_FAILURE";
export const MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_REQUEST: MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_REQUEST_TYPE =
    "MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_REQUEST";
export const MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_SUCCESS: MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_SUCCESS_TYPE =
    "MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_SUCCESS";
export const MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_FAILURE: MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_FAILURE_TYPE =
    "MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_FAILURE";
export interface MembersPortalComponentDataRequest {
    type: MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_REQUEST_TYPE;
}
export interface MembersPortalComponentDataSuccess {
    type: MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_SUCCESS_TYPE;
    payload: {
        componentData: IMembersPortalComponentData;
    };
}
export interface MembersPortalComponentDataFailure {
    type: MP_FETCH_MEMBERS_PORTAL_COMPONENT_DATA_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export const MP_APPLY_FORMATS_WORK_MAINTENANCE: MP_APPLY_FORMATS_WORK_MAINTENANCE_TYPE = 'MP_APPLY_FORMATS_WORK_MAINTENANCE';
type MP_APPLY_FORMATS_WORK_MAINTENANCE_TYPE = 'MP_APPLY_FORMATS_WORK_MAINTENANCE';
export interface ApplyFormatsWorkMaintenance {
    type: MP_APPLY_FORMATS_WORK_MAINTENANCE_TYPE,
}

export const MP_GET_USERS_REQUEST: MP_GET_USERS_REQUEST_TYPE = "MP_GET_USERS_REQUEST";
export const MP_GET_USERS_SUCCESS: MP_GET_USERS_SUCCESS_TYPE = "MP_GET_USERS_SUCCESS";
export const MP_GET_USERS_FAILURE: MP_GET_USERS_FAILURE_TYPE = "MP_GET_USERS_FAILURE";
type MP_GET_USERS_REQUEST_TYPE = "MP_GET_USERS_REQUEST";
type MP_GET_USERS_SUCCESS_TYPE = "MP_GET_USERS_SUCCESS";
type MP_GET_USERS_FAILURE_TYPE = "MP_GET_USERS_FAILURE";

export interface GetUsersRequest {
    type: MP_GET_USERS_REQUEST_TYPE;
}

export interface GetUsersSuccess {
    type: MP_GET_USERS_SUCCESS_TYPE;
    payload: {
        users: string[];
    };
}

export interface GetUsersFailure {
    type: MP_GET_USERS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export const SET_DATA_SOURCE: SET_DATA_SOURCE_TYPE = "SET_DATA_SOURCE";
type SET_DATA_SOURCE_TYPE = "SET_DATA_SOURCE";
export interface SetDataSourceAction {
    type: SET_DATA_SOURCE_TYPE;
    payload: {
        dataSource: string;
    };
}

export interface AddWorkToSetList {
    type: ADD_WORK_TO_SET_LIST_TYPE;
    payload: {
        work: IDraftWorkSearchResult;
    }
}

export interface AddSetListToLivePerformance {
    type: ADD_SET_LIST_TO_LIVE_PERFORMANCE_TYPE;
    payload: {
        setList: IDraftSetListSearchResult;
    }
}

export interface SearchLivePerformancesRequest {
    type: MP_SEARCH_LIVE_PERFORMANCES_REQUEST_TYPE
}

export interface SearchLivePerformancesSuccess {
    type: MP_SEARCH_LIVE_PERFORMANCES_SUCCESS_TYPE;
    payload: {
        livePerformancesResult: ILivePerformancesSearchResult;
    }
}

export interface SearchLivePerformancesFailure {
    type: MP_SEARCH_LIVE_PERFORMANCES_FAILURE_TYPE;
    payload: {
        error: string
    }
}

export interface GetLivePerformanceDetailsRequest {
    type: MP_GET_LIVE_PERFORMANCE_DETAILS_REQUEST_TYPE;
}
export interface GetLivePerformanceDetailsSuccess {
    type: MP_GET_LIVE_PERFORMANCE_DETAILS_SUCCESS_TYPE;
    payload: {
        livePerformanceDetails: IUsageGroup;
    };
}
export interface GetLivePerformanceDetailsFailure {
    type: MP_GET_LIVE_PERFORMANCE_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        usageId: string;
    };
}

export interface SearchLivePerformancesRequest {
    type: MP_SEARCH_LIVE_PERFORMANCES_REQUEST_TYPE
}

export interface SearchLivePerformancesSuccess {
    type: MP_SEARCH_LIVE_PERFORMANCES_SUCCESS_TYPE;
    payload: {
        livePerformancesResult: ILivePerformancesSearchResult;
    }
}

export interface SearchLivePerformancesFailure {
    type: MP_SEARCH_LIVE_PERFORMANCES_FAILURE_TYPE;
    payload: {
        error: string
    }
}

export interface GetShareDecimalsToDisplayRequest {
    type: MP_GET_SHARE_DECIMALS_TO_DISPLAY_REQUEST_TYPE;
}

export interface GetShareDecimalsToDisplaySuccess {
    type: MP_GET_SHARE_DECIMALS_TO_DISPLAY_SUCCESS_TYPE;
    payload: {
        decimalsToDisplay: number
    };
}

export interface GetShareDecimalsToDisplayFailure {
    type: MP_GET_SHARE_DECIMALS_TO_DISPLAY_FAILURE_TYPE;
    payload: {
        error: any
    }
}

export interface RefreshWorkDetailsRequest {
    type: MP_REFRESH_WORK_DETAILS_REQUEST_TYPE;
}

export interface RefreshWorkDetailsSuccess {
    type: MP_REFRESH_WORK_DETAILS_SUCCESS_TYPE;
    payload: {
        workDetails: IWork;
    };
}
export interface RefreshWorkDetailsFailure {
    type: MP_REFRESH_WORK_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        workId: number;
    };
}

export interface RefreshAgreementDetailsRequest {
    type: MP_REFRESH_AGREEMENT_DETAILS_REQUEST_TYPE;
}

export interface RefreshAgreementDetailsSuccess {
    type: MP_REFRESH_AGREEMENT_DETAILS_SUCCESS_TYPE;
    payload: {
        agreementDetails: IAgreement;
    };
}
export interface RefreshAgreementDetailsFailure {
    type: MP_REFRESH_AGREEMENT_DETAILS_FAILURE_TYPE;
    payload: {
        error: string;
        agreementId: number;
    };
}

export interface SaveContributorComment {
    type: MP_SAVE_WORK_CONTRIBUTOR_COMMENT_TYPE 
    payload: {
        contributorID: number;
        comments: string;
    }
}

export interface GetCRMIntegratedRequest {
    type: MP_GET_CRM_INTEGRATION_ENABLED_REQUEST_TYPE
}
export interface GetCRMIntegratedSuccess {
    type: MP_GET_CRM_INTEGRATION_ENABLED_SUCCESS_TYPE,
    payload: {
        crmIntegrationEnabled: boolean;
    }
}
export interface GetCRMIntegratedFailure {
    type: MP_GET_CRM_INTEGRATION_ENABLED_FAILURE_TYPE,
    payload: {
        error: string;
    }
}

export interface GetCRMPowerPortalBaseURLRequest {
    type: MP_GET_CRM_POWER_PORTAL_BASE_URL_REQUEST_TYPE
}
export interface GetCRMPowerPortalBaseURLSuccess {
    type: MP_GET_CRM_POWER_PORTAL_BASE_URL_SUCCESS_TYPE,
    payload: {
        crmPowerPortalBaseURL: string;
    }
}
export interface GetCRMPowerPortalBaseURLFailure {
    type: MP_GET_CRM_POWER_PORTAL_BASE_URL_FAILURE_TYPE,
    payload: {
        error: string;
    }
}

export type ActionTypes =
    | GetMatchingSourcesRequest
    | GetMatchingSourcesSuccess
    | GetMatchingSourcesFailure
    | GetUsageDistributionsFailure
    | GetUsageDistributionsRequest
    | GetUsageDistributionsSuccess
    | MembersPortalComponentDataSuccess
    | SearchDraftWorkVersionHistoryRequest
    | SearchDraftWorkVersionHistorySuccess
    | SearchDraftWorkVersionHistoryFailure
    | SearchUnpaidClaimsRequest
    | SearchUnpaidClaimsFailure
    | SearchDraftWorksFailure
    | SearchDraftWorksSuccess
    | SearchDraftWorksRequest
    | SearchDraftAgreementsFailure
    | SearchDraftAgreementsIPFailure
    | SearchDraftAgreementsWorksFailure
    | SearchDraftAgreementsSuccess
    | SearchDraftAgreementsIPSuccess
    | SearchDraftAgreementsWorksSuccess
    | SearchDraftAgreementsRequest
    | SearchDraftAgreementsIPRequest
    | SearchDraftAgreementsWorksRequest
    | SearchRegisteredAgreementsFailure
    | SearchRegisteredAgreementsSuccess
    | SearchRegisteredAgreementsRequest
    | SearchDraftSetListsRequest
    | SearchDraftSetListsSuccess
    | SearchDraftSetListsFailure
    | GetDraftWorkIDsRequest
    | GetDraftWorkIDsSuccess
    | GetDraftWorkIDsFailure
    | CreateNewDraftWork
    | CreateNewDraftAgreement
    | DeleteDraftWorkRequest
    | DeleteDraftWorkSuccess
    | DeleteDraftWorkFailure
    | UndoDraftWorkChangesRequest
    | UndoDraftWorkChangesSuccess
    | UndoDraftWorkChangesFailure
    | UndoDraftSetListChangesRequest
    | UndoDraftSetListChangesSuccess
    | UndoDraftSetListChangesFailure
    | GetDraftWorkDetailsRequest
    | GetDraftWorkDetailsSuccess
    | GetDraftWorkDetailsFailure
    | GetDraftAgreementDetailsRequest
    | GetDraftAgreementDetailsSuccess
    | GetDraftAgreementDetailsFailure
    | UpdateDraftWorkField
    | UpdateDraftAgreementField
    | UpdateDraftAgreementWorkField
    | SaveDraftWorkRequest
    | SaveDraftWorkSuccess
    | SaveDraftWorkFailure
    | CopyExistingDraftWork
    | GetDraftWorkIDsFailure
    | IncrementTempID
    | SetWarningMessage
    | UpdateUserPreference
    | GetUserPreferenceSuccess
    | UpdatePagination
    | ResetPagination
    | SortSearchResults
    | ExpandAllResults
    | EnableCustomField
    | GetFormatFieldsSuccess
    | SetEditableFields
    | SearchUnpaidClaimsSuccess
    | ClaimUnpaidUsageSuccess
    | UpdateEditableField
    | UpdateComponentFieldRequest
    | UpdateComponentFieldFailure
    | UpdateComponentFieldSuccess
    | OpenAccordion
    | CloseAccordion
    | CloseWorkMaintenanceAccordian
    | SetChangesMade
    | ClearModalSearchResults
    | AddTabAction
    | ReplaceTabAction
    | RemoveTabAction
    | OpenTabAction
    | SetDataSourceAction
    | SearchRegisteredWorkVersionHistoryRequest
    | SearchRegisteredWorkVersionHistorySuccess
    | SearchRegisteredWorkVersionHistoryFailure
    | SearchRegisteredWorksFailure
    | SearchRegisteredWorksSuccess
    | SearchRegisteredWorksRequest
    | GetRegisteredWorkIDsRequest
    | GetRegisteredWorkIDsSuccess
    | GetRegisteredWorkIDsFailure
    | CreateNewRegisteredWork
    | DeleteRegisteredWorkRequest
    | DeleteRegisteredWorkSuccess
    | DeleteRegisteredWorkFailure
    | UndoRegisteredWorkChangesRequest
    | UndoRegisteredWorkChangesSuccess
    | UndoRegisteredWorkChangesFailure
    | GetRegisteredWorkFailure
    | GetRegisteredWorkRequest
    | GetRegisteredWorkSuccess
    | UpdateRegisteredWorkField
    | SaveRegisteredWorkRequest
    | SaveRegisteredWorkSuccess
    | SaveRegisteredWorkFailure
    | CopyExistingRegisteredWork
    | UpdateWorkContributorIPRequest
    | UpdateWorkContributorIPSuccess
    | UpdateWorkContributorIPFailure
    | SearchContributorRequest
    | SearchContributorsSuccess
    | SearchContributorsFailure
    | SearchArtistsRequest
    | SearchArtistsSuccess
    | SearchArtistsFailure
    | AddArtistToWork
    | GetRegisteredWorkDetailsSuccess
    | GetRegisteredWorkDetailsFailure
    | AddRepertoireAttachmentRequest
    | AddRepertoireAttachmentSuccess
    | AddRepertoireAttachmentFailure
    | RemoveWorkAttachmentRequest
    | RemoveWorkAttachmentSuccess
    | RemoveWorkAttachmentFailure
    | AddDroppedFile
    | SetProgressUploadFileRepertoireProccess
    | CleanDroppedFilesState
    | RemoveWorkAttachmentFailure
    | SearchAllRegisteredWorksFailure
    | SearchAllRegisteredWorksSuccess
    | GetRegisteredWorkDetailsRequest
    | GetAllRegisteredWorkDetailsRequest
    | GetAllRegisteredWorkDetailsSuccess
    | GetAllRegisteredWorkDetailsFailure
    | ExpandWorkResult
    | ExpandMemberStatementResult
    | ReisterStepsSetCurrent
    | SearchAllRegisteredWorksRequest
    | SearchRequestAccessIP
    | SearchRequestAccessIPSuccess
    | SearchRequestAccessIPFailure
    | RequestViewAsAccess
    | RequestViewAsAccessSuccess
    | RequestViewAsAccessFailure
    | GetViewAsAccessRequests
    | GetViewAsAccessRequestsSuccess
    | GetViewAsAccessRequestsFailure
    | SaveViewAsAccess
    | SaveViewAsAccessSuccess
    | SaveViewAsAccessFailure
    | ResetViewAsSaveStatus
    | CancelAccessRequest
    | CancelAccessRequestSuccess
    | CancelAccessRequestFailure
    | SearchMemberStatementsRequest
    | SearchMemberStatementsSuccess
    | SearchMemberStatementsFailure
    | ResetMessageBanner
    | SearchRegisteredSetListsSuccess
    | CreateNewDraftSetLists
    | CopyExistingDraftSetList
    | DeleteDraftSetListRequest
    | DeleteDraftSetListSuccess
    | DeleteDraftSetListFailure
    | GetDraftSetListDetailsRequest
    | GetDraftSetListDetailsSuccess
    | GetDraftSetListDetailsFailure
    | GetRegisteredSetListDetailsRequest
    | GetRegisteredSetListDetailsSuccess
    | GetRegisteredSetListDetailsFailure
    | SaveDraftSetListsRequest
    | SaveDraftSetListsSuccess
    | SaveDraftSetListsFailure
    | SaveDraftAgreementRequest
    | SaveDraftAgreementSuccess
    | SaveDraftAgreementFailure
    | UpdateDraftSetListsField
    | AddWorkToSetList
    | AddSetListToLivePerformance
    | CreateNewDraftLivePerformance
    | UpdateLivePerformanceField
    | UpdateLivePerformanceSetListAdditionalField
    | UpdateLivePerformanceVenueDetailsField
    | CopyExistingDraftLivePerformance
    | CreateNewDraftLivePerformance
    | SaveDraftLivePerformanceFailure
    | SaveDraftLivePerformanceSuccess
    | SearchLivePerformancesRequest
    | SearchLivePerformancesSuccess
    | SearchLivePerformancesFailure
    | GetLivePerformanceDetailsRequest
    | GetLivePerformanceDetailsSuccess
    | GetLivePerformanceDetailsFailure
    | GetShareDecimalsToDisplayRequest
    | GetShareDecimalsToDisplaySuccess
    | GetShareDecimalsToDisplayFailure
    | RefreshWorkDetailsRequest
    | RefreshWorkDetailsSuccess
    | RefreshWorkDetailsFailure
    | SaveContributorComment
    | SearchClaimsHistoryRequest
    | SearchClaimsHistorySuccess
    | SearchClaimsHistoryFailure
    | RefreshAgreementDetailsRequest
    | RefreshAgreementDetailsSuccess
    | RefreshAgreementDetailsFailure
    | GetMyApplications
    | GetMyApplicationsSuccess
    | GetMyApplicationsFailure
    | GetMyCasesSuccess
    | GetMyCasesFailure
    | GetMyCases
    | GetCRMIntegratedRequest
    | GetCRMIntegratedSuccess
    | GetCRMIntegratedFailure
    | GetCRMPowerPortalBaseURLRequest
    | GetCRMPowerPortalBaseURLSuccess
    | GetCRMPowerPortalBaseURLFailure;
    


// Action names
export const MP_SAVE_DRAFT_WORK_REQUEST: MP_SAVE_DRAFT_WORK_REQUEST_TYPE = "MP_SAVE_DRAFT_WORK_REQUEST";
export const MP_SAVE_DRAFT_WORK_SUCCESS: MP_SAVE_DRAFT_WORK_SUCCESS_TYPE = "MP_SAVE_DRAFT_WORK_SUCCESS";
export const MP_SAVE_DRAFT_WORK_FAILURE: MP_SAVE_DRAFT_WORK_FAILURE_TYPE = "MP_SAVE_DRAFT_WORK_FAILURE";
export const MP_SAVE_DRAFT_SET_LISTS_REQUEST: MP_SAVE_DRAFT_SET_LISTS_REQUEST_TYPE = "MP_SAVE_DRAFT_SET_LISTS_REQUEST";
export const MP_SAVE_DRAFT_SET_LISTS_SUCCESS: MP_SAVE_DRAFT_SET_LISTS_SUCCESS_TYPE = "MP_SAVE_DRAFT_SET_LISTS_SUCCESS";
export const MP_SAVE_DRAFT_SET_LISTS_FAILURE: MP_SAVE_DRAFT_SET_LISTS_FAILURE_TYPE = "MP_SAVE_DRAFT_SET_LISTS_FAILURE";
export const MP_SAVE_DRAFT_LIVE_PERFORMANCE_SUCCESS: MP_SAVE_DRAFT_LIVE_PERFORMANCE_SUCCESS_TYPE = "MP_SAVE_DRAFT_LIVE_PERFORMANCE_SUCCESS";
export const MP_SAVE_DRAFT_LIVE_PERFORMANCE_FAILURE: MP_SAVE_DRAFT_LIVE_PERFORMANCE_FAILURE_TYPE = "MP_SAVE_DRAFT_LIVE_PERFORMANCE_FAILURE";
export const MP_SAVE_DRAFT_AGREEMENT_REQUEST: MP_SAVE_DRAFT_AGREEMENT_REQUEST_TYPE = "MP_SAVE_DRAFT_AGREEMENT_REQUEST";
export const MP_SAVE_DRAFT_AGREEMENT_SUCCESS: MP_SAVE_DRAFT_AGREEMENT_SUCCESS_TYPE = "MP_SAVE_DRAFT_AGREEMENT_SUCCESS";
export const MP_SAVE_DRAFT_AGREEMENT_FAILURE: MP_SAVE_DRAFT_AGREEMENT_FAILURE_TYPE = "MP_SAVE_DRAFT_AGREEMENT_FAILURE";

export const MP_DELETE_DRAFT_WORK_REQUEST: MP_DELETE_DRAFT_WORK_REQUEST_TYPE = "MP_DELETE_DRAFT_WORK_REQUEST";
export const MP_DELETE_DRAFT_WORK_SUCCESS: MP_DELETE_DRAFT_WORK_SUCCESS_TYPE = "MP_DELETE_DRAFT_WORK_SUCCESS";
export const MP_DELETE_DRAFT_WORK_FAILURE: MP_DELETE_DRAFT_WORK_FAILURE_TYPE = "MP_DELETE_DRAFT_WORK_FAILURE";
export const MP_DELETE_DRAFT_SET_LIST_REQUEST: MP_DELETE_DRAFT_SET_LIST_REQUEST_TYPE = "MP_DELETE_DRAFT_SET_LIST_REQUEST";
export const MP_DELETE_DRAFT_SET_LIST_SUCCESS: MP_DELETE_DRAFT_SET_LIST_SUCCESS_TYPE = "MP_DELETE_DRAFT_SET_LIST_SUCCESS";
export const MP_DELETE_DRAFT_SET_LIST_FAILURE: MP_DELETE_DRAFT_SET_LIST_FAILURE_TYPE = "MP_DELETE_DRAFT_SET_LIST_FAILURE";

export const MP_CREATE_NEW_DRAFT_WORK: MP_CREATE_NEW_DRAFT_WORK_TYPE = "MP_CREATE_NEW_DRAFT_WORK";
export const MP_CREATE_NEW_DRAFT_AGREEMENT: MP_CREATE_NEW_DRAFT_AGREEMENT_TYPE = "MP_CREATE_NEW_DRAFT_AGREEMENT"
export const MP_CREATE_NEW_DRAFT_SET_LISTS: MP_CREATE_NEW_DRAFT_SET_LISTS_TYPE = "MP_CREATE_NEW_DRAFT_SET_LISTS";
export const MP_CREATE_NEW_DRAFT_LIVE_PERFORMANCE: MP_CREATE_NEW_DRAFT_LIVE_PERFORMANCE_TYPE = "MP_CREATE_NEW_DRAFT_LIVE_PERFORMANCE";

export const MP_CREATE_NEW_LIVE_PERFORMANCE: MP_CREATE_NEW_LIVE_PERFORMANCE_TYPE = "MP_CREATE_NEW_LIVE_PERFORMANCE";

export const MP_COPY_DRAFT_WORK: MP_COPY_DRAFT_WORK_TYPE = "MP_COPY_DRAFT_WORK";
export const MP_COPY_DRAFT_SET_LIST: MP_COPY_DRAFT_SET_LIST_TYPE = "MP_COPY_DRAFT_SET_LIST";
export const MP_COPY_DRAFT_LIVE_PERFORMANCE: MP_COPY_DRAFT_LIVE_PERFORMANCE_TYPE = "MP_COPY_DRAFT_LIVE_PERFORMANCE";

export const MP_UNDO_DRAFT_WORK_CHANGES_REQUEST: MP_UNDO_DRAFT_WORK_CHANGES_REQUEST_TYPE = "MP_UNDO_DRAFT_WORK_CHANGES_REQUEST";
export const MP_UNDO_DRAFT_WORK_CHANGES_SUCCESS: MP_UNDO_DRAFT_WORK_CHANGES_SUCCESS_TYPE = "MP_UNDO_DRAFT_WORK_CHANGES_SUCCESS";
export const MP_UNDO_DRAFT_WORK_CHANGES_FAILURE: MP_UNDO_DRAFT_WORK_CHANGES_FAILURE_TYPE = "MP_UNDO_DRAFT_WORK_CHANGES_FAILURE";

export const MP_UNDO_DRAFT_SET_LIST_CHANGES_REQUEST: MP_UNDO_DRAFT_SET_LIST_CHANGES_REQUEST_TYPE = "MP_UNDO_DRAFT_SET_LIST_CHANGES_REQUEST";
export const MP_UNDO_DRAFT_SET_LIST_CHANGES_SUCCESS: MP_UNDO_DRAFT_SET_LIST_CHANGES_SUCCESS_TYPE = "MP_UNDO_DRAFT_SET_LIST_CHANGES_SUCCESS";
export const MP_UNDO_DRAFT_SET_LIST_CHANGES_FAILURE: MP_UNDO_DRAFT_SET_LIST_CHANGES_FAILURE_TYPE = "MP_UNDO_DRAFT_SET_LIST_CHANGES_FAILURE";

export const MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_REQUEST: MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_REQUEST_TYPE = "MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_REQUEST";
export const MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_SUCCESS: MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_SUCCESS_TYPE = "MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_SUCCESS";
export const MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_FAILURE: MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_FAILURE_TYPE = "MP_SEARCH_DRAFT_WORK_VERSION_HISTORY_FAILURE";


export const MP_SEARCH_DRAFT_WORKS_REQUEST: MP_SEARCH_DRAFT_WORKS_REQUEST_TYPE = "MP_SEARCH_DRAFT_WORKS_REQUEST";
export const MP_SEARCH_DRAFT_WORKS_SUCCESS: MP_SEARCH_DRAFT_WORKS_SUCCESS_TYPE = "MP_SEARCH_DRAFT_WORKS_SUCCESS";
export const MP_SEARCH_DRAFT_WORKS_FAILURE: MP_SEARCH_DRAFT_WORKS_FAILURE_TYPE = "MP_SEARCH_DRAFT_WORKS_FAILURE";

export const MP_SEARCH_DRAFT_AGREEMENTS_REQUEST: MP_SEARCH_DRAFT_AGREEMENTS_REQUEST_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_REQUEST";
export const MP_SEARCH_DRAFT_AGREEMENTS_SUCCESS: MP_SEARCH_DRAFT_AGREEMENTS_SUCCESS_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_SUCCESS";
export const MP_SEARCH_DRAFT_AGREEMENTS_FAILURE: MP_SEARCH_DRAFT_AGREEMENTS_FAILURE_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_FAILURE";

export const MP_SEARCH_DRAFT_AGREEMENTS_IP_REQUEST: MP_SEARCH_DRAFT_AGREEMENTS_IP_REQUEST_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_IP_REQUEST";
export const MP_SEARCH_DRAFT_AGREEMENTS_IP_SUCCESS: MP_SEARCH_DRAFT_AGREEMENTS_IP_SUCCESS_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_IP_SUCCESS";
export const MP_SEARCH_DRAFT_AGREEMENTS_IP_FAILURE: MP_SEARCH_DRAFT_AGREEMENTS_IP_FAILURE_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_IP_FAILURE";

export const MP_SEARCH_DRAFT_AGREEMENTS_WORKS_REQUEST: MP_SEARCH_DRAFT_AGREEMENTS_WORKS_REQUEST_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_WORKS_REQUEST";
export const MP_SEARCH_DRAFT_AGREEMENTS_WORKS_SUCCESS: MP_SEARCH_DRAFT_AGREEMENTS_WORKS_SUCCESS_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_WORKS_SUCCESS";
export const MP_SEARCH_DRAFT_AGREEMENTS_WORKS_FAILURE: MP_SEARCH_DRAFT_AGREEMENTS_WORKS_FAILURE_TYPE = "MP_SEARCH_DRAFT_AGREEMENTS_WORKS_FAILURE";



export const MP_SEARCH_REGISTERED_AGREEMENTS_REQUEST: MP_SEARCH_REGISTERED_AGREEMENTS_REQUEST_TYPE = "MP_SEARCH_REGISTERED_AGREEMENTS_REQUEST";
export const MP_SEARCH_REGISTERED_AGREEMENTS_SUCCESS: MP_SEARCH_REGISTERED_AGREEMENTS_SUCCESS_TYPE = "MP_SEARCH_REGISTERED_AGREEMENTS_SUCCESS";
export const MP_SEARCH_REGISTERED_AGREEMENTS_FAILURE: MP_SEARCH_REGISTERED_AGREEMENTS_FAILURE_TYPE = "MP_SEARCH_REGISTERED_AGREEMENTS_FAILURE";

export const MP_SEARCH_DRAFT_SET_LISTS_REQUEST: MP_SEARCH_DRAFT_SET_LISTS_REQUEST_TYPE = "MP_SEARCH_DRAFT_SET_LISTS_REQUEST";
export const MP_SEARCH_DRAFT_SET_LISTS_SUCCESS: MP_SEARCH_DRAFT_SET_LISTS_SUCCESS_TYPE = "MP_SEARCH_DRAFT_SET_LISTS_SUCCESS";
export const MP_SEARCH_DRAFT_SET_LISTS_FAILURE: MP_SEARCH_DRAFT_SET_LISTS_FAILURE_TYPE = "MP_SEARCH_DRAFT_SET_LISTS_FAILURE";

export const MP_GET_DRAFT_WORK_DETAILS_REQUEST: MP_GET_DRAFT_WORK_DETAILS_REQUEST_TYPE = "MP_GET_DRAFT_WORK_DETAILS_REQUEST";
export const MP_GET_DRAFT_WORK_DETAILS_SUCCESS: MP_GET_DRAFT_WORK_DETAILS_SUCCESS_TYPE = "MP_GET_DRAFT_WORK_DETAILS_SUCCESS";
export const MP_GET_DRAFT_WORK_DETAILS_FAILURE: MP_GET_DRAFT_WORK_DETAILS_FAILURE_TYPE = "MP_GET_DRAFT_WORK_DETAILS_FAILURE";

export const MP_GET_DRAFT_AGREEMENT_DETAILS_REQUEST: MP_GET_DRAFT_AGREEMENT_DETAILS_REQUEST_TYPE = "MP_GET_DRAFT_AGREEMENT_DETAILS_REQUEST";
export const MP_GET_DRAFT_AGREEMENT_DETAILS_SUCCESS: MP_GET_DRAFT_AGREEMENT_DETAILS_SUCCESS_TYPE = "MP_GET_DRAFT_AGREEMENT_DETAILS_SUCCESS";
export const MP_GET_DRAFT_AGREEMENT_DETAILS_FAILURE: MP_GET_DRAFT_AGREEMENT_DETAILS_FAILURE_TYPE = "MP_GET_DRAFT_AGREEMENT_DETAILS_FAILURE";


export const MP_GET_REGISTERED_WORK_DETAILS_REQUEST: MP_GET_REGISTERED_WORK_DETAILS_REQUEST_TYPE = "MP_GET_REGISTERED_WORK_DETAILS_REQUEST";
export const MP_GET_REGISTERED_WORK_DETAILS_SUCCESS: MP_GET_REGISTERED_WORK_DETAILS_SUCCESS_TYPE = "MP_GET_REGISTERED_WORK_DETAILS_SUCCESS";
export const MP_GET_REGISTERED_WORK_DETAILS_FAILURE: MP_GET_REGISTERED_WORK_DETAILS_FAILURE_TYPE = "MP_GET_REGISTERED_WORK_DETAILS_FAILURE";

export const MP_GET_DRAFT_SET_LIST_DETAILS_REQUEST: MP_GET_DRAFT_SET_LIST_DETAILS_REQUEST_TYPE = "MP_GET_DRAFT_SET_LIST_DETAILS_REQUEST";
export const MP_GET_DRAFT_SET_LIST_DETAILS_SUCCESS: MP_GET_DRAFT_SET_LIST_DETAILS_SUCCESS_TYPE = "MP_GET_DRAFT_SET_LIST_DETAILS_SUCCESS";
export const MP_GET_DRAFT_SET_LIST_DETAILS_FAILURE: MP_GET_DRAFT_SET_LIST_DETAILS_FAILURE_TYPE = "MP_GET_DRAFT_SET_LIST_DETAILS_FAILURE";

export const MP_GET_REGISTERED_SET_LIST_DETAILS_REQUEST: MP_GET_REGISTERED_SET_LIST_DETAILS_REQUEST_TYPE = "MP_GET_REGISTERED_SET_LIST_DETAILS_REQUEST";
export const MP_GET_REGISTERED_SET_LIST_DETAILS_SUCCESS: MP_GET_REGISTERED_SET_LIST_DETAILS_SUCCESS_TYPE = "MP_GET_REGISTERED_SET_LIST_DETAILS_SUCCESS";
export const MP_GET_REGISTERED_SET_LIST_DETAILS_FAILURE: MP_GET_REGISTERED_SET_LIST_DETAILS_FAILURE_TYPE = "MP_GET_REGISTERED_SET_LIST_DETAILS_FAILURE";

export const MP_GET_ALL_REGISTERED_WORK_DETAILS_REQUEST: MP_GET_ALL_REGISTERED_WORK_DETAILS_REQUEST_TYPE = "MP_GET_ALL_REGISTERED_WORK_DETAILS_REQUEST";
export const MP_GET_ALL_REGISTERED_WORK_DETAILS_SUCCESS: MP_GET_ALL_REGISTERED_WORK_DETAILS_SUCCESS_TYPE = "MP_GET_ALL_REGISTERED_WORK_DETAILS_SUCCESS";
export const MP_GET_ALL_REGISTERED_WORK_DETAILS_FAILURE: MP_GET_ALL_REGISTERED_WORK_DETAILS_FAILURE_TYPE = "MP_GET_ALL_REGISTERED_WORK_DETAILS_FAILURE";


export const MP_GET_DRAFT_WORK_ID_REQUEST: MP_GET_DRAFT_WORK_ID_REQUEST_TYPE = "MP_GET_DRAFT_WORK_ID_REQUEST";
export const MP_GET_DRAFT_WORK_ID_SUCCESS: MP_GET_DRAFT_WORK_ID_SUCCESS_TYPE = "MP_GET_DRAFT_WORK_ID_SUCCESS";
export const MP_GET_DRAFT_WORK_ID_FAILURE: MP_GET_DRAFT_WORK_ID_FAILURE_TYPE = "MP_GET_DRAFT_WORK_ID_FAILURE";

export const MP_UPDATE_DRAFT_WORK_FIELD: MP_UPDATE_DRAFT_WORK_FIELD_TYPE = "MP_UPDATE_DRAFT_WORK_FIELD";
export const MP_UPDATE_DRAFT_AGREEMENT_FIELD: MP_UPDATE_DRAFT_AGREEMENT_FIELD_TYPE = "MP_UPDATE_DRAFT_AGREEMENT_FIELD";

export const MP_UPDATE_DRAFT_SET_LISTS_FIELD: MP_UPDATE_DRAFT_SET_LISTS_FIELD_TYPE = "MP_UPDATE_DRAFT_SET_LISTS_FIELD";
export const MP_UPDATE_LIVE_PERFORMANCE_FIELD: MP_UPDATE_LIVE_PERFORMANCE_FIELD_TYPE = "MP_UPDATE_LIVE_PERFORMANCE_FIELD";
export const MP_UPDATE_LIVE_PERFORMANCE_SET_LIST_ADDITIONAL_FIELD: MP_UPDATE_LIVE_PERFORMANCE_SET_LIST_ADDITIONAL_FIELD_TYPE = "MP_UPDATE_LIVE_PERFORMANCE_SET_LIST_ADDITIONAL_FIELD";
export const MP_UPDATE_LIVE_PERFORMANCE_VENUE_DETAILS_FIELD: MP_UPDATE_LIVE_PERFORMANCE_VENUE_DETAILS_FIELD_TYPE = "MP_UPDATE_LIVE_PERFORMANCE_VENUE_DETAILS_FIELD";

export const MP_UPDATE_DRAFT_AGREEMENT_WORK_FIELD: MP_UPDATE_DRAFT_AGREEMENT_WORK_FIELD_TYPE = "MP_UPDATE_DRAFT_AGREEMENT_WORK_FIELD";

export const MP_SAVE_REGISTERED_WORK_REQUEST: MP_SAVE_REGISTERED_WORK_REQUEST_TYPE = "MP_SAVE_REGISTERED_WORK_REQUEST";
export const MP_SAVE_REGISTERED_WORK_SUCCESS: MP_SAVE_REGISTERED_WORK_SUCCESS_TYPE = "MP_SAVE_REGISTERED_WORK_SUCCESS";
export const MP_SAVE_REGISTERED_WORK_FAILURE: MP_SAVE_REGISTERED_WORK_FAILURE_TYPE = "MP_SAVE_REGISTERED_WORK_FAILURE";

export const MP_DELETE_REGISTERED_WORK_REQUEST: MP_DELETE_REGISTERED_WORK_REQUEST_TYPE = "MP_DELETE_REGISTERED_WORK_REQUEST";
export const MP_DELETE_REGISTERED_WORK_SUCCESS: MP_DELETE_REGISTERED_WORK_SUCCESS_TYPE = "MP_DELETE_REGISTERED_WORK_SUCCESS";
export const MP_DELETE_REGISTERED_WORK_FAILURE: MP_DELETE_REGISTERED_WORK_FAILURE_TYPE = "MP_DELETE_REGISTERED_WORK_FAILURE";

export const MP_CREATE_NEW_REGISTERED_WORK: MP_CREATE_NEW_REGISTERED_WORK_TYPE = "MP_CREATE_NEW_REGISTERED_WORK";

export const MP_COPY_REGISTERED_WORK: MP_COPY_REGISTERED_WORK_TYPE = "MP_COPY_REGISTERED_WORK";

export const MP_UNDO_REGISTERED_WORK_CHANGES_REQUEST: MP_UNDO_REGISTERED_WORK_CHANGES_REQUEST_TYPE = "MP_UNDO_REGISTERED_WORK_CHANGES_REQUEST";
export const MP_UNDO_REGISTERED_WORK_CHANGES_SUCCESS: MP_UNDO_REGISTERED_WORK_CHANGES_SUCCESS_TYPE = "MP_UNDO_REGISTERED_WORK_CHANGES_SUCCESS";
export const MP_UNDO_REGISTERED_WORK_CHANGES_FAILURE: MP_UNDO_REGISTERED_WORK_CHANGES_FAILURE_TYPE = "MP_UNDO_REGISTERED_WORK_CHANGES_FAILURE";

export const MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_REQUEST: MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_REQUEST_TYPE = "MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_REQUEST";
export const MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_SUCCESS: MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_SUCCESS_TYPE = "MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_SUCCESS";
export const MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_FAILURE: MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_FAILURE_TYPE = "MP_SEARCH_REGISTERED_WORK_VERSION_HISTORY_FAILURE";


export const MP_SEARCH_REGISTERED_WORKS_REQUEST: MP_SEARCH_REGISTERED_WORKS_REQUEST_TYPE = "MP_SEARCH_REGISTERED_WORKS_REQUEST";
export const MP_SEARCH_REGISTERED_WORKS_SUCCESS: MP_SEARCH_REGISTERED_WORKS_SUCCESS_TYPE = "MP_SEARCH_REGISTERED_WORKS_SUCCESS";
export const MP_SEARCH_REGISTERED_WORKS_FAILURE: MP_SEARCH_REGISTERED_WORKS_FAILURE_TYPE = "MP_SEARCH_REGISTERED_WORKS_FAILURE";

export const MP_SEARCH_REGISTERED_SET_LISTS_REQUEST: MP_SEARCH_REGISTERED_SET_LISTS_REQUEST_TYPE = "MP_SEARCH_REGISTERED_SET_LISTS_REQUEST";
export const MP_SEARCH_REGISTERED_SET_LISTS_SUCCESS: MP_SEARCH_REGISTERED_SET_LISTS_SUCCESS_TYPE = "MP_SEARCH_REGISTERED_SET_LISTS_SUCCESS";
export const MP_SEARCH_REGISTERED_SET_LISTS_FAILURE: MP_SEARCH_REGISTERED_SET_LISTS_FAILURE_TYPE = "MP_SEARCH_REGISTERED_SET_LISTS_FAILURE";

export const MP_SEARCH_ALL_REGISTERED_WORKS_REQUEST: MP_SEARCH_ALL_REGISTERED_WORKS_REQUEST_TYPE = "MP_SEARCH_ALL_REGISTERED_WORKS_REQUEST";
export const MP_SEARCH_ALL_REGISTERED_WORKS_SUCCESS: MP_SEARCH_ALL_REGISTERED_WORKS_SUCCESS_TYPE = "MP_SEARCH_ALL_REGISTERED_WORKS_SUCCESS";
export const MP_SEARCH_ALL_REGISTERED_WORKS_FAILURE: MP_SEARCH_ALL_REGISTERED_WORKS_FAILURE_TYPE = "MP_SEARCH_ALL_REGISTERED_WORKS_FAILURE";


export const MP_GET_REGISTERED_WORK_REQUEST: MP_GET_REGISTERED_WORK_REQUEST_TYPE = "MP_GET_REGISTERED_WORK_REQUEST";
export const MP_GET_REGISTERED_WORK_SUCCESS: MP_GET_REGISTERED_WORK_SUCCESS_TYPE = "MP_GET_REGISTERED_WORK_SUCCESS";
export const MP_GET_REGISTERED_WORK_FAILURE: MP_GET_REGISTERED_WORK_FAILURE_TYPE = "MP_GET_REGISTERED_WORK_FAILURE";

export const MP_GET_REGISTERED_WORK_ID_REQUEST: MP_GET_REGISTERED_WORK_ID_REQUEST_TYPE = "MP_GET_REGISTERED_WORK_ID_REQUEST";
export const MP_GET_REGISTERED_WORK_ID_SUCCESS: MP_GET_REGISTERED_WORK_ID_SUCCESS_TYPE = "MP_GET_REGISTERED_WORK_ID_SUCCESS";
export const MP_GET_REGISTERED_WORK_ID_FAILURE: MP_GET_REGISTERED_WORK_ID_FAILURE_TYPE = "MP_GET_REGISTERED_WORK_ID_FAILURE";

export const MP_UPDATE_REGISTERED_WORK_FIELD: MP_UPDATE_REGISTERED_WORK_FIELD_TYPE = "MP_UPDATE_REGISTERED_WORK_FIELD";

export const MP_SET_WARNING_MESSAGE: MP_SET_WARNING_MESSAGE_TYPE = 'MP_SET_WARNING_MESSAGE';

export const MP_INCREMENT_TEMPID: MP_INCREMENT_TEMPID_TYPE = "MP_INCREMENT_TEMPID";

export const MP_UPDATE_WORK_CONTRIBUTOR_IP_REQUEST: MP_UPDATE_WORK_CONTRIBUTOR_IP_REQUEST_TYPE = "MP_UPDATE_WORK_CONTRIBUTOR_IP_REQUEST";
export const MP_UPDATE_WORK_CONTRIBUTOR_IP_SUCCESS: MP_UPDATE_WORK_CONTRIBUTOR_IP_SUCCESS_TYPE = "MP_UPDATE_WORK_CONTRIBUTOR_IP_SUCCESS";
export const MP_UPDATE_WORK_CONTRIBUTOR_IP_FAILURE: MP_UPDATE_WORK_CONTRIBUTOR_IP_FAILURE_TYPE = "MP_UPDATE_WORK_CONTRIBUTOR_IP_FAILURE";
export const MP_SAVE_WORK_CONTRIBUTOR_COMMENT: MP_SAVE_WORK_CONTRIBUTOR_COMMENT_TYPE = "MP_SAVE_WORK_CONTRIBUTOR_COMMENT";

export const MP_SEARCH_CONTRIBUTORS_REQUEST: MP_SEARCH_CONTRIBUTORS_REQUEST_TYPE =
    "MP_SEARCH_CONTRIBUTORS_REQUEST";
export const MP_SEARCH_CONTRIBUTORS_SUCCESS: MP_SEARCH_CONTRIBUTORS_SUCCESS_TYPE =
    "MP_SEARCH_CONTRIBUTORS_SUCCESS";
export const MP_SEARCH_CONTRIBUTORS_FAILURE: MP_SEARCH_CONTRIBUTORS_FAILURE_TYPE =
    "MP_SEARCH_CONTRIBUTORS_FAILURE";

export const MP_SEARCH_ARTISTS_REQUEST: MP_SEARCH_ARTISTS_REQUEST_TYPE =
    "MP_SEARCH_ARTISTS_REQUEST";
export const MP_SEARCH_ARTISTS_SUCCESS: MP_SEARCH_ARTISTS_SUCCESS_TYPE =
    "MP_SEARCH_ARTISTS_SUCCESS";
export const MP_SEARCH_ARTISTS_FAILURE: MP_SEARCH_ARTISTS_FAILURE_TYPE =
    "MP_SEARCH_ARTISTS_FAILURE";

export const MP_ADD_ARTIST_TO_WORK: MP_ADD_ARTIST_TO_WORK_TYPE =
    "MP_ADD_ARTIST_TO_WORK";

export const MP_ADD_REPERTOIRE_ATTACHMENT_REQUEST: MP_ADD_REPERTOIRE_ATTACHMENT_REQUEST_TYPE = 'MP_ADD_REPERTOIRE_ATTACHMENT_REQUEST';
export const MP_ADD_REPERTOIRE_ATTACHMENT_SUCCESS: MP_ADD_REPERTOIRE_ATTACHMENT_SUCCESS_TYPE = 'MP_ADD_REPERTOIRE_ATTACHMENT_SUCCESS';
export const MP_ADD_REPERTOIRE_ATTACHMENT_FAILURE: MP_ADD_REPERTOIRE_ATTACHMENT_FAILURE_TYPE = 'MP_ADD_REPERTOIRE_ATTACHMENT_FAILURE';

export const MP_REMOVE_WORK_ATTACHMENT_REQUEST: MP_REMOVE_WORK_ATTACHMENT_REQUEST_TYPE = 'MP_REMOVE_WORK_ATTACHMENT_REQUEST';
export const MP_REMOVE_WORK_ATTACHMENT_SUCCESS: MP_REMOVE_WORK_ATTACHMENT_SUCCESS_TYPE = 'MP_REMOVE_WORK_ATTACHMENT_SUCCESS';
export const MP_REMOVE_WORK_ATTACHMENT_FAILURE: MP_REMOVE_WORK_ATTACHMENT_FAILURE_TYPE = 'MP_REMOVE_WORK_ATTACHMENT_FAILURE';

export const MP_ADD_DROPPED_FILE: MP_ADD_DROPPED_FILE_TYPE = 'MP_ADD_DROPPED_FILE';
export const MP_SET_NEW_PROGRESS_REPERTOIRE_UPLOAD_FILE: MP_SET_NEW_PROGRESS_UPLOAD_FILE_REPERTOIRE_PROCCESS_TYPE = 'MP_SET_NEW_PROGRESS_UPLOAD_FILE_REPERTOIRE_PROCCESS';
export const MP_CLEAN_DROPPED_FILES_STATE: MP_CLEAN_DROPPED_FILES_STATE_TYPE = 'MP_CLEAN_DROPPED_FILES_STATE';

export const MP_REGISTER_STEPS_SET_CURRENT: MP_REGISTER_STEPS_SET_CURRENT_TYPE = "MP_REGISTER_STEPS_SET_CURRENT";


export const MP_SEARCH_REQUEST_ACCESS_IP: MP_SEARCH_REQUEST_ACCESS_IP_TYPE = 'MP_SEARCH_REQUEST_ACCESS_IP'

export const MP_SEARCH_REQUEST_ACCESS_IP_SUCCESS: MP_SEARCH_REQUEST_ACCESS_IP_SUCCESS_TYPE = 'MP_SEARCH_REQUEST_ACCESS_IP_SUCCESS';
export const MP_SEARCH_REQUEST_ACCESS_IP_FAILURE: MP_SEARCH_REQUEST_ACCESS_IP_FAILURE_TYPE = 'MP_SEARCH_REQUEST_ACCESS_IP_FAILURE';

export const MP_REQUEST_VIEW_AS_ACCESS: MP_REQUEST_VIEW_AS_ACCESS_TYPE = 'MP_REQUEST_VIEW_AS_ACCESS'

export const MP_REQUEST_VIEW_AS_ACCESS_SUCCESS: MP_REQUEST_VIEW_AS_ACCESS_SUCCESS_TYPE = 'MP_REQUEST_VIEW_AS_ACCESS_SUCCESS';
export const MP_REQUEST_VIEW_AS_ACCESS_FAILURE: MP_REQUEST_VIEW_AS_ACCESS_FAILURE_TYPE = 'MP_REQUEST_VIEW_AS_ACCESS_FAILURE';


export const MP_GET_VIEW_AS_ACCESS_REQUESTS: MP_GET_VIEW_AS_ACCESS_REQUESTS_TYPE = 'MP_GET_VIEW_AS_ACCESS_REQUESTS'

export const MP_GET_VIEW_AS_ACCESS_REQUESTS_SUCCESS: MP_GET_VIEW_AS_ACCESS_REQUESTS_SUCCESS_TYPE = 'MP_GET_VIEW_AS_ACCESS_REQUESTS_SUCCESS';
export const MP_GET_VIEW_AS_ACCESS_REQUESTS_FAILURE: MP_GET_VIEW_AS_ACCESS_REQUESTS_FAILURE_TYPE = 'MP_GET_VIEW_AS_ACCESS_REQUESTS_FAILURE';

export const MP_GET_MY_APPLICATIONS: MP_GET_MY_APPLICATIONS_TYPE = 'MP_GET_MY_APPLICATIONS';

export const MP_GET_MY_APPLICATIONS_SUCCESS: MP_GET_MY_APPLICATIONS_SUCCESS_TYPE = 'MP_GET_MY_APPLICATIONS_SUCCESS';
export const MP_GET_MY_APPLICATIONS_FAILURE: MP_GET_MY_APPLICATIONS_FAILURE_TYPE = 'MP_GET_MY_APPLICATIONS_FAILURE';

export const MP_GET_MY_CASES: MP_GET_MY_CASES_TYPE = 'MP_GET_MY_CASES';

export const MP_GET_MY_CASES_SUCCESS: MP_GET_MY_CASES_SUCCESS_TYPE = 'MP_GET_MY_CASES_SUCCESS';
export const MP_GET_MY_CASES_FAILURE: MP_GET_MY_CASES_FAILURE_TYPE = 'MP_GET_MY_CASES_FAILURE';


export const MP_SAVE_VIEW_AS_ACCESS: MP_SAVE_VIEW_AS_ACCESS_TYPE = 'MP_SAVE_VIEW_AS_ACCESS'
export const MP_SAVE_VIEW_AS_ACCESS_SUCCESS: MP_SAVE_VIEW_AS_ACCESS_SUCCESS_TYPE = 'MP_SAVE_VIEW_AS_ACCESS_SUCCESS';
export const MP_SAVE_VIEW_AS_ACCESS_FAILURE: MP_SAVE_VIEW_AS_ACCESS_FAILURE_TYPE = 'MP_SAVE_VIEW_AS_ACCESS_FAILURE';
export const MP_RESET_VIEW_AS_SAVE_STATUS: MP_RESET_VIEW_AS_SAVE_STATUS_TYPE = 'MP_RESET_VIEW_AS_SAVE_STATUS'

export const MP_SEARCH_STATEMENTS_REQUEST: MP_SEARCH_STATEMENTS_REQUEST_TYPE = "MP_SEARCH_STATEMENTS_REQUEST";
export const MP_SEARCH_STATEMENTS_SUCCESS: MP_SEARCH_STATEMENTS_SUCCESS_TYPE = "MP_SEARCH_STATEMENTS_SUCCESS";
export const MP_SEARCH_STATEMENTS_FAILURE: MP_SEARCH_STATEMENTS_FAILURE_TYPE = "MP_SEARCH_STATEMENTS_FAILURE";

export const MP_SEARCH_UNPAID_CLAIMS_REQUEST: MP_SEARCH_UNPAID_CLAIMS_REQUEST_TYPE = "MP_SEARCH_UNPAID_CLAIMS_REQUEST";
export const MP_SEARCH_UNPAID_CLAIMS_FAILURE: MP_SEARCH_UNPAID_CLAIMS_FAILURE_TYPE = "MP_SEARCH_UNPAID_CLAIMS_FAILURE";
export const MP_SEARCH_UNPAID_CLAIMS_SUCCESS: MP_SEARCH_UNPAID_CLAIMS_SUCCESS_TYPE = "MP_SEARCH_UNPAID_CLAIMS_SUCCESS";

export const MP_SEARCH_CLAIMS_HISTORY_REQUEST: MP_SEARCH_CLAIMS_HISTORY_REQUEST_TYPE = "MP_SEARCH_CLAIMS_HISTORY_REQUEST";
export const MP_SEARCH_CLAIMS_HISTORY_FAILURE: MP_SEARCH_CLAIMS_HISTORY_FAILURE_TYPE = "MP_SEARCH_CLAIMS_HISTORY_FAILURE";
export const MP_SEARCH_CLAIMS_HISTORY_SUCCESS: MP_SEARCH_CLAIMS_HISTORY_SUCCESS_TYPE = "MP_SEARCH_CLAIMS_HISTORY_SUCCESS";
export const MP_CLAIM_UNPAID_USAGE_REQUEST: MP_CLAIM_UNPAID_USAGE_REQUEST_TYPE = "MP_CLAIM_UNPAID_USAGE_REQUEST";
export const MP_CLAIM_UNPAID_USAGE_FAILURE: MP_CLAIM_UNPAID_USAGE_FAILURE_TYPE = "MP_CLAIM_UNPAID_USAGE_FAILURE";
export const MP_CLAIM_UNPAID_USAGE_SUCCESS: MP_CLAIM_UNPAID_USAGE_SUCCESS_TYPE = "MP_CLAIM_UNPAID_USAGE_SUCCESS";

export const MP_GET_STATEMENT_DETAILS_REQUEST: MP_GET_STATEMENT_DETAILS_REQUEST_TYPE = "MP_GET_STATEMENT_DETAILS_REQUEST";
export const MP_GET_STATEMENT_DETAILS_SUCCESS: MP_GET_STATEMENT_DETAILS_SUCCESS_TYPE = "MP_GET_STATEMENT_DETAILS_SUCCESS";
export const MP_GET_STATEMENT_DETAILS_FAILURE: MP_GET_STATEMENT_DETAILS_FAILURE_TYPE = "MP_GET_STATEMENT_DETAILS_FAILURE";

export const MP_CANCEL_ACCESS_REQUEST: MP_CANCEL_ACCESS_REQUEST_TYPE = 'MP_CANCEL_ACCESS_REQUEST'
export const MP_CANCEL_ACCESS_REQUEST_SUCCESS: MP_CANCEL_ACCESS_REQUEST_SUCCESS_TYPE = 'MP_CANCEL_ACCESS_REQUEST_SUCCESS';
export const MP_CANCEL_ACCESS_REQUEST_FAILURE: MP_CANCEL_ACCESS_REQUEST_FAILURE_TYPE = 'MP_CANCEL_ACCESS_REQUEST_FAILURE';
export const MP_RESET_MESSAGE_BANNER: MP_RESET_MESSAGE_BANNER_TYPE = "MP_RESET_MESSAGE_BANNER";
export const MP_ADD_SET_LIST_TO_LIVE_PERFORMANCE: ADD_SET_LIST_TO_LIVE_PERFORMANCE_TYPE = "MP_ADD_SET_LIST_TO_LIVE_PERFORMANCE_TYPE";
export const MP_ADD_WORK_TO_SET_LIST: ADD_WORK_TO_SET_LIST_TYPE = "MP_ADD_WORK_TO_SET_LIST";

export const MP_SEARCH_LIVE_PERFORMANCES_REQUEST: MP_SEARCH_LIVE_PERFORMANCES_REQUEST_TYPE = "MP_SEARCH_LIVE_PERFORMANCES_REQUEST";
export const MP_SEARCH_LIVE_PERFORMANCES_SUCCESSS: MP_SEARCH_LIVE_PERFORMANCES_SUCCESS_TYPE = "MP_SEARCH_LIVE_PERFORMANCES_SUCCESS";
export const MP_SEARCH_LIVE_PERFORMANCES_FAILURE: MP_SEARCH_LIVE_PERFORMANCES_FAILURE_TYPE = "MP_SEARCH_LIVE_PERFORMANCES_FAILURE";

export const MP_GET_LIVE_PERFORMANCE_DETAILS_REQUEST: MP_GET_LIVE_PERFORMANCE_DETAILS_REQUEST_TYPE = "MP_GET_LIVE_PERFORMANCE_DETAILS_REQUEST";
export const MP_GET_LIVE_PERFORMANCE_DETAILS_SUCCESS: MP_GET_LIVE_PERFORMANCE_DETAILS_SUCCESS_TYPE = "MP_GET_LIVE_PERFORMANCE_DETAILS_SUCCESS";
export const MP_GET_LIVE_PERFORMANCE_DETAILS_FAILURE: MP_GET_LIVE_PERFORMANCE_DETAILS_FAILURE_TYPE = "MP_GET_LIVE_PERFORMANCE_DETAILS_FAILURE";

export const MP_GET_SHARE_DECIMALS_TO_DISPLAY_REQUEST: MP_GET_SHARE_DECIMALS_TO_DISPLAY_REQUEST_TYPE = "MP_GET_SHARE_DECIMALS_TO_DISPLAY_REQUEST";
export const MP_GET_SHARE_DECIMALS_TO_DISPLAY_SUCCESS: MP_GET_SHARE_DECIMALS_TO_DISPLAY_SUCCESS_TYPE = "MP_GET_SHARE_DECIMALS_TO_DISPLAY_SUCCESS";
export const MP_GET_SHARE_DECIMALS_TO_DISPLAY_FAILURE: MP_GET_SHARE_DECIMALS_TO_DISPLAY_FAILURE_TYPE = "MP_GET_SHARE_DECIMALS_TO_DISPLAY_FAILURE";

export const MP_REFRESH_WORK_DETAILS_REQUEST: MP_REFRESH_WORK_DETAILS_REQUEST_TYPE = "MP_REFRESH_WORK_DETAILS_REQUEST";
export const MP_REFRESH_WORK_DETAILS_SUCCESS: MP_REFRESH_WORK_DETAILS_SUCCESS_TYPE = "MP_REFRESH_WORK_DETAILS_SUCCESS";
export const MP_REFRESH_WORK_DETAILS_FAILURE: MP_REFRESH_WORK_DETAILS_FAILURE_TYPE = "MP_REFRESH_WORK_DETAILS_FAILURE";

export const MP_REFRESH_AGREEMENT_DETAILS_REQUEST: MP_REFRESH_AGREEMENT_DETAILS_REQUEST_TYPE = "MP_REFRESH_AGREEMENT_DETAILS_REQUEST";
export const MP_REFRESH_AGREEMENT_DETAILS_SUCCESS: MP_REFRESH_AGREEMENT_DETAILS_SUCCESS_TYPE = "MP_REFRESH_AGREEMENT_DETAILS_SUCCESS";
export const MP_REFRESH_AGREEMENT_DETAILS_FAILURE: MP_REFRESH_AGREEMENT_DETAILS_FAILURE_TYPE = "MP_REFRESH_AGREEMENT_DETAILS_FAILURE";
export const MP_GET_CRM_INTEGRATION_ENABLED_REQUEST: MP_GET_CRM_INTEGRATION_ENABLED_REQUEST_TYPE = 'MP_GET_CRM_INTEGRATION_ENABLED_REQUEST_TYPE';
export const MP_GET_CRM_INTEGRATION_ENABLED_SUCCESS: MP_GET_CRM_INTEGRATION_ENABLED_SUCCESS_TYPE = 'MP_GET_CRM_INTEGRATION_ENABLED_SUCCESS_TYPE';
export const MP_GET_CRM_INTEGRATION_ENABLED_FAILURE: MP_GET_CRM_INTEGRATION_ENABLED_FAILURE_TYPE = 'MP_GET_CRM_INTEGRATION_ENABLED_FAILURE_TYPE';

export const MP_GET_CRM_POWER_PORTAL_BASE_URL_REQUEST: MP_GET_CRM_POWER_PORTAL_BASE_URL_REQUEST_TYPE = 'MP_GET_CRM_POWER_PORTAL_BASE_URL_REQUEST';
export const MP_GET_CRM_POWER_PORTAL_BASE_URL_SUCCESS: MP_GET_CRM_POWER_PORTAL_BASE_URL_SUCCESS_TYPE = 'MP_GET_CRM_POWER_PORTAL_BASE_URL_SUCCESS';
export const MP_GET_CRM_POWER_PORTAL_BASE_URL_FAILURE: MP_GET_CRM_POWER_PORTAL_BASE_URL_FAILURE_TYPE = 'MP_GET_CRM_POWER_PORTAL_BASE_URL_FAILURE';
