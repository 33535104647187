import * as React from "react";
import FormBoxMenuContainer from "../../core/components/formBoxMenu/FormBoxMenuContainer";
import { IMenuItem } from "../../core/types/IMenuItem";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { LANGUAGE_LOOKUP } from "../../lookup/Consts";
import IHomeComponentData from "../types/IHomeComponentData";
import JobSearchResultsViewContainer from "./jobSearchResultsView/JobSearchResultsViewContainer";
import { IJobSearchState } from "../types/IJobSearchState";
import { IJobSearchResult } from "../types/IJobSearchResult";
import { setCurrentPage } from "../../redux/reducers/HomeReducer";
import { IEnableModulesConfigurationParametersItem } from "../../redux/types/IEnableModulesConfigurationParameterItem";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ICwrgParameters } from "../../redux/types/ICwrgParameters";
import { hideModal, showModal, updateComment } from "../../redux/reducers/ModalReducer";
import HomeModal from "./homeModal/HomeModal";
import { getCWRAckHWM, getManualJobDataSources, getUsageFilenames, getScheduledJob, getAllUsageFilenamesFromDistribution, triggerManualJob, upsertScheduledJobAsync } from "../../redux/thunks/HomeThunks";
import { IAckHWMState } from "../types/IAckHWMState";
import { IDataSourceBase } from "../types/JobParameterControls/IDataSource";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IContributorSearchQuery } from "../../repertoire/types/IContributorSearchQuery";
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";
import { MEMBERS_PORTAL } from "../../accounts/Consts";
import { Card } from 'antd';
import FooterContainer from "../../core/components/footer/FooterContainer";
import IShortCutKeys from "../../redux/types/IShortCutKeys";
import { IApplication } from "../../membersportal/types/IApplication";
import { ICase } from "../../membersportal/types/ICase";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IRepertoireField } from "../../repertoire/types/IRepertoireField";
import { MEMBER_MY_APPLICATIONS, MEMBER_MY_CASES } from "../../membersportal/Consts";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { ColumnsType } from "antd/lib/table/interface";
import { Checkbox, Table } from "antd";
import { NavItem, NavLink } from 'reactstrap';
import { Link } from "react-router-dom";
import { parseDateType } from "../../membersportal/util/dateHelpers";

interface IHomePageProps {
    getHomeFields: () => void;
    getLookupEntities: () => void;
    getUsers: () => void;
    searchJobs: (searchQuery: IJobSearchState) => void;
    componentData: IHomeComponentData;
    lookups: ILookupDictionary;
    users: string[];
    jobSearchState: IJobSearchState;
    jobSearchResults: IJobSearchResult[];
    currentPage: IMenuItem;
    setCurrentPage: typeof setCurrentPage;
    enableModules?: IEnableModulesConfigurationParametersItem[];
    getEnabledModules?: () => void;
    defaultActiveAccordions?: IActiveAccordion[];
    resultsPerPage?: IResultsPerPage[];
    resultsPerModalPage?: number;
    currentModalPage: number;
    getUserPreferences?: () => void;
    manualJobs: () => void;
    displayModal: boolean;
    modalContent: string;
    modalComponentInstance: string;
    modalProps: any;
    displayModalCloseButton: boolean;
    modalTitle: string;
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerModalPage: number, currentPage: number, repertoireSection: string) => void;
    updateModalComment: typeof updateComment;
    modalMessage: string;
    manualJobType: string;
    manualJobParameters: any;
    triggerManualJob: typeof triggerManualJob;
    getUsageFilenames:typeof getUsageFilenames;
    getScheduledJob: typeof getScheduledJob;
    upsertScheduledJobAsync:typeof upsertScheduledJobAsync; 
    getAllUsageFilenamesFromDistribution:typeof getAllUsageFilenamesFromDistribution;
    cwrAckHWM: IAckHWMState[];
    getCWRAckHWM: typeof getCWRAckHWM;
    fetchManualJobDataSources: typeof getManualJobDataSources
    manualJobDataSources: { [key: string]: IDataSourceBase }
    getPaymentRunIDs: () => void;
    paymentRunIDs: any;
    portalType: string;
    username?: string;
    currentUserNameNumber?: string;
    searchViewData?: IRepertoireComponentDataItem;
    dataSource?: string;
    searchResultsTableData?: IRepertoireComponentDataItem;
    searchContributors?: (
        query: IContributorSearchQuery
    ) => void;
    contributorsSearchResults?: IContributorSearchResult[];
    searchSuccessful?: boolean;
    searchViewResultsPerPage?: number;
    searchViewCurrentPage?: number;
    updateManualJobParams: (params: any) => void;
    getCustomer: () => void;
    getCustomerDateConfiguration: () => void;
    getUserCRMValuesThunk: () => void;
    currentViewAsUserName: string;
    getUserCRMIPNumber: (guid: string) => void;
    getShortCutKeys: () => void;
    shortCutKeys: IShortCutKeys[];
    getMyApplications: () => void;
    myApplications: IApplication[];
    getMyCases: () => void;
    myCases: ICase[];
    membersPortalSearchResultsTableData: IMembersPortalComponentDataItem;
    getCRMIntegratedEnabled: () => void;
    isCRMIntegrationEnabled: boolean;
    powerPortalURL: string;
    customerDatePreference: string;
}

export class HomePage extends React.Component<IHomePageProps>{
    constructor(props: IHomePageProps) {
        super(props);
    }

    componentDidMount() {
        const {
            getHomeFields,
            getLookupEntities,
            getUsers,
            searchJobs,
            componentData,
            lookups,
            users,
            jobSearchState,
            jobSearchResults,
            enableModules,
            getEnabledModules,
            getUserPreferences,
            defaultActiveAccordions,
            resultsPerPage,
            getCustomer,
            getCustomerDateConfiguration,
            portalType,
            getUserCRMValuesThunk,
            currentUserNameNumber,
            getShortCutKeys,
            shortCutKeys,           
            getCRMIntegratedEnabled,
            isCRMIntegrationEnabled,
            getMyApplications,
            getMyCases
        } = this.props;

        if (portalType !== MEMBERS_PORTAL) {
            if (componentData.JobSearchView.fields.length === 0) {
                getHomeFields();
            }
            if (!(lookups && lookups[LANGUAGE_LOOKUP])) {
                getLookupEntities();
            }
            if (!users || users.length === 0) {
                getUsers();
            }
            if (!jobSearchResults || jobSearchResults.length === 0) {
                searchJobs(jobSearchState)
            }
        }

        if (portalType === MEMBERS_PORTAL) {
            this.setState(() => ({ isLoading: true }));
            if (!(lookups && lookups[LANGUAGE_LOOKUP])) {
                getLookupEntities();
            }
            if (!currentUserNameNumber) {               
                if (!currentUserNameNumber){
                    getUserCRMValuesThunk();
                }               
            }

            if (isCRMIntegrationEnabled) {
                getMyApplications();
                getMyCases();
            }
            else {
                getCRMIntegratedEnabled();           
            }

        }

        if (!enableModules || enableModules.length === 0)
            getEnabledModules && getEnabledModules();

        if ((!(defaultActiveAccordions && defaultActiveAccordions.length > 0) || !(resultsPerPage && resultsPerPage.length > 0)) && getUserPreferences)
            getUserPreferences();
        if (!(shortCutKeys && shortCutKeys.length > 0) && getShortCutKeys) {
            getShortCutKeys();
        }

        getCustomer();
        getCustomerDateConfiguration();

    }

    getMyApplicationsColumns = () =>{
        const { membersPortalSearchResultsTableData, customerDatePreference } = this.props;

        if (membersPortalSearchResultsTableData) {

            if (membersPortalSearchResultsTableData && membersPortalSearchResultsTableData.fields) {

                let filteredFields = membersPortalSearchResultsTableData.fields.filter(
                    (field: IRepertoireField) =>
                        field.componentInstance === MEMBER_MY_APPLICATIONS
                );

                let orderedFields = ComponentsHelper.orderFieldsBySpecificField(filteredFields, "order");

                if (!orderedFields && orderedFields.length > 0) {
                    orderedFields = filteredFields
                }                

                let columns: ColumnsType<IApplication> = orderedFields.filter(x => !x.hidden).map((field, index) => {
                    switch (field.name) {                                              
                        case 'applicationId':
                            return {
                                title: 'Application Id',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                                render: (value, record, index) => {
                                    return <NavItem>
                                        <NavLink tag={Link} to={{ pathname: `${"/myForms"}`, aboutProps: { guid: record.guid } }} onClick={() => this.props.setCurrentPage(`${"/myForms?guid="}${record.guid}`)}>{record.applicationId}</NavLink>
                                    </NavItem>;
                                }
                            }
                        case 'name':
                            return {
                                title: 'Contact',
                                dataIndex: field.name,
                                width: '8em',
                                key: `${field.name}`,
                                sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                                render: (value, record, index) => {
                                    return record.name;
                                }
                            }
                        case 'submittedDateTime':
                            return {
                                title: 'Submitted Date',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                                render: (value, record, index) => {
                                    return parseDateType(record.submittedDateTime, customerDatePreference + ' HH:mm:ss');
                                }
                            }
                        case 'lastModifiedDateTime':
                            return {
                                title: 'Last Modified Date',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                render: (value, record, index) => {
                                    return parseDateType(record.lastModifiedDate, customerDatePreference + ' HH:mm:ss');
                                }
                            }
                        case 'status':
                            return {
                                title: 'Status',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                render: (value, record, index) => {
                                    return record.status;
                                }
                            }                          
                        default:
                            return {
                                title: field.data, dataIndex: field.name,
                                width: '6em',
                                key: `${field.name}`,
                                align: 'center',
                                sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                                render: (value) => value !== null ? <div key={`${value}_${index}`}>{value}</div> : null
                            }
                    }
                })
                return columns;
            }
        }
    }

    getMyCasesColumns = () => {
        const { membersPortalSearchResultsTableData, customerDatePreference  } = this.props;

        if (membersPortalSearchResultsTableData) {

            if (membersPortalSearchResultsTableData && membersPortalSearchResultsTableData.fields) {

                let filteredFields = membersPortalSearchResultsTableData.fields.filter(
                    (field: IRepertoireField) =>
                        field.componentInstance === MEMBER_MY_CASES
                );

                let orderedFields = ComponentsHelper.orderFieldsBySpecificField(filteredFields, "order");

                if (!orderedFields && orderedFields.length > 0) {
                    orderedFields = filteredFields
                }
               
                let columns: ColumnsType<ICase> = orderedFields.filter(x => !x.hidden).map((field, index) => {
                    switch (field.name) {
                        case 'ticketNumber':
                            return {
                                title: 'Ticket Number',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                                render: (value, record, index) => {
                                    return <NavItem>
                                        <NavLink tag={Link} to={{ pathname: `${"/myCases"}`, aboutProps: { guid: record.guid }}}>{record.ticketNumber}</NavLink>
                                    </NavItem>;
                                }
                            }
                        case 'title':
                            return {
                                title: 'Title',
                                dataIndex: field.name,
                                width: '8em',
                                key: `${field.name}`,
                                sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                                render: (value, record, index) => {
                                    return record.title;
                                }
                            }
                        case 'caseType':
                            return {
                                title: 'Case Type',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                                render: (value, record, index) => {
                                    return record.caseType;
                                }
                            }
                        case 'caseOrigin':
                            return {
                                title: 'Case Origin',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                render: (value, record, index) => {
                                    return record.caseOrigin;
                                }
                            }
                        case 'submittedDateTime':
                            return {
                                title: 'Submitted Date',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                render: (value, record, index) => {
                                    return parseDateType(record.submittedDateTime, customerDatePreference + ' HH:mm:ss');
                                }
                            }
                        case 'lastModifiedDateTime':
                            return {
                                title: 'Last Modified Date',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                render: (value, record, index) => {
                                    return parseDateType(record.lastModifiedDate, customerDatePreference + ' HH:mm:ss');
                                }
                            }
                        case 'status':
                            return {
                                title: 'Status',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                render: (value, record, index) => {
                                    return record.status;
                                }
                            }                                                
                        default:
                            return {
                                title: field.data, dataIndex: field.name,
                                width: '6em',
                                key: `${field.name}`,
                                align: 'center',
                                sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                                render: (value) => value !== null ? <div key={`${value}_${index}`}>{value}</div> : null
                            }
                    }
                })
                return columns;
            }
        }
    }

    renderCRMIntegration = () => {
        const { myApplications, myCases, isCRMIntegrationEnabled } = this.props;        

        const applicationColumns = this.getMyApplicationsColumns();
        const casesColumns = this.getMyCasesColumns();

        if (isCRMIntegrationEnabled)
            return (
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <span className="title">My Applications</span>
                                <div className="newJobButton" title="New Application">
                                    <div className="IconTextButton">
                                        <i className="icon ms-Icon ms-Icon--CircleAddition" aria-hidden="true"></i>
                                        <NavLink tag={Link} to={{ pathname: `${"/myForms"}`, aboutProps: { type: "new" } }} >Create New Application</NavLink>
                                    </div>
                                </div>
                                <div className="tableContainer">
                                    <Table
                                        columns={applicationColumns}
                                        dataSource={myApplications}
                                        rowClassName={() => 'editable-row'}
                                        size={'middle'}
                                        onChange={() => { }}
                                        className="parent-table"
                                        tableLayout={"fixed"}
                                    />
                                </div>
                            </div>
                        </div>
                     </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <span className="title">My Cases</span>
                                <div className="newJobButton" title="New Case">
                                    <div className="IconTextButton">
                                        <i className="icon ms-Icon ms-Icon--CircleAddition" aria-hidden="true"></i>
                                        <NavLink tag={Link} to={{ pathname: `${"/myCases"}`, aboutProps: { type: "new" } }}>Open New Case</NavLink>
                                    </div>
                                </div>
                                <div className="tableContainer">
                                    <Table
                                        columns={casesColumns}
                                        dataSource={myCases}
                                        rowClassName={() => 'editable-row'}
                                        size={'middle'}
                                        onChange={() => { }}
                                        className="parent-table"
                                        tableLayout={"fixed"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        else return <div></div>
    }    

    renderMatchingMaintenanceHomePage = () => {
        const { displayModal } = this.props;
        if (this.renderMatchingMaintenanceHomePage) {
            document.title = "Matching Engine";
        }
        return (
            <div className="container pageContent">
                <FormBoxMenuContainer />
                <div className="row">
                    <JobSearchResultsViewContainer />
                </div>
                {displayModal ? (<HomeModal {...this.props} />) : null}
            </div>
        );
    }

    renderMembersPortalHomePage = () => {
        const { currentViewAsUserName, currentUserNameNumber } = this.props;
        const username = sessionStorage.getItem('username');

        let viewInfo = undefined;
        if (!currentUserNameNumber)
            viewInfo = "hidding";
        else viewInfo = "";

        if (this.renderMembersPortalHomePage) {
            document.title = "Members Portal";
        }

        let viewAsName = undefined;
        if (!currentViewAsUserName) {
            viewAsName = sessionStorage.getItem('currentViewAsUserName');

            if (!viewAsName) {
                viewAsName = sessionStorage.getItem('username');
            }
        }
        else {
            viewAsName = currentViewAsUserName;
        }

        return (
            <div className="pageContent ">
                <div className="membersPortalContainer">
                    <Card className="membersPortalPanel">
                        <div className="row">
                            <div>
                                <h3 className="cardSubText">Welcome</h3>
                                <h3 className="cardMainText">{username}</h3>
                            </div>
                            <div className={viewInfo}>
                                <div className="cardSideText">
                                    <h3 className="sideDesc"> You are currently viewing the portal as:</h3>
                                    <h3 className="sideViewName">{viewAsName}</h3>
                                    <h3 className="sideIPNumber"> Account No. {currentUserNameNumber} </h3>
                                </div>
                            </div>
                        </div>
                    </Card>

                    <img className="membersPortalBgImg" src="assets/side-view-male-musician-home-playing-guitar-mixing-with-laptop.jpg" />

                    <div className="container" style={{ marginTop: '-40px' }}>
                        <FormBoxMenuContainer />
                        {this.renderCRMIntegration() }
                    </div>
                    <FooterContainer />
                </div>

            </div>
        );


    }

    render() {
        const { currentPage, setCurrentPage, portalType, username
        } = this.props;
        if (!currentPage || currentPage.link !== '/') {
            setCurrentPage('/');
        }
        if (portalType === MEMBERS_PORTAL) {
            return this.renderMembersPortalHomePage()
        }
        else {
            return this.renderMatchingMaintenanceHomePage()
        }
    }
}