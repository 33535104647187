import IAppState from "../../redux/types/IAppState";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { addTab, createNewUsagePool, resetPagination, sortSearchResults, updatePagination } from "../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { showModal } from "../../redux/reducers/ModalReducer";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { getMatchingSourcesThunk, getUsagePoolDetailsThunk, searchUsagePoolsThunk } from "../../redux/thunks/RepertoireThunks";
import PoolsPage from "./PoolsPage";
import { IUsagePoolSearchQuery } from "../types/usageTypes/IUsagePoolSearchQuery";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import { ITreeData } from "../types/ITreeData";
import { saveRoles } from "../../redux/reducers/AccountReducer";

export default connect(
    (state: IAppState) => ({
        usagePoolsPageData: state.repertoire.componentData.UsagePoolsPage,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        usagePoolsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].usagePoolSearchResult,
        searchSuccessful: state.repertoire.searchSuccessful,
        usagePoolGeneralDataView: state.repertoire.componentData.UsagePoolMaintenanceGeneralDataView,
        usagePoolMaintenanceToolbarData: state.repertoire.componentData.UsagePoolMaintenanceToolbar,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'pools')[0].indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'pools')[0].indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'pools')[0].resultsPerSection,
        sources: state.repertoire.matchingSourceTreeData,
        currentPage: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].currentPage ? state.repertoire.tabs[state.repertoire.activeTab].currentPage : undefined,
        roles: state.account.roles,
    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        searchUsagePools: (query: IUsagePoolSearchQuery) =>
            dispatch<any>(searchUsagePoolsThunk(query)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        getUsagePool: (poolId: number, matchingSources: ITreeData[],isPoolMaintainence?:boolean) => dispatch<any>(getUsagePoolDetailsThunk(poolId, matchingSources,true)),
        createNewUsagePool: (lookups: ILookupDictionary, isNew?: boolean, usagePoolMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) =>
          dispatch<any>(createNewUsagePool(lookups, isNew, usagePoolMaintenanceGeneralDataViewData)),
        getSources: () => dispatch<any>(getMatchingSourcesThunk()),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
    })
)(PoolsPage);

