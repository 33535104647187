import * as React from "react";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { IRepertoireField } from "../../../types/IRepertoireField";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { showModal } from "../../../../redux/reducers/ModalReducer";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IAccordionData } from "../../../types/IAccordionData";
import { addTab, updateWorkField, sortWorkFieldData, updateAgreementField } from "../../../../redux/reducers/RepertoireReducer";
import { IWorkStateKeys } from "../../../../redux/types/IWorkState";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { TITLES_KEY, CONTRIBUTORS_KEY, WORKS_RETAINED_STATE_KEY, BULK_TRANSFER_WORKS_STATE_KEY, AGREEMENT_SIGNATURE_DATE_STATE_KEY, PRIOR_ROYALTIES_STATE_KEY, EFFECTIVE_NOTIFIED_FROM_STATE_KEY, EFFECTIVE_NOTIFIED_TO_STATE_KEY, LICENSED_DIRECT_STATE_KEY, EFFECTIVE_FROM_STATE_KEY, EFFECTIVE_TO_STATE_KEY, EXPECTED_TERMINATION_STATE_KEY, EXPECTED_TERMINATION_TEXT_STATE_KEY, POST_TERM_RETENTION_PERIOD_STATE_KEY, DEFAULT_RETAINED_UNTIL_STATE_KEY, DEFAULT_RETENTION_END_DATE_STATE_KEY, POST_TERM_COLLECTION_PERIOD_STATE_KEY, DEFAULT_COLLECTION_UNTIL_STATE_KEY, DEFAULT_COLLECTION_END_DATE_STATE_KEY, SALES_OR_MANUFACTURE_STATE_KEY, LIBRARY_MUSIC_STATE_KEY, RECORDING_PREFIX_STATE_KEY, AGREEMENT_MAINTAINED_STATE_KEY } from "../../../Consts";
import { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import SizedDateDataInput from "../../../components/dateDataInput/DateDataInput";
import { WideTextDataInput, SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { IAgreementStateKeys } from "../../../../redux/types/IAgreementState";
import {
AGREEMENT_RETENTION_LOOKUP,
AGREEMENT_RETAINED_UNTIL_LOOKUP
} from "../../../../lookup/Consts";
import { getLookupDefaultMultiSelect, getLookupDefaultV2, getLookupValues } from "../../../../lookup/services/LookupHelpers";

export interface IAgreementMaintenanceAccordionProps {
    updateAgreementField: typeof updateAgreementField;
    agreementMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    field: IRepertoireField;
    lookupValues: ILookupDictionary;
    openWorkAccordian: (accordionName: string, componentName: string) => void;
    closeWorkAccordian: (accordionName: string, componentName: string) => void;
    tabs: ITabReduxItem[];
    activeTab: number;
    effectiveFrom: Date;
    effectiveTo?: Date;
    effectiveNotifiedTo: Date;
    effectiveNotifiedFrom: Date;
    salesOrManufacture: number;
    agreementSignatureDate: Date;
    bulkTransferWorks: boolean;
    priorRoyalties: boolean;
    expectedTermination: number;
    expectedTerminationText: string;
    postTermRetentionPeriod: boolean;
    postTermCollectionPeriod: boolean;
    libraryMusic: boolean;
    recordingPrefix: string;
    worksRetained?: string;
    defaultRetainedUntil: string;
    defaultRetentionEndDate: Date;
    defaultCollectionUntil: string;
    defaultCollectionPeriodEndDate: Date;
    licensedDirect: boolean;
    licensedDirectReadOnly?: boolean;
    isReadonly: boolean;
    incrementTempID?: () => void;
}

interface IAgreementMaintenanceAccordionState {
    isAccordionActive: boolean;
}

export default class AgreementMaintenanceAccordion extends React.PureComponent<
    IAgreementMaintenanceAccordionProps,
    IAgreementMaintenanceAccordionState
    > {
    viewData;
    constructor(props: IAgreementMaintenanceAccordionProps) {
        super(props);

        this.state = {
            isAccordionActive: false
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            openWorkAccordian,
            field: { name, fieldType, componentName },
            tabs,
            activeTab,
            agreementMaintenanceGeneralDataViewData: { fields }
        } = this.props;

        if (tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.find(x => x === name)) {
            openWorkAccordian(name, componentName);
            this.setState({ isAccordionActive: true });
        }

        fields.forEach(item => {
            this.viewData[item.name] = item.data;
        });
    }

    toggleAccordion = () => {
        const { isAccordionActive } = this.state;
        const {
            field: { name, componentName },
            openWorkAccordian,
            closeWorkAccordian,
            tabs,
            activeTab
        } = this.props;
        if (tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(name)) {
            closeWorkAccordian(name, componentName);
        } else {
            openWorkAccordian(name, componentName);
        }
        this.setState({
            isAccordionActive: !isAccordionActive
        });
    };

    changeData = (value: any, name: IRepertoireStateKeys) => {
        this.props.updateAgreementField(value, name as IAgreementStateKeys);
    }

    renderAccordionData = () => {
        const {
            field,
            lookupValues,
            updateAgreementField,
            tabs,
            activeTab,
            effectiveFrom,
            effectiveTo,
            effectiveNotifiedTo,
            effectiveNotifiedFrom,
            salesOrManufacture,
            agreementSignatureDate,
            bulkTransferWorks,
            priorRoyalties,
            expectedTermination,
            expectedTerminationText,
            postTermRetentionPeriod,
            postTermCollectionPeriod,
            libraryMusic,
            recordingPrefix,
            worksRetained,
            defaultRetainedUntil,
            defaultRetentionEndDate,
            defaultCollectionUntil,
            defaultCollectionPeriodEndDate,
            licensedDirect,
            licensedDirectReadOnly,
            isReadonly,
            incrementTempID,
        } = this.props

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            if(value == '') value = null
            updateAgreementField(value, name as IAgreementStateKeys);
        }

        if (tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(field.name)) {
            return (
                <div className="contentAccordionAgreement">
                    <div>
                    <div className="row">
                        <SizedDropdownDataInput
                            label={this.viewData[WORKS_RETAINED_STATE_KEY]}
                            fieldName={WORKS_RETAINED_STATE_KEY}
                            value={worksRetained}
                            changeData={changeData}
                            options={getLookupValues(AGREEMENT_RETAINED_UNTIL_LOOKUP , lookupValues)}
                            allowNull={true}
                            isHidden={false}
                            readOnly={isReadonly}
                        />

                        <SizedCheckboxDataInput
                            label={this.viewData[BULK_TRANSFER_WORKS_STATE_KEY]}
                            fieldName={BULK_TRANSFER_WORKS_STATE_KEY}
                            value={bulkTransferWorks}
                            changeData={changeData}
                            isHidden={false}
                            readonly={isReadonly}
                            />
                    </div>
                    <div className="row">
                        <SizedDateDataInput
                            label={this.viewData[AGREEMENT_SIGNATURE_DATE_STATE_KEY]}
                            fieldName={AGREEMENT_SIGNATURE_DATE_STATE_KEY}
                            changeData={changeData}
                            value={agreementSignatureDate ? agreementSignatureDate.toString() : null}
                            isHidden={false}
                            readOnly={isReadonly}
                        />

                        <SizedCheckboxDataInput
                            label={this.viewData[PRIOR_ROYALTIES_STATE_KEY]}
                            fieldName={PRIOR_ROYALTIES_STATE_KEY}
                            value={priorRoyalties}
                            changeData={changeData}
                            isHidden={false}
                            readonly={isReadonly}
                        />
                    </div>
                    <div className="row">
                        <SizedDateDataInput
                            label={this.viewData[EFFECTIVE_NOTIFIED_FROM_STATE_KEY]}
                            fieldName={EFFECTIVE_NOTIFIED_FROM_STATE_KEY}
                            changeData={changeData}
                            value={effectiveNotifiedFrom ? effectiveNotifiedFrom.toString() : null}
                            isHidden={false}
                            readOnly={isReadonly}
                        />

                        <SizedDateDataInput
                            label={this.viewData[EFFECTIVE_NOTIFIED_TO_STATE_KEY]}
                            fieldName={EFFECTIVE_NOTIFIED_TO_STATE_KEY}
                            changeData={changeData}
                            value={effectiveNotifiedTo ? effectiveNotifiedTo.toString() : null}
                            isHidden={false}
                            readOnly={isReadonly}
                        />

                        <SizedCheckboxDataInput
                            label={this.viewData[LICENSED_DIRECT_STATE_KEY]}
                            fieldName={LICENSED_DIRECT_STATE_KEY}
                            value={licensedDirect}
                            changeData={changeData}
                            isHidden={false}
                            readonly={licensedDirectReadOnly ? licensedDirectReadOnly : isReadonly}
                        />
                    </div>
                    <div className="row">
                        <SizedDateDataInput
                                label={this.viewData[EFFECTIVE_FROM_STATE_KEY]}
                                fieldName={EFFECTIVE_FROM_STATE_KEY}
                                changeData={changeData}
                                value={effectiveFrom ? effectiveFrom.toString() : null}
                                isHidden={false}
                                readOnly={isReadonly}
                                isMandatory={true}
                        />

                        <SizedDateDataInput
                            label={this.viewData[EFFECTIVE_TO_STATE_KEY]}
                            fieldName={EFFECTIVE_TO_STATE_KEY}
                                changeData={changeData}
                                value={effectiveTo ? effectiveTo.toString() : null}
                            isHidden={false}
                            readOnly={isReadonly}
                        />
                    </div>
                    <div className="row">
                        <SizedDropdownDataInput
                            label={this.viewData[EXPECTED_TERMINATION_STATE_KEY]}
                            fieldName={EXPECTED_TERMINATION_STATE_KEY}
                            value={expectedTermination}
                            changeData={changeData}
                            options={null}
                            allowNull={true}
                            isHidden={false}
                            readOnly={isReadonly}
                        />
                    </div>
                    <div className="row">
                        <WideTextDataInput
                            label={this.viewData[EXPECTED_TERMINATION_TEXT_STATE_KEY]}
                            fieldName={EXPECTED_TERMINATION_TEXT_STATE_KEY}
                            value={expectedTerminationText}
                            changeData={changeData}
                            isHidden={false}
                            readOnly={isReadonly}
                        />
                    </div>
                    <div className="row">
                        <SizedCheckboxDataInput
                            label={this.viewData[POST_TERM_RETENTION_PERIOD_STATE_KEY]}
                            fieldName={POST_TERM_RETENTION_PERIOD_STATE_KEY}
                            value={postTermRetentionPeriod}
                            changeData={changeData}
                            isHidden={false}
                            readonly={isReadonly}
                        />

                        <SizedDropdownDataInput
                            label={this.viewData[DEFAULT_RETAINED_UNTIL_STATE_KEY]}
                            fieldName={DEFAULT_RETAINED_UNTIL_STATE_KEY}
                            value={defaultRetainedUntil}
                            changeData={changeData}
                            options={getLookupValues(AGREEMENT_RETAINED_UNTIL_LOOKUP , lookupValues)}
                            allowNull={true}
                            isHidden={false}
                            readOnly={isReadonly}
                        />

                        <SizedDateDataInput
                            label={this.viewData[DEFAULT_RETENTION_END_DATE_STATE_KEY]}
                            fieldName={DEFAULT_RETENTION_END_DATE_STATE_KEY}
                            changeData={changeData}
                            value={defaultRetentionEndDate ? defaultRetentionEndDate.toString() : null}
                            isHidden={false}
                            readOnly={isReadonly}
                        />
                    </div>
                    <div className="row">
                        <SizedCheckboxDataInput
                            label={this.viewData[POST_TERM_COLLECTION_PERIOD_STATE_KEY]}
                            fieldName={POST_TERM_COLLECTION_PERIOD_STATE_KEY}
                            value={postTermCollectionPeriod}
                            changeData={changeData}
                            isHidden={false}
                            readonly={isReadonly}
                        />

                        <SizedDropdownDataInput
                            label={this.viewData[DEFAULT_COLLECTION_UNTIL_STATE_KEY]}
                            fieldName={DEFAULT_COLLECTION_UNTIL_STATE_KEY}
                            value={defaultCollectionUntil}
                            changeData={changeData}
                            options={getLookupValues(AGREEMENT_RETENTION_LOOKUP , lookupValues)}
                            allowNull={true}
                            isHidden={false}
                            readOnly={isReadonly}
                        />

                        <SizedDateDataInput
                            label={this.viewData[DEFAULT_COLLECTION_END_DATE_STATE_KEY]}
                            fieldName={DEFAULT_COLLECTION_END_DATE_STATE_KEY}
                            changeData={changeData}
                            value={defaultCollectionPeriodEndDate ? defaultCollectionPeriodEndDate.toString() : null}
                            isHidden={false}
                            readOnly={isReadonly}
                        />
                    </div>
                    <div className="row">
                        <SizedDropdownDataInput
                            label={this.viewData[SALES_OR_MANUFACTURE_STATE_KEY]}
                            fieldName={SALES_OR_MANUFACTURE_STATE_KEY}
                            value={salesOrManufacture}
                            changeData={changeData}
                            options={null}
                            allowNull={true}
                            isHidden={false}
                            readOnly={isReadonly}
                        />

                        <SizedCheckboxDataInput
                            label={this.viewData[LIBRARY_MUSIC_STATE_KEY]}
                            fieldName={LIBRARY_MUSIC_STATE_KEY}
                            value={libraryMusic}
                            changeData={changeData}
                            isHidden={false}
                            readonly={isReadonly}
                        />

                        <SizedTextDataInput
                            label={this.viewData[RECORDING_PREFIX_STATE_KEY]}
                            fieldName={RECORDING_PREFIX_STATE_KEY}
                            value={recordingPrefix}
                            changeData={changeData}
                            isHidden={false}
                            readOnly={isReadonly}
                        />

                    </div>
                    </div>
                </div>
            );
        }
    };

    render() {
        const { field, tabs, activeTab } = this.props;
        return (
            <div className="AccordionContainer">
                <div
                    className={["itemAccordion", tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(field.name) ? "activeItem" : null].join(" ")}
                    onClick={() => this.toggleAccordion()}
                >
                    <i
                        title="Expand section"
                        className={["icon ms-Icon ms-Icon--ChevronRight",
                            tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(field.name) ? "expanded" : null].join(" ")}
                    />
                    {field.data}
                </div>
                <div>{this.renderAccordionData()}</div>
            </div>
        );
    }
}
