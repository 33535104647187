import * as React from "react";
import { render } from "react-dom";
import { hideModal } from "../../../redux/reducers/ModalReducer";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ASSING_VIEW_DESCRIPTION_STATE_KEY, ASSING_WORKFLOW_VIEW, SEARCH_VIEW_WORKFLOW, VERSION_HISTORY_VIEW } from "../../Consts";
import SelectionTable from "../selectionTable/SelectionTable";

export interface IAssingWorkflowViewProps {
    searchResultsTableData: IRepertoireComponentDataItem;
    hideModal: typeof hideModal;
    assingWorkflowViewData: IRepertoireComponentDataItem;
    items: any[];
    assingTo: (username: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
}

interface IAssingWorkflowViewState{
    loaded: boolean;
}

export default class AssingWorkflowView extends React.PureComponent<
IAssingWorkflowViewProps,IAssingWorkflowViewState>{
    viewData;

    constructor(props: IAssingWorkflowViewProps){
        super(props);

        this.state = {
            loaded: false
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            assingWorkflowViewData: {fields }
        } = this.props;

        if(fields){
            fields.forEach(item => {
                this.viewData[item.name] = item.data;
    
                if (Object.keys(this.viewData).length === fields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }
    
    render(){
        const { loaded } = this.state;
        const { searchResultsTableData, items, assingTo, updatePagination } = this.props;

        
        if(loaded){
            return (
                <div>
             <div className="centerTittle">
                 <p>{this.viewData[ASSING_VIEW_DESCRIPTION_STATE_KEY]}</p>
             </div>
             <div className="col-md-12 searchResultsDiv">
                 <SelectionTable
                     key={1}
                     searchResultsTableData={searchResultsTableData}
                     tableContents={items}
                     componentInstance={ASSING_WORKFLOW_VIEW}
                     onAssingWorkflows={assingTo}
                     hideModal={hideModal}
                     updatePagination={updatePagination}
                     repertoireSection={SEARCH_VIEW_WORKFLOW}
                 />
             </div>
         </div>
            )
        }
        else return null;
    }
}