import * as React from "react";
import WorkMaintenanceAccordion from "../workMaintenanceAccordion/WorkMaintenanceAccordion";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../../types/IRepertoireField";
import {
  hideModal,
  showModal,
  showUpdateFieldsModal,
  showWorkSubmission,
} from "../../../../redux/reducers/ModalReducer";
import {
  addTab,
  updateWorkField,
  sortWorkFieldData,
  updateContributorSetType,
  clearModalSearchResults,
  enableCustomField,
  setEditableFields,
} from "../../../../redux/reducers/RepertoireReducer";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IAccordionData } from "../../../types/IAccordionData";
import {
  ADD_NEW_ACTION,
  SEARCH_ACTION,
  PARENT_WORK_KEY,
  ARTICLE_WORK_TYPE_CODE,
  MAGAZINE_WORK_TYPE_CODE,
  SHEET_MUSIC_WORK_TYPE_CODE,
  CHAPTER_WORK_TYPE_CODE,
  BOOK_WORK_TYPE_CODE,
  BOOK_METADATA_KEY,
  CHAPTER_METADATA_KEY,
  SHEET_MUSIC_METADATA_KEY,
  MAGAZINE_METADATA_KEY,
  ARTICLE_METADATA_KEY,
  INSTRUMENTATION_KEY,
  OTHER_INDICATORS_KEY,
  READONLY_INDICATORS_KEY,
  INTEGER_INPUT,
  DROPDOWN_INPUT,
} from "../../../Consts";
import { ITitle } from "../../../types/ITitle";
import { IArtist } from "../../../types/IArtist";
import { IArticle } from "../../../types/IArticle";
import { INumber } from "../../../types/INumber";
import { IComment } from "../../../types/IComment";
import { IContributorItem } from "../../../types/IContributorItem";
import { Dictionary } from "../../../../core/types/Dictionary";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { DataSource } from "../../../types/DataSource";
import { IWorkProductDataItems } from "../../../types/IWorkProductDataItems";
import { IWorkPaymentRunDataItems } from "../../../types/IWorkPaymentRunDataItems";
import { IMatchingWork } from "../../../types/IMatchingWork";
import { IIntrayMatchingWork } from "../../../types/IIntrayMatchingWork";
import { IWorkContributorSetType } from "../../../types/IWorkContributorSetType";
import { IWorksSubmission } from "../../../types/IWorkSubmission";
import { IWorkSubmissionStatus } from "../../../types/IWorkSubmissionStatus";
import { IWorkState } from "../../../../redux/types/IWorkState";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { ManualMergeOptions } from "../../../types/ManualMergeOptions";
import { IContainedWork } from "../../../types/IContainedWork";
import { IContributorSiteConfiguration } from "../../../types/IContributorSiteConfiguration";
import { IInstrumentation } from "../../../types/IInstrumentation";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { IDistribution } from "../../../types/usageTypes/IDistribution";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import IDroppedFiles from "../../../../redux/types/IDroppedFiles";
import {
  addFileToUpload,
  CleanUploadFilesState,
  setInDropZone,
  setProgressUploadFileProccess,
  sortColumn,
} from "../../../../redux/reducers/DataIngestionReducer";
import { IStoragePathItemData } from "../../../../dataingestion/types/IStoragePathItemData";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { IReplacedWork } from "../../../types/IReplacedWork";
import { IAgreementShareDisplayConfiguration } from "../../../types/IAgreementShareDisplayConfiguration";
import { IWorkRepresentation } from "../../../types/IWorkRepresentation";
import { ISheetMusic } from "../../../types/ISheetMusic";
import { IBook } from "../../../types/IBook";
import { IChapter } from "../../../types/IChapter";
import { IMagazine } from "../../../types/IMagazine";
import { IFlag } from "../../../types/IFlag";
import { IReadonlyFlagsField } from "../../../types/IReadonlyFlagsField";
import { IWorkMaintenanceSubject } from "../../../types/IWorkMaintenanceSubjects";
import { IWorkPrice } from "../../../types/IWorkPrice";
import { INumberAdditional } from "../../../types/INumberAdditional";
import { ISubject } from "../../../types/ISubject";
import { IEdition } from "../../../types/IEdition";
import { IWorkEditionDataItems } from "../../../types/IWorkEditionDataItems";

export interface IWorkMaintenanceAccordionListProps {
  updateWorkField?: typeof updateWorkField;
  sortWorkFieldData: typeof sortWorkFieldData;
  updateContributorSetType: typeof updateContributorSetType;
  workMaintenanceAccordionViewData: IRepertoireComponentDataItem;
  dataGridTableData?: IRepertoireComponentDataItem;
  contributorsDataViewData?: IRepertoireComponentDataItem;
  workMetadataAccordionViewData?: IRepertoireComponentDataItem;
  componentInstance: string;
  showModal: typeof showModal;
  clearModalSearchResults: typeof clearModalSearchResults;
  lookupValues: ILookupDictionary;
  activeAccordian: string[];
  openWorkAccordian: (accordionName: string, componentName: string) => void;
  closeWorkAccordian: (accordionName: string, componentName: string) => void;
  dataSource: DataSource;
  workNames: ITitle[];
  workNumbers: INumber[];
  subjects: ISubject[];
  workNumbersAdditional: INumberAdditional[];
  workArtists: IArtist[];
  workContributorSetTypes: IWorkContributorSetType[];
  workContributors: Dictionary<IContributorItem[]>;
  workArticles: IArticle;
  workSheetMusic: ISheetMusic;
  workBook: IBook;
  workChapter: IChapter;
  workMagazine: IMagazine;
    workFlags: IFlag[];
    workPrice: IWorkPrice[];
  readonlyIndicators: IFlag[];
  workNotes: IComment[];
  workProduct: IWorkProductDataItems[];
  workPaymentRun: IWorkPaymentRunDataItems[];
  matchingWorks: IMatchingWork[];
  matchingIntrayWorks: IIntrayMatchingWork[];
  addTab: typeof addTab;
  tabs: ITabReduxItem[];
  activeTab: number;
  getAgreementDetails: (
    dataSource: string,
    agreementID: number,
    lookups: ILookupDictionary,
    dataAction?: IDataActionToolbar[],
    agreementGeneralDataView?: IRepertoireComponentDataItem
  ) => void;
  isReadonly: boolean;
  getWorkDetails: (dataSource: string, workID: number) => void;
  getProductDetails: (dataSource: string, productID: number) => void;
  getIPDetails: (ipBaseNumber: string, accountNumber: string) => void;
  iswcSubmissions?: IWorksSubmission[];
  cisNetSubmissions?: IWorksSubmission[];
  widSubmissions?: IWorksSubmission[];
  iceSubmissions?: IWorksSubmission[];
  showWorkSubmissionDetail?: typeof showWorkSubmission;
  hideModal?: typeof hideModal;
  workSubmissionDetails?: IRepertoireComponentDataItem;
  work: IWorkState;
  currentUser?: string;
  manualMergeWork: (
    matchingWorkId: number,
    manualMergeOption: ManualMergeOptions
  ) => void;
  relatedWorks: IContainedWork[];
  contributorDisplaySettings: IContributorSiteConfiguration[];
  agreementMaintenanceGeneralDataView: IRepertoireComponentDataItem;
  workEdition: IWorkEditionDataItems[];
  workInstrumentation: IInstrumentation[];
  customFields?: number[];
  enableCustomField?: typeof enableCustomField;
  containerDetailsWindowComponentData: IComponentDataItem;
  distributions: IDistribution[];
  destinations: ILookupInstance[];
  selectedFileSystem: IFileSystem;
  droppedFiles: IDroppedFiles;
  setInDropZone: typeof setInDropZone;
  addFileToUpload: typeof addFileToUpload;
  sortColumn: typeof sortColumn;
  isDropZone: boolean;
  setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
  cleanUploadFilesState: typeof CleanUploadFilesState;
  storagePaths: IStoragePathItemData[];
  storagePathItemData: IComponentDataItem;
  refreshDirectory: (filesystem: IFileSystem) => void;
  attachedFiles?: IAttachedFile[];
  updateSetTypeOnBreak: (setType: string) => void;
  showUpdateFieldsModal?: typeof showUpdateFieldsModal;
  editableContributorFieldGridDataView: IRepertoireComponentDataItem;
  contributorGridEditableFields: IRepertoireField[];
  setEditableFields: typeof setEditableFields;
  updateContributorFields?: (
    fields: IRepertoireField[],
    componentName: string,
    componentInstance?: string
  ) => void;
  replacedWorks: IReplacedWork[];
  agreementShareDisplayOptions: IAgreementShareDisplayConfiguration;
  shareDecimalsToDisplay?: number;
  resetMessageBanner?: () => void;
  containerWorks?: IContainedWork[];
  readonlyFlagsField: IReadonlyFlagsField[];
  editableFields: IRepertoireField[];
  updateComponentFieldsItems: (
    fields: IRepertoireField[],
    componentName: string,
    componentInstance: string,
    componentDataFieldName: string,
    tabs: ITabReduxItem[],
    activeTab: number
  ) => void;
  addNewAccount: () => void;
  representations: IWorkRepresentation[];
  expandedWorkResults?: number[];
  expandMatchingWork?: (index: number) => void;
  saveResultData: IRepertoireComponentDataItem;
  incrementTempID?: () => void;
  commentCategoryFlag?: boolean;
  saveAdditionalWorkNumbers?: (pageNumber: number, additionalNumbers: INumberAdditional[]) => void;
  getCurrentPageDetails?: (pageNumber: number) => void;
}

const WorkMaintenanceAccordionList: React.SFC<
  IWorkMaintenanceAccordionListProps
> = ({
  updateWorkField,
  sortWorkFieldData,
  componentInstance,
  workMaintenanceAccordionViewData,
  dataGridTableData,
  contributorsDataViewData,
  workMetadataAccordionViewData,
  showModal,
  clearModalSearchResults,
  lookupValues,
  activeAccordian,
  openWorkAccordian,
  closeWorkAccordian,
  dataSource,
  workNames,
  workNumbers,
  subjects,
  workNumbersAdditional,
  workArtists,
  workArticles,
  workSheetMusic,
  workBook,
  workChapter,
  workMagazine,
  workFlags,
  workPrice,
  readonlyIndicators,
  workContributors,
  workNotes,
  workProduct,
  workPaymentRun,
  matchingWorks,
  matchingIntrayWorks,
  addTab,
  tabs,
  activeTab,
  getAgreementDetails,
  workContributorSetTypes,
  updateContributorSetType,
  isReadonly,
  getWorkDetails,
  getProductDetails,
  getIPDetails,
  iswcSubmissions,
  iceSubmissions,
  cisNetSubmissions,
  widSubmissions,
  showWorkSubmissionDetail,
  hideModal,
  workSubmissionDetails,
  work,
  currentUser,
  manualMergeWork,
  relatedWorks,
  contributorDisplaySettings,
  agreementMaintenanceGeneralDataView,
  workEdition,
  workInstrumentation,
  customFields,
  enableCustomField,
  distributions,
  selectedFileSystem,
  droppedFiles,
  setInDropZone,
  sortColumn,
  addFileToUpload,
  isDropZone,
  setProgressUploadFileProccess,
  cleanUploadFilesState,
  storagePaths,
  storagePathItemData,
  containerDetailsWindowComponentData,
  destinations,
  refreshDirectory,
  attachedFiles,
  updateSetTypeOnBreak,
  showUpdateFieldsModal,
  editableFields,
  editableContributorFieldGridDataView,
  contributorGridEditableFields,
  setEditableFields,
  updateContributorFields,
  replacedWorks,
  agreementShareDisplayOptions,
  shareDecimalsToDisplay,
  resetMessageBanner,
  containerWorks,
  updateComponentFieldsItems,
  addNewAccount,
  readonlyFlagsField,
  representations,
  expandedWorkResults,
  expandMatchingWork,
  saveResultData,
  incrementTempID,
  commentCategoryFlag,
  saveAdditionalWorkNumbers,
  getCurrentPageDetails
}) => {
  const renderAccordions = () => {
    if (
      workMaintenanceAccordionViewData &&
      workMaintenanceAccordionViewData.fields
    ) {
      let filteredFields = workMaintenanceAccordionViewData.fields.filter(
        (field: IRepertoireField) =>
          field.componentInstance === componentInstance
      );

      if (dataSource === DataSource.Intray) {
        filteredFields = filteredFields.filter(
          (field: IRepertoireField) =>
            field.name !== "iceSubmissions" &&
            field.name !== "iswcSubmissions" &&
            field.name !== "cisNetSubmissions" &&
            field.name !== "widSubmissions"
        );
      }

      if (dataSource === DataSource.Repertoire) {
        filteredFields = filteredFields.filter(
          (field: IRepertoireField) => field.name !== "matchingWorks"
        );
      }

      if (!tabs[activeTab].enableCoreWorkNumbers || (dataSource === DataSource.Intray || work.workID === 0)) {
        filteredFields = filteredFields.filter(
          (field: IRepertoireField) => field.name !== "additionalNumbers"
        );
      }

      const getFieldData = (field: IRepertoireField): IAccordionData => {
        switch (field.name) {
          case "titles":
            const titleData: IAccordionData = {
              actions: [{ name: ADD_NEW_ACTION }],
              data: workNames,
            };
            return titleData;
          case "subjects":
            const subjectData: IAccordionData = {
                actions: [{ name: ADD_NEW_ACTION }],
                data: subjects,
            };
            return subjectData;
          case "numbers":
            const numberData: IAccordionData = {
              actions: [{ name: ADD_NEW_ACTION }],
              data: workNumbers,
            };
            return numberData;
          case "additionalNumbers":
            const additionalNumberData: IAccordionData = {
              actions: [{ name: ADD_NEW_ACTION }],
              data: workNumbersAdditional,
            };
            return additionalNumberData;
          case "artists":
            const artistData: IAccordionData = {
              actions:
                dataSource === DataSource.Intray
                  ? [{ name: ADD_NEW_ACTION }]
                  : [{ name: SEARCH_ACTION }],
              data: workArtists,
            };
            return artistData;
          case "contributors":
            let contributorData: IAccordionData = {
              actions: [{ name: ADD_NEW_ACTION }],
              data: workContributors,
            };
            return contributorData;
          case "comments":
            let commentsData: IAccordionData = {
              actions: [{ name: ADD_NEW_ACTION }],
              data: workNotes,
            };
            return commentsData;
          case "licensesAndProducts":
            let workProductData: IAccordionData = {
              actions: [],
              data: workProduct,
            };

            return workProductData;
          case "workPaymentRuns":
            let workPaymentRunData: IAccordionData = {
              actions: [],
              data: workPaymentRun,
            };

            return workPaymentRunData;
          case "matchingRepertoireWorks":
            let matchingWorksData: IAccordionData = {
              actions: [{ name: ADD_NEW_ACTION }],
              data: matchingWorks,
            };
            return matchingWorksData;
          case "matchingIntrayWorks":
            let matchingIntrayWorksData: IAccordionData = {
              actions: [],
              data: matchingIntrayWorks,
            };
            return matchingIntrayWorksData;
          case "iceSubmissions":
            let iceSubmissionsData: IAccordionData = {
              actions: [],
              data: iceSubmissions,
            };
            return iceSubmissionsData
          case "iswcSubmissions":
            let icwsSubmissionsData: IAccordionData = {
              actions: [],
              data: iswcSubmissions,
            };
            return icwsSubmissionsData;
          case "cisNetSubmissions":
            let cisNetSubmissionsData: IAccordionData = {
              actions: [],
              data: cisNetSubmissions,
            };
            return cisNetSubmissionsData;  
          case "widSubmissions":
            let widSubmissionsData: IAccordionData = {
              actions: [],
              data: widSubmissions,
            };
            return widSubmissionsData;
          case "components":
            const relatedWorksData: IAccordionData = {
              actions: [{ name: SEARCH_ACTION }],
              data: relatedWorks,
            };
            return relatedWorksData;
          case "workEdition":
            let editionData: IAccordionData = {
              actions: [{ name: ADD_NEW_ACTION }],
              data: workEdition,
              };
            return editionData;
          case "instrumentation":
            let instrumentationData: IAccordionData = {
              actions: [{ name: ADD_NEW_ACTION }],
              data: workInstrumentation,
            };
            return instrumentationData;
          case "replaced":
            let replacedWorksData: IAccordionData = {
              actions: [],
              data: replacedWorks,
            };
            return replacedWorksData;
          case "representations":
            let representationsWorkData: IAccordionData = {
              actions: [],
              data: representations,
            };
            return representationsWorkData;
          case PARENT_WORK_KEY:
            let parentNavigationData: IAccordionData = {
              actions: [],
              data: containerWorks,
            };
            return parentNavigationData;
          case "articleMetadata":
            let articleData: IAccordionData = {
              actions: [],
              data: workArticles,
            };
            return articleData;
          case "sheetMusicMetadata":
            let sheetMusicData: IAccordionData = {
              actions: [],
              data: workSheetMusic,
            };
            return sheetMusicData;
          case "bookMetadata":
            let bookData: IAccordionData = {
              actions: [],
              data: workBook,
            };
            return bookData;
          case "chapterMetadata":
            let chapterData: IAccordionData = {
              actions: [],
              data: workChapter,
            };
            return chapterData;
          case "magazineMetadata":
            let magazineData: IAccordionData = {
              actions: [],
              data: workMagazine,
            };
            return magazineData;
          case "otherIndicators":
            let flagData: IAccordionData = {
              actions: [],
              data: workFlags,
            };
            return flagData;
          case "readonlyIndicators":
            let readonlyIndicatorsData: IAccordionData = {
              actions: [],
              data: readonlyIndicators,
            };
                return readonlyIndicatorsData;
          case "workPrice":
            const workPricesData: IAccordionData = {
                    actions: [{ name: ADD_NEW_ACTION }],
                data: workPrice,
                };
                return workPricesData;
          default:
            return null;
        }
      };

      return filteredFields.map((field: IRepertoireField, index: number) =>
        work.workType &&
        work.workType === SHEET_MUSIC_WORK_TYPE_CODE &&
        field.name === INSTRUMENTATION_KEY ? null : field.name ===
            BOOK_METADATA_KEY ||
          field.name === CHAPTER_METADATA_KEY ||
          field.name === SHEET_MUSIC_METADATA_KEY ||
          field.name === MAGAZINE_METADATA_KEY ||
          field.name === ARTICLE_METADATA_KEY ||
          field.name === OTHER_INDICATORS_KEY ||
          field.name === READONLY_INDICATORS_KEY ? (
          (work.workType &&
            work.workType === BOOK_WORK_TYPE_CODE &&
            field.name === BOOK_METADATA_KEY) ||
          (work.workType &&
            work.workType === BOOK_WORK_TYPE_CODE &&
            field.name === OTHER_INDICATORS_KEY) ||
          (work.workType &&
            work.workType === CHAPTER_WORK_TYPE_CODE &&
            field.name === CHAPTER_METADATA_KEY) ||
          (work.workType &&
            work.workType === SHEET_MUSIC_WORK_TYPE_CODE &&
            field.name === SHEET_MUSIC_METADATA_KEY) ||
          (work.workType &&
            work.workType === MAGAZINE_WORK_TYPE_CODE &&
            field.name === MAGAZINE_METADATA_KEY) ||
          (work.workType &&
            work.workType === ARTICLE_WORK_TYPE_CODE &&
            field.name === ARTICLE_METADATA_KEY) ||
          (work.workType && field.name === READONLY_INDICATORS_KEY) ? (
            <div key={index}>
              <WorkMaintenanceAccordion
                workSubmissionDetails={workSubmissionDetails}
                viewData={workMaintenanceAccordionViewData}
                updateWorkField={updateWorkField}
                sortWorkFieldData={sortWorkFieldData}
                field={field}
                data={getFieldData(field)}
                dataGridTableData={dataGridTableData}
                contributorsDataViewData={contributorsDataViewData}
                workMetadataAccordionViewData={workMetadataAccordionViewData}
                showModal={showModal}
                clearModalSearchResults={clearModalSearchResults}
                lookupValues={lookupValues}
                activeAccordian={activeAccordian}
                openAccordion={openWorkAccordian}
                closeAccordion={closeWorkAccordian}
                addTab={addTab}
                tabs={tabs}
                activeTab={activeTab}
                dataSource={dataSource}
                getAgreementDetails={getAgreementDetails}
                workContributorSetTypes={workContributorSetTypes}
                updateContributorSetType={updateContributorSetType}
                isReadonly={isReadonly}
                getWorkDetails={getWorkDetails}
                getProductDetails={getProductDetails}
                getIPDetails={getIPDetails}
                showWorkSubmissionDetail={showWorkSubmissionDetail}
                hideModal={hideModal}
                work={work}
                currentUser={currentUser}
                manualMergeWork={manualMergeWork}
                contributorDisplaySettings={contributorDisplaySettings}
                agreementMaintenanceGeneralDataView={
                  agreementMaintenanceGeneralDataView
                }
                customFields={customFields}
                enableCustomField={enableCustomField}
                addFileToUpload={addFileToUpload}
                cleanUploadFilesState={cleanUploadFilesState}
                containerDetailsWindowComponentData={
                  containerDetailsWindowComponentData
                }
                destinations={destinations}
                distributions={distributions}
                droppedFiles={droppedFiles}
                isDropZone={isDropZone}
                selectedFileSystem={selectedFileSystem}
                setInDropZone={setInDropZone}
                setProgressUploadFileProccess={setProgressUploadFileProccess}
                sortColumn={sortColumn}
                storagePathItemData={storagePathItemData}
                storagePaths={storagePaths}
                refreshDirectory={refreshDirectory}
                updateSetTypeOnBreak={updateSetTypeOnBreak}
                editableContributorFieldGridDataView={
                  editableContributorFieldGridDataView
                }
                editableFields={editableFields}
                showUpdateFieldsModal={showUpdateFieldsModal}
                contributorGridEditableFields={contributorGridEditableFields}
                setEditableFields={setEditableFields}
                updateContributorFields={updateContributorFields}
                agreementShareDisplayOptions={agreementShareDisplayOptions}
                shareDecimalsToDisplay={shareDecimalsToDisplay}
                resetMessageBanner={resetMessageBanner}
                updateComponentFieldsItems={updateComponentFieldsItems}
                addNewAccount={addNewAccount}
                expandedWorkResults={expandedWorkResults}
                expandMatchingWork={expandMatchingWork}
                readonlyFlagsField={readonlyFlagsField}
                readonlyIndicators={readonlyIndicators}
                saveResultData={saveResultData}
                incrementTempID={incrementTempID}
                commentCategoryFlag={commentCategoryFlag}
                saveAdditionalWorkNumbers={saveAdditionalWorkNumbers}
                getCurrentPageDetails={getCurrentPageDetails}
              />
            </div>
          ) : null
        ) : (
          <div key={index}>
            <WorkMaintenanceAccordion
              workSubmissionDetails={workSubmissionDetails}
              viewData={workMaintenanceAccordionViewData}
              updateWorkField={updateWorkField}
              sortWorkFieldData={sortWorkFieldData}
              field={field}
              data={getFieldData(field)}
              dataGridTableData={dataGridTableData}
              contributorsDataViewData={contributorsDataViewData}
              workMetadataAccordionViewData={workMetadataAccordionViewData}
              showModal={showModal}
              clearModalSearchResults={clearModalSearchResults}
              lookupValues={lookupValues}
              activeAccordian={activeAccordian}
              openAccordion={openWorkAccordian}
              closeAccordion={closeWorkAccordian}
              addTab={addTab}
              tabs={tabs}
              activeTab={activeTab}
              dataSource={dataSource}
              getAgreementDetails={getAgreementDetails}
              workContributorSetTypes={workContributorSetTypes}
              updateContributorSetType={updateContributorSetType}
              isReadonly={isReadonly}
              getWorkDetails={getWorkDetails}
              getProductDetails={getProductDetails}
              getIPDetails={getIPDetails}
              showWorkSubmissionDetail={showWorkSubmissionDetail}
              hideModal={hideModal}
              work={work}
              currentUser={currentUser}
              manualMergeWork={manualMergeWork}
              contributorDisplaySettings={contributorDisplaySettings}
              agreementMaintenanceGeneralDataView={
                agreementMaintenanceGeneralDataView
              }
              customFields={customFields}
              enableCustomField={enableCustomField}
              addFileToUpload={addFileToUpload}
              cleanUploadFilesState={cleanUploadFilesState}
              containerDetailsWindowComponentData={
                containerDetailsWindowComponentData
              }
              destinations={destinations}
              distributions={distributions}
              droppedFiles={droppedFiles}
              isDropZone={isDropZone}
              selectedFileSystem={selectedFileSystem}
              setInDropZone={setInDropZone}
              setProgressUploadFileProccess={setProgressUploadFileProccess}
              sortColumn={sortColumn}
              storagePathItemData={storagePathItemData}
              storagePaths={storagePaths}
              refreshDirectory={refreshDirectory}
              updateSetTypeOnBreak={updateSetTypeOnBreak}
              editableContributorFieldGridDataView={
                editableContributorFieldGridDataView
              }
              editableFields={editableFields}
              showUpdateFieldsModal={showUpdateFieldsModal}
              contributorGridEditableFields={contributorGridEditableFields}
              setEditableFields={setEditableFields}
              updateContributorFields={updateContributorFields}
              agreementShareDisplayOptions={agreementShareDisplayOptions}
              shareDecimalsToDisplay={shareDecimalsToDisplay}
              resetMessageBanner={resetMessageBanner}
              updateComponentFieldsItems={updateComponentFieldsItems}
              addNewAccount={addNewAccount}
              expandedWorkResults={expandedWorkResults}
              expandMatchingWork={expandMatchingWork}
              readonlyFlagsField={readonlyFlagsField}
              readonlyIndicators={readonlyIndicators}
              saveResultData={saveResultData}
              incrementTempID={incrementTempID}
              commentCategoryFlag={commentCategoryFlag}
              saveAdditionalWorkNumbers={saveAdditionalWorkNumbers}
              getCurrentPageDetails={getCurrentPageDetails}
            />
          </div>
        )
      );
    }
  };

  return <div>{renderAccordions()}</div>;
};

export default WorkMaintenanceAccordionList;
