import React from "react";
import UsagePoolMaintenanceGeneralDataViewFormik from "./UsagePoolMaintenanceGeneralDataViewFormik";


const UsagePoolMaintenanceGeneralDataWrapperFormik = ({
    usagePoolMaintenanceGeneralDataViewData,
    poolCode,
    description,
    cat1,
    cat2,
    allocationRule,
    distributionCategoryType,
    invoice,
    poolEnabled,
    licenseeID,
    updateUsagePoolField,
    changesMade,
    updateComponentFieldsItems,
    showUpdateFieldsModal,
    editableFieldsDataView,
    dataGridTableEditableFieldsData,
    setEditableFields,
    activeTab,
    tabs,
    showModal,
    hideModal,
    isReadonly,
    lookupValues,
    editableFields,
    payment,
    roles,
    poolDataFormikRef,
    setIsChangeGeneralForm,
    dataGridTableData,
}) => {

    
    const [poolMaintenanceData, setProductMaintenanceData] = React.useState<any | null>(null);
    const [initialLoad, setInitialLoad] = React.useState(true);

    React.useEffect(() => {
      if (initialLoad && usagePoolMaintenanceGeneralDataViewData) {
        const initialState = {
            usagePoolMaintenanceGeneralDataViewData: usagePoolMaintenanceGeneralDataViewData,
            poolCode: poolCode,
            description: description,
            cat1: cat1,
            cat2: cat2,
            allocationRule: allocationRule ,
            distributionCategoryType: distributionCategoryType,
            invoice: invoice,
            poolEnabled: poolEnabled,
            licenseeID: licenseeID,
            updateUsagePoolField: updateUsagePoolField,
            changesMade: changesMade,
            updateComponentFieldsItems: updateComponentFieldsItems,
            showUpdateFieldsModal: showUpdateFieldsModal,
            editableFieldsDataView: editableFieldsDataView,
            dataGridTableEditableFieldsData: dataGridTableEditableFieldsData,
            setEditableFields: setEditableFields,
            activeTab: activeTab,
            tabs: tabs,
            showModal: showModal,
            hideModal: hideModal,
            isReadonly: isReadonly,
            lookupValues: lookupValues,
            editableFields: editableFields,
            payment: payment,
            roles: roles,
            poolDataFormikRef: poolDataFormikRef,
            setIsChangeGeneralForm: setIsChangeGeneralForm,
            dataGridTableData: dataGridTableData,
        }
        setProductMaintenanceData(initialState);

        setInitialLoad(false);
      }
    }, [initialLoad]);

    return poolMaintenanceData && poolMaintenanceData.usagePoolMaintenanceGeneralDataViewData ? <UsagePoolMaintenanceGeneralDataViewFormik
                    usagePoolMaintenanceGeneralDataViewData={poolMaintenanceData.usagePoolMaintenanceGeneralDataViewData	}
                    poolCode={poolMaintenanceData.poolCode}
                    description={poolMaintenanceData.description}
                    cat1={poolMaintenanceData.cat1}
                    cat2={poolMaintenanceData.cat2}
                    allocationRule={poolMaintenanceData.allocationRule}
                    distributionCategoryType={poolMaintenanceData.distributionCategoryType}
                    invoice={poolMaintenanceData.invoice}
                    poolEnabled={poolMaintenanceData.poolEnabled}
                    licenseeID={poolMaintenanceData.licenseeID}
                    updateUsagePoolField={poolMaintenanceData.updateUsagePoolField}
                    changesMade={poolMaintenanceData.changesMade}
                    updateComponentFieldsItems={poolMaintenanceData.updateComponentFieldsItems}
                    showUpdateFieldsModal={poolMaintenanceData.showUpdateFieldsModal}
                    editableFieldsDataView={poolMaintenanceData.editableFieldsDataView}
                    dataGridTableEditableFieldsData={poolMaintenanceData.dataGridTableEditableFieldsData}
                    setEditableFields={poolMaintenanceData.setEditableFields}
                    activeTab={poolMaintenanceData.activeTab}
                    tabs={poolMaintenanceData.tabs}
                    showModal={poolMaintenanceData.showModal}
                    hideModal={poolMaintenanceData.hideModal}
                    isReadonly={poolMaintenanceData.isReadonly}
                    lookupValues={poolMaintenanceData.lookupValues}
                    editableFields={editableFields}
                    payment={poolMaintenanceData.payment}
                    roles={poolMaintenanceData.roles}
                    poolDataFormikRef={poolMaintenanceData.poolDataFormikRef}
                    setIsChangeGeneralForm={poolMaintenanceData.setIsChangeGeneralForm}
                    dataGridTableData={poolMaintenanceData.dataGridTableData}
    /> : <>Loading...</>

}

export default UsagePoolMaintenanceGeneralDataWrapperFormik;