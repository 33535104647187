import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import IAppState from "../types/IAppState";
import {
    getAccountComponentDataFailure,
    getAccountComponentDataRequest,
    getAccountComponentDataSuccess,
    getAppVersionNumberFailure,
    getAppVersionNumberRequest,
    getAppVersionNumberSuccess,
    getCustomerDateConfigurationSuccess,
    getCustomerDefaultTerritoriesSuccess,
    getCustomerSuccess,
    getUserCRMValuesSuccess,
    getUsersFailure,
    getUsersRequest,
    getUsersSuccess,
    loginFailure,
    loginRequest,
    loginSuccess,
    loginTypeFailure,
    loginTypeRequest,
    loginTypeSuccess,
    logoutFailure,
    logoutRequest,
    logoutSuccess,
} from "../reducers/AccountReducer";
import { AccountService } from "../../accounts/services/AccountService";
import { LookupService } from "../../lookup/services/LookupService"
import { ILoginViewModel } from "../../accounts/types/ILoginViewModel";
import { IAccountComponentData } from "../../accounts/types/IAccountComponentData";
import { ComponentFields } from "../../core/services/ComponentService";
import { IAADConfiguration } from "../../accounts/types/IAADConfiguration";
import { MATCHING_ENGINE, MEMBERS_PORTAL } from "../../accounts/Consts";
import { CUSTOMER_KEY, CUSTOMER_GROUP, CUSTOMER_DATE_KEY, CUSTOMER_DEFAULT_TERRITORIES } from "../../home/Consts";
import { SearchRequests } from "../../repertoire/services/SearchRequests";
import { IB2CConfiguration } from "../../accounts/types/IB2CConfiguration";
import { IViewAsAccessRequest } from "../../membersportal/types/IViewAsAccessRequest";

export const getUsersThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getUsersRequest());
        return LookupService.getUsers()
            .then((res: string[]) => {
                dispatch(getUsersSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getUsersFailure(error));
            });
    };
};

export const loginThunk = (viewModel: ILoginViewModel): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(loginRequest());
        return AccountService.login(viewModel)
            .then((res: string) => {
                var userProps = res.split(",");
                sessionStorage.isAuthenticated = true;
                sessionStorage.isSignedIn = true;
                sessionStorage.setItem('username', viewModel.username);
                sessionStorage.setItem('currentGuid', userProps[2])

                dispatch(loginSuccess(userProps[0], userProps[1]));
                if (viewModel.redirect.search) {
                    window.location.href = viewModel.redirect.pathname + viewModel.redirect.search;
                }
            })
            .catch((error: any) => {
                sessionStorage.isAuthenticated = false;
                sessionStorage.isSignedIn = false;
                dispatch(loginFailure(error));
            });
    };
};

export const logoutThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(logoutRequest());
        return AccountService.logout()
            .then(res => {
                sessionStorage.isAuthenticated = false;
                dispatch(logoutSuccess());
            })
            .catch((error: any) => {
                dispatch(logoutFailure(error));
            });
    };
};

export const getAccountComponentDataThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getAccountComponentDataRequest());
        return ComponentFields.getAccountComponentData()
            .then((res: IAccountComponentData) => {
                dispatch(getAccountComponentDataSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getAccountComponentDataFailure(error));
            });
    };
};

export const getAppVersionNumberThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getAppVersionNumberRequest());
        return ComponentFields.getAppVersionNumber()
            .then((res: string) => {
                dispatch(getAppVersionNumberSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getAppVersionNumberFailure(error));
            });
    };
};

export async function getAADLoginConfigurationThunk(): Promise<IAADConfiguration> {
    return await AccountService.getAADLoginConfiguration()
};

export async function getB2CLoginConfigurationThunk(): Promise<IB2CConfiguration> {
    return await AccountService.getB2CLoginConfiguration()
};

export const loginTypeThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(loginTypeRequest());
        return AccountService.loginType()
            .then((res: string) => {
                dispatch(loginTypeSuccess(res));
            })
            .catch((error: any) => {
                dispatch(loginTypeFailure(error));
            });
    };
};

export async function getPortalType(): Promise<string> {
    let result = await ComponentFields.getPortalType()
    if (result === MEMBERS_PORTAL)
        return MEMBERS_PORTAL;
    else
        return MATCHING_ENGINE;
};

export const getCustomerThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return SearchRequests.getConfigurationParameter(CUSTOMER_KEY, CUSTOMER_GROUP)
            .then((res: string) => {
                dispatch(getCustomerSuccess(res));
            })
    }
}

export const getCustomerDefaultTerritoriesThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return SearchRequests.getConfigurationParameter(CUSTOMER_DEFAULT_TERRITORIES, CUSTOMER_GROUP)
            .then((res: any) => {
                dispatch(getCustomerDefaultTerritoriesSuccess(res));
            })
    }
}


export const getCustomerDateConfigurationThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return SearchRequests.getConfigurationParameter(CUSTOMER_DATE_KEY, CUSTOMER_GROUP)
            .then((res: string) => {
                dispatch(getCustomerDateConfigurationSuccess(res));
            })
    }
}

export const getUserCRMValuesThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return AccountService.getUserCRMValues()
            .then((res: string) => {
                dispatch(getUserCRMValuesSuccess(res));
            })
    }
}

export const getUserCRMIpNumberByGuidThunk = (
    guid: string
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {

        return AccountService.getUserCRMIpNumber(guid)
            .then((res: string) => {
                dispatch(getUserCRMValuesSuccess(res));
        })
    }
}
