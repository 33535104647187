import IconTextButton from "../../../components/iconTextButton/IconTextButton";
import { CSVLink } from "react-csv"
import { IUsageSearchResultRowType } from "../../../types/usageTypes/IUsageGroupsSearchResult";
import { USAGERESULTTABLE_VIEW } from "../../../Consts";

export interface IUsageSearchToolBar {

    data?: IUsageSearchResultRowType[];
    getIconForExpandAll?: () => string;
    onClickExpandAll?: (rows: IUsageSearchResultRowType[]) => void;
    onClickSave?: () => void;
    showFieldsModal?: (componentFieldName: string) => void;
    hasChanges?: number[]
}

const UsageSearchToolbar = (props: IUsageSearchToolBar) => {

    const { data, getIconForExpandAll, onClickExpandAll, onClickSave, showFieldsModal, hasChanges } = props;

    return (

        <div
            className="toolbarWrapper"
            id={"toolbar"}
        >
            <div>
                <CSVLink
                    filename={"UsageSearchResults.csv"}
                    data={data.map(({ matchesExpanded, ...keepAttrs }) => keepAttrs)}
                    className="btn"
                >
                    <IconTextButton
                        text="Export"
                        icon="icon ms-Icon ms-Icon--Download"
                    />
                </CSVLink>
            </div>

            <div style={{paddingLeft:"6px", paddingRight:"6px"}}>
                <IconTextButton text="Show All Matches" icon={"icon ms-Icon ".concat(getIconForExpandAll())}
                    onClick={() => onClickExpandAll(data)} />
            </div>

            <div style={{ paddingLeft: "6px" }}>
                <IconTextButton text="&nbsp;Configure Data Fields" icon={"ms-Icon ms-Icon--Settings"}
                    onClick={() => showFieldsModal(USAGERESULTTABLE_VIEW)} />
            </div>

            <div className="saveButton" >
                    <div className="saveButtonContainer btn-group" >
                        <button
                            title={"Save"}
                            aria-disabled={true}
                            className={(hasChanges.length > 0) ? "saveButton" : "disabledSaveButton"}
                            onClick={() => onClickSave()}
                            disabled={!(hasChanges.length > 0)}
                        >
                            <div className="saveButtonText">Save Changes</div>
                        </button>
                    </div>
            </div>
        </div>
    );
}

export default UsageSearchToolbar