import { connect } from "react-redux";
import IAppState from "../../redux/types/IAppState";
import { Dispatch } from "redux";
import { HomePage } from "./HomePage";
import {
    getEnabledModulesThunk,
    getHomeComponentDataThunk,
    searchJobsThunk,
    triggerManualJob,
    getCWRAckHWM,
    getAutomaticAgreementGHWM,
    getManualJobDataSources,
    searchContributorsThunk,
    getCWRGHighWaterMarkThunk
} from "../../redux/thunks/HomeThunks";
import {
    getUsersThunk,
    getCustomerThunk,
    getUserCRMValuesThunk,
    getCustomerDateConfigurationThunk,
    getUserCRMIpNumberByGuidThunk
} from "../../redux/thunks/AccountThunks";
import {
    getLookupEntitiesThunk
} from "../../redux/thunks/LookupThunks";
import { IJobSearchState } from "../types/IJobSearchState";
import { resetPaginationHomeModal, setCurrentPage, updateManualJobParams, updatePaginationHomeModal } from "../../redux/reducers/HomeReducer";
import { getPaymentRunIDsThunk, getShortCutKeysThunk, getUserPreferences } from "../../redux/thunks/RepertoireThunks";
import { showModal } from "../../redux/reducers/ModalReducer";
import { clearModalSearchResultsThunk } from "../../redux/thunks/ModalThunks";
import { ITriggerManualJob } from "../../redux/types/ITriggerManualJob";
import { IContributorSearchQuery } from "../../repertoire/types/IContributorSearchQuery";
import { getMyApplicationsThunk, getMyCasesThunk, getCRMIntegratedEnabledThunk } from "../../redux/thunks/MembersPortalThunks";
import { ICwrgParameters } from "../../redux/types/ICwrgParameters";

export default connect(
    (state: IAppState) => ({
        lookups: state.lookupEntities,
        componentData: state.home.componentData,
        users: state.account.users,
        jobSearchState: state.home.jobSearchState,
        jobSearchResults: state.home.jobSearchResults,
        currentPage: state.home.currentPage,
        enableModules: state.home.enableModules,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        resultsPerPage: state.home.resultsPerPage,
        displayModal: state.modal.displayModal,
        modalContent: state.modal.modalContent,
        modalProps: state.modal.modalProps,
        modalComponentInstance: state.modal.modalComponentInstance,
        modalTitle: state.modal.title,
        displayModalCloseButton: state.modal.displayModalCloseButton,
        manualJobParameters: state.home.manualJobParameters,
        manualJobType: state.home.manualJobType,
        manualJobDataSources: state.home.manualJobDataSources,
        cwrAckHWM: state.home.cwrAckHWM,
        atomaticAgreementGHWM: state.home.automaticAgreementGHWM,
        paymentRunIDs: state.repertoire.paymentRunIDs,
        shortCutKeys: state.repertoire.shortCutKeys,
        searchViewData: state.home.componentData.SearchView,
        dataSource: state.home.dataSource,
        searchResultsTableData: state.home.componentData.SearchResultsTable,
        contributorsSearchResults: state.home.contributorsSearchResult,
        searchSuccessful: state.home.searchSuccessful,
        portalType: state.account.portalType,
        username: state.account.currentUser,
        currentUserNameNumber: state.account.currentUserNameNumber,
        currentViewAsUserName: state.account.currentViewAsUserName,
        myApplications: state.membersPortal.myApplications,
        myCases: state.membersPortal.myCases,
        membersPortalSearchResultsTableData: state.membersPortal.componentData.SearchResultsTable,
        isCRMIntegrationEnabled: state.membersPortal.isCRMIntegrationEnabled,
        powerPortalURL: state.membersPortal.powerPortalURL,
        customerDatePreference: state.account.customerDate
    }),
    (dispatch: Dispatch) => ({
        getHomeFields: () => dispatch<any>(getHomeComponentDataThunk()),
        getLookupEntities: () => dispatch<any>(getLookupEntitiesThunk()),
        getUsers: () => dispatch<any>(getUsersThunk()),
        searchJobs: (searchQuery: IJobSearchState) => dispatch<any>(searchJobsThunk(searchQuery)),
        setCurrentPage: (link: string) => dispatch(setCurrentPage(link)),
        getEnabledModules: () => dispatch<any>(getEnabledModulesThunk()),
        getUserPreferences: () => dispatch<any>(getUserPreferences()),
        getShortCutKeys: () => dispatch<any>(getShortCutKeysThunk()),
        hideModal: () => dispatch<any>(clearModalSearchResultsThunk()),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        triggerManualJob: (manualJob: ITriggerManualJob) => dispatch<any>(triggerManualJob(manualJob)),
        getCWRAckHWM: () => dispatch<any>(getCWRAckHWM()),
        getAutomaticAgreementGHWM: () => dispatch<any>(getAutomaticAgreementGHWM()),
        fetchManualJobDataSources: () => dispatch<any>(getManualJobDataSources()),
        getPaymentRunIDs: () => dispatch<any>(getPaymentRunIDsThunk()),
        searchContributors: (query: IContributorSearchQuery) =>
            dispatch<any>(searchContributorsThunk(query)),
        updateManualJobParams: (params: any) => dispatch(updateManualJobParams(params)),
        resetPagination: (repertoireSection: string) => dispatch(resetPaginationHomeModal(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePaginationHomeModal(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        getCustomer: () => dispatch<any>(getCustomerThunk()),
        getCustomerDateConfiguration: () => dispatch<any>(getCustomerDateConfigurationThunk()),
        getUserCRMValuesThunk: () => dispatch<any>(getUserCRMValuesThunk()),
        getUserCRMIPNumber: (guid: string) => dispatch<any>(getUserCRMIpNumberByGuidThunk(guid)),
        getMyApplications: () => dispatch<any>(getMyApplicationsThunk()),
        getMyCases: () => dispatch<any>(getMyCasesThunk()),
        getCRMIntegratedEnabled: () => dispatch<any>(getCRMIntegratedEnabledThunk()),
    })
)(HomePage);