import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import {
    hideModal,
    showModal,
    showUpdateFieldsModal
} from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { setEditableFields, updateUsagePoolField } from "../../../../redux/reducers/RepertoireReducer";
import SizedDateDataInput from "../../../components/dateDataInput/DateDataInput";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IFieldSettingViewModalProps } from "../../../components/modalViews/fieldSettingView/FieldSettingView";
import { getLookupDefault, getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { ALL_ROLES, ARTIST_FIRST_NAME_STATE_KEY, ARTIST_LAST_NAME_STATE_KEY, ARTIST_MAINTENANCE_GENERAL_DATA_VIEW, ARTIST_TYPE_STATE_KEY, COMPONENT_INSTANCE_ARTISTS, COMPONENT_INSTANCE_POOLS, CONFIGURE_POOLS_ROLE, EDITABLE_FIELDS, GENERAL_DATA_EDITABLE_FIELDS, POOL_ALLOCATION_RULE_STATE_KEY, POOL_CAT_1_STATE_KEY, POOL_CAT_2_STATE_KEY, POOL_DESCRIPTION_STATE_KEY, POOL_DISTRIBUTION_CATEGORY_TYPE_STATE_KEY, POOL_ENABLED_STATE_KEY, POOL_INVOICE_STATE_KEY, POOL_LICENSEE_ID_STATE_KEY, POOL_PAYMENT_STATE_KEY, POOL_POOL_CODE_STATE_KEY, TITLE_EDITABLE_FIELDS_VIEW, USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW } from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IArtistStateKeys } from "../../../../redux/types/IArtistState";
import { ALLOCATION_RULE_LOOKUP, DISTRIBUTION_CATEGORY_TYPE_LOOKUP, WORK_PERFORMER_LOOKUP } from "../../../../lookup/Consts";
import { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";
import { IUsagePoolStateKeys } from "../../../../redux/types/IUsagePoolState";

export interface IUsagePoolMaintenanceGeneralDataViewProps {
    usagePoolMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    poolCode: string;
    description: string;
    cat1: string;
    cat2: string;
    allocationRule: string;
    distributionCategoryType: string;
    invoice: boolean;
    poolEnabled: boolean;
    licenseeID: string;
    updateUsagePoolField: typeof updateUsagePoolField;
    changesMade: boolean;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    dataGridTableEditableFieldsData?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    editableFields: IRepertoireField[];
    payment: boolean;
    roles: string[];
}

interface IUsagePoolMaintenanceGeneralDataViewState {
    loaded: boolean;
}

export default class UsagePoolMaintenanceGeneralDataView extends React.PureComponent<
    IUsagePoolMaintenanceGeneralDataViewProps,
    IUsagePoolMaintenanceGeneralDataViewState
> {
    viewData;
    constructor(props: IUsagePoolMaintenanceGeneralDataViewProps) {
        super(props);
        this.state = {
            loaded: false
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            usagePoolMaintenanceGeneralDataViewData: { fields }
        } = this.props
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab } = this.props;
        updateComponentFieldsItems(editableFields["default"], USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_ARTISTS, USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW, tabs, activeTab);
    }

    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableEditableFieldsData,
            setEditableFields,
        } = this.props;

        setEditableFields(USAGE_POOL_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_POOLS)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableEditableFieldsData,
            onClickSave: this.onSaveEditableFields.bind(this)
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    render() {
        const {
            usagePoolMaintenanceGeneralDataViewData,
            poolCode,
            description,
            cat1,
            cat2,
            allocationRule,
            distributionCategoryType,
            invoice,
            poolEnabled,
            licenseeID,
            changesMade,
            updateUsagePoolField,
            isReadonly,
            lookupValues,
            payment
        } = this.props;

        const { loaded } = this.state;

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            updateUsagePoolField(value, name as IUsagePoolStateKeys);
        }

        const getConfigureRoleDisabled = (): boolean => {
            const { roles } = this.props;
            if (roles && (roles.includes(CONFIGURE_POOLS_ROLE) || roles.includes(ALL_ROLES))) {
                return true;
            }
        }

        if (loaded) {
            return (
                <div className="">
                    <div className="row">
                        <div className="col-md-12 editableSectionHeading">
                            <h1 className="borderBootom">General</h1>
                            <button className="editBtn" title="Configure data fields" onClick={this.showFieldsModal.bind(this)} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                    </div>
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Pool Information</h1>
                            </div>
                        </div>
                        <div className="row">
                            <SizedTextDataInput
                                label={this.viewData[POOL_POOL_CODE_STATE_KEY]}
                                fieldName={POOL_POOL_CODE_STATE_KEY}
                                changeData={changeData}
                                value={poolCode}
                                isHidden={!this.viewData[POOL_POOL_CODE_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedTextDataInput
                                label={this.viewData[POOL_DESCRIPTION_STATE_KEY]}
                                fieldName={POOL_DESCRIPTION_STATE_KEY}
                                changeData={changeData}
                                value={description}
                                isHidden={!this.viewData[POOL_DESCRIPTION_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedCheckboxDataInput
                                label={this.viewData[POOL_INVOICE_STATE_KEY]}
                                fieldName={POOL_INVOICE_STATE_KEY}
                                value={invoice}
                                changeData={changeData}
                                readonly={isReadonly}
                                isHidden={!this.viewData[POOL_INVOICE_STATE_KEY]}
                                inline={true}
                            />

                        </div>
                        <div className="row">
                            <SizedTextDataInput
                                label={this.viewData[POOL_CAT_1_STATE_KEY]}
                                fieldName={POOL_CAT_1_STATE_KEY}
                                changeData={changeData}
                                value={cat1}
                                isHidden={!this.viewData[POOL_CAT_1_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedTextDataInput
                                label={this.viewData[POOL_CAT_2_STATE_KEY]}
                                fieldName={POOL_CAT_2_STATE_KEY}
                                changeData={changeData}
                                value={cat2}
                                isHidden={!this.viewData[POOL_CAT_2_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedCheckboxDataInput
                                label={this.viewData[POOL_PAYMENT_STATE_KEY]}
                                fieldName={POOL_PAYMENT_STATE_KEY}
                                value={payment}
                                changeData={changeData}
                                readonly={isReadonly}
                                isHidden={!this.viewData[POOL_PAYMENT_STATE_KEY]}
                                inline={true}
                            />
                        </div>
                        <div className="row">
                            <SizedDropdownDataInput
                                label={this.viewData[POOL_ALLOCATION_RULE_STATE_KEY]}
                                fieldName={POOL_ALLOCATION_RULE_STATE_KEY}
                                value={allocationRule ? allocationRule : getLookupDefault(ALLOCATION_RULE_LOOKUP, lookupValues)}
                                changeData={changeData}
                                options={getLookupValues(ALLOCATION_RULE_LOOKUP, lookupValues)}
                                allowNull={false}
                                readOnly={isReadonly}
                                isHidden={!this.viewData[POOL_ALLOCATION_RULE_STATE_KEY]} />
                            <SizedDropdownDataInput
                                label={this.viewData[POOL_DISTRIBUTION_CATEGORY_TYPE_STATE_KEY]}
                                fieldName={POOL_DISTRIBUTION_CATEGORY_TYPE_STATE_KEY}
                                value={distributionCategoryType ? distributionCategoryType : getLookupDefault(DISTRIBUTION_CATEGORY_TYPE_LOOKUP, lookupValues)}
                                changeData={changeData}
                                options={getLookupValues(DISTRIBUTION_CATEGORY_TYPE_LOOKUP, lookupValues)}
                                allowNull={false}
                                readOnly={isReadonly}
                                isHidden={!this.viewData[POOL_DISTRIBUTION_CATEGORY_TYPE_STATE_KEY]} />
                            <SizedTextDataInput
                                label={this.viewData[POOL_LICENSEE_ID_STATE_KEY]}
                                fieldName={POOL_LICENSEE_ID_STATE_KEY}
                                changeData={changeData}
                                value={licenseeID}
                                isHidden={!this.viewData[POOL_LICENSEE_ID_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        return <div />;
    }
}