import { connect } from "react-redux";
import WorksPage from "./WorksPage";
import IAppState from "../../redux/types/IAppState";
import { setDataSource, addTab, createNewWork, expandWorkResult, expandAllResults, setEditableFields, resetPagination, updatePagination, updateUserPreference, sortSearchResults, addArtistToWork, updateEditableFields } from "../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { Dispatch } from "redux";
import { hideModal, showModal, showUpdateFieldsModal } from "../../redux/reducers/ModalReducer";
import {
    getWorkDetailsThunk,
    getWorkDetailsWorkflowThunk,
    searchWorksThunk,
    searchArtistsThunk,
    searchContributorsThunk,
    updateComponentFieldItemsThunk,
    updateUserPreferencesThunk,
    getOtherIndicatorsWorkFlagTypesThunk,
    getReadonlyIndicatorsWorkFlagTypesThunk,
    getShowCommentSubjectAsDropdownConfigurationParameterThunk,
    AddtoPackageThunk
} from "../../redux/thunks/RepertoireThunks";
import { IWorksSearchQuery } from "../types/IWorksSearchQuery";
import { IContributorSearchQuery } from "../types/IContributorSearchQuery";
import { IArtistSearchQuery } from "../types/IArtistSearchQuery";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../redux/types/FormatFields";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IShowFieldSettingViewModalProps } from "../components/repertoireModal/RepertoireModal";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import { IRepertoireField } from "../types/IRepertoireField";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { IArtist } from "../types/IArtist";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { IWorkflowSearchResult } from "../types/IWorkflowSearchResult";

export default connect(
    (state: IAppState) => ({
        worksPageData: state.repertoire.componentData.WorksPage,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        dataSource: state.repertoire.dataSource,
        worksSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].worksSearchResult,
        artistsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].artistsSearchResult,
        lookupValues: state.lookupEntities,
        otherIndicatorsWorkFlagTypes: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].otherIndicatorWorkFlagTypes,
        readonlyIndicatorsWorkFlagTypes: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].readonlyIndicatorWorkFlagTypes,
        contributorsSearchResult: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].contributorsSearchResult,
        searchSuccessful: state.repertoire.searchSuccessful,
        workMaintenanceGeneralDataViewData: state.repertoire.componentData.WorkMaintenanceGeneralDataView,
        expandedWorkResults: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].expandedWorkResults,
        expandAll: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].expandAll,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        editableFields: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].editableFields,
        activeTab: state.repertoire.activeTab,
        tabs: state.repertoire.tabs,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'works')[0].indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'works')[0].indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'works')[0].resultsPerSection,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        contributorsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].contributorsSearchResult,
        currentPage: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].currentPage ? state.repertoire.tabs[state.repertoire.activeTab].currentPage : undefined,
        roles: state.account.roles,
        currentWorkflowIndex: state.repertoire.currentWorkflowIndex,
        workflowSessionItems: state.repertoire.workflowSessionItems,
        username: state.account.currentUser
    }),
    (dispatch: Dispatch) => ({
        setDataSource: (dataSource: string) => dispatch(setDataSource(dataSource)),
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        getWorkDetails: (dataSource: string, workID: number,
            lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], actionDataList: IDataActionToolbar[], readonlyIndicatorsWorkFlagTypes: string[], workMaintenanceViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], openEntityForWorflowSession?: boolean, workGroup?: string) =>
            dispatch<any>(getWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, actionDataList, workMaintenanceViewData, formats, readonlyIndicatorsWorkFlagTypes, openEntityForWorflowSession, workGroup)),
        getWorkDetailsWorkflow: (
            dataSource: string,
            workID: number,
            lookups: ILookupDictionary,
            otherIndicatorsWorkFlagTypes: string[],
            dataActions: IDataActionToolbar[],
            workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem,
            formats: FormatFields[],
            readonlyIndicatorsWorkFlagTypes: string[],
            openEntityForWorkflowSession?: boolean,
            activeTab?: number, currentWorkflowIndex?: number,
            workflows?: IWorkflowSearchResult[],
            workGroup?: string) => 
            dispatch<any>(getWorkDetailsWorkflowThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes, openEntityForWorkflowSession, activeTab, currentWorkflowIndex, workflows, workGroup)),
        searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) =>
            dispatch<any>(searchWorksThunk(query, false)),
        searchArtists: (query: IArtistSearchQuery, dataSource: string) =>
            dispatch<any>(searchArtistsThunk(query, dataSource)),
        searchContributors: (query: IContributorSearchQuery) =>
            dispatch<any>(searchContributorsThunk(query)),
        addNewWork: (lookups: ILookupDictionary, actionList: IDataActionToolbar[], isNew?: boolean, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[], workGroupId?: string, workGroup?: string) =>
            dispatch<any>(createNewWork(lookups, actionList, isNew, workMaintenanceGeneralDataView, formats, workGroupId, workGroup)),
        expandWorkResult: (workResult: number) => dispatch<any>(expandWorkResult(workResult)),
        expandAllResults: () => dispatch<any>(expandAllResults()),
        getOtherIndicatorsWorkFlagTypes: () => dispatch<any>(getOtherIndicatorsWorkFlagTypesThunk()),
        getReadonlyIndicatorsWorkFlagTypes: () => dispatch<any>(getReadonlyIndicatorsWorkFlagTypesThunk()),
        setEditableFields: (componentFieldName: string, componentInstance: string) => dispatch(setEditableFields(componentFieldName, componentInstance)),
        showUpdateFieldsModal: (tittle: string, props: IShowFieldSettingViewModalProps) => dispatch<any>(showUpdateFieldsModal(tittle, props)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        hideModal: () => dispatch(hideModal()),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        addArtistToWork: (artist: IArtist, id: number) => dispatch<any>(addArtistToWork(artist, id)),
        updateEditableField: (value: any[], key: string) => dispatch<any>(updateEditableFields(value, key)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        getShowCommentSubjectAsDropdownConfigurationParameter: (group: string) => dispatch<any>(getShowCommentSubjectAsDropdownConfigurationParameterThunk(group)),
        addToPackages:( userName:string,entityType:string,entityID:number)=>dispatch<any>(AddtoPackageThunk(userName,entityType,entityID)),
    })
)(WorksPage);
