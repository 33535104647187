import { connect } from "react-redux";
import { Dispatch } from "redux";
import IAppState from "../../redux/types/IAppState";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IShoppingCartSearchQuery } from "../types/IShoppingCartSearchQuery";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { addTab, createNewLicense, resetPagination, sortSearchResults, updatePagination } from "../../redux/reducers/RepertoireReducer";
import { getLicenseDetailsThunk, searchLicensesThunk, updateUserPreferencesThunk } from "../../redux/thunks/RepertoireThunks";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import LicensesPage from "./LicensesPage";
import { ILicenseInputItem } from "../../redux/types/ILicenseInputItem";

export default connect(
    (state: IAppState) => ({
        licensesPageData: state.repertoire.componentData.LicensesPage,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        licensesSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].licenseSearchResult,
        searchSuccessful: state.repertoire.searchSuccessful,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'licenses')[0]?.indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'licenses')[0]?.indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'licenses')[0]?.resultsPerSection,
        currentPage: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].currentPage ? state.repertoire.tabs[state.repertoire.activeTab].currentPage : undefined,
        licenseMaintenanceGeneralDataViewData: state.repertoire.componentData.LicenseMaintenanceGeneralDataView,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        roles: state.account.roles,
    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        searchLicenses: (query: IShoppingCartSearchQuery) =>
            dispatch<any>(searchLicensesThunk(query)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        getLicenseDetails: (licenseID: number, lookupValues: ILookupDictionary,licenseRequestWorkParameterFields: ILicenseInputItem[]) => dispatch<any>(getLicenseDetailsThunk(licenseID, lookupValues,licenseRequestWorkParameterFields)),
        addNewLicense: (lookups: ILookupDictionary,licenseRequestWorkParameterFields: ILicenseInputItem[],isNew?: boolean, licenseMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) =>
            dispatch(createNewLicense(lookups,licenseRequestWorkParameterFields, isNew, licenseMaintenanceGeneralDataViewData)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles))
    })
)(LicensesPage);