import * as React from 'react';
import { Link } from "react-router-dom";
import { NavLink } from 'reactstrap';
import { If } from '../../../core/components/If';
import { IComponentDataItem } from '../../../core/types/IComponentDataItem';
import { IComponentField } from '../../../core/types/IComponentField';
import { ILookupInstance } from '../../../lookup/types/ILookup';
import {
    addFileToUpload, CleanUploadFilesState, editingChanged,
    fileMetadataChanged, matchNow, setInDropZone, setProgressUploadFileProccess, sortColumn, toggleSidebarCollapsed, validationMessageChanged
} from '../../../redux/reducers/DataIngestionReducer';
import { hideModal, showModal, showYesNoPrompt } from '../../../redux/reducers/ModalReducer';
import { addDroppedFile, cleanDroppedFilesRepertoireState, setProgressUploadFileRepertoireProccess } from '../../../redux/reducers/RepertoireReducer';
import { IAttachedFile } from '../../../redux/types/IAttachedFile';
import IDroppedFiles from '../../../redux/types/IDroppedFiles';
import IFile from '../../../redux/types/IFiles';
import { IJobState } from '../../../redux/types/IJobState';
import { IPaginationDetail } from '../../../redux/types/IPaginationDetail';
import PaginationView from '../../../repertoire/components/paginationView/PaginationView';
import TableHeaderSection from '../../../repertoire/components/tableHeaderSection/TableHeaderSection';
import { AGREEMENTS_KEY, ATTACHMENT_TYPE_NOT_REQUIRED, EVENT_HANDLER_THROTTLE_TIME, PRODUCTS_PAGE_VIEW, REPERTOIRE, WORKS_PAGE_VIEW } from '../../../repertoire/Consts';
import { DataSource } from '../../../repertoire/types/DataSource';
import { ITreeData } from '../../../repertoire/types/ITreeData';
import { IDistribution } from "../../../repertoire/types/usageTypes/IDistribution";
import { IUsagePool } from '../../../repertoire/types/usageTypes/IUsagePool';
import {
    ALL_ROLES,
    ATTACHMENT_TYPE_SORTING_KEY,
    BATCH_ID_SORTING_KEY, CONTAINER_DETAILS_WINDOW_KEY, CONTRIBUTE_INGESTION_REPERTOIRE_ROLE, CONTRIBUTE_INGESTION_USAGE_ROLE, CONTRIBUTE_PAYMENT_ROLE, DESTINATION_SORTING_KEY, DISTRIBUTION_CODE_SORTING_KEY, FILE_NAME_SORTING_KEY, FILE_SIZE_SORTING_KEY, INGESTION_PAGE_JOBS, INPUT_FORMAT_SORTING_KEY, LAST_MODIFIED_SORTING_KEY, OUTPUT_FORMAT_SORTING_KEY, POOL_CODE_SORTING_KEY, RIGHTHOLDER_SORTING_KEY, SHOPPINGCART_SORTING_KEY, SOURCE_SORTING_KEY,
    TRACKING_INFORMATION_SORTING_KEY,
} from '../../Consts';
import { FileSystemHelper } from '../../services/FileSystemHelper';
import { StorageService } from '../../services/StorageService';
import {
    FILE_METADATA_BATCH_ID, FILE_METADATA_DESTINATION,
    FILE_METADATA_DISTRIBUTION, FILE_METADATA_FILE_SIZE, FILE_METADATA_FORMAT,
    FILE_METADATA_OUTPUT_FORMAT, FILE_METADATA_POOL_CODE, FILE_METADATA_RIGHTHOLDER, FILE_METADATA_SHOPPINGCART, FILE_METADATA_SOURCE
} from '../../types/IFileMetadata';
import { IFileSystem } from '../../types/IFileSystem';
import { IStoragePathItemData } from '../../types/IStoragePathItemData';
import { SaveState } from '../../types/SaveState';
import { StoragePathItem } from '../storagePathItem/StoragePathItem';
import {
    BATCH_ID_TABLE_HEADER_FIELD, CANCELLED_MESSAGE_FIELD,
    CANCEL_BUTTON_TEXT_FIELD,
    DESTINATION_TABLE_HEADER_FIELD, DISABLE_DRAG_AND_DROP,
    DISABLE_DRAG_AND_DROP_AGREEMENTS, DISABLE_DRAG_AND_DROP_PRODUCTS, DISTRIBUTION_CODE_TABLE_HEADER_FIELD, DRAG_AND_DROP_INSTRUCTION, ERROR_OBTAINING_SAS, ERROR_UPLOADING_FILE_SAS, FILENAME_TABLE_HEADER_FIELD, FILE_SIZE_TABLE_HEADER_FIELD, INPUT_FORMAT_TABLE_HEADER_FIELD, LAST_MODIFIED_DATE_TABLE_HEADER_FIELD, OBTAINED_SAS, OUTPUT_FORMAT_TABLE_HEADER_FIELD, PATH_TO_DROP_FILE, POOL_CODE_TABLE_HEADER_FIELD, PREVENT_REFRESH_FIELD, REMOVE, REQUEST_SAS, SAVE_BUTTON_TEXT_FIELD,
    SAVE_FAILED_MESSAGE_FIELD,
    SAVE_SUCCESSFUL_MESSAGE_FIELD, SOURCE_TABLE_HEADER_FIELD, UPLOADING_FILE_SAS, UPLOAD_COMPLETED, VALIDATION_ERROR_MESSAGE, RIGHTHOLDER_TABLE_HEADER_FIELD, SHOPPINGCART_TABLE_HEADER_FIELD, ATTACHMENT_TYPE_TABLE_HEADER_FIELD,
    INGESTION_PROCESS_STARTED_FIELD,
    TRACKING_INFORMATION_TABLE_HEADER_FIELD
} from './ContainerDetailsWindowFields';
import { SEARCH_VIEW } from "../../../repertoire/Consts";
import { clearModalSearchResults } from "../../../redux/reducers/RepertoireReducer";
import { DataIngestionToolbar } from '../dataIngestionToolbar/DataIngestionToolbar';
import throttle from 'lodash.throttle';
import {
    addDroppedFile as mpAddDroppedFile,
    cleanDroppedFilesRepertoireState as mpCleanDroppedFilesRepertoireState,
    setProgressUploadFileRepertoireProccess as mpSetProgressUploadFileRepertoireProccess
} from "../../../redux/reducers/MembersPortalReducer";
import { ILookupDictionary } from '../../../lookup/types/ILookupDictionary';
import { ITabReduxItem } from '../../../redux/types/ITabReduxItem';
import { MessageBanner, MessageType } from '../../../repertoire/components/messageBanner/MessageBanner';
import { isNullOrUndefined } from '../../../util/tools';

export interface IContainerDetailsWindowProps {
    containerDetailsWindowComponentData: IComponentDataItem;
    storagePathItemData: IComponentDataItem;
    pageContentIsActive: boolean;
    storagePaths: IStoragePathItemData[];
    fileMetadataChanged?: typeof fileMetadataChanged;
    saveChanges?: (fileSystem: IFileSystem, storagePaths: IStoragePathItemData[], sources: ITreeData[]) => void;
    cancelChanges?: (fileSystem: IFileSystem, sources: ITreeData[]) => void;
    openDirectory?: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    refreshDirectory?: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    currentDirectory?: string;
    toggleSidebarCollapsed?: typeof toggleSidebarCollapsed;
    validationMessageChanged?: typeof validationMessageChanged;
    saveState?: SaveState;
    distributions?: IDistribution[];
    destinations?: ILookupInstance[];
    lookupValues?: ILookupDictionary;
    editingChanged?: typeof editingChanged;
    selectedFileSystem?: IFileSystem;
    jobs?: IJobState[];
    matchNow?: typeof matchNow;
    matchNowSubmit?: (fileSystem: IFileSystem, storagePaths: IStoragePathItemData[], sources: ITreeData[]) => void;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    sortColumn: typeof sortColumn;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    isRepertoireModule?: boolean;
    addRepertoireAttachmentToState?: (attachedFile: IAttachedFile) => void;
    uploadAttachment?: (fileName: string, id: number, dataSource: DataSource) => Promise<any>;
    removeAttachmentIfExists?: (attachedFile: IAttachedFile, source: DataSource) => void;
    attachmentsDropped?: IDroppedFiles;
    addDroppedFileRepertoire?: typeof addDroppedFile | typeof mpAddDroppedFile;
    setProgressUploadFileRepertoire?: typeof setProgressUploadFileRepertoireProccess | typeof mpSetProgressUploadFileRepertoireProccess;
    cleanDroppedFilesRepertoire?: typeof cleanDroppedFilesRepertoireState | typeof mpCleanDroppedFilesRepertoireState;
    agreementAttachmentsDropped?: IDroppedFiles;
    entityID?: number;
    entitySource?: DataSource;
    component?: string;
    removeAttachmentMaintenanceScreen?: (fullName: string, workId: number, dataSource: DataSource, guid: string, index: number) => void;
    deleteBlobFile?: (fullName: string, name: string) => Promise<boolean>;
    displayRemove?: boolean;
    paginationDetails?: IPaginationDetail[];
    updatePagination?: (resultsPerPage: number, currentPage: number, section: string) => void;
    sources?: ITreeData[];
    getMatchingSources?: () => void;
    pools?: IUsagePool[];
    undoUsageIngestion?: (fullFilePath: string) => void;
    showYesNoPrompt?: typeof showYesNoPrompt;
    showModal?: typeof showModal;
    hideModal?: typeof hideModal;
    clearModalSearchResults?: typeof clearModalSearchResults;
    dataIngestionToolbarData?: IComponentDataItem;
    roles?: string[];
    activeTab?: number;
    tabs?: ITabReduxItem[];
    isWorkAttachmentTypeNotRequired?: boolean;
    isWorkAttachmentTypeOptional?: boolean;
    isProductAttachmentTypeNotRequired?: boolean;
    isProductAttachmentTypeOptional?: boolean;
    currentUserNameNumber?: string;
    agreementAttachmentTypeRequired?: string;
    resetMessageBanner?: () => void;
    updatedScroll?: number;
}

interface IContainerDetailsWindowState {
    tableContents: any;
    activeSortHeaderSection: string;
    loaded: boolean;
    resultsPerPage?: number;
    currentPage?: number;
    paginationDetails?: IPaginationDetail[];
    downloadSchemaLink?: string;
}

export class ContainerDetailsWindow extends React.Component<
    IContainerDetailsWindowProps,
    IContainerDetailsWindowState
>{

    toolbarParentRef;
    viewData;
    downloadSchemaAnchorRef: React.RefObject<HTMLAnchorElement>;
    constructor(props: IContainerDetailsWindowProps) {
        super(props);

        this.state = {
            loaded: false,
            tableContents: props.storagePaths,
            activeSortHeaderSection: undefined,
            paginationDetails: props.paginationDetails,
            downloadSchemaLink: undefined
        };

        this.viewData = {};
        this.toolbarParentRef = React.createRef();
        this.downloadSchemaAnchorRef = React.createRef();
    }

    componentDidMount() {
        this.loadFields();
        const {
            sources,
            getMatchingSources,
        } = this.props;
        if (getMatchingSources && !sources) {
            getMatchingSources();
        }
    }

    componentDidUpdate = (prevProps: IContainerDetailsWindowProps, prevState: IContainerDetailsWindowState) => {
        const { storagePaths, paginationDetails, selectedFileSystem, pools, roles } = this.props;

        if (prevProps.paginationDetails !== paginationDetails) {
            this.setState({
                paginationDetails: paginationDetails,
                currentPage: paginationDetails.find(pd => pd.section == selectedFileSystem.fullName).currentPage,
                resultsPerPage: paginationDetails.find(pd => pd.section == selectedFileSystem.fullName).resultsPerPage,
            })
        }

        if (prevProps.storagePaths !== storagePaths) {
            this.setState({ tableContents: storagePaths });

        }

        if (selectedFileSystem) {
            const schemaDownloadLinks = {
                'accounts': "/assets/SchemaFiles/Account/account_schema.json",
                'agreements': "/assets/SchemaFiles/Agreement/agreement_schema.json",
                'works': "/assets/SchemaFiles/WorkToWorkMatching/work_to_work_matching_schema.json",
                'usage': "/assets/SchemaFiles/UsageToUsageMatching/usage_to_usage_matching_schema.json",
            };
            if (
                selectedFileSystem.parent?.name in schemaDownloadLinks
            ) {
                if (this.state.downloadSchemaLink !== schemaDownloadLinks[selectedFileSystem.parent?.name])
                    this.setState({ downloadSchemaLink: schemaDownloadLinks[selectedFileSystem.parent?.name] });
            }
            else {
                if (this.state.downloadSchemaLink !== undefined) this.setState({ downloadSchemaLink: undefined });
            }
        }

    };

    public ascending: boolean = false;

    loadFields() {
       
        const { loaded } = this.state;
        const { isRepertoireModule, containerDetailsWindowComponentData: { fields }, roles } = this.props;
        if (!loaded) {
            if (!this.props.containerDetailsWindowComponentData) {
                return;
            }

            if (fields)
                fields.forEach(item => {
                    this.viewData[item.name] = item.data;

                    if (Object.keys(this.viewData).length === fields.length) {
                        this.setState({ loaded: true });
                    }
                });
        }
    }

    sortTableByColumn = (section: string, ascending: boolean) => {
        this.props.sortColumn(section, ascending);

    };

    renderSaveAndCancelButtons(showMetadata: boolean): JSX.Element {
        const { storagePaths, saveChanges, selectedFileSystem, cancelChanges, isRepertoireModule, sources } = this.props;
        const showButtons: boolean = showMetadata && !isNullOrUndefined(storagePaths.find(s => s.isEditing));
        const disabled: boolean = isNullOrUndefined(storagePaths.find(s => s.isDirty));

        if (isRepertoireModule)
            return null;

        return <div className="row">
            <If condition={showButtons}>
                <div>
                    <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                        <button className="button btn-defaultSecondary" disabled={false}
                            onClick={() => cancelChanges(selectedFileSystem, sources)}>
                            {this.viewData[CANCEL_BUTTON_TEXT_FIELD]}
                        </button>
                    </div>

                    <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                        <button className="button btn-defaultPrimary" disabled={disabled}
                            onClick={() => saveChanges(selectedFileSystem, storagePaths, sources)}>
                            {this.viewData[SAVE_BUTTON_TEXT_FIELD]}
                        </button>
                    </div>
                </div>
            </If>
        </div>
    }

    renderSaveStateMessage(): JSX.Element {
        const { isRepertoireModule, resetMessageBanner } = this.props;
        if (isRepertoireModule)
            return null;

        return <div>
            {/* Save ingestion started*/}
            <If condition={this.props.saveState === SaveState.IngestionProcessStaredMessage}>
                <div className="row">
                    <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={[this.viewData[INGESTION_PROCESS_STARTED_FIELD]]}/>;
                </div>
            </If>

            {/* Save success */}
            <If condition={this.props.saveState === SaveState.SaveSucceeded}>
                <div className="row">
                    <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={[this.viewData[SAVE_SUCCESSFUL_MESSAGE_FIELD]]}/>;
                </div>
            </If>

            {/* Save failure */}
            <If condition={this.props.saveState === SaveState.SaveFailed}>
                <div className="row">
                    <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Danger} messages={[this.viewData[SAVE_FAILED_MESSAGE_FIELD]]}/>;
                </div>
            </If>

            {/* Cancelled */}
            <If condition={this.props.saveState === SaveState.Cancelled}>
                <div className="row">
                    <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Warning} messages={[this.viewData[CANCELLED_MESSAGE_FIELD]]}/>;
                </div>
            </If>
        </div>
    }

    renderTableHeader(showMetadata: boolean): JSX.Element {
        const { storagePathItemData, selectedFileSystem, isRepertoireModule, displayRemove, isWorkAttachmentTypeNotRequired, isProductAttachmentTypeNotRequired,
            currentDirectory, agreementAttachmentTypeRequired } = this.props;
        const { activeSortHeaderSection } = this.state;
        if (!selectedFileSystem)
            return <thead>
                <tr>
                    <td></td>
                    <td></td>
                </tr>
            </thead>

        const dir: string = selectedFileSystem.fullName;
        const fields: IComponentField[] = storagePathItemData.fields.filter(f => f.componentInstance === dir);

        if (showMetadata) {
            return (
                <thead>
                    <tr>
                        <td>
                            <TableHeaderSection
                                section={this.viewData[FILENAME_TABLE_HEADER_FIELD]}
                                fieldName={FILE_NAME_SORTING_KEY}
                                isSortingActive={this.viewData[FILENAME_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                ascending={this.ascending}
                                onClickHeaderSection={this.sortTableByColumn}
                                sortable
                            />
                        </td>
                        <If condition={!!fields.find(f => f.name === FILE_METADATA_SOURCE)}>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[SOURCE_TABLE_HEADER_FIELD]}
                                    fieldName={SOURCE_SORTING_KEY}
                                    isSortingActive={this.viewData[SOURCE_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>
                        <If condition={!!fields.find(f => f.name === FILE_METADATA_POOL_CODE)}>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[POOL_CODE_TABLE_HEADER_FIELD]}
                                    fieldName={POOL_CODE_SORTING_KEY}
                                    isSortingActive={this.viewData[POOL_CODE_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>
                        <If condition={!!fields.find(f => f.name === FILE_METADATA_DISTRIBUTION)}>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[DISTRIBUTION_CODE_TABLE_HEADER_FIELD]}
                                    fieldName={DISTRIBUTION_CODE_SORTING_KEY}
                                    isSortingActive={this.viewData[DISTRIBUTION_CODE_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>


                        <If condition={!!fields.find(f => f.name === FILE_METADATA_FORMAT)}>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[INPUT_FORMAT_TABLE_HEADER_FIELD]}
                                    fieldName={INPUT_FORMAT_SORTING_KEY}
                                    isSortingActive={this.viewData[INPUT_FORMAT_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>
                        <If condition={!!fields.find(f => f.name === FILE_METADATA_OUTPUT_FORMAT)}>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[OUTPUT_FORMAT_TABLE_HEADER_FIELD]}
                                    fieldName={OUTPUT_FORMAT_SORTING_KEY}
                                    isSortingActive={this.viewData[OUTPUT_FORMAT_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>

                        <If condition={!!fields.find(f => f.name === FILE_METADATA_DESTINATION)}>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[DESTINATION_TABLE_HEADER_FIELD]}
                                    fieldName={DESTINATION_SORTING_KEY}
                                    isSortingActive={this.viewData[DESTINATION_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>
                        <If condition={!!fields.find(f => f.name === FILE_METADATA_RIGHTHOLDER)}>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[RIGHTHOLDER_TABLE_HEADER_FIELD]}
                                    fieldName={RIGHTHOLDER_SORTING_KEY}
                                    isSortingActive={this.viewData[RIGHTHOLDER_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>
                        <If condition={!!fields.find(f => f.name === FILE_METADATA_FILE_SIZE)}>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[FILE_SIZE_TABLE_HEADER_FIELD]}
                                    fieldName={FILE_SIZE_SORTING_KEY}
                                    isSortingActive={this.viewData[FILE_SIZE_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>
                        <If condition={!!fields.find(f => f.name === FILE_METADATA_BATCH_ID)}>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[BATCH_ID_TABLE_HEADER_FIELD]}
                                    fieldName={BATCH_ID_SORTING_KEY}
                                    isSortingActive={this.viewData[BATCH_ID_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>
                        <If condition={!!fields.find(f => f.name === FILE_METADATA_SHOPPINGCART)}>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[FILE_METADATA_SHOPPINGCART]}
                                    fieldName={SHOPPINGCART_SORTING_KEY}
                                    isSortingActive={this.viewData[SHOPPINGCART_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>

                        <td>
                            <TableHeaderSection
                                section={this.viewData[LAST_MODIFIED_DATE_TABLE_HEADER_FIELD]}
                                fieldName={LAST_MODIFIED_SORTING_KEY}
                                isSortingActive={this.viewData[LAST_MODIFIED_DATE_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                ascending={this.ascending}
                                onClickHeaderSection={this.sortTableByColumn}
                                sortable
                            />
                        </td>

                        <td>
                            <TableHeaderSection
                                section={this.viewData[TRACKING_INFORMATION_TABLE_HEADER_FIELD]}
                                fieldName={TRACKING_INFORMATION_SORTING_KEY}
                                isSortingActive={this.viewData[TRACKING_INFORMATION_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                ascending={this.ascending}
                                onClickHeaderSection={this.sortTableByColumn}
                                sortable
                            />
                        </td>

                        <If condition={(currentDirectory === "work" && isWorkAttachmentTypeNotRequired === false) ||
                            (currentDirectory === "product" && isProductAttachmentTypeNotRequired === false) ||
                            (currentDirectory === "agreement" && agreementAttachmentTypeRequired != ATTACHMENT_TYPE_NOT_REQUIRED)}>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[ATTACHMENT_TYPE_TABLE_HEADER_FIELD]}
                                    fieldName={ATTACHMENT_TYPE_SORTING_KEY}
                                    isSortingActive={this.viewData[ATTACHMENT_TYPE_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>

                        <td><div /></td>
                    </tr>
                </thead>
            );
        }
        else {
            return (
                <thead>
                    <tr>
                        <td>
                            <TableHeaderSection
                                section={this.viewData[FILENAME_TABLE_HEADER_FIELD]}
                                fieldName={FILE_NAME_SORTING_KEY}
                                isSortingActive={this.viewData[FILENAME_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                ascending={this.ascending}
                                onClickHeaderSection={this.sortTableByColumn}
                                sortable
                            />
                        </td>

                        <td>
                            <TableHeaderSection
                                section={this.viewData[LAST_MODIFIED_DATE_TABLE_HEADER_FIELD]}
                                fieldName={LAST_MODIFIED_SORTING_KEY}
                                isSortingActive={this.viewData[LAST_MODIFIED_DATE_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                ascending={this.ascending}
                                onClickHeaderSection={this.sortTableByColumn}
                                sortable
                            />
                        </td>

                        <td>
                            <TableHeaderSection
                                section={this.viewData[TRACKING_INFORMATION_TABLE_HEADER_FIELD]}
                                fieldName={TRACKING_INFORMATION_SORTING_KEY}
                                isSortingActive={this.viewData[TRACKING_INFORMATION_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                ascending={this.ascending}
                                onClickHeaderSection={this.sortTableByColumn}
                                sortable
                            />
                        </td>

                        <If condition={(currentDirectory === "work" && isWorkAttachmentTypeNotRequired === false) ||
                        (currentDirectory === "usage" && isWorkAttachmentTypeNotRequired === false) ||
                            (currentDirectory === "product" && isProductAttachmentTypeNotRequired === false) ||
                            (currentDirectory === "agreement" && agreementAttachmentTypeRequired != ATTACHMENT_TYPE_NOT_REQUIRED) }>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[ATTACHMENT_TYPE_TABLE_HEADER_FIELD]}
                                    fieldName={ATTACHMENT_TYPE_SORTING_KEY}
                                    isSortingActive={this.viewData[ATTACHMENT_TYPE_TABLE_HEADER_FIELD] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>

                        <If condition={isRepertoireModule && displayRemove === true}>
                            <td>
                                <TableHeaderSection
                                    section={this.viewData[REMOVE]}
                                    fieldName={LAST_MODIFIED_SORTING_KEY}
                                    isSortingActive={this.viewData[REMOVE] === activeSortHeaderSection}
                                    ascending={this.ascending}
                                    onClickHeaderSection={this.sortTableByColumn}
                                    sortable
                                />
                            </td>
                        </If>
                    </tr>
                </thead>
            );
        }
    }

    showSearchModal = (modalProps: any, searchViewKey: string) => {
        const { showModal } = this.props;
        showModal(SEARCH_VIEW, searchViewKey, modalProps, true, "Search");
    };

    determineIsReadOnly = (storagePathFullName: string): boolean => {
        const { roles } = this.props;
        if (roles && !(roles.includes(CONTRIBUTE_INGESTION_USAGE_ROLE) || roles.includes(ALL_ROLES))) {
            if (storagePathFullName.startsWith('usage')) {
                return true;
            }
        }
        if (roles && !(roles.includes(CONTRIBUTE_INGESTION_REPERTOIRE_ROLE) || roles.includes(ALL_ROLES))) {
            if (storagePathFullName.startsWith('repertoire')) {
                return true;
            }
        }
        if (roles && !(roles.includes(CONTRIBUTE_PAYMENT_ROLE) || roles.includes(ALL_ROLES))) {
            if (storagePathFullName.startsWith('payments')) {
                return true;
            }
        }
        return false;
    }

    renderTableBody(showMetadata: boolean): JSX.Element {
        const {
            storagePaths,
            selectedFileSystem,
            fileMetadataChanged,
            editingChanged,
            distributions,
            openDirectory,
            storagePathItemData,
            jobs,
            destinations,
            isRepertoireModule,
            displayRemove,
            paginationDetails,
            updatePagination,
            sources,
            pools,
            undoUsageIngestion,
            showYesNoPrompt,
            showModal,
            hideModal,
            lookupValues,
            tabs,
            activeTab,
            currentDirectory,
            isWorkAttachmentTypeNotRequired,
            isWorkAttachmentTypeOptional,
            isProductAttachmentTypeNotRequired, 
            isProductAttachmentTypeOptional,
            agreementAttachmentTypeRequired,
        } = this.props;

        if (storagePaths.length != 0) {
            for (var storageItem of storagePaths) {
                if (storageItem.metadata != null) {
                    for (var job of jobs) {
                        if (storageItem.metadata.jobID == job.jobID) {
                            storageItem.inProgress = true;
                        }
                    }
                }
            }
        }

        let sectionPagination: IPaginationDetail = updatePagination && paginationDetails.find(pd => pd.section === (selectedFileSystem ? selectedFileSystem.fullName : ''));
        if (updatePagination && sectionPagination == undefined) sectionPagination = paginationDetails[paginationDetails.push({ section: selectedFileSystem ? selectedFileSystem.fullName : '', currentPage: 1, resultsPerPage: 10 }) - 1];
        let { currentPage, resultsPerPage }: IPaginationDetail = sectionPagination || { section: selectedFileSystem ? selectedFileSystem.fullName : '', currentPage: 1, resultsPerPage: 10 };
        if(currentPage == "") { currentPage = 1 }
        const currentStoragePaths = updatePagination ? storagePaths.slice(
            (currentPage - 1) * resultsPerPage,
            (currentPage) * resultsPerPage
        ) : storagePaths;

        if (currentStoragePaths) {
            return <tbody>
                {
                    currentStoragePaths.map((p: IStoragePathItemData, idx: number) => {
                        const isReadOnly = this.determineIsReadOnly(p.fullName);

                        return (<StoragePathItem
                            key={idx}
                            index={idx + ((currentPage - 1) * resultsPerPage)}
                            data={p}
                            openDirectory={openDirectory}
                            hasMetadata={showMetadata}
                            canEditMetadata={selectedFileSystem && selectedFileSystem.canEditMetadata}
                            fileMetadataChanged={fileMetadataChanged}
                            editingChanged={editingChanged}
                            distributions={distributions}
                            storagePathItemData={storagePathItemData}
                            matchNow={this.matchNowUpdate.bind(this)}
                            destinations={FileSystemHelper.filtered_destination_lookup(selectedFileSystem, destinations)}
                            isRepertoireModule={isRepertoireModule}
                            removeAttachmentFromMaintenanceScreen={this.removeAttachmentFromMaintenanceScreen.bind(this)}
                            deleteFile={this.deleteFile.bind(this)}
                            displayRemove={displayRemove}
                            sources={sources}
                            pools={pools}
                            undoUsageIngestion={undoUsageIngestion}
                            showYesNoPrompt={showYesNoPrompt}
                            hideModal={hideModal}
                            showSearchModal={this.showSearchModal}
                            isReadOnly={isReadOnly}
                            lookupValues={lookupValues}
                            activeTab={activeTab}
                            tabs={tabs}
                            currentDirectory={currentDirectory}
                            isWorkAttachmentTypeNotRequired={isWorkAttachmentTypeNotRequired}
                            isWorkAttachmentTypeOptional={isWorkAttachmentTypeOptional}
                            isProductAttachmentTypeNotRequired={isProductAttachmentTypeNotRequired}
                            isProductAttachmentTypeOptional={isProductAttachmentTypeOptional}
                            path={selectedFileSystem.path}
                            agreementAttachmentTypeRequired={agreementAttachmentTypeRequired}
                        />)
                    }
                    )}
            </tbody>
        }
        else {
            return <tbody></tbody>
        }
    }

    onClick(fileSystem: IFileSystem): void {
        if (fileSystem)
            this.props.refreshDirectory(fileSystem, this.props.sources);
    }

    matchNowUpdate(data: IStoragePathItemData, index: number): void {
        const { matchNow, matchNowSubmit, selectedFileSystem, storagePaths, storagePathItemData, validationMessageChanged, sources } = this.props;
        const dir: string = data.fullName.replace('/' + data.name, '');
        const fields: IComponentField[] = storagePathItemData.fields.filter(f => f.componentInstance === dir);
        let allSet: boolean = fields.every((field, i) => {
            if (field.name === 'trackingInformation') {
                return true;
            }
            return data.metadata[field.name];
        });

        if (allSet) {
            data.validationError = null;
            matchNow(data, index);
            matchNowSubmit(selectedFileSystem, storagePaths, sources);
        }
        else {
            validationMessageChanged(this.viewData[VALIDATION_ERROR_MESSAGE], index);
        }
    }

    renderBreadcrumbs(): JSX.Element {
        const filesystem = this.props.selectedFileSystem;
        const { isRepertoireModule } = this.props;
        if (!filesystem || isRepertoireModule)
            return null;

        const filesystems = FileSystemHelper.getAncestors(filesystem);
        const links: JSX.Element[] = [];
        let idx: number = 0;

        if (filesystems.length > 0) {
            links.push(<NavLink tag={Link} to="#" key={idx++} onClick={() => this.onClick(filesystems[0])}>
                <span className="icon ms-Icon ms-Icon--FabricFolder"></span>{filesystems[0].name}
            </NavLink>);
        }

        for (let i = 1; i < filesystems.length; i++) {
            links.push(<i key={idx++} className="icon-separator ms-Icon ms-Icon--ChevronRightMed"></i>);
            links.push(<NavLink tag={Link} to="#" key={idx++} onClick={() => this.onClick(filesystems[i])}>{filesystems[i].name}</NavLink>);
        }

        return <div className="row">
            <div className="col-md-12">
                <button type="button" id="sidebarCollapse" className="navbar-toggle btnLateralmenu"
                    onClick={this.props.toggleSidebarCollapsed}>
                    <i className="glyphicon glyphicon-th" ></i>Data Ingestion Options
                </button>
                <div className="breadcrumb">
                    {links}
                </div>
            </div>
        </div>
    }

    downloadSchema(): void {
        const link = this.downloadSchemaAnchorRef.current;
        if (link.href !== undefined) link.click();
    }

    renderHeaderSection(): JSX.Element {
        const filesystem = this.props.selectedFileSystem;
        const { isRepertoireModule, dataIngestionToolbarData, updatedScroll } = this.props;
        if (!filesystem || isRepertoireModule)
            return null;

        const filesystems = FileSystemHelper.getAncestors(filesystem);
        let title = null;

        if (filesystems.length > 0) {
            title = <span className="title" > {filesystems[0].name}</span>;
        }

        for (let i = 1; i < filesystems.length; i++) {
            title = <span className="title" > {filesystems[i].name}</span>;
        }

        return <div className="">
            <div><span className="title">{title}</span></div>

            {dataIngestionToolbarData &&
                <div id="toolbarContainer" className="DataIngestionToolbar collapse" ref={this.toolbarParentRef}>
                    <DataIngestionToolbar
                        scroll={updatedScroll}
                        toolbarData={dataIngestionToolbarData}
                        toolbarWidth={null}
                        component={CONTAINER_DETAILS_WINDOW_KEY}
                        refresh={() => { this.onClick(filesystem) }}
                        downloadSchema={this.state.downloadSchemaLink ? this.downloadSchema.bind(this) : undefined}
                    />
                    <a href={this.state.downloadSchemaLink} download ref={this.downloadSchemaAnchorRef} />
                </div>
            }

        </div>
    }

    removeAttachmentFromMaintenanceScreen(fullName: string, guid: string, index: number) {
        const { removeAttachmentMaintenanceScreen, entityID, entitySource, isRepertoireModule } = this.props;

        removeAttachmentMaintenanceScreen(fullName, entityID, entitySource, guid, index);
    }

    deleteFile(fullName: string, name: string) {
        const { deleteBlobFile, refreshDirectory, selectedFileSystem } = this.props;

        deleteBlobFile(fullName, name).then((res: boolean) => {
            refreshDirectory(selectedFileSystem, this.props.sources);
        })
    }

    uploadFile(newFile: IFile, path: string, file: any) {
        const {
            addFileToUpload
            , setProgressUploadFileProccess
            , setInDropZone
            , isRepertoireModule
            , addDroppedFileRepertoire
            , setProgressUploadFileRepertoire
        } = this.props;

        if (!isRepertoireModule)
            addFileToUpload(newFile, true);
        else
            addDroppedFileRepertoire(newFile, true);

        setInDropZone(false);

        if (!isRepertoireModule)
            setProgressUploadFileProccess(this.viewData[REQUEST_SAS], newFile, false);
        else
            setProgressUploadFileRepertoire(this.viewData[REQUEST_SAS], newFile, false);
        return Promise.resolve(
            StorageService.getUploadUri(path, isRepertoireModule).then(
                (uri: string) => {
                    if (uri && uri.length > 0) {
                        if (!isRepertoireModule) {
                            setProgressUploadFileProccess(this.viewData[OBTAINED_SAS], newFile, false);
                            setProgressUploadFileProccess(this.viewData[UPLOADING_FILE_SAS], newFile, false);
                        }
                        else {
                            setProgressUploadFileRepertoire(this.viewData[OBTAINED_SAS], newFile, false);
                            setProgressUploadFileRepertoire(this.viewData[UPLOADING_FILE_SAS], newFile, false);
                        }
                        return StorageService.uploadFile(uri, file).then(
                            (res: boolean) => {
                                if (res) {
                                    if (!isRepertoireModule)
                                        setProgressUploadFileProccess(this.viewData[UPLOAD_COMPLETED], newFile, true);
                                    else
                                        setProgressUploadFileRepertoire(this.viewData[UPLOAD_COMPLETED], newFile, true);
                                    return true;
                                }
                            },
                            reject => {
                                if (!isRepertoireModule)
                                    setProgressUploadFileProccess(this.viewData[ERROR_UPLOADING_FILE_SAS], newFile, true);
                                else
                                    setProgressUploadFileRepertoire(this.viewData[ERROR_UPLOADING_FILE_SAS], newFile, true);

                                return false;
                            }
                        )
                            .catch(error => {
                                return false;
                            })
                    }
                },
                reject => {
                    if (!isRepertoireModule)
                        setProgressUploadFileProccess(this.viewData[ERROR_OBTAINING_SAS], newFile, true);
                    else
                        setProgressUploadFileRepertoire(this.viewData[ERROR_OBTAINING_SAS], newFile, true);

                    return false;
                }
            )
        )
    }

    handleDrop = e => {
        const {
            selectedFileSystem
            , cleanUploadFilesState
            , isRepertoireModule
            , currentDirectory
            , entityID
            , entitySource
            , addRepertoireAttachmentToState
            , uploadAttachment
            , removeAttachmentIfExists
            , cleanDroppedFilesRepertoire
            , sources
        } = this.props;
        const files = [...e.dataTransfer.files];

        const validSectionPath: string = this.viewData[PATH_TO_DROP_FILE] as string;

        if (selectedFileSystem && (validSectionPath.split(',').map(x => x = x.toLocaleLowerCase()).includes(selectedFileSystem.name.toLocaleLowerCase()))) {

            if (!isRepertoireModule)
                cleanUploadFilesState();
            else
                cleanDroppedFilesRepertoire();

            files.forEach(file => {

                let newFile: IFile = {
                    message: '',
                    name: file.name,
                    size: file.size,
                    isInProgress: true
                }

                let fullPath: string = "";

                if (isRepertoireModule) {
                    fullPath = `${selectedFileSystem.fullName}/${currentDirectory}/${newFile.name}`;

                    uploadAttachment(newFile.name, entityID, entitySource)
                        .then((res: IAttachedFile) => {
                            const fileToUpload: IFile = { ...newFile };
                            fileToUpload.name = res.guid;
                            fullPath = `${selectedFileSystem.fullName}/${currentDirectory}/${fileToUpload.name}`;
                            this.uploadFile(newFile, fullPath, file).then(
                                (uploaded: boolean) => {
                                    if (!uploaded) {
                                        removeAttachmentIfExists(res, entitySource);
                                    }
                                    else {
                                        addRepertoireAttachmentToState(res);
                                    }
                                }
                            )
                                .catch((err: boolean) => {
                                    if (err == false)
                                        removeAttachmentIfExists(res, entitySource);
                                });
                        });
                }
                else {
                    fullPath = `${selectedFileSystem.fullName}/${newFile.name}`;

                    this.uploadFile(newFile, fullPath, file).then(
                        res => {
                            this.props.refreshDirectory(selectedFileSystem, sources);
                        });
                }

            });
        }
        e.preventDefault();
    };

    handleDragLeave = e => {
        const { setInDropZone, selectedFileSystem } = this.props;

        const validSectionPath: string = this.viewData[PATH_TO_DROP_FILE] as string;
        if (selectedFileSystem && (validSectionPath.split(',').map(x => x = x.toLocaleLowerCase()).includes(selectedFileSystem.name.toLocaleLowerCase())))
            setInDropZone(true);
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragEnd = e => {
        const { setInDropZone, selectedFileSystem } = this.props;
        const validSectionPath: string = this.viewData[PATH_TO_DROP_FILE] as string;

        if (selectedFileSystem && (validSectionPath.split(',').map(x => x = x.toLocaleLowerCase()).includes(selectedFileSystem.name.toLocaleLowerCase())))
            setInDropZone(false);
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragEnter = e => {
        const { setInDropZone, selectedFileSystem } = this.props;

        const validSectionPath: string = this.viewData[PATH_TO_DROP_FILE] as string;
        if (selectedFileSystem && (validSectionPath.split(',').map(x => x = x.toLocaleLowerCase()).includes(selectedFileSystem.name.toLocaleLowerCase())))
            setInDropZone(true);
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragOver = e => {
        const { setInDropZone, selectedFileSystem } = this.props;

        const validSectionPath: string = this.viewData[PATH_TO_DROP_FILE] as string;
        if (selectedFileSystem && (validSectionPath.split(',').map(x => x = x.toLocaleLowerCase()).includes(selectedFileSystem.name.toLocaleLowerCase())))
            setInDropZone(true);
        e.preventDefault();
        e.stopPropagation();
    };

    renderUploadStages(files: IFile[]): JSX.Element {
        return <ul>
            {files.map((file: IFile) => {
                return (
                    <div className="containerStages">
                        <div className="subTittle2 bold textCenter">
                            <span className="">{this.viewData[PREVENT_REFRESH_FIELD]}</span>
                        </div>
                        <div className="row">
                            <li key={file.name}>
                                <span className='bold'>{file.name}</span>
                                <span className={file.isInProgress ? 'icon ms-Icon ms-Icon--SyncOccurence' : ''} ></span>
                            </li>
                        </div>
                        <span className="subTittle">{file.message}</span>
                    </div>
                )
            })}
        </ul>
    }

    renderStageBox(): JSX.Element {
        const {
            droppedFiles
            , isRepertoireModule
            , cleanUploadFilesState
            , entityID
            , attachmentsDropped
            , cleanDroppedFilesRepertoire
            , agreementAttachmentsDropped
        } = this.props;

        if (isRepertoireModule) {
            if (attachmentsDropped && attachmentsDropped.files && attachmentsDropped.files.length > 0) {
                return (
                    <div className="row">
                        <div className="col-md-12 alert alert-success alert-dismissible fade in">
                            <NavLink tag={Link} to="#" className="close" data-dismiss="alert" aria-label="close" onClick={cleanDroppedFilesRepertoire} >&times;</NavLink>
                            {this.renderUploadStages(attachmentsDropped.files)}
                        </div>
                    </div>
                )
            }
            else if (agreementAttachmentsDropped && agreementAttachmentsDropped.files && agreementAttachmentsDropped.files.length > 0) {
                return (
                    <div className="row">
                        <div className="col-md-12 alert alert-success alert-dismissible fade in">
                            <NavLink tag={Link} to="#" className="close" data-dismiss="alert" aria-label="close" onClick={cleanDroppedFilesRepertoire} >&times;</NavLink>
                            {this.renderUploadStages(agreementAttachmentsDropped.files)}
                        </div>
                    </div>
                )
            }
        }
        else {
            return (
                <If condition={droppedFiles && droppedFiles.files && droppedFiles.files.length > 0}>
                    <div className="row">
                        <div className="col-md-12 alert alert-success alert-dismissible fade in">
                            <NavLink tag={Link} to="#" className="close" data-dismiss="alert" aria-label="close" onClick={cleanUploadFilesState} >&times;</NavLink>
                            {this.renderUploadStages(droppedFiles.files)}
                        </div>
                    </div>
                </If>
            )
        }
    }

    renderPaginationView(): JSX.Element {
        const { selectedFileSystem, updatePagination } = this.props;
        const { tableContents, paginationDetails } = this.state;
        let sectionPagination: IPaginationDetail = updatePagination && paginationDetails.find(pd => pd.section === (selectedFileSystem ? selectedFileSystem.fullName : ''));
        if (updatePagination && sectionPagination == undefined) {
            sectionPagination = paginationDetails[paginationDetails.push({ section: selectedFileSystem ? selectedFileSystem.fullName : '', currentPage: 1, resultsPerPage: 10 }) - 1];
        }
        const { section, currentPage, resultsPerPage } = sectionPagination;

        return <If condition={tableContents}>
            <PaginationView key='pagination'
                contentsLength={tableContents ? tableContents.length : 0}
                resultsPerPage={resultsPerPage}
                currentPage={currentPage}
                updateContentsTable={(indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: any, repertoireSection: string) =>
                    updatePagination(resultsPerPage, currentPage, selectedFileSystem.fullName)}
                repertoireSection={section}
            />
        </If>
    }

    render() {
        if (!this.state.loaded)
            return <div className="loading" />

        const { selectedFileSystem
            , pageContentIsActive
            , isDropZone
            , droppedFiles
            , isRepertoireModule
            , cleanUploadFilesState
            , entityID
            , attachmentsDropped
            , cleanDroppedFilesRepertoire
            , component
            , sources
        } = this.props;

        const showMetadata: boolean = selectedFileSystem ? selectedFileSystem.hasMetadata : false;

        let className = "container-fluid ";
        let dragAndDropClassName = "dropBox notToDisplay";
        let dragAndDropContainerClassName = "formBox autoBar";
        let dragAreaClassName = "dragArea ";

        if(selectedFileSystem)
            if(!selectedFileSystem.canEditMetadata) dragAreaClassName = "notToDisplay";

        if (pageContentIsActive) {
            className = className + " active";
        }

        if (!isRepertoireModule)
            className = className + " pageContent "

        if (isDropZone) {
            dragAndDropClassName = "dropBox display";
        }

        if ((!entityID || entityID === 0) && isRepertoireModule) {
            dragAndDropContainerClassName = dragAndDropContainerClassName + "disabledDragAndDrop"
        }
        return (
            <div className={className}>
                {/* Header */}
                {this.renderBreadcrumbs()}

                {this.renderHeaderSection()}

                {this.renderStageBox()}
                {this.renderSaveStateMessage()}
                {this.renderPaginationView()}

                <If condition={(!entityID || entityID === 0) && isRepertoireModule}>
                    <div className="row">
                        <div className="col-md-12 alert alert-warning alert-dismissible fade in">
                            <NavLink tag={Link} to="#" className="close" data-dismiss="alert" aria-label="close" onClick={cleanUploadFilesState} >&times;</NavLink>
                            <div className="row textCenter">
                                {component === AGREEMENTS_KEY ?
                                    (<span className='bold '>{this.viewData[DISABLE_DRAG_AND_DROP_AGREEMENTS]}</span>)
                                    : component === WORKS_PAGE_VIEW ?
                                        (<span className='bold '>{this.viewData[DISABLE_DRAG_AND_DROP]}</span>)
                                        : component === PRODUCTS_PAGE_VIEW ?
                                            (<span className='bold '>{this.viewData[DISABLE_DRAG_AND_DROP_PRODUCTS]}</span>) : <div></div>
                                }
                            </div>
                        </div>
                    </div>
                </If>

                {/* Files */}
                <div className="row">
                    <If condition={isRepertoireModule}>
                        <div className='row textCenter' style={{ padding: '10px' }}>{this.viewData[DRAG_AND_DROP_INSTRUCTION]}</div>
                    </If>
                    <div className="form-group col-md-12">
                        <div className={dragAndDropContainerClassName}>
                            <div id="DragAndDropArea" className="dragAndDrop" onDragOver={e => this.handleDragOver(e)} onDragEnter={e => this.handleDragEnter(e)} onDrop={e => this.handleDrop(e)}>
                                <table className="dataTable">
                                    {this.renderTableHeader(showMetadata)}
                                    {this.renderTableBody(showMetadata)}
                                </table>
                            </div>
                            <div className={dragAndDropClassName}
                                onDrop={e => this.handleDrop(e)}
                                onDragLeave={e => this.handleDragLeave(e)}
                                onDragEnd={e => this.handleDragEnd(e)}
                                onDragOver={e => this.handleDragOver(e)}
                                onDragEnter={e => this.handleDragEnter(e)}>
                                <div className="dropBoxContent">
                                    <div className="row">
                                        <span className="icon ms-Icon ms-Icon--Upload dopBoxContent-Icon" aria-hidden="true"></span>
                                    </div>
                                    <div className="row">
                                        <span className="dopBoxContent-Heading">Drop files to upload</span>
                                    </div>
                                </div>
                            </div>
                            <div className = {dragAreaClassName} onDragOver={e => this.handleDragOver(e)} onDragEnter={e => this.handleDragEnter(e)}>
                                <span>Drag files here to upload</span>
                            </div>
                        </div>
                    </div>
                </div>
                <br />

                {this.renderPaginationView()}

                {/* Save and cancel buttons */}
                {this.renderSaveAndCancelButtons(showMetadata)}

            </div>
        )
    }
}