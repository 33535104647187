import * as React from "react";
import { throttle, last } from "lodash";
import { EVENT_HANDLER_THROTTLE_TIME, REPRESENTATION_PAGE } from "../../../../Consts";
import IRepertoireComponentDataItem from "../../../../../redux/types/IRepertoireComponentDataItem";
import { IInterestedParty } from "../../../../types/IInterestedParty";
import { ISocIP } from "../../../../types/ISocIP";
import { ITabReduxItem } from "../../../../../redux/types/ITabReduxItem";
import { showModal, showYesNoPrompt, hideModal, showUpdateFieldsModal } from "../../../../../redux/reducers/ModalReducer";
import {
    setChangesMade, addTab, updateInterestedPartyField, sortInterestedPartyFieldData, updateIpAgreementsFilterStateField,
    setEditableFields, displayRepresentationFlag, addNewRepresentation, setDataSource, addArtistToWork, updateEditableFields
} from "../../../../../redux/reducers/RepertoireReducer";
import { IComponentDataItem } from "../../../../../core/types/IComponentDataItem";
import { IAgreementSearchState } from "../../../../types/IAgreementSearchState";
import { IRepertoireField } from "../../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../../../../redux/types/FormatFields";
import { IActiveAccordion } from "../../../../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../../../../redux/types/IResultsPerPage";
import { IIPMaintenancePageUIConfiguration } from "../../../../../redux/types/IIPMaintenancePageUIConfiguration";
import WorkGroupGeneralDataView from "./components/WorkGroupGeneralDataView";
import { IWorkGroupMaintenanceState } from "../../../../../redux/types/IWorkGroupMaintenanceState";
import { IDataActionToolbar } from "../../../../types/IDataActionToolbar";
import { IContributorSearchQuery } from "../../../../types/IContributorSearchQuery";
import { IArtistSearchQuery } from "../../../../types/IArtistSearchQuery";
import { IWorksSearchQuery } from "../../../../types/IWorksSearchQuery";
import { IContributorSearchResult } from "../../../../types/IContributorSearchResult";
import { IArtist } from "../../../../types/IArtist";
import { IWorksSearchResult } from "../../../../types/IWorksSearchResult";
import { IWorkflowSearchResult } from "../../../../types/IWorkflowSearchResult";

export interface IWorkGroupPageProps {
    workGroupMaintenanceState: IWorkGroupMaintenanceState;
    workGroupGeneralDataViewData?: IRepertoireComponentDataItem;
    activeTab: number;
    tabs: ITabReduxItem[];
    worksPageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    dataSource: string;
    worksSearchResults: IWorksSearchResult[];
    artistsSearchResults: IArtist[];
    lookupValues: ILookupDictionary;
    contributorsSearchResults: IContributorSearchResult[];
    searchSuccessful: boolean;
    workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    expandedWorkResults: number[];
    expandAll: boolean;
    editableFieldsDataView: IRepertoireComponentDataItem;
    editableFields: IRepertoireField[];
    dataGridTableData?: IRepertoireComponentDataItem;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    currentPage?: number;
    setDataSource: typeof setDataSource;
    addTab: typeof addTab;
    showModal: typeof showModal;
    getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], actionList: IDataActionToolbar[], readonlyIndicatorsWorkFlagTypes: string[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], openEntityForWorflowSession?: boolean, workGroup?: string) => void;
    getWorkDetailsWorkflow: (
        dataSource: string,
        workID: number,
        lookups: ILookupDictionary,
        otherIndicatorsWorkFlagTypes: string[],
        dataActions: IDataActionToolbar[],
        workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem,
        formats: FormatFields[],
        readonlyIndicatorsWorkFlagTypes: string[],
        openEntityForWorflowSession?: boolean,
        activeTab?: number, currentWorkflowIndex?: number,
        workflows?: IWorkflowSearchResult[],
        workGroup?: string) => void;
    searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    searchArtists: (query: IArtistSearchQuery, dataSource: string) => void;
    searchContributors: (
        query: IContributorSearchQuery,
        dataSource: string
    ) => void;
    addNewWork: (lookups: ILookupDictionary, actionList: IDataActionToolbar[], isNew?: boolean, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[]) => void;
    expandWorkResult: (workResult: number) => void;
    expandAllResults: () => void;
    setEditableFields: typeof setEditableFields;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    hideModal?: typeof hideModal;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    sortSearchResults: (name: string, results: any) => void;
    ipMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    ipMaintenanceGridsViewData: IRepertoireComponentDataItem;
    ipMaintenanceAccordionViewData?: IRepertoireComponentDataItem;
    ipMaintenanceToolbarData?: IRepertoireComponentDataItem;
    ipMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    interestedParty: IInterestedParty;
    setChangesMade: typeof setChangesMade;
    activeAccordion: string[];
    updateInterestedPartyField: typeof updateInterestedPartyField;
    sortIPFieldData: typeof sortInterestedPartyFieldData;
    openIPAccordion: (accordionName: string, componentName: string) => void;
    closeIPAccordion: (accordionName: string, componentName: string) => void;
    showYesNoPrompt: typeof showYesNoPrompt;
    saveChanges: (account: ISocIP) => void;
    deleteAccount: (ipBaseNumber: string, account: string, activeTab: number) => void;
    addNewAccount: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralViewData?: IRepertoireComponentDataItem) => void;
    undoIPChanges: (ipBaseNumber?: string, accountNumber?: string) => void;
    resetMessageBanner: () => void;
    ipMaintenanceTimeRangeSelectorViewData: IComponentDataItem;
    ipAgrementsSearchState: IAgreementSearchState;
    updateIpAgreementsFilterStateField: typeof updateIpAgreementsFilterStateField;
    filterIpAgreements: (searchState: IAgreementSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[]) => void;
    searchSocIPVersionHistory: (socIPId: number, activeTabItem: ITabReduxItem, formats: FormatFields[]) => void;
    workflowFieldsData: IRepertoireComponentDataItem;
    ipMaintenancePageUIConfiguration: IIPMaintenancePageUIConfiguration;
    getIPMaintenaceDisplaySettings: () => void;
    displayRepresentation: typeof displayRepresentationFlag;
    displayRepresentationBoolean: boolean;
    ipRepresentationGeneralDataViewData: IRepertoireComponentDataItem;
    ipBaseNumber: string;
    addNewRepresentation: typeof addNewRepresentation;
    addArtistToWork: typeof addArtistToWork;
    updateEditableField: typeof updateEditableFields;
    readonlyIndicatorsWorkFlagTypes: string[];
    otherIndicatorsWorkFlagTypes: string[];
    getOtherIndicatorsWorkFlagTypes: () => void;
    getReadonlyIndicatorsWorkFlagTypes: () => void;
}

interface IWorkGroupPageState {
    toolbarWidth: number;
}
export type IWorkGroupMaintenanceToolbarKeys = keyof IWorkGroupPageProps;

export class WorkGroupPage extends React.PureComponent<
    IWorkGroupPageProps,
    IWorkGroupPageState
> {
    toolbarParentRef;
    constructor(props: IWorkGroupPageProps) {
        super(props)
        this.state = {
            toolbarWidth: null
        };
        this.toolbarParentRef = React.createRef();
    }

    render() {
        const {
            tabs,
            activeTab,
            showModal,
            hideModal,
            lookupValues,
            ipRepresentationGeneralDataViewData,
            ipBaseNumber,
            addNewRepresentation,
            resetMessageBanner,
            workGroupGeneralDataViewData,
            worksPageData,
            searchViewData,
            searchResultsTableData,
            dataSource,
            setDataSource,
            getWorkDetails,
            getWorkDetailsWorkflow,
            searchWorks,
            worksSearchResults,
            searchArtists,
            artistsSearchResults,
            searchContributors,
            contributorsSearchResults,
            addNewWork,
            searchSuccessful,
            workMaintenanceGeneralDataViewData,
            expandedWorkResults,
            expandAll,
            expandWorkResult,
            expandAllResults,
            editableFieldsDataView,
            setEditableFields,
            updateComponentFieldsItems,
            editableFields,
            resetPagination,
            updatePagination,
            updateUserPreferences,
            ipMaintenanceSaveResultData,
            workGroupMaintenanceState,
            addArtistToWork,
            updateEditableField,
            allResultsPerPage,
            defaultActiveAccordions,
            showUpdateFieldsModal,
            dataGridTableData,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            sortSearchResults,
            otherIndicatorsWorkFlagTypes,
            getOtherIndicatorsWorkFlagTypes,
            getReadonlyIndicatorsWorkFlagTypes,
            readonlyIndicatorsWorkFlagTypes
        } = this.props;

        const isReadonly: boolean = tabs[activeTab].isReadonly;

        return (<WorkGroupGeneralDataView
            workGroupMaintenanceState={workGroupMaintenanceState}
            workGroupGeneralDataViewData={workGroupGeneralDataViewData}
            worksPageData={worksPageData}
            searchViewData={searchViewData}
            searchResultsTableData={searchResultsTableData}
            dataSource={dataSource}
            setDataSource={setDataSource}
            addArtistToWork={addArtistToWork}
            getWorkDetails={getWorkDetails}
            getWorkDetailsWorkflow={getWorkDetailsWorkflow}
            searchWorks={searchWorks}
            worksSearchResults={worksSearchResults}
            searchArtists={searchArtists}
            artistsSearchResults={artistsSearchResults}
            searchContributors={searchContributors}
            contributorsSearchResults={contributorsSearchResults}
            addNewWork={addNewWork}
            lookupValues={lookupValues}
            searchSuccessful={searchSuccessful}
            workMaintenanceGeneralDataViewData={workMaintenanceGeneralDataViewData}
            expandedWorkResults={expandedWorkResults}
            expandAll={expandAll}
            expandWorkResult={expandWorkResult}
            expandAllResults={expandAllResults}
            editableFieldsDataView={editableFieldsDataView}
            updateEditableField={updateEditableField}
            setEditableFields={setEditableFields}
            updateComponentFieldsItems={updateComponentFieldsItems}
            editableFields={editableFields}
            resetPagination={resetPagination}
            updatePagination={updatePagination}
            updateUserPreferences={updateUserPreferences}
            addTab={addTab}
            ipBaseNumber={ipBaseNumber}
            activeTab={activeTab}
            tabs={tabs}
            hideModal={hideModal}
            showModal={showModal}
            isReadonly={isReadonly}
            ipRepresentationGeneralDataViewData={ipRepresentationGeneralDataViewData}
            addNewRepresentation={addNewRepresentation}
            resetMessageBanner={resetMessageBanner}
            ipMaintenanceSaveResultData={ipMaintenanceSaveResultData}
            allResultsPerPage={allResultsPerPage}
            defaultActiveAccordions={defaultActiveAccordions}
            showUpdateFieldsModal={showUpdateFieldsModal}
            dataGridTableData={dataGridTableData}
            indexOfFirstResult={indexOfFirstResult}
            indexOfLastResult={indexOfLastResult}
            resultsPerPage={resultsPerPage}
            currentPage={currentPage}
            sortSearchResults={sortSearchResults}
            otherIndicatorsWorkFlagTypes={otherIndicatorsWorkFlagTypes}
            getOtherIndicatorsWorkFlagTypes={getOtherIndicatorsWorkFlagTypes}
            getReadonlyIndicatorsWorkFlagTypes={getReadonlyIndicatorsWorkFlagTypes}
            readonlyIndicatorsWorkFlagTypes={readonlyIndicatorsWorkFlagTypes}
        />);
    }
}

