import * as React from "react";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { EDITABLE_FIELDS, EMPTY_STRING_VALUE, FIELD_LABELS, IS_HIDDEN_FIELD, REPERTOIRE_PAGE_FORM_ITEM_SIZE, REPERTOIRE_PAGE_FORM_WIDE_SIZE, REPERTOIRE_PAGE_FORM_LARGE_BOX_SIZE } from "../../Consts";
import { IMembersPortalStateKeys } from "../../../membersportal/types/IMembersPortalStateKeys";
import ITooltip from "../Tooltip/IToolTip";

export interface ITextDataInputProps {
    label?: string;
    value?: any;
    changeData: (value: string | boolean | number, fieldName: IRepertoireStateKeys | IMembersPortalStateKeys, isOnBlur?: boolean, index?: number) => void;
    fieldName: IRepertoireStateKeys | IMembersPortalStateKeys;
    useEnterSubmit?: boolean;
    handleKeyDown?: any;
    readOnly?: boolean;
    fieldType?: string;
    maxLength?: number;
    clickPrompt?: boolean;
    onClick?: any;
    textArea?: boolean;
    isHidden: boolean;
    isOnBlurOn?: boolean
    onBlurEvent?: any;
    minValueForNumericField?: number;
    isMandatory?: boolean;
    Width?: number;
    objectKey?:string;
}

interface ITextDataInputState { }

export const SizedTextDataInput = (props: ITextDataInputProps) => {

    if (props.isHidden)
        return null;
    return (props.objectKey==="Agreements"?(<div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE} style={{paddingRight:0}}>
        <TextDataInput {...props} />
    </div>):(<div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE} >
        <TextDataInput {...props} />
    </div>))
}

export const WideTextDataInput = (props: ITextDataInputProps) => {

    if (props.isHidden)
        return null;

    return <div className={REPERTOIRE_PAGE_FORM_WIDE_SIZE}>
        <TextDataInput {...props} />
    </div>
}

export default class TextDataInput extends React.PureComponent<
    ITextDataInputProps,
    ITextDataInputState
> {
    constructor(props: ITextDataInputProps) {
        super(props);

        this.state = {};
    }

    _changeData = (event) => {
        const {
            changeData,
            fieldName,
            fieldType,
        } = this.props;

        const value = event.target.value;
        if (fieldType === "integer" && value !== '') {
            this._handleNumberInput(event);
            changeData(Number(value), fieldName)
        }
        else {
            changeData(value, fieldName)
        }
    }

    _handleNumberInput = (event) => {
        event.target.setAttribute("type", "tel")
        const caret = event.target.selectionEnd
        let element = event.target
        window.requestAnimationFrame(() => {
            if (caret === 0) {
                element.selectionStart = caret + 1
                element.selectionEnd = caret + 1
            }
            else {
                element.selectionStart = caret
                element.selectionEnd = caret
            }
        })
    }

    render() {
        const {
            label,
            value,
            changeData,
            fieldName,
            handleKeyDown,
            useEnterSubmit,
            readOnly,
            fieldType,
            maxLength,
            onClick,
            clickPrompt,
            textArea,
            isOnBlurOn,
            onBlurEvent,
            minValueForNumericField,
            isMandatory,
            Width,
            objectKey
        } = this.props;

        const inputType = fieldType === "integer" ? "number" : "text";
        const editableLabel = fieldName === EDITABLE_FIELDS ? "editableLabel" : "";
        const textAlign= (objectKey==="interestPercent"||objectKey==="perDuration")? true :false;
        return (
            <div>
                {label ? <label className={EMPTY_STRING_VALUE.concat(isMandatory ? " isMandatory " : "", readOnly ? " disabledItem " : "")}>{label}:</label> : null}

                {textArea ?
                    <textarea
                        style={{ minHeight: '50px' }}
                        value={value == null || value == undefined ? '' : value}
                        onChange={this._changeData}
                        disabled={readOnly}
                    />
                    : useEnterSubmit ?
                        <input
                            type={inputType}
                            value={value == null || value == undefined ? '' : value}
                            onChange={this._changeData}
                            onKeyDown={event => handleKeyDown(event, fieldName)}
                            maxLength={maxLength}
                            data-testid={fieldName}
                        /> : (clickPrompt ?
                            <input
                                type={inputType}
                                value={value == null || value == undefined ? '' : value}
                                onChange={this._changeData}
                                maxLength={maxLength}
                                onClick={event => onClick(fieldName)}
                                data-testid={fieldName}
                            /> : (readOnly ?
                                <ITooltip
                                    tooltipContent={value == null || value == undefined ? '' : value}
                                    showTooltip={value !== null && value !== undefined}
                                >
                                    <input
                                        className={"disabledItem"}
                                        style={textAlign?{textAlign: 'right'}:{}}
                                        type={inputType}
                                        value={value == null || value == undefined ? '' : value}
                                        onChange={this._changeData}
                                        maxLength={maxLength}
                                        disabled
                                        data-testid={fieldName}
                                    /></ITooltip>
                                : (isOnBlurOn) ?
                                    <input
                                        type={inputType}
                                        value={value == null || value == undefined ? '' : value}
                                        onChange={this._changeData}
                                        onBlur={event => onBlurEvent(event.target.value, fieldName, true)}
                                        maxLength={maxLength}
                                        data-testid={fieldName}
                                    /> :
                                    <input
                                        type={inputType}
                                        value={value == null || value == undefined ? '' : value}
                                        onChange={this._changeData}
                                        min={(minValueForNumericField == undefined ? '' : minValueForNumericField.toString())}
                                        maxLength={maxLength}
                                        className={editableLabel}
                                        onBlur={event => event.target.setAttribute("type", inputType)}
                                        data-testid={fieldName}
                                        style={{ width: Width + 'px' }}
                                    />))
                }
            </div>
        );
    }
}

