import { connect } from "react-redux";
import IAppState from "../../../../../redux/types/IAppState";
import { IInterestedPartyStateKeys } from "../../../../types/IInterestedParty";
import { showModal, showYesNoPrompt, hideModal, showUpdateFieldsModal } from "../../../../../redux/reducers/ModalReducer";
import {
    addTab, sortInterestedPartyFieldData, setChangesMade, updateInterestedPartyField, createNewAccount, resetMessageBanner, updateIpAgreementsFilterStateField,
    updateEditableFields, setEditableFields, openAccordion, closeAccordion, displayRepresentationFlag, resetPagination, expandAllResults, expandWorkResult, createNewWork, setDataSource, updatePagination, sortSearchResults, addArtistToWork
} from "../../../../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../../../../redux/types/ITabReduxItem";
import { ISocIP } from "../../../../types/ISocIP";
import { deleteAccountThunk, filterIpAgreementsThunk, saveAccountChangesThunk, addNewRepresentationThunk, undoIPChangesThunk, updateComponentFieldItemsThunk, searchSocIPVersionHistoryThunk, updateUserPreferencesThunk, getIPMaintenaceDisplaySettingsThunk, getRepresentationGroupsThunk, getWorkDetailsThunk, getWorkDetailsWorkflowThunk, searchWorksThunk, searchArtistsThunk, searchContributorsThunk, getOtherIndicatorsWorkFlagTypesThunk, getReadonlyIndicatorsWorkFlagTypesThunk } from "../../../../../redux/thunks/RepertoireThunks";
import { IYesNoPromptViewModalProps } from "../../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IAgreementSearchState, IAgreementSearchStateKeys } from "../../../../types/IAgreementSearchState";
import IRepertoireComponentDataItem from "../../../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../../../../redux/types/FormatFields";
import { IResultsPerPage } from "../../../../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../../../../redux/types/IActiveAccordion";
import { IShowFieldSettingViewModalProps } from "../../../../components/repertoireModal/RepertoireModal";
import { IRepresentation } from "../../../../types/usageTypes/IRepresentation";
import { WorkGroupPage } from "./WorkGroupPage";
import { IDataActionToolbar } from "../../../../types/IDataActionToolbar";
import { IWorksSearchQuery } from "../../../../types/IWorksSearchQuery";
import { IArtistSearchQuery } from "../../../../types/IArtistSearchQuery";
import { IContributorSearchQuery } from "../../../../types/IContributorSearchQuery";
import { IArtist } from "../../../../types/IArtist";
import { IWorkflowSearchResult } from "../../../../types/IWorkflowSearchResult";

export default connect(
    (state: IAppState) => ({
        workGroupMaintenanceState: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].workGroupMaintenanceState,
        ipBaseNumber: state.repertoire.tabs[state.repertoire.activeTab].representationMaintenanceState &&
            state.repertoire.tabs[state.repertoire.activeTab].representationMaintenanceState.representationState &&
            state.repertoire.tabs[state.repertoire.activeTab].representationMaintenanceState.representationState.ipBaseNumber,
        workGroupGeneralDataViewData:
            state.repertoire.componentData.WorkGroupGeneralDataView,
        activeTab: state.repertoire.activeTab,
        tabs: state.repertoire.tabs,
        worksPageData: state.repertoire.componentData.WorksPage,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        dataSource: state.repertoire.dataSource,
        worksSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].worksSearchResult,
        artistsSearchResult: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].artistsSearchResult,
        lookupValues: state.lookupEntities,
        contributorsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].contributorsSearchResult,
        artistsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].artistsSearchResult,
        searchSuccessful: state.repertoire.searchSuccessful,
        workMaintenanceGeneralDataViewData: state.repertoire.componentData.WorkMaintenanceGeneralDataView,
        expandedWorkResults: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].expandedWorkResults,
        expandAll: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].expandAll,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        editableFields: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].editableFields,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        indexOfFirstResult: state.repertoire.resultsPerPage[0] && state.repertoire.resultsPerPage[0].indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage[0] && state.repertoire.resultsPerPage[0].indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage[0] && state.repertoire.resultsPerPage[0].resultsPerSection,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        currentPage: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].currentPage ? state.repertoire.tabs[state.repertoire.activeTab].currentPage : undefined,
        ipMaintenanceGeneralDataViewData:
            state.repertoire.componentData.IPMaintenanceGeneralDataView,
        ipMaintenanceGridsViewData:
            state.repertoire.componentData.IPMaintenanceGridsView,
        ipMaintenanceAccordionViewData:
            state.repertoire.componentData.IPMaintenanceAccordionView,
        ipMaintenanceToolbarData:
            state.repertoire.componentData.IPMaintenanceToolbar,
        ipMaintenanceSaveResultData:
            state.repertoire.componentData.SaveResult,
        activeAccordian: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].activeAccordions,
        loaded: state.repertoire.tabs[state.repertoire.activeTab].interestedPartyMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].interestedPartyMaintenanceState.loaded,
        ipMaintenancePageUIConfiguration: state.repertoire.ipMaintenancePageUIConfiguration,
        interestedParty: state.repertoire.tabs[state.repertoire.activeTab].interestedPartyMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].interestedPartyMaintenanceState.interestedParty,
        ipMaintenanceTimeRangeSelectorViewData: state.repertoire.componentData.IpMaintenanceTimeRangeSelector,
        ipAgrementsSearchState: state.repertoire.ipAgrementsSearchState,
        workflowFieldsData: state.repertoire.componentData.WorkflowFieldsData,
        displayRepresentationBoolean: state.repertoire.displayRepresentation,
        ipRepresentationGeneralDataViewData: state.repertoire.componentData.IPRepresentationGeneralDataView,
        activeAccordion: state.repertoire.tabs[state.repertoire.activeTab].activeAccordions,
        readonlyIndicatorsWorkFlagTypes: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].readonlyIndicatorWorkFlagTypes,
        otherIndicatorsWorkFlagTypes: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].otherIndicatorWorkFlagTypes,
    }),
    dispatch => ({
        setDataSource: (dataSource: string) => dispatch(setDataSource(dataSource)),
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        getWorkDetails: (dataSource: string, workID: number,
            lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], actionDataList: IDataActionToolbar[], readonlyIndicatorsWorkFlagTypes: string[], workMaintenanceViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], openEntityForWorflowSession?: boolean, workGroup?: string) =>
            dispatch<any>(getWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, actionDataList, workMaintenanceViewData, formats, readonlyIndicatorsWorkFlagTypes, openEntityForWorflowSession, workGroup)),
        getWorkDetailsWorkflow: (
            dataSource: string,
            workID: number,
            lookups: ILookupDictionary,
            otherIndicatorsWorkFlagTypes: string[],
            dataActions: IDataActionToolbar[],
            workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem,
            formats: FormatFields[],
            readonlyIndicatorsWorkFlagTypes: string[],
            openEntityForWorkflowSession?: boolean,
            activeTab?: number, currentWorkflowIndex?: number,
            workflows?: IWorkflowSearchResult[],
            workGroup?: string) => 
            dispatch<any>(getWorkDetailsWorkflowThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes, openEntityForWorkflowSession, activeTab, currentWorkflowIndex, workflows)),
        searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) =>
            dispatch<any>(searchWorksThunk(query, false)),
        searchArtists: (query: IArtistSearchQuery, dataSource: string) =>
            dispatch<any>(searchArtistsThunk(query, dataSource)),
        searchContributors: (query: IContributorSearchQuery) =>
            dispatch<any>(searchContributorsThunk(query)),
        addNewWork: (lookups: ILookupDictionary, actionList: IDataActionToolbar[], isNew?: boolean, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[], workGroupId?: string, workGroup?: string) =>
            dispatch<any>(createNewWork(lookups, actionList, isNew, workMaintenanceGeneralDataView, formats, workGroupId, workGroup)),
        expandWorkResult: (workResult: number) => dispatch<any>(expandWorkResult(workResult)),
        expandAllResults: () => dispatch<any>(expandAllResults()),
        setEditableFields: (componentFieldName: string, componentInstance: string) => dispatch(setEditableFields(componentFieldName, componentInstance)),
        showUpdateFieldsModal: (tittle: string, props: IShowFieldSettingViewModalProps) => dispatch<any>(showUpdateFieldsModal(tittle, props)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        hideModal: () => dispatch(hideModal()),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        setChangesMade: (tabIndex: number, changesMade: boolean) =>
            dispatch(setChangesMade(tabIndex, changesMade)),
        updateInterestedPartyField: (value: any, fieldName: IInterestedPartyStateKeys) =>
            dispatch(updateInterestedPartyField(value, fieldName)),
        saveChanges: (account: ISocIP) =>
            dispatch<any>(saveAccountChangesThunk(account)),
        addNewAccount: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralDataView?: IRepertoireComponentDataItem) =>
            dispatch<any>(createNewAccount(lookupValues, isNew, ipMaintenanceGeneralDataView)),
        sortIPFieldData: (value: any, fieldName: IInterestedPartyStateKeys) =>
            dispatch(sortInterestedPartyFieldData(value, fieldName)),
        openIPAccordion: (accordionName: string, componentName: string) =>
            dispatch(openAccordion(accordionName, componentName)),
        closeIPAccordion: (accordionName: string, componentName: string) =>
            dispatch(closeAccordion(accordionName, componentName)),
        undoIPChanges: (ipBaseNumber?: string, accountNumber?: string) =>
            dispatch<any>(undoIPChangesThunk(ipBaseNumber, accountNumber)),
        deleteAccount: (ipBaseNumber: string, account: string, activeTab?: number) =>
            dispatch<any>(deleteAccountThunk(ipBaseNumber, account, activeTab)),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        updateIpAgreementsFilterStateField: (value: any, field: IAgreementSearchStateKeys) =>
            dispatch<any>(updateIpAgreementsFilterStateField(value, field)),
        filterIpAgreements: (searchState: IAgreementSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[]) =>
            dispatch<any>(filterIpAgreementsThunk(searchState, ipBaseNumber, accountNumber, creationClasses)),
        updateEditableField: (value: any, key: string) =>
            dispatch(updateEditableFields(value, key)),
        searchSocIPVersionHistory: (socIPID: number, activeTabItem: ITabReduxItem, formats: FormatFields[]) =>
            dispatch<any>(searchSocIPVersionHistoryThunk(socIPID, activeTabItem, formats)),
        getIPMaintenaceDisplaySettings: () =>
            dispatch<any>(getIPMaintenaceDisplaySettingsThunk()),
        displayRepresentation: (display: boolean) => dispatch<any>(displayRepresentationFlag(display)),
        addNewRepresentation: (representation: IRepresentation, activeTab?: number) =>
            dispatch<any>(addNewRepresentationThunk(representation, activeTab)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        getOtherIndicatorsWorkFlagTypes: () => dispatch<any>(getOtherIndicatorsWorkFlagTypesThunk()),
        getReadonlyIndicatorsWorkFlagTypes: () => dispatch<any>(getReadonlyIndicatorsWorkFlagTypesThunk()),
        addArtistToWork: (artist: IArtist, id: number) => dispatch(addArtistToWork(artist, id)),
    })
)(WorkGroupPage);