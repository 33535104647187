import { connect } from "react-redux";
import { Dispatch } from "redux";
import { setCWRGHighWaterMarkState, updateJobSearchStateField, updateManualJobParams } from "../../../redux/reducers/HomeReducer";
import { hideModal, showModal } from "../../../redux/reducers/ModalReducer";
import {
    getCWRAckHWM,
    getAutomaticAgreementGHWM,
    getEmuwHwm,
    getManualJobDataSources,
    getOpenDistributionsThunk,
    getAllDistributionsThunk,
    getPoolsThunk,
    getUsageFilenames,
    getScheduledJob,
    getAllUsageFilenamesFromDistribution,
    manualJobView,
    searchJobsThunk,
    triggerManualJob,
    upsertScheduledJobAsync,
    getCWRGHighWaterMarkThunk,
    getSeparatePublishedCheckboxThunk
} from "../../../redux/thunks/HomeThunks";
import { getPaymentRunIDsThunk } from "../../../redux/thunks/RepertoireThunks";
import IAppState from "../../../redux/types/IAppState";
import { ITriggerManualJob } from "../../../redux/types/ITriggerManualJob";
import {
    IJobSearchState,
    IJobSearchStateKeys,
} from "../../types/IJobSearchState";
import { IScheduledJobState } from "../../../redux/types/IScheduledJobState";
import { JobSearchResultsView } from "./JobSearchResultsView";
import { ICwrgParameters } from "../../../redux/types/ICwrgParameters";

export default connect(
    (state: IAppState) => ({
        jobSearchResultsViewData: state.home.componentData.JobSearchResultsView,
        timeRangeSelectorData: state.home.componentData.TimeRangeSelector,
        jobSearchResultsGridData: state.home.componentData.JobSearchResultsGrid,
        jobSearchState: state.home.jobSearchState,
        jobSearchResults: state.home.jobSearchResults,
        newSearch: state.home.newSearch,
        lookups: state.lookupEntities,
        componentData: state.home.componentData,
        modalProps: state.modal.modalProps,
        manualJobParameters: state.home.manualJobParameters,
        manualJobType: state.home.manualJobType,
        manualJobDataSources: state.home.manualJobDataSources,
        cwrAckHWM: state.home.cwrAckHWM,
        automaticAgreementGHWM: state.home.automaticAgreementGHWM,
        paymentRunIDs: state.repertoire.paymentRunIDs,
        openDistributions: state.home.openDistributions,
        allDistributions:state.home.allDistributions,
        pools: state.home.pools,
        emuwHighWaterMark: state.home.emuwHighWaterMark,
        usageFileNames: state.home.usageFilenames,
        scheduledJob: state.home.scheduledJob,
        cwrgHighWaterMark: state.home.cwrgHighWaterMark,
        separatePublishedCheckbox: state.home.separatePublishedCheckbox
    }),
    (dispatch: Dispatch) => ({
        updateJobSearchStateField: (value: any, field: string) =>
            dispatch<any>(updateJobSearchStateField(value, field)),
        searchJobs: (searchQuery: IJobSearchState) =>
            dispatch<any>(searchJobsThunk(searchQuery)),
        manualJobView: () => dispatch<any>(manualJobView()),
        fetchManualJobDataSources: () => dispatch<any>(getManualJobDataSources()),
        triggerManualJob: (manualJob: ITriggerManualJob) =>
            dispatch<any>(triggerManualJob(manualJob)),
        upsertScheduledJobAsync: (schedule: IScheduledJobState)=>
            dispatch<any>(upsertScheduledJobAsync(schedule)),
        getUsageFilenames: (poolValue:string,distributionValue:string) =>
            dispatch<any>(getUsageFilenames(poolValue,distributionValue)),
        getScheduledJob: (jobType:string,jobParameters:string) =>
            dispatch<any>(getScheduledJob(jobType, jobParameters)),
        getAllUsageFilenamesFromDistribution: (distributionValue:string) =>
            dispatch<any>(getAllUsageFilenamesFromDistribution(distributionValue)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        getCWRAckHWM: () => dispatch<any>(getCWRAckHWM()),
        getAutomaticAgreementGHWM: () => dispatch<any>(getAutomaticAgreementGHWM()),
        getEmuwHwm: (distributionId: number) => dispatch<any>(getEmuwHwm(distributionId)),
        getPaymentRunIDs: () => dispatch<any>(getPaymentRunIDsThunk()),
        updateManualJobParams: (params: any) => dispatch(updateManualJobParams(params)),
        hideModal: () => dispatch(hideModal()),
        getOpenDistributions: () => dispatch<any>(getOpenDistributionsThunk()),
        getAllDistributions: () => dispatch<any>(getAllDistributionsThunk()),
        getPools: () => dispatch<any>(getPoolsThunk()),
        getSeparatePublishedCheckbox: () => dispatch<any>(getSeparatePublishedCheckboxThunk()),
        getCWRGHighWaterMark: (cwrgParams: ICwrgParameters) => dispatch<any>(getCWRGHighWaterMarkThunk(cwrgParams)),
        setCWRGHighWaterMark: (hwm: any) =>
            dispatch<any>(setCWRGHighWaterMarkState(hwm)),
    })
)(JobSearchResultsView);
