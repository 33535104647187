import { stat } from "fs";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { addTab, createNewAdjustment, resetPagination, setAdjustmentDataSource, sortSearchResults, updatePagination } from "../../redux/reducers/RepertoireReducer";
import { getDistributionsThunk } from "../../redux/thunks/DataIngestionThunks";
import { getAdjustmentDetailsThunk, getPoolsThunk, getUsersThunk, searchAdjustmentsThunk, searchAdjustmentVersionHistoryThunk } from "../../redux/thunks/RepertoireThunks";
import IAppState from "../../redux/types/IAppState";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import { IAdjustment } from "../types/usageTypes/IAdjustment";
import { IAdjustmentSearchQuery } from "../types/usageTypes/IAdjustmentSearchQuery";
import { IDistributionSearchQuery } from "../types/usageTypes/IDistributionSearchQuery";
import AdjustmentsPage from "./AdjustmentsPage";
import { FormatFields } from "../../redux/types/FormatFields";
import { saveRoles } from "../../redux/reducers/AccountReducer";

export default connect(
    (state: IAppState) => ({
        adjustmentsPageData: state.repertoire.componentData.AdjustmentsPage,
        adjustmentGeneralDataView: state.repertoire.componentData.AdjustmentMaintenanceGeneralDataView,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        adjustmentsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].adjustmentSearchResult,
        searchSuccessful: state.repertoire.searchSuccessful,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'adjustments')[0].indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'adjustments')[0].indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'adjustments')[0].resultsPerSection,
        currentPage: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].currentPage ? state.repertoire.tabs[state.repertoire.activeTab].currentPage : undefined,
        adjustmentDataSource: state.repertoire.adjustmentDataSource,
        distributions: state.dataIngestion.distributions,
        pools: state.repertoire.pools,
        users: state.repertoire.repertoireUsers && state.repertoire.repertoireUsers,
        roles: state.account.roles,
    }),
    (dispatch: Dispatch) => ({
        setAdjustmentDataSource: (dataSource: string) => dispatch(setAdjustmentDataSource(dataSource)),
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        searchAdjustments: (query: IAdjustmentSearchQuery, dataSource) =>
            dispatch<any>(searchAdjustmentsThunk(query, dataSource)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        searchAdjustmentVersionHistory: (adjustmentId: string, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[]) =>
            dispatch<any>(searchAdjustmentVersionHistoryThunk(adjustmentId, lookups, actionList, formats)),
        getDistributions: () => dispatch<any>(getDistributionsThunk()),
        getPools: () => dispatch<any>(getPoolsThunk()),
        getUsers: () => dispatch<any>(getUsersThunk()),
        getAdjustmentDetails: (adjustmentId: string, adjustments: IAdjustment[]) => dispatch<any>(getAdjustmentDetailsThunk(adjustmentId, adjustments)),
        addNewAdjustment: (lookupValues: ILookupDictionary, isNew?: boolean, adjustmentMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) => dispatch<any>(createNewAdjustment(lookupValues, true, adjustmentMaintenanceGeneralDataViewData)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
    })
)(AdjustmentsPage);