import * as React from "react";
import { PRODUCT_SOURCE_LOOKUP, PRODUCT_SOURCE_TYPE_LOOKUP, PRODUCT_STATUS_TYPE_LOOKUP, PRODUCT_TYPE_LOOKUP } from "../../../lookup/Consts";
import { getLookupValues } from "../../../lookup/services/LookupHelpers";
import { ILookupInstance } from "../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../redux/reducers/ModalReducer";
import { matchProductBatchRequest, postProductBatchRequest, setEditableFields, updateProductField, clearModalSearchResults } from "../../../redux/reducers/RepertoireReducer";
import { IProductState, IProductStateKeys } from "../../../redux/types/IProductState";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { SizedCheckboxDataInput } from "../../components/checkboxDataInput/CheckboxDataInput";
import SizedDateDataInput from "../../components/dateDataInput/DateDataInput";
import { SizedDropdownDataInput } from "../../components/dropdownDataInput/DropdownDataInput";
import { IShowFieldSettingViewModalProps } from "../../components/repertoireModal/RepertoireModal";
import { SizedTextDataInput } from "../../components/textDataInput/TextDataInput";
import FormattedTimeDataInput from "../../components/timeFieldDataInput/FormattedTimeDataInput";
import { ALL_ROLES, COMPONENT_INSTANCE_PRODUCTS, CONFIGURE_PRODUCTS_ROLE, DURATION_STATE_KEY, EDITABLE_FIELDS, GENERAL_DATA_EDITABLE_FIELDS, MATCH_PRODUCT_BATCH_STATE_KEY, MERGE_THIS_PRODUCT_STATE_KEY, POST_PRODUCT_BATCH_STATE_KEY, PRODUCT_BATCH_ID_STATE_KEY, PRODUCT_ID1_STATE_KEY, PRODUCT_ID2_STATE_KEY, PRODUCT_LAST_MODIFIED_DATE_STATE_KEY, PRODUCT_MAINTAINED_STATE_KEY, PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW, PRODUCT_RELEASED_DATE_STATE_KEY, PRODUCT_SOURCE_STATE_KEY, PRODUCT_SOURCE_TYPE_STATE_KEY, PRODUCT_STATUS_KEY, PRODUCT_TYPE_STATE_KEY, SEARCH_VIEW, SEARCH_VIEW_PRODUCTSOURCE_IP, PRODUCT_SOURCE_IPI_NUMBER_STATE_KEY, PRODUCT_SOURCE_IP_STATE_KEY, TITLE_EDITABLE_FIELDS_VIEW, DATA_GRID_TABLE, RECORDING_DETAILS, INTRAY_WORK_COUNT_STATE_KEY, REPERTOIRE_WORK_COUNT_STATE_KEY } from "../../Consts";
import { DataSource } from "../../types/DataSource";
import { IRepertoireField } from "../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { ISourceIP } from "../../types/ISourceIP";
import WorkBatchInput from "../../works/components/workBatchInput/WorkBatchInput";

export interface IProductMaintenanceGeneralDataViewProps {
    productMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    updateProductField: typeof updateProductField;
    lookupValues: ILookupDictionary;
    dataSource: DataSource;
    changesMade: boolean;
    saveInProgress: boolean;
    productType: string;
    id1: string;
    id2: string;
    duration: number;
    released: Date;
    trackCount: number;
    source: string;
    maintained: boolean;
    production: number;
    licenseeID: number;
    lastModifiedDate: Date;
    productBatchID: number;
    postProductBatch: typeof postProductBatchRequest;
    matchProductBatch: typeof matchProductBatchRequest;
    productBatchOperationInProgress: boolean;
    productSourceIP?: ISourceIP;
    isReadonly: boolean;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    editableFields: IRepertoireField[];
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    dataGridTableEditableFieldsData?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    hideModal?: typeof hideModal;
    roles: string[];
    dateCreated?: Date;
    sourceIP?: number;
    status?: string;
    sourceType?: string;
    clearModalSearchResults?: typeof clearModalSearchResults;
    showModal?: typeof showModal;
    product: IProductState;
}

interface IProductMaintenanceGeneralDataViewState {
    loaded: boolean;
    isHiddenIP: boolean;
    intrayWorkCount: number;
    repertoireWorkCount: number
}

export default class ProductMaintenanceGeneralDataView extends React.PureComponent<
    IProductMaintenanceGeneralDataViewProps,
    IProductMaintenanceGeneralDataViewState
> {
    viewData;
    constructor(props: IProductMaintenanceGeneralDataViewProps) {
        super(props);

        this.state = {
            loaded: false,
            isHiddenIP: true,
            intrayWorkCount: this.props.product.productWork.filter(x => x.intrayIndicator.value).length,
            repertoireWorkCount: this.props.product.productWork.filter(x => !(x.intrayIndicator.value)).length
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            productMaintenanceGeneralDataViewData: { fields }
        } = this.props;
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
        this.setReadOnlySourceIPFields();
    }

    componentDidUpdate(prevProps: IProductMaintenanceGeneralDataViewProps) {
        const { productType } = this.props;
        if (prevProps.productType != productType) {
            this.setReadOnlySourceIPFields();
        }
    }

    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab } = this.props;
        updateComponentFieldsItems(editableFields["default"], PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_PRODUCTS, PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW, tabs, activeTab);
    }

    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableEditableFieldsData,
            setEditableFields,
        } = this.props;

        setEditableFields(PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_PRODUCTS)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableEditableFieldsData,
            onClickSave: this.onSaveEditableFields.bind(this)
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    setReadOnlySourceIPFields() {
        const { productType } = this.props;
        if (productType === "SET LIST") {
            this.setState(() => ({
                isHiddenIP: false,
            }));
        }
        else {
            this.setState(() => ({
                isHiddenIP: true,
            }));
        }
    }

    onClick = (fieldName: IRepertoireStateKeys) => {
        if (fieldName == PRODUCT_SOURCE_IP_STATE_KEY) {
            this.showSearchModal(fieldName, SEARCH_VIEW_PRODUCTSOURCE_IP)
        }
    }

    showSearchModal = (modalProps: any, searchViewKey: string) => {
        const { showModal } = this.props;
        clearModalSearchResults();
        showModal(SEARCH_VIEW, searchViewKey, modalProps, true, "Search");
    };

    getStatusOptions = (lookupValues, dataSource): ILookupInstance[] => {
        let options = [];
        dataSource === DataSource.Intray ?
            options = getLookupValues(PRODUCT_STATUS_TYPE_LOOKUP, lookupValues)?.slice(0, 2) :
            getLookupValues(PRODUCT_STATUS_TYPE_LOOKUP, lookupValues);
        return options;
    }

    render() {
        const {
            productMaintenanceGeneralDataViewData,
            lookupValues,
            dataSource,
            changesMade,
            saveInProgress,
            productType,
            id1,
            id2,
            duration,
            released,
            trackCount,
            source,
            maintained,
            production,
            licenseeID,
            lastModifiedDate,
            productBatchID,
            postProductBatch,
            matchProductBatch,
            productBatchOperationInProgress,
            isReadonly,
            tabs,
            activeTab,
            sourceIP,
            sourceType,
            status,
            productSourceIP,
            product
        } = this.props;

        const { loaded } = this.state;

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            this.props.updateProductField(value, name as IProductStateKeys);
        }

        const getConfigureRoleDisabled = (): boolean => {
            const { roles } = this.props;
            if (roles && (roles.includes(CONFIGURE_PRODUCTS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }
        const renderPostBatch = (): JSX.Element => {
            if (dataSource === DataSource.Intray) {
                return <WorkBatchInput
                    label={this.viewData[PRODUCT_BATCH_ID_STATE_KEY]}
                    fieldName={PRODUCT_BATCH_ID_STATE_KEY}
                    value={productBatchID}
                    changeData={changeData}
                    postButtonLabel={this.viewData[POST_PRODUCT_BATCH_STATE_KEY]}
                    postButtonAction={() => postProductBatch(productBatchID)}
                    matchButtonLabel={this.viewData[MATCH_PRODUCT_BATCH_STATE_KEY]}
                    matchButtonAction={() => matchProductBatch(productBatchID)}
                    isEnabled={!changesMade && !saveInProgress && !productBatchOperationInProgress && !isReadonly}
                />
            }
            else {
                return null;
            }
        }
        if (loaded) {
            return (
                <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                    <div className="row">
                        <div className="col-md-12 editableSectionHeading">
                            <h1 className="borderBootom">General</h1>
                            <button className="editBtn" title="Configure data fields" onClick={this.showFieldsModal.bind(this)} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <SizedCheckboxDataInput
                            label={this.viewData[PRODUCT_MAINTAINED_STATE_KEY]}
                            fieldName={PRODUCT_MAINTAINED_STATE_KEY}
                            value={maintained}
                            changeData={changeData}
                            readonly={isReadonly}
                            isHidden={!this.viewData[PRODUCT_MAINTAINED_STATE_KEY]}
                        />
                        <SizedCheckboxDataInput
                            label={this.viewData[MERGE_THIS_PRODUCT_STATE_KEY]}
                            fieldName={MERGE_THIS_PRODUCT_STATE_KEY}
                            value={null}
                            changeData={changeData}
                            readonly={isReadonly}
                            isHidden={!this.viewData[MERGE_THIS_PRODUCT_STATE_KEY]}
                        />
                        <SizedDateDataInput
                            label={this.viewData[PRODUCT_LAST_MODIFIED_DATE_STATE_KEY]}
                            fieldName={PRODUCT_LAST_MODIFIED_DATE_STATE_KEY}
                            value={lastModifiedDate ? lastModifiedDate.toString() : null}
                            changeData={changeData}
                            readOnly={true}
                            isHidden={!this.viewData[PRODUCT_LAST_MODIFIED_DATE_STATE_KEY]}
                        />
                    </div>
                    <div className="row">
                        <SizedDropdownDataInput
                            label={this.viewData[PRODUCT_TYPE_STATE_KEY]}
                            fieldName={PRODUCT_TYPE_STATE_KEY}
                            value={productType}
                            changeData={changeData}
                            options={getLookupValues(PRODUCT_TYPE_LOOKUP, lookupValues)}
                            allowNull={true}
                            readOnly={isReadonly}
                            isHidden={!this.viewData[PRODUCT_TYPE_STATE_KEY]}
                        />
                        <SizedTextDataInput
                            label={this.viewData[PRODUCT_ID1_STATE_KEY]}
                            fieldName={PRODUCT_ID1_STATE_KEY}
                            value={id1}
                            changeData={changeData}
                            readOnly={isReadonly}
                            isHidden={!this.viewData[PRODUCT_ID1_STATE_KEY]}
                        />
                        <SizedTextDataInput
                            label={this.viewData[PRODUCT_ID2_STATE_KEY]}
                            fieldName={PRODUCT_ID2_STATE_KEY}
                            value={id2}
                            changeData={changeData}
                            readOnly={isReadonly}
                            isHidden={!this.viewData[PRODUCT_ID2_STATE_KEY]}
                        />
                    </div>
                    <div className="row">
                        <SizedDropdownDataInput
                            label={this.viewData[PRODUCT_SOURCE_STATE_KEY]}
                            fieldName={PRODUCT_SOURCE_STATE_KEY}
                            value={source}
                            changeData={changeData}
                            options={getLookupValues(PRODUCT_SOURCE_LOOKUP, lookupValues)}
                            allowNull={true}
                            readOnly={isReadonly}
                            isHidden={!this.viewData[PRODUCT_SOURCE_STATE_KEY]}
                        />
                        <SizedDropdownDataInput
                            label={this.viewData[PRODUCT_SOURCE_TYPE_STATE_KEY]}
                            fieldName={PRODUCT_SOURCE_TYPE_STATE_KEY}
                            value={sourceType}
                            changeData={changeData}
                            options={getLookupValues(PRODUCT_SOURCE_TYPE_LOOKUP, lookupValues)}
                            allowNull={true}
                            readOnly={isReadonly}
                            isHidden={this.state.isHiddenIP}
                        />
                        <SizedDateDataInput
                            label={this.viewData[PRODUCT_RELEASED_DATE_STATE_KEY]}
                            fieldName={PRODUCT_RELEASED_DATE_STATE_KEY}
                            value={released ? released.toString() : null}
                            changeData={changeData}
                            readOnly={isReadonly}
                            isHidden={!this.viewData[PRODUCT_RELEASED_DATE_STATE_KEY]}
                        />
                    </div>
                    <div className="row">
                        <SizedDropdownDataInput
                            label={this.viewData[PRODUCT_STATUS_KEY]}
                            fieldName={PRODUCT_STATUS_KEY}
                            value={status}
                            changeData={changeData}
                            options={this.getStatusOptions(lookupValues, dataSource)}
                            allowNull={true}
                            readOnly={dataSource === DataSource.Repertoire}
                            isHidden={this.state.isHiddenIP}
                        />
                        <SizedTextDataInput
                            label={this.viewData[PRODUCT_SOURCE_IP_STATE_KEY]}
                            fieldName={PRODUCT_SOURCE_IP_STATE_KEY}
                            value={((productSourceIP === null) ? null : productSourceIP.fullName.value)}
                            changeData={changeData}
                            readOnly={(isReadonly === true ? isReadonly : this.state.isHiddenIP)}
                            onClick={this.onClick}
                            clickPrompt={(isReadonly === true ? !isReadonly : !this.state.isHiddenIP)}
                            isHidden={(isReadonly === true ? !isReadonly : this.state.isHiddenIP)}
                        />
                        <SizedTextDataInput
                            label={this.viewData[PRODUCT_SOURCE_IPI_NUMBER_STATE_KEY]}
                            fieldName={PRODUCT_SOURCE_IPI_NUMBER_STATE_KEY}
                            value={((productSourceIP === null) ? null : productSourceIP.ipiNameNumber)}
                            changeData={changeData}
                            readOnly={true}
                            clickPrompt={false}
                            isHidden={(isReadonly === true ? !isReadonly : this.state.isHiddenIP)}
                        />
                        <SizedTextDataInput
                            label={this.viewData[INTRAY_WORK_COUNT_STATE_KEY]}
                            fieldName={INTRAY_WORK_COUNT_STATE_KEY}
                            value={this.state.intrayWorkCount}
                            changeData={changeData}
                            readOnly={true}
                            isHidden={!this.viewData[PRODUCT_SOURCE_STATE_KEY]}
                        />
                        <SizedTextDataInput
                            label={this.viewData[REPERTOIRE_WORK_COUNT_STATE_KEY]}
                            fieldName={REPERTOIRE_WORK_COUNT_STATE_KEY}
                            value={this.state.repertoireWorkCount}
                            changeData={changeData}
                            readOnly={true}
                            isHidden={!this.viewData[PRODUCT_SOURCE_STATE_KEY]}
                        />

                    </div>
                    <div className="row">
                        <FormattedTimeDataInput
                            fieldName={DURATION_STATE_KEY}
                            label={this.viewData[DURATION_STATE_KEY]}
                            isHidden={!this.viewData[DURATION_STATE_KEY]}
                            value={product.productWork?.reduce((sum, item) => sum + item.duration.value, 0)}
                            changeData={changeData}
                            readOnly={isReadonly}
                            tabs={tabs}
                            activeTab={activeTab}
                        />
                        {renderPostBatch()}
                    </div>
                </div>
            );
        }
        return <div />;
    }
}
