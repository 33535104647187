import { IAccountComponentData } from "../../accounts/types/IAccountComponentData";
import { LoginType } from "../../accounts/types/LoginType";
import {
    ActionTypes,
    GetUsersRequest,
    GetUsersSuccess,
    GetUsersFailure,
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE,
    LOGIN_REQUEST,
    LoginRequest,
    LOGIN_SUCCESS,
    LoginSuccess,
    LOGIN_FAILURE,
    LoginFailure,
    LogoutRequest,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LogoutSuccess,
    LOGOUT_FAILURE,
    LogoutFailure,
    AccountComponentDataRequest,
    FETCH_ACCOUNT_COMPONENT_DATA_REQUEST,
    AccountComponentDataSuccess,
    FETCH_ACCOUNT_COMPONENT_DATA_SUCCESS,
    AccountComponentDataFailure,
    FETCH_ACCOUNT_COMPONENT_DATA_FAILURE,
    AppVersionNumberRequest,
    FETCH_APP_VERSION_NUMBER_REQUEST,
    FETCH_APP_VERSION_NUMBER_SUCCESS,
    FETCH_APP_VERSION_NUMBER_FAILURE,
    AppVersionNumberSuccess,
    AppVersionNumberFailure,
    LoginTypeRequest,
    LoginTypeSuccess,
    LoginTypeFailure,
    LOGIN_TYPE_REQUEST,
    LOGIN_TYPE_SUCCESS,
    LOGIN_TYPE_FAILURE,
    SAVE_ROLES,
    SaveRoles,
    SET_PORTAL_TYPE,
    SetPortalType,
    GET_CUSTOMER_SUCCESS,
    GetCustomerSuccess,
    GetUserCRMValuesSuccess,
    GET_USER_CRM_VALUES_SUCCESS,
    SetCurrentUserNameNumber,
    SET_CURRENT_USER_NAME_NUMBER,
    GET_CUSTOMER_DATE_CONFIGURATION_SUCCESS,
    GetCustomerDateConfigurationSuccess,
    GetCustomerDefaultTerritoriesSuccess,
    GET_CUSTOMER_DEFAULT_TERRITORIES_SUCCESS,
} from "../actions/AccountActions";
import IAccountState from "../types/IAccountState";

const initialState: IAccountState = {
    users: [],
    currentUser: undefined,
    loginFailed: false,
    isSignedIn: false,
    componentData: {
        LoginPage: {
            fields: []
        }
    },
    appVersionNumber: null,
    loginType: undefined,
    roles: [],
    portalType: undefined,
    customer: undefined,
    customerDate: undefined,
    currentUserNameNumber: undefined,
    currentViewAsUserName:undefined,
    defaultTerritories:undefined
}

export function getUsersRequest(): GetUsersRequest {
    return {
        type: GET_USERS_REQUEST
    };
}

export function getUsersSuccess(users: string[]): GetUsersSuccess {
    return {
        type: GET_USERS_SUCCESS,
        payload: {
            users
        }
    };
}

export function getUsersFailure(error: string): GetUsersFailure {
    return {
        type: GET_USERS_FAILURE,
        payload: { error }
    };
}

export function loginRequest(): LoginRequest {
    return {
        type: LOGIN_REQUEST
    };
}

export function loginSuccess(username: string, currentUserNameNumber: string): LoginSuccess {
    return {
        type: LOGIN_SUCCESS,
        payload: {
            username,
            currentUserNameNumber
        }
    };
}

export function loginFailure(error: string): LoginFailure {
    return {
        type: LOGIN_FAILURE,
        payload: { error }
    };
}

export function logoutRequest(): LogoutRequest {
    return {
        type: LOGOUT_REQUEST
    };
}

export function logoutSuccess(): LogoutSuccess {
    return {
        type: LOGOUT_SUCCESS
    };
}

export function logoutFailure(error: string): LogoutFailure {
    return {
        type: LOGOUT_FAILURE,
        payload: { error }
    };
}

export function getAccountComponentDataRequest(): AccountComponentDataRequest {
    return {
        type: FETCH_ACCOUNT_COMPONENT_DATA_REQUEST
    };
}

export function getAccountComponentDataSuccess(componentData: IAccountComponentData): AccountComponentDataSuccess {
    return {
        type: FETCH_ACCOUNT_COMPONENT_DATA_SUCCESS,
        payload: {
            componentData
        }
    };
}

export function getAccountComponentDataFailure(error: string): AccountComponentDataFailure {
    return {
        type: FETCH_ACCOUNT_COMPONENT_DATA_FAILURE,
        payload: { error }
    };
}

export function getAppVersionNumberRequest(): AppVersionNumberRequest {
    return {
        type: FETCH_APP_VERSION_NUMBER_REQUEST
    };
}

export function getAppVersionNumberSuccess(appVersionNumber: string): AppVersionNumberSuccess {
    return {
        type: FETCH_APP_VERSION_NUMBER_SUCCESS,
        payload: {
            appVersionNumber
        }
    };
}

export function getAppVersionNumberFailure(error: string): AppVersionNumberFailure {
    return {
        type: FETCH_APP_VERSION_NUMBER_FAILURE,
        payload: { error }
    };
}

export function loginTypeRequest(): LoginTypeRequest {
    return {
        type: LOGIN_TYPE_REQUEST
    };
}

export function loginTypeSuccess(loginType: string): LoginTypeSuccess {
    return {
        type: LOGIN_TYPE_SUCCESS,
        payload: {
            loginType
        }
    };
}

export function loginTypeFailure(error: string): LoginTypeFailure {
    return {
        type: LOGIN_TYPE_FAILURE,
        payload: { error }
    };
}

export function saveRoles(roles: string[]): SaveRoles {
    return {
        type: SAVE_ROLES,
        payload: { roles }
    };
}

export function setPortalType(portalType: string): SetPortalType {
    return {
        type: SET_PORTAL_TYPE,
        payload: { portalType }
    }
}

export function getCustomerSuccess(customer: string): GetCustomerSuccess {
    return {
        type: GET_CUSTOMER_SUCCESS,
        payload: { customer }
    }
}

export function getCustomerDefaultTerritoriesSuccess(defaultTerritories: any): GetCustomerDefaultTerritoriesSuccess {
    return {
        type: GET_CUSTOMER_DEFAULT_TERRITORIES_SUCCESS,
        payload: { defaultTerritories }
    }
}

export function getCustomerDateConfigurationSuccess(customerDate: string): GetCustomerDateConfigurationSuccess {
    return {
        type: GET_CUSTOMER_DATE_CONFIGURATION_SUCCESS,
        payload: { customerDate }
    }
}

export function getUserCRMValuesSuccess(currentUserNameNumber: string): GetUserCRMValuesSuccess {
    return {
        type: GET_USER_CRM_VALUES_SUCCESS,
        payload: { currentUserNameNumber }
    }
}


export function setCurrentUserNameNumber(nameNumber: string, userName:string): SetCurrentUserNameNumber {
    return {
        type: SET_CURRENT_USER_NAME_NUMBER,
        payload: { nameNumber, userName }
    }
}


const accountReducer = (
    state: IAccountState | undefined,
    action: ActionTypes
): IAccountState => {
    if (state === undefined) {
        return initialState;
    }
    switch (action.type) {
        case GET_USERS_SUCCESS: {
            const { users } = action.payload;
            return {
                ...state,
                users
            }
        }
        case LOGIN_SUCCESS: {
            const { username, currentUserNameNumber } = action.payload;
            return {
                ...state,
                currentUser: username,
                loginFailed: false,
                isSignedIn: true,
                currentUserNameNumber,
                
            }
        }
        case LOGIN_FAILURE: {
            return {
                ...state,
                loginFailed: true,
                isSignedIn: false
            }
        }
        case LOGOUT_SUCCESS: {
            return {
                ...state,
                currentUser: '',
                loginFailed: false,
                isSignedIn: false
            }
        }
        case FETCH_ACCOUNT_COMPONENT_DATA_SUCCESS: {
            const { componentData } = action.payload;
            return {
                ...state,
                componentData
            };
        }
        case FETCH_APP_VERSION_NUMBER_SUCCESS: {
            const { appVersionNumber } = action.payload;
            return {
                ...state,
                appVersionNumber
            };
        }
        case LOGIN_TYPE_SUCCESS: {
            const { loginType } = action.payload;
            return {
                ...state,
                loginType: LoginType[loginType]
            }
        }
        case SAVE_ROLES: {
            const { roles } = action.payload;

            return {
                ...state,
                roles
            }
        }
        case SET_PORTAL_TYPE: {
            const { portalType } = action.payload
            return {
                ...state,
                portalType
            }
        }
        case GET_CUSTOMER_SUCCESS: {
            const { customer } = action.payload;
            return {
                ...state,
                customer
            }
        }
        case GET_CUSTOMER_DEFAULT_TERRITORIES_SUCCESS: {
            const { defaultTerritories } = action.payload;
            return {
                ...state,
                defaultTerritories
            }
        }
        case GET_CUSTOMER_DATE_CONFIGURATION_SUCCESS: {
            const { customerDate } = action.payload;
            return {
                ...state,
                customerDate
            }
        }
        case GET_USER_CRM_VALUES_SUCCESS: {
            return {
                ...state,
                currentUserNameNumber: action.payload.currentUserNameNumber.toString()
            }
        }
        case SET_CURRENT_USER_NAME_NUMBER:{
            return {
                ...state,
                currentUserNameNumber: action.payload.nameNumber,
                currentViewAsUserName: action.payload.userName
            }
        }
        default:
            return state;
    }
}

export default accountReducer;