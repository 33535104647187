import * as React from "react";
import {ITriggerManualJob} from "../../../redux/types/ITriggerManualJob";
import {IAutomaticAgreementGHWMState} from "../../types/IAutomaticAgreementGHWMState";
import {Dropdown, IDropdownOption} from "office-ui-fabric-react/lib/Dropdown";
import TextDataInput from "../../../repertoire/components/textDataInput/TextDataInput";
import {AAG_JOB_TYPE, HIGH_WATER_MARK_KEY, HIGH_WATER_MARK_TITLE} from "../../Consts";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { getLookupValues } from '../../../lookup/services/LookupHelpers';
import { SUBMITTER_LOOKUP } from "../../../lookup/Consts";

export interface IAagHwmProps {
    setManualJobParameters : (parameters : ITriggerManualJob) => void;
    automaticAgreementGHWM: IAutomaticAgreementGHWMState[];
    lookups: ILookupDictionary;
}

interface IAagHwmState {
    hwm : Date;
    selectedOpenSubmitters: any[];
    submitters: any[];
}

export class AutomaticAgreementGHWM extends React.Component < IAagHwmProps,
IAagHwmState > {
    constructor(props : IAagHwmProps) {
        super(props);
        this.state = {
            hwm: null,
            selectedOpenSubmitters: [],
            submitters: getLookupValues(SUBMITTER_LOOKUP, this.props.lookups),
        };
    }

    renderIPIOptions = () => {
        const { submitters } = this.state;

        const options = [];
        options.push({key: "All", text: "All", data: "*"});
        if (submitters) {
            submitters.map((s) =>
                options.push({
                    data: s.extraFields.IPNameNumber,
                    text: s.description,
                    key: s.extraFields.IPNameNumber.toString(),
                })
            );
        } 
        return options;
    }

    changeHWM = (event : React.FormEvent < HTMLDivElement >, option?: IDropdownOption, index?: number) => {

        const {automaticAgreementGHWM, setManualJobParameters} = this.props;
        const { selectedOpenSubmitters, submitters } = this.state;
        let selectSubmitterState = selectedOpenSubmitters;
        let submitterOptions = this.renderIPIOptions();
        if (option.text === "All" && option.selected) {
            submitterOptions.forEach((option) => {
                selectSubmitterState.push(option.key.toString())
            });
        } else if (option.text === "All" && !option.selected) {
            selectSubmitterState = [];
        } else if (option.text !== "All" && !selectSubmitterState.includes(option.key.toString())) {
            selectSubmitterState.push(option.key.toString());
        } else {
            const index = selectSubmitterState.indexOf(option.key.toString());

            if (index > -1) {
                selectSubmitterState.splice(index, 1);
            }

            if (selectSubmitterState.includes(0)) {
                selectSubmitterState.splice(0, 1);
            }
        }

        const manualJobParameters : ITriggerManualJob = {
            jobType: AAG_JOB_TYPE,
            jobParameters: JSON.stringify(selectSubmitterState.length > 0 && selectSubmitterState[0] === "All"
                ? {
                    "IPINameNumbers": ["All"]
                }
                : {
                    "IPINameNumbers": selectSubmitterState
                })
        };
        setManualJobParameters(manualJobParameters);
        if (selectSubmitterState.length === 1 && selectSubmitterState[0] != "All") {
            const AAGHWM = submitters.find(
                (x) => x.extraFields.IPNameNumber.toString() === selectSubmitterState[0]
            );
            const hwmDate = AAGHWM
                ? AAGHWM.extraFields.AAGHighWaterMark
                : null;
            this.setState({hwm: hwmDate});
        }
        this.setState(() => ({selectedOpenSubmitters: selectSubmitterState}));

    }

    render() {
        const {hwm} = this.state;

        const onRenderPeriodDropdownTitle = (options : IDropdownOption[] | undefined) : JSX.Element => {
            const displayValue = options
                .filter(o => o.text !== 'All')
                .map(o => o.text)
                .join(", ");
            return (
                <div>
                    <span>{displayValue}</span>
                </div>
            );
        };
        return ( 
        <> 
            <div className="form-group col-md-6 col-sm-6 col-xs-12">
            <label>Submitter</label>
            <Dropdown
                selectedKeys={this.state.selectedOpenSubmitters}
                options={this.renderIPIOptions()}
                multiSelect={true}
                onChange={this.changeHWM}
                placeholder={""}
                disabled={false}
                required={true}
                onRenderTitle={onRenderPeriodDropdownTitle}/>
            </div> 
            < div className = "form-group col-md-6 col-sm-6 col-xs-12" > 
            <TextDataInput
                label={HIGH_WATER_MARK_TITLE}
                fieldName={HIGH_WATER_MARK_KEY}
                changeData={null}
                value={hwm}
                readOnly={true}
                isHidden={false}/> 
            </div>
        </>
        );
    }
}
