import IAppState from "../../redux/types/IAppState";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { addTab, createNewArtist, resetPagination, sortSearchResults, updatePagination } from "../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { showModal } from "../../redux/reducers/ModalReducer";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IArtistSearchQuery } from "../types/IArtistSearchQuery";
import { getArtistDetailsThunk, searchArtistsThunk } from "../../redux/thunks/RepertoireThunks";
import { DataSource } from "../types/DataSource";
import ArtistsPage from "./ArtistsPage";
import { saveRoles } from "../../redux/reducers/AccountReducer";

export default connect(
    (state: IAppState) => ({
        artistsPageData: state.repertoire.componentData.ArtistsPage,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        artistsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] === undefined ? undefined : state.repertoire.tabs[state.repertoire.activeTab].artistsSearchResult,
        searchSuccessful: state.repertoire.searchSuccessful,
        artistGeneralDataView: state.repertoire.componentData.ArtistMaintenanceGeneralDataView,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'artists')[0].indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'artists')[0].indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'artists')[0].resultsPerSection,
        currentPage: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].currentPage ? state.repertoire.tabs[state.repertoire.activeTab].currentPage : undefined,
        roles: state.account.roles,
    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        searchArtists: (query: IArtistSearchQuery) =>
            dispatch<any>(searchArtistsThunk(query, DataSource.Repertoire)),
        getArtistDetails: (performerID: number, artistMaintenanceGeneralViewData?: IRepertoireComponentDataItem) =>
            dispatch<any>(getArtistDetailsThunk(performerID, artistMaintenanceGeneralViewData)),
        addNewArtist: (lookupValues: ILookupDictionary, isNew?: boolean, artistMaintenanceGeneralViewData?: IRepertoireComponentDataItem) => dispatch(createNewArtist(lookupValues, isNew, artistMaintenanceGeneralViewData)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
    })
)(ArtistsPage);

