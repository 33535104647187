import * as React from "react";
import { WideActionButton } from "../../actionButton/ActionButton";
import { updateTerritorySelector } from "../../../../redux/reducers/RepertoireReducer";
import { READ_ONLY_INPUT, REPERTOIRE_PAGE_FORM_ITEM_SIZE, TEXT_INPUT } from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IDistribution } from "../../../types/usageTypes/IDistribution";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IDropdownOption } from "office-ui-fabric-react";
import { TERRITORY_LOOKUP } from "../../../../lookup/Consts";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { IIPRepresentation } from "../../../types/IIPRepresentation";
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { SizedRadiobuttonDataInput } from "../../radioButtonDataInput/RadiobuttonDataInput";
import { ComponentsHelper } from "../../../../core/services/ComponentHelper";
import TextDataInput from "../../textDataInput/TextDataInput";

export interface ITerritorySelectionViewProps {
    interestedPartyRepresentation: IIPRepresentation;
    hideModal: () => void;
    updateTerritorySelector: typeof updateTerritorySelector;
    dataGridTableData: IRepertoireComponentDataItem,
    lookupValues: ILookupDictionary;
    isReadOnly?: boolean;
    incrementTempID?: () => void;
    title?: string;
    modalIndex?: number;

}

interface ITerritorySelectionViewState {
    representation: IIPRepresentation;
    setCountry: any[];
    setRegion: any[];
    territoryCodes: any[];
    isCountryEnabled: boolean;
    isRegionEnabled: boolean;
}


export default class TerritorySelectionView extends React.PureComponent<ITerritorySelectionViewProps, ITerritorySelectionViewState> {

    constructor(props: ITerritorySelectionViewProps) {
        super(props);
        this.state = {
            representation: this.props.interestedPartyRepresentation,
            setCountry: [],
            setRegion: [],
            territoryCodes: [],
            isCountryEnabled: true,
            isRegionEnabled: false

        }
    }

    componentDidMount() {
        const { interestedPartyRepresentation, lookupValues, title } = this.props;
        let updatedTerritoryCodes = [];
        let updatedTerritoryList = [];
        let updatedCountries = [];
        let updatedRegion = [];


        if (interestedPartyRepresentation) {
            if (title === 'Territory Include') {
                updatedTerritoryCodes = interestedPartyRepresentation.includeTerritories.value.split(",");
            } else {
                updatedTerritoryCodes = interestedPartyRepresentation.excludeTerritories.value.split(",");
            }

            if (!updatedTerritoryCodes.includes('')) {
                const lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookupValues);
                let startsWithTwo = [];
                let startsWithoutTwo = [];

                updatedTerritoryCodes.map(x => {
                    updatedTerritoryList.push((lookupValsTerritories.find(item => item.code === x)).description)
                })
                startsWithTwo = updatedTerritoryCodes.filter(item => item.startsWith('2'));
                startsWithoutTwo = updatedTerritoryCodes.filter(item => !item.startsWith('2'));
                if (startsWithoutTwo.length > 0) {
                    updatedTerritoryCodes.filter(item => !item.startsWith('2')).map(x => updatedCountries.push(lookupValsTerritories.find(item => item.code === x).typeID));
                }
                if (startsWithTwo.length > 0) {
                    updatedTerritoryCodes.filter(item => item.startsWith('2')).map(x => updatedRegion.push(lookupValsTerritories.find(item => item.code === x).typeID))
                }
                this.setState({ setCountry: updatedCountries, setRegion: updatedRegion, territoryCodes: updatedTerritoryList as any })

            }

        }
    }

    updateTerritorySelectorState = (value: any, field: string) => {
        switch (field) {

            case "Country":
                this.setState({
                    isCountryEnabled: true,
                    isRegionEnabled: false
                });
                break;
            case "Region":
                this.setState({
                    isRegionEnabled: true,
                    isCountryEnabled: false
                });
                break;
        }
    };

    multiSelectChangeRegionData = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        const { setCountry, setRegion, isCountryEnabled, isRegionEnabled, territoryCodes } = this.state;

        const { lookupValues } = this.props;
        let countriesOptions = [];
        let regionOptions = [];
        const lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookupValues);
        if (lookupValsTerritories) {
            lookupValsTerritories.filter(item => !item.code.startsWith('2')).map(x => countriesOptions.push({ key: x.typeID, text: x.description, data: x.code }));
            lookupValsTerritories.filter(item => item.code.startsWith('2')).map(x => regionOptions.push({ key: x.typeID, text: x.description, data: x.code }));
        }

        let result = [];

        let countryNames = [] as any;
        let newArr;
        if (isCountryEnabled) {

            if (setCountry.length) {
                result = [...new Set(setCountry)].map(x => { return x })
            }
            if (!result.includes(option.key)) {
                result.push(option.key)
                result.map(x => countryNames.push(countriesOptions.find(item => item.key === x).text))
                newArr = territoryCodes.concat(countryNames) as any
            }
            else {
                const index = result.indexOf(option.key);
                if (index > -1) {
                    result.splice(index, 1);
                }
                result.map(x => countryNames.push(countriesOptions.find(item => item.key === x).text))
                newArr = territoryCodes.filter(x => x !== option.text)
            }
            newArr = [...new Set(newArr)]
            this.setState({ setCountry: result, territoryCodes: newArr })
        } else {

            if (setRegion.length) {
                result = [...new Set(setRegion)].map(x => { return x })
            }
            if (!result.includes(option.key)) {
                result.push(option.key)
                result.map(x => countryNames.push((regionOptions.find(item => item.key === x)).text))
                newArr = territoryCodes.concat(countryNames) as any
            }
            else {
                const index = result.indexOf(option.key);
                if (index > -1) {
                    result.splice(index, 1);
                }
                result.map(x => countryNames.push((regionOptions.find(item => item.key === x)).text))
                newArr = territoryCodes.filter(x => x !== option.text)
            }

            newArr = territoryCodes.concat(countryNames) as any
            newArr = [...new Set(newArr)]
            this.setState({ setRegion: result, territoryCodes: newArr })
        }


    }



    render() {
        const { representation, isCountryEnabled, isRegionEnabled, setRegion, setCountry, territoryCodes } = this.state;
        const { hideModal, isReadOnly, lookupValues, interestedPartyRepresentation, updateTerritorySelector, title, modalIndex } = this.props;
        let buttonSaveAction = () => {
            const lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookupValues);
            if (interestedPartyRepresentation) {
                let code = [] as any;
                let repId;
                if (interestedPartyRepresentation.representationID) {
                    repId = interestedPartyRepresentation.representationID;
                } else {
                    repId = modalIndex;
                }
                territoryCodes.map(x => code.push((lookupValsTerritories.find(item => item.description === x)).code))
                if (title === 'Territory Include') {
                    let resultRep: IIPRepresentation = {
                        ...representation,
                        includeTerritories: { value: (code.join(",")), inputType: TEXT_INPUT }
                    }
                    updateTerritorySelector(repId, resultRep);
                    hideModal();
                } else {
                    let resultRep: IIPRepresentation = {
                        ...representation,
                        excludeTerritories: { value: (code.join(",")), inputType: TEXT_INPUT }
                    }
                    updateTerritorySelector(repId, resultRep);
                    hideModal();
                }

            }


        };
        let buttonCloseAction = () => {
            hideModal();
        };


        let countriesOptions = [];
        let regionOptions = [];

        const lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookupValues);
        if (lookupValsTerritories) {
            lookupValsTerritories.filter(item => !item.code.startsWith('2')).map(x => countriesOptions.push({ key: x.typeID, text: x.description, data: x.code }));
            lookupValsTerritories.filter(item => item.code.startsWith('2')).map(x => regionOptions.push({ key: x.typeID, text: x.description, data: x.code }));
        }
        let buttonDeselectAction = (value: string) => {

            let updatedRegion = []
            let updatedCountries = [];
            if (territoryCodes.filter(item => item !== value)) {

                if (!territoryCodes.filter(item => item !== value).includes(' ')) {
                    const lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookupValues);
                    territoryCodes.filter(item => item !== value).map(item => {
                        let result = lookupValsTerritories.find(x => item === x.description).code
                        if (result.startsWith('2')) {
                            updatedRegion.push(lookupValsTerritories.find(x => item === x.description).typeID)
                        } else {
                            updatedCountries.push(lookupValsTerritories.find(x => item === x.description).typeID)
                        }
                    })

                    this.setState({ setCountry: updatedCountries, setRegion: updatedRegion, territoryCodes: territoryCodes.filter(item => item !== value) })

                }

            }
        };
        if (representation) {

            return (
                <div className="modal-dialog settingsModal" style={{ width: 952 }}>
                    <div className="modal-content">
                        <div style={{ height: 'auto', background: 'white' }}>

                            <div className='modal-header' style={{
                                background: "#38429C",
                                color: "white",
                            }}>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => hideModal()}>
                                    <i className="icon ms-Icon ms-Icon--ChromeClose" style={{ color: "white" }} title="Close"></i>
                                </button>
                                <h1 className="modal-title">{title}</h1>
                            </div>
                            <div style={{ padding: 15 }}>


                                <div style={{ fontWeight: 600, fontSize: 15 }}>Filter by:</div>
                                <div className="row">


                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: 15, top: 0 }}>
                                        <SizedRadiobuttonDataInput
                                            onSelectionChange={(value) => this.updateTerritorySelectorState(value, "Country")}
                                            disabled={false}
                                            radioName=""
                                            title=""
                                            options={[
                                                { desc: "Country", code: 'C' }
                                            ]}
                                            selectedValue={isCountryEnabled ?
                                                'C' :
                                                ''} />
                                        <SizedRadiobuttonDataInput
                                            onSelectionChange={(value) => this.updateTerritorySelectorState(value, "Region")}
                                            disabled={false}
                                            radioName=""
                                            title=""
                                            options={[
                                                { desc: "Region", code: 'R' }
                                            ]}
                                            selectedValue={isRegionEnabled ?
                                                'R' :
                                                ''} />
                                    </div>


                                    {isCountryEnabled && countriesOptions && <>
                                        <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                                            <div style={{ height: '100', position: 'relative', width: 920, overflow: 'hidden' }}>
                                                <Dropdown
                                                    label={"Country"}
                                                    selectedKeys={setCountry}
                                                    options={countriesOptions}
                                                    multiSelect={true}
                                                    onChange={this.multiSelectChangeRegionData}
                                                    placeholder={"No Countries selected."}
                                                    styles={{
                                                        title: { height: 40, backgroundColor: "WhiteSmoke", borderWidth: 1, borderColor: "LightGray", padding: 4, width: 400 },
                                                        dropdownItems: { height: 200 }

                                                    }}
                                                    disabled={false}
                                                    dropdownWidth={920} />
                                            </div>
                                        </div></>}
                                    {isRegionEnabled && <>
                                        <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                                            <div style={{ height: '100', position: 'relative', width: 920 }}>
                                                <Dropdown
                                                    label={"Region"}
                                                    selectedKeys={setRegion ? setRegion as any : getLookupValues(TERRITORY_LOOKUP, lookupValues)}
                                                    options={regionOptions}
                                                    multiSelect={true}
                                                    onChange={this.multiSelectChangeRegionData}
                                                    placeholder={"No Region selected."}
                                                    styles={{
                                                        title: { height: 40, backgroundColor: "red", borderWidth: 1, borderColor: "LightGray", padding: 4, width: 400 },
                                                        dropdownItems: { height: 200 }

                                                    }}
                                                    disabled={false}
                                                    dropdownWidth={920}
                                                />
                                            </div>
                                        </div>
                                    </>}

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className="selected-item-container">
                                        <div style={{ fontSize: 13, fontWeight: 600 }}>Selected items</div>
                                        <hr style={{ borderBottom: 2 }}></hr>
                                        <div style={{height:50,overflowY:'scroll'}}>
                                        <div className="selected-items-list">
                                            {territoryCodes && territoryCodes.map((x, index) => {
                                                return <div className="selected-item">
                                                    <span>{x}</span> &nbsp;&nbsp;
                                                    <span className="close-icon" onClick={() => buttonDeselectAction(x)}>&times;</span>
                                                </div>
                                            })}

                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ borderBottom: 2 }}></hr>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <WideActionButton
                                        buttonText="Cancel"
                                        buttonAction={buttonCloseAction}
                                        disabled={isReadOnly}
                                        className="button btn-defaultSecondary" />
                                    <WideActionButton
                                        buttonText="Save"
                                        buttonAction={buttonSaveAction}
                                        disabled={isReadOnly} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            );
        }
        else return <div></div>
    }



}