import { connect } from "react-redux";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { addFileToUpload, CleanUploadFilesState, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import { hideModal, showMessage, showModal, showUpdateFieldsModal, showWorkSubmission, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import {
    addTab,
    closeAccordion,
    createNewWork,
    openAccordion,
    setChangesMade,
    sortWorkFieldData,
    switchSetTypeGroup,
    updateWorkField,
    updateContributorSetType,
    resetMessageBanner,
    setEditableFields,
    updateEditableFields,
    enableCustomField,
    addRepertoireAttachmentSuccess,
    addDroppedFile,
    setProgressUploadFileRepertoireProccess,
    cleanDroppedFilesRepertoireState,
    updateSetTypeOnBreak,
    nextWorkflowInSession,
    setWarningMessage,
    createNewAccount,
    expandMatchingWork,
    autoMatchWork,
    incrementTempID,
    updateWorkFields,
    setValidationWarningMessage,
} from "../../../../redux/reducers/RepertoireReducer";
import { getDataIngestionComponentDataThunk, getFilesystemsThunk, refreshDirectoryThunk, selectedNodeChangedThunk } from "../../../../redux/thunks/DataIngestionThunks";
import { matchWorkBatchThunk, postWorkBatchThunk } from "../../../../redux/thunks/JobThunks";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import {
    cancelWorkflowSessionThunk,
    copyExistingWorkThunk, deleteWorkThunk,
    getAgreementDetailsThunk, getContributorDisplaySettingsThunk, getFieldFormatsThunk, getGenreCategoriesThunk, getIPDetailsThunk, getOtherIndicatorsWorkFlagTypesThunk, getProductDetailsThunk, getReadonlyFlagsFieldThunk, getReadonlyIndicatorsWorkFlagTypesThunk, getWorkDetailsThunk, mergeWorkThunk, refreshWorkDetailsThunk,
    removeWorkAttachmentIfExistsThunk, saveWorkflowThunk, saveWorkThunk,
    searchWorkVersionHistoryThunk, skipUsageWorkflowInSessionThunk, skipWorkflowInSessionThunk, undoWorkChangesThunk, updateComponentFieldItemsThunk, updateUserPreferencesThunk, getGenreCategoriesByDomOrForeignThunk, getCommentCategoryFlagThunk, saveAdditionalWorkNumbersThunk, getCurrentPageDetailsThunk, searchWorksThunk, getFilterGenreCategoriesThunk,getRecalculateDomesticorForeignThunk,
    getPDArrangementMusicLyricOptions,
    AddtoPackageThunk

} from "../../../../redux/thunks/RepertoireThunks";
import { FormatFields } from "../../../../redux/types/FormatFields";
import IAppState from "../../../../redux/types/IAppState";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import IFile from "../../../../redux/types/IFiles";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IWorkState, IWorkStateKeys } from "../../../../redux/types/IWorkState";
import { ITreeNodeData } from "../../../../treeview/types/TreeNodeData";
import { IPopUpInfoProps } from "../../../components/modalViews/popUpInfo/PopUpInfo";
import { ISubmissionPromptViewModalProps } from "../../../components/modalViews/workSubmission/WorkSubmission";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { DataSource } from "../../../types/DataSource";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IWorkflowSearchResult } from "../../../types/IWorkflowSearchResult";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { ManualMergeOptions } from "../../../types/ManualMergeOptions";
import { SaveOption } from "../../../types/SaveOption";
import { ITreeData } from "../../../types/ITreeData";
import { WORK_WORKFLOW } from "../../../Consts";
import { IWorkflowParams } from "../../../types/IWorkFlowParams";
import { IWorksSearchResult } from "../../../types/IWorksSearchResult";
import { IWorksSearchQuery } from "../../../types/IWorksSearchQuery";
import WorkMaintenancePageScrollListener from "./WorkMaintenancePageScrollListener";

export default connect(
    (state: IAppState) => ({
        workMaintenanceGeneralDataViewData:
            state.repertoire.componentData.WorkMaintenanceGeneralDataView,
        workMaintenanceGridsViewData:
            state.repertoire.componentData.WorkMaintenanceGridsView,
        workMaintenanceAccordionViewData:
            state.repertoire.componentData.WorkMaintenanceAccordionView,
        workMaintenanceToolbarData:
            state.repertoire.componentData.WorkMaintenanceToolbar,
        workMaintenanceSaveResultData:
            state.repertoire.componentData.SaveResult,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        contributorsDataViewData:
            state.repertoire.componentData.ContributorsDataView,
        workMetadataAccordionViewData:
            state.repertoire.componentData.WorkMetadataAccordionView,
        isFetchingWork: state.repertoire.isFetchingWork,
        lookupValues: state.lookupEntities,
        activeTab: state.repertoire.activeTab,
        tabs: state.repertoire.tabs,
        saveInProgress: state.repertoire.saveInProgress,
        loaded: state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState!.loaded,
        dataSource: state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState!.work && state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState!.work!.dataSource,
        work: state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState!.work,
        activeAccordion: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].activeAccordions,
        workSubmissionDetails: state.repertoire.componentData.WorkSubmissionDetails,
        currentUser: state.account.currentUser,
        contributorDisplaySettings: state.repertoire.contributorDisplaySettings,
        readonlyFlagsField: state.repertoire.readonlyFlagsField,
        otherIndicatorsWorkFlagTypes: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].otherIndicatorWorkFlagTypes,
        readonlyIndicatorsWorkFlagTypes: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].readonlyIndicatorWorkFlagTypes,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        ipMaintenanceGeneralDataView: state.repertoire.componentData.IPMaintenanceGeneralDataView,
        agreementMaintenanceGeneralDataView: state.repertoire.componentData.AgreementMaintenanceGeneralDataView,
        productMaintenanceGeneralDataView: state.repertoire.componentData.ProductMaintenanceGeneralDataView,
        formatFields: state.repertoire.tabs[state.repertoire.activeTab].formatFields,
        containerDetailsWindowComponentData: state.dataIngestion.componentData.ContainerDetailsWindow,
        genreCategories: state.repertoire.genreCategories,
        storagePathItemData: state.dataIngestion.componentData.StoragePathItem,
        distributions: state.dataIngestion.distributions,
        formats: state.dataIngestion.formats,
        storagePaths: state.dataIngestion.storagePaths,
        selectedFileSystem: state.dataIngestion.selectedFileSystem,
        destinations: state.dataIngestion.destinations && state.dataIngestion.destinations.lookups,
        droppedFiles: state.dataIngestion.droppedFiles,
        isDropZone: state.dataIngestion.droppedFiles.inDropZone,
        customFields: state.repertoire.tabs[state.repertoire.activeTab].customFields,
        filesystems: state.dataIngestion.filesystems,
        treeNodes: state.dataIngestion.treeNodes,
        selectedNode: state.dataIngestion.selectedNode,
        attachedFiles: state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState!.work && state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState!.work.files,
        workAttachmentsDropped: state.repertoire.tabs && state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].droppedFiles,
        editableContributorFieldGridDataView: state.repertoire.componentData.EditableGridFieldsView,
        workflowFieldsData: state.repertoire.componentData.WorkflowFieldsData,
        agreementShareDisplayOptions: state.repertoire.agreementShareDisplayOptions,
        shareDecimalsToDisplay: state.repertoire.shareDecimalsToDisplay,
        isWorkflowSessionStarted: state.repertoire.isWorkflowSessionStarted,
        workflowSessionItems: state.repertoire.workflowSessionItems,
        dataActionList: state.repertoire.tabs[state.repertoire.activeTab].dataActionToolbar,
        workFormats: state.repertoire.tabs[state.repertoire.activeTab].formatFields,
        currentWorkflowIndex: state.repertoire.currentWorkflowIndex,
        isOpenForWorkflowSession: state.repertoire.tabs[state.repertoire.activeTab].isEntityForWorkflowSession,
        shareToleranceValues: state.repertoire.shareToleranceValues,
        editableFields: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].editableFields,
        containerWorks: state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState!.work && state.repertoire.tabs[state.repertoire.activeTab].workMaintenanceState!.work.parentWorks,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        sources: state.repertoire.matchingSourceTreeData,
        paginationDetails: state.dataIngestion.paginationDetails,
        expandedWorkResults: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].expandedWorkResults,
        genreCategoriesByDomOrInt: state.repertoire.genreCategoriesByDomOrInt,
        activeAccordian: state.repertoire.tabs[state.repertoire.activeTab].activeAccordions,
        roles: state.account.roles,
        useNewUIWorkMaintenanceGeneralView: state.repertoire.useNewUIWorkMaintenanceGeneralView,
        commentCategoryFlag: state.repertoire.commentCategoryFlag,
        customer:state.account.customer,
        worksSearchResults: state.repertoire.tabs[state.repertoire.activeTab].worksSearchResult,
        excludeSourceTypePickShowIP: state.repertoire.excludeSourceTypePickShowIP,
        filterGenreCategories: state.repertoire.filterGenreCategories,
        pdArrangementMusicLyric: state.repertoire.pdArrangementMusicLyric,
        recalculateDomesticorForeign:state.repertoire.recalculateDomesticorForeign
    }),
    dispatch => ({
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string,
            modalMessage?: string,
            isReadOnly?: boolean,
            key?: string,
            index?:number
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title,modalMessage,isReadOnly,key,index)
            ),
        getPDArrangementMusicLyricOptions: () => dispatch<any>(getPDArrangementMusicLyricOptions()),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps, message?: string) => dispatch(showYesNoPrompt(title, props, message)),
        showMessage: (props: IPopUpInfoProps) => dispatch(showMessage(props)),
        hideModal: () => dispatch(hideModal()),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        openWorkAccordian: (accordionName: string, componentName: string) =>
            dispatch(openAccordion(accordionName, componentName)),
        closeWorkAccordian: (accordionName: string, componentName: string) =>
            dispatch(closeAccordion(accordionName, componentName)),
        saveChanges: (updatedWork: IWorkState, 
            saveOption: SaveOption, 
            lookups: ILookupDictionary, 
            otherIndicatorsWorkFlagTypes: string[], 
            dataActions: IDataActionToolbar[],
            readonlyIndicatorsWorkFlagTypes: string[],
            isWorkflowSession: boolean = false,
            workflowsSessionItems?: IWorkflowSearchResult[],
            currentWorkflowIndex?: number,
            activeTab?: number,
            formats?: FormatFields[],
            workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, 
            isOpenForWorkflowSession?: boolean,
            customer?: string,
            worksSearchResults?: IWorksSearchResult[]) =>
            dispatch<any>(saveWorkThunk(updatedWork, saveOption, lookups, otherIndicatorsWorkFlagTypes, dataActions, isWorkflowSession, readonlyIndicatorsWorkFlagTypes, workflowsSessionItems, currentWorkflowIndex, activeTab, formats, workMaintenanceGeneralDataViewData, isOpenForWorkflowSession,customer,worksSearchResults)),
        undoChanges: (dataSource: string, workID: number, lookups: ILookupDictionary) => dispatch<any>(undoWorkChangesThunk(dataSource, workID, lookups)),
        addNewWork: (lookups: ILookupDictionary, actionList: IDataActionToolbar[], isNew?: boolean, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[]) => dispatch(createNewWork(lookups, actionList, isNew, workMaintenanceGeneralDataView, formats)),
        addNewAccount: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralDataView?: IRepertoireComponentDataItem) =>
            dispatch<any>(createNewAccount(lookupValues, isNew, ipMaintenanceGeneralDataView)),
        copyExistingWork: (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary) => dispatch<any>(copyExistingWorkThunk(actionList, userName, lookups)),
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        deleteWork: (dataSource: DataSource, workId: number, activeTab: number) => dispatch<any>(deleteWorkThunk(dataSource, workId, activeTab)),
        setChangesMade: (tabIndex: number, changesMade: boolean) => dispatch(setChangesMade(tabIndex, changesMade)),
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        updateWorkField: (value: any, fieldName: IWorkStateKeys, index?: number) => dispatch(updateWorkField(value, fieldName, index)),
        updateWorkFields: (updatedWork: any) => dispatch(updateWorkFields(updatedWork)),
        sortWorkFieldData: (value: any, fieldName: IWorkStateKeys) => dispatch(sortWorkFieldData(value, fieldName)),
        postWorkBatch: (workBatchID: number) => dispatch<any>(postWorkBatchThunk(workBatchID)),
        matchWorkBatch: (workBatchID: number) => dispatch<any>(matchWorkBatchThunk(workBatchID)),
        getAgreementDetails: (dataSource: string, agreementID: number, lookups: ILookupDictionary, dataActions: IDataActionToolbar[], agreementGeneralDataView: IRepertoireComponentDataItem) =>
            dispatch<any>(getAgreementDetailsThunk(dataSource, agreementID, lookups, dataActions)),
        switchSetTypeGroup: (setTypeGroup: string) => dispatch<any>(switchSetTypeGroup(setTypeGroup)),
        updateContributorSetType: (setType: string, inheritFrom: string) => dispatch<any>(updateContributorSetType(setType, inheritFrom)),
        searchWorkVersionHistory: (workId: number, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[], otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataSource: DataSource) => dispatch<any>(searchWorkVersionHistoryThunk(workId, lookups, actionList, formats, otherIndicatorsWorkFlagTypes, readonlyIndicatorsWorkFlagTypes, dataSource)),
        getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataActions: IDataActionToolbar[], workMaintenanceViewData: IRepertoireComponentDataItem, formats: FormatFields[], openEntityForWorflowSession?: boolean) =>
            dispatch<any>(getWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceViewData, formats, readonlyIndicatorsWorkFlagTypes, openEntityForWorflowSession)),
        getProductDetails: (dataSource: string, productCoreID: number, productMaintenanceGeneralDataView: IRepertoireComponentDataItem, formats: FormatFields[]) =>
            dispatch<any>(getProductDetailsThunk(dataSource, productCoreID, productMaintenanceGeneralDataView, formats)),
        refreshWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], activeTabItem: ITabReduxItem, readonlyIndicatorsWorkFlagTypes: string[], formats: FormatFields[]) =>
            dispatch<any>(refreshWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, activeTabItem, formats, readonlyIndicatorsWorkFlagTypes)),
        getIPDetails: (ipBaseNumber: string, accountNumber: string, ipMaintenanceGeneralDataView: IRepertoireComponentDataItem) =>
            dispatch<any>(getIPDetailsThunk(ipBaseNumber, accountNumber, ipMaintenanceGeneralDataView)),
        showWorkSubmissionDetail: (submission: string, props: ISubmissionPromptViewModalProps) =>
            dispatch<any>(showWorkSubmission(submission, props)),
        manualMergeWork: (work: IWorkState, matchingWorkIds: number, manualMergeOption: ManualMergeOptions, lookups: ILookupDictionary, dataActions: IDataActionToolbar[], mergeIntoMatch: boolean,customer: string) =>
            dispatch<any>(mergeWorkThunk(work, matchingWorkIds, manualMergeOption, lookups, dataActions, mergeIntoMatch,customer)),
        getContributorDisplaySettings: () => dispatch<any>(getContributorDisplaySettingsThunk()),
        getGenreCategories: () => dispatch<any>(getGenreCategoriesThunk()),
        getReadonlyFlagsField: () => dispatch<any>(getReadonlyFlagsFieldThunk()),
        getOtherIndicatorsWorkFlagTypes: () => dispatch<any>(getOtherIndicatorsWorkFlagTypesThunk()),
        getReadonlyIndicatorsWorkFlagTypes: () => dispatch<any>(getReadonlyIndicatorsWorkFlagTypesThunk()),
        setEditableFields: (componentFieldName: string, componentInstance: string) =>
            dispatch<any>(setEditableFields(componentFieldName, componentInstance)),
        updateEditableField: (value: any, key: string) => dispatch(updateEditableFields(value, key)),
        showUpdateFieldsModal: (tittle: string, props: IShowFieldSettingViewModalProps) => dispatch<any>(showUpdateFieldsModal(tittle, props)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        getFieldFormats: (key: string, group: string) => dispatch<any>(getFieldFormatsThunk(key, group)),
        setInDropZone: (inDropZone: boolean) => dispatch(setInDropZone(inDropZone)),
        refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => dispatch<any>(refreshDirectoryThunk(filesystem, sources)),
        addFileToUpload: (file: IFile, isComplete: boolean) => dispatch<any>(addFileToUpload(file, isComplete)),
        setProgressUploadFileProccess: (message: string, file: IFile, isUploadComplete: boolean) => dispatch<any>(setProgressUploadFileProccess(message, file, isUploadComplete)),
        sortColumn: (section: string, ascending: boolean) => dispatch<any>(sortColumn(section, ascending)),
        cleanUploadFilesState: () => dispatch(CleanUploadFilesState()),
        enableCustomField: (index: number) => dispatch<any>(enableCustomField(index)),
        getFilesystems: (isRepertoireModule?: boolean) => dispatch<any>(getFilesystemsThunk(isRepertoireModule)),
        selectedNodeChanged: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => dispatch<any>(selectedNodeChangedThunk(node, sources)),
        getDataIngestionComponentData: () => dispatch<any>(getDataIngestionComponentDataThunk()),
        addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => dispatch<any>(addRepertoireAttachmentSuccess(attachedFile)),
        removeWorkAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => dispatch<any>(removeWorkAttachmentIfExistsThunk(attachedFile, source)),
        addDroppedFileRepertoire: (file: IFile, inProgress: boolean) => dispatch(addDroppedFile(file, inProgress)),
        setProgressUploadFileRepertoire: (message: string, file: IFile, isUploadComplete: boolean) => dispatch(setProgressUploadFileRepertoireProccess(message, file, isUploadComplete)),
        cleanDroppedFilesRepertoire: () => dispatch(cleanDroppedFilesRepertoireState()),
        updateSetTypeOnBreak: (setType: string) => dispatch<any>(updateSetTypeOnBreak(setType)),
        nextWorkflowItem: () => dispatch(nextWorkflowInSession()),
        cancelWorkflowSession: (activeTab: number) => dispatch<any>(cancelWorkflowSessionThunk(activeTab)),
        skipWorkflowItem: (activeTab: number
            , currentWorkflowIndex: number
            , workflows: IWorkflowSearchResult[]
            , lookups: ILookupDictionary
            , otherIndicatorsWorkFlagTypes: string[]
            , dataActions: IDataActionToolbar[] = []
            , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
            , formats: FormatFields[],
            readonlyIndicatorsWorkFlagTypes: string[]) => dispatch<any>(skipWorkflowInSessionThunk(activeTab, currentWorkflowIndex, workflows, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes)),
        setWarningMessage: (perfWarning: boolean, mechWarning: boolean) => dispatch(setWarningMessage(perfWarning, mechWarning)),
        setValidationWarningMessage: (displayWarning: boolean) => dispatch(setValidationWarningMessage(displayWarning)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        skipUsageWorkflowItem: (activeTab: number
            , currentWorkflowIndex: number
            , workflows: IWorkflowSearchResult[]) => dispatch<any>(skipUsageWorkflowInSessionThunk(activeTab, currentWorkflowIndex, workflows)),
        updatePagination: (resultsPerPage: number, currentPage: number, section: string) =>
            dispatch<any>(updatePagination(resultsPerPage, currentPage, section)),
        expandMatchingWork: (index: number) => dispatch<any>(expandMatchingWork(index)),
        saveWorkflow: (workflowparams: IWorkflowParams) =>
            dispatch<any>(saveWorkflowThunk(workflowparams, WORK_WORKFLOW)),
        getGenreCategoriesByDomOrInt: () => dispatch<any>(getGenreCategoriesByDomOrForeignThunk()),
        autoMatch: (value: any, fieldName: IWorkStateKeys) => dispatch<any>(autoMatchWork(value, fieldName)),
        incrementTempID: () => dispatch<any>(incrementTempID()),
        saveAdditionalWorkNumbers: (work: IWorkState, lookups: ILookupDictionary, pageNumber: number, customer: string) => dispatch<any>(saveAdditionalWorkNumbersThunk(work, lookups, customer, pageNumber)),
        getCurrentPageDetails: (work: IWorkState, lookups: ILookupDictionary, pageNumber: number) => dispatch<any>(getCurrentPageDetailsThunk(work, lookups, pageNumber)),
        searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) =>
            dispatch<any>(searchWorksThunk(query, false)),
        getFilterGenreCategories: () => dispatch<any>(getFilterGenreCategoriesThunk()),
        getRecalculateDomesticorForeignflag:()=> dispatch<any>(getRecalculateDomesticorForeignThunk()),
        addToPackages:( userName:string,entityType:string,entityID:number)=>dispatch<any>(AddtoPackageThunk(userName,entityType,entityID)),
    })
)(WorkMaintenancePageScrollListener);