import { connect } from "react-redux";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import { createNewLicense, resetMessageBanner, updateLicenseField, copyExistingLicense, updatePagination, addWorkToLicenseRequest, updateLicenseRequestWorkItem, importLicenseWorksPrices } from "../../../../redux/reducers/RepertoireReducer";
import { calculateLicenseWorksPricesThunk, deleteLicenseThunk, getLicenseRequestWorkParametersThunk, getLicenseWorksContributorsThunk, getShoppingCartParameters, saveLicenseThunk, searchLicenseUserThunk, submitLicenseToCRMThunk, getWorkDetailsThunk, undoLicenseChangesThunk } from "../../../../redux/thunks/RepertoireThunks";
import IAppState from "../../../../redux/types/IAppState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { ITreeData } from "../../../types/ITreeData";
import { ILicenseUserSearchQuery } from "../../../types/ILicenseUserSearchQuery";
import { IWorksSearchResult } from "../../../types/IWorksSearchResult";
import { ILicenseRequestWorkItem } from "../../../../redux/types/ILicenseRequestWorkItem";
import { ILicenseStateKeys, IShoppingCartState } from "../../../../redux/types/IShoppingCartState";
import { ILicenseInputItem } from "../../../../redux/types/ILicenseInputItem";
import { ILicenseRequestItem } from "../../../../redux/types/ILicenseRequestItem";
import { FormatFields } from "../../../../redux/types/FormatFields";
import LicenseMaintenancePageScrollListener from "./LicenseMaintenancePageScrollListener";

export default connect(
    (state: IAppState) => ({
        licenseMaintenanceGeneralDataViewData: state.repertoire.componentData.LicenseMaintenanceGeneralDataView,
        licenseMaintenanceToolbarData: state.repertoire.componentData.LicenseMaintenanceToolbar,
        licenseMaintenanceGridsViewData: state.repertoire.componentData.LicenseMaintenanceGridsViewData,
        licenseMaintenanceSaveResultData: state.repertoire.componentData.SaveResult,
        tabs: state.repertoire.tabs,
        activeTab: state.repertoire.activeTab,
        licenseCode: state.repertoire.tabs[state.repertoire.activeTab].licenseMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].licenseMaintenanceState.licenseCode,
        lookupValues: state.lookupEntities,
        sources: state.repertoire.matchingSourceTreeData,
        shoppingCart: state.repertoire.tabs[state.repertoire.activeTab].licenseMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].licenseMaintenanceState.shoppingCart,
        searchResultTableData: state.repertoire.componentData.SearchResultsTable,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        shoppingCartParameters: state.repertoire.tabs[state.repertoire.activeTab].licenseMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].licenseMaintenanceState.shoppingCartParameters,
        crmUsers: state.repertoire.tabs[state.repertoire.activeTab].licenseMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].licenseMaintenanceState.crmUsers,
        licenseRequestWorkParameterFields: state.repertoire.tabs[state.repertoire.activeTab].licenseMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].licenseMaintenanceState.licenseRequestWorkParameter,
        bookPriceCalculationsJobInProgress: state.repertoire.tabs[state.repertoire.activeTab].bookPriceCalculationsJobInProgress,        
        workMaintenanceGeneralDataViewData: state.repertoire.componentData.WorkMaintenanceGeneralDataView,
        formats: state.dataIngestion.formats
    }),
    dispatch => ({
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        hideModal: () => dispatch(hideModal()),
        updateLicenseField: (value: any, fieldName: ILicenseStateKeys, lookups?: ILookupDictionary, subFieldName?: string) =>
            dispatch(updateLicenseField(value, fieldName, lookups,subFieldName)),
        saveChanges: (license: IShoppingCartState,licenseRequestWorkParameterFields: ILicenseInputItem[],lookupValues: ILookupDictionary) =>
            dispatch<any>(saveLicenseThunk(license,licenseRequestWorkParameterFields,lookupValues)),
        deleteLicense: (licenseID: number, sources: ITreeData[], activeTab?: number) =>
            dispatch<any>(deleteLicenseThunk(licenseID, sources, activeTab)),
        addNewLicense: (lookups: ILookupDictionary,licenseRequestWorkParameterFields: ILicenseInputItem[], isNew?: boolean, licenseMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) =>
            dispatch(createNewLicense(lookups, licenseRequestWorkParameterFields, isNew, licenseMaintenanceGeneralDataViewData)),
        copyLicense: (shoppingCart: IShoppingCartState) => dispatch<any>(copyExistingLicense(shoppingCart)),
        showModal: (modalContent: string, modalComponentInstance: string, modalProps: any, displayModalCloseButton: boolean, title: string) =>
            dispatch(showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)),
        getShoppingCartParameters: () =>
            dispatch<any>(getShoppingCartParameters()),
        searchLicenseUser: (query: ILicenseUserSearchQuery) =>
            dispatch<any>(searchLicenseUserThunk(query)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => 
            dispatch(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        addWorkToLicenseRequest: (work: IWorksSearchResult) => dispatch<any>(addWorkToLicenseRequest(work)),
        updateLicenseRequestWorkItem: (licenseRequestWorkID: number, licenseRequestWorks: ILicenseRequestWorkItem[]) =>
            dispatch(updateLicenseRequestWorkItem(licenseRequestWorkID, licenseRequestWorks)),
        getLicenseRequestWorkFields: (commercialUseToken: number, geographicalToken: number, sectorToken: number, userTypeToken: number, useTypeToken: string, workID: number, licenseRequestWorkID: number) =>
            dispatch<any>(getLicenseRequestWorkParametersThunk(commercialUseToken, geographicalToken, sectorToken, userTypeToken, useTypeToken, workID, licenseRequestWorkID)),
        getWorksContributors: (workIds: number[]) => 
            dispatch<any>(getLicenseWorksContributorsThunk(workIds)),
        licenseWorksPricesImport: (licenseWorksTable: any[]) => dispatch<any>(importLicenseWorksPrices(licenseWorksTable)),
        licenseCalculateWorkPrice: (shoppingCart: IShoppingCartState, selectedLicenseRequestWorks: ILicenseRequestWorkItem[], licenseRequestWorkParameterFields: ILicenseInputItem[],
            licenseRequest: ILicenseRequestItem, lookupValues: ILookupDictionary, isModified: boolean) =>
            dispatch<any>(calculateLicenseWorksPricesThunk(shoppingCart, selectedLicenseRequestWorks, licenseRequestWorkParameterFields, licenseRequest, lookupValues, isModified)),
        submitLicense: (licenseRequestID: number, clientID: string) => dispatch<any>(submitLicenseToCRMThunk(licenseRequestID, clientID)),
        getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataActions?: IDataActionToolbar[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(getWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes)),
        undoLicenseChanges: (licenseID: number, lookupValues: ILookupDictionary, licenseRequestWorkParameterFields: ILicenseInputItem[]) => dispatch<any>(undoLicenseChangesThunk(licenseID, lookupValues, licenseRequestWorkParameterFields)),
    })
)(LicenseMaintenancePageScrollListener);