import React, { useState } from 'react';
import { Button, Steps, } from 'antd';
import { DataSource } from '../../../../repertoire/types/DataSource';
import { MessageBanner, MessageType } from "../../../../repertoire/components/messageBanner/MessageBanner";
import { ITabReduxItem } from '../../../../redux/types/ITabReduxItem';
import InvalidAdditionalNumbersAlert from '../../../../repertoire/works/components/workMaintenanceAccordion/InvaildAdditionalNumberAlert';
import { resetMessageBanner } from '../../../../redux/reducers/MembersPortalReducer';
enum FormPages {
    GeneralPage = 0,
    AssigneesPage = 1,
    WorksPage = 2,
    AttachmentPage = 4,
    CommentsPage = 3
}
export interface Step {
    title: string;
    content: any;
}
export interface IDraftAgreementsMaintenanceFormProps {
    steps: Step[];
    readonly: boolean;
    saveChanges: () => void;
    confirmAndSubmit: () => void;
    dataSource?: string;
    current?: number;
    setCurrent?: (current: number) => void;
    tabs?: ITabReduxItem
    activeTab?: number;
    resetMessageBanner: () => void;
    currentUserNameNumber: string;
}
const DraftAgreementsMaintenanceForm = (props) => {

    const [submit, setSubmit] = useState(false)
    const [errorMessages, setErrorMessages] = useState([]);
    const handleSubmit = () => {
        setSubmit(props.confirmAndSubmit());
    }
    const next = () => {
        const { current, setCurrent, tabs, activeTab, currentUserNumber } = props;
        const next = current + 1;
        switch (current) {
            case FormPages.GeneralPage:
                if (validateGeneralPage(tabs[activeTab].agreementMaintenanceState.agreement)) {
                    setCurrent(next)
                }
                break;
            case FormPages.AssigneesPage:
                if (validateAssigneePage(tabs[activeTab].agreementMaintenanceState.agreement, currentUserNumber)) {
                    setCurrent(next);
                }
                break;
            case FormPages.WorksPage:
                if (isWorksPage(tabs[activeTab].agreementMaintenanceState.agreement)) {
                    if (validateWorksPage(tabs[activeTab].agreementMaintenanceState.agreement)) {
                        setCurrent(next)
                    }
                }
                if (isCommentsPage(tabs[activeTab].agreementMaintenanceState.agreement, FormPages.WorksPage)) {
                    if (validateCommentsPage(tabs[activeTab].agreementMaintenanceState.agreement)) {
                        if (!tabs[activeTab].agreementMaintenanceState.agreement?.status) {
                        props.saveChanges();
                        }
                        setCurrent(next)
                    }
                }
                break;
            case FormPages.AttachmentPage:
                setCurrent(next)
                break;
            case FormPages.CommentsPage:
                if (isCommentsPage(tabs[activeTab].agreementMaintenanceState.agreement, FormPages.CommentsPage)) {
                    if (validateCommentsPage(tabs[activeTab].agreementMaintenanceState.agreement)) {
                        props.saveChanges();
                        setCurrent(next)
                    }
                } else {
                    setCurrent(next)
                }
                break;
            default:
                setCurrent(next)

        }

    };

    const isCommentsPage = (state, pageNumber) => {
        const GENERIC_TYPE = '1';
        const SPECIFIC_TYPE = '2';
        return state.type === SPECIFIC_TYPE && pageNumber === FormPages.CommentsPage || state.type === GENERIC_TYPE && pageNumber === FormPages.WorksPage;
    }

    const validateCommentsPage = (state) => {
        const messages = [];
        if (state.comments && state.comments.length > 0) {
            state.comments.forEach((comment) => {
                if (comment.commentCategory.value === null) {
                    messages.push("Comment category cannot be empty");
                }
            });
        }
        if (messages.length > 0) {
            setErrorMessages(messages);
            return false;
        }
        setErrorMessages([]);
        return true;
    }

    const isWorksPage = (state) => {
        const SPECIFIC_TYPE = '2'
        return state.type === SPECIFIC_TYPE;
    }

    const validateWorksPage = (state) => {
        const messages = [];
        if (isWorksPage(state)) {
            if (state.agreementWorks?.length > 0) {
                if (state?.agreementWorks[0]?.workName?.value === '') {
                    messages.push("Work cannot be empty");
                }
            }
            if (!state.agreementWorks || state.agreementWorks.length === 0) {
                messages.push("Work required");
            }
        }
        if (messages.length > 0) {
            setErrorMessages(messages);
            return false;
        }
        setErrorMessages([]);
        return true;
    }

    const validateAssigneePage = (state, currentUserNumber) => {
        const messages = [];

        let isAssignee = false;
        let isAssignor = false;

        state.agreementAdminIP && state.agreementAdminIP.length > 0 && state.agreementAdminIP.forEach((assignee) => {
            if (assignee?.administeredIP?.value == currentUserNumber || assignee?.assigneeNameNumber?.value == currentUserNumber || assignee?.administeredByIPBaseNumber?.value == currentUserNumber) {
                isAssignee = true;
            }
        })

        if (state.agreementAdminIP.some(assignee => assignee?.assigneeNameNumber?.value === state.ipNameNumber)) {
            messages.push("Assignee and Assignor cannot be the same IP");
        }

        if (state?.ipNameNumber == currentUserNumber) {
            isAssignor = true;
        }

        if (!isAssignee && !isAssignor) {
            messages.push("You must be the assignor or assignee required");
        }

        if (!state.agreementAdminIP || state.agreementAdminIP.length === 0) {
            messages.push("Assignee required");
        }
        if (state.agreementAdminIP && state.agreementAdminIP.length > 0) {
            let isAdministeredNameError = false;
            let isSocietyAffiliationError = false;
            let isShareValuesEqualZero = false;

            let toleranceUpperPerformer = 100.02;
            let toleranceUpperMechanical = 100.02;

            let performingShareTotal = 0;
            let mechanicalShareTotal = 0;
            state.agreementAdminIP.forEach((assignee) => {
                if ((!assignee.administeredName || assignee.administeredName.value === '') && !isAdministeredNameError) {
                    isAdministeredNameError = true;
                    messages.push("Assignee name cannot be empty");
                }
                if (((!assignee.performingShare || assignee.performingShare.value == '' || assignee.performingShare.value == 0) && (!assignee.mechanicalShare || assignee.mechanicalShare.value == '' || assignee.mechanicalShare.value == 0) ) && !isShareValuesEqualZero) {
                    isShareValuesEqualZero = true;
                    messages.push("Assignee needs to have either mechanical or performing share values");
                }
                if (assignee?.performingShare?.value) {
                    performingShareTotal += parseFloat(assignee.performingShare.value);
                }
                if (assignee?.mechanicalShare?.value) {
                    mechanicalShareTotal += parseFloat(assignee.mechanicalShare.value);
                }

            });
            if ( performingShareTotal > toleranceUpperPerformer) {
                messages.push("Performing share cannot exceed 100%");
            }
            if (mechanicalShareTotal > toleranceUpperMechanical) {
                messages.push("Mechanical share cannot exceed 100%");
            }

        }
        if (messages.length > 0) {
            setErrorMessages(messages);
            return false;
        }
        setErrorMessages([]);
        return true;
    };



    const validateGeneralPage = (state) => {
        const messages = [];
        // if (!state.ipNameNumber || state.ipNameNumber === '') {
        //     messages.push("IP name number cannot be empty");
        // }
        if (!state.ipName || state.ipName === '') {
            messages.push("IP name cannot be empty");
        }
        if (!state.effectiveFrom || state.effectiveFrom === '') {
            messages.push("Effective from date cannot be empty");
        }
        if (!state.agreementCategory || state.agreementCategory === '') {
            messages.push("Category cannot be empty");
        }
        if (!state.type || state.type === '') {
            messages.push("Type cannot be empty");
        }
        if (!state.agreementShareSet || state.agreementShareSet.length === 0) {
            messages.push("Assigned set type cannot be empty");
        }
        if (state.agreementTerritory) {
            if(state.agreementTerritory.length===0){
                messages.push("Territory cannot be empty");
            }else{
                let includedTerritories = [];
                let excludedTerritories = [];
                state.agreementTerritory.map(item => {
                    if (item.include) {
                        includedTerritories.push(item)
                    } else {
                        excludedTerritories.push(item)
                    }
    
                })
                if (includedTerritories.length === 0) {
                    if (includedTerritories.length < excludedTerritories.length) {
                        messages.push("Agreement cannot have Exclude Territory without an Include Territory")
                    }
                }
            }
        }
        if(state.type==='2'){
            if(!state.applicableWorksType){
                messages.push("Applicable Works Type cannot be empty");
            }            
        }
        if(state.effectiveFrom && state.effectiveTo){
            let fromDate = new Date(state.effectiveFrom as any);
                let toDate = new Date(state.effectiveTo); 
                if (fromDate > toDate) {
                    messages.push("Effective From date must be before Effective To date.");
                }
        }
        if (messages.length > 0) {
            setErrorMessages(messages);
            return false;
        }
        setErrorMessages([]);
        return true;
    };


    const prev = () => {
        const { current, setCurrent } = props;
        setErrorMessages([]);
        setCurrent(current - 1);
    };
    let validationErrors = !!(errorMessages.length > 0);
    const renderCloseErrorMessages = () => {
        setErrorMessages([]);};
    const items = props.steps.map((item) => ({ key: item.title, title: item.title }));
    const { current, dataSource } = props;
   
    return (
        <>
            {(dataSource === DataSource.Intray) ? <Steps current={current} items={items} /> : <></>}
            <div style={{ padding: '20px' }}>{props.steps[current].content}</div>
            {!(validationErrors && <MessageBanner resetMessageBanner={renderCloseErrorMessages} messageType={MessageType.Danger} messages={errorMessages} />) && document.querySelector('.loaderWrapper') == null && submit && <div
            >
                <MessageBanner messageType={MessageType.Success}
                    messages={["Draft submitted"]}
                    resetMessageBanner={resetMessageBanner}
                />
            </div>
            }
            <div className="row">
                <div
                    className="btn-group form-group col-lg-2 col-lg-push-5 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0"
                    style={{ marginTop: 24 }}
                >
                    {current > 0 && (
                        <Button data-testid={'prev'} className="button btn-defaultSecondary" onClick={() => prev()}>
                            Previous
                        </Button>
                    )}
                </div>
                <div
                    className="btn-group form-group col-lg-2 col-lg-push-5 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0"
                    style={{ marginTop: 24 }}
                >
                    {current < props.steps.length - 1 && (
                        <Button data-testid={'next'} className="button btn-defaultSecondary" onClick={() => next()}>
                            Next
                        </Button>
                    )}
                </div>
                {current === props.steps.length - 1 && props.dataSource === DataSource.Intray && (
                    <>
                        <div
                            className="btn-group form-group col-lg-2 col-lg-push-3 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0"
                            style={{ marginTop: 24 }}
                        >
                            <Button data-testid={'save-registration'} className="button btn-defaultSecondary" onClick={() => props.saveChanges()}>
                                Save changes
                            </Button>
                        </div>
                        <div
                            className="btn-group form-group col-lg-2 col-lg-push-3 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0"
                            style={{ marginTop: 24 }}
                        >
                            <Button
                                data-testid={'confirm-submit-registration'}
                                className="button btn-defaultPrimary btn-confirm"
                                onClick={() => handleSubmit()}
                            >
                                Confirm & Submit Agreement
                            </Button>
                        </div>
                    </>
                )}
            </div>
            {validationErrors && <MessageBanner resetMessageBanner={renderCloseErrorMessages} messageType={MessageType.Danger} messages={errorMessages} />}
        </>
    );
};
export default DraftAgreementsMaintenanceForm;