import { useMsal } from "@azure/msal-react";
import { throttle } from "lodash";
import * as React from "react";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { hideModal, openassignWorkflowTo, showModal, showUpdateWorkflow } from "../../redux/reducers/ModalReducer";
import { clearWorkflowSelection, markSelectAll, nextWorkflowInSession, selectAllWorkflow, selectSingleWorkflow, setErrorWorkflowSession, startWorkflowSession } from "../../redux/reducers/RepertoireReducer";
import { FormatFields } from "../../redux/types/FormatFields";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import SearchView from "../components/searchView/SearchView";
import { getDataAction } from "../components/toolBar/ToolbarHelper";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP, WORK_MAINTENANCE_GROUP } from "../ConfigurationConsts";
import { ALL_ROLES, EMPTY_STRING_VALUE, EVENT_HANDLER_THROTTLE_TIME, SEARCH_VIEW_WORKFLOW, VIEW_WORKFLOWS_ROLE, WORK_MAINTENANCE_TOOLBAR } from "../Consts";
import { SearchRequests } from "../services/SearchRequests";
import { IAssignWorkflowModel } from "../types/IAssignWorkflowModel";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import { IWorkflowSearchQuery } from "../types/IWorkflowSearchQuery";
import { IWorkflowSearchResult } from "../types/IWorkflowSearchResult";

export interface IWorkflowsPageProps {
    workflowPageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    workflowPageToolbarData: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    searchSuccessful?: boolean;
    workflowSearchResults: IWorkflowSearchResult[];
    workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], actionList: IDataActionToolbar[], readonlyIndicatorsWorkFlagTypes: string[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], openEntityForWorflowSession?: boolean) => void;
    getWorkDetailsWorkflow: (
        dataSource: string,
        workID: number,
        lookups: ILookupDictionary,
        otherIndicatorsWorkFlagTypes: string[],
        dataActions: IDataActionToolbar[],
        workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem,
        formats: FormatFields[],
        readonlyIndicatorsWorkFlagTypes: string[],
        openEntityForWorflowSession?: boolean,
        activeTab?: number, currentWorkflowIndex?: number,
        workflows?: IWorkflowSearchResult[],
        workGroup?: string) => void;
    searchWorkflow: (query: IWorkflowSearchQuery, modalOpen: boolean) => void;
    selectSingleWorkflow: typeof selectSingleWorkflow;
    hideModal: typeof hideModal;
    showModal: typeof showModal;
    activeTab?: number,
    tabs?: ITabReduxItem[];
    onOpenAssignView?: typeof openassignWorkflowTo;
    assingWorkflowDataView?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    assignWorkflows?: (model: IAssignWorkflowModel, searchBody: IWorkflowSearchQuery) => void;
    latestWorkflowSearchQuery: IWorkflowSearchQuery;
    updateStatusWorkflows?: (model: IAssignWorkflowModel, searchBody: IWorkflowSearchQuery) => void;
    updateWorkflowViewData?: IRepertoireComponentDataItem;
    onOpenUpdateWorkflows: typeof showUpdateWorkflow;
    clearSelection?: typeof clearWorkflowSelection;
    users: {
        code: string;
        description: string;
    }[];
    selectAllWorkflow?: typeof selectAllWorkflow;
    isSelectedAll?: boolean;
    markSelectAll?: typeof markSelectAll;
    getUsers?: () => void;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    startWorkflowSession: typeof startWorkflowSession;
    cancelWorkflowSession: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    workflowSessionItems?: IWorkflowSearchResult[];
    dataActionList?: IDataActionToolbar[];
    workFormats?: FormatFields[];
    getFieldFormats?: (key: string, group: string) => void;
    isAnySelectedCompletedError?: boolean;
    setErrorWorkflowSession?: typeof setErrorWorkflowSession;
    currentWorkflowIndex?: number;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    getUsageDetails: (usageID: string, openEntityForWorflowSession?: boolean) => void;
    skipUsageWorkflowItem: (activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    sortSearchResults: (name: string, results: any) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    getProductDetails: (dataSource: string, productID: number,
        productMaintenanceGeneralDataView: IRepertoireComponentDataItem,
        formats?: FormatFields[],
        openEntityForWorflowSession?: boolean) => void;
    productMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    getAgreementDetails: (dataSource: string,
        agreementID: number,
        lookups: ILookupDictionary,
        dataActions: IDataActionToolbar[],
        openEntityForWorflowSession?: boolean) => void;
}

const WorkflowPage = (props: IWorkflowsPageProps) => {
    const [scroll, setScroll] = React.useState(0)
    const [toolbarWidth, setToolbarWidth] = React.useState(null)
    const [loaded, setLoaded] = React.useState(false)
    const { instance, accounts } = useMsal()

    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getRoles();

        window.addEventListener(
            "scroll",
            throttle(handleScroll, EVENT_HANDLER_THROTTLE_TIME, {
                trailing: true
            })
        );

        return () => {
            window.removeEventListener(
                "scroll",
                throttle(handleScroll, EVENT_HANDLER_THROTTLE_TIME, {
                    leading: true
                })
            );
        }
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])


    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    const checkIfLoaded = () => {
        const { workflowPageData, users, getUsers, workFormats, getFieldFormats } = props;

        if (workflowPageData) {
            if (workflowPageData.fields && workflowPageData.fields.length > 0 && !loaded) {
                setLoaded(true);
                const header = workflowPageData.fields.find(f => f.name === "PageTitle");
                if (header) {
                    document.title = header.data;
                }
            }

        }

        if (!users || (users && users.length == 0)) {
            getUsers();
        }
        if (!workFormats) {
            getFieldFormats(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP);
        }
    }

    const onAssignTo = (userName: string) => {
        const { workflowSearchResults, assignWorkflows, latestWorkflowSearchQuery } = props;

        if (workflowSearchResults && workflowSearchResults.length > 0) {
            const selectedWorkflows = workflowSearchResults.filter(w => w.selected) as IWorkflowSearchResult[];
            let assignModel: IAssignWorkflowModel = {
                username: userName,
                workflows: [],
                status: EMPTY_STRING_VALUE
            };
            if (selectedWorkflows && selectedWorkflows.length > 0) {
                selectedWorkflows.map(w => {
                    assignModel.workflows.push({ ID: w.ID.toString(), entityID: w.entityID });
                });
                assignWorkflows(assignModel, latestWorkflowSearchQuery);
            }
        }
    }

    const onUpdateStatus = (status: string) => {
        const { workflowSearchResults, updateStatusWorkflows, latestWorkflowSearchQuery } = props;

        if (workflowSearchResults && workflowSearchResults.length > 0) {
            const selectedWorkflows = workflowSearchResults.filter(w => w.selected) as IWorkflowSearchResult[];
            let assignModel: IAssignWorkflowModel = {
                username: EMPTY_STRING_VALUE,
                workflows: [],
                status: status
            };
            if (selectedWorkflows && selectedWorkflows.length > 0) {
                selectedWorkflows.map(w => {
                    assignModel.workflows.push({ ID: w.ID.toString(), entityID: w.entityID });
                });
                updateStatusWorkflows(assignModel, latestWorkflowSearchQuery);
            }
        }
    }

    const getActionList = (dataSource: string) => {
        return getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
    }

    const getWorkFormats = () => {
        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    const getWorkflowStatusList = (): { code: string, description: string }[] => {
        const { workflowPageData } = props;

        return [
            "workflowStatusOptAssigned",
            "workflowStatusOptCancelled",
            "workflowStatusOptCompleted",
            "workflowStatusOptOpen"
        ]
            .map((v, i, a) => ({
                code: ComponentsHelper.getFieldValueByName(v, workflowPageData.fields, EMPTY_STRING_VALUE),
                description: ComponentsHelper.getFieldValueByName(v, workflowPageData.fields, EMPTY_STRING_VALUE)
            }));
    }

    const getWorkflowEntityTypeList = (): { code: string, description: string }[] => {
        const { workflowPageData } = props;

        return [
            "workflowTypeOptIntrayWork",
            "workflowTypeOptRepertoireWork",
            "workflowTypeOptUsageWorkBased",
            "workflowTypeOptUsageProductBased",
            "workflowTypeOptUsageLivePerformance",
            "workflowTypeOptMagazineApprovals",
            "workflowTypeOptIntrayProduct",
            "workflowTypeOptRepertoireProduct",
            "workflowTypeOptUsageUnidentifiedPerformance",
            "workflowTypeOptIntrayAgreement"
        ]
            .map((v, i, a) => ({
                code: ComponentsHelper.getFieldValueByName(v, workflowPageData.fields, EMPTY_STRING_VALUE),
                description: ComponentsHelper.getFieldValueByName(v, workflowPageData.fields, EMPTY_STRING_VALUE)
            }))
            .filter(item => item.code !== '' || item.description !== '');
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const workflowStatusList = getWorkflowStatusList();

    const workflowEntityTypeList = getWorkflowEntityTypeList();

    if (loaded) {
        const {
            lookupValues,
            workMaintenanceGeneralDataViewData,
            searchViewData,
            searchResultsTableData,
            searchSuccessful,
            searchWorkflow,
            workflowSearchResults,
            selectSingleWorkflow,
            workflowPageToolbarData,
            activeTab,
            tabs,
            onOpenAssignView,
            assingWorkflowDataView,
            dataGridTableData,
            updateWorkflowViewData,
            onOpenUpdateWorkflows,
            hideModal,
            clearSelection,
            users,
            workflowPageData,
            selectAllWorkflow,
            isSelectedAll,
            markSelectAll,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            resetPagination,
            updatePagination,
            startWorkflowSession,
            nextWorkflowItem,
            cancelWorkflowSession,
            skipWorkflowItem,
            workflowSessionItems,
            dataActionList,
            workFormats,
            isAnySelectedCompletedError,
            setErrorWorkflowSession,
            currentWorkflowIndex,
            getUsageDetails,
            skipUsageWorkflowItem,
            sortSearchResults,
            productMaintenanceGeneralDataViewData
        } = props;

        const getWorkDetails = (dataSource: string, workID: number, openEntityForWorflowSession?: boolean) => {
            const actionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
            const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                .then(formats => {
                    props.getWorkDetails(dataSource, workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, actionList, tabs[activeTab].readonlyIndicatorWorkFlagTypes, workMaintenanceGeneralDataViewData, formats, openEntityForWorflowSession);
                });

        }

        const getAgreementDetails = (dataSource: string, agreementID: number, openEntityForWorflowSession?: boolean) => {
            const actionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
            props.getAgreementDetails(dataSource, agreementID, lookupValues, actionList, openEntityForWorflowSession);

        }

        const getWorkDetailsWorkflow = (dataSource: string, workID: number, selectedWorkflows: IWorkflowSearchResult[], openEntityForWorkflowSession?: boolean) => {
            const actionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
            const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                .then(formats => {
                    props.getWorkDetailsWorkflow(dataSource, workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, actionList, workMaintenanceGeneralDataViewData, formats, tabs[activeTab].readonlyIndicatorWorkFlagTypes, openEntityForWorkflowSession, activeTab, currentWorkflowIndex, selectedWorkflows);
                });
        }

        const getProductDetails = (dataSource: string, productID: number, openEntityForWorflowSession?: boolean) => {
            const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP)
                .then(formats => {
                    props.getProductDetails(dataSource, productID, productMaintenanceGeneralDataViewData, formats, openEntityForWorflowSession);
                });
        }

        const getViewRoleDisabled = (): boolean => {
            const { roles } = props;
            if (roles && (roles.includes(VIEW_WORKFLOWS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">Find Workflow</span>
                    </div>
                </div>

                <SearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_WORKFLOW}
                    modalProps={undefined}
                    dataSource={EMPTY_STRING_VALUE}
                    getWorkDetails={getWorkDetails}
                    getAgreementDetails={getAgreementDetails}
                    getWorkDetailsWorkflow={getWorkDetailsWorkflow}
                    getProductDetails={getProductDetails}
                    searchWorks={undefined}
                    worksSearchResults={undefined}
                    hideModal={hideModal}
                    searchSuccessful={searchSuccessful}
                    expandedWorkResults={undefined}
                    expandAll={undefined}
                    expandWorkResult={undefined}
                    expandAllResults={undefined}
                    searchWorkflow={searchWorkflow}
                    workflowSearchResults={workflowSearchResults}
                    selectWorkflow={selectSingleWorkflow}
                    activeTab={activeTab}
                    tabs={tabs}
                    workflowPageToolbarData={workflowPageToolbarData}
                    onOpenAssignView={onOpenAssignView}
                    assingWorkflowDataView={assingWorkflowDataView}
                    dataGridTableData={dataGridTableData}
                    onAssignWorkflow={onAssignTo}
                    updateWorkflowViewData={updateWorkflowViewData}
                    onOpenUpdateWorkflows={onOpenUpdateWorkflows}
                    onUpdateStatusWorkflow={onUpdateStatus}
                    clearSelection={clearSelection}
                    users={users}
                    workflowStatusList={workflowStatusList}
                    workflowEntityTypeList={workflowEntityTypeList}
                    workflowPageData={workflowPageData}
                    selectAllWorkflow={selectAllWorkflow}
                    isSelectedAll={isSelectedAll}
                    markSelectAll={markSelectAll}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    updatePagination={updateUserPagination}
                    startWorkflowSession={startWorkflowSession}
                    nextWorkflowItem={nextWorkflowItem}
                    cancelWorkflowSession={cancelWorkflowSession}
                    skipWorkflowItem={skipWorkflowItem}
                    workflowSessionItems={workflowSessionItems}
                    dataActionList={dataActionList}
                    workFormats={workFormats}
                    workMaintenanceGeneralDataViewData={workMaintenanceGeneralDataViewData}
                    lookupValues={lookupValues}
                    isAnySelectedCompletedError={isAnySelectedCompletedError}
                    setErrorWorkflowSession={setErrorWorkflowSession}
                    openEntityForWorflowSession={true}
                    currentWorkflowIndex={currentWorkflowIndex}
                    getUsageDetails={getUsageDetails}
                    skipUsageWorkflowItem={skipUsageWorkflowItem}
                    sortSearchResults={sortSearchResults}
                    searchDisabled={getViewRoleDisabled()}
                />
            </div>
        )
    }
    else {
        return null;
    }
}

export default WorkflowPage;