import * as React from "react";
import { IComponentDataItem } from "../../../core/types/IComponentDataItem";
import { updateJobSearchStateField } from "../../../redux/reducers/HomeReducer";
import ActionButton from "../../../repertoire/components/actionButton/ActionButton";
import { IJobSearchResult } from "../../types/IJobSearchResult";
import { IJobSearchState, WITH_REFERENCE } from "../../types/IJobSearchState";
import JobSearchResultsGrid from "../jobSearchResultsGrid/JobSearchResultsGrid";
import { TimeRangeSelector } from "../timeRangeSelector/TimeRangeSelector";
import { SEARCH_FIELD, JOBS_TITLE_FIELD } from "./JobSearchResultsViewFields";
import JobSearchViewContainer from "../jobSearchView/JobSearchViewContainer";
import { ManualJobView } from "../modalViews/ManualJobView";
import {
    hideModal, showModal,
} from "../../../redux/reducers/ModalReducer";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import {
    getCWRAckHWM,
    getAutomaticAgreementGHWM,
    getManualJobDataSources,
    triggerManualJob,
    getOpenDistributionsThunk,
    getAllDistributionsThunk,
    getPoolsThunk,
    getEmuwHwm,
    getUsageFilenames,
    getScheduledJob,
    upsertScheduledJobAsync,
    getAllUsageFilenamesFromDistribution
} from "../../../redux/thunks/HomeThunks";
import { IAckHWMState } from "../../types/IAckHWMState";
import { IAutomaticAgreementGHWMState } from "../../types/IAutomaticAgreementGHWMState";
import { IDataSourceBase } from "../../types/JobParameterControls/IDataSource";
import IHomeComponentData from "../../types/IHomeComponentData";
import { getLookupDefault, getLookupValues } from "../../../lookup/services/LookupHelpers";
import { lookup } from "dns";
import { ICwrgParameters } from "../../../redux/types/ICwrgParameters";
import { JOB_TIME_RANGE_LOOKUP } from "../../../lookup/Consts";

interface IJobSearchResultsViewProps {
    jobSearchResultsViewData: IComponentDataItem;
    timeRangeSelectorData: IComponentDataItem;
    jobSearchResultsGridData: IComponentDataItem;
    updateJobSearchStateField: typeof updateJobSearchStateField;
    jobSearchState: IJobSearchState;
    jobSearchResults: IJobSearchResult[];
    searchJobs: (searchQuery: IJobSearchState) => void;
    newSearch?: boolean;
    hideModal: typeof hideModal;
    showModal: typeof showModal;
    modalProps: any;
    lookups: ILookupDictionary;
    manualJobType: string;
    manualJobParameters: any;
    triggerManualJob: typeof triggerManualJob;
    getUsageFilenames:typeof getUsageFilenames;
    getScheduledJob: typeof getScheduledJob;
    upsertScheduledJobAsync:typeof upsertScheduledJobAsync
    getAllUsageFilenamesFromDistribution:typeof getAllUsageFilenamesFromDistribution;
    cwrAckHWM: IAckHWMState[];
    getCWRAckHWM: typeof getCWRAckHWM;
    automaticAgreementGHWM: IAutomaticAgreementGHWMState[];
    getAutomaticAgreementGHWM: typeof getAutomaticAgreementGHWM;
    getEmuwHwm: (distributionId: number) => void
    fetchManualJobDataSources: typeof getManualJobDataSources;
    manualJobDataSources: { [key: string]: IDataSourceBase };
    componentData: IHomeComponentData;
    getPaymentRunIDs: () => void;
    paymentRunIDs: any;
    updateManualJobParams: (params: any) => void;
    getOpenDistributions: typeof getOpenDistributionsThunk;
    getAllDistributions: typeof getAllDistributionsThunk
    getPools: typeof getPoolsThunk;
    openDistributions?: any;
    allDistributions?: any;
    pools?: any;
    emuwHighWaterMark?: any;
    usageFileNames?:any;
    scheduledJob?: any;
    getCWRGHighWaterMark?: (cwrgParams: ICwrgParameters) => void;
    cwrgHighWaterMark?: any;
    setCWRGHighWaterMark?: (hwm: string) => void;
    getSeparatePublishedCheckbox?: () => void;
    separatePublishedCheckbox?: boolean;
}

interface IJobSearchResultsViewState {
    loaded: boolean;
    showManualJobView?: boolean;
}

export class JobSearchResultsView extends React.Component<
    IJobSearchResultsViewProps,
    IJobSearchResultsViewState
> {
    viewData;
    constructor(props: IJobSearchResultsViewProps) {
        super(props);
        this.state = {
            loaded: false,
            showManualJobView: false,
        };
        this.viewData = {};
    }

    componentDidUpdate() {
        this.load();
    }

    componentDidMount() {
        this.load();
    }

    load() {
        const { loaded } = this.state;
        const { lookups } = this.props;
        if (!loaded) {
            if (!this.props.jobSearchResultsViewData) {
                return;
            }

            const {
                jobSearchResultsViewData: { fields },
            } = this.props;

            if (fields)
                fields.forEach((item) => {
                    this.viewData[item.name] = item.data;

                    if (Object.keys(this.viewData).length === fields.length && lookups && Object.keys(lookups).length > 0) {
                        this.setState({ loaded: true });
                    }
                });
        }
    }

    onClickSearch() {
        const { jobSearchState, searchJobs } = this.props;
        searchJobs(jobSearchState);
    }

    createNewJob() {
        this.setState({ showManualJobView: !this.state.showManualJobView });
    }

    renderManualJobElem(showManualJobView: boolean) {
        return showManualJobView ? (
            <ManualJobView
                createNewJob={this.createNewJob.bind(this)}
                hideModal={this.props.hideModal}
                showModal={this.props.showModal}
                props={this.props.modalProps}
                lookups={this.props.lookups}
                manualJobType={this.props.manualJobType}
                manualJobParameters={this.props.manualJobParameters}
                triggerManualJob={this.props.triggerManualJob}
                cwrAckHWM={this.props.cwrAckHWM}
                automaticAgreementGHWM={this.props.automaticAgreementGHWM}
                getEmuwHwm={this.props.getEmuwHwm}
                getCWRAckHWM={this.props.getCWRAckHWM}
                getAutomaticAgreementGHWM={this.props.getAutomaticAgreementGHWM}
                fetchManualJobDataSources={this.props.fetchManualJobDataSources}
                manualJobDataSources={this.props.manualJobDataSources}
                manualJobViewData={this.props.componentData.ManualJobView}
                getPaymentRunIDs={this.props.getPaymentRunIDs}
                paymentRunIDs={this.props.paymentRunIDs}
                updateManualJobParams={this.props.updateManualJobParams}
                getOpenDistributions={this.props.getOpenDistributions}
                getAllDistributions={this.props.getAllDistributions}
                getPools={this.props.getPools}
                openDistributions={this.props.openDistributions}
                allDistributions={this.props.allDistributions}
                pools={this.props.pools}
                emuwHighWaterMark={this.props.emuwHighWaterMark}
                getUsageFilenames={this.props.getUsageFilenames}
                getScheduledJob={this.props.getScheduledJob}
                upsertScheduledJobAsync={this.props.upsertScheduledJobAsync}
                usageFileNames={this.props.usageFileNames}
                scheduledJob={this.props.scheduledJob}
                getAllUsageFilenamesFromDistribution={this.props.getAllUsageFilenamesFromDistribution}
                getCWRGHighWaterMark={this.props.getCWRGHighWaterMark}            
                setCWRGHighWaterMark={this.props.setCWRGHighWaterMark}
                cwrgHighWaterMark={this.props.cwrgHighWaterMark}           
                getSeparatePublishedCheckbox={this.props.getSeparatePublishedCheckbox}
                separatePublishedCheckbox={this.props.separatePublishedCheckbox}                   />
        ) : (
            <></>
        );
    }

    render() {
        const { loaded } = this.state;
        if (!loaded) return <div className="loading" />;
        const {
            jobSearchResultsGridData,
            timeRangeSelectorData,
            jobSearchState,
            updateJobSearchStateField,
            jobSearchResults,
            newSearch,
            showModal,
            lookups
        } = this.props;

        return (
            <div className="form-group col-md-12 ">
                <div className="formBox">
                    <div className="row">
                        <div className="col-md-12">
                            <span className="title">{this.viewData[JOBS_TITLE_FIELD]}</span>
                            <div className="newJobButton" title="Create New Job">
                                <div
                                    className="IconTextButton"
                                    onClick={this.createNewJob.bind(this)}
                                >
                                    <i
                                        className="icon ms-Icon ms-Icon--CircleAddition"
                                        aria-hidden="true"
                                    ></i>
                                    Create New Job
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.renderManualJobElem(this.state.showManualJobView)}
                    </div>
                    <JobSearchViewContainer />
                    <TimeRangeSelector
                        timeRangeSelectorData={timeRangeSelectorData}
                        jobSearchState={jobSearchState}
                        updateJobSearchStateField={updateJobSearchStateField}
                        timeRangeOptions={getLookupValues(JOB_TIME_RANGE_LOOKUP, lookups)}
                        dateRangeDefault={getLookupDefault(JOB_TIME_RANGE_LOOKUP, lookups)}
                    />
                    <div className="row">
                        <div className="form-group col-md-3 col-sm-6 col-xs-12">
                            <label>{this.viewData[WITH_REFERENCE]}</label>
                            <input type="text"
                                onChange={event => updateJobSearchStateField(event.target.value, WITH_REFERENCE)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                            <ActionButton
                                buttonText={this.viewData[SEARCH_FIELD]}
                                buttonAction={this.onClickSearch.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 searchResultsDiv">
                            <JobSearchResultsGrid
                                searchResultsTableData={jobSearchResultsGridData}
                                tableContents={jobSearchResults}
                                newSearch={newSearch}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
