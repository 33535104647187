import { AGENCY_LOOKUP, TERRITORY_LOOKUP } from "../../lookup/Consts";
import { getLookupValues } from "../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { isNullOrUndefined } from "../../util/tools";
import { DROPDOWN_INPUT } from "../Consts";
import { IIPRepresentation } from "../types/IIPRepresentation";
import { ISocIP } from "../types/ISocIP";
import { IValidationMessage } from "../types/IValidationMessage";

export class InterestedPartyOnSaveValidator {

    public static validateFromDate(date: Date): boolean {
        return isNullOrUndefined(date) ? false : true;
    }
    public static validateToDate(date: Date): boolean {
        if (date) {
            return isNullOrUndefined(date) ? false : true;
        }
        else {
            return false;
        }

    }

    public static validateIncludeTerritory(territory: string): boolean {
        return isNullOrUndefined(territory) ? false : true;
    }
    public static validateExcludeTerritory(territory: string): boolean {
        return isNullOrUndefined(territory) ? false : true;
    }
    public static checkValidateTerritory(territory: string, lookups?: ILookupDictionary): boolean {
        if (territory.length > 0) {
            let resultTerritory = territory.split(',');
            let territoriesLookup = getLookupValues(TERRITORY_LOOKUP, lookups);
            let territoryCodes = territoriesLookup.map(item => item.code);
            return resultTerritory.every(item => territoryCodes.includes(item.toUpperCase()));
        } else {
            return true;
        }

    }

    public static validateTerritoryAdded(territoryInclude: string, territoryExclude: string): boolean {
        let territoriesInclude;
        let territoriesExclude;
        if (territoryInclude && territoryExclude) {
            territoriesInclude = territoryInclude.split(',').map(item => item.toUpperCase());
            territoriesExclude = territoryExclude.split(',').map(item => item.toUpperCase());
            const setA = new Set(territoriesInclude);
            return territoriesExclude.some(item => setA.has(item))

        }
    }


    public static validateInterestedParty(account: ISocIP, lookups: ILookupDictionary): IValidationMessage[] {
        if (account.ipRepresentations && account.ipRepresentations.length !== 0) {
            let accountIsValid: IValidationMessage[];
            accountIsValid = [];
            let ignoringProps = ['agencyID',
                'agencyName',
                'amendedDateTime',
                'applicableTerritories',
                'economicRightDescription',
                'externalReferenceID',
                'isAgreementEnded',
                'isDeleted',
                'isWorld',
                'lastModifiedDate',
                'representationID',
                'sharePercentage',
                'signedDate',
                'socIPID',
                'territories', 'shareSet', 'fromDate', 'toDate','societyName'];
            for (var i = 0; i < account.ipRepresentations.length; i++) {
                if (!InterestedPartyOnSaveValidator.validateFromDate(account.ipRepresentations[i].fromDate.value as any))
                    accountIsValid.push({ messageKey: "interestedPartyDateFromValidationMessage" });
                if (!InterestedPartyOnSaveValidator.validateToDate(account.ipRepresentations[i].toDate.value as any))
                    accountIsValid.push({ messageKey: "interestedPartyDateToValidationMessage" });
                if (Boolean((account.ipRepresentations[i].creationClass.value as any)) === false)
                    accountIsValid.push({ messageKey: "interestedPartyCreationClassValidationMessage" });
                if (Boolean((account.ipRepresentations[i].economicRights.value as any)) === false)
                    accountIsValid.push({ messageKey: "interestedPartyEconomicRightValidationMessage" });
                if (Boolean((account.ipRepresentations[i].societyName.value as any)) === false)
                    accountIsValid.push({ messageKey: "interestedPartySocietyNameValidationMessage" });
                if (Boolean((account.ipRepresentations[i].role.value as any)) === false)
                    accountIsValid.push({ messageKey: "interestedPartyRoleValidationMessage" });
                let includeTerritories;
                let excludeTerritories;
                if (account.ipRepresentations[i].applicableTerritories) {
                    includeTerritories = account.ipRepresentations[i].applicableTerritories.filter(x => x.includeExclude === 'I')
                    excludeTerritories = account.ipRepresentations[i].applicableTerritories.filter(x => x.includeExclude === 'E')
                    if (includeTerritories.length === 0) {
                        if (excludeTerritories.length > 0) {
                            accountIsValid.push({ messageKey: "interestedPartyExcludeTerritoryValidationMessage" });
                        }
                    }
                }
                if (account.ipRepresentations[i].includeTerritories.value.length === 0) {
                    if (account.ipRepresentations[i].excludeTerritories.value.length !== 0) {
                        accountIsValid.push({ messageKey: "interestedPartyExcludeTerritoryValidationMessage" });
                    }
                }
                if (!InterestedPartyOnSaveValidator.checkValidateTerritory(account.ipRepresentations[i].includeTerritories.value, lookups)) {
                    accountIsValid.push({ messageKey: "interestedPartyInvalidIncludeTerritoryMessage" });
                }
                if (!InterestedPartyOnSaveValidator.checkValidateTerritory(account.ipRepresentations[i].excludeTerritories.value, lookups)) {
                    accountIsValid.push({ messageKey: "interestedPartyInvalidExcludeTerritoryMessage" });
                }
                if (InterestedPartyOnSaveValidator.validateTerritoryAdded(account.ipRepresentations[i].includeTerritories.value, account.ipRepresentations[i].excludeTerritories.value)) {
                    accountIsValid.push({ messageKey: "interestedPartyInvalidTerritoriesAddedMessage" });
                }


               

                let fromDate = new Date(account.ipRepresentations[i].fromDate.value as any); // Example date in 'YYYY-MM-DD' format
                let toDate = new Date(account.ipRepresentations[i].toDate.value as any);   // Example date in 'YYYY-MM-DD' format
                // Compare dates
                if (fromDate > toDate) {
                    accountIsValid.push({ messageKey: "interestedPartyToDateValidationMessage" });
                }

            }
            function areAllObjectsUnique(arr: IIPRepresentation[], ignoreProps: string[]): boolean {
                const seen = new Set();

                for (const obj of arr) {

                    const uniqueKey = JSON.stringify(
                        Object.keys(obj)
                            .filter(key => !ignoreProps.includes(key))
                            .sort()
                            .reduce((acc, key) => {
                                acc[key] = obj[key];
                                return acc;
                            }, {})
                    );

                    if (seen.has(uniqueKey)) {
                        return false;
                    }

                    seen.add(uniqueKey);
                }

                return true;
            }
            
            function areIncludeTerritoriesNotUnique(arr1, arr2) {
                return arr1.some(item => arr2.includes(item));
            }
            

            function findOverlappingRecords(records,lookups) {
                for (let i = 0; i < records.length; i++) {
                    for (let j = i + 1; j < records.length; j++) {
                        const record1 = records[i];
                        const record2 = records[j];
                        const fromDate1 = new Date(record1.fromDate.value);
                        const toDate1 = new Date(record1.toDate.value);
                        const fromDate2 = new Date(record2.fromDate.value);
                        const toDate2 = new Date(record2.toDate.value);
                        if ((fromDate1 <= toDate2) && (fromDate2 <= toDate1)) {
                            let updatedArray;
                            if (lookups) {
                                let societyLookUp = getLookupValues(AGENCY_LOOKUP, lookups);
                                let agency
                                updatedArray = [record1, record2].map(lineItem => {
                                    if (societyLookUp) {
                                        if (lineItem.agencyID && Number(lineItem.societyName.value)) {
                                            agency = societyLookUp.filter(x => x.code === (lineItem.societyName.value as any))

                                        } else if (lineItem.agencyID) {
                                            agency = societyLookUp.filter(x => x.code === (lineItem.agencyID as any))
                                        } else {
                                            agency = societyLookUp.filter(x => x.code === (lineItem.societyName.value as any))
                                        }
                                    }
                                    const isAgency = agency && agency.length;
                                    return { ...lineItem, societyName: { value: isAgency ? agency[0].description : '', inputType: DROPDOWN_INPUT } }
                                })

                            }
                            if (!areAllObjectsUnique(updatedArray, ignoringProps)) {
                                if (areIncludeTerritoriesNotUnique(record1['includeTerritories'].value.split(','), record2['includeTerritories'].value.split(','))) {
                                    accountIsValid.push({ messageKey: "interestedPartyDateOverlapMessage" })
                                }
                            }
                        }
                    }
                }
            }
            findOverlappingRecords(account.ipRepresentations, lookups);

            return accountIsValid;
        } else {
            return []
        }
    }
}