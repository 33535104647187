import { connect } from "react-redux";
import IAppState from "../../../../redux/types/IAppState";
import { showModal, hideModal, showYesNoPrompt, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import {
    resetPagination,
    updatePagination,
    resetMessageBanner,
    updateUsageField,
    setEditableFields,
    nextWorkflowInSession,
    addUsageDetailsShareIPRow,
    updateUsageGroupLocation,
    updateUsageGroupSetListAdditional,
    updateUsageGroupVenueDetails,
    expandUsageDetails
} from "../../../../redux/reducers/RepertoireReducer";

import {
    saveUsageGroupThunk,
    updateComponentFieldItemsThunk,
    getWorkDetailsThunk,
    getProductDetailsThunk,
    getMatchingSourcesThunk,
    cancelWorkflowSessionThunk,
    skipWorkflowInSessionThunk,
    skipUsageWorkflowInSessionThunk,
    saveWorkflowThunk,
    updateWorkflowAndEntityStatusThunk,
    refreshUsageGroupDetailsThunk,
    updateClaimWorkflowAndEntityStatusThunk,
    searchUsagePoolsThunk,
    getUsagePoolDetailsThunk
} from "../../../../redux/thunks/RepertoireThunks";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IUsageGroupState } from "../../../../redux/types/IUsageGroupState";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IWorkflowSearchResult } from "../../../types/IWorkflowSearchResult";
import { USAGE_WORKFLOW } from "../../../Consts";
import { IWorkflowParams } from "../../../types/IWorkFlowParams";
import { IUsageDetailsAdjustmentsDataItem } from "../usageRowDropdown/UsageRowDropdown";
import { CleanUploadFilesState } from "../../../../redux/reducers/DataIngestionReducer";
import { getDataIngestionComponentDataThunk, getFilesystemsThunk } from "../../../../redux/thunks/DataIngestionThunks";
import { IUsageGroupLocation } from "../../../types/usageTypes/IUsageGroupLocation";
import { IUsageGroupSetListAdditional } from "../../../types/usageTypes/IUsageGroupSetListAdditional";
import { IUsageGroupVenueDetails } from "../../../types/usageTypes/IUsageGroupVenueDetails";
import { IUsagePoolSearchQuery } from "../../../types/usageTypes/IUsagePoolSearchQuery";
import { ITreeData } from "../../../types/ITreeData";
import UsageMaintenancePageScrollListener from "./UsageMaintenancePageScrollListener";

export default connect(
    (state: IAppState) => ({
        searchViewData: state.repertoire.componentData.UsageSearchView,
        searchResultsTableData: state.repertoire.componentData.ResultsTable,
        searchSuccessful: state.repertoire.searchSuccessful,
        usageMaintenanceGeneralViewData:
            state.repertoire.componentData.UsageMaintenanceGeneralDataView,
        usageMaintenanceGridsViewData: state.repertoire.componentData.UsageMaintenanceGridsView,
        usageDataGridTableData: state.repertoire.componentData.DataGridTable,
        tabs: state.repertoire.tabs,
        activeTab: state.repertoire.activeTab,
        usageGroup: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].usageMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].usageMaintenanceState.usageGroup,
        loaded: state.repertoire.tabs[state.repertoire.activeTab].usageMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].usageMaintenanceState.loaded,
        dataActionList: state.repertoire.tabs[state.repertoire.activeTab].dataActionToolbar,
        currentUser: state.account.currentUser,
        usageMaintenanceSaveResultData: state.repertoire.componentData.SaveResult,
        saveInProgress: state.repertoire.saveInProgress,
        usageMaintenanceToolbarData: state.repertoire.componentData.UsageMaintenanceToolbar,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        distributions: state.dataIngestion.distributions,
        editableFields: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].editableFields,
        lookupValues: state.lookupEntities,
        workMaintenanceGeneralDataViewData: state.repertoire.componentData.WorkMaintenanceGeneralDataView,
        productMaintenanceGeneralDataViewData: state.repertoire.componentData.ProductMaintenanceGeneralDataView,
        lookupSources: state.repertoire.matchingSourceTreeData,
        workflowFieldsData: state.repertoire.componentData.WorkflowFieldsData,
        isWorkflowSessionStarted: state.repertoire.isWorkflowSessionStarted,
        workflowSessionItems: state.repertoire.workflowSessionItems,
        currentWorkflowIndex: state.repertoire.currentWorkflowIndex,
        isOpenForWorkflowSession: state.repertoire.tabs[state.repertoire.activeTab].isEntityForWorkflowSession,
        formatFields: state.repertoire.tabs[state.repertoire.activeTab].formatFields,
        roles: state.account.roles,
        matchingSearchViewDefaults: {
            usageMatchingSearchDefaultsProducts: state.repertoire.usageMatchingDefaultsProducts,
            usageMatchingSearchDefaultsWorks: state.repertoire.usageMatchingDefaultsWorks
        },
        containerDetailsWindowComponentData: state.dataIngestion.componentData.ContainerDetailsWindow,
        usagePoolsSearchResults: state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].usagePoolSearchResult && state.repertoire.tabs[state.repertoire.activeTab].usagePoolSearchResult,
        pool: state.repertoire.tabs[state.repertoire.activeTab].usagePoolMaintenanceState && state.repertoire.tabs[state.repertoire.activeTab].usagePoolMaintenanceState.usagePool,
        expandedUsageDetailsRow:state.repertoire.tabs[state.repertoire.activeTab] && state.repertoire.tabs[state.repertoire.activeTab].expandedUsageDetails,

    }),
    dispatch => ({
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) => dispatch(showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)),
        hideModal: () => dispatch(hideModal()),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps, message?: string) => dispatch(showYesNoPrompt(title, props, message)),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        saveChanges: (updatedUsage: IUsageGroupState, isWorkflowSession: boolean = false,
            workflowsSessionItems?: IWorkflowSearchResult[],
            currentWorkflowIndex?: number,
            activeTab?: number,
            isOpenForWorkflowSession?: boolean) => dispatch<any>(saveUsageGroupThunk(updatedUsage, isWorkflowSession, workflowsSessionItems, currentWorkflowIndex, activeTab, isOpenForWorkflowSession)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) =>
            dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        updateUsageField: (value: any, fieldName: IRepertoireStateKeys) => dispatch(updateUsageField(value, fieldName)),
        setEditableFields: (componentFieldName: string, componentInstance: string) =>
            dispatch<any>(setEditableFields(componentFieldName, componentInstance)),
        showUpdateFieldsModal: (tittle: string, props: IShowFieldSettingViewModalProps) => dispatch<any>(showUpdateFieldsModal(tittle, props)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        getProductDetails: (dataSource: string, productCoreID: number, productMaintenanceGeneralDataView: IRepertoireComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(getProductDetailsThunk(dataSource, productCoreID, productMaintenanceGeneralDataView, formats)),
        getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataActions?: IDataActionToolbar[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], openEntityForWorflowSession?: boolean) =>
            dispatch<any>(getWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes, openEntityForWorflowSession)),
        getSources: () => dispatch<any>(getMatchingSourcesThunk()),
        nextWorkflowItem: () => dispatch(nextWorkflowInSession()),
        cancelWorkflowSession: (activeTab: number) => dispatch<any>(cancelWorkflowSessionThunk(activeTab)),
        skipWorkflowItem: (activeTab: number
            , currentWorkflowIndex: number
            , workflows: IWorkflowSearchResult[]) => dispatch<any>(skipUsageWorkflowInSessionThunk(activeTab, currentWorkflowIndex, workflows)),
        skipWorkWorkflowItem: (activeTab: number
            , currentWorkflowIndex: number
            , workflows: IWorkflowSearchResult[]
            , lookups: ILookupDictionary
            , otherIndicatorsWorkFlagTypes: string[]
            , dataActions: IDataActionToolbar[] = []
            , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
            , formats: FormatFields[]
            , readonlyIndicatorsWorkFlagTypes: string[]) => dispatch<any>(skipWorkflowInSessionThunk(activeTab, currentWorkflowIndex, workflows, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes)),
        refreshUsageGroupDetails: (usageID: string, activeTabItem: ITabReduxItem) =>
            dispatch<any>(refreshUsageGroupDetailsThunk(usageID, activeTabItem)),
        saveWorkflow: (workflowparams: IWorkflowParams) =>
            dispatch<any>(saveWorkflowThunk(workflowparams, USAGE_WORKFLOW)),
        addUsageDetailsShareIPRow: (value: IUsageDetailsAdjustmentsDataItem[], usageRowViewIndex: number, usageDetailsIndex: number) => dispatch(addUsageDetailsShareIPRow(value, usageRowViewIndex, usageDetailsIndex)),
        cleanUploadFilesState: () => dispatch(CleanUploadFilesState()),
        getFilesystems: (isRepertoireModule?: boolean) => dispatch<any>(getFilesystemsThunk(isRepertoireModule)),
        getDataIngestionComponentData: () => dispatch<any>(getDataIngestionComponentDataThunk()),
        updateLocation: (newLocation: IUsageGroupLocation) => dispatch(updateUsageGroupLocation(newLocation)),
        updateSetListAdditional: (newSetListAdditional: IUsageGroupSetListAdditional) => dispatch(updateUsageGroupSetListAdditional(newSetListAdditional)),
        updateUsageGroupVenueDetails: (newVenueDetails: IUsageGroupVenueDetails) => dispatch(updateUsageGroupVenueDetails(newVenueDetails)),
        updateWorkflowAndEntityStatus: (workflowparams: IWorkflowParams, approvalStatus?: boolean, refreshDetails?: () => void) =>
            dispatch<any>(updateWorkflowAndEntityStatusThunk(workflowparams, approvalStatus!, USAGE_WORKFLOW, refreshDetails)),
        updateClaimWorkflowAndEntityStatus : (workflowparams: IWorkflowParams,callFrom:string, refreshDetails: () => void) =>
        dispatch<any>(updateClaimWorkflowAndEntityStatusThunk(workflowparams, callFrom, refreshDetails)),
        searchUsagePools: (query: IUsagePoolSearchQuery) =>
            dispatch<any>(searchUsagePoolsThunk(query)),
        getUsagePool: (poolId: number, matchingSources: ITreeData[],isPoolMaintainence?:boolean) => dispatch<any>(getUsagePoolDetailsThunk(poolId, matchingSources,false)),
        expandUsageDetail: (usageDetail: number) => dispatch<any>(expandUsageDetails(usageDetail)),
    })
)(UsageMaintenancePageScrollListener);