import { IMenuItem } from "../../core/types/IMenuItem";
import { LAST_24_HOURS_FIELD } from "../../home/components/timeRangeSelector/TimeRangeSelectorFields";
import { ICustomerLogo } from "../../home/types/ICustomerLogo";
import IHomeComponentData from "../../home/types/IHomeComponentData";
import { IJobSearchResult } from "../../home/types/IJobSearchResult";
import { IJobSearchStateKeys } from "../../home/types/IJobSearchState";
import { IDataSourceBase } from "../../home/types/JobParameterControls/IDataSource";
import { EMPTY_STRING_VALUE, INTRAY } from "../../repertoire/Consts";
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";
import * as HomeActions from "../actions/HomeActions";
import { IDistributionState } from "../types/IDistributionState";
import { IEnableModulesConfigurationParametersItem } from "../types/IEnableModulesConfigurationParameterItem";
import IHomeState from "../types/IHomeState";
import { IResultsPerPage } from "../types/IResultsPerPage";
import { IUsagePoolState } from "../types/IUsagePoolState";

const initialState: IHomeState = {
    menuItems: [],
    currentPage: undefined,
    isSearching: false,
    dataSource: INTRAY,
    contributorsSearchResult: [],
    resultsPerPage: [],
    componentData: {
        SearchResultsTable:
            { fields: [] },
        SearchView: {
            fields: []
        },
        JobSearchView: {
            fields: []
        },
        JobSearchResultsView: {
            fields: []
        },
        JobSearchResultsGrid: {
            fields: []
        },
        TimeRangeSelector: {
            fields: []
        },
        ManualJobView: {
            fields: []
        }
    },
    jobSearchState: {
        jobType: "All",
        jobStatus: undefined,
        user: "All",
        customDateRange: false,
        dateRange: LAST_24_HOURS_FIELD,
        dateFrom: undefined,
        dateTo: undefined,
    },
    jobSearchResults: [],
    topMenuIsActive: false,
    newSearch: false,
    enableModules: [],
    manualJobType: "",
    manualJobParameters: "",
    cwrAckHWM: [],
    iceATF:[],
    automaticAgreementGHWM: [],
    displayCustomerLogo: false,
    separatePublishedCheckbox: false,
    logoAddresses: [],
    customerName: EMPTY_STRING_VALUE,
    manualJobDataSources: {},
    searchSuccessful: undefined,
    isLogoRequested: false,
    openDistributions: [],
    allDistributions:[],
    pools: [],
    cwrgHighWaterMark: undefined,
   
}

export function getHomeComponentDataRequest(): HomeActions.HomeComponentDataRequest {
    return {
        type: HomeActions.FETCH_HOME_COMPONENT_DATA_REQUEST
    };
}

export function getHomeComponentDataSuccess(componentData: IHomeComponentData): HomeActions.HomeComponentDataSuccess {
    return {
        type: HomeActions.FETCH_HOME_COMPONENT_DATA_SUCCESS,
        payload: {
            componentData
        }
    };
}

export function getHomeComponentDataFailure(error: string): HomeActions.HomeComponentDataFailure {
    return {
        type: HomeActions.FETCH_HOME_COMPONENT_DATA_FAILURE,
        payload: { error }
    };
}

export function getMenuItemsRequest(): HomeActions.MenuItemsRequest {
    return {
        type: HomeActions.FETCH_MENU_ITEMS_REQUEST
    };
}

export function getMenuItemsSuccess(menuItems: IMenuItem[]): HomeActions.MenuItemsSuccess {
    return {
        type: HomeActions.FETCH_MENU_ITEMS_SUCCESS,
        payload: {
            menuItems
        }
    };
}

export function getMenuItemsFailure(error: string): HomeActions.MenuItemsFailure {
    return {
        type: HomeActions.FETCH_MENU_ITEMS_FAILURE,
        payload: { error }
    };
}

export function updateJobSearchStateField(value: any, field: string): HomeActions.UpdateJobSearchStateField {
    return {
        type: HomeActions.UPDATE_JOB_SEARCH_STATE_FIELD,
        payload: {
            value,
            field
        }
    }
}

export function searchJobsRequest(): HomeActions.SearchJobsRequest {
    return {
        type: HomeActions.SEARCH_JOBS_REQUEST
    };
}

export function searchJobsSuccess(jobs: IJobSearchResult[]): HomeActions.SearchJobsSuccess {
    return {
        type: HomeActions.SEARCH_JOBS_SUCCESS,
        payload: {
            jobs
        }
    };
}

export function searchJobsFailure(error: string): HomeActions.SearchJobsFailure {
    return {
        type: HomeActions.SEARCH_JOBS_FAILURE,
        payload: { error }
    };
}

export function setCurrentPage(link: string): HomeActions.SetCurrentPage {
    return {
        type: HomeActions.SET_CURRENT_PAGE,
        payload: { link }
    };
}

export function toggleTopMenuIsActive(): HomeActions.ToggleTopMenuIsActive {
    return {
        type: HomeActions.TOGGLE_TOP_MENU_IS_ACTIVE
    };
}

export function getEnabledModuleRequest(): HomeActions.GetEnabledModuleRequest {
    return {
        type: HomeActions.GET_ENABLED_MODULES_REQUEST
    };
}

export function getEnabledModuleSuccess(
    param: IEnableModulesConfigurationParametersItem[]
): HomeActions.GetEnabledModuleSuccess {
    return {
        type: HomeActions.GET_ENABLED_MODULES_SUCCESS,
        payload: {
            param: param
        }
    };
}

export function getEnabledModuleFailure(error: string): HomeActions.GetEnabledModuleFailure {
    return {
        type: HomeActions.GET_ENABLED_MODULES_FAILURE,
        payload: {
            error
        }
    };
}

export function manualJobViewSuccess(): HomeActions.ManualJobViewSuccess {
    return {
        type: HomeActions.MANUAL_JOB_VIEW_SUCCESS
    };
}

export function manualJobViewFailure(): HomeActions.ManualJobViewFailure {
    return {
        type: HomeActions.MANUAL_JOB_VIEW_FAILURE
    };
}

export function manualJobViewRequest(): HomeActions.ManualJobViewRequest {
    return {
        type: HomeActions.MANUAL_JOB_VIEW_REQUEST
    };
}

export function getManualJobParametersRequest(value: any): HomeActions.GetManualJobParametersRequest {
    return {
        type: HomeActions.GET_MANUAL_JOB_PARAMETERS_REQUEST,
        payload: {
            value
        }
    }
}

export function getManualJobParametersSuccess(jobParameter: any, jobName: any): HomeActions.GetManualJobParametersSuccess {
    return {
        type: HomeActions.GET_MANUAL_JOB_PARAMETERS_SUCCESS,
        payload: {
            jobParameter,
            jobName
        }
    }
}

export function getManualJobParametersFailure(value: any): HomeActions.GetManualJobParametersFailure {
    return {
        type: HomeActions.GET_MANUAL_JOB_PARAMETERS_FAILURE
    }
}

export function triggerManualJobFailure(value: any): HomeActions.TriggerManualJobFailure {
    return {
        type: HomeActions.TRIGGER_MANUAL_JOB_FAILURE
    }
}

export function getUsageFilenamesFailure(value: any): HomeActions.GetUsageFileNamesFailure {
    return {
        type: HomeActions.GET_USAGE_FILENAMES_FAILURE
    }
}

export function triggerManualJobSuccess(): HomeActions.TriggerManualJobSuccess {
    return {
        type: HomeActions.TRIGGER_MANUAL_JOB_SUCCESS
    }
}
export function getUsageFilenamesSuccess(value:any): HomeActions.GetUsageFileNamesSuccess {
    return {
        type:HomeActions.GET_USAGE_FILENAMES_SUCCESS,
        payload: {
            value
        }
    }
}

export function triggerManualJobRequest(): HomeActions.TriggerManualJobRequest {
    return {
        type: HomeActions.TRIGGER_MANUAL_JOB_REQUEST
    }
}

export function getUsageFilenamesRequest(): HomeActions.GetUsageFileNamesRequest {
    return {
        type: HomeActions.GET_USAGE_FILENAMES_REQUEST
    }
}

export function getScheduledJobRequest(): HomeActions.GetScheduledJobRequest {
    return {
        type: HomeActions.GET_SCHEDULED_JOB_REQUEST
    }
}

export function getScheduledJobSuccess(value:any): HomeActions.GetScheduledJobSuccess {
    return {
        type: HomeActions.GET_SCHEDULED_JOB_SUCCESS,
        payload: {
            value
        }
    }
}

export function getScheduledJobFailure(value:any): HomeActions.GetScheduledJobFailure {
    return {
        type: HomeActions.GET_SCHEDULED_JOB_FAILURE,
        payload: {
            value
        }
    }
}

export function upsertScheduledJobAsyncRequest(): HomeActions.UpsertScheduledJobAsyncRequest {
    return {
        type: HomeActions.UPSERT_SCHEDULED_JOB_REQUEST
    }
}

export function upsertScheduledJobAsyncSuccess(value:any): HomeActions.UpsertScheduledJobAsyncSuccess {
    return {
        type: HomeActions.UPSERT_SCHEDULED_JOB_SUCCESS,
        payload: {
            value
        }
    }
}

export function upsertScheduledJobAsyncFailure(): HomeActions.UpsertScheduledJobAsyncFailure {
    return {
        type: HomeActions.UPSERT_SCHEDULED_JOB_FAILURE,
    }
} 

export function getAllUsageFilenamesFromDistributionRequest(): HomeActions.GetAllUsageFilenamesFromDistributionRequest {
    return {
        type: HomeActions.GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_REQUEST
    }
}

export function getAllUsageFilenamesFromDistributionSuccess(value:any): HomeActions.GetAllUsageFilenamesFromDistributionSuccess {
    return {
        type:HomeActions.GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_SUCCESS,
        payload: {
            value
        }
    }
}

export function getAllUsageFilenamesFromDistributionFailure(value: any): HomeActions.GetAllUsageFilenamesFromDistributionFailure {
    return {
        type: HomeActions.GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_FAILURE
    }
}

export function clearHomeCache(): HomeActions.ClearHomeCache {
    return { type: HomeActions.CLEAR_HOME_CACHE }
}

export function getCWRAckHWMSuccess(value: any): HomeActions.GetCWRAckHWMSuccess {
    return {
        type: HomeActions.GET_CWR_ACK_HWM_SUCCESS,
        payload: {
            value
        }
    }
}

export function getCWRAckHWMFailure(value: any): HomeActions.GetCWRAckHWMFailure {
    return {
        type: HomeActions.GET_CWR_ACK_HWM_FAILURE,
        payload: {
            value
        }
    }
}

export function getAutomaticAgreementGHWMSuccess(value: any): HomeActions.GetAutomaticAgreementGHWMSuccess {
    return {
        type: HomeActions.GET_AAG_HWM_SUCCESS,
        payload: {
            value
        }
    }
}

export function getAutomaticAgreementGHWMFailure(value: any): HomeActions.GetAutomaticAgreementGHWMFailure {
    return {
        type: HomeActions.GET_AAG_HWM_FAILURE,
        payload: {
            value
        }
    }
}

export function getIceATFSuccess(value: any): HomeActions.getIceATFSuccess {
    return {
        type: HomeActions.GET_ICE_ATF_SUCCESS,
        payload: {
            value
        }
    }
}

export function getIceATFFailure(value: any): HomeActions.getIceATFFailure {
    return {
        type: HomeActions.GET_ICE_ATF_FAILURE,
        payload: {
            value
        }
    }
}

export function getEmuwHwmRequest(distributionId: any): HomeActions.GetEmuwHwmRequest {
    return {
        type: HomeActions.GET_EMUW_HWM_REQUEST,
        payload: {
            distributionId
        }
    }
}

export function getEmuwHwmSuccess(value: any): HomeActions.GetEmuwHwmSuccess {
    return {
        type: HomeActions.GET_EMUW_HWM_SUCCESS,
        payload: {
            value
        }
    }
}

export function getEmuwHwmFailure(value: any): HomeActions.GetEmuwHwmFailure {
    return {
        type: HomeActions.GET_EMUW_HWM_FAILURE,
        payload: {
            value
        }
    }
}

export function getOpenDistributionsRequest(): HomeActions.GetOpenDistributionsRequest {
    return {
        type: HomeActions.GET_OPEN_DISTRIBUTIONS_REQUEST
    }
}

export function getOpenDistributionsSuccess(openDistributions: IDistributionState[]): HomeActions.GetOpenDistributionsSuccess {
    return {
        type: HomeActions.GET_OPEN_DISTRIBUTIONS_SUCCESS,
        payload: {
            openDistributions
        }
    }
}

export function getOpenDistributionsFailure(value: any): HomeActions.GetOpenDistributionsFailure {
    return {
        type: HomeActions.GET_OPEN_DISTRIBUTIONS_FAILURE,
        payload: {
            value
        }
    }
}

export function getAllDistributionsRequest(): HomeActions.GetAllDistributionsRequest {
    return {
        type: HomeActions.GET_All_DISTRIBUTIONS_REQUEST
    }
}

export function getAllDistributionsSuccess(allDistributions: IDistributionState[]): HomeActions.GetAllDistributionsSuccess {
    return {
        type: HomeActions.GET_ALL_DISTRIBUTIONS_SUCCESS,
        payload: {
            allDistributions
        }
    }
}

export function getAllDistributionsFailure(value: any): HomeActions.GetAllDistributionsFailure {
    return {
        type: HomeActions.GET_ALL_DISTRIBUTIONS_FAILURE,
        payload: {
            value
        }
    }
}

export function getPoolsRequest(): HomeActions.GetPoolsRequest {
    return {
        type: HomeActions.GET_POOLS_REQUEST
    }
}

export function getPoolsSuccess(pools: IUsagePoolState[]): HomeActions.GetPoolsSuccess {
    return {
        type: HomeActions.GET_POOLS_SUCCESS,
        payload: {
            pools
        }
    }
}

export function getPoolsFailure(value: any): HomeActions.GetPoolsFailure {
    return {
        type: HomeActions.GET_POOLS_FAILURE,
        payload: {
            value
        }
    }
}

export function getDisplayCustomerLogoRequest(): HomeActions.GetDisplayCustomerLogoRequest {
    return {
        type: HomeActions.DISPLAY_CUSTOMER_LOGO_REQUEST,
        payload: {
            requested: true
        }
    };
}

export function getDisplayCustomerLogoSuccess(value: number): HomeActions.GetDisplayCustomerLogoSuccess {
    return {
        type: HomeActions.DISPLAY_CUSTOMER_LOGO_SUCCESS,
        payload: {
            value
        }
    };
}

export function getDisplayCustomerLogoFailure(): HomeActions.GetDisplayCustomerLogoFailure {
    return {
        type: HomeActions.DISPLAY_CUSTOMER_LOGO_FAILURE,

    };
}

export function getSeparatePublishedCheckboxRequest(): HomeActions.GetSeparatePublishedCheckboxRequest {
    return {
        type: HomeActions.SEPARATE_PUBLISHED_CHECKBOX_REQUEST
    };
}

export function getSeparatePublishedCheckboxSuccess(value: number): HomeActions.GetSeparatePublishedCheckboxSuccess {
    return {
        type: HomeActions.SEPARATE_PUBLISHED_CHECKBOX_SUCCESS,
        payload: {
            value
        }
    };
}

export function getSeparatePublishedCheckboxFailure(error): HomeActions.GetSeparatePublishedCheckboxFailure {
    return {
        type: HomeActions.SEPARATE_PUBLISHED_CHECKBOX_FAILURE,

    };
}

export function getCustomerNameSuccess(customerName: string): HomeActions.GetCustomerNameSuccess {
    return {
        type: HomeActions.GET_CUSTOMER_NAME_SUCCESS,
        payload: {
            name: customerName
        }
    };
}

export function getCustomerLogoAddresses(customerLogos: ICustomerLogo[], customerName: string): HomeActions.GetLogoAddresses {
    return {
        type: HomeActions.GET_CUSTOMER_LOGO_ADDRESSES_SUCCESS,
        payload: {
            customerLogos: customerLogos,
            customerName: customerName
        }
    }
}

export function getManualJobDataSourcesRequest(): HomeActions.FetchManualJobDataSourcesRequest {
    return {
        type: HomeActions.FETCH_MANUAL_JOB_DATA_SOURCES_REQUEST
    };
}

export function getManualJobDataSourcesSuccess(dataSources: { [key: string]: IDataSourceBase }): HomeActions.FetchManualJobDataSourcesSuccess {
    return {
        type: HomeActions.FETCH_MANUAL_JOB_DATA_SOURCES_SUCCESS,
        payload: {
            DataSources: dataSources
        }
    };
}

export function getManualJobDataSourcesFailure(error: string): HomeActions.FetchManualJobDataSourcesFailure {
    return {
        type: HomeActions.FETCH_MANUAL_JOB_DATA_SOURCES_FAILURE,
        payload: { error }
    };
}


export function searchContributorsRequestHomeModal(): HomeActions.SearchContributorRequest {
    return {
        type: HomeActions.SEARCH_CONTRIBUTORS_REQUEST_HOME_MODAL
    };
}

export function searchContributorsSuccessHomeModal(
    result: IContributorSearchResult[]
): HomeActions.SearchContributorsSuccess {
    return {
        type: HomeActions.SEARCH_CONTRIBUTORS_SUCCESS_HOME_MODAL,
        payload: {
            contributorsResult: result
        }
    };
}

export function searchContributorsFailureHomeModal(
    error: string
): HomeActions.SearchContributorsFailure {
    return {
        type: HomeActions.SEARCH_CONTRIBUTORS_FAILURE_HOME_MODAL,
        payload: {
            error
        }
    };
}

export function updatePaginationHomeModal(indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string): HomeActions.UpdatePagination {
    return {
        type: HomeActions.UPDATE_PAGINATION_HOME_MODAL,
        payload: {
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            repertoireSection
        }
    }
}

export function resetPaginationHomeModal(repertoireSection: string): HomeActions.ResetPagination {
    return {
        type: HomeActions.RESET_PAGINATION_HOME_MODAL,
        payload: {
            repertoireSection
        }
    };
}

export function updateManualJobParams(params: any): HomeActions.UpdateManualJobParams {
    return {
        type: HomeActions.UPDATE_MANUAL_JOB_PARAMS,
        payload: {
            params
        }
    };
}

export function getCWRGHighWaterMarkRequest(): HomeActions.GetCwrgHwmRequest {
    return {
        type: HomeActions.GET_CWRG_HWM_REQUEST
    };
}

export function getCWRGHighWaterMarkSuccess(hwm: string): HomeActions.GetCwrgHwmSuccess {
    return {
        type: HomeActions.GET_CWRG_HWM_SUCCESS,
        payload: { hwm }
    };
}

export function getCWRGHighWaterMarkFailure(error: string): HomeActions.GetCwrgHwmFailure {
    return {
        type: HomeActions.GET_CWRG_HWM_FAILURE,
        payload: { error }
    };
}

export function setCWRGHighWaterMarkState(hwm: string): HomeActions.SetCwrgHwm {
    return {
        type: HomeActions.SET_CWRG_HWM,
        payload: { hwm }
    };
}


const homeReducer = (
    state: IHomeState | undefined,
    action: HomeActions.ActionTypes
): IHomeState => {
    if (state === undefined) {
        return initialState;
    }
    switch (action.type) {
        case HomeActions.FETCH_HOME_COMPONENT_DATA_SUCCESS: {
            const { componentData } = action.payload;
            return {
                ...state,
                componentData
            };
        }
        case HomeActions.FETCH_MENU_ITEMS_SUCCESS: {
            const { menuItems } = action.payload;
            return {
                ...state,
                menuItems
            }
        }
        case HomeActions.SET_CURRENT_PAGE: {
            const { link } = action.payload;
            const flattened: IMenuItem[] = [];
            state.menuItems.map(m => { flattened.push(m); m.children.map(c => flattened.push(c)) });
            let currentPage: IMenuItem;
            if (link === '/') {
                currentPage = flattened.find(i => link === i.link);
            }
            else {
                currentPage = flattened.find(i => link.endsWith(i.link));
            }
            return {
                ...state,
                currentPage,
                topMenuIsActive: false
            }
        }
        case HomeActions.TOGGLE_TOP_MENU_IS_ACTIVE: {
            const isActive: boolean = state.topMenuIsActive === true ? false : true;

            return {
                ...state,
                topMenuIsActive: isActive
            }
        }
        case HomeActions.UPDATE_JOB_SEARCH_STATE_FIELD: {
            const { field, value } = action.payload;
            const jobSearchState: any = Object.assign({}, state.jobSearchState);
            jobSearchState[field] = value as Pick<IJobSearchStateKeys, keyof IJobSearchStateKeys>
            if (jobSearchState['customDateRange'] == false) {
                delete jobSearchState['dateTo'];
                delete jobSearchState['dateFrom'];
            }
            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;
            newState.jobSearchState = jobSearchState;
            return newState;
        }
        case HomeActions.SEARCH_JOBS_SUCCESS: {
            const { jobs } = action.payload;

            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;
            newState.jobSearchResults = jobs;
            newState.newSearch = false;
            return newState;
        }

        case HomeActions.SEARCH_JOBS_REQUEST: {

            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;
            newState.newSearch = true;

            return newState;
        }
        case HomeActions.GET_ENABLED_MODULES_SUCCESS: {
            const { param } = action.payload;

            return {
                ...state,
                enableModules: param
            }
        }
        case HomeActions.MANUAL_JOB_VIEW_REQUEST: {
            const newState: IHomeState = JSON.parse(JSON.stringify(state)) as IHomeState;
            return newState;
        }
        case HomeActions.MANUAL_JOB_VIEW_FAILURE: {
            const newState: IHomeState = JSON.parse(JSON.stringify(state)) as IHomeState;
            return newState;
        }
        case HomeActions.MANUAL_JOB_VIEW_SUCCESS: {
            const newState: IHomeState = JSON.parse(JSON.stringify(state)) as IHomeState;
            return newState;
        }
        case HomeActions.GET_OPEN_DISTRIBUTIONS_SUCCESS: {
            const { openDistributions } = action.payload;
            return {
                ...state,
                openDistributions: openDistributions
            }
        }
        case HomeActions.GET_ALL_DISTRIBUTIONS_SUCCESS: {
            const { allDistributions } = action.payload;
            return {
                ...state,
                allDistributions: allDistributions
            }
        }
        case HomeActions.GET_POOLS_SUCCESS: {
            const { pools } = action.payload;
            return {
                ...state,
                pools: pools
            }
        }
        case HomeActions.GET_MANUAL_JOB_PARAMETERS_REQUEST: {
            const { value } = action.payload;
            return {
                ...state,
                manualJobType: value
            };
        }
        case HomeActions.GET_MANUAL_JOB_PARAMETERS_SUCCESS: {
            const { jobParameter, jobName } = action.payload;
            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;
            newState.manualJobParameters = jobParameter;
            newState.manualJobType = jobName;
            return newState;
        }
        case HomeActions.GET_SCHEDULED_JOB_SUCCESS :{
            const { value } = action.payload;
            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;
            newState.scheduledJob = value;
            return newState;
        }
        case HomeActions.GET_USAGE_FILENAMES_SUCCESS: {
            const { value } = action.payload;
            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;
            newState.usageFilenames = value;
            return newState;
        }
        case HomeActions.GET_ALL_USAGE_FILENAMES_FROM_DISTRIBUTION_SUCCESS: {
            const { value } = action.payload;
            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;
            newState.usageFilenames = value;
            return newState;
        }
        case HomeActions.TRIGGER_MANUAL_JOB_REQUEST: {
            return {
                ...state,
            };
        }
        case HomeActions.CLEAR_HOME_CACHE: {
            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;
            newState.menuItems = [];
            newState.currentPage = {
                icon: '',
                children: [],
                isEnabled: false,
                label: '',
                link: '',
                pageHeader: '',
                portalType: ''
            }
            newState.componentData = undefined

            return newState;
        }
        case HomeActions.GET_CWR_ACK_HWM_SUCCESS: {
            const { value } = action.payload;

            return {
                ...state,
                cwrAckHWM: value
            }
        }

        case HomeActions.GET_AAG_HWM_SUCCESS: {
            const { value } = action.payload;

            return {
                ...state,
                automaticAgreementGHWM: value
            }
        }
        case HomeActions.GET_ICE_ATF_SUCCESS: {
            const { value } = action.payload;

            return {
                ...state,
                iceATF: value
            }
        }

        case HomeActions.GET_EMUW_HWM_SUCCESS: {
            const { value } = action.payload;

            return {
                ...state,
                emuwHighWaterMark: value
            }
        }

        case HomeActions.GET_CWRG_HWM_SUCCESS: {
            const { hwm } = action.payload;

            return {
                ...state,
                cwrgHighWaterMark: hwm
            }
        }

        case HomeActions.SET_CWRG_HWM: {
            const { hwm } = action.payload;

            return {
                ...state,
                cwrgHighWaterMark: hwm
            }
        }



        case HomeActions.DISPLAY_CUSTOMER_LOGO_REQUEST: {
            const { requested } = action.payload;
            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;

            newState.isLogoRequested = requested;
            return newState;
        }
        case HomeActions.DISPLAY_CUSTOMER_LOGO_SUCCESS: {
            const { value } = action.payload;
            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;
            newState.displayCustomerLogo = value === 1 ? true : false

            return newState;
        }
        case HomeActions.SEPARATE_PUBLISHED_CHECKBOX_SUCCESS: {
            const { value } = action.payload;
            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;
            newState.separatePublishedCheckbox = value === 1 ? true : false

            return newState;
        }


        case HomeActions.DISPLAY_CUSTOMER_LOGO_FAILURE: {
            return {
                ...state,
                displayCustomerLogo: false,
                isLogoRequested: false
            }
        }


        case HomeActions.GET_CUSTOMER_LOGO_ADDRESSES_SUCCESS: {
            const { customerLogos, customerName } = action.payload;
            const parsedCustomerLogos: ICustomerLogo[] = JSON.parse(JSON.stringify(customerLogos));
            const filteredCustomerLogos = parsedCustomerLogos.find(x => x.Customer = customerName);

            return {
                ...state,
                logoAddresses: filteredCustomerLogos.Addresses
            }
        }

        case HomeActions.GET_CUSTOMER_NAME_SUCCESS: {
            const { name } = action.payload;

            return {
                ...state,
                customerName: name
            }
        }

        case HomeActions.FETCH_MANUAL_JOB_DATA_SOURCES_SUCCESS: {
            const { DataSources } = action.payload;

            return {
                ...state,
                manualJobDataSources: DataSources
            }
        }

        case HomeActions.UPDATE_PAGINATION_HOME_MODAL: {
            const { indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection } = action.payload;
            let resultsPerPageList: IResultsPerPage[] = [];

            state.resultsPerPage.map(r => resultsPerPageList.push(Object.assign({}, r)));
            const rpp = resultsPerPageList.find(r => r.repertoireSection === repertoireSection);

            if (rpp != null) {
                rpp.resultsPerSection = resultsPerPage;
                rpp.indexOfFirstResult = indexOfFirstResult;
                rpp.indexOfLastResult = indexOfLastResult;
            }

            return {
                ...state,
                resultsPerPage: resultsPerPageList
            }
        }
        case HomeActions.RESET_PAGINATION_HOME_MODAL: {
            const { repertoireSection } = action.payload;
            const resultsPerPageList: IResultsPerPage[] = [];

            state.resultsPerPage.map(r => resultsPerPageList.push(Object.assign({}, r)));
            const rpp = resultsPerPageList.find(r => r.repertoireSection === repertoireSection);
            if (rpp != null) {
                rpp.indexOfFirstResult = 0;
                rpp.indexOfLastResult = rpp.resultsPerSection
            }

            return {
                ...state,
                resultsPerPage: resultsPerPageList
            }
        }
        case HomeActions.SEARCH_CONTRIBUTORS_REQUEST_HOME_MODAL:
            return {
                ...state,
                isSearching: true
            };

        case HomeActions.SEARCH_CONTRIBUTORS_SUCCESS_HOME_MODAL: {
            const { contributorsResult } = action.payload;

            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;

            newState.contributorsSearchResult = contributorsResult;
            newState.searchSuccessful = true;
            newState.isSearching = false;
            return newState;
        }
        case HomeActions.SEARCH_CONTRIBUTORS_FAILURE_HOME_MODAL: {
            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;

            newState.contributorsSearchResult = [];
            newState.searchSuccessful = false;
            newState.isSearching = false;
            return newState;
        }
        case HomeActions.UPDATE_MANUAL_JOB_PARAMS: {
            const { params } = action.payload;
            const newState = JSON.parse(JSON.stringify(state)) as IHomeState;
            newState.manualJobParameters = params === '' ? '' :
                JSON.parse(JSON.stringify(params));
            newState.searchSuccessful = true;
            newState.isSearching = false;
            return newState;
        }        
        default:
            return state;
    }
}

export default homeReducer;
