import { throttle } from "lodash";
import * as React from "react";
import { If } from "../../../../core/components/If";
import { DISTRIBUTION_SUB_TYPE_LOOKUP, DISTRIBUTION_TYPE_LOOKUP } from "../../../../lookup/Consts";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showYesNoPrompt, showUpdateFieldsModal, showScheduledJobModalView } from "../../../../redux/reducers/ModalReducer";
import { clearModalSearchResults, copyExistingDistribution, setEditableFields, sortDistributionPoolFieldData, updateDistributionField, updateScheduledJobField } from "../../../../redux/reducers/RepertoireReducer";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IDistributionState } from "../../../../redux/types/IDistributionState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { ITriggerManualJob } from "../../../../redux/types/ITriggerManualJob";
import { ALL_ROLES, EVENT_HANDLER_THROTTLE_TIME, UPDATE_DISTRIBUTIONS_ROLE } from "../../../Consts";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { ITreeData } from "../../../types/ITreeData";
import { IDistributionType } from "../../../types/usageTypes/IDistibutionType";
import { ILastPercentageSearchQuery } from "../../../types/usageTypes/ILastPercentageSearchQuery";
import { IUsagePool } from "../../../types/usageTypes/IUsagePool";
import { IUsagePoolSearchQuery } from "../../../types/usageTypes/IUsagePoolSearchQuery";
import { DistributionMaintenanceGeneralDataView } from "../distributionMaintenanceGeneralDataView/DistributionMaintenanceGeneralDataView";
import { DistributionMaintenanceGridsView } from "../distributionMaintenanceGridsView/DistributionMaintenanceGridsView";
import { DistributionMaintenanceToolbar } from "../distributionMaintenanceToolbar/DistributionMaintenanceToolbar";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IDistributionSubType } from "../../../types/usageTypes/IDistributionSubType";
import { IScheduledJobState } from "../../../../redux/types/IScheduledJobState";

export interface IDistributionMaintenancePageProps {
    distributionMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    scheduledJobModalViewData?: IRepertoireComponentDataItem;
    distributionMaintenanceToolbarData?: IRepertoireComponentDataItem;
    distributionMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    distributionMaintenanceGridsViewData?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    distribution: IDistributionState;
    schedule?: IScheduledJobState[];
    activeTab: number;
    tabs: ITabReduxItem[];
    lookupValues: ILookupDictionary;
    distributionTypes: IDistributionType[];
    distributionSubTypes: IDistributionSubType[];
    hideModal: typeof hideModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    showScheduledJobModalView: typeof showScheduledJobModalView;
    resetMessageBanner: () => void;
    addNewDistribution: (lookupValues: ILookupDictionary, isNew?: boolean, distributionMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) => void;
    updateDistributionField: typeof updateDistributionField;
    saveChanges: (distribution: IDistributionState, distributionMaintenanceGeneralDataViewData: IRepertoireComponentDataItem, lookupValues: ILookupDictionary) => void;
    undoDistribution: (distributionID: number, lookupValues: ILookupDictionary) => void;
    deleteDistribution: (distributionId: number, activeTab: number, distributionCode: string, sources: ITreeData[]) => void;
    triggerManualJob: (manualJob: ITriggerManualJob) => void;
    searchUsagePools: (query: IUsagePoolSearchQuery) => void;
    usagePools: IUsagePool[];
    allocateDistributionPools: (distribution: IDistributionState, undoAllocation: boolean) => void;
    setCDAllocationSchedule?: (distribution: IDistributionState, undoAllocation: boolean) => void;
    showModal: typeof showModal;
    clearModalSearchResults: typeof clearModalSearchResults;
    searchDistributionVersionHistory: (distributionId: number, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[]) => void;
    exportDistributionPools: (distribution: IDistributionState, lookupValues: ILookupDictionary) => void;
    importDistributionPools: (distribution: IDistributionState, fileName: string, lookupValues: ILookupDictionary) => void;
    copyDistribution: typeof copyExistingDistribution
    getLastPercentageValues: (lastPercentageSearchQuery: ILastPercentageSearchQuery, lookups: ILookupDictionary) => void;
    sources?: ITreeData[];
    allocationJobInProgress: boolean;
    roles: string[];
    incrementTempID?: () => void;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    showUpdateFieldsModal: typeof showUpdateFieldsModal;
    editableFields: IRepertoireField[];
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    getCustomer: () => void;
    customer: string;
    usagePoolsSearchResults?: IUsagePool[];
    getUsagePool: (poolId: number, matchingSources: ITreeData[]) => void;
    jobStatusQA?: string;
    runQAOnDistributionPools: (distribution: IDistributionState, undoQA: boolean) => void;
    upsertScheduledJob: (schedule: IScheduledJobState) => void;
    updatedScroll?: number;
    sortDistributionPoolFieldData?: typeof sortDistributionPoolFieldData;
}

interface IDistributionMaintenancePageState {
    toolbarWidth: number;
    isBulkCheckboxActive?: boolean;
}

export class DistributionMaintenancePage extends React.PureComponent<
    IDistributionMaintenancePageProps,
    IDistributionMaintenancePageState
> {
    toolbarParentRef;

    constructor(props: IDistributionMaintenancePageProps) {
        super(props);
        this.state = {
            toolbarWidth: null,
            isBulkCheckboxActive: false,
        };
        this.toolbarParentRef = React.createRef();
    }

    componentDidMount() {
        const { getCustomer } = this.props;
        getCustomer();
    }

    saveDistribution() {
        const { saveChanges, distribution, distributionMaintenanceGeneralDataViewData, lookupValues } = this.props;
        saveChanges(distribution, distributionMaintenanceGeneralDataViewData, lookupValues);
        this.setState({ isBulkCheckboxActive: false });
    }

    allocateDistributionPools() {
        const { distribution, allocateDistributionPools } = this.props;
        allocateDistributionPools(distribution, false);
        this.setState({ isBulkCheckboxActive: false });
    }

    undoAllocationDistributionPools() {
        const { distribution, allocateDistributionPools } = this.props;
        allocateDistributionPools(distribution, true);
    }

    runQAOnDistributionPools = () => {
        const { distribution, runQAOnDistributionPools } = this.props;
        runQAOnDistributionPools(distribution, false);
        this.setState({ isBulkCheckboxActive: false });
    }
    setCDAllocationSchedule = () => {
        const { distribution, setCDAllocationSchedule } = this.props;
        setCDAllocationSchedule(distribution, false);
        this.setState({ isBulkCheckboxActive: false });
    }

    revertQAOnDistributionPools = () => {
        const { distribution, runQAOnDistributionPools } = this.props;
        runQAOnDistributionPools(distribution, true);
        this.setState({ isBulkCheckboxActive: false });
    }

    exportDistributionPools = () => {
        const { distribution, exportDistributionPools, lookupValues } = this.props;
        exportDistributionPools(distribution, lookupValues);
    }

    deleteDistribution() {
        const { activeTab, distribution, sources } = this.props;
        this.props.deleteDistribution(distribution.distributionID, activeTab, distribution.distributionCode, sources);
    }

    undoChanges = () => {
        const { distribution, undoDistribution, lookupValues } = this.props;
        undoDistribution(distribution.distributionID, lookupValues);
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    addDistributionTypeToLookup(lookupValues: ILookupDictionary): ILookupDictionary {
        const {
            distributionTypes
        } = this.props;

        if (distributionTypes === undefined) return lookupValues;
        else if (lookupValues[DISTRIBUTION_TYPE_LOOKUP] === undefined) {
            const lookups: ILookupInstance[] = [];
            distributionTypes.forEach((dt, index) => {
                const lookup: ILookupInstance = {
                    typeID: index,
                    code: dt.description,
                    description: dt.description
                };
                lookups.push(lookup);
            });
            lookupValues[DISTRIBUTION_TYPE_LOOKUP] = {
                entity: DISTRIBUTION_TYPE_LOOKUP,
                lookups: lookups,
                defaultValue: null
            };
            return lookupValues;
        }

        return lookupValues;
    }

    addDistributionSubTypeToLookup(lookupValues: ILookupDictionary): ILookupDictionary {
        const {
            distributionSubTypes
        } = this.props;

        if (distributionSubTypes === undefined) return lookupValues;
        else if (lookupValues[DISTRIBUTION_SUB_TYPE_LOOKUP] === undefined) {
            const lookups: ILookupInstance[] = [];
            distributionSubTypes.forEach((dt, index) => {
                const lookup: ILookupInstance = {
                    typeID: index,
                    code: dt.description,
                    description: dt.description
                };
                lookups.push(lookup);
            });
            lookupValues[DISTRIBUTION_SUB_TYPE_LOOKUP] = {
                entity: DISTRIBUTION_SUB_TYPE_LOOKUP,
                lookups: lookups,
                defaultValue: null
            };
            return lookupValues;
        }

        return lookupValues;
    }
    
    changeBulkCheckbox = (value: boolean) => {
        this.setState({ isBulkCheckboxActive: value });
    }

    render() {
        if (this.props.distribution) {
            const {
                tabs,
                activeTab,
                distribution,
                distributionMaintenanceGeneralDataViewData,
                hideModal,
                showYesNoPrompt,
                showScheduledJobModalView,
                scheduledJobModalViewData,
                distributionMaintenanceToolbarData,
                distributionMaintenanceSaveResultData,
                addNewDistribution,
                updateDistributionField,
                dataGridTableData,
                distributionMaintenanceGridsViewData,
                searchUsagePools,
                usagePools,
                showModal,
                searchDistributionVersionHistory,
                clearModalSearchResults,
                copyDistribution,
                getLastPercentageValues,
                allocationJobInProgress,
                roles,
                triggerManualJob,
                undoDistribution,
                incrementTempID,
                editableFieldsDataView,
                setEditableFields,
                editableFields,
                showUpdateFieldsModal,
                updateComponentFieldsItems,
                customer,
                usagePoolsSearchResults,
                sources,
                getUsagePool,
                jobStatusQA,
                upsertScheduledJob,
                sortDistributionPoolFieldData
            } = this.props;

            const {
                distributionPools
            } = this.props.distribution;

            let isNewDistribution: boolean = this.props.distribution.distributionID < 1 ? true : false;

            const {
                distributionSettings, subjects, distributionSubjectTypes
            } = this.props.distribution;

            const title = distribution ? distribution.distributionCode : null;

            const { updatedScroll } = this.props;

            const {
                toolbarWidth,
                isBulkCheckboxActive
            } = this.state;

            const changesMade = tabs[activeTab].isReadonly ? false : tabs[activeTab].changesMade;

            const isReadonly = (tabs[activeTab].isReadonly != undefined && tabs[activeTab].isReadonly);

            let { lookupValues } = this.props;

            lookupValues = this.addDistributionTypeToLookup(lookupValues);
            lookupValues = this.addDistributionSubTypeToLookup(lookupValues);

            return (
                <div>
                    <div><span className="title">{title}</span></div>
                    <If condition={tabs[activeTab].versionNumber != null}>
                        <div><span>Version history number: {tabs[activeTab].versionNumber}</span></div>
                    </If>
                    <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                        <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                    </button>
                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <DistributionMaintenanceToolbar
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            changesMade={changesMade}
                            saveResultData={distributionMaintenanceSaveResultData}
                            toolbarData={distributionMaintenanceToolbarData}
                            saveChanges={this.saveDistribution.bind(this)}
                            deleteDistribution={this.deleteDistribution.bind(this)}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            triggerManualJob={triggerManualJob}
                            undoDistribution={undoDistribution}
                            activeTab={activeTab}
                            tabs={tabs}
                            showYesNoPrompt={showYesNoPrompt}
                            showScheduledJobModalView={showScheduledJobModalView}
                            scheduledJobModalViewData={scheduledJobModalViewData}
                            hideModal={hideModal}
                            distribution={distribution}
                            isReadonly={isReadonly}
                            clearModalSearchResults={clearModalSearchResults}
                            undoDistributionChanges={this.undoChanges.bind(this)}
                            addNewDistribution={() => addNewDistribution(lookupValues, true, distributionMaintenanceGeneralDataViewData)}
                            isNew={tabs[activeTab].distributionMaintenanceState.isNew}
                            allocateDistributionPools={() => { this.allocateDistributionPools(); }}
                            undoAllocationDistributionPools={() => { this.undoAllocationDistributionPools(); }}
                            searchVersionHistory={searchDistributionVersionHistory}
                            lookupValues={lookupValues}
                            exportDistributionPools={this.exportDistributionPools}
                            showModal={showModal}
                            copyDistribution={copyDistribution}
                            allocationJobInProgress={allocationJobInProgress}
                            roles={roles}
                            jobStatusQA={jobStatusQA}
                            runQAOnDistributionPools={this.runQAOnDistributionPools}
                            revertQAOnDistributionPools={this.revertQAOnDistributionPools}
                            upsertScheduledJob={upsertScheduledJob}
                            setCDAllocationSchedule={this.setCDAllocationSchedule}
                        />
                    </div>

                    <DistributionMaintenanceGeneralDataView
                        distributionMaintenanceGeneralDataViewData={distributionMaintenanceGeneralDataViewData}
                        distribution={distribution}
                        isReadonly={isReadonly}
                        lookupValues={lookupValues}
                        updateDistributionField={updateDistributionField}
                        roles={roles}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        editableFieldsDataView={editableFieldsDataView}
                        dataGridTableEditableFieldsData={dataGridTableData}
                        setEditableFields={setEditableFields}
                        editableFields={editableFields}
                        tabs={tabs}
                        activeTab={activeTab}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        customer={customer}
                    />
                    <DistributionMaintenanceGridsView
                        dataGridTableData={dataGridTableData}
                        distributionMaintenanceGridsViewData={distributionMaintenanceGridsViewData}
                        updateDistributionField={updateDistributionField}
                        lookupValues={lookupValues}
                        isReadonly={isReadonly}
                        distributionPools={distributionPools}
                        searchUsagePools={searchUsagePools}
                        distributionSettings={distributionSettings}
                        distributionSubjectTypes={distributionSubjectTypes}
                        subjects={subjects}
                        usagePools={usagePools}
                        showModal={showModal}
                        isNewDistribution={isNewDistribution}
                        getLastPercentageValues={getLastPercentageValues}
                        isBulkCheckboxActive={isBulkCheckboxActive}
                        changeBulkCheckbox={this.changeBulkCheckbox}
                        tabs={tabs}
                        activeTab={activeTab}
                        resetMessageBanner={this.resetMessageBanner.bind(this)}
                        incrementTempID={incrementTempID}
                        usagePoolsSearchResults={usagePoolsSearchResults}
                        sources={sources}
                        getUsagePool={getUsagePool}
                        customer={customer}
                        sortDistributionPoolFieldData={sortDistributionPoolFieldData}
                    />
                </div>
            );
        }
        else
            return null;
    }
}