import * as React from "react";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../redux/reducers/ModalReducer";
import { addTab, clearModalSearchResults, setEditableFields, sortProductFieldData, updateEditableFields, updateProductField } from "../../../redux/reducers/RepertoireReducer";
import { IProductState } from "../../../redux/types/IProductState";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ISocietyInterestPercentage } from "../../../redux/types/ISocietyInterestPercentage";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { ADD_NEW_ACTION, PRODUCT_AV_SUBMISSIONS_KEY, RECORDING_DETAILS, REMOVE_ACTION, SEARCH_ACTION } from "../../Consts";
import { DataSource } from "../../types/DataSource";
import { IAccordionData } from "../../types/IAccordionData";
import { IComment } from "../../types/IComment";
import { IIntrayProductDuplicate } from "../../types/IIntrayProductDuplicate";
import { IMatchingProduct } from "../../types/IMatchingProduct";
import { IProductAVRequest } from "../../types/IProductAVRequest";
import { IProductAVWork } from "../../types/IProductAVWork";
import { IProductAVWorkSubmission } from "../../types/IProductAVWorkSubmission";
import { IProductContributorDataItems } from "../../types/IProductContributorDataItems";
import { IProductDuplicate } from "../../types/IProductDuplicate";
import { IProductMaintenanceSubmissionConfig } from "../../types/IProductMaintenanceSubmissionConfig";
import { IProductNameDataItems } from "../../types/IProductNameDataItems";
import { IProductNumberDataItems } from "../../types/IProductNumberDataItems";
import { IProductPerformerDataItems } from "../../types/IProductPerformerDataItems";
import { IProductWorkDataItems } from "../../types/IProductWorkDataItems";
import { IRepertoireField } from "../../types/IRepertoireField";
import { ManualMergeOptions } from "../../types/ManualMergeOptions";
import ProductMaintenanceAccordion from "../productMaintenanceAccordion/ProductMaintenanceAccordion";

export interface IProductMaintenanceAccordionListProps {
    updateProductField?: typeof updateProductField;
    sortProductFieldData: typeof sortProductFieldData;
    productMaintenanceAccordionViewData: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    avWorkDetailsAccordianViewData?: IRepertoireComponentDataItem;
    avSubmissionDetailsAccordianViewData?: IRepertoireComponentDataItem;
    componentInstance: string;
    showModal: typeof showModal;
    clearModalSearchResults: typeof clearModalSearchResults;
    submitProductAVRequest: (productAVRequest: IProductAVRequest, productCoreID: number) => IProductAVWorkSubmission[];
    lookupValues: ILookupDictionary;
    activeAccordian: string[];
    openAccordian: (accordionName: string, componentName: string) => void;
    closeAccordian: (accordionName: string, componentName: string) => void;
    dataSource: DataSource;
    productName: IProductNameDataItems[];
    productNumber: IProductNumberDataItems[];
    productPerformer: IProductPerformerDataItems[];
    productWork: IProductWorkDataItems[];
    productDuplicate: IProductDuplicate[];
    intrayProductDuplicate: IIntrayProductDuplicate[];
    productAVWork: IProductAVWork;
    productContributor: IProductContributorDataItems[];
    productAVWorkSubmissions: IProductAVWorkSubmission[];
    productAVRequest: IProductAVRequest;
    addTab: typeof addTab;
    tabs: ITabReduxItem[];
    activeTab: number;
    getWorkDetails: (dataSource: string, workID: number) => void;
    getIPDetails: (ipBaseNumber: string, accountNumber: string) => void;
    getProductDetails: (dataSource: string, productID: number) => void;
    isReadonly: boolean;
    productCoreID: number;
    productMaintenanceSubmissionConfig?: IProductMaintenanceSubmissionConfig;
    hideModal?: typeof hideModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    editableFields: IRepertoireField[];
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    productType: string,
    productNotes: IComment[];
    currentUser?: string;
    matchingProducts: IMatchingProduct[];
    expandMatchingProduct?: (index: number) => void;
    expandedProductResults?: number[];
    manualMergeProduct: (matchingProductId: number, manualMergeOption: ManualMergeOptions) => void;
    incrementTempID?: () => void;
    product?: IProductState;
    commentCategoryFlag?: boolean;
    toggleAccordian: (fieldName, componentInstance) => void;
    societyInterestPercentage?: ISocietyInterestPercentage[];
}

const ProductMaintenanceAccordionList: React.SFC<
    IProductMaintenanceAccordionListProps
> = ({
    updateProductField,
    sortProductFieldData,
    componentInstance,
    productMaintenanceAccordionViewData,
    avWorkDetailsAccordianViewData,
    avSubmissionDetailsAccordianViewData,
    dataGridTableData,
    showModal,
    clearModalSearchResults,
    lookupValues,
    activeAccordian,
    openAccordian,
    closeAccordian,
    dataSource,
    productName,
    productNumber,
    productPerformer,
    productWork,
    productDuplicate,
    intrayProductDuplicate,
    productAVWork,
    productContributor,
    productAVWorkSubmissions,
    productAVRequest,
    addTab,
    tabs,
    activeTab,
    isReadonly,
    getWorkDetails,
    getIPDetails,
    getProductDetails,
    productCoreID,
    submitProductAVRequest,
    productMaintenanceSubmissionConfig,
    updateComponentFieldsItems,
    hideModal,
    editableFields,
    showUpdateFieldsModal,
    editableFieldsDataView,
    setEditableFields,
    productType,
    productNotes,
    currentUser,
    matchingProducts,
    expandedProductResults,
    expandMatchingProduct,
    manualMergeProduct,
    incrementTempID,
    product,
    commentCategoryFlag,
    toggleAccordian,
    societyInterestPercentage
}) => {    
        const renderAccordions = () => {
            if (
                productMaintenanceAccordionViewData &&
                productMaintenanceAccordionViewData.fields
            ) {
                const filteredFields = productMaintenanceAccordionViewData.fields.filter(
                    (field: IRepertoireField) =>
                        field.componentInstance === componentInstance
                );

                const getFieldData = (field: IRepertoireField): IAccordionData => {
                    switch (field.name) {
                        case "productName":
                            const productNamesData: IAccordionData = {
                                actions: [{ name: ADD_NEW_ACTION }],
                                data: productName
                            };
                            return productNamesData;
                        case "productNumber":
                            const productNumbersData: IAccordionData = {
                                actions: [{ name: ADD_NEW_ACTION }],
                                data: productNumber
                            };
                            return productNumbersData;
                        case "productPerformer":
                            const productPerformersData: IAccordionData = {
                                actions: dataSource === DataSource.Intray ? [{ name: ADD_NEW_ACTION }] : [{ name: SEARCH_ACTION }],
                                data: productPerformer
                            };
                            return productPerformersData;
                        case "productWork":
                            const productWorksData: IAccordionData = {
                                actions: [{ name: SEARCH_ACTION }],
                                data: productWork
                            };
                            return productWorksData;
                        case "productContributor":
                            let productContributorsData: IAccordionData = {
                                actions: [{ name: ADD_NEW_ACTION }],
                                data: productContributor
                            };
                            return productContributorsData;
                        case "parentProduct":
                            let parentProductsData: IAccordionData = {
                                actions: null,
                                data: null
                            };
                            return parentProductsData;
                        case "childProducts":
                            let childProductsData: IAccordionData = {
                                actions: [],
                                data: null
                            }
                            return childProductsData;
                        case "matchingRepertoireProducts":
                            let matchingRepertoireProductsData: IAccordionData = {
                                actions: [{ name: ADD_NEW_ACTION }],
                                data: matchingProducts
                            }
                            return matchingRepertoireProductsData;
                        case "matchingIntrayProducts":
                            let matchingIntrayProductsData: IAccordionData = {
                                actions: [],
                                data: intrayProductDuplicate
                            }
                            return matchingIntrayProductsData;
                        case "productAVWork":
                            let productAVWorksData: IAccordionData = {
                                actions: [{ name: ADD_NEW_ACTION }],
                                data: productAVWork
                            };
                            return productAVWorksData;
                        case "societyInterestPercentage":
                            let socInterestPercentData: IAccordionData = {
                                actions: null,
                                data: societyInterestPercentage
                            };
                            return socInterestPercentData;
                        case PRODUCT_AV_SUBMISSIONS_KEY:
                            let productAVSubmissionsData: IAccordionData = {
                                actions: [{ name: ADD_NEW_ACTION }],
                                data: { submissions: productAVWorkSubmissions, requestDetails: productAVRequest }
                            };
                            return productAVSubmissionsData;
                        case "comments":
                            let commentsData: IAccordionData = {
                                actions: [{ name: ADD_NEW_ACTION }],
                                data: productNotes
                            };
                            return commentsData;
                        default: return null;
                    }
                }

                return filteredFields.map((field: IRepertoireField, index: number) => {
                    return(
                    <div key={index}>
                        <ProductMaintenanceAccordion
                            updateProductField={updateProductField}
                            sortProductFieldData={sortProductFieldData}
                            avWorkDetailsAccordianViewData={avWorkDetailsAccordianViewData}
                            avSubmissionDetailsAccordianViewData={avSubmissionDetailsAccordianViewData}
                            field={field}
                            data={getFieldData(field)}
                            dataGridTableData={dataGridTableData}
                            showModal={showModal}
                            clearModalSearchResults={clearModalSearchResults}
                            lookupValues={lookupValues}
                            activeAccordian={activeAccordian}
                            openAccordian={openAccordian}
                            closeAccordian={closeAccordian}
                            addTab={addTab}
                            tabs={tabs}
                            activeTab={activeTab}
                            dataSource={dataSource}
                            getWorkDetails={getWorkDetails}
                            getIPDetails={getIPDetails}
                            getProductDetails={getProductDetails}
                            isReadonly={isReadonly}
                            productCoreID={productCoreID}
                            submitProductAVRequest={submitProductAVRequest}
                            productMaintenanceSubmissionConfig={productMaintenanceSubmissionConfig}
                            updateComponentFieldsItems={updateComponentFieldsItems}
                            hideModal={hideModal}
                            editableFields={editableFields}
                            showUpdateFieldsModal={showUpdateFieldsModal}
                            editableFieldsDataView={editableFieldsDataView}
                            setEditableFields={setEditableFields}
                            productType={productType}
                            currentUser={currentUser}
                            expandedProductResults={expandedProductResults}
                            expandMatchingProduct={expandMatchingProduct}
                            manualMergeProduct={manualMergeProduct}
                            incrementTempID={incrementTempID}
                            product={product}
                            commentCategoryFlag={commentCategoryFlag}
                            toggleAccordion={toggleAccordian}
                        />
                    </div>)})
            }
        };

        return <div>{renderAccordions()}</div>;
    };

export default ProductMaintenanceAccordionList;
