import { PublicClientApplication } from "@azure/msal-browser";
import axios, { AxiosError } from "axios";
import * as React from 'react';
import { Route } from 'react-router-dom';
import { Dispatch } from "redux";
import { ComponentFields as CF } from '../src/core/services/ComponentService';
import B2CCallbackCatchContainer from "./accounts/components/B2C/B2CCallbackCatchContainer";
import LoginPageContainer from "./accounts/components/loginPage/LoginPageContainer";
import MSALCallbackCatchContainer from "./accounts/components/MSAL/MSALCallbackCatchContainer";
import { RegisterPage } from './accounts/components/RegisterPage';
import SignOutContainer from "./accounts/components/signOut/SignOutContainer";
import { MATCHING_ENGINE, MEMBERS_PORTAL } from "./accounts/Consts";
import { AccountService } from './accounts/services/AccountService';
import { msalConfig } from "./accounts/types/msalAuthConfig";
import HeaderContainer from "./core/components/header/HeaderContainer";
import { PrivateRoute } from './core/components/PrivateRoute';
import { IMenuItem } from './core/types/IMenuItem';
import DataIngestionPageContainer from "./dataingestion/components/DataIngestionPageContainer";
import HomePageContainer from './home/components/HomePageContainer';
import MyAccountPageContainer from "./membersportal/account/MyAccountPageContainer";
import MyCasesPageContainer from "./membersportal/crmIntegrations/MyCasesPageContainer";
import MyFormsPageContainer from "./membersportal/crmIntegrations/MyFormsPageContainer";
import MembersPortalPageContainer from "./membersportal/MembersPortalPageContainer";
import RequestAccessPageContainer from "./membersportal/requestAccess/RequestAccessPageContainer";
import ViewAsPageContainer from "./membersportal/viewAs/ViewAsPageContainer";
import RepertoirePageContainer from './repertoire/RepertoirePageContainer';
import SettingsPageContainer from './settings/components/SettingsPageContainer';
import ValidationSettingsContainer from './settings/components/ValidationSettingsContainer';

interface IAppProps {
    portalType: string;
    dispatch: Dispatch;
    isTest?: boolean;
    instance?: PublicClientApplication;
}

interface IAppState {
    routes: JSX.Element[];
}

export default class App extends React.Component<IAppProps, IAppState> {
    constructor(props: IAppProps) {
        super(props);

        this.state = ({
            routes: []
        });
    }

    componentDidMount() {
        const { portalType, dispatch, isTest, instance } = this.props;

        if (portalType === MEMBERS_PORTAL) {
            const accounts = instance.getAllAccounts();
            if (accounts.length > 0) {
                instance.setActiveAccount(accounts[0]);
            }

            let scope =
            {
                scopes: [msalConfig.auth.scope]
            }

            if (instance.getAllAccounts.length < 1 && instance) {
                instance
                    .handleRedirectPromise()
                    .then((tokenResponse) => {
                        if (!tokenResponse) {
                            const account = instance.getActiveAccount();
                            if (!account) {
                                instance.acquireTokenRedirect(scope);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }

        if (!isTest) {
            axios.interceptors?.response.use(response => {
                return response;
            }, (error: AxiosError) => {
                if (error && error.response && error.response.status === 401) {
                    AccountService.logout().then(res => {
                        window.location.replace("/signOut");
                    });
                } else if (error && error.response && error.response.status == 500) {
                    throw error.response.data["message"];
                }
                return error;
            });

            const menuItemsSession = sessionStorage.getItem('menuItems');
            const menuItemsParsed = JSON.parse(menuItemsSession) as IMenuItem[];
            if (menuItemsParsed && menuItemsParsed.length > 0) {
                this.setState({ routes: this.getRoutes(menuItemsParsed) })
            }
            else {
                CF.getMenuItems(portalType, dispatch)
                    .then(menuItems => {

                        sessionStorage && sessionStorage.setItem('menuItems', JSON.stringify(menuItems));
                        this.setState({ routes: this.getRoutes(menuItems) });
                    });
            }
        }

    }

    getRoutes(menuItems: IMenuItem[]): JSX.Element[] {
        const { portalType, instance } = this.props;
        let routes: JSX.Element[] = [];

        let scope =
        {
            scopes: [msalConfig.auth.scope]
        }

        if (menuItems && menuItems.length > 0) {

            routes.push(<PrivateRoute key='/' exact path='/' component={HomePageContainer} instance={instance} portalType={portalType} />);

            const settings = menuItems.find(m => m.link == '/settings');

            if (settings) {
                settings.isEnabled && routes.push(<PrivateRoute key={settings.link} exact path={settings.link} component={SettingsPageContainer} instance={instance} portalType={portalType} scope={scope} />);

                const matching = settings.children.find(m => m.link == '/settings/matching');
                matching && matching.isEnabled && routes.push(<PrivateRoute key={matching.link} path={matching.link} component={SettingsPageContainer} instance={instance} portalType={portalType} scope={scope} />);

                const validation = settings.children.find(m => m.link == '/settings/validation');
                validation && validation.isEnabled && routes.push(<PrivateRoute key={validation.link} exact path={validation.link} component={ValidationSettingsContainer} instance={instance} portalType={portalType} scope={scope} />);
            }

            const repertoire = menuItems.find(m => m.link == '/repertoire');
            repertoire && repertoire.isEnabled && routes.push(<PrivateRoute key={repertoire.link} path={'/repertoire/:submodule?/:datasource?/:id?'} component={RepertoirePageContainer} instance={instance} portalType={portalType} scope={scope} />);

            const modernIngestion = menuItems.find(m => m.link == '/modernIngestion');
            if (modernIngestion) {
                modernIngestion.isEnabled && routes.push(<PrivateRoute key={modernIngestion.link} exact path={modernIngestion.link} component={DataIngestionPageContainer} instance={instance} portalType={portalType} scope={scope} />);

                const dataIngestion = modernIngestion.children.find(c => c.link === '/modernIngestion/dataIngestion');
                dataIngestion && dataIngestion.isEnabled && repertoire.isEnabled && routes.push(<PrivateRoute key={dataIngestion.link} exact path={dataIngestion.link} component={DataIngestionPageContainer} instance={instance} portalType={portalType} scope={scope} />);
            }

            const membersPortalWorks = menuItems.find(m => m.link == '/works');
            if (membersPortalWorks) {
                membersPortalWorks.isEnabled && routes.push(<PrivateRoute key={'/works'} path={'/works/:submodule?/:datasource?/:id?'} component={MembersPortalPageContainer} instance={instance} portalType={portalType} scope={scope} />);
            }

            const myAccount = menuItems.find(m => m.link == '/myAccount');
            if (myAccount) {

                myAccount.isEnabled && routes.push(<PrivateRoute key={'/myAccount/requestAccess'} path={'/myAccount/requestAccess/:submodule?/:datasource?/:id?'} component={RequestAccessPageContainer} instance={instance} portalType={portalType} scope={scope} />);

                myAccount.isEnabled && routes.push(<PrivateRoute key={'/myAccount/viewAs'} path={'/myAccount/viewAs/:submodule?/:datasource?/:id?'} component={ViewAsPageContainer} instance={instance} portalType={portalType} scope={scope} />);

                myAccount.isEnabled && routes.push(<PrivateRoute key={'/myAccount/'} exact path={'/myAccount/'} component={MyAccountPageContainer} instance={instance} portalType={portalType} scope={scope} />);

            }

            const memberStatements = menuItems.find(m => m.link == '/memberStatements');
            if (memberStatements) {
                memberStatements.isEnabled && routes.push(<PrivateRoute key={'/memberStatements'} path={'/memberStatements/:submodule?/:id?'} component={MembersPortalPageContainer} instance={instance} portalType={portalType} scope={scope} />);
            }

            const myForms = menuItems.find(m => m.link == '/myForms');
            if (myForms) {
                myForms.isEnabled && routes.push(<PrivateRoute key={'/myForms'} path={'/myForms/:submodule?/:id?'} component={MyFormsPageContainer} instance={instance} portalType={portalType} scope={scope} />);
            }

            const myCases = menuItems.find(m => m.link == '/myCases');
            if (myCases) {
                myCases.isEnabled && routes.push(<PrivateRoute key={'/myCases'} path={'/myCases/:submodule?/:id?'} component={MyCasesPageContainer} instance={instance} portalType={portalType} scope={scope} />);
            }

            const memberPortalProducts = menuItems.find(m => m.link == '/products');
            if (memberPortalProducts) {
                memberPortalProducts.isEnabled && routes.push(<PrivateRoute key={'/products'} path={'/products/:submodule?/:id?'} component={MembersPortalPageContainer} instance={instance} portalType={portalType} scope={scope} />);
            }

            const memberPortalClaims = menuItems.find(m => m.link == '/claims');
            if (memberPortalClaims) {
                memberPortalClaims.isEnabled && routes.push(<PrivateRoute key={'/claims'} path={'/claims/:submodule?/:id?'} component={MembersPortalPageContainer} instance={instance} portalType={portalType} scope={scope} />);
            }

            const memberPortalAgreements = menuItems.find(m => m.link == '/agreements');
            if (memberPortalAgreements) {
                memberPortalAgreements.isEnabled && routes.push(<PrivateRoute key={'/agreements'} path={'/agreements/:submodule?/:id?'} component={MembersPortalPageContainer} instance={instance} portalType={portalType} scope={scope} />);
            }
        }

        return routes;
    }

    render() {
        const { portalType } = this.props

        return (
            <>
                <HeaderContainer />
                {portalType === MATCHING_ENGINE ? <Route path='/login' component={LoginPageContainer} /> : <></>}
                {portalType === MATCHING_ENGINE ? <Route path='/aadLogin' component={MSALCallbackCatchContainer} /> : <></>}
                <Route path='/aadB2CLogin' component={B2CCallbackCatchContainer} />
                {portalType === MATCHING_ENGINE ? <Route path='/register' component={RegisterPage} /> : <></>}
                <Route path='/signOut' component={SignOutContainer} />
                {this.state.routes}
            </>
        );
    }
}
