import * as React from "react";
import { Component } from "react";
import { NavItem, NavLink } from 'reactstrap';
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import { clearModalSearchResults, nextWorkflowInSession, startWorkflowSession } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IUsageGroupState } from "../../../../redux/types/IUsageGroupState";
import {
    SAVE_METHOD_TOOLBAR,
    DELETE_METHOD_TOOLBAR,
    TOOLBAR_POSITION_BREAKPOINT,
    USAGE_MAINTENANCE_TOOLBAR,
    UNDO_METHOD_TOOLBAR,
    UNDO_ICON,
    DELETE_ICON
} from "../../../Consts";
import { IDataActionToolbar } from "../../../../repertoire/types/IDataActionToolbar";
import IconTextButton from "../../../../repertoire/components/iconTextButton/IconTextButton";
import SaveResult from "../saveResult/UsageSaveResult";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { MessageBanner, MessageType } from "../../../../repertoire/components//messageBanner/MessageBanner";
import { IWorkflowSearchResult } from "../../../types/IWorkflowSearchResult";
import { WorkflowBanner } from "../../workflowBanner/WorkflowBanner";
import { ITreeData } from "../../../types/ITreeData";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { IWorkflow } from "../../../types/IWorkflow";
import { IWorkflowParams } from "../../../types/IWorkFlowParams";

export interface IToolbarComponentProps {
    changesMade?: boolean;
    showYesNoPrompt?: typeof showYesNoPrompt;
    hideModal?: typeof hideModal;
    showModal?: typeof showModal;
    resetMessageBanner?: () => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    scroll?: number;
    toolbarWidth: number;
    toolbarData?: IRepertoireComponentDataItem;
    saveResultData: IRepertoireComponentDataItem;
    usageGroup: IUsageGroupState;
    activeTab: number;
    tabs: ITabReduxItem[];
    isReadonly?: boolean;
    dataActionToolbar: IDataActionToolbar[];
    component: string;
    isNew?: boolean;
    dataActionList?: IDataActionToolbar[];
    workflowFieldsData?: IRepertoireComponentDataItem;
    isWorkflowSessionStarted?: boolean;
    startWorkflowSession?: typeof startWorkflowSession;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    workflowSessionItems?: IWorkflowSearchResult[];
    currentWorkflowIndex?: number;
    isOpenForWorkflowSession?: boolean;
    skipWorkWorkflowItem: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    lookupValues: ILookupDictionary;
    formatFields: FormatFields[];
    refreshWorkDetails?: () => void;
    saveWorkflow?: (workflowparams: IWorkflowParams) => void;
    roles?: string[];
    updateWorkflowAndEntityStatus?: (workflowparams: IWorkflowParams, approvalStatus: boolean) => void;
    updateClaimWorkflowAndEntityStatus?: (workflowparams: IWorkflowParams, callFrom: string, refreshDetails: () => void) => void;
    completeWorkflowOnSave?: (value: boolean) => void;
}

export interface IToolbarState {
    loaded: boolean;
}

class ToolbarComponent extends Component<
    IToolbarComponentProps,
    IToolbarState
>{
    actionData;

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
        this.actionData = {};
    }

    componentDidMount() {
        const {
            dataActionToolbar,
            tabs,
            activeTab,
            toolbarData: { fields }
        } = this.props;
        const { loaded } = this.state;

        dataActionToolbar.forEach(item => {
            let itemName = item.method;
            let itemData = this.getToolbarActionData(item);
            this.actionData[itemName] = itemData;
        });

        if (!loaded) this.checkIfLoaded();
    }

    componentDidUpdate() {
        const { loaded } = this.state;
        if (!loaded) this.checkIfLoaded();
    }

    checkIfLoaded = () => {
        const {
            dataActionToolbar,
            component,
            isNew
        } = this.props;

        let dataActionLength = 0;


        if (Object.keys(this.actionData).length >= dataActionLength)
            this.setState({ loaded: true });
    };

    getToolbarStyleClasses = () => {
        const { scroll } = this.props;
        return scroll > TOOLBAR_POSITION_BREAKPOINT
            ? "container fixedToolbar" : "container";
    };

    getToolbarActionData = (dataActionToolbar: IDataActionToolbar) => {

        let iconPath = "";

        switch (dataActionToolbar.method) {
            case UNDO_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(UNDO_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case DELETE_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(DELETE_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            default:
                return {};
        }
    };

    getToolbarActionIconPath = (iconName: string) => {
        switch (iconName) {
            case "copy": return "icon ms-Icon ms-Icon--Copy";
            case "undo": return "icon ms-Icon ms-Icon--Undo";
            case "merge": return "icon ms-Icon ms-Icon--BranchMerge";
            case "delete": return "icon ms-Icon ms-Icon--Delete";
            case "addNew": return "icon ms-Icon ms-Icon--CircleAddition";
            case "versionHistory": return "icon ms-Icon ms-Icon--History";
            case "automatch": return "icon ms-Icon ms-Icon--TriggerAuto";
            case "assingTo": return "icon ms-Icon ms-Icon--FollowUser";
            case "updateStatus": return "icon ms-Icon ms-Icon--ProgressLoopOuter";
            case "deselect": return "icon ms-Icon ms-Icon--Cancel";
            case "startSession": return "icon ms-Icon ms-Icon--Flow";
            default: return "";
        }
    };

    saveMethod = (itemToSave: any) => {
        const { dataActionToolbar } = this.props;
        const filteredDataActionToolbar = dataActionToolbar.find(
            action =>
                action.method === SAVE_METHOD_TOOLBAR
        );
        filteredDataActionToolbar.action(itemToSave);
    }

    renderToolbarButtons = () => {
        const {
            isReadonly,
            dataActionToolbar,
            component,
            isNew
        } = this.props;

        let filteredDataActionToolbar = dataActionToolbar.filter(
            action =>
                action.method !== SAVE_METHOD_TOOLBAR
        );

        if (isNew === true) {
            filteredDataActionToolbar = filteredDataActionToolbar.filter(action =>
                action.method !== DELETE_METHOD_TOOLBAR
            )
        }

        return filteredDataActionToolbar.map(item =>
            <div className="toolbarButton" key={item.method}>
                <div className="toolbarButtonContainer">
                    <IconTextButton
                        text={item.name}
                        icon={item.icon}
                        onClick={item.action}
                        disabled={isReadonly}
                    />
                </div>
            </div>
        )
    };

    renderSaveButton = () => {
        const {
            changesMade,
            dataActionToolbar,
            usageGroup,
            isReadonly,
            component,
        } = this.props;
        const lastIndex = dataActionToolbar.length;

        return (
            <div className="saveButton" key={lastIndex}>
                <div className="saveButtonContainer" key={lastIndex}>
                    <button
                        aria-disabled={isReadonly}
                        className={changesMade ? "saveButton" : "disabledSaveButton"}
                        onClick={() => this.saveMethod(usageGroup)}
                        disabled={!changesMade}
                    >
                        <div className="saveButtonText">{dataActionToolbar.find(f => f.method === "Save").name}</div>
                    </button>
                </div>
            </div>
        );

    };

    renderWorkflowBanner() {
        const { usageGroup
            , workflowFieldsData
            , isWorkflowSessionStarted
            , skipWorkflowItem
            , tabs
            , cancelWorkflowSession
            , nextWorkflowItem
            , workflowSessionItems
            , activeTab
            , currentWorkflowIndex
            , dataActionToolbar
            , isOpenForWorkflowSession
            , skipWorkWorkflowItem,
            lookupValues,
            formatFields,
            refreshWorkDetails,
            saveWorkflow,
            roles,
            updateWorkflowAndEntityStatus,
            updateClaimWorkflowAndEntityStatus,
            showModal,
            hideModal,
            completeWorkflowOnSave
        } = this.props;

        if (usageGroup != null) {
            if (usageGroup.workflows != null && usageGroup.workflows.length > 0) {
                return (
                    <WorkflowBanner
                        workflows={usageGroup.workflows}
                        workflowFieldsData={workflowFieldsData}
                        isWorkflowSessionStarted={isWorkflowSessionStarted}
                        hideModal={hideModal}
                        showModal={showModal}
                        nextWorkflowItem={nextWorkflowItem}
                        cancelWorkflowSession={cancelWorkflowSession}
                        skipUsageWorkflowItem={skipWorkflowItem}
                        tabs={tabs}
                        skipWorkflowItem={skipWorkWorkflowItem}
                        workflowSessionItems={workflowSessionItems}
                        activeTab={activeTab}
                        currentWorkflowIndex={currentWorkflowIndex}
                        dataActionList={dataActionToolbar}
                        isOpenForWorkflowSession={isOpenForWorkflowSession}
                        lookupValues={lookupValues}
                        workFormats={formatFields}
                        refreshDetails={refreshWorkDetails}
                        saveWorkflow={saveWorkflow}
                        roles={roles}
                        updateWorkflowAndEntityStatus={updateWorkflowAndEntityStatus}
                        updateClaimWorkflowAndEntityStatus={updateClaimWorkflowAndEntityStatus}
                        completeWorkflowOnSave={completeWorkflowOnSave}
                    />
                );
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }

    render() {
        const { toolbarWidth, tabs, activeTab, saveResultData, resetMessageBanner, component, } = this.props;
        const { loaded } = this.state;
        if (loaded) {
            return (
                <>
                    <div
                        className="toolbarWrapper"
                        id={"toolbar"}
                        style={{ width: toolbarWidth }}
                    >
                        {this.renderToolbarButtons()}
                        {this.renderSaveButton()}
                    </div>
                    <SaveResult
                        componentInstance={component}
                        saveSuccess={tabs[activeTab].saveSuccessful}
                        saveResultData={saveResultData}
                        messages={tabs[activeTab].validationMessages}
                        resetMessageBanner={resetMessageBanner}
                        approvedSuccess={tabs[activeTab].approvedSuccessful}
                        rejectedSuccess={tabs[activeTab].rejectedSuccessful}
                    />
                    {this.renderWorkflowBanner()}
                </>
            );
        }
        return <div />;
    }
}

export default ToolbarComponent;