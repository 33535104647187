import { ErrorMessage, Field, FieldArray, FieldArrayRenderProps, Form, Formik, getIn } from "formik";
import * as Yup from 'yup';
import { ActionButtonFormik, SizedActionButton } from "../../../components/actionButton/ActionButton";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import DatePickerFormikField from "../../../../membersportal/unpaidClaims/DatePickerFormik";
import { useEffect, useRef, useState } from "react";
import DisplayText from "../../../components/text/DisplayText";
import IconTextButton from "../../../components/iconTextButton/IconTextButton";
import { isDateRangesOverLapping } from "./utils";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IUsagePoolDatesAndDimensions } from "../../../types/usageTypes/IUsagePoolDatesAndDimensions";
import OverLappingDatesAlert from "./OverLappingDatesAlert";
import { DropdownDataInputFormik } from "../../../components/dropdownDataInput/DropdownDataInputFormik";

const OVER_LAPPING_DATES = 'Overlapping dates';

const datesAndDimensionsSchema = Yup.object().shape({
    datesAndDimensions: Yup.array()
        .of(Yup.object().shape({
            effectiveFrom: Yup.date().required('Required'),
            effectiveTo: Yup.date().nullable().min(
                Yup.ref('effectiveFrom'),
                "From date can't be before to date"
            ),
            musicSource: Yup.boolean().test('musicSource', 'Check one', (value, testContext) => {
                return value || testContext.parent.use || testContext.parent.dimension1 || testContext.parent.dimension2 || testContext.parent.dimension3 || testContext.parent.dimension4 || testContext.parent.dimension5 || testContext.parent.time;
            }),
            time: Yup.boolean().test('time', 'Check one', (value, testContext) => {
                return value || testContext.parent.musicSource || testContext.parent.use || testContext.parent.dimension1 || testContext.parent.dimension2 || testContext.parent.dimension3 || testContext.parent.dimension4 || testContext.parent.dimension5;
            }),
            use: Yup.boolean().test('use', 'Check one', (value, testContext) => {
                return value || testContext.parent.musicSource || testContext.parent.dimension1 || testContext.parent.dimension2 || testContext.parent.dimension3 || testContext.parent.dimension4 || testContext.parent.dimension5 || testContext.parent.time;
            }),
            dimension1: Yup.boolean().test('dimension1', 'Check one', (value, testContext) => {
                return value || testContext.parent.use || testContext.parent.musicSource || testContext.parent.dimension2 || testContext.parent.dimension3 || testContext.parent.dimension4 || testContext.parent.dimension5 || testContext.parent.time;
            }),
            dimension2: Yup.boolean().test('dimension2', 'Check one', (value, testContext) => {
                return value || testContext.parent.use || testContext.parent.dimension1 || testContext.parent.musicSource || testContext.parent.dimension3 || testContext.parent.dimension4 || testContext.parent.dimension5 || testContext.parent.time;
            }),
            dimension3: Yup.boolean().test('dimension3', 'Check one', (value, testContext) => {
                return value || testContext.parent.use || testContext.parent.dimension1 || testContext.parent.dimension2 || testContext.parent.musicSource || testContext.parent.dimension4 || testContext.parent.dimension5 || testContext.parent.time;
            }),
            dimension4: Yup.boolean().test('dimension4', 'Check one', (value, testContext) => {
                return value || testContext.parent.use || testContext.parent.dimension1 || testContext.parent.dimension2 || testContext.parent.dimension3 || testContext.parent.musicSource || testContext.parent.dimension5 || testContext.parent.time;
            }),
            dimension5: Yup.boolean().test('dimension5', 'Check one', (value, testContext) => {
                return value || testContext.parent.use || testContext.parent.dimension1 || testContext.parent.dimension2 || testContext.parent.dimension3 || testContext.parent.dimension4 || testContext.parent.musicSource || testContext.parent.time;
            }),
        })).test(OVER_LAPPING_DATES, OVER_LAPPING_DATES, (datesAndDimensions) => {
            const overlappingLineItem = datesAndDimensions.find((lineItem, index) => {
                return isDateRangesOverLapping(lineItem, datesAndDimensions[index + 1])
            }

            )
            return !overlappingLineItem
        })
        .required(OVER_LAPPING_DATES),
})

export interface IDatesAndDimensionsWeightingTableProps {
    weightSettingsPoolMaintenanceFields: IRepertoireComponentDataItem;
    changeData?: (value: any, name: IRepertoireStateKeys, objectKey?: string, ipRepresentationsIdsToRemove?: number[]) => void;
    datesAndDimensions?: IUsagePoolDatesAndDimensions[];
    saveChanges: () => void;
    poolEnabled: boolean;
    handleDeleteAllLinkedWeightsSettingsWhenTheDatesAndDimensionsAreDeleted: (deletedDatesAndDimensionsRow: any, allRemainingDatesAndDimensions: any) => void;
}

const emptyDatesAndDimensionsTableRow =
{
    effectiveFrom: null,
    effectiveTo: null,
    musicSource: false,
    use: false,
    time: false,
    dimension1: false,
    dimension2: false,
    dimension3: false,
    dimension4: false,
    dimension5: false,
    effectiveDateRange: null,
    productMusicUsageDimension: null
};

const DatesAndDimensionsWeightingTable = ({
    weightSettingsPoolMaintenanceFields,
    changeData,
    datesAndDimensions,
    saveChanges,
    poolEnabled,
    handleDeleteAllLinkedWeightsSettingsWhenTheDatesAndDimensionsAreDeleted,
}) => {
    const [isDataAvailable, setIsDataAvailable] = useState(false);
    const arrayHelperRef = useRef<FieldArrayRenderProps>();
    useEffect(() => {
        if (weightSettingsPoolMaintenanceFields.fields) {
            setIsDataAvailable(true)
        }
    }, [weightSettingsPoolMaintenanceFields, weightSettingsPoolMaintenanceFields.fields])

    const productMusicUsageDimensionOptions = weightSettingsPoolMaintenanceFields.fields.filter(f => !["time", "effectiveFrom", "effectiveTo", "productMusicUsageDimension"].includes(f.name)).map(f => ({"code": f.name.includes("dimension") ? `usage${f.name}` : f.name, "description": f.data }))
    
    const onChange = (fieldName: string, value: any) => {
        changeData(value.data, fieldName);
    }

    const CustomCheckBox = ({
        field,
        form: { touched, errors, values },
        ...props
    }) => {
        const isError = errors && errors.datesAndDimensions && errors.datesAndDimensions[props.index] && !!errors.datesAndDimensions[props.index][props.errorName]
        return (
            <div className={isError ? "invalidForkmikField checkboxSize" : "checkboxSize"}>
                <div className={"checkboxContainer checkBoxContainerSize"}>
                    <label className="subContainer checkBoxContainerSize">
                        {poolEnabled ? <input
                            checked={field && field.value ? field.value : false}
                            type="checkbox"
                            {...field} {...props}
                        /> : <input
                            checked={field && field.value ? field.value : false}
                            type="checkbox"
                            disabled
                            {...field} {...props}
                        />}
                        <span className="inputCheckbox" />
                    </label>
                </div>
            </div>
        )
    }

    const renderTableHeader = (fields, arrayHelperRef) => {
        const formFields = fields.map(field => {
            return <td key={fields.name} className="td">
                <div className={"headerCell"}>
                    {field.data}
                </div>
            </td>
        })
        formFields.push(<></>);
        return formFields;
    }

    const renderField = (field, index) => {
        let formField: React.ReactElement = <></>;
        if (field.fieldType === 'date') {
            formField = <div key={index} >
                <DatePickerFormikField label={`${field.data}`} name={`datesAndDimensions[${index}].${field.name}`} disabled={!poolEnabled} />
                <label><ErrorMessage name={`datesAndDimensions[${index}].${field.name}`} /></label>
            </div>
        }

        if (field.fieldType === 'boolean') {
            formField = <div key={index}>
                <Field label={`${field.data}`} name={`datesAndDimensions[${index}].${field.name}`} disabled={!poolEnabled} index={index} errorName={field.name} component={CustomCheckBox} />
            </div>
        }

        if (field.fieldType === 'dropdown') {
            formField = <div key={index}>
                <DropdownDataInputFormik
                    fieldName={`datesAndDimensions[${index}].${field.name}`}
                    useComboBoxAsMenuWidth={true}
                    options={productMusicUsageDimensionOptions}
                    allowNull={true}
                    readOnly={!poolEnabled}
                    isHidden={false}
                    selectedCode={datesAndDimensions && datesAndDimensions.length > 0 && datesAndDimensions[index] ? datesAndDimensions[index][field.name] : null}
                />
            </div>
        }

        return formField;
    }


    const handleDeleteRow = (rowIndex) => {
        if (datesAndDimensions && datesAndDimensions.length && datesAndDimensions[rowIndex] && datesAndDimensions[rowIndex].effectiveDateRange) {
            handleDeleteAllLinkedWeightsSettingsWhenTheDatesAndDimensionsAreDeleted(datesAndDimensions[rowIndex]);
        }
        arrayHelperRef.current.remove(rowIndex)
    }


    const renderTableContentRow = (fields, rowIndex, arrayHelpers) => {
        const formFields = fields.map((field, index) => <td key={`tableCell${field.name}${rowIndex}`} className={"td"} title={`${field.data}`}>
            <div className={"tableCell flexRow flexAlignItemsCenter tableData"}>
                {renderField(field, rowIndex)}
            </div>
        </td>)
        formFields.push(<td key={'AddButton'} className="td"><div className={"tableCell flexRow flexAlignItemsCenter tableData"}><IconTextButton onClick={() => {
            handleDeleteRow(rowIndex)
        }
        } icon={'icon ms-Icon ms-Icon--Delete'} disabled={!poolEnabled} /></div></td>)

        return <tr>{formFields}</tr>;
    }

    const formatValues = (values) => {
        let updatedDatesAndDimensions = values;
        updatedDatesAndDimensions = updatedDatesAndDimensions.map(dd => ({ ...dd, productMusicUsageDimension: dd.productMusicUsageDimension?.data ? dd.productMusicUsageDimension.data : null }));
        return updatedDatesAndDimensions;
    }

    const handleSave = (values) => {
        let updatedDatesAndDimensions = formatValues(values.datesAndDimensions);
        changeData(updatedDatesAndDimensions, 'datesAndDimensions');
        saveChanges();
    }


    const mapDataForForm = (datesAndDimensions) => {
        return datesAndDimensions.map(line => {
            return {
                poolDimensionsAndDatesID: line.poolDimensionsAndDatesID,
                effectiveFrom: line.effectiveFrom,
                effectiveTo: line.effectiveTo,
                effectiveDateRange: line.effectiveDateRange,
                musicSource: line.musicSource,
                use: line.use,
                time: line.time,
                dimension1: line.dimension1,
                dimension2: line.dimension2,
                dimension3: line.dimension3,
                dimension4: line.dimension4,
                dimension5: line.dimension5,
                productMusicUsageDimension: line.productMusicUsageDimension
            }
        })
    }
    return (
        <Formik
            enableReinitialize
            initialValues={{ datesAndDimensions: datesAndDimensions && datesAndDimensions.length > 0 ? mapDataForForm(datesAndDimensions) : [emptyDatesAndDimensionsTableRow] }}
            validationSchema={datesAndDimensionsSchema}
            onSubmit={(values, { setSubmitting }) => {
                handleSave(values)
                setSubmitting(false);
            }}
        >
            {({ isSubmitting, isValid, values, errors }) => (
                <Form>
                    {isDataAvailable ? <div className={errors && errors.datesAndDimensions === OVER_LAPPING_DATES ? "invalidForkmikField tableContainer" : "tableContainer"} >
                        {errors && errors.datesAndDimensions === OVER_LAPPING_DATES && <OverLappingDatesAlert text={OVER_LAPPING_DATES} />}
                        <table className="table">
                            <thead key="thead" className={"tableContainer table thead tableHeader"}>
                                <tr>
                                    {renderTableHeader(weightSettingsPoolMaintenanceFields.fields, arrayHelperRef)}
                                </tr>
                            </thead>
                            <tbody className="tbody">
                                <FieldArray
                                    name="datesAndDimensions"
                                    render={(arrayHelpers) => {
                                        arrayHelperRef.current = arrayHelpers;
                                        return values.datesAndDimensions.map((datesAndDimensions, index) => renderTableContentRow(weightSettingsPoolMaintenanceFields.fields, index, arrayHelpers))
                                    }}
                                />
                            </tbody>
                        </table>
                        <div className="flexRow flexJustifyContentRight"> <SizedActionButton buttonAction={() => { arrayHelperRef.current.push(emptyDatesAndDimensionsTableRow) }} buttonText={'Add New'} disabled={!poolEnabled} dataTestId={"weighting"} /></div>
                    </div> : <DisplayText text={"Loading ..."} />}
                    <div key='action' className="row">
                        <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                            <ActionButtonFormik buttonText="Save" isSubmitting={isSubmitting} isErrorInForm={!isValid} disabled={!poolEnabled} />
                        </div>
                    </div>
                </Form>)}
        </Formik>
    )
}

export default DatesAndDimensionsWeightingTable;