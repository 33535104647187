import * as React from "react";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../redux/reducers/ModalReducer";
import { addTab, clearModalSearchResults, setEditableFields, sortProductFieldData, updateProductField } from "../../../redux/reducers/RepertoireReducer";
import { IProductStateKeys, IProductState } from "../../../redux/types/IProductState";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import DataGridTable from "../../components/dataGridTable/DataGridTable";
import { IShowFieldSettingViewModalProps } from "../../components/repertoireModal/RepertoireModal";
import { ADVERTISEMENT_TYPE, AVWORK_DETAILS_ACCORDION_VIEW, AV_WORK_DETAILS_EDITABLE_FIELDS, COMMENTS_KEY, COMMENT_CATEGORY_KEY, CUE_SHEET_TYPE, DATA_GRID_TABLE, EDITABLE_FIELDS, GENERAL_DATA_EDITABLE_FIELDS, INTRAY, PRODUCT_AV_SUBMISSIONS_KEY, PRODUCT_AV_WORKS_KEY, PRODUCT_WORKS_KEY, RECORDING_DETAILS, REPERTOIRE, SEARCH_VIEW, SEARCH_VIEW_PRODUCTS, SET_LIST_TYPE, TITLES_KEY, TITLE_EDITABLE_FIELDS_VIEW } from "../../Consts";
import { DataSource } from "../../types/DataSource";
import { IAccordionData } from "../../types/IAccordionData";
import { IProductAVRequest } from "../../types/IProductAVRequest";
import { IProductAVWorkSubmission } from "../../types/IProductAVWorkSubmission";
import { IProductMaintenanceSubmissionConfig } from "../../types/IProductMaintenanceSubmissionConfig";
import { IProductWorkDataItems } from "../../types/IProductWorkDataItems";
import { IRepertoireField } from "../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { ManualMergeOptions } from "../../types/ManualMergeOptions";
import AVSubmissionDetailsAccordian from "../avSubmissionDetailsAccordian/AVSubmissionDetailsAccordian";
import AVWorkDetailsAccordian from "../avWorkDetailsAccordian/AVWorkDetailsAccordian";
import ProductMaintenanceWorkTableContainer from "./ProductMaintenanceWorkTableContainer";

export interface IProductMaintenanceAccordionProps {
    updateProductField: typeof updateProductField;
    sortProductFieldData: typeof sortProductFieldData;
    addTab: typeof addTab;
    showModal: typeof showModal;
    openAccordian: (accordionName: string, componentName: string) => void;
    closeAccordian: (accordionName: string, componentName: string) => void;
    submitProductAVRequest: (productAVRequest: IProductAVRequest, productCoreID: number) => IProductAVWorkSubmission[];
    field: IRepertoireField;
    data: IAccordionData;
    dataGridTableData?: IRepertoireComponentDataItem;
    avWorkDetailsAccordianViewData?: IRepertoireComponentDataItem;
    avSubmissionDetailsAccordianViewData?: IRepertoireComponentDataItem;
    clearModalSearchResults: typeof clearModalSearchResults;
    lookupValues: ILookupDictionary;
    activeAccordian: string[];
    tabs: ITabReduxItem[];
    activeTab: number;
    dataSource: DataSource;
    getWorkDetails: (dataSource: string, workID: number) => void;
    getIPDetails: (ipBaseNumber: string, accountNumber: string) => void;
    getProductDetails: (dataSource: string, productID: number) => void;
    isReadonly: boolean;
    productCoreID: number;
    productMaintenanceSubmissionConfig?: IProductMaintenanceSubmissionConfig;
    setEditableFields: typeof setEditableFields;
    hideModal?: typeof hideModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    editableFields: IRepertoireField[];
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    productType: string;
    currentUser?: string;
    expandedProductResults?: number[];
    incrementTempID?: () => void;
    expandMatchingProduct?: (index: number) => void;
    manualMergeProduct: (matchingProductId: number, manualMergeOption: ManualMergeOptions) => void;
    product?: IProductState;
    commentCategoryFlag?: boolean;
    toggleAccordion: (name, componentName) => void;
}

export default class ProductMaintenanceAccordion extends React.PureComponent<
    IProductMaintenanceAccordionProps
> {

    componentDidMount() {
        const {
            openAccordian,
            field: { name, componentName },
            tabs,
            activeTab,
        } = this.props;

        if (tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.find(x => x === name)) {
            openAccordian(name, componentName);
        }
    }
    openWork(openItem: IProductWorkDataItems): void {

        if (openItem.intrayIndicator.value) {
            this.props.getWorkDetails(INTRAY, openItem.workCode)
        } else {
            this.props.getWorkDetails(REPERTOIRE, openItem.workCode)
        }
    }

    openInNewTab(openItem: any): void {
        if (openItem["ipBaseNumber"] != undefined) {
            this.props.getIPDetails(openItem["ipBaseNumber"], null);
        }
        else if (openItem["repertoireProductID"] != undefined) {
            this.props.getProductDetails(REPERTOIRE, openItem["repertoireProductID"]);
        }

    }

    changeData = (value: any, name: IRepertoireStateKeys) => {
        this.props.updateProductField(value, name as IProductStateKeys);
    }

    groupActionClick = (action: any, tableContentItem: any) => {
        this.props.manualMergeProduct(tableContentItem.repertoireProductID, action);
    }

    sortData(value: any, name: IRepertoireStateKeys): void {
        this.props.sortProductFieldData(value, name as IProductStateKeys);
    }

    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab, productType } = this.props;
        updateComponentFieldsItems(editableFields["default"], AVWORK_DETAILS_ACCORDION_VIEW, PRODUCT_AV_WORKS_KEY + this.getAVWorkType(productType), AVWORK_DETAILS_ACCORDION_VIEW, tabs, activeTab);
    }

    onSaveRecordingSectionFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab } = this.props;
        updateComponentFieldsItems(editableFields["default"], DATA_GRID_TABLE, RECORDING_DETAILS, DATA_GRID_TABLE, tabs, activeTab);
    }

    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableData,
            setEditableFields,
            productType
        } = this.props;

        setEditableFields(AVWORK_DETAILS_ACCORDION_VIEW, PRODUCT_AV_WORKS_KEY + this.getAVWorkType(productType))

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === AV_WORK_DETAILS_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableData,
            onClickSave: this.onSaveEditableFields.bind(this)
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    showFieldsModalRecordingSectionProducts = () => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableData,
            setEditableFields,
            editableFields,
        } = this.props;

        setEditableFields(DATA_GRID_TABLE, RECORDING_DETAILS)
        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableData,
            onClickSave: this.onSaveRecordingSectionFields.bind(this)
        }

        showUpdateFieldsModal('Recording Details', fieldSetting);
    }

    getAVWorkType(productType: string): string {
        switch (productType) {
            case ADVERTISEMENT_TYPE:
                return 'Ads';
            case SET_LIST_TYPE:
                return 'SetLists'
            case CUE_SHEET_TYPE:
                return 'CueSheets'
            default:
                return '';
        }
    }

    renderAccordionData = () => {
        const {
            field,
            avWorkDetailsAccordianViewData,
            avSubmissionDetailsAccordianViewData,
            showModal,
            clearModalSearchResults,
            lookupValues,
            data,
            addTab,
            updateProductField,
            tabs,
            activeTab,
            dataSource,
            isReadonly,
            productCoreID,
            submitProductAVRequest,
            productMaintenanceSubmissionConfig,
            productType,
            currentUser,
            expandedProductResults,
            incrementTempID,
            expandMatchingProduct,
            product,
            commentCategoryFlag,
            dataGridTableData,
        } = this.props;

        const isRecordType = product.productType === 'RECORDING';

        const isDataSourceIntray = product.dataSource === DataSource.Intray;

        if (!commentCategoryFlag && field.name === COMMENTS_KEY) {
            dataGridTableData.fields = dataGridTableData.fields.filter(x => x.name != COMMENT_CATEGORY_KEY);
        }

        if (tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(field.name)) {
            if (field.name == PRODUCT_AV_WORKS_KEY) {
                return <div className={["contentAccordion", isReadonly ? "pointerEnable" : ""].join(" ")}>
                    <AVWorkDetailsAccordian
                        avWorkDetailsAccordianViewData={avWorkDetailsAccordianViewData}
                        lookupValues={lookupValues}
                        isReadonly={isReadonly}
                        productAVWork={data && data.data}
                        updateProductField={updateProductField}
                        tabs={tabs}
                        activeTab={activeTab}
                        productType={productType}
                        getAvWorkType={this.getAVWorkType}
                        product={product}
                    />
                </div>
            }
            else if (field.name == PRODUCT_AV_SUBMISSIONS_KEY) {
                if (isDataSourceIntray) {
                    return <div />
                }
                return <div className={["contentAccordion", isReadonly ? "pointerEnable" : ""].join(" ")}>
                    <AVSubmissionDetailsAccordian
                        avSubmissionDetailsAccordianViewData={avSubmissionDetailsAccordianViewData}
                        dataGridTableData={dataGridTableData}
                        lookupValues={lookupValues}
                        isReadonly={isReadonly || productCoreID <= 0}
                        productAVWorkSubmissions={data && data.data && data.data.submissions}
                        productAVRequest={data && data.data && data.data.requestDetails}
                        updateProductField={updateProductField}
                        tabs={tabs}
                        activeTab={activeTab}
                        sortData={this.sortData.bind(this)}
                        productCoreID={productCoreID}
                        submitProductAVRequest={submitProductAVRequest}
                        productMaintenanceSubmissionConfig={productMaintenanceSubmissionConfig}
                        incrementTempID={incrementTempID}
                    />
                </div>
            }
            else if (field.name == PRODUCT_WORKS_KEY && data && data.data) {
                return <div className={["contentAccordion", isReadonly ? "pointerEnable" : ""].join(" ")}>
                            <ProductMaintenanceWorkTableContainer
                                tableContents={data.data}
                                dataGridTableData={dataGridTableData}
                                componentInstance={field.name}
                                changeData={this.changeData.bind(this)}
                                openWork={this.openWork.bind(this)}
                                stateKey={field.name}
                                lookupValues={lookupValues}
                                showModal={showModal}
                                isDataSourceIntray={isDataSourceIntray}
                                isRecordType={isRecordType}
                            />
                </div>
            }
            else if (data && data.data) {
                return (
                    <div className={["contentAccordion", isReadonly ? "pointerEnable" : ""].join(" ")}>
                        <DataGridTable
                            tableContents={data.data}
                            tableActions={data.actions}
                            stateKey={field.name}
                            changeData={this.changeData.bind(this)}
                            sortData={this.sortData.bind(this)}
                            componentInstance={field.name}
                            dataGridTableData={dataGridTableData}
                            showModal={showModal}
                            clearModalSearchResults={clearModalSearchResults}
                            lookupValues={lookupValues}
                            addTab={addTab}
                            showRowNumber={false}
                            dataSource={dataSource}
                            isReadOnly={field.name === "societyInterestPercentage" ? true : isReadonly}
                            dependentRowAction={this.openWork.bind(this)}
                            openInNewTab={this.openInNewTab.bind(this)}
                            tabs={tabs}
                            activeTab={activeTab}
                            currentUser={currentUser}
                            groupActionClick={this.groupActionClick.bind(this)}
                            expandedProductResults={expandedProductResults}
                            expandMatchingProduct={expandMatchingProduct}
                            incrementTempID={incrementTempID}
                            entity={product}
                        />
                    </div>
                );
            }
        }
    };

    render() {
        const { field, tabs, activeTab } = this.props;
        const renderConfigureFieldsButton: boolean = field.name === PRODUCT_AV_WORKS_KEY || field.name === RECORDING_DETAILS ;
        return (
            <div className="AccordionContainer">
                <div
                    className={["itemAccordion", tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(field.name) ? "activeItem" : null].join(" ")}>
                    <div className="col-md-12">
                        <div onClick={() => this.props.toggleAccordion(field.name, field.componentInstance)}>
                            <i
                                title="Expand section"
                                className={["icon ms-Icon ms-Icon--ChevronRight",
                                    tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(field.name) ? "expanded" : null].join(" ")}
                            />
                            {field.data}
                        </div>
                        {
                            renderConfigureFieldsButton &&
                            <button className="editBtn" title="Configure data fields" onClick={field.name === RECORDING_DETAILS  ? this.showFieldsModalRecordingSectionProducts.bind(this) : this.showFieldsModal.bind(this)}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        }

                    </div>
                </div>
                <div>{this.renderAccordionData()}</div>
            </div>
        );
    }
}
