import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IUsageGroupState } from "../../../../redux/types/IUsageGroupState";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { SEARCH_ACTION, USAGE_MATCHING_WORKS_SECTION_KEY, USAGE_ROWS_SECTION_KEY, MATCHINGWORKS_KEY, DEFINITE_MATCH, USAGE_MATCHING_PRODUCTS_SECTION_KEY, MATCHINGPRODUCTS_KEY, REPERTOIRE, MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY, USAGE_MATCHSTATUS_DEFINITE_MATCH, USAGE_MATCHSTATUS_POTENTIAL_MATCH, USAGE_MATCHSTATUS_NO_MATCH_FOUND, USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY, USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY, USAGE_PRODUCT_DETAILS_ROWS_SECTION_KEY, USAGE_GROUP_ATTACHMENTS_SECTION_KEY, EDITABLE_FIELDS_GRID, TITLE_EDITABLE_FIELDS_VIEW, DATA_GRID_TABLE, GENERAL_DATA_EDITABLE_FIELDS, USAGE_MAINTENANCE_GRIDS_VIEW } from "../../../Consts";
import { ITableAction } from "../../../types/ITableAction";
import { showModal, hideModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { addUsageDetailsShareIPRow, clearModalSearchResults, expandUsageDetails, setEditableFields } from "../../../../redux/reducers/RepertoireReducer";
import { updateUsageField } from "../../../../redux/reducers/RepertoireReducer";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IMatchingWorksRowData } from "../../../types/usageTypes/IMatchingWorksRowData";
import { IMatchingProductsRowData } from "../../../types/usageTypes/IMatchingProductsRowData";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IUsageProductDetails } from "../../../types/usageTypes/IUsageProductDetails";
import { ContainerDetailsWindow } from "../../../../dataingestion/components/containerDetailsWindow/ContainerDetailsWindow";
import UsageMaintenanceAttachmentsGridContainer from "./UsageMaintenanceAttachmentsGridContainer";
import { MessageBanner, MessageType } from "../../../components/messageBanner/MessageBanner";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IUsagePool } from "../../../types/usageTypes/IUsagePool";
import { ITreeData } from "../../../types/ITreeData";
import { IUsagePoolState } from "../../../../redux/types/IUsagePoolState";
import UsageMaintenanceDetailsGrid from "./UsageMaintenanceDetailsGrid";

export interface IUsageMaintenanceGridsViewProps {
    usageMaintenanceGridsViewData: IRepertoireComponentDataItem,
    usageDataGridTableData: IRepertoireComponentDataItem,
    usageGroup: IUsageGroupState
    showModal: typeof showModal;
    clearModalSearchResults: typeof clearModalSearchResults;
    hideModal: typeof hideModal;
    updateUsageField: typeof updateUsageField;
    getWorkDetails: (dataSource: string, workID: number) => void;
    getProductDetails: (dataSource: string, workID: number) => void;
    lookupValues?: ILookupDictionary;
    addUsageDetailsShareIPRow?: typeof addUsageDetailsShareIPRow;
    resetMessageBanner: () => void;
    editableFields: IRepertoireField[];
    setEditableFields: typeof setEditableFields;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    usagePoolsSearchResults: IUsagePool[];
    sources?: ITreeData[];
    getUsagePool: (poolId: number, matchingSources: ITreeData[]) => void;
    pool?: IUsagePoolState;
    expandedUsageDetailsRow?: number[];
    expandUsageDetail?: (index: number) => void;
}

interface IUsageMaintenanceGridsViewState {
    activeHeaderSection: string;
    isExpandedUsageMatchingSection: boolean;
}

export class UsageMaintenanceGridsView extends React.PureComponent<
    IUsageMaintenanceGridsViewProps,
    IUsageMaintenanceGridsViewState
> {

    constructor(props: IUsageMaintenanceGridsViewProps) {
        super(props);
        this.state = {
            activeHeaderSection: USAGE_ROWS_SECTION_KEY,
            isExpandedUsageMatchingSection: false
        };
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { usageMaintenanceGridsViewData, usageGroup } = this.props;

        if (usageMaintenanceGridsViewData && usageMaintenanceGridsViewData.fields) {

            let filteredFields = usageMaintenanceGridsViewData.fields.filter(
                (field: IRepertoireField) =>
                    (usageGroup.usageType.toString() === "UsageToWork"
                        && field.name !== USAGE_MATCHING_PRODUCTS_SECTION_KEY) ||
                    (usageGroup.usageType.toString() === "UsageToProduct"
                        && field.name !== USAGE_MATCHING_WORKS_SECTION_KEY)
            ).filter(
                (field: IRepertoireField) =>
                    (usageGroup.usageType.toString() === "UsageToWork"
                        && field.name !== USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY) ||
                    (usageGroup.usageType.toString() === "UsageToProduct"
                        && field.name !== USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY)
            )

            return filteredFields.map((section, index) => (
                <div
                    className={[
                        "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                    ].join(" ")}
                    key={index}
                    onClick={() => this.onClickHeaderSection(section.name)}
                >
                    {section.data}
                </div>
            ));
        }
    }

    getTableActions = (sectionKey: string) => {
        let actions: ITableAction[] = []
        switch (sectionKey) {
            case USAGE_MATCHING_WORKS_SECTION_KEY:
            case USAGE_MATCHING_PRODUCTS_SECTION_KEY:
                actions = [{ name: SEARCH_ACTION }]
                break;
        }
        return actions;
    }

    changeData = (value: any, name: IRepertoireStateKeys) => {
        if ((name === MATCHINGWORKS_KEY || name === MATCHINGPRODUCTS_KEY)) {
            if (value.length === 1) {
                value[0].status = DEFINITE_MATCH;
                this.props.updateUsageField(USAGE_MATCHSTATUS_DEFINITE_MATCH, MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY);
            }
            else if (value.length > 1) {
                this.props.updateUsageField(USAGE_MATCHSTATUS_POTENTIAL_MATCH, MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY);
            }
            else if (value.length === 0) {
                this.props.updateUsageField(USAGE_MATCHSTATUS_NO_MATCH_FOUND, MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY);
            }
        }
        if (name === 'usage') {

        }
        this.props.updateUsageField(value, name as IRepertoireStateKeys);
    }

    openWork(openItem: IMatchingWorksRowData): void {
        this.props.getWorkDetails(REPERTOIRE, openItem.workID)
    }

    openProduct(openItem: IMatchingProductsRowData): void {
        this.props.getProductDetails(REPERTOIRE, openItem.productCoreID)
    }

    clearAllRowsButThis = (value: any) => {
        const { usageGroup } = this.props;
        if (usageGroup.matchingWorks) {
            const matchingWorks = usageGroup.matchingWorks.filter(x => x.workID == value.workID);
            this.props.updateUsageField(matchingWorks, MATCHINGWORKS_KEY);

            if (matchingWorks.filter(x => x.status == USAGE_MATCHSTATUS_DEFINITE_MATCH).length == 1) {
                this.props.updateUsageField(USAGE_MATCHSTATUS_DEFINITE_MATCH, MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY);
            }
        }
        else {
            const matchingProducts = usageGroup.matchingProducts.filter(x => x.productCoreID == value.productCoreID);
            this.props.updateUsageField(matchingProducts, MATCHINGPRODUCTS_KEY);

            if (matchingProducts.filter(x => x.status == USAGE_MATCHSTATUS_DEFINITE_MATCH).length == 1) {
                this.props.updateUsageField(USAGE_MATCHSTATUS_DEFINITE_MATCH, MATCHSTATUS_USAGE_MAINTENANCE_STATE_KEY);
            }
        }
    }

    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFields,
            usageDataGridTableData,
            setEditableFields,
            editableFieldsDataView
        } = this.props;
        setEditableFields(DATA_GRID_TABLE, USAGE_ROWS_SECTION_KEY);

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;
        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS_GRID,
            componentViewData: fieldsViewData,
            dataGridViewData: usageDataGridTableData,
            onClickSave: this.onSaveEditableFields.bind(this),
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab } = this.props;
        updateComponentFieldsItems(editableFields["default"], DATA_GRID_TABLE, USAGE_ROWS_SECTION_KEY, DATA_GRID_TABLE, tabs, activeTab);
    }

    onExpandClicked = () => {

        this.setState({ isExpandedUsageMatchingSection: !this.state.isExpandedUsageMatchingSection });
    }
    renderExpandAll = () => {

        const { isExpandedUsageMatchingSection } = this.state;

        return (
            <div className='arrowIconExpandDiv'>
                <div className={isExpandedUsageMatchingSection ? 'arrowIconExpandAllExpanded' : 'arrowIconExpandAll'}>
                    <i className="icon ms-Icon ms-Icon--DoubleChevronLeft" aria-hidden="true" id="expandResultsButton" onClick={() => this.onExpandClicked()} title={isExpandedUsageMatchingSection ? "Collapse Results" : "Expand Results"}></i>
                </div>
                {isExpandedUsageMatchingSection ? <div className='arrowIconExpandText'> Collapse Results</div> : <div className='arrowIconExpandText'> Expand Results</div>}
            </div>
        );
    }


    renderAccordions() {
        const { usageDataGridTableData, expandedUsageDetailsRow, expandUsageDetail, usageGroup, lookupValues, usagePoolsSearchResults, sources, getUsagePool, pool, resetMessageBanner, showModal, hideModal, clearModalSearchResults, addUsageDetailsShareIPRow } = this.props;
        const { activeHeaderSection, isExpandedUsageMatchingSection } = this.state;
        switch (activeHeaderSection) {
            case USAGE_ROWS_SECTION_KEY:
                return (
                    <>
                        <div className="marginVerticalLarge flex-end-Content widthFull">
                            <button className="contributorGearBtn" title="Configure data fields" onClick={this.showFieldsModal.bind(this)}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                        <UsageMaintenanceDetailsGrid
                            dataGridTableData={usageDataGridTableData}
                            tableContents={usageGroup.usages}
                            isExpandable={true}
                            stateKey={undefined}
                            changeData={this.changeData.bind(this)}
                            lookupValues={lookupValues}
                            showModal={showModal}
                            addUsageDetailsShareIPRow={addUsageDetailsShareIPRow}
                            resetMessageBanner={resetMessageBanner}
                            pool={pool}
                            expandedUsageDetailsRow={expandedUsageDetailsRow}
                            expandUsageDetailRow={expandUsageDetail}
                        />
                    </>)
            case USAGE_PRODUCT_DETAILS_ROWS_SECTION_KEY:
                let usageProductDetails: IUsageProductDetails[] = [];
                usageGroup.usages.map(usage =>
                    usage.productDetails.map(pd =>
                        usageProductDetails.push(pd)
                    ));
                return (
                    <DataGridTable
                        dataGridTableData={usageDataGridTableData}
                        componentInstance={USAGE_PRODUCT_DETAILS_ROWS_SECTION_KEY}
                        tableContents={usageProductDetails}
                        isExpandable={false}
                        stateKey={undefined}
                        changeData={undefined}
                        showRowNumber={false}
                        sortData={undefined}
                    />
                );
            case USAGE_MATCHING_WORKS_SECTION_KEY:
                let openWorkFlow: boolean;
                function sortByDateDesc(a: any, b: any) {
                    return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
                }

                if (usageGroup.workflows !== null && usageGroup.workflows.length > 0) {
                    usageGroup.workflows.sort(sortByDateDesc);
                    openWorkFlow = usageGroup.workflows[0].entityType === "Usage (Unidentified Performance)" && usageGroup.workflows[0].status === "Open";
                }
                return (
                    <div>
                        {openWorkFlow ?
                            <MessageBanner resetMessageBanner={resetMessageBanner}
                                messageType={MessageType.Danger}
                                messages={["Outstanding Unidentified Claim Workflow must be processed before this usage group can be matched."]} />
                            : null}
                        <div className="row">
                            <div className="searchResultsDiv">
                                {this.renderExpandAll()}
                            </div>
                            <div className="matchingWorksSection">
                                <DataGridTable
                                    dataGridTableData={usageDataGridTableData}
                                    componentInstance={USAGE_MATCHING_WORKS_SECTION_KEY}
                                    tableContents={usageGroup.matchingWorks}
                                    tableActions={this.getTableActions(USAGE_MATCHING_WORKS_SECTION_KEY)}
                                    showModal={showModal}
                                    clearModalSearchResults={clearModalSearchResults}
                                    stateKey={MATCHINGWORKS_KEY}
                                    hideModal={hideModal}
                                    isExpandable={true}
                                    changeData={this.changeData.bind(this)}
                                    showRowNumber={false}
                                    sortData={undefined}
                                    usageType={usageGroup.usageType}
                                    openWorkFlow={openWorkFlow}
                                    dependentRowAction={this.openWork.bind(this)}
                                    clearAllRowsButThis={this.clearAllRowsButThis.bind(this)}
                                    isExpandedUsageMatchingSection={isExpandedUsageMatchingSection}
                                />
                            </div>
                        </div>
                    </div>
                )
            case USAGE_MATCHING_PRODUCTS_SECTION_KEY:
                return (
                    <DataGridTable
                        dataGridTableData={usageDataGridTableData}
                        componentInstance={USAGE_MATCHING_PRODUCTS_SECTION_KEY}
                        tableContents={usageGroup.matchingProducts}
                        tableActions={this.getTableActions(USAGE_MATCHING_PRODUCTS_SECTION_KEY)}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        stateKey={MATCHINGPRODUCTS_KEY}
                        hideModal={hideModal}
                        isExpandable={true}
                        changeData={this.changeData.bind(this)}
                        showRowNumber={false}
                        sortData={undefined}
                        usageType={usageGroup.usageType}
                        dependentRowAction={this.openProduct.bind(this)}
                        clearAllRowsButThis={this.clearAllRowsButThis.bind(this)}
                    />
                );
            case USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY:
                return (
                    <DataGridTable
                        dataGridTableData={usageDataGridTableData}
                        componentInstance={USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY}
                        tableContents={usageGroup.allocations}
                        isExpandable={false}
                        stateKey={undefined}
                        changeData={undefined}
                        showRowNumber={false}
                        sortData={undefined}
                    />
                );
            case USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY:
                return (
                    <DataGridTable
                        dataGridTableData={usageDataGridTableData}
                        componentInstance={USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY}
                        tableContents={usageGroup.allocations}
                        isExpandable={false}
                        stateKey={undefined}
                        changeData={undefined}
                        showRowNumber={false}
                        sortData={undefined}
                    />
                );
            case USAGE_GROUP_ATTACHMENTS_SECTION_KEY:
                return (
                    <UsageMaintenanceAttachmentsGridContainer />
                )
        }
    }

    render() {
        return (
            <div className="GridsView">
                <div className="headerContainer">
                    {this.renderGridViewHeaderSections()}
                </div>
                {this.renderAccordions()}
            </div>

        );
    }
}