import * as React from "react";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { IValidationMessage } from "../../types/IValidationMessage";
import { MessageBanner, MessageType } from "../messageBanner/MessageBanner";
import { WORK_MAINTENANCE_TOOLBAR, PRODUCT_MAINTENANCE_TOOLBAR, IP_MAINTENANCE_TOOLBAR, LICENSE_MAINTENANCE_TOOLBAR, AGREEMENT_MAINTENANCE_TOOLBAR } from "../../Consts";
import IMembersPortalComponentDataItem from "../../../redux/types/IMembersPortalComponentDataItem";
import { isNullOrUndefined } from "../../../util/tools";

export interface ISaveResultProps {
    componentInstance: string;
    saveSuccess?: boolean;
    postSuccess?: boolean;
    matchingSuccess?: boolean;
    saveResultData: IRepertoireComponentDataItem | IMembersPortalComponentDataItem;
    messages: IValidationMessage[];
    deleteAccount?: boolean;
    mergeSuccess?: boolean;
    addRepresentationSuccessful?: boolean;
    exportSuccess?: boolean;
    newAllocateDistributionPoolsJobSuccess?: boolean;
    allocationJobInProgress?: boolean;
    resetMessageBanner: () => void;
    newBookPriceCalculationsJobSuccess?: boolean;
    bookPriceCalculationsJobInProgress?: boolean;
    processClaimJobSuccess?: boolean;
    mergeAgreementSuccess?: boolean;
}

export default class SaveResult extends React.Component<
    ISaveResultProps
> {

    constructor(props) {
        super(props)
    }

    renderValidationMessages(): string[] {
        const { messages, saveResultData } = this.props;
        const validationMessages: string[] = [];
        if (messages && messages.length) {
            messages.forEach(e => {
                if (!e || !e.messageKey || typeof e.messageKey !== 'string') {
                    return;
                }
                else if (e.messageKey.includes('AgreementValidationWithID')) {
                    e.messageKey = e.messageKey.replace('AgreementValidationWithID: ', '');
                    validationMessages.push(e.messageKey);
                }
                else {
                    try {
                        let message = saveResultData.fields.find(x => x.name.toString() === e.messageKey).data
                        if (e.additionalInfo) {
                            if (e.additionalInfo.startsWith('<') && e.additionalInfo.endsWith('>')) {
                                var formatValuesArray = e.additionalInfo.substring(1, e.additionalInfo.length - 1).split(',')

                                for (let i = 0; i < formatValuesArray.length; i++) {
                                    message = message.replace("<" + i + ">", formatValuesArray[i])
                                }
                            }
                            else {
                                message = message + ' ' + e.additionalInfo;
                            }

                        }
                        validationMessages.push(message);
                    }
                    catch (error) {
                        let message = e.messageKey
                        if (e.additionalInfo) {
                            message = message + ' ' + e.additionalInfo;
                        }
                        validationMessages.push(message);
                    }
                }
            })
        }
        else {
            validationMessages.push(saveResultData.fields.find(x => x.name.toString() === 'saveFailedMessage').data);
        }

        return validationMessages;
    }

    render() {
        const {
            saveSuccess,
            postSuccess,
            matchingSuccess,
            saveResultData,
            deleteAccount,
            mergeSuccess,
            exportSuccess,
            resetMessageBanner,
            componentInstance,
            addRepresentationSuccessful,
            newAllocateDistributionPoolsJobSuccess,
            allocationJobInProgress,
            newBookPriceCalculationsJobSuccess,
            bookPriceCalculationsJobInProgress,
            processClaimJobSuccess,
            messages,
            mergeAgreementSuccess
        } = this.props;
        if (isNullOrUndefined(postSuccess) && isNullOrUndefined(saveSuccess) && isNullOrUndefined(matchingSuccess)) {
            return <div />;
        }
        else if (postSuccess === false || saveSuccess === false || matchingSuccess === false) {
            if(componentInstance === "WorkMaintenanceAccordionView") {
                return <div className="workMessageBanner"><MessageBanner 
                resetMessageBanner={resetMessageBanner}
                messageType={MessageType.Danger}
                messages={this.renderValidationMessages()} /></div>
            } else {
                return <MessageBanner 
                resetMessageBanner={resetMessageBanner}
                messageType={MessageType.Danger}
                messages={this.renderValidationMessages()} />
            }
        }
        else if (matchingSuccess && componentInstance !== IP_MAINTENANCE_TOOLBAR) {
            return <MessageBanner
                resetMessageBanner={resetMessageBanner}
                messageType={MessageType.Success}
                messages={this.renderValidationMessages()} />
        }
        else if (postSuccess && componentInstance !== IP_MAINTENANCE_TOOLBAR) {

            let postSuccessMessage: string;
            if (componentInstance === WORK_MAINTENANCE_TOOLBAR) {
                postSuccessMessage = 'workPostSuccessMessage';
            } else if (componentInstance === PRODUCT_MAINTENANCE_TOOLBAR) {
                postSuccessMessage = 'productPostSuccessMessage';
            } else {
                postSuccessMessage = 'agreementPostSuccessMessage';
            }

            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === postSuccessMessage).data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={messages} />
        }
        else if (deleteAccount) {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'accountDeleteSuccess').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={messages} />
        }
        else if (mergeSuccess) {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'mergeSucessMessage').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={messages} />
        }
        else if (exportSuccess) {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'exportSuccessMessage').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={messages} />
        }
        else if (newAllocateDistributionPoolsJobSuccess) {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'newAllocateDistributionPoolsJobSuccessMessage').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={messages} />
        }
        else if (allocationJobInProgress) {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'newAllocateDistributionPoolsJobSuccessMessage').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Information} messages={messages} />
        }
        else if (processClaimJobSuccess){
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'processClaimJobSuccessMessage').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={messages} />
        }
        else if (newBookPriceCalculationsJobSuccess) {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'newBookPriceCalculationsJobSuccessMessage').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={messages} />
        }
        else if (bookPriceCalculationsJobInProgress) {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'newBookPriceCalculationsJobSuccessMessage').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Information} messages={messages} />
        }
        else if (saveSuccess && addRepresentationSuccessful !== undefined && !(addRepresentationSuccessful === true))
        {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'saveFailedMessage').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Danger} messages={messages} />
        }
        else if (saveSuccess && componentInstance === LICENSE_MAINTENANCE_TOOLBAR && messages?.length === 1 && messages[0]?.messageKey === 'submitLicenseSuccessMessage')
        {
            const message: string[] = [saveResultData.fields.find(x => x.name.toString() === messages[0]?.messageKey).data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={message} />                      
        }
        else if (saveSuccess && componentInstance === WORK_MAINTENANCE_TOOLBAR && messages?.length === 1 && messages[0]?.messageKey === 'workAddtoPackageSuccessMessage')
        {
            const message: string[] = [saveResultData.fields.find(x => x.name.toString() === messages[0]?.messageKey).data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={message} />                      
        }
        else if (saveSuccess && componentInstance === PRODUCT_MAINTENANCE_TOOLBAR && messages?.length === 1 && messages[0]?.messageKey === 'productAddtoPackageSuccessMessage')
        {
            const message: string[] = [saveResultData.fields.find(x => x.name.toString() === messages[0]?.messageKey).data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={message} />                      
        }
        else if (saveSuccess && componentInstance === AGREEMENT_MAINTENANCE_TOOLBAR && messages?.length === 1 && messages[0]?.messageKey === 'agreementAddtoPackageSuccessMessage')
        {
            const message: string[] = [saveResultData.fields.find(x => x.name.toString() === messages[0]?.messageKey).data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={message} />                      
        }
        else if (saveSuccess && componentInstance === LICENSE_MAINTENANCE_TOOLBAR && messages?.length === 1 && messages[0]?.messageKey === 'submitLicenseFailureMessage') {
            const message: string[] = [saveResultData.fields.find(x => x.name.toString() === messages[0]?.messageKey).data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Danger} messages={message} />
        }
        else if (mergeAgreementSuccess) {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'agreementMergeSuccessMessage').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={messages} />
        }
        else {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'saveSuccessMessage')?.data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={messages} />
        }
    }
}