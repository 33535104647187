import * as React from "react";
import { IRepertoireField } from "../../repertoire/types/IRepertoireField";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IMembersPortalField } from "../types/IMembersPortalField";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { SEARCH_VIEW_DRAFT_AGREEMENTS, SEARCH_VIEW_DRAFT_WORKS } from "../Consts";
import { ActionButtonFormik } from "../../repertoire/components/actionButton/ActionButton";
import DisplayTableSearchResults from "../sharedComponents/displayTableSearchResults/DisplayTableSearchResults";
import { useMsal } from "@azure/msal-react";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { IMemberStatementSearchResult } from "../types/IMemberStatementSearchResult";
import { FormatFields } from "../../redux/types/FormatFields";
import { IDraftWorkSearchResult } from "../types/IDraftWorkSearchResult";
import { Formik, Form, Field, FieldArray } from 'formik';
import { ASSIGNEE, ASSIGNOR, REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../repertoire/Consts";
import { AGREEMENT_CATEGORY_LOOKUP, AGREEMENT_TYPE_LOOKUP, TERRITORY_LOOKUP } from "../../lookup/Consts";
import { getLookupValues } from "../../lookup/services/LookupHelpers";
import { IDraftAgreementSearchQuery } from "../types/IDraftAgreementSearchQuery";
import SelectorView from "../../repertoire/components/selectorView/SelectorView";
import { SizedDropdownDataInputFormik } from "../../repertoire/components/dropdownDataInput/DropdownDataInputFormik";
import { IDraftAgreementSearchResult } from "../types/IDraftAgreementsSearchResult";


const AGREEMENT_TYPE = 'agreementType';
const AGREEMENT_CATEGORY = 'agreementCategory';

export interface IDraftAgreementsSearchViewProps {
    searchDraftAgreements: (query: IDraftAgreementSearchQuery, lookupValues: ILookupDictionary, isModalOpen?: boolean) => void;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    draftAgreementsSearchResults: any[];
    searchSuccessful: boolean;
    paymentStatementGeneralDataViewData?: IMembersPortalComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    resetPagination: (repertoireSection: string) => void;
    getMemberStatementDetails?: (payment: IMemberStatementSearchResult, lookupValues: ILookupDictionary, userNameNumber: string, formats?: FormatFields[]) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    disabled?: boolean;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    currentUserNameNumber?: string;
    expandAllResults: () => void;
    expandAll: boolean;
    isSearching?: boolean;
    dataSource: string;
    getAgreementDetails?: (dataSource: string, agreementID:number) => void;
}



const DraftAgreementsSearchView: React.FC<IDraftAgreementsSearchViewProps> = ({
    searchDraftAgreements,
    searchViewData,
    searchSuccessful,
    searchResultsTableData,
    draftAgreementsSearchResults,
    currentUserNameNumber,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    resetPagination,
    lookupValues,
    sortSearchResults,
    dataSource,
    getAgreementDetails,
}) => {
    const [loaded, setLoaded] = React.useState(false);
    const [filteredFields, setFilteredFields] = React.useState<IRepertoireField[] | IMembersPortalField[]>([]);
    const [filteredSearchResults, setFilteredSearchResults] = React.useState<IRepertoireField[]>([]);
    const [presentCurrentPage, setPresentCurrentPage] = React.useState(1);
    const { instance, accounts } = useMsal();
    const [searchType, setSearchType] = React.useState(ASSIGNOR);

    const componentInstance = SEARCH_VIEW_DRAFT_AGREEMENTS;

    React.useEffect(() => {
        setLoaded(true);
        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        
    }, [])


    React.useEffect(() => {

        if (searchViewData && searchViewData.fields) {
            let filteredSearchFields = searchViewData.fields.filter(
                (searchField: IRepertoireField | IMembersPortalField) =>
                    searchField.componentInstance === componentInstance
            );

            setFilteredFields(filteredSearchFields);
        }

        if (searchResultsTableData.fields) {
            let filteredFields = searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === componentInstance
        
            );
            setFilteredSearchResults(filteredFields);
        }

    }, [searchViewData])

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        setPresentCurrentPage(currentPage);
    }

    const onSearch = (values: {
                agreementNumber?: string,
                ipName?: string,
                agreementCategory: { data: string, description: string },
                ipNameNumber: string,
                draftAgreementIsAssignor: string,
                agreementType: { data: string, description: string },
    }) => {
        let searchQuery = {
            category: values.agreementCategory && values.agreementCategory.data ? values.agreementCategory.data : '',
            type: values.agreementType && values.agreementType.data ? values.agreementType.data : '',
            ipNumber: values.ipNameNumber||null,
            ipName: values.ipName||null,
            number: values.agreementNumber,
            searchType: searchType,
            currentUserNameNumber: currentUserNameNumber,
        }
        searchDraftAgreements(searchQuery, lookupValues)
    };

      
      const CustomInputComponent = ({
        field, 
        form: { touched, errors }, 
        ...props
      }) => (
        <div>
          <input type="text" {...field} {...props} />
          {touched[field.name] &&
            errors[field.name] && <div className="error">{errors[field.name]}</div>}
        </div>
      );

    const renderSearchFields = () => {
            let filteredSearchFieldsMapper = (searchField: IRepertoireField | IMembersPortalField, index: number): React.ReactElement => {
                let formField: React.ReactElement = <></>;

                if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal')) {

                    formField =  <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                     <label>{`${searchField.data}:`}</label>
                            <Field label={`${searchField.data}`} name={`${searchField.name}`} component={CustomInputComponent} />
                    </div>
                }
              
                if (searchField.fieldType === 'select_action' && (searchField.name === AGREEMENT_TYPE || searchField.name === AGREEMENT_CATEGORY)) {
                    let options: { code: string, description: string }[] = [];

                    if (lookupValues && searchField.name === AGREEMENT_TYPE) {
                        options.push(... getLookupValues(AGREEMENT_TYPE_LOOKUP, lookupValues))
                    }
                    else if (lookupValues && searchField.name === AGREEMENT_CATEGORY){
                        const membersPortalCodes = ['ADM', 'SPB']
                        const categories = getLookupValues(AGREEMENT_CATEGORY_LOOKUP, lookupValues).filter(option => membersPortalCodes.includes(option.code));

                        options.push(... categories)
                    }
                    formField = <SizedDropdownDataInputFormik
                                            label={searchField.data}
                                            fieldName={searchField.name}
                                            useComboBoxAsMenuWidth={true}
                                            options={options}
                                            allowNull={true}
                                            readOnly={false}
                                            isHidden={false}
                                        />
                }
                return formField;
            };
            
        
        return <FieldArray
                    name="draftAgreementsForm"
                    render={() => (
                    <div>
                        {filteredFields.length > 0 ? (
                        filteredFields.map(filteredSearchFieldsMapper)
                        ) : <>Loading...</>}
                    </div>
                    )}
      />    
    };

    const handleSorting = (result) => {
        sortSearchResults(SEARCH_VIEW_DRAFT_WORKS, result)
    }

    const rowActions = {
        iconButton: {
            icon: "icon ms-Icon ms-Icon--OpenInNewTab",
            onClick: (agreement) => {
                getAgreementDetails(dataSource, agreement.agreementID)
            }
        }
    }

    const onChangeSearchType = (searchType: string) => {
        setSearchType(searchType)
    }

    const getStatusDescription = (status: string) => {
        const statusLookup = lookupValues['AgreementStatus'].lookups.filter(lookup => lookup.code === status);
        if (statusLookup.length == 0) {
            return status;
        }
        return statusLookup[0].description;
    }
    const getSearchResultsWithStatusDescription = (searchResults: IDraftWorkSearchResult[]) => {
        if (searchResults && searchResults.length > 0) {
            return searchResults.map((searchResult: IDraftWorkSearchResult) => {
                searchResult.status = getStatusDescription(searchResult.status);
            });
        }
        else{
            return searchResults;
        }
    }

    getSearchResultsWithStatusDescription(draftAgreementsSearchResults)

    if (loaded && filteredFields && filteredFields.length > 0) {
        let updatedSearchResult=[];
        if(draftAgreementsSearchResults && draftAgreementsSearchResults.length>0){
             const lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookupValues);

             updatedSearchResult= draftAgreementsSearchResults.map(item=>{
                let updatedTerritoryCodes = [];
                item.agreementTerritory.map(x => {
                    updatedTerritoryCodes.push(x.include ? `I: ${lookupValsTerritories.find(item => item.code === x.territory).code}` : (`E: ${lookupValsTerritories.find(item => item.code === x.territory).code}`))
                })
                return {...item,agreementTerritory:updatedTerritoryCodes}
             })
            }
        return (
            <div className="searchView">       
                <div className="row">
                    <SelectorView
                            selection={searchType}
                            setSelection={onChangeSearchType}
                            option1={ASSIGNOR}
                            option2={ASSIGNEE}
                            selectorTitle={''}
                        />
                </div>
                <Formik
                    initialValues={{
                        agreementNumber: null,
                        ipName: null,
                        agreementCategory: null,
                        ipNameNumber: null,
                        draftAgreementIsAssignor: null,
                        agreementType: null,
                }}
                    onSubmit={(values, { setSubmitting }) => {
                        onSearch(values)
                        setSubmitting(false);
                    }}
                    >                       
       {({ isSubmitting, isValid, dirty }) => (
         <Form>
                <div key='searchFields' className="row"> 
                    {renderSearchFields()}
                </div>
                <div key='action' className="row">
                    <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                       <ActionButtonFormik buttonText="Search" isSubmitting={isSubmitting} isErrorInForm={!isValid && dirty} />
                    </div>
                </div>         
         </Form>
       )}
     </Formik>     
                    {searchSuccessful && <div key='results'>
                        <DisplayTableSearchResults 
                            searchSuccessful={searchSuccessful} 
                            searchResultsTableData={filteredSearchResults} 
                            searchResults={updatedSearchResult}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={presentCurrentPage}
                            updatePagination={updateUserPagination}
                            lookupValues={lookupValues}
                            resetPagination={resetPagination}
                            sortSearchResults={handleSorting}
                            rowActions={rowActions}
                            getAgreementDetails={getAgreementDetails}
                        />
            </div>}
            </div>    
        );
    }
    return <div>Loading ...</div>
}

export default DraftAgreementsSearchView;